import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, FreeMode, Scrollbar } from "swiper";

const useMediaShortsStyles = makeStyles((theme) => ({
  mainBox: {
    position: "relative",
  },
  headingText: {
    fontWeight: "bold",
    bold: 700,
    padding: "0px 0px 16px 0px",
  },
  headingBox: {
    padding: "44px 0px 0px 0px",
  },
  ellips_61_blue: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  mediaCardBox: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    width: "632px",
    height: "398px",
    padding: "0 18px 0 0",
    borderRadius: "20px",
    backdropFilter: "blur(60px)",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    borderWidth: "2px",

    // WebkitBackdropFilter: "blur(60px)",
    // backgroundClip: "contain-box,border-box",
    backgroundOrigin: "border-box",
    borderImageSlice: 1,
    borderImageSource:
      "linear-gradient(122deg, var(--white) 1%, rgba(255, 255, 255, 0) 101%)",
    backgroundImage:
      "linearGradient(123deg, rgba(255, 255, 255, 0.5) -4%, rgba(255, 255, 255, 0.5) 106%), linear-gradient(122deg, var(--white) 1%, rgba(255, 255, 255, 0) 101%)",
    [theme.breakpoints.down("lg")]: {
      width: "502px",
      height: "338px",
    },
    [theme.breakpoints.down("md")]: {
      width: "462px",
      height: "328px",
    },
  },
  mediaCardLeft: {
    flex: 0.5,
  },
  mediaCarRight: {
    flex: 0.5,
  },
  mediaIcons: {
    display: "flex",
    flexDirection: "column",
    height: "398px",
    // justifyContent: "center",
    width: "107px",
    paddingTop: "34px",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("lg")]: {
      height: "338px",
    },
    [theme.breakpoints.down("md")]: {
      height: "328px",
    },
  },
  description: {
    position: "absolute",
    bottom: 0,
    padding: "0px 18px 24px 24px",
    [theme.breakpoints.down("lg")]: {
      padding: "0px 8px 15px 15px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 8px 10px 15px",
    },
  },
  mediaSection: {
    display: "flex",
    flexDirection: "row",
  },
  media: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "34px",
  },
  cardImage: {
    width: "316px",
    height: "398px",
    [theme.breakpoints.down("lg")]: {
      width: "262px",
      height: "338px",
    },
    [theme.breakpoints.down("md")]: {
      width: "232px",
      height: "328px",
    },
  },
  icons: {
    // width: "54px", height: "54px",
    cursor: "pointer",
  },
  groupRight: {
    marginLeft: "6px",
    width: "65px",
    height: "65px",
    padding: "19px 23px 26px 22px",
    borderRadius: "33px",
    WebkitBackdropFilter: "blur(60px)",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #ebe3ff",
    backgroundImage:
      "linear-gradient(135deg, rgba(255, 255, 255, 0.5) -10%, rgba(255, 255, 255, 0) 104%)",
    cursor: "pointer",
  },
  groupLeft: {
    width: "65px",
    height: "65px",
    padding: "19px 22px 26px 23px",
    borderRadius: "33px",
    WebkitBackdropFilter: "blur(60px)",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #ebe3ff",
    backgroundImage:
      "linear-gradient(225deg, rgba(255, 255, 255, 0.5) 110%, rgba(255, 255, 255, 0) -4%)",
    cursor: "pointer",
  },
  SubtitleText: {
    maxWidth: 220,
  },
  inActivityIcon: { width: "74px", height: "74px", padding: "10px" },
}));

const MediaCards = (props) => {
  const { url, title, subtitle } = props;
  const classes = useMediaShortsStyles();

  return (
    <Box className={classes.mediaCardBox}>
      <Box className={classes.mediaCardLeft}>
        <img className={classes.cardImage} src={url} />
      </Box>
      <Box>
        <Box className={classes.description}>
          <Typography
            variant="h4"
            sx={{ color: "#7a48ff" }}
            className={classes.headingText}
          >
            <b>{title}</b>
          </Typography>
          <Typography variant="subtitle1" className={classes.SubtitleText}>
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default function MediaShorts() {
  const classes = useMediaShortsStyles();
  const maskGroup1 = require("../../../assets/graphics/mask_group.webp");
  const maskGroup = require("../../../assets/graphics/mask_group_1.webp");
  const ellips_62_blue = require("../../../assets/graphics/ellipse 61_blue.webp");
  const group_insta = require("../../../assets/social-icons/group_insta.webp");
  // const group_fb = require("../../../assets/social-icons/group_fb.webp");
  const gropp_lin = require("../../../assets/social-icons/Group2610.png");
  const socialRight = require("../../../assets/icons/social_Right.webp");
  const socialLeft = require("../../../assets/icons/social_Left.webp");
  const [active, setActive] = useState("FB");

  const {
    ReactComponent: FbActive,
  } = require("../../../assets/social-icons/active_Inactive/Facebook - Active.svg");
  const {
    ReactComponent: FbInactive,
  } = require("../../../assets/social-icons/active_Inactive/Facebook - Inactive.svg");
  const {
    ReactComponent: LinActive,
  } = require("../../../assets/social-icons/active_Inactive/Linkedin - Active.svg");
  const {
    ReactComponent: LinInactive,
  } = require("../../../assets/social-icons/active_Inactive/Linkedin - Inactive.svg");

  const {
    ReactComponent: InstActive,
  } = require("../../../assets/social-icons/active_Inactive/Instagram - Active.svg");
  const {
    ReactComponent: InstInactive,
  } = require("../../../assets/social-icons/active_Inactive/Instagram - Inactive.svg");

  const handleClick = (logo_name) => {
    setActive(logo_name);
  };
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const array = [
    {
      url: require("../../../assets/graphics/elegance 316x398.png"),
      title: "Elegance redefined",
      subtitle:
        "Immerse yourself in a world of exquisite 3D fashion designs that skillfully redefine elegance and leave a lasting impression with refined details and modern flair. Redesign the future with Designers Capitol!",
    },
    {
      url: require("../../../assets/graphics/clo3d 316x398.png"),
      title: "Revolutionize Fashion Design: Clo3D ",
      subtitle:
        "A cutting-edge 3D design software tailored for fashion creators. Seamlessly visualize, simulate, and bring your ideas to life in the virtual realm. Empowering innovation and efficiency.",
    },
    {
      // url: require("../../../assets/graphics/clo3d 316x398.png"),
      url: require("../../../assets/graphics/Snapshots_pic.png"),
      title: "Textures with Adobe Substance",
      subtitle:
        "The versatile material creation tools that help fashion designers do their best work. Create textures and 3D assets and turn your imagination into captivating reality. The possibilities are endless!",
    },
    {
      url: require("../../../assets/graphics/blueprint 316x398.png"),
      title: "Blueprint's Library:",
      subtitle:
        "Your design journey begins here. Choose from a treasure trove of inspiring designs as a starting point for your 3D fashion creations. Add your vision and create the trends of tomorrow.",
    },
  ];

  return (
    <Box className={classes.mainBox}>
      <img className={classes.ellips_61_blue} src={ellips_62_blue} />
      <Box
        sx={{
          height: "66px",
          position: "absolute",
          flexDirection: "row",
          display: "flex",
          right: "50px",
          top: "65px",
        }}
      >
        <div className={classes.groupLeft} ref={navigationPrevRef}>
          <img src={socialLeft} />
        </div>
        <div className={classes.groupRight} ref={navigationNextRef}>
          <img src={socialRight} />
        </div>
      </Box>
      {/* <GroupLeft className={classes.groupLeft} ref={navigationPrevRef} /> */}

      {/* <GroupRight className={classes.groupRight} ref={navigationNextRef} /> */}

      <Box className={classes.headingBox}>
        <Typography variant="h3" className={classes.headingText}>
          <b>Social Media Shots</b>
        </Typography>
      </Box>
      <Box className={classes.media}>
        <Box className={classes.mediaIcons}>
          {active === "FB" ? (
            <a
              href="https://www.facebook.com/DesignersCapitol"
              target="_blank"
              rel="noreferrer"
            >
              <FbActive onClick={() => handleClick("FB")} />
            </a>
          ) : (
            <FbInactive
              className={classes.inActivityIcon}
              onClick={() => handleClick("FB")}
            />
          )}
          {active === "LIN" ? (
            <a
              href="https://www.linkedin.com/company/designerscapitol/"
              target="_blank"
              rel="noreferrer"
            >
              <LinActive onClick={() => handleClick("LIN")} />
            </a>
          ) : (
            <LinInactive
              className={classes.inActivityIcon}
              onClick={() => handleClick("LIN")}
            />
          )}
          {active === "INST" ? (
            <a
              href="https://www.instagram.com/designerscapitol"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <InstActive onClick={() => handleClick("INST")} />
            </a>
          ) : (
            <InstInactive
              className={classes.inActivityIcon}
              onClick={() => handleClick("INST")}
            />
          )}
        </Box>

        <div className="Vector" style={{ display: "contents" }}>
          <Swiper
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            modules={[FreeMode, Navigation, Scrollbar]}
            // spaceBetween={30}
            scrollbar={{
              hide: true,
              dragSize: 500,
            }}
            mousewheel={true}
            freeMode={true}
            slidesPerView={2}
            style={{ height: "450px" }}
          >
            {array.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <MediaCards
                    url={item.url}
                    title={item.title}
                    subtitle={item.subtitle}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </Box>
    </Box>
  );
}
