import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import {
  webLinkDeleteReducerTypes,
  webLinkDetailReducerTypes,
  webLinkPositionReducerTypes,
  webLinkUploadReducerTypes,
} from "../reducers/userReducer/webLink";

const version = process.env.REACT_APP_API_VERSION;

function* uploadWebLinkRequest(params) {
  const res = yield request(
    "post",
    EndPoints.WEBLINK_SAVE.replace("{version}", version),
    params
  );

  return res;
}

function* uploadWebLink({ params }: any) {
  try {
    yield put({
      type: webLinkUploadReducerTypes.REQUEST_WEBLINK_UPLOAD,
      payload: null,
    });
    const response = yield call(uploadWebLinkRequest, params);

    yield put({
      type: webLinkUploadReducerTypes.RESPONSE_WEBLINK_UPLOAD,
      payload: response?.data,
    });
    yield put({
      type: webLinkDetailReducerTypes.WEBLINK_DETAILS,
      params: { accountGuid: params.accountGuid },
    });
  } catch (e) {
    yield put({ type: webLinkUploadReducerTypes.ERROR_WEBLINK_UPLOAD });
  }
}

//get Weblink Detail list

function* WebLinkDetailRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.WEBLINK_DETAILS.replace("{version}", version),
    payload,
    0
  );

  return res;
}

function* webLinkDetail({ params }: any) {
  try {
    yield put({
      type: webLinkDetailReducerTypes.REQUEST_WEBLINK_DETAILS,
      payload: null,
    });
    const response = yield call(WebLinkDetailRequest, params);

    yield put({
      type: webLinkDetailReducerTypes.RESPONSE_WEBLINK_DETAILS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: webLinkDetailReducerTypes.ERROR_WEBLINK_DETAILS });
  }
}

//get deleteWeblinkDetail list

function* deleteWebLinkRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.WEBLINK_DELETE.replace("{version}", version),
    payload
  );

  return res;
}

function* deleteWebLink({ params }: any) {
  try {
    yield put({
      type: webLinkDeleteReducerTypes.REQUEST_WEBLINK_DELETE,
      payload: null,
    });
    const response = yield call(deleteWebLinkRequest, params);

    yield put({
      type: webLinkDeleteReducerTypes.RESPONSE_WEBLINK_DELETE,
      payload: response?.data,
    });
    yield put({
      type: webLinkDetailReducerTypes.WEBLINK_DETAILS,
      params: { accountGuid: params.accountGuid },
    });
  } catch (e) {
    yield put({ type: webLinkDeleteReducerTypes.ERROR_WEBLINK_DELETE });
  }
}

//WeblinPosition

function* webLinkPositionRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.WEBLINK_POSITION.replace("{version}", version),
    payload
  );

  return res;
}

function* webLinkPosition({ params }: any) {
  try {
    yield put({
      type: webLinkPositionReducerTypes.REQUEST_WEBLINK_POSITION,
      payload: null,
    });
    const response = yield call(webLinkPositionRequest, params);

    yield put({
      type: webLinkPositionReducerTypes.RESPONSE_WEBLINK_POSITION,
      payload: response?.data,
    });
    yield put({
      type: webLinkDetailReducerTypes.WEBLINK_DETAILS,
      params: { accountGuid: params.accountGuid },
    });
  } catch (e) {
    yield put({ type: webLinkPositionReducerTypes.ERROR_WEBLINK_POSITION });
  }
}

function* webLinkSaga() {
  yield all([
    takeLatest(webLinkUploadReducerTypes.WEBLINK_UPLOAD, uploadWebLink),
    takeLatest(webLinkDetailReducerTypes.WEBLINK_DETAILS, webLinkDetail),
    takeLatest(webLinkDeleteReducerTypes.WEBLINK_DELETE, deleteWebLink),
    takeLatest(webLinkPositionReducerTypes.WEBLINK_POSITION, webLinkPosition),
  ]);
}

export default webLinkSaga;
