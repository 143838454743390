/*
 **	User name
 **	Created	5/25/2023
 **	uploadForm.tsx
 */

import { ExpandMoreRounded } from "@mui/icons-material";
import {
  Chip,
  createTheme,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  TextField,
  ThemeProvider,
  useTheme,
  Box,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { IRootState } from "app/redux/reducers";

import {
  designerProductsReducerTypes,
  productListReducerTypes,
} from "../../redux/reducers/productReducer/product-main";
import { uploadDesignReducerTypes } from "../../redux/reducers/productReducer/uploadDesign";
import { uploadProductFileReducerTypes } from "../../redux/reducers/productReducer/uploadProductFile";
import { CheckboxField } from "../UIFormFields";
import {
  BlackBorderedButton,
  GreenButton,
  PurpleButton,
  PurpleLightButton,
} from "../UIFormFields/Buttons";
import UploadThumbnail from "./uploadThumbnail";
import CategoryComponent from "../CategoryComponent";
import { CategoryDataContext } from "../CategoryComponent/CategoryDataContext";

export default function UploadForm(props) {
  const { uploadFile, setUploadFile, handleClose, setUploadModalStatus } =
    props;

  const { productData } = useContext(CategoryDataContext);
  const theme = useTheme();
  const dispatch = useDispatch();
  const userData = useSelector((state: IRootState) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;
  const filterTypes = useSelector((state: IRootState) => state.filterTypes);
  const uploadProduct = useSelector((state: IRootState) => state.uploadDesign);
  const uploadProductFile = useSelector(
    (state: IRootState) => state.uploadProductFile
  );
  const toolTypes = filterTypes.toolTypeList;
  const productTag = filterTypes?.productTagList;
  const handleReupload = useRef<HTMLInputElement>();
  const [count, setCount] = React.useState({
    title: 0,
    description: 0,
  });
  const isRegisteredWithStripe =
    userData?.userAdditionalInfo?.isRegisteredWithStripe;

  const stripeId = userData?.userAdditionalInfo?.stripeId;
  const stripeRegistered = Boolean(isRegisteredWithStripe && stripeId);
  const [formData, _setFormData] = React.useState({
    title: "",
    productTag: [],
    productId: null,
    tools: [],
    description: "",
    categories: [],
    currency: "",
    price: "",
    material: [],
    size: [],
    color: "",
    audience: "",
    deliveryScope: "",
    files: [],
    ProductReferenceId: "",
  });

  const setFormData = (params) => {
    _setFormData(params);
    props?.setFormData?.(params);
  };

  const [errors, setErrors] = React.useState({
    title: "",
    description: "",
    // categories: "",
    currency: "",
    price: "",
    material: "",
    productTag: "",
    tools: "",
    thumbnail: "",
  });

  useEffect(() => {
    if (
      uploadProductFile?.dataThumbnail?.id &&
      uploadProductFile?.dataThumbnail?.id !== formData.productId &&
      (uploadProductFile !== null || uploadProductFile !== undefined)
    ) {
      setFormData({
        ...formData,
        productId: uploadProductFile?.dataThumbnail?.id,
      });
    }
  }, [uploadProductFile]);

  //api for upload
  const designUploadAPI = () => {
    const params = new FormData();

    params.append(
      "ProductUploadId",
      formData?.productId || uploadProductFile?.dataThumbnail?.id || 0
    );
    params.append("ProductTitle", formData.title || "Default");
    params.append("ProductTagIdsList", formData.productTag?.join(","));
    params.append("ProductToolIdsList", formData.tools?.join(","));
    params.append("ProductDescription", formData.description || "Draft");
    // params.append("ProductCategoryIdList", formData.categories);
    params.append("ProductCurrencyId", "1");
    params.append("ProductPrice", "0");
    // params.append("ProductMaterialIdList", formData.material?.join(","));
    // params.append("ProductSizeList", formData.size);
    params.append("ProductColorList", formData.color);
    params.append("AudienceId", formData.audience);
    params.append("DeliveryScopeId", formData.deliveryScope);
    params.append("AccountGuid", accountGuid);
    params.append("ProductReferenceId", formData.ProductReferenceId);
    params.append("IsPublished", "false");

    params.append(
      "ProductId",
      formData?.productId || uploadProductFile?.dataThumbnail?.id
    );

    if (productData) {
      const productCategoryId = productData?.productCategoriesTreeData
        ?.map((data) => {
          if (data?.parentCategory?.id) {
            return [data?.parentCategory?.id, data.id].join(",");
          }
          return data.id;
        })
        .filter((data) => data)
        .join(",");

      if (productData?.attributesTreeData?.length) {
        productData?.attributesTreeData.forEach((data3) => {
          if (data3?.selectedAttribute) {
            data3?.selectedAttribute.forEach((selectedAttributeValue, i) => {
              const mainData = {
                productId: 0,
                productCategoryId,
                productAttributeId: parseInt(data3?.id),
                productAttributeValueId: selectedAttributeValue,
              };
              // Product Attributes Id

              params.append(
                "productCategorySavedDetails",
                JSON.stringify(mainData)
              );
            });
          }
        });
      }
      if (productCategoryId?.includes("1,208")) {
        params.append(
          "productCategorySavedDetails",
          JSON.stringify({
            productId: 0,
            productCategoryId,
            productAttributeId: 0,
            productAttributeValueId: 0,
          })
        );
      }

      if (productData?.colorData.length) {
        productData?.colorData.forEach((colorD) => {
          // Product Colors
          params.append("ColorList", colorD);
        });
      }
    }

    dispatch({
      type: uploadDesignReducerTypes.EDIT_DESIGN,
      payload: params,
      noSuccess: true,
    });
  };

  useEffect(() => {
    if (uploadProductFile?.dataThumbnail?.id !== null || undefined || 0) {
      setErrors({ ...errors, thumbnail: "" });
    }
  }, [uploadProductFile]);

  const handleSubmit = () => {
    const tempErrors = {
      title: "",
      description: "",
      currency: "",
      price: "",
      material: "",
      thumbnail: "",
      tools: "",
      // categories: "",
      productTag: "",
    };
    let hasError = false;

    if (!formData.title) {
      tempErrors.title = "This field is required!";
      hasError = true;
    } else if (formData.title.length < 3) {
      tempErrors.title =
        "The title must be between 3 and 55 characters in length.";
      hasError = true;
    } else if (formData.title.length > 55) {
      tempErrors.title =
        "The title must be between 3 and 55 characters in length.";
      hasError = true;
    }

    if (!formData.description) {
      tempErrors.description = "This field is required!";
      hasError = true;
    }
    if (!uploadProductFile?.dataThumbnail?.id) {
      tempErrors.thumbnail = "Upload thumbnail";
      hasError = true;
    }

    // if (formData.categories.length < 1) {
    //   tempErrors.categories = "This field is required!";
    //   hasError = true;
    // }

    // if (formData.productTag.length < 1) {
    //   tempErrors.productTag = "This field is required!";
    //   hasError = true;
    // }

    if (formData.tools.length < 1) {
      tempErrors.tools = "This field is required!";
      hasError = true;
    }

    if (hasError) {
      setErrors({ ...errors, ...tempErrors });
    } else {
      setErrors({ ...tempErrors });
      designUploadAPI();
      setUploadModalStatus(true);
    }
  };

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setCount({ ...count, [e.target.name]: e.target.value.length });
    setErrors({ ...errors, [e.target.name]: false });
  };

  const mapedTagsObj = React.useMemo(() => {
    const obj = {};

    productTag?.forEach((value) => {
      obj[value.productTagID] = value.productTagName;
    });
    return obj;
  }, [productTag]);

  const handleChangeTags = (event) => {
    const {
      target: { value },
    } = event;

    setFormData({ ...formData, productTag: [...value] });
  };

  const handleDeleteTags = (item, index) => {
    const newData = [...formData.productTag];

    if (index > -1) {
      newData.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFormData({ ...formData, productTag: [...newData] });
  };

  const mapedToolsObj = React.useMemo(() => {
    const obj = {};

    toolTypes?.forEach((value) => {
      obj[value.toolDetailId] = value.toolName;
    });
    return obj;
  }, [toolTypes]);

  const handleChangeTools = (event) => {
    const {
      target: { value },
    } = event;

    setFormData({ ...formData, tools: [...value] });
  };

  const handleDeleteTools = (item, index) => {
    const newData = [...formData.tools];

    if (index > -1) {
      newData.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFormData({ ...formData, tools: [...newData] });
  };

  const {
    ReactComponent: DeleteIcon,
  } = require("../../../assets/icons/Icons_Light_Cross_White_Icon.svg");

  const onUploadFile = (event) => {
    setUploadFile(event.target.files[0]);
  };

  function onUploadProgress(progressEvent) {
    // alert(JSON.stringify(progressEvent))
    const progress = (progressEvent.loaded / progressEvent.total) * 100;

    dispatch({
      type: uploadProductFileReducerTypes.SET_UPLOAD_PRODUCT_THUMBNAIL_FILE_PROGRESS,
      payload: progress,
    });
  }

  const handleOnUpload = (file) => {
    if (uploadFile || file) {
      const params = new FormData();

      params.append("ProductThumbnail", uploadFile || file);
      params.append("AccountGuid", accountGuid);
      params.append("ProductUploadedThumbnailDetailsId", "0");

      dispatch({
        type: uploadProductFileReducerTypes.UPLOAD_PRODUCT_THUMBNAIL_FILE,
        payload: params,
        onUploadProgress,
      });
    }
    if (handleReupload.current) {
      handleReupload.current?.click();
    }
  };

  const handleReuploadCTA = () => {
    dispatch({
      type: uploadProductFileReducerTypes.CLEAR_UPLOAD_PRODUCT_THUMBNAIL_FILE,
    });
    setUploadFile(null);
  };

  const previewImage = useMemo(() => {
    return (
      <UploadThumbnail
        errors={errors}
        uploadFile={uploadFile}
        uploadProductFile={uploadProductFile}
        handleReupload={handleReupload}
        onUploadFile={onUploadFile}
        handleOnUpload={handleOnUpload}
      />
    );
  }, [uploadFile, uploadProductFile]);

  const showSuccess = formData.price ? stripeRegistered : true;

  useEffect(() => {
    if (uploadProduct?.data !== null) {
      if (
        uploadProduct?.data.responseMessage.includes(
          "Product uploaded successfully"
        ) &&
        showSuccess
      ) {
        handleClose();
        Swal.fire({
          icon: "success",
          title: "Congratulations...",
          text: `${uploadProduct?.data.responseMessage}`,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch({ type: uploadDesignReducerTypes.CLEAR_UPLOAD_DESIGN });
            dispatch({
              type: productListReducerTypes.PRODUCT_LIST,
              params: accountGuid === undefined ? "null" : accountGuid,
            });
            dispatch({
              type: designerProductsReducerTypes.DESIGNER_PRODUCT,
              payload: {
                accountGuid,
                loggedInGuid: accountGuid,
              },
            });
          }
          localStorage.removeItem("uploadType");
          localStorage.removeItem("fileUplaoded");
        });
      }
    }
  }, [uploadProduct, showSuccess]);

  const isNextEnabled = (() => {
    const lastChild =
      productData?.treeData?.[productData?.treeData?.length - 1]?.children;

    const productCategoryIsOkay =
      productData?.productCategoriesTreeData?.length === 2;

    if (
      uploadProduct?.loading !== true &&
      formData?.title &&
      formData?.description &&
      formData?.tools &&
      formData?.productTag &&
      productCategoryIsOkay &&
      productData?.arrtibutesLength ==
        productData?.attributesTreeData?.filter(
          (attributesTreeData) => attributesTreeData?.selectedAttribute?.length
        )?.length &&
      (productData?.showColorSwatch ? productData.colorData.length : true)
    ) {
      return true;
    }

    return false;
  })();

  return (
    <ThemeProvider
      theme={createTheme({
        typography: {
          allVariants: {
            fontFamily: "Poppins",
          },
        },
        components: {
          MuiMenuItem: {
            styleOverrides: {
              root: {
                padding: "12px",
                "&.Mui-selected": {
                  color: theme?.palette?.mode === "light" ? "#7a48ff" : " #fff",
                  backgroundColor:
                    theme?.palette?.mode === "light" ? "#fff" : "black",
                },
                hover: false,
              },
            },
          },
          MuiButtonBase: {
            defaultProps: {
              disableTouchRipple: true,
              disableRipple: true,
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: "6px",
              },
            },
          },
        },
      })}
    >
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "388px" }}>
          {previewImage}
          {uploadFile !== null ? (
            uploadProductFile.progressThumbnail < 100 ? (
              <PurpleLightButton
                disableFocusRipple
                disableElevation
                style={{
                  width: 400,
                  // marginLeft: 16,
                  marginTop: 24,
                }}
                onClick={handleReuploadCTA}
              >
                <span style={{ color: "#a49bbe" }}>
                  <i
                    className="fa-solid fa-cloud-arrow-up"
                    style={{ margin: "0px 10px 3px 0px", fontSize: "22px" }}
                  ></i>
                  ReUpload
                </span>
              </PurpleLightButton>
            ) : (
              <GreenButton
                disableFocusRipple
                disableElevation
                style={{
                  width: 400,
                  // marginLeft: 16,
                  marginTop: 24,
                }}
                onClick={handleReuploadCTA}
              >
                <i
                  className="fa-solid fa-cloud-arrow-up"
                  style={{ margin: "0px 10px 3px 0px", fontSize: "22px" }}
                ></i>
                ReUpload
              </GreenButton>
            )
          ) : (
            ""
          )}
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", marginLeft: "34px" }}
        >
          <div>
            <FormControl fullWidth error={!!errors.title}>
              <TextField
                error={!!errors.title}
                onChange={handleOnChange}
                value={formData?.title}
                id="outlined-name"
                name="title"
                label="Product Title*"
                sx={{ width: "690px" }}
              />
            </FormControl>
            <FormHelperText
              sx={{
                fontSize: "11px",
                fontWeight: "bold",
                color: "#ff4f47",
              }}
            >
              {errors.title}
            </FormHelperText>
          </div>

          <Box sx={{ marginTop: "24px" }}>
            <FormControl fullWidth>
              <TextField
                onChange={handleOnChange}
                value={formData?.ProductReferenceId}
                id="outlined-name"
                name="ProductReferenceId"
                label="Reference ID"
                sx={{ width: "690px" }}
              />
            </FormControl>
            <FormHelperText
              sx={{
                fontSize: "11px",
                fontWeight: "bold",
                color: "#a49bbe",
              }}
            >
              Note: Use this field to provide a 'Reference Number' from your
              existing Database for this product.
            </FormHelperText>
          </Box>

          <div>
            <FormControl fullWidth sx={{ marginTop: "24px" }}>
              <InputLabel id="demo-simple-select-label">Tools*</InputLabel>
              <Select
                sx={{ width: "690px" }}
                fullWidth
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                name="productTag"
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                  PaperProps: { sx: { maxHeight: 250 } },
                }}
                IconComponent={ExpandMoreRounded}
                value={formData?.tools}
                onChange={handleChangeTools}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Tools" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value, index) => {
                      const item = mapedToolsObj[value];

                      return (
                        <Chip
                          style={{
                            background: "#2b2639",
                            color: "#fff",
                            borderRadius: 6,
                            fontSize: 15,
                          }}
                          key={index}
                          label={item}
                          onDelete={() => handleDeleteTools(item, index)}
                          deleteIcon={<DeleteIcon />}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {toolTypes?.map((item) => (
                  <MenuItem
                    sx={{
                      borderBottom: 1,
                      borderBottomColor: "#ebe3ff",
                    }}
                    key={item.toolDetailId}
                    value={item.toolDetailId}
                  >
                    <CheckboxField
                      checked={formData.tools.indexOf(item.toolDetailId) > -1}
                      style={{ padding: "0px 10px 0px 10px" }}
                    />
                    {item.toolName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText
              sx={{
                fontSize: "11px",
                fontWeight: "bold",
                color: "#ff4f47",
              }}
            >
              {errors.tools}
            </FormHelperText>
          </div>

          <div>
            <FormControl fullWidth sx={{ marginTop: "24px" }}>
              <InputLabel id="demo-simple-select-label">Product Tag</InputLabel>
              <Select
                sx={{ width: "690px" }}
                fullWidth
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                label="Product Tag"
                name="productTag"
                value={formData.productTag}
                MenuProps={{
                  MenuListProps: { disablePadding: true },
                }}
                IconComponent={ExpandMoreRounded}
                onChange={handleChangeTags}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Product Tag"
                  />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value, index) => {
                      const item = mapedTagsObj[value];

                      return (
                        <Chip
                          style={{
                            background: "#2b2639",
                            color: "#fff",
                            borderRadius: 6,
                            fontSize: 15,
                          }}
                          key={index}
                          label={item}
                          onDelete={() => handleDeleteTags(item, index)}
                          deleteIcon={<DeleteIcon />}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                        />
                      );
                    })}
                  </Box>
                )}
              >
                {productTag?.map((item) => (
                  <MenuItem
                    sx={{
                      borderBottom: 1,
                      borderBottomColor: "#ebe3ff",
                    }}
                    key={item.productTagID}
                    value={item.productTagID}
                  >
                    <CheckboxField
                      checked={
                        formData.productTag.indexOf(item.productTagID) > -1
                      }
                      style={{ padding: "0px 10px 0px 10px" }}
                    />
                    {item.productTagName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText
              sx={{
                fontSize: "11px",
                fontWeight: "bold",
                color: "#ff4f47",
              }}
            >
              {errors.productTag}
            </FormHelperText>
          </div>

          <div>
            <FormControl fullWidth error={!!errors.description}>
              <TextField
                error={!!errors.description}
                onChange={handleOnChange}
                value={formData.description}
                id="outlined-name"
                name="description"
                label="Description*"
                sx={{ width: "690px", marginTop: "24px" }}
                multiline
                InputProps={{
                  inputComponent: TextareaAutosize,
                  inputProps: {
                    minRows: 4,
                    maxLength: 1500,
                    style: {
                      resize: "vertical",
                    },
                  },
                }}
              />
            </FormControl>
            <FormHelperText
              sx={{
                fontSize: "11px",
                fontWeight: "bold",
                color: "#ff4f47",
              }}
            >
              {errors.description}
            </FormHelperText>
          </div>
          <h6
            style={{
              display: "flex",
              justifyContent: "end",
              fontSize: "13px",
              fontWeight: "bold",
              color: "#a49bbe",
              marginTop: "6px",
            }}
          >
            Note : Max character limit: {count.description}/1500
          </h6>

          <Box
            sx={{
              marginTop: "24px",
              display: "flex",
              flexDirection: "column",
              maxWidth: 690,
            }}
          >
            <CategoryComponent isProduct />
          </Box>
        </Box>
      </Box>

      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginTop: "54px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <BlackBorderedButton
            sx={{
              width: "86px",
            }}
            onClick={handleClose}
          >
            Cancel
          </BlackBorderedButton>
          {uploadProductFile?.progressThumbnail < 100 || !uploadFile ? (
            <PurpleLightButton
              disabled={!isNextEnabled}
              style={{
                width: "86px",
                marginLeft: 16,
              }}
            >
              <span style={{ color: "#a49bbe" }}>Next</span>
            </PurpleLightButton>
          ) : (
            <PurpleButton
              disabled={!isNextEnabled}
              onClick={handleSubmit}
              style={{
                width: "86px",
                marginLeft: 16,
              }}
            >
              Next
            </PurpleButton>
          )}
        </Box>
        {formData?.productId || uploadProductFile?.dataThumbnail?.id ? (
          <h6
            style={{
              marginTop: "24px",
              textAlign: "center",
              fontWeight: "bold",
              color: "#a49bbe",
              cursor: "pointer",
            }}
            onClick={() => {
              designUploadAPI();
              handleClose();
            }}
          >
            Save to draft
          </h6>
        ) : null}
      </Box>
    </ThemeProvider>
  );
}
