//delete user

const initState = {
  loading: false,
  data: null,
  error: null,
};

export const deleteUserReducerTypes = {
  DELETE_USER: "DELETE_USER",
  REQUEST_DELETE_USER: "REQUEST_DELETE_USER",
  RESPONSE_DELETE_USER: "RESPONSE_DELETE_USER",
  ERROR_DELETE_USER: "ERROR_DELETE_USER",
};

export function deleteUserReducer(state = initState, action) {
  switch (action.type) {
    case deleteUserReducerTypes.REQUEST_DELETE_USER:
      return { ...state, loading: true };
    case deleteUserReducerTypes.RESPONSE_DELETE_USER:
      return { error: null, loading: false, data: action.payload };
    case deleteUserReducerTypes.ERROR_DELETE_USER:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}
