import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useGridStyles = makeStyles((theme) => ({
  gridBox: {
    display: "flex",
    color: "#a49bbe",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    // width: "100%",
    height: "120px",
    borderRadius: "6px",
    border: "1px solid #a49bbe",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #2b2639",
      color: "#2b2639",
    },
  },
  selectedGridBox: {
    display: "flex",
    color: "#2b2639",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    width: "120px",
    // width: "100%",
    height: "120px",
    borderRadius: "6px",
    cursor: "pointer",
    border: "1px solid #2b2639",
  },
}));

export default function UploadGrid({ uploadType, handleUploadFileTypeClick }) {
  const {
    ReactComponent: ThreeDModalIcon,
  } = require("../../../../assets/icons/Icons_Light_3D square Icon.svg");
  const {
    ReactComponent: AtworkIcon,
  } = require("../../../../assets/icons/Icons_Light_Artwork upload Icon.svg");
  const {
    ReactComponent: ImageIcon,
  } = require("../../../../assets/icons/Icons_Light_Image upload Icon.svg");
  const {
    ReactComponent: PatternIcon,
  } = require("../../../../assets/icons/Icons_Light_Pattern upload Icon.svg");
  const {
    ReactComponent: ScetchIcon,
  } = require("../../../../assets/icons/Icons_Light_Sketch upload Icon.svg");
  const {
    ReactComponent: UploadIcon,
  } = require("../../../../assets/icons/Icons_Light_Upload upload Icon.svg");

  const classes = useGridStyles();

  return (
    <div>
      <Box
        sx={{
          height: "418px",
          padding: "16px",
          backgroundColor: "#faf8ff",
          borderRadius: "6px",
          // opacity: 0.7,
          // width: "100%",
          margin: "0px 0px 0px 34px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            columnGap: "16px",
            rowGap: "16px",
            gridTemplateColumns: "auto auto auto ",
          }}
        >
          <Box
            className={
              uploadType._3D ? classes.selectedGridBox : classes.gridBox
            }
            onClick={() =>
              handleUploadFileTypeClick({
                _3D: true,
                pattern: false,
                artWork: false,
                sketch: false,
                image: false,
                otherFiles: false,
              })
            }
          >
            <ThreeDModalIcon style={{ width: "34px", height: "34px" }} />
            <Typography>3D</Typography>
          </Box>
          <Box
            className={
              uploadType.pattern ? classes.selectedGridBox : classes.gridBox
            }
            onClick={() =>
              handleUploadFileTypeClick({
                _3D: false,
                pattern: true,
                artWork: false,
                sketch: false,
                image: false,
                otherFiles: false,
              })
            }
          >
            <PatternIcon />
            <Typography>Pattern</Typography>
          </Box>
          <Box
            className={
              uploadType.artWork ? classes.selectedGridBox : classes.gridBox
            }
            onClick={() =>
              handleUploadFileTypeClick({
                _3D: false,
                pattern: false,
                artWork: true,
                sketch: false,
                image: false,
                otherFiles: false,
              })
            }
          >
            <AtworkIcon />
            <Typography>Art work</Typography>
          </Box>
          <Box
            className={
              uploadType.sketch ? classes.selectedGridBox : classes.gridBox
            }
            onClick={() =>
              handleUploadFileTypeClick({
                _3D: false,
                pattern: false,
                artWork: false,
                sketch: true,
                image: false,
                otherFiles: false,
              })
            }
          >
            <ScetchIcon />
            <Typography>Sketch</Typography>
          </Box>
          <Box
            className={
              uploadType.image ? classes.selectedGridBox : classes.gridBox
            }
            onClick={() =>
              handleUploadFileTypeClick({
                _3D: false,
                pattern: false,
                artWork: false,
                sketch: false,
                image: true,
                otherFiles: false,
              })
            }
          >
            <ImageIcon />
            <Typography>Image</Typography>
          </Box>
          <Box
            className={
              uploadType.otherFiles ? classes.selectedGridBox : classes.gridBox
            }
            onClick={() =>
              handleUploadFileTypeClick({
                _3D: false,
                pattern: false,
                artWork: false,
                sketch: false,
                image: false,
                otherFiles: true,
              })
            }
          >
            <UploadIcon />
            <Typography>Other files</Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
