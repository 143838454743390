/*
 **	User name
 **	Created	5/25/2023
 **	navigationBar.tsx
 */

import React from "react";
import { Box, useTheme } from "@mui/material";
import { useNavBarStyles } from "styles/navbarStyles";

export default function LibraryProductEditNavBar({ active, setActive }) {
  const classes = useNavBarStyles();
  const theme = useTheme();

  return (
    <Box className="">
      <Box className={classes.header}>
        <nav style={{ width: "100%" }}>
          <ul style={{ width: "100%", marginLeft: "-26px", display: "flex" }}>
            <li className={classes.navbar_li}>
              <span
                className={
                  theme?.palette?.mode === "light"
                    ? !active
                      ? `${classes.navbar_li_a_light} HeaderNavBar newDesignersNavBar`
                      : `${classes.navbar_li_a_light} HeaderNavBar`
                    : !active
                    ? `${classes.navbar_li_a_dark} HeaderNavBar newHeaderActiveNavBarNight`
                    : `${classes.navbar_li_a_dark} HeaderNavBar`
                }
                onClick={() => setActive(false)}
              >
                Library Product Information
              </span>
            </li>
            <li className={classes.navbar_li}>
              <span
                className={
                  theme?.palette?.mode === "light"
                    ? active
                      ? `${classes.navbar_li_a_light} HeaderNavBar newDesignersNavBar`
                      : `${classes.navbar_li_a_light} HeaderNavBar`
                    : active
                    ? `${classes.navbar_li_a_dark} HeaderNavBar newHeaderActiveNavBarNight`
                    : `${classes.navbar_li_a_dark} HeaderNavBar`
                }
                onClick={() => setActive(true)}
              >
                Additional Information
              </span>
            </li>
          </ul>
          <hr
            style={{
              color: "#999",
              width: "100%",
              height: "2px",
              marginTop: "25px",
            }}
          />
        </nav>
      </Box>
    </Box>
  );
}
