import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { LightModeCross, NightModeCross } from "app/components/Logo/logos";
import { PurpleButton } from "app/components/UIFormFields/Buttons";
import { useState } from "react";
import { saveSkipSettingForInviteEmployeesActionTypes } from "app/redux/reducers/company";
import InviteEmployeeForm from "./InviteEmployeeForm";
import Employee_Icon from "../../../../assets/icons/Employee_Icon.png";

export const useUpload3dModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 560,
    maxHeight: "95vh",
    height: 413,
    maxWidth: "80%",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 28,
    right: 24,
    position: "absolute",
  },
  titleBox: {
    margin: "54px 0px 0px 0px",
  },
}));

export default function InviteEmployeeAlert({ open, handleClose }) {
  const classes = useUpload3dModalStyles();
  const theme = useTheme();
  const userData = useSelector((state) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;
  const [inviteForm, setInviteForm] = useState(false);

  const dispatch = useDispatch();

  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };

  const openInviteForm = () => {
    setInviteForm(true);
  };

  const skipModal = () => {
    dispatch({
      type: saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES,
      payload: {
        isInviteEmployeeSettingSkipped: true,
        accountGuid,
      },
    });
  };

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal open={open}>
        <Box
          className={classes.modalStyle}
          style={{
            backgroundColor: theme?.palette?.mode === "light" && "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box className={classes.closeBox}>
              {theme?.palette?.mode === "light" ? (
                <LightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <NightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>
            <Box>
              <Box>
                <img
                  src={Employee_Icon}
                  alt="employee logo"
                  style={{
                    width: "100px",
                    height: "100px",
                    position: "relative",
                    top: "54px",
                    objectFit: "contain",
                  }}
                ></img>
              </Box>
              <Box
                className={classes.titleBox}
                style={{
                  width: "350px",
                }}
              >
                <Typography
                  style={
                    theme?.palette?.mode === "light"
                      ? heading.lightMode
                      : heading.nightMode
                  }
                >
                  {"Do you want to invite your Employees?"}
                </Typography>
              </Box>
              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 54,
                  paddingRight: 54,
                  width: "100%",
                  color: "black",
                }}
              >
                <p
                  style={{
                    width: "260px",
                    height: "23px",
                    flexGrow: 0,
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#2b2639",
                    position: "absolute",
                    top: "245px",
                  }}
                >
                  {"Invite employees to your company"}
                </p>
              </Box>
              <Box>
                <PurpleButton
                  style={{
                    width: "158px",
                    flexGrow: 0,
                    display: "flex",
                    flexDirection: "row",
                    justifyDontent: "center",
                    alignItems: "center",
                    gap: "10px",
                    position: "absolute",
                    top: "283px",
                    left: "201px",
                    objectFit: "contain",
                  }}
                  onClick={openInviteForm}
                >
                  {" "}
                  Invite Employee
                </PurpleButton>
              </Box>
              <Box
                onClick={() => {
                  skipModal();
                  handleClose(false);
                }}
                style={{
                  flexGrow: 0,
                  objectFit: "contain",
                  fontFamily: "Poppins",
                  fontSize: "15px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: "#a49bbe",
                  position: "absolute",
                  top: "357px",
                  left: "231px",
                }}
              >
                <p style={{ cursor: "pointer" }}>Skip For Now</p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* next modal */}
      {inviteForm && (
        <InviteEmployeeForm
          open={true}
          handleClose={() => setInviteForm(false)}
        />
      )}
    </Box>
  );
}
