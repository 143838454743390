import React from "react";
import { Button } from "@mui/material";

type Props = { onClick: () => any; name: string; isSelected: boolean };

const CategoryTile = ({ onClick, name, isSelected }: Props) => {
  return (
    <Button
      variant="outlined"
      onClick={onClick}
      style={{
        marginRight: 8,
        marginBottom: 8,
        color: "#a49bbe",
        borderColor: "#a49bbe",
        textTransform: "none",
        minHeight: 35,
        padding: "5px 8px",
        minWidth: 0,
        ...(isSelected && {
          background: "#2b2639",
          color: "#ffffff",
          borderColor: "#2b2639",
        }),
      }}
    >
      {name}
    </Button>
  );
};

export default CategoryTile;
