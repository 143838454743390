import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { licenceActionTypes } from "../reducers/license";
import { licenseSelectionReducerTypes } from "../reducers/licenceSelection";

const version = process.env.REACT_APP_API_VERSION;

function* fetchCompanyLicensesReqest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_COMPANY_LICENSES.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchCompanyLicenses({ payload }: any) {
  try {
    yield put({
      type: licenceActionTypes.GET_COMPANY_LICENSES_REQUEST,
    });
    const response = yield call(fetchCompanyLicensesReqest, payload);

    yield put({
      type: licenceActionTypes.GET_COMPANY_LICENSES_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: licenceActionTypes.GET_COMPANY_LICENSES_FAILURE,
      payload: e,
    });
  }
}

function* fetchLicenseInfoReqest(payload) {
  const res = yield request(
    "get",
    (
      EndPoints.FETCH_LICENSE +
      (payload.isCompany ? "/GetAllCompanyLicense" : "/GetAllIndividualLicense")
    ).replace("{version}", version),
    payload
  );

  return res;
}

function* fetchLicenseInfo({ payload }: any) {
  try {
    yield put({
      type: licenceActionTypes.FETCH_LICENSE_REQUEST,
    });
    const response = yield call(fetchLicenseInfoReqest, payload);

    yield put({
      type: licenceActionTypes.FETCH_LICENSE_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: licenceActionTypes.FETCH_LICENSE_FAILURE,
      payload: e,
    });
  }
}

//select individual licence

function* individualLicenceReqest(payload) {
  const res = yield request(
    "post",
    EndPoints.INDIVIDUAL_LICENCE.replace("{version}", version),
    payload
  );

  return res;
}

function* individualLicence({ params }: any) {
  try {
    yield put({
      type: licenseSelectionReducerTypes.REQUEST_LICENCE_SELECTION,
      payload: null,
    });
    const response = yield call(individualLicenceReqest, params);

    yield put({
      type: licenseSelectionReducerTypes.RESPONSE_LICENCE_SELECTION,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: licenseSelectionReducerTypes.ERROR_LICENCE_SELECTION });
  }
}

//select company licence
function* companyLicenceReqest(payload) {
  const res = yield request(
    "post",
    EndPoints.COMPANY_LICENCE.replace("{version}", version),
    payload
  );

  return res;
}

function* companyLicence({ params }: any) {
  try {
    yield put({
      type: licenseSelectionReducerTypes.REQUEST_COMPANY_LICENCE_SELECTION,
      payload: null,
    });
    const response = yield call(companyLicenceReqest, params);

    yield put({
      type: licenseSelectionReducerTypes.RESPONSE_COMPANY_LICENCE_SELECTION,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: licenseSelectionReducerTypes.ERROR_COMPANY_LICENCE_SELECTION,
    });
  }
}

//Add licence Key
function* addLicenceKeyReqest(payload) {
  const res = yield request(
    "put",
    EndPoints.ADD_LICENCE_KEY.replace("{version}", version),
    payload
  );

  return res;
}

function* addLicenceKey({ payload }: any) {
  try {
    yield put({
      type: licenceActionTypes.ADD_LICENSE_KEY_REQUEST,
      payload: null,
    });
    const response = yield call(addLicenceKeyReqest, payload);

    yield put({
      type: licenceActionTypes.ADD_LICENSE_KEY_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: licenceActionTypes.ADD_LICENSE_KEY_FAILURE });
  }
}

// new license saga ----

function* allCompanyLicensesReqest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_COMPANY_LICENSE.replace("{version}", version),
    payload
  );

  return res;
}

function* allCompanyLicenses({ payload }: any) {
  try {
    yield put({
      type: licenceActionTypes.GET_ALL_COMPANY_LICENSE_REQUEST,
    });
    const response = yield call(allCompanyLicensesReqest, payload);

    yield put({
      type: licenceActionTypes.GET_ALL_COMPANY_LICENSE_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: licenceActionTypes.GET_ALL_COMPANY_LICENSE_FAILURE,
      payload: e,
    });
  }
}
// individual's subscriptions options
function* allIndividualLicensesReqest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_INDIVIDUAL_LICENSE.replace("{version}", version),
    payload
  );

  return res;
}

function* allIndividualLicenses({ payload }: any) {
  try {
    yield put({
      type: licenceActionTypes.GET_ALL_COMPANY_LICENSE_REQUEST,
    });
    const response = yield call(allIndividualLicensesReqest, payload);

    yield put({
      type: licenceActionTypes.GET_ALL_COMPANY_LICENSE_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: licenceActionTypes.GET_ALL_COMPANY_LICENSE_FAILURE,
      payload: e,
    });
  }
}

function* licenseSaga() {
  yield all([
    takeLatest(licenceActionTypes.FETCH_LICENSE, fetchLicenseInfo),
    takeLatest(
      licenseSelectionReducerTypes.LICENCE_SELECTION,
      individualLicence
    ),
    takeLatest(
      licenseSelectionReducerTypes.COMPANY_LICENCE_SELECTION,
      companyLicence
    ),
    takeLatest(licenceActionTypes.ADD_LICENSE_KEY, addLicenceKey),
    takeLatest(licenceActionTypes.GET_COMPANY_LICENSES, fetchCompanyLicenses),
    takeLatest(licenceActionTypes.GET_ALL_COMPANY_LICENSE, allCompanyLicenses),
    takeLatest(
      licenceActionTypes.GET_ALL_INDIVIDUAL_LICENSE,
      allIndividualLicenses
    ),
  ]);
}
export default licenseSaga;
