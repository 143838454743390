import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/styles";
import { CirclePicker } from "react-color";
import ColorizeIcon from "@mui/icons-material/Colorize";
import { ColorPicker, useColor } from "react-color-palette";
import { ColorPickerBoard, LightModeCross } from "../../components/Logo/logos";
import "react-color-palette/lib/css/styles.css";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement={"top"} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    marginLeft: "-15px",
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    width: 348,
    padding: 0,
    objectFit: "contain",
    borderRadius: 8,
  },
  [`& .${tooltipClasses.arrow}`]: {
    visibility: "hidden",
  },
}));

const HoverCustomColor = ({ formData, setFormData }) => {
  const [color, setColor] = useColor(formData?.color, "#333");

  useEffect(() => {
    setFormData({ ...formData, color: color?.hex });
  }, [color.rgb, color.hex, color.hsv]);

  return (
    <Box>
      <Box style={{ height: "56px" }}>
        <h6
          style={{
            justifyContent: "start",
            display: "flex",
            padding: "16px 0px 0px 16px",
            fontWeight: "bold",
            color: "#a49bbe",
            cursor: "pointer",
          }}
        >
          Custom Color
        </h6>
        <Box
          style={{
            position: "absolute",
            top: 0,
            right: 16,
            padding: "16px 0px 16px 16px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <LightModeCross />
        </Box>
      </Box>

      <ColorPicker
        width={348}
        height={348}
        color={color}
        onChange={setColor}
        hideHSV
        hideRGB
      ></ColorPicker>
    </Box>
  );
};

const CustomColor = ({ formData, setFormData }) => {
  return (
    <>
      <HtmlTooltip
        leaveDelay="300"
        classes={{
          // @ts-ignore
          arrow: {
            "&::before": {
              backgroundColor: "blue",
              border: "2px solid red",
            },
          },
          tooltip: {
            backgroundColor: "#f00",
          },
        }}
        title={
          <HoverCustomColor formData={formData} setFormData={setFormData} />
        }
        placement="right-start"
        style={{ marginLeft: "50px" }}
        arrow
        // open
      >
        <h6
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            padding: "0px 16px 10px 0px",
            fontWeight: "bold",
            fontSize: "15px",
            color: "#7a48ff !important",
            cursor: "pointer",
          }}
        >
          Custom Color
        </h6>
      </HtmlTooltip>
    </>
  );
};

const HoverColor = ({ formData, setFormData }) => {
  const [selectedColor, setSelectedColor] = useState([]);
  const handleColorSelect = (color, event) => {
    setFormData({ ...formData, color: color.hex });
    const arr = selectedColor;

    arr.push(color.hex);
    setSelectedColor(arr);
  };

  const imgsrc = require("../../../assets/icons/Icons_Light_Tick mark Icon.svg");

  return (
    <Box>
      <Box>
        <h6
          style={{
            justifyContent: "start",
            margin: "10px 0px 0px 0px",
            display: "flex",
            padding: "0px 10px 0px 10px",
            fontWeight: "bold",
            color: "#a49bbe",
            cursor: "pointer",
          }}
        >
          Pick a Swatch
        </h6>
        {/* <h6
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            justifyContent: "end",
            margin: "10px 0px 0px 0px",
            display: "flex",
            padding: "0px 10px 0px 10px",
            fontWeight: "bold",
            color: "#a49bbe",
            cursor: "pointer",
          }}
        >
          Reset
        </h6> */}
      </Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "16px",
        }}
      >
        <CirclePicker
          width="100%"
          circleSize={45}
          circleSpacing={15}
          onChange={handleColorSelect}
          colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5"]}
        />
      </Box>
      {/* <h6
        style={{
          justifyContent: "start",
          display: "flex",
          padding: "0px 10px 0px 10px",
          fontWeight: "bold",
          color: "#a49bbe",
          cursor: "pointer",
        }}
      >
        Recently Picked
      </h6> */}
      {/* <Box
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "16px",
        }}
      >
        <CirclePicker
          width="100%"
          circleSize={45}
          circleSpacing={15}
          colors={selectedColor}
        />
      </Box> */}
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "16px",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            border: "1px solid greey",
            borderRadius: "3px",
            width: "34px",
            height: "34px",
          }}
        >
          <ColorizeIcon style={{ opacity: "20%" }} fontSize="large" />
        </Box>
        <CustomColor formData={formData} setFormData={setFormData} />
      </Box>
    </Box>
  );
};

const ColorFilter = ({ formData, setFormData }) => {
  return (
    <>
      <HtmlTooltip
        classes={{
          // @ts-ignore
          arrow: {
            "&::before": {
              backgroundColor: "blue",
              border: "2px solid red",
            },
          },
          tooltip: {
            backgroundColor: "#f00",
          },
        }}
        title={<HoverColor formData={formData} setFormData={setFormData} />}
        placement="bottom-end"
        style={{ marginLeft: "50px" }}
        arrow
        // open
      >
        <ColorPickerBoard style={{ cursor: "pointer" }} />
      </HtmlTooltip>
    </>
  );
};

export default ColorFilter;
