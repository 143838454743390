/*
 **	User name
 **	Created	5/25/2023
 **	index.tsx
 */

import React from "react";
import { useTheme } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { InputLabel, Select } from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";

const names = ["English", "German"];

function getStyles(name, languageName, theme) {
  return {
    fontWeight:
      languageName.indexOf(name) === -1
        ? theme?.typography?.fontWeightRegular
        : theme?.typography?.fontWeightMedium,
  };
}

export default function LanguageSwitcher() {
  const theme = useTheme();
  const [languageName, setLanguageName] = React.useState(["English"]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setLanguageName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div>
      <FormControl sx={{ m: 1, width: 300, borderRadius: "6px" }}>
        <InputLabel>Select language</InputLabel>
        <Select
          sx={{ borderRadius: "6px" }}
          disabled
          // select
          // displayEmpty
          value={languageName}
          onChange={handleChange}
          IconComponent={ExpandMoreRounded}
          // input={<OutlinedInput notched={true} />}
          // renderValue={(selected) => {
          //   if (selected.length === 0) {
          //     return <em>Select Language</em>;
          //   }

          //   return selected.join(", ");
          // }}

          label="Select language"
          // InputLabelProps={{ shrink: "false" }}
          // inputLabelProps={{ shrink: false }}
          inputProps={{ shrink: "false" }}
        >
          {/* <MenuItem disabled value="">
            <em>Select Language</em>
          </MenuItem> */}
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, languageName, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
