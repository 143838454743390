/*
 **	User name
 **	Created	5/25/2023
 **	index.ts
 */

import { handlePaginationData, handlePaginationLoadingData } from "../helper";

type UserAdditionalInfoType = {
  // vatNumber: string;
  // streetName: string;
  id: number;
  companyId: number;
  companyEmployeeRoleId: number;
  companyEmployeeLicenses: Array<any>;
  isInviteEmployeeSettingSkipped: boolean;
  noOfEmployeesCompany: number;
  emailAddress: string;
  userName: string;
  toolId: number;
  industryId: number;
  accountID: number;
  accountGUID: string;
  aboutMe: string | null;
  headlineText: string | null;
  isUpgradable: true;
  firstName: string;
  middleName: string | null;
  lastName: string;
  country: string;
  city: string;
  companyOverview: string | null;
  companyWebsite: string | null;
  companyTypeId: string | null;
  companyTypeName: string | null;
  companyOffices: string | null;
  companyFoundationDate: string | null;
  companySpecialityText: string | null;
  description: string | null;
  street: string;
  houseNumber: string;
  postCode: string;
  birthDate: string;
  gender: string;
  profession: string;
  profilePictureUrl: string;
  coverPictureUrl: string | null;
  contentType: string | null;
  profileType: 1 | 2 | 3;
  foundingDate: string;
  companyName: string | null;
  createdDate: string;
  modifiedDate: string;
  instagramUrl: string | null;
  facebookUrl: string | null;
  linkedInUrl: string | null;
  pinInterestUrl: string | null;
  createdBy: string;
  modifiedBy: string;
  languages: Array<any> | null;
  licenceTypeTd: number;
  licenceGuid: string;
  currentPlanId: number;
  subscriptionId: string;
  customerId: string | null;
  isRegisteredWithStripe: boolean;
  stripeId: string;
  daysLeftForPlanExpiry: number;
  isTwoDayPlanLeftPopupCancelled: boolean;
  isOneDayPlanLeftPopupCancelled: boolean;
  isExpiredPlanPopupCancelled: boolean;
  isWelcomePopup: boolean;
  vatNo: string;
  isTrialExpired: boolean;
  isAccountBlocked: boolean;
  isPlanOverride: boolean;
  accountPlanOverrride: Array<any>;
};

type initialStateType = {
  loading: boolean;
  data: any;
  error: any;
  userInfo: any;
  userAdditionalInfo: UserAdditionalInfoType;
  loginErrors: any;
  loginStatus: any;
  forgotErrors: any;
  forgotStatus: any;
  updateUserAccountInfoErrors: any;
  updateUserAccountInfoStatus: any;
  updateUserWithProfileErrors: any;
  updateUserWithProfileStatus: any;
  resetLoading: boolean;
  resetStatus: any;
  resetErrors: any;
};

const initState: initialStateType = {
  loading: false,
  data: null,
  error: null,
  userInfo: null,
  userAdditionalInfo: null,
  loginErrors: null,
  loginStatus: null,
  forgotErrors: null,
  forgotStatus: null,
  updateUserAccountInfoErrors: null,
  updateUserAccountInfoStatus: null,
  updateUserWithProfileErrors: null,
  updateUserWithProfileStatus: null,
  resetLoading: false,
  resetStatus: null,
  resetErrors: null,
};

const initStateUserType = {
  loading: false,
  data: null,
  companyData: null,
  error: null,
  designerData: null,
};

export const userRegisterReducerTypes = {
  REGISTER_USER: "REGISTER_USER",
  REQUEST_REGISTER: "REQUEST_REGISTER",
  RESPONSE_REGISTER: "RESPONSE_REGISTER",
  ERROR_REGISTER: "ERROR_REGISTER",
  CLEAR_REGISTER: "CLEAR_REGISTER",

  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_REQUEST: "LOGIN_USER_REQUEST",
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  LOGIN_USER_FAILURE: "LOGIN_USER_FAILURE",
  LOGIN_USER_CLEAR: "LOGIN_USER_CLEAR",

  LOGOUT_USER: "LOGOUT_USER",

  FETCH_USER_INFO: "FETCH_USER_INFO",
  FETCH_USER_INFO_REQUEST: "FETCH_USER_INFO_REQUEST",
  FETCH_USER_INFO_SUCCESS: "FETCH_USER_INFO_SUCCESS",
  FETCH_USER_INFO_FAILURE: "FETCH_USER_INFO_FAILURE",

  UPDATE_USER_ACCOUNT_INFO: "UPDATE_USER_ACCOUNT_INFO",
  UPDATE_USER_ACCOUNT_INFO_REQUEST: "UPDATE_USER_ACCOUNT_INFO_REQUEST",
  UPDATE_USER_ACCOUNT_INFO_SUCCESS: "UPDATE_USER_ACCOUNT_INFO_SUCCESS",
  UPDATE_USER_ACCOUNT_INFO_FAILURE: "UPDATE_USER_ACCOUNT_INFO_FAILURE",
  UPDATE_USER_ACCOUNT_INFO_CLEAR: "UPDATE_USER_ACCOUNT_INFO_CLEAR",

  UPDATE_USER_INFO_WITH_PROFILE: "UPDATE_USER_INFO_WITH_PROFILE",
  UPDATE_USER_INFO_WITH_PROFILE_REQUEST:
    "UPDATE_USER_INFO_WITH_PROFILE_REQUEST",
  UPDATE_USER_INFO_WITH_PROFILE_SUCCESS:
    "UPDATE_USER_INFO_WITH_PROFILE_SUCCESS",
  UPDATE_USER_INFO_WITH_PROFILE_FAILURE:
    "UPDATE_USER_INFO_WITH_PROFILE_INFO_FAILURE",
  UPDATE_USER_INFO_WITH_PROFILE_CLEAR: "UPDATE_USER_INFO_WITH_PROFILE_CLEAR",

  SET_USER_INFO: "SET_USER_INFO",
};
export const resetPasswordReducerTypes = {
  CHECK_RESET_TOKEN: "CHECK_RESET_TOKEN",
  CHECK_RESET_TOKEN_REQUEST: "CHECK_RESET_TOKEN_REQUEST",
  CHECK_RESET_TOKEN_SUCCESS: "CHECK_RESET_TOKEN_SUCCESS",
  CHECK_RESET_TOKEN_FAILURE: "CHECK_RESET_TOKEN_FAILURE",

  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_CLEAR: "RESET_PASSWORD_CLEAR",

  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  FORGOT_PASSWORD_CLEAR: "FORGOT_PASSWORD_CLEAR",

  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST",
  UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE",
};

export function registerReducer(state = initState, action): initialStateType {
  switch (action.type) {
    case userRegisterReducerTypes.REQUEST_REGISTER:
      return { ...state, data: null, error: null, loading: true };
    case userRegisterReducerTypes.RESPONSE_REGISTER:
      return { ...state, error: null, loading: false, data: action.payload };
    case userRegisterReducerTypes.ERROR_REGISTER:
      return { ...state, loading: false, data: null, error: action.payload };
    case userRegisterReducerTypes.CLEAR_REGISTER:
      return { ...state, loading: false, data: null, error: null };

    case userRegisterReducerTypes.SET_USER_INFO:
      return { ...state, userInfo: action.payload };
    case userRegisterReducerTypes.FETCH_USER_INFO_SUCCESS:
      return { ...state, userAdditionalInfo: action.payload };
    case userRegisterReducerTypes.FETCH_USER_INFO:
      return {
        ...state,
        updateUserAccountInfoStatus: null,
        updateUserAccountInfoErrors: null,
      };

    case userRegisterReducerTypes.LOGIN_USER_REQUEST:
      return { ...state, loginErrors: null, loginStatus: "REQUEST" };
    case userRegisterReducerTypes.LOGIN_USER_FAILURE:
      return { ...state, loginErrors: action.payload, loginStatus: "FAILURE" };
    case userRegisterReducerTypes.LOGIN_USER_SUCCESS:
      return { ...state, loginStatus: "SUCCESS" };
    case userRegisterReducerTypes.LOGIN_USER_CLEAR:
      return { ...state, loginErrors: null, loginStatus: "CLEAR" };

    case userRegisterReducerTypes.UPDATE_USER_ACCOUNT_INFO_REQUEST:
      return {
        ...state,
        updateUserAccountInfoErrors: null,
        updateUserAccountInfoStatus: "REQUEST",
      };
    case userRegisterReducerTypes.UPDATE_USER_ACCOUNT_INFO_FAILURE:
      return {
        ...state,
        updateUserAccountInfoErrors: action.payload,
        updateUserAccountInfoStatus: "FAILURE",
      };
    case userRegisterReducerTypes.UPDATE_USER_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        updateUserAccountInfoStatus: "SUCCESS",
        userAdditionalInfo: action.payload,
      };
    case userRegisterReducerTypes.UPDATE_USER_ACCOUNT_INFO_CLEAR:
      return {
        ...state,
        updateUserAccountInfoStatus: "CLEAR",
      };

    case userRegisterReducerTypes.UPDATE_USER_INFO_WITH_PROFILE_REQUEST:
      return {
        ...state,
        updateUserWithProfileErrors: null,
        updateUserWithProfileStatus: "REQUEST",
      };
    case userRegisterReducerTypes.UPDATE_USER_INFO_WITH_PROFILE_FAILURE:
      return {
        ...state,
        updateUserWithProfileErrors: action.payload,
        updateUserWithProfileStatus: "FAILURE",
      };
    case userRegisterReducerTypes.UPDATE_USER_INFO_WITH_PROFILE_SUCCESS:
      return {
        ...state,
        updateUserWithProfileStatus: "SUCCESS",
        userAdditionalInfo: action.payload,
      };
    case userRegisterReducerTypes.UPDATE_USER_INFO_WITH_PROFILE_CLEAR:
      return {
        ...state,
        userAdditionalInfo: null,
        updateUserWithProfileStatus: "CLEAR",
      };

    case userRegisterReducerTypes.LOGOUT_USER:
      return { ...initState };

    default:
      return state;
  }
}

export const checkUserNameReducerTypes = {
  CHECK_USERNAME: "CHECK_USERNAME",
  REQUEST_CHECKUSER: "REQUEST_CHECKUSER",
  RESPONSE_CHECKUSER: "RESPONSE_CHECKUSER",
  ERROR_CHECKUSER: "ERROR_CHECKUSER",
};

export function usernameReducer(state = initState, action) {
  switch (action.type) {
    case checkUserNameReducerTypes.REQUEST_CHECKUSER:
      return { ...state, loading: true };
    case checkUserNameReducerTypes.RESPONSE_CHECKUSER:
      return { loading: false, data: action.payload, error: null };
    case checkUserNameReducerTypes.ERROR_CHECKUSER:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export const checkEmailReducerTypes = {
  CHECK_EMAIL: "CHECK_EMAIL",
  REQUEST_CHECKEMAIL: "REQUEST_CHECKEMAIL",
  RESPONSE_CHECKEMAIL: "RESPONSE_CHECKEMAIL",
  ERROR_CHECKEMAIL: "ERROR_CHECKEMAIL",
  CLEAR_CHECKEMAIL: "CLEAR_CHECKEMAIL",
};

export function userEmailReducer(state = initState, action) {
  switch (action.type) {
    case checkEmailReducerTypes.REQUEST_CHECKEMAIL:
      return { ...state, loading: true };
    case checkEmailReducerTypes.RESPONSE_CHECKEMAIL:
      return { loading: false, data: action.payload, error: null };
    case checkEmailReducerTypes.ERROR_CHECKEMAIL:
      return { loading: false, data: null, error: action.payload };
    case checkEmailReducerTypes.CLEAR_CHECKEMAIL:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

export function resetPasswordReducer(state = initState, action) {
  switch (action.type) {
    case resetPasswordReducerTypes.CHECK_RESET_TOKEN_REQUEST:
      return {
        ...state,
        data: null,
        resetStatus: "REQUEST",
        resetLoading: true,
      };
    case resetPasswordReducerTypes.CHECK_RESET_TOKEN_FAILURE:
      return {
        ...state,
        data: action.payload,
        resetStatus: "FAILURE",
        resetLoading: false,
      };
    case resetPasswordReducerTypes.CHECK_RESET_TOKEN_SUCCESS:
      return {
        ...state,
        resetStatus: "SUCCESS",
        data: action.payload,
        resetLoading: false,
      };

    case resetPasswordReducerTypes.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetStatus: resetPasswordReducerTypes.RESET_PASSWORD_REQUEST,
        data: action.payload,
        resetLoading: false,
      };
    case resetPasswordReducerTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetStatus: resetPasswordReducerTypes.RESET_PASSWORD_SUCCESS,
        data: action.payload,
        resetLoading: false,
      };
    case resetPasswordReducerTypes.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        resetStatus: resetPasswordReducerTypes.RESET_PASSWORD_FAILURE,
        data: action.payload,
        resetLoading: false,
      };
    case resetPasswordReducerTypes.RESET_PASSWORD_CLEAR:
      return {
        ...state,
        resetStatus: resetPasswordReducerTypes.RESET_PASSWORD_CLEAR,
        data: null,
        resetLoading: false,
      };

    case resetPasswordReducerTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        forgotErrors: null,
        forgotStatus: resetPasswordReducerTypes.FORGOT_PASSWORD_REQUEST,
      };
    case resetPasswordReducerTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        forgotErrors: action.payload,
        forgotStatus: resetPasswordReducerTypes.FORGOT_PASSWORD_FAILURE,
      };
    case resetPasswordReducerTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotErrors: action.payload,
        forgotStatus: resetPasswordReducerTypes.FORGOT_PASSWORD_SUCCESS,
      };

    case resetPasswordReducerTypes.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        forgotErrors: null,
        forgotStatus: resetPasswordReducerTypes.UPDATE_PASSWORD_REQUEST,
      };
    case resetPasswordReducerTypes.UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        forgotErrors: action.payload,
        forgotStatus: resetPasswordReducerTypes.UPDATE_PASSWORD_FAILURE,
      };
    case resetPasswordReducerTypes.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotErrors: action.payload,
        forgotStatus: resetPasswordReducerTypes.UPDATE_PASSWORD_SUCCESS,
      };

    case resetPasswordReducerTypes.FORGOT_PASSWORD_CLEAR:
    default:
      return state;
  }
}

export const userTypeReducerTypes = {
  COMPANY_LIST: "COMPANY_LIST",
  REQUEST_COMPANY_LIST: "REQUEST_COMPANY_LIST",
  RESPONSE_COMPANY_LIST: "RESPONSE_COMPANY_LIST",
  ERROR_COMPANY_LIST: "ERROR_COMPANY_LIST",
  CLEAR_COMPANY_LIST: "CLEAR_COMPANY_LIST",

  DESIGNERS_LIST: "DESIGNERS_LIST",
  REQUEST_DESIGNERS_LIST: "REQUEST_DESIGNERS_LIST",
  RESPONSE_DESIGNERS_LIST: "RESPONSE_DESIGNERS_LIST",
  ERROR_DESIGNERS_LIST: "ERROR_DESIGNERS_LIST",

  DESIGNERS_DETAILS: "DESIGNERS_DETAILS",
  REQUEST_DESIGNERS_DETAILS: "REQUEST_DESIGNERS_DETAILS",
  RESPONSE_DESIGNERS_DETAILS: "RESPONSE_DESIGNERS_DETAILS",
  ERROR_DESIGNERS_DETAILS: "ERROR_DESIGNERS_DETAILS",

  FILTERED_DESIGNERS: "FILTERED_DESIGNERS",
  REQUEST_FILTERED_DESIGNERS: "REQUEST_FILTERED_DESIGNERS",
  RESPONSE_FILTERED_DESIGNERS: "RESPONSE_FILTERED_DESIGNERS",
  ERROR_FILTERED_DESIGNERS: "ERROR_FILTERED_DESIGNERS",

  FILTERED_YOUNG_DESIGNERS: "FILTERED_YOUNG_DESIGNERS",
  REQUEST_FILTERED_YOUNG_DESIGNERS: "REQUEST_FILTERED_YOUNG_DESIGNERS",
  RESPONSE_FILTERED_YOUNG_DESIGNERS: "RESPONSE_FILTERED_YOUNG_DESIGNERS",
  ERROR_FILTERED_YOUNG_DESIGNERS: "ERROR_FILTERED_YOUNG_DESIGNERS",
};
export function userTypeReducer(state = initStateUserType, action) {
  switch (action.type) {
    //Filtered Young designers
    case userTypeReducerTypes.REQUEST_FILTERED_YOUNG_DESIGNERS:
      return {
        ...state,
        loading: true,
        type: userTypeReducerTypes.REQUEST_FILTERED_YOUNG_DESIGNERS,
      };

    case userTypeReducerTypes.RESPONSE_FILTERED_YOUNG_DESIGNERS: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "accountId",
      });

      return {
        ...state,
        loading: false,
        data: newData,
        error: null,
        type: userTypeReducerTypes.RESPONSE_FILTERED_YOUNG_DESIGNERS,
      };
    }

    case userTypeReducerTypes.ERROR_FILTERED_YOUNG_DESIGNERS:
      return {
        ...state,
        loading: false,
        error: action.payload,
        type: userTypeReducerTypes.ERROR_FILTERED_YOUNG_DESIGNERS,
      };

    //

    //Filtered designers
    case userTypeReducerTypes.REQUEST_FILTERED_DESIGNERS:
      return {
        ...state,
        loading: true,
        type: userTypeReducerTypes.REQUEST_FILTERED_DESIGNERS,
      };

    case userTypeReducerTypes.RESPONSE_FILTERED_DESIGNERS: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "accountId",
      });

      return {
        ...state,
        loading: false,
        data: newData,
        error: null,
        type: userTypeReducerTypes.RESPONSE_FILTERED_DESIGNERS,
      };
    }

    case userTypeReducerTypes.ERROR_FILTERED_DESIGNERS:
      return {
        ...state,
        loading: false,
        error: action.payload,
        type: userTypeReducerTypes.ERROR_FILTERED_DESIGNERS,
      };

    //
    case userTypeReducerTypes.CLEAR_COMPANY_LIST:
      return {
        ...initStateUserType,
        type: userTypeReducerTypes.CLEAR_COMPANY_LIST,
      };
    case userTypeReducerTypes.REQUEST_COMPANY_LIST:
      return {
        ...state,
        loading: true,
        companyData: [],
        type: userTypeReducerTypes.REQUEST_COMPANY_LIST,
      };
    case userTypeReducerTypes.RESPONSE_COMPANY_LIST:
      return {
        ...state,
        loading: false,
        companyData: action.payload,
        error: null,
        type: userTypeReducerTypes.RESPONSE_COMPANY_LIST,
      };
    case userTypeReducerTypes.ERROR_COMPANY_LIST:
      return {
        ...state,
        loading: false,
        companyData: null,
        error: action.payload,
        type: userTypeReducerTypes.ERROR_COMPANY_LIST,
      };

    case userTypeReducerTypes.REQUEST_DESIGNERS_LIST:
      return {
        ...state,
        loading: true,
        type: userTypeReducerTypes.REQUEST_DESIGNERS_LIST,
      };

    case userTypeReducerTypes.RESPONSE_DESIGNERS_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "accountId",
      });

      return {
        ...state,
        loading: false,
        data: newData,
        error: null,
        type: userTypeReducerTypes.RESPONSE_DESIGNERS_LIST,
      };
    }

    case userTypeReducerTypes.ERROR_DESIGNERS_LIST:
      return {
        ...state,
        loading: false,
        error: action.payload,
        type: userTypeReducerTypes.ERROR_DESIGNERS_LIST,
      };

    case saveFollowerCountTypes.RESPONSE_SAVE_FOLLOW_COUNTER: {
      const { data } = state;

      const index = data.findIndex(
        (item) => item.id === action.payload.accountIdToFollow
      );

      if (index > -1) {
        data[index] = {
          ...data[index],
          isFollowedByMe: action.payload.isFollow,
        };
      }

      return { ...state, data: [...data] };
    }

    case userTypeReducerTypes.REQUEST_DESIGNERS_DETAILS:
      return {
        ...state,
        loading: true,
        designerData: null,
        type: userTypeReducerTypes.REQUEST_DESIGNERS_DETAILS,
      };
    case userTypeReducerTypes.RESPONSE_DESIGNERS_DETAILS:
      return {
        ...state,
        loading: false,
        designerData: action.payload,
        error: null,
        type: userTypeReducerTypes.RESPONSE_DESIGNERS_DETAILS,
      };
    case userTypeReducerTypes.ERROR_DESIGNERS_DETAILS:
      return {
        ...state,
        loading: false,
        designerData: null,
        error: action.payload,
        type: userTypeReducerTypes.ERROR_DESIGNERS_DETAILS,
      };

    default:
      return state;
  }
}

export const userLatestDesignersTypeReducerTypes = {
  LATEST_DESIGNERS_LIST: "LATEST_DESIGNERS_LIST",
  REQUEST_LATEST_DESIGNERS_LIST: "REQUEST_LATEST_DESIGNERS_LIST",
  RESPONSE_LATEST_DESIGNERS_LIST: "RESPONSE_LATEST_DESIGNERS_LIST",
  ERROR_LATEST_DESIGNERS_LIST: "ERROR_LATEST_DESIGNERS_LIST",
};

export function userLatestDesignersTypeReducer(
  state = initStateUserType,
  action
) {
  switch (action.type) {
    case userLatestDesignersTypeReducerTypes.REQUEST_LATEST_DESIGNERS_LIST:
      return {
        ...state,
        loading: true,
        type: userLatestDesignersTypeReducerTypes.REQUEST_LATEST_DESIGNERS_LIST,
      };
    case userLatestDesignersTypeReducerTypes.RESPONSE_LATEST_DESIGNERS_LIST:
      return {
        loading: false,
        data: action.payload,
        error: null,
        type: userLatestDesignersTypeReducerTypes.RESPONSE_LATEST_DESIGNERS_LIST,
      };
    case userLatestDesignersTypeReducerTypes.ERROR_LATEST_DESIGNERS_LIST:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: userLatestDesignersTypeReducerTypes.ERROR_LATEST_DESIGNERS_LIST,
      };
    default:
      return state;
  }
}

export const userYoungDesignersTypeReducerTypes = {
  YOUNG_DESIGNERS_LIST: "YOUNG_DESIGNERS_LIST",
  REQUEST_YOUNG_DESIGNERS_LIST: "REQUEST_YOUNG_DESIGNERS_LIST",
  RESPONSE_YOUNG_DESIGNERS_LIST: "RESPONSE_YOUNG_DESIGNERS_LIST",
  ERROR_YOUNG_DESIGNERS_LIST: "ERROR_YOUNG_DESIGNERS_LIST",
};

export function userYoungDesignersTypeReducerTypesReducer(
  state = initStateUserType,
  action
) {
  switch (action.type) {
    case userYoungDesignersTypeReducerTypes.REQUEST_YOUNG_DESIGNERS_LIST:
      return {
        ...state,
        loading: true,
        type: userYoungDesignersTypeReducerTypes.REQUEST_YOUNG_DESIGNERS_LIST,
      };
    case userYoungDesignersTypeReducerTypes.RESPONSE_YOUNG_DESIGNERS_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "accountId",
      });

      return {
        loading: false,
        data: newData,
        error: null,
        type: userYoungDesignersTypeReducerTypes.RESPONSE_YOUNG_DESIGNERS_LIST,
      };
    }

    case userYoungDesignersTypeReducerTypes.ERROR_YOUNG_DESIGNERS_LIST:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: userYoungDesignersTypeReducerTypes.ERROR_YOUNG_DESIGNERS_LIST,
      };
    case saveFollowerCountTypes.RESPONSE_SAVE_FOLLOW_COUNTER: {
      const { data } = state;

      const index = data.findIndex(
        (item) => item.id === action.payload.accountIdToFollow
      );

      if (index > -1) {
        data[index] = {
          ...data[index],
          isFollowedByMe: action.payload.isFollow,
        };
      }

      return { ...state, data: [...data] };
    }
    default:
      return state;
  }
}

export const followersDesignerTypes = {
  FOLLOWERS_DESIGNERS_LIST: "FOLLOWERS_DESIGNERS_LIST",
  REQUEST_FOLLOWERS_DESIGNERS_LIST: "REQUEST_FOLLOWERS_DESIGNERS_LIST",
  RESPONSE_FOLLOWERS_DESIGNERS_LIST: "RESPONSE_FOLLOWERS_DESIGNERS_LIST",
  ERROR_FOLLOWERS_DESIGNERS_LIST: "ERROR_FOLLOWERS_DESIGNERS_LIST",
  CLEAR_FOLLOWERS_DESIGNERS_LIST: "CLEAR_FOLLOWERS_DESIGNERS_LIST",
};

export function followersDesignerReducer(state = initStateUserType, action) {
  switch (action.type) {
    case followersDesignerTypes.REQUEST_FOLLOWERS_DESIGNERS_LIST:
      return {
        ...state,
        loading: true,
        type: followersDesignerTypes.REQUEST_FOLLOWERS_DESIGNERS_LIST,
      };
    case followersDesignerTypes.RESPONSE_FOLLOWERS_DESIGNERS_LIST:
      return {
        loading: false,
        data: action.payload,
        error: null,
        type: followersDesignerTypes.RESPONSE_FOLLOWERS_DESIGNERS_LIST,
      };
    case followersDesignerTypes.ERROR_FOLLOWERS_DESIGNERS_LIST:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: followersDesignerTypes.ERROR_FOLLOWERS_DESIGNERS_LIST,
      };
    case followersDesignerTypes.CLEAR_FOLLOWERS_DESIGNERS_LIST:
      return {
        loading: false,
        data: null,
        error: null,
        type: followersDesignerTypes.CLEAR_FOLLOWERS_DESIGNERS_LIST,
      };
    case saveFollowerCountTypes.RESPONSE_SAVE_FOLLOW_COUNTER: {
      const {
        data: { data },
      } = state;

      const index = data.findIndex(
        (item) => item.id === action.payload.accountIdToFollow
      );

      if (index > -1) {
        data[index] = {
          ...data[index],
          isFollowedByMe: action.payload.isFollow,
        };
      }

      return { ...state, data: { ...state.data, data: [...data] } };
    }
    default:
      return state;
  }
}

export const followingsDesignerTypes = {
  FOLLOWINGS_DESIGNERS_LIST: "FOLLOWINGS_DESIGNERS_LIST",
  REQUEST_FOLLOWINGS_DESIGNERS_LIST: "REQUEST_FOLLOWINGS_DESIGNERS_LIST",
  RESPONSE_FOLLOWINGS_DESIGNERS_LIST: "RESPONSE_FOLLOWINGS_DESIGNERS_LIST",
  ERROR_FOLLOWINGS_DESIGNERS_LIST: "ERROR_FOLLOWINGS_DESIGNERS_LIST",
  CLEAR_FOLLOWINGS_DESIGNERS_LIST: "CLEAR_FOLLOWINGS_DESIGNERS_LIST",
};

export function followingsDesignerReducer(state = initStateUserType, action) {
  switch (action.type) {
    case followingsDesignerTypes.REQUEST_FOLLOWINGS_DESIGNERS_LIST:
      return {
        ...state,
        loading: true,
        type: followingsDesignerTypes.REQUEST_FOLLOWINGS_DESIGNERS_LIST,
      };
    case followingsDesignerTypes.RESPONSE_FOLLOWINGS_DESIGNERS_LIST:
      return {
        loading: false,
        data: action.payload,
        error: null,
        type: followingsDesignerTypes.RESPONSE_FOLLOWINGS_DESIGNERS_LIST,
      };
    case followingsDesignerTypes.ERROR_FOLLOWINGS_DESIGNERS_LIST:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: followingsDesignerTypes.ERROR_FOLLOWINGS_DESIGNERS_LIST,
      };
    case followingsDesignerTypes.CLEAR_FOLLOWINGS_DESIGNERS_LIST:
      return {
        loading: false,
        data: null,
        error: null,
        type: followingsDesignerTypes.CLEAR_FOLLOWINGS_DESIGNERS_LIST,
      };

    case saveFollowerCountTypes.RESPONSE_SAVE_FOLLOW_COUNTER: {
      const {
        data: { data },
      } = state;

      const index = data.findIndex(
        (item) => item.id === action.payload.accountIdToFollow
      );

      if (index > -1) {
        data[index] = {
          ...data[index],
          isFollowedByMe: action.payload.isFollow,
        };
      }

      return { ...state, data: { ...state.data, data: [...data] } };
    }
    default:
      return state;
  }
}

export const saveFollowerCountTypes = {
  SAVE_FOLLOW_COUNTER: "SAVE_FOLLOW_COUNTER",
  REQUEST_SAVE_FOLLOW_COUNTER: "REQUEST_SAVE_FOLLOW_COUNTER",
  RESPONSE_SAVE_FOLLOW_COUNTER: "RESPONSE_SAVE_FOLLOW_COUNTER",
  ERROR_SAVE_FOLLOW_COUNTER: "ERROR_SAVE_FOLLOW_COUNTER",
};

export function saveFollowerCountReducer(state = initStateUserType, action) {
  switch (action.type) {
    case saveFollowerCountTypes.REQUEST_SAVE_FOLLOW_COUNTER:
      return {
        ...state,
        loading: true,
        type: saveFollowerCountTypes.REQUEST_SAVE_FOLLOW_COUNTER,
      };
    case saveFollowerCountTypes.RESPONSE_SAVE_FOLLOW_COUNTER:
      return {
        loading: false,
        data: action.payload,
        error: null,
        type: saveFollowerCountTypes.RESPONSE_SAVE_FOLLOW_COUNTER,
      };
    case saveFollowerCountTypes.ERROR_SAVE_FOLLOW_COUNTER:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: saveFollowerCountTypes.ERROR_SAVE_FOLLOW_COUNTER,
      };
    default:
      return state;
  }
}

export const getMutualFollowersTypes = {
  MUTUAL_FOLLOWERS: "MUTUAL_FOLLOWERS",
  REQUEST_MUTUAL_FOLLOWERS: "REQUEST_MUTUAL_FOLLOWERS",
  RESPONSE_MUTUAL_FOLLOWERS: "RESPONSE_MUTUAL_FOLLOWERS",
  ERROR_MUTUAL_FOLLOWERS: "ERROR_MUTUAL_FOLLOWERS",
};

export function mutualFollowersReducer(state = initStateUserType, action) {
  switch (action.type) {
    case getMutualFollowersTypes.REQUEST_MUTUAL_FOLLOWERS: {
      const newData = handlePaginationLoadingData({
        payload: action.payload,
        oldData: state?.data,
      });

      return {
        ...state,
        loading: true,
        data: newData,
        type: getMutualFollowersTypes.REQUEST_MUTUAL_FOLLOWERS,
      };
    }
    case getMutualFollowersTypes.RESPONSE_MUTUAL_FOLLOWERS: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "productLibraryId",
      });

      return {
        ...state,
        loading: false,
        data: newData,
        error: null,
        type: getMutualFollowersTypes.RESPONSE_MUTUAL_FOLLOWERS,
      };
    }
    case getMutualFollowersTypes.ERROR_MUTUAL_FOLLOWERS:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: getMutualFollowersTypes.ERROR_MUTUAL_FOLLOWERS,
      };
    default:
      return state;
  }
}

const initStateUser2Type = {
  loading: false,
  data: null,
  type: null,
  error: null,
  customerData: null,
};

export const saveStripeCustomerIdTypes = {
  SAVE_STRIPE_CUSTOMER_ID: "SAVE_STRIPE_CUSTOMER_ID",
  REQUEST_SAVE_STRIPE_CUSTOMER_ID: "REQUEST_SAVE_STRIPE_CUSTOMER_ID",
  RESPONSE_SAVE_STRIPE_CUSTOMER_ID: "RESPONSE_SAVE_STRIPE_CUSTOMER_ID",
  ERROR_SAVE_STRIPE_CUSTOMER_ID: "ERROR_SAVE_STRIPE_CUSTOMER_ID",

  SAVE_CURRENT_PLAN_DETAILS: "SAVE_CURRENT_PLAN_DETAILS",
  REQUEST_SAVE_CURRENT_PLAN_DETAILS: "REQUEST_SAVE_CURRENT_PLAN_DETAILS",
  RESPONSE_SAVE_CURRENT_PLAN_DETAILS: "RESPONSE_SAVE_CURRENT_PLAN_DETAILS",
  ERROR_SAVE_CURRENT_PLAN_DETAILS: "ERROR_SAVE_CURRENT_PLAN_DETAILS",

  SAVE_STRIPE_ACCOUNT_ID: "SAVE_STRIPE_ACCOUNT_ID",
  REQUEST_SAVE_STRIPE_ACCOUNT_ID: "REQUEST_SAVE_STRIPE_ACCOUNT_ID",
  RESPONSE_SAVE_STRIPE_ACCOUNT_ID: "RESPONSE_SAVE_STRIPE_ACCOUNT_ID",
  ERROR_SAVE_STRIPE_ACCOUNT_ID: "ERROR_SAVE_STRIPE_ACCOUNT_ID",

  SAVE_STRIPE_ACCOUNT_ID_REGISTERED: "SAVE_STRIPE_ACCOUNT_ID_REGISTERED",
  REQUEST_SAVE_STRIPE_ACCOUNT_ID_REGISTERED:
    "REQUEST_SAVE_STRIPE_ACCOUNT_ID_REGISTERED",
  RESPONSE_SAVE_STRIPE_ACCOUNT_ID_REGISTERED:
    "RESPONSE_SAVE_STRIPE_ACCOUNT_ID_REGISTERED",
  ERROR_SAVE_STRIPE_ACCOUNT_ID_REGISTERED:
    "ERROR_SAVE_STRIPE_ACCOUNT_ID_REGISTERED",

  SAVE_STRIPE_CUSTOMER_ID_BY_SELLER: "SAVE_STRIPE_CUSTOMER_ID_BY_SELLER",
  REQUEST_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER:
    "REQUEST_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER",
  RESPONSE_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER:
    "RESPONSE_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER",
  ERROR_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER:
    "ERROR_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER",

  GET_STRIPE_CUSTOMER_ID_BY_SELLER: "GET_STRIPE_CUSTOMER_ID_BY_SELLER",
  REQUEST_GET_STRIPE_CUSTOMER_ID_BY_SELLER:
    "REQUEST_GET_STRIPE_CUSTOMER_ID_BY_SELLER",
  RESPONSE_GET_STRIPE_CUSTOMER_ID_BY_SELLER:
    "RESPONSE_GET_STRIPE_CUSTOMER_ID_BY_SELLER",
  ERROR_GET_STRIPE_CUSTOMER_ID_BY_SELLER:
    "ERROR_GET_STRIPE_CUSTOMER_ID_BY_SELLER",
};

const initStateUser3Type = {
  ...initStateUser2Type,
  loading: true,
};

export function saveStripeCustomerIdReducer(
  state = initStateUser3Type,
  action
) {
  switch (action.type) {
    case saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_CUSTOMER_ID:
      return {
        ...state,
        loading: true,
        type: saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_CUSTOMER_ID,
      };
    case saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_CUSTOMER_ID:
      return {
        loading: false,
        data: action.payload,
        error: null,
        type: saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_CUSTOMER_ID,
      };
    case saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_CUSTOMER_ID:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_CUSTOMER_ID,
      };

    case saveStripeCustomerIdTypes.REQUEST_SAVE_CURRENT_PLAN_DETAILS:
      return {
        ...state,
        loading: true,
        type: saveStripeCustomerIdTypes.REQUEST_SAVE_CURRENT_PLAN_DETAILS,
      };
    case saveStripeCustomerIdTypes.RESPONSE_SAVE_CURRENT_PLAN_DETAILS:
      return {
        loading: false,
        data: action.payload,
        error: null,
        type: saveStripeCustomerIdTypes.RESPONSE_SAVE_CURRENT_PLAN_DETAILS,
      };
    case saveStripeCustomerIdTypes.ERROR_SAVE_CURRENT_PLAN_DETAILS:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: saveStripeCustomerIdTypes.ERROR_SAVE_CURRENT_PLAN_DETAILS,
      };

    case saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_ACCOUNT_ID:
      return {
        ...state,
        loading: true,
        type: saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_ACCOUNT_ID,
      };
    case saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_ACCOUNT_ID:
      return {
        loading: false,
        data: action.payload,
        error: null,
        type: saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_ACCOUNT_ID,
      };
    case saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_ACCOUNT_ID:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_ACCOUNT_ID,
      };

    case saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_ACCOUNT_ID_REGISTERED:
      return {
        ...state,
        loading: true,
        type: saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_ACCOUNT_ID_REGISTERED,
      };
    case saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_ACCOUNT_ID_REGISTERED:
      return {
        loading: false,
        data: action.payload,
        error: null,
        type: saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_ACCOUNT_ID_REGISTERED,
      };
    case saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_ACCOUNT_ID_REGISTERED:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_ACCOUNT_ID_REGISTERED,
      };

    case saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER:
      return {
        ...state,
        loading: true,
        type: saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER,
      };
    case saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER:
      return {
        loading: false,
        data: action.payload,
        error: null,
        type: saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER,
      };
    case saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER,
      };

    case saveStripeCustomerIdTypes.REQUEST_GET_STRIPE_CUSTOMER_ID_BY_SELLER:
      return {
        ...state,
        loading: true,
        type: saveStripeCustomerIdTypes.REQUEST_GET_STRIPE_CUSTOMER_ID_BY_SELLER,
      };
    case saveStripeCustomerIdTypes.RESPONSE_GET_STRIPE_CUSTOMER_ID_BY_SELLER:
      return {
        loading: false,
        customerData: action.payload,
        error: null,
        type: saveStripeCustomerIdTypes.RESPONSE_GET_STRIPE_CUSTOMER_ID_BY_SELLER,
      };
    case saveStripeCustomerIdTypes.ERROR_GET_STRIPE_CUSTOMER_ID_BY_SELLER:
      return {
        loading: false,
        data: null,
        error: action.payload,
        type: saveStripeCustomerIdTypes.ERROR_GET_STRIPE_CUSTOMER_ID_BY_SELLER,
      };

    default:
      return state;
  }
}
