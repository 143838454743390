import { useEffect, useState } from "react";
import { Box, Chip, Modal, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@mui/styles";
import ReadMore from "app/components/UIFormFields/readMore";
import { LightModeCross, NightModeCross } from "app/components/Logo/logos";
import { useProductDetailsStyles } from "../../../../styles/productDetails";

const useRightPannelStyle = makeStyles((theme) => ({
  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
    zIndex: 100,
  },
  attechment: {
    width: "100%",
    height: "320px",
    display: "flex",
    backgroundColor: "#ebe3ff",
    borderRadius: "6px",
    position: "relative",
    justifyContent: "center",
  },
  attechmentTitle: {
    display: "flex",
    margin: "17px 16px 0px 16px",
    justifyContent: "space-between",
  },
  textFields: {
    marginTop: "16px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  container: {
    width: "100%",
    padding: "0px 0px 0px 34px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },

  titleStyle: {
    color: "#a49bbe",
    fontSize: "13px",
    fontWeight: "bold",
    marginBottom: "6px",
    display: "flex",
  },
  title: {
    fontSize: "13px !important",
    fontWeight: "bold !important",
    color: "#a49bbe",
    margin: "16px 0px 8px 0px !important",
    display: "flex",
  },
  clickBox: {
    display: "flex",
    flexDirection: "row !important",
    flexWrap: "wrap",
  },
  descriptionInfo: {
    color: "#2b2639",
    textAlign: "justify",
  },
  descriptionSeeMore: {
    fontSize: "15px",
    textAlign: "left",
    color: "#7a48ff",
    paddingTop: "6px",
  },
}));

export default function RightPannelInfoPreview({ selectedData, is3D }) {
  const theme = useTheme();
  const classes = useRightPannelStyle();
  const classes3D = useProductDetailsStyles();
  const categoryTypes = useSelector((state) => state.category);
  const filterTypes = useSelector((state) => state.filterTypes);
  const toolTypes = filterTypes.toolTypeList;
  const productTag = useSelector((state) => state.tags);
  const [previewData, setPreviewData] = useState(
    localStorage.getItem("editProductData")
  );

  const {
    ReactComponent: Minimize,
  } = require("../../../../assets/icons/Minimize_Icon.svg");
  const {
    ReactComponent: Maximize,
  } = require("../../../../assets/icons/Maximise_Icon.svg");
  const [openFullSize, setOpenFullSize] = useState(false);

  const handleClickOpen = () => {
    setOpenFullSize(true);
  };

  const handleClose = () => {
    setOpenFullSize(false);
  };

  const returnToolValue = (item) =>
    toolTypes?.map((value) => {
      if (parseInt(item) === parseInt(value.toolDetailId))
        return value?.toolName;
    });

  const returnTagValue = (item) =>
    productTag?.data?.map((value) => {
      if (parseInt(item) === parseInt(value.productTagID))
        return value.productTagName;
    });
  const returnCategoryValue = (item) =>
    categoryTypes?.data?.map((value) => {
      if (parseInt(item) === parseInt(value.categoryID))
        return value.categoryName;
    });

  useEffect(() => {
    const editProductData = JSON.parse(localStorage.getItem("editProductData"));

    setPreviewData(editProductData);
  }, []);

  const productUploadData = JSON.parse(
    localStorage.getItem("productUploadData")
  );

  return (
    <>
      <div className={classes.container}>
        <Box className={classes.attechment}>
          {!is3D ? (
            <Box width={"100%"}>
              <Box
                sx={{
                  display: "flex",
                  position: "relative",

                  height: "320px",
                }}
              >
                <img
                  src={URL.createObjectURL(selectedData)}
                  alt="product"
                  style={{
                    height: "100%",
                    width: "100%",
                    border: "2px solid #ccc",
                    borderRadius: "6px",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    width: "34px",
                    height: "34px",
                    borderRadius: "50%",
                    bottom: "8px",
                    right: "8px",
                    cursor: "pointer",
                    backgroundColor: "#d4c4ff",
                  }}
                  onClick={handleClickOpen}
                >
                  <Maximize />
                </div>
              </Box>
            </Box>
          ) : (
            <Box width={"100%"}>
              <Box
                sx={{
                  display: "flex",
                  position: "relative",

                  height: "320px",
                }}
              >
                <model-viewer
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "2px solid #ccc",
                    borderRadius: "6px",
                    // margin: "0px 16px 0px 0px",
                  }}
                  src={URL.createObjectURL(selectedData)}
                  ios-src=""
                  alt="model name"
                  camera-controls
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  environment-image="neutral"
                  oncontextmenu="return false;"
                  autoplay
                ></model-viewer>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute",
                    width: "34px",
                    height: "34px",
                    borderRadius: "50%",
                    bottom: "8px",
                    right: "8px",
                    cursor: "pointer",
                    backgroundColor: "#d4c4ff",
                  }}
                  onClick={handleClickOpen}
                >
                  <Maximize />
                </div>
              </Box>
            </Box>
          )}
        </Box>
        <Box className={classes.textFields}>
          {!is3D && (
            <>
              {" "}
              <Typography className={classes.title}>Tools</Typography>
              <Box className={classes.clickBox}>
                {(
                  selectedData?.toolListDetails || productUploadData?.tools
                )?.map((value, index) => {
                  return (
                    <Chip
                      sx={{
                        background: "#ebe3ff",
                        color: "#7a48ff",
                        borderRadius: "6px",
                        fontSize: 15,
                        height: "34px !important",
                        marginRight: "8px",
                        marginBottom: "5px",
                      }}
                      key={index}
                      label={returnToolValue(value?.toolId)}
                    />
                  );
                })}
              </Box>
              <Box marginTop="16px">
                <p className={classes.titleStyle}>Description</p>

                <Box>
                  <ReadMore
                    maxViweLength={485}
                    textStyle={classes.descriptionInfo}
                    seeMoreStyle={classes.descriptionSeeMore}
                  >
                    {previewData?.description || productUploadData?.description}
                  </ReadMore>
                </Box>
              </Box>{" "}
            </>
          )}
          {/* <Typography className={classes.title}>Tags</Typography> */}
          {/* <Box className={classes.clickBox}>
            {selectedData?.tagListDetails?.map((value, index) => {
              return (
                <Chip
                  sx={{
                    background: "#ebe3ff",
                    color: "#7a48ff",
                    borderRadius: "6px",
                    fontSize: 15,
                    height: "34px !important",
                    marginRight: "8px",
                    marginBottom: "5px",
                  }}
                  key={index}
                  label={returnTagValue(value?.tagId)}
                />
              );
            })}
          </Box> */}
          {/* <Typography className={classes.title}>Categories</Typography>
          <Box className={classes.clickBox}>
            {selectedData?.categoryListDetails?.map((value, index) => {
              return (
                <Chip
                  sx={{
                    background: "#ebe3ff",
                    color: "#7a48ff",
                    borderRadius: "6px",
                    fontSize: 15,
                    height: "34px !important",
                    marginRight: "8px",
                    marginBottom: "5px",
                  }}
                  key={index}
                  label={returnCategoryValue(value?.categoryId)}
                />
              );
            })}
          </Box> */}

          {/* for 3D Modal */}
          {is3D && (
            <Box
              className={classes3D.rightBottomPreviewBox}
              style={{ width: "100%" }}
            >
              <Typography className={classes3D.userName}>File</Typography>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  3D formats
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  Autodesk FBX (fbx), Converted Format GLTF (.gltf), Converted
                  Format USDZ (.usdz)
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Converted Format
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Image formats
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  JPG, PNG
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  File size
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  21 MB
                </Typography>
              </Box>
              <Box className={classes3D.rightBottomBoundaryLine} />
              <Typography className={classes3D.userName}>Geometry</Typography>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Edges
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  Triangles 54.6k
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Vertices
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  31.4k
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Triangles
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Quads
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Polygons
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Objects in Scene
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.rightBottomBoundaryLine} />
              <Typography className={classes3D.userName}>Textures</Typography>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Texture Type
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  PBR
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Texture Count
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Materials
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  UV Map
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Vertex colors
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Colors
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Displacement
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Glossiness
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Metalness
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.rightBottomBoundaryLine} />
              <Typography className={classes3D.userName}>Animation</Typography>

              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Animations
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
              <Box className={classes3D.detailsUser}>
                <Typography
                  className={classes3D.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Rigged geometries
                </Typography>
                <Typography
                  className={`${classes3D.rightPanelDescription} ${classes3D.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  -
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </div>

      {/* fullSizeComponent */}

      <Modal open={openFullSize}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "768px",
            backgroundColor: "#ebe3ff",
          }}
        >
          <Box className={classes.closeBox}>
            {theme?.palette?.mode === "light" ? (
              <LightModeCross
                onClick={() => handleClose()}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <NightModeCross
                onClick={() => handleClose()}
                style={{ cursor: "pointer" }}
              />
            )}
          </Box>
          {!is3D ? (
            <>
              <img
                src={URL.createObjectURL(selectedData)}
                alt="product"
                style={{
                  height: "100%",
                  //   width: "100%",
                }}
              />
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  width: "34px",
                  height: "34px",
                  borderRadius: "50%",
                  bottom: "324px",
                  right: "24px",
                  cursor: "pointer",
                  backgroundColor: "#d4c4ff",
                  zIndex: 100,
                }}
                onClick={handleClose}
              >
                <Minimize />
              </Box>
            </>
          ) : (
            <>
              <model-viewer
                style={{
                  width: "100%",
                  height: "100%",
                  border: "2px solid #ccc",
                  borderRadius: "6px",
                  // margin: "0px 16px 0px 0px",
                }}
                src={URL.createObjectURL(selectedData)}
                ios-src=""
                alt="model name"
                camera-controls
                ar
                ar-modes="webxr scene-viewer quick-look"
                environment-image="neutral"
                oncontextmenu="return false;"
                autoplay
              ></model-viewer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  width: "34px",
                  height: "34px",
                  borderRadius: "50%",
                  bottom: "324px",
                  right: "8px",
                  cursor: "pointer",
                  backgroundColor: "#d4c4ff",
                  zIndex: 100,
                }}
                onClick={handleClickOpen}
              >
                <Maximize />
              </div>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
}
