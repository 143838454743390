const productEditNavInitialState = {
  productInfo: true,
  _3d: false,
  pattern: false,
  art: false,
  image: false,
  sketch: false,
  others: false,
  additionalInfo: false,
};

export const productEditNavBarType = {
  PRODUCT_INFO: "PRODUCT_INFO",
  _3D: "_3D",
  PATTERN: "PATTERN",
  ART_FILE: "ART_FILE",
  IMAGE_FILE: "IMAGE_FILE",
  SKETCH_FILE: "SKETCH_FILE",
  OTHER_FILE: "OTHER_FILE",
  ADDITINAL_INFO: "ADDITINAL_INFO",
};

export function productEditNavBarReducer(
  state = productEditNavInitialState,
  action
) {
  switch (action.type) {
    case productEditNavBarType.PRODUCT_INFO:
      return {
        productInfo: true,
        _3d: false,
        pattern: false,
        art: false,
        image: false,
        sketch: false,
        others: false,
        additionalInfo: false,
      };
    case productEditNavBarType._3D:
      return {
        productInfo: false,
        _3d: true,
        pattern: false,
        art: false,
        image: false,
        sketch: false,
        others: false,
        additionalInfo: false,
      };
    case productEditNavBarType.PATTERN:
      return {
        productInfo: false,
        _3d: false,
        pattern: true,
        art: false,
        image: false,
        sketch: false,
        others: false,
        additionalInfo: false,
      };
    case productEditNavBarType.ART_FILE:
      return {
        productInfo: false,
        _3d: false,
        pattern: false,
        art: true,
        image: false,
        sketch: false,
        others: false,
        additionalInfo: false,
      };
    case productEditNavBarType.IMAGE_FILE:
      return {
        productInfo: false,
        _3d: false,
        pattern: false,
        art: false,
        image: true,
        sketch: false,
        others: false,
        additionalInfo: false,
      };
    case productEditNavBarType.SKETCH_FILE:
      return {
        productInfo: false,
        _3d: false,
        pattern: false,
        art: false,
        image: false,
        sketch: true,
        others: false,
        additionalInfo: false,
      };
    case productEditNavBarType.OTHER_FILE:
      return {
        productInfo: false,
        _3d: false,
        pattern: false,
        art: false,
        image: false,
        sketch: false,
        others: true,
        additionalInfo: false,
      };

    case productEditNavBarType.ADDITINAL_INFO:
      return {
        productInfo: false,
        _3d: false,
        pattern: false,
        art: false,
        image: false,
        sketch: false,
        others: false,
        additionalInfo: true,
      };
    default:
      return state;
  }
}
