import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, FormHelperText, Typography } from "@mui/material";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch } from "react-redux";
import { useAppSelector } from "app/utils/hooks";

import { useNewsLetterStyles } from "../../../styles/NewsLetterStyle";
import { ComponentSuccess } from "../../components/Logo/logos";
import MessageModel from "../../components/Newsletter/messageModel";
import { GradientColoredButton } from "../../components/UIFormFields";
import { subscribeWithEmailTypes } from "../../redux/reducers/userReducer/newsLetter";
import { isValidEmail } from "../../utils/helper";

const useSubscribeBannerStyles = makeStyles((theme) => ({
  bannerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: "519px",
    borderRadius: "20px",
    opacity: 0.99,
    position: "relative",
    zIndex: 2,
    objectFit: "contain",
    backdropFilter: "blur(60px)",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    // backgroundImage:
    // "linear-gradient(112deg, rgba(255, 255, 255, 0.5) -3%, rgba(255, 255, 255, 0.5) 106%)",
  },
  mailImage: {},
  textField: { width: "442px", height: "54px" },
  actionButton: { marginLeft: "20px !important" },
  title: {
    color: "#2b2639",
    fontFamily: "Poppins",
    fontSize: "23px !important",
    fontWeight: "bold",
  },
  titleBox: {
    display: "flex",
    justifyContent: "flex-start",
  },
  inputBox: {
    display: "flex",
    justifyContent: "flex-start",
    padding: "20px 0px 0px 0px",
    flexDirection: "row",
  },
  box: {
    padding: "25px",
  },
  group_red_box: {
    position: "absolute",
    top: "31px",
    right: "63px",
    zIndex: -1,
  },
  frame_yellow_box: {
    position: "absolute",
    top: "113px",
    right: "51px",
    zIndex: -1,
  },
  group_2057: {
    position: "absolute",
    top: "400px",
    left: "60px",
    zIndex: -1,
  },
  group_2056: {
    position: "absolute",
    top: "304px",
    left: "65px",
    zIndex: -1,
  },
  union_horizontal: {
    position: "absolute",
    top: "24px",
    left: "24px",
    zIndex: -1,
  },
  union_vertical: {
    position: "absolute",
    bottom: "24px",
    right: "24px",
    zIndex: -1,
  },
  mainBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignItems: "center",
    textAlign: "center",
    width: "1300px !important",
  },
  button: {
    marginLeft: "20px",
  },
  [theme.breakpoints.down("md")]: {
    bannerBox: {
      // flexDirection: "column",
      // backgroundColor: "red",
      backgroundColor: "white",
      width: "100%",
      margin: "auto",
    },
    mainBox: { flexDirection: "column", width: "95% !important" },
    mailImage: { width: "227px", height: "195px" },
    inputBox: {
      flexDirection: "column",
      width: "100%",
    },
    textField: {
      width: "100%",
      height: "54px",
      marginBottom: "20px !important",
    },
    actionButton: { margin: "auto !important", width: "100% !important" },
    group_red_box: {
      top: "-30px",
      right: "-40px",
    },
    frame_yellow_box: {
      top: "100px",
      right: "-35px",
    },
    //
    group_2057: {
      display: "none",
    },
    group_2056: {
      display: "none",
    },
    union_horizontal: {
      top: "-10px",
      left: "-40px",
    },
    union_vertical: {
      display: "none",
    },
    box: { padding: "5px" },
  },
}));

export function SubscribeWithEmailSuccess(props) {
  const {
    handleOpen,
    clerStateData,
    // open,
    // setOpen,
    openPopUp,
    setPopUpStatus,
    status,
  } = props;
  const classes = useNewsLetterStyles();

  return (
    <Box>
      <MessageModel
        icon={<ComponentSuccess className={classes.icon} />}
        title="Thank you for your interest in the Newsletter!"
        subtitle={
          status !== null && status
            ? "We have sent a confirmation link to your email address"
            : "Confirmation Link has already been sent to your Email Address"
        }
        handleOpen={handleOpen}
        open={openPopUp}
        setOpen={setPopUpStatus}
        clearStates={clerStateData}
      />
    </Box>
  );
}

export default function SubscribeBanner() {
  const classes = useSubscribeBannerStyles();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const subscribeWithEmail = useAppSelector(
    (state) => state.subscribeWithEmail
  );

  const group_2045 = require("../../../assets/graphics/group_2045.webp");
  const group_red_box = require("../../../assets/graphics/group_red_box.png");
  const group_2057 = require("../../../assets/graphics/group_2057.png");
  const group_2056 = require("../../../assets/graphics/group_2056.png");
  const frame_yellow_box = require("../../../assets/graphics/frame_yellow_box.png");
  const union_horizontal = require("../../../assets/graphics/union_horizontal.png");
  const union_vertical = require("../../../assets/graphics/union_vertical.png");

  useEffect(() => {
    ValidatorForm.addValidationRule("validEmail", (value) => {
      if (!isValidEmail(value)) {
        return false;
      }
      return true;
    });

    // if (subscribeWithEmail.data) handleOpen();
  }, [subscribeWithEmail]);

  const [openPopUp, setPopUpStatus] = useState(false);

  const handleSubmit = () => {
    dispatch({
      type: subscribeWithEmailTypes.SUBSCRIBE_WITH_EMAIL,
      emailAddress: email,
    });

    setTimeout(() => setPopUpStatus(true), 500);
  };

  const clerStateData = () => {
    dispatch({
      type: subscribeWithEmailTypes.CLEAR_SUBSCRIBE_WITH_EMAIL,
    });
  };
  const handleOnChange = (e) => {
    setEmail(e.target.value);
    setEmailError(false);
  };

  return (
    <Box>
      {/* <Box className="newlatterback"></Box> */}
      <SubscribeWithEmailSuccess
        clerStateData={clerStateData}
        handleOpen={handleOpen}
        // setOpen={setOpen}
        open={open}
        openPopUp={openPopUp}
        setPopUpStatus={setPopUpStatus}
        status={subscribeWithEmail?.data}
      />
      <Box className={classes.bannerBox}>
        <img src={group_red_box} className={classes.group_red_box} />
        <img src={frame_yellow_box} className={classes.frame_yellow_box} />
        <img src={group_2057} className={classes.group_2057} />
        <img src={group_2056} className={classes.group_2056} />
        <img src={union_horizontal} className={classes.union_horizontal} />
        <img src={union_vertical} className={classes.union_vertical} />

        <Box className={classes.mainBox}>
          <img src={group_2045} className={classes.mailImage} />
          <Box className={classes.box}>
            <Box className={classes.titleBox}>
              <Typography variant="h4" className={classes.title}>
                <b>Subscribe to our Newsletter</b>
              </Typography>
            </Box>
            <ValidatorForm onSubmit={handleSubmit} className={classes.inputBox}>
              <TextValidator
                error={!!emailError}
                // style={{ width: "442px", height: "54px" }}
                className={classes.textField}
                label="Enter your email address"
                onChange={handleOnChange}
                name="emailAddress"
                value={email}
                validators={["required", "validEmail"]}
                errorMessages={[
                  "Please enter email address",
                  "Please enter a valid email address",
                ]}
                inputProps={{
                  autoComplete: "new-password",
                }}
              />
              <GradientColoredButton
                // style={{ color: "#fff", marginLeft: "20px" }}
                className={classes.actionButton}
                type="submit"
              >
                Subscribe Now
              </GradientColoredButton>
            </ValidatorForm>
            {emailError && (
              <FormHelperText
                style={{
                  fontFamily: "Poppins",
                  fontSize: "11px",
                  fontWeight: "bold",
                  color: "#ff4f47",
                }}
              >
                Please enter email address
              </FormHelperText>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
