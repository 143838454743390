/*
 **	User name
 **	Created	5/25/2023
 **	index.tsx
 */

import React, { useContext } from "react";

import { Box } from "@mui/material";
import { ColorModeContext } from "../../../ColorModeContext";

export default function ColorModeSwitch() {
  const {
    ReactComponent: SwitchDarkIcon,
  } = require("../../../assets/theme/DarkTheme.svg");
  const {
    ReactComponent: SwitchLightIcon,
  } = require("../../../assets/theme/LightTheme.svg");

  const { isDarkMode, toggleColorMode } = useContext(ColorModeContext);

  return (
    <Box
      sx={{
        cursor: "pointer",
        width: "78px",
        height: "34px",
        display: "flex",
        justifyContent: "center",
        marginTop: "13px",
      }}
      // style={{
      //   cursor: "pointer", position: "absolute",
      //   top: 20,
      //   right: "20px",
      // }}
      onClick={() => {
        toggleColorMode(!isDarkMode);
      }}
    >
      {isDarkMode ? <SwitchDarkIcon /> : <SwitchLightIcon />}
    </Box>
  );
}
