/*
 **	User name
 **	Created	5/25/2023
 **	editList.ts
 */

const initState = {
  loading: false,
  data: null,
  error: null,
};

//get all degrees reducer
export const degreesType = {
  DEGREES: "DEGREES",
  REQUEST_DEGREES: "REQUEST_DEGREES",
  RESPONSE_DEGREES: "RESPONSE_DEGREES",
  ERROR_DEGREES: "ERROR_DEGREES",
};

export function degreesReducer(state = initState, action) {
  switch (action.type) {
    case degreesType.REQUEST_DEGREES:
      return { ...state, loading: true };
    case degreesType.RESPONSE_DEGREES:
      return { loading: false, error: null, data: action.payload };
    case degreesType.ERROR_DEGREES:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

//get all GetAllUniversitites reducer

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const universitiesType = {
  UNIVERSITIES: "UNIVERSITIES",
  REQUEST_UNIVERSITIES: "REQUEST_UNIVERSITIES",
  RESPONSE_UNIVERSITIES: "RESPONSE_UNIVERSITIES",
  ERROR_UNIVERSITIES: "ERROR_UNIVERSITIES",
};

export function universitiesReducer(state = initialState, action) {
  switch (action.type) {
    case universitiesType.REQUEST_UNIVERSITIES:
      return { ...state, loading: true };
    case universitiesType.RESPONSE_UNIVERSITIES:
      return { loading: false, error: null, data: action.payload };
    case universitiesType.ERROR_UNIVERSITIES:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

//get all GetAllUniversitites reducer

const initFieldState = {
  loading: false,
  data: null,
  error: null,
};

export const fieldsOfStudyType = {
  FIELDS_OF_STUDY: "FIELDS_OF_STUDY",
  REQUEST_FIELDS_OF_STUDY: "REQUEST_FIELDS_OF_STUDY",
  RESPONSE_FIELDS_OF_STUDY: "RESPONSE_FIELDS_OF_STUDY",
  ERROR_FIELDS_OF_STUDY: "ERROR_FIELDS_OF_STUDY",
};

export function fieldsOfStudyReducer(state = initFieldState, action) {
  switch (action.type) {
    case fieldsOfStudyType.REQUEST_FIELDS_OF_STUDY:
      return { ...state, loading: true };
    case fieldsOfStudyType.RESPONSE_FIELDS_OF_STUDY:
      return { loading: false, error: null, data: action.payload };
    case fieldsOfStudyType.ERROR_FIELDS_OF_STUDY:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}
