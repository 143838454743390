/*
 **	User name
 **	Created	5/25/2023
 **	index.tsx
 */

import React from "react";
import { Box } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import classNames from "classnames";
import { AppRoutes } from "./AppRoutes";

const useStyles = makeStyles((theme) => ({
  container: {
    // backgroundColor: theme?.palette?.background?.default + " !important",
    color: theme?.palette?.text?.primary
      ? `${theme.palette.text.primary} !important`
      : "",
    flex: 1,
    height: "100%",
  },
}));

export const Navigation = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box
      className={classNames(
        classes.container,
        theme?.palette?.mode === "dark" ? "dark-mode" : "light-mode"
      )}
    >
      <AppRoutes {...props} />
    </Box>
  );
};
