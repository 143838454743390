/*
 **	User name
 **	Created	7/18/2023
 **	additionalInfoModal.tsx
 */

import React, { useContext, useEffect, useState, useRef } from "react";
import CurrencyFlag from "react-currency-flags";
import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  Button,
  MenuItem,
  Select,
  FormControl,
  FormControlLabel,
  useTheme,
  Modal,
  ThemeProvider,
  TextField,
  createTheme,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ExpandMoreRounded } from "@mui/icons-material";
import { useProductDetailsModalStyles } from "styles/productDetails";
import {
  LightAttachmentIcon,
  LightModeCross,
  NightModeCross,
} from "app/components/Logo/logos";
import { MenuProps } from "app/screens/newDesigners/utils";
import {
  BlackBorderedButton,
  PurpleButton,
} from "app/components/UIFormFields/Buttons";
import { useDispatch } from "react-redux";
import { uploadDesignReducerTypes } from "app/redux/reducers/productReducer/uploadDesign";
import { designerProfileCardOptionStateType } from "app/redux/reducers/navBar";
import { PurchaseContext } from "context/PurchaseContext";
import { AppPermissionsContext } from "context/AppPermissionsContext";
import { useAppSelector } from "app/utils/hooks";
import { BlackButton } from "app/components/UIFormFields";
import Moment from "react-moment";
import { isIndividualSelector } from "app/redux/selectors/user-selector";

import ProductDetailsPreviewModal from "../preview/modal";

const useInfoStyles = makeStyles((theme) => ({
  leftSection: {
    color: "#a49bbe",
    fontWeight: "bold !important",
    marginRight: "68px !important",
  },
  modelStyle: {
    position: "absolute",
    textAlign: "center",
    top: "50%",
    left: "50%",
    width: "850px",
    maxHeight: "90vh !important",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    boxShadow: "0 47px 47px 0 rgba(43, 38, 57, 0.4)",
    borderRadius: 20,
    overflow: "auto",
  },
  viewLicenseTxt: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "6px !important",
    fontSize: "13px !important",
    color: "#7a48ff",
    cursor: "pointer",
  },
  snackbarcss: {
    minWidth: "185px !important",
    height: "54px !important",
    // marginBottom: "20px",
    border: "solid 1px #ebe3ff !important",
    backgroundColor: "#fff !important",
    color: "#2b2639 !important",
    borderRadius: "10px !important",
    boxShadow: "0 10px 16px 0 rgba(122, 72, 255, 0.1) !important",
    // marginTop: "54px !important",
  },
  inputNumber: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  buttonBox: { display: "flex", justifyContent: "center" },
  uploadbutton: {
    width: "160px",
    padding: "10px 16px",
    textAlign: "left",
  },
  disabledButton: {
    background: "#ebe3ff !important",
    color: "#a49bbe !important",
  },
  infoBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "6px",
  },
  info: {
    color: "#a49bbe",
    fontFamily: "Poppins !important",
    fontSize: "13px !important",
    fontWeight: "bold !important",
  },
}));

const currencyArray = [
  { name: "USD", display: "USD-US Dollar", id: 1 },
  { name: "EUR", display: "Euro-Euro", id: 2 },
];

export const AdditionalInfo = ({
  open,
  setAdditionalInfo = (data: any) => null,
  dataOfForm = {
    title: "",
    productTag: "",
    tools: "",
    description: "",
    categories: "",
  },
  handleEditProductCloseWithDiscardMessage = () => null,
  isEditProduct,
  additionalErrors,
  setAdditionalErrors,
  additionalFormData,
  setAdditionalFormData,
  additionalDownloadType,
  setAdditionalDownloadType,
  additonalVisibility,
  setAdditonalVisibility,
  success = false,
  setSuccess,
  individualProductData,
  productEditNavBar,
}) => {
  const { permissions } = useContext(AppPermissionsContext);
  const { createStripeConnectAccount } = useContext(PurchaseContext);
  const [visibility, setVisibility] = useState("");
  const userData = useAppSelector((state) => state.register);
  const uploadProduct = useAppSelector((state) => state.uploadDesign);
  const uploadProductFile = useAppSelector((state) => state.uploadProductFile);
  const thumbnailUpload = uploadProductFile.dataThumbnail;
  // const productUploadId = useAppSelector((state) => state.uploadProductFile);
  const accountGuid = userData?.userInfo?.accountGuid;
  const detailsOfProduct = useAppSelector(
    (state) => state.productWithUploadType
  );
  const companyInfo = useAppSelector((state) => state.companyInfo);
  const isIndividual = useAppSelector(isIndividualSelector);

  const productDetails = detailsOfProduct?.data;
  const dispatch = useDispatch();
  const attachFileRef = useRef<HTMLInputElement>();
  const [openPreview, setOpenPreview] = useState(false);

  const licenseAssigned =
    userData?.userAdditionalInfo?.companyEmployeeLicenses?.map((item) =>
      parseInt(item)
    ) || [];

  const licenseList =
    (userData?.userAdditionalInfo?.profileType !== 3
      ? companyInfo?.CompanyLicencesData
      : companyInfo?.CompanyLicencesData?.filter((lic) => {
          return (
            lic.companyLicenseId &&
            licenseAssigned.includes(lic.companyLicenseId)
          );
        })) || [];

  const [downloadType, setDownloadType] = useState({
    no: false,
    free: false,
    paid: true,
  });

  const [formData, setFormData] = useState({
    license: "",
    currency: null,
    amount: "",
    downloadType: { no: false, free: false, paid: true },
    ProductCustomLicence: null,
    visibility: "",
  });

  useEffect(() => {
    const roleId = userData?.userAdditionalInfo?.companyEmployeeRoleId;

    setVisibility(roleId === 3 ? "2" : roleId === 4 ? "3" : "1");
    setFormData({
      ...formData,
      visibility: roleId === 3 ? "2" : roleId === 4 ? "3" : "1",
    });
  }, []);

  useEffect(
    () =>
      isEditProduct &&
      setFormData({
        ...formData,
        visibility: additionalFormData?.visibilityId,
      }),
    []
  );

  // useEffect(() => {
  //   setFormData({
  //     ...formData,
  //     license: additionalFormData?.license || "",
  //     currency: additionalFormData?.currency || null,
  //     amount: additionalFormData?.amount || "",
  //     visibility: additionalFormData?.visibilityId || "1",
  //     ProductCustomLicence: additionalFormData?.license,
  //   });

  //   additionalFormData?.downloadType === 1
  //     ? setDownloadType({ no: true, free: false, paid: false })
  //     : additionalFormData?.downloadType === 2
  //     ? setDownloadType({ no: false, free: true, paid: true })
  //     : // (additionalFormData?.downloadType === 3 &&
  //       setDownloadType({ no: false, free: false, paid: true });
  // }, [
  //   additionalFormData?.visibilityId,
  //   additionalFormData?.license,
  //   additionalFormData?.downloadType,
  //   additionalFormData?.currencyID,
  // ]);

  useEffect(() => {
    userData?.userAdditionalInfo.companyEmployeeRoleId === 3 &&
      setDownloadType({ no: true, free: false, paid: false });
  }, []);

  const [errors, setErrors] = useState({
    license: "",
    currency: "",
    amount: "",
    downloadType: "",
    ProductCustomLicence: "",
  });

  const {
    ReactComponent: SearchIcon,
  } = require("../../../../assets/icons/rightSearch_Icon.svg");
  const {
    ReactComponent: Tick,
  } = require("../../../../assets/icons/Icons_Light_Tick mark green icon.svg");
  const classes = useInfoStyles();
  const classInfo = useProductDetailsModalStyles();
  const theme = useTheme();
  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };

  const handleDownloadButton = (obj) => {
    if (isEditProduct) {
      setAdditionalDownloadType(obj);
      const temp = (obj?.no && 1) || (obj?.free && 2) || (obj?.paid && 3);

      setAdditionalFormData({ ...additionalFormData, downloadType: temp });
      setFormData({ ...formData, downloadType: obj });
      !obj.paid &&
        (setAdditionalFormData({ ...additionalFormData, amount: "" }),
        setFormData({ ...formData, amount: "" }));
    } else {
      setDownloadType(obj);
      setFormData({ ...formData, downloadType: obj });
      if (!obj.paid) {
        setFormData({ ...formData, amount: "" });
      }
    }
  };

  const [temp, setTemp] = useState(false);

  const handleVisibility = (e) => {
    if (isEditProduct) {
      if (e.target.value === "2" || e.target.value === "3") {
        handleDownloadButton({
          no: false,
          free: true,
          paid: false,
        });
        setTemp(true);
      } else {
        setTemp(false);
      }
      setAdditonalVisibility(e.target.value);
      setFormData({ ...formData, visibility: e.target.value });
      setAdditionalFormData({
        ...additionalFormData,
        [e.target.name]: e.target.value?.toString(),
      });
    } else {
      if (e.target.value === "2" || e.target.value === "3") {
        handleDownloadButton({
          no: false,
          free: true,
          paid: false,
        });
        setTemp(true);
      } else {
        setTemp(false);
      }
      setVisibility(e.target.value);
      setFormData({ ...formData, visibility: e.target.value });
    }
  };

  const handleOnChange = (e) => {
    if (isEditProduct) {
      setAdditionalFormData({
        ...additionalFormData,
        [e.target.name]: e.target.value?.toString(),
      });
      setAdditionalErrors({ ...additionalErrors, [e.target.name]: false });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setErrors({ ...errors, [e.target.name]: false });
    }
  };

  const VisibilityTypeIdHandler = () => {
    if (isEditProduct) {
      if (additonalVisibility === "Public") return 1;
      else if (additonalVisibility === "Private") return 2;
      else if (additonalVisibility === "Team") return 3;
    } else {
      if (visibility === "Public") return 1;
      else if (visibility === "Private") return 2;
      else if (visibility === "Team") return 3;
    }
  };

  const downloadTypeIdHandler = () => {
    if (isEditProduct) {
      if (additionalDownloadType.no) return 1;
      else if (additionalDownloadType.free) return 2;
      else if (additionalDownloadType.paid) return 3;
    } else {
      if (downloadType.no) return 1;
      else if (downloadType.free) return 2;
      else if (downloadType.paid) return 3;
    }
  };

  const designUploadAPI = async (IsPublished) => {
    const params = new FormData();

    params.append(
      "ProductId",
      isEditProduct
        ? productDetails?.productDetailedEntity?.productID
        : thumbnailUpload?.id
    );
    params.append(
      "ProductTitle",
      isEditProduct ? additionalFormData.title : dataOfForm.title
    );
    params.append(
      "ProductTagIdsList",
      isEditProduct ? additionalFormData.productTag : dataOfForm.productTag
    );
    params.append(
      "ProductToolIdsList",
      isEditProduct ? additionalFormData.tools : dataOfForm.tools
    );
    params.append(
      "ProductDescription",
      isEditProduct ? additionalFormData.description : dataOfForm.description
    );
    params.append(
      "ProductCategoryIdList",
      isEditProduct ? additionalFormData.categories : dataOfForm.categories
    );

    params.append(
      "VisibilityId",
      isEditProduct ? additionalFormData.visibilityId : formData?.visibility
      // : VisibilityTypeIdHandler()?.toString()
    );
    params.append(
      "DownloadType",
      // isEditProduct
      // ? additionalFormData.downloadType
      // :
      downloadTypeIdHandler()?.toString()
    );
    params.append(
      "LicenseId",
      (isEditProduct ? additionalFormData.license : formData?.license) || 1
      // (isEditProduct ? additionalFormData.licenseId : dataOfForm.license) || 1
    );
    params.append(
      "ProductCurrencyId",
      (isEditProduct ? additionalFormData.currencyID : formData.currency) || 1
      // (isEditProduct ? additionalFormData.currencyID : dataOfForm.currency) || 1
    );
    params.append(
      "ProductPrice",
      isEditProduct ? additionalFormData.amount : formData.amount
    );
    params.append("IsPublished", IsPublished);
    params.append("AccountGuid", accountGuid);
    params.append(
      "ProductCustomLicence",
      isEditProduct
        ? additionalFormData.ProductCustomLicence
        : formData.ProductCustomLicence
    );

    // Commenting this and showing upload modal

    await dispatch({
      type: uploadDesignReducerTypes.EDIT_DESIGN,
      payload: params,
    });

    // setUploadModalStatus(true);

    // below code for closing the flow popup
    await dispatch({
      type: designerProfileCardOptionStateType.CLOSE_EDIT,
    });
    const response = await dispatch({
      type: uploadDesignReducerTypes.CLEAR_UPLOAD_DESIGN,
    });

    localStorage.removeItem("editProductData");

    await setTimeout(() => window.location.reload(), 500);
  };

  const handleStripeConnect = async () => {
    designUploadAPI(false);

    const accountLink = await createStripeConnectAccount({
      email: userData?.userAdditionalInfo?.emailAddress,
      accountId: stripeId || null,
      data: {
        productId: productDetails?.productDetailedEntity?.productID,
        isLibraryProduct: false,
        productLibraryId: 0,
      },
    });

    if (accountLink?.url) {
      window.location.href = accountLink.url;
    }
  };

  const handlePreviewOpen = () => {
    dispatch({
      type: designerProfileCardOptionStateType.OPEN_ALL,
    });
    localStorage.setItem(
      "editProductData",
      JSON.stringify({ ...formData, ...dataOfForm })
    );
  };

  useEffect(() => {
    localStorage.setItem(
      "productUploadData",
      JSON.stringify({ ...formData, ...dataOfForm })
    );
  }, [formData, dataOfForm]);

  const handlePreviewOpenForAdd = () => {
    setOpenPreview(true);
    localStorage.setItem(
      "productUploadData",
      JSON.stringify({ ...formData, ...dataOfForm })
    );
  };

  useEffect(() => {
    if (uploadProduct?.data !== null) {
      if (uploadProduct?.data.responseCode === 200) {
        setSuccess?.(true);
      }
    }
  }, [uploadProduct]);

  const handleSubmit = () => {
    const tempErrors = {
      license: "",
      currency: "",
      amount: "",
      downloadType: "",
      ProductCustomLicence: "",
    };
    let hasError = false;

    if (formData?.license == "4" && ProductCustomLicence?.name === undefined) {
      tempErrors.license = "File is required!";
      hasError = true;
    }

    if (isEditProduct) {
      if (additionalDownloadType.paid || additionalDownloadType.free) {
        if (!additionalFormData.license) {
          tempErrors.license = "This field is required!";
          hasError = true;
        }
      }
      if (additionalDownloadType.paid) {
        if (!additionalFormData.currency) {
          tempErrors.currency = "This field is required!";
          hasError = true;
        }
        if (!additionalFormData.amount) {
          tempErrors.amount = "This field is required!";
          hasError = true;
        }
      }
      if (additionalDownloadType.paid) {
        if (!additionalFormData.currency) {
          tempErrors.currency = "This field is required!";
          hasError = true;
        }
      }

      if (additionalFormData.license === false) {
        // isEditProduct ? additionalErrors.license : errors.license
        tempErrors.license = "This field is required!";
        hasError = true;
      }
    } else {
      if (downloadType.paid || downloadType.free) {
        if (!formData.license) {
          tempErrors.license = "This field is required!";
          hasError = true;
        }
      }
      if (downloadType.paid) {
        if (!formData.currency) {
          tempErrors.currency = "This field is required!";
          hasError = true;
        }
        if (!formData.amount) {
          tempErrors.amount = "This field is required!";
          hasError = true;
        }
      }
    }

    if (hasError) {
      if (isEditProduct) {
        setAdditionalErrors({ ...additionalErrors, ...tempErrors });
      } else setErrors({ ...errors, ...tempErrors });
    } else {
      if (isEditProduct) {
        setAdditionalErrors({ ...tempErrors });
        designUploadAPI(true);
      } else {
        setErrors({ ...tempErrors });
        designUploadAPI(true);
      }
    }
  };
  const onUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isEditProduct) {
      setAdditionalFormData({
        ...additionalFormData,
        ProductCustomLicence: event.target.files[0],
      });
      setAdditionalErrors({ ...additionalErrors, ProductCustomLicence: "" });
    } else {
      setFormData({ ...formData, ProductCustomLicence: event.target.files[0] });
      setErrors({ ...errors, ProductCustomLicence: "" });
    }
  };

  const onFileRemove = () => {
    if (isEditProduct) {
      setAdditionalFormData({
        ...additionalFormData,
        ProductCustomLicence: null,
      });
    } else {
      setFormData({ ...formData, ProductCustomLicence: null });
    }
  };

  const {
    ReactComponent: SmallSearchIcon,
  } = require("../../../../assets/icons/Icons_Light_Small_Search Icon.svg");

  const isRegisteredWithStripe =
    userData?.userAdditionalInfo?.isRegisteredWithStripe;

  const stripeId = userData?.userAdditionalInfo?.stripeId;
  const stripeRegistered = Boolean(isRegisteredWithStripe && stripeId);

  const messageStatusFunction = () => {
    const status =
      ["", "0"].includes(formData?.amount) ||
      (formData?.downloadType?.paid === false &&
        additionalDownloadType !== 3 &&
        additionalFormData?.downloadType !== 3)
        ? false
        : !stripeRegistered
        ? true
        : false;

    return status;
  };

  const showStripeDetails = () => {
    const data = isEditProduct ? additionalFormData : formData;

    const showStripeDetails =
      (data?.downloadType?.paid || [3, "3"].includes(data?.downloadType)) &&
      ![" ", "0", ""].includes(data?.amount) &&
      !isRegisteredWithStripe
        ? true
        : false;
    // data?.downloadType?.paid || data?.downloadType === 3
    //   ? [" ", "0", ""].includes(data?.amount)
    //     ? false
    //     : isRegisteredWithStripe
    //     ? false
    //     : true
    //   : false;

    return showStripeDetails;
  };

  const companyEmployeeRoleId =
    userData?.userAdditionalInfo?.companyEmployeeRoleId;

  // const visibilityArray = isIndividual
  //   ? ["Public", "Private"]
  //   : permissions?.productSettings?.uploadPublic
  //   ? companyEmployeeRoleId === 3
  //     ? ["Public", "Private"]
  //     : ["Public", "Private", "Team"]
  //   : companyEmployeeRoleId === 3
  //   ? ["", "Private", ""]
  //   : ["", "Private", "Team"];

  const visibilityArray = isIndividual
    ? ["Public", "Private"]
    : [
        permissions?.productSettings?.uploadPublic ||
        companyEmployeeRoleId === 1
          ? "Public"
          : "",
        "Private",
        companyEmployeeRoleId === 3 ? "" : "Team",
      ];

  const ProductCustomLicence = isEditProduct
    ? additionalFormData.ProductCustomLicence
    : formData.ProductCustomLicence;

  const showError = (() => {
    try {
      if (uploadProduct?.data) {
        const parsedData = uploadProduct?.data;

        if (parsedData?.responseCode === 401) {
          return parsedData?.responseMessage;
        }
      }
    } catch (error) {}

    return null;
  })();

  function licenseTypeCoustomErrorHandle() {
    const tempErrors = {
      license: "",
      currency: "",
      amount: "",
      downloadType: "",
      ProductCustomLicence: "",
    };

    tempErrors.license = "File is  required!";
    setErrors({ ...tempErrors });
  }

  const centerSection = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "54px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography className={classes.leftSection}>Visibility</Typography>
          </Box>

          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            // name="controlled-radio-buttons-group"
            name="visibilityId"
            // value={isEditProduct ? additonalVisibility : visibility}
            value={isEditProduct ? formData?.visibility : visibility}
            onChange={(e) => handleVisibility(e)}
          >
            <Box>
              {visibilityArray.map(
                (item, index) =>
                  !!item && (
                    <FormControlLabel
                      sx={{ color: "#2d2d2d" }}
                      value={(index + 1)?.toString()}
                      control={
                        <Radio
                          sx={{
                            color: "#a49bbe",
                            "&.Mui-checked": {
                              color: "#7a48ff",
                            },
                          }}
                        />
                      }
                      label={item}
                      key={item}
                    />
                  )
              )}
            </Box>
          </RadioGroup>
        </Box>

        <Box width={"475px"} display="flex" flexDirection={"column"}>
          <Box
            sx={{
              marginTop: "31px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#a49bbe",
                  fontWeight: "bold",
                }}
              >
                Download
              </Typography>
            </Box>
            {userData?.userAdditionalInfo.companyEmployeeRoleId === 3 ? (
              <Box
                sx={{
                  width: "338px",
                  border: "solid 1px #d7d0e8",
                  borderRadius: "7px",
                  display: "flex",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: (
                      isEditProduct
                        ? additionalDownloadType.no
                        : downloadType.no
                    )
                      ? "#2b2639"
                      : "#fff",
                    border:
                      (isEditProduct
                        ? additionalDownloadType.no
                        : downloadType.no) && "#2b2639",
                    width: "112.7px !important",
                    height: "58px !important",
                    textTransform: " none !important",
                    color: (
                      isEditProduct
                        ? additionalDownloadType.no
                        : downloadType.no
                    )
                      ? "#fff"
                      : "black",
                    ":hover": {
                      backgroundColor: (
                        isEditProduct
                          ? additionalDownloadType.no
                          : downloadType.no
                      )
                        ? "#2b2639"
                        : "#fff",
                      color: (
                        isEditProduct
                          ? additionalDownloadType.no
                          : downloadType.no
                      )
                        ? "#fff"
                        : "black",
                    },
                    borderRadius: "7px",
                  }}
                  onClick={() =>
                    handleDownloadButton({
                      no: true,
                      free: false,
                      paid: false,
                    })
                  }
                >
                  <Typography>No</Typography>
                </Button>

                <span
                  style={{
                    width: "1px",
                    height: "30px",
                    margin: "auto",
                    borderRight: "1px solid #d7d0e8",
                  }}
                ></span>
                <Button
                  style={{ backgroundColor: "#e5e5e5", opacity: "30%" }}
                  sx={{
                    backgroundColor: (
                      isEditProduct
                        ? additionalDownloadType.free
                        : downloadType.free
                    )
                      ? "#2b2639"
                      : "#fff",
                    border:
                      (isEditProduct
                        ? additionalDownloadType.free
                        : downloadType.free) && "#2b2639",
                    width: "112.7px !important",
                    height: "58px !important",
                    textTransform: " none !important",
                    color: (
                      isEditProduct
                        ? additionalDownloadType.free
                        : downloadType.free
                    )
                      ? "#fff"
                      : "black",
                    ":hover": {
                      backgroundColor: (
                        isEditProduct
                          ? additionalDownloadType.free
                          : downloadType.free
                      )
                        ? "#2b2639"
                        : "#fff",
                      color: (
                        isEditProduct
                          ? additionalDownloadType.free
                          : downloadType.free
                      )
                        ? "#fff"
                        : "black",
                    },
                    borderRadius: "7px",
                  }}
                >
                  <Typography>Free</Typography>
                </Button>
                <span
                  style={{
                    width: "1px",
                    height: "30px",
                    margin: "auto",
                    borderRight: "1px solid #d7d0e8",
                  }}
                ></span>
                {/* <div
                  style={{
                    height: "15px",
                    width: "2px",
                    marginTop: "20px",
                    border: "ridge gray 1px",
                  }}
                /> */}
                <Button
                  style={{ backgroundColor: "#e5e5e5", opacity: "30%" }}
                  sx={{
                    backgroundColor: (
                      isEditProduct
                        ? additionalDownloadType.paid
                        : downloadType.paid
                    )
                      ? "#2b2639"
                      : "#fff",
                    border:
                      (isEditProduct
                        ? additionalDownloadType.paid
                        : downloadType.paid) && "#2b2639",
                    width: "112.7px !important",
                    height: "58px !important",
                    textTransform: " none !important",
                    color: (
                      isEditProduct
                        ? additionalDownloadType.paid
                        : downloadType.paid
                    )
                      ? "#fff"
                      : "black",
                    ":hover": {
                      backgroundColor: (
                        isEditProduct
                          ? additionalDownloadType.paid
                          : downloadType.paid
                      )
                        ? "#2b2639"
                        : "#fff",
                      color: (
                        isEditProduct
                          ? additionalDownloadType.paid
                          : downloadType.paid
                      )
                        ? "#fff"
                        : "black",
                    },
                    borderRadius: "7px",
                  }}
                >
                  <Typography>Paid</Typography>
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "338px",
                  border: "solid 1px #d7d0e8",
                  borderRadius: "7px",
                  display: "flex",
                }}
              >
                <Button
                  sx={{
                    backgroundColor: (
                      isEditProduct
                        ? additionalDownloadType.no
                        : downloadType.no
                    )
                      ? "#2b2639"
                      : "#fff",
                    border:
                      (isEditProduct
                        ? additionalDownloadType.no
                        : downloadType.no) && "#2b2639",
                    width: "112.7px !important",
                    height: "58px !important",
                    textTransform: " none !important",
                    color: (
                      isEditProduct
                        ? additionalDownloadType.no
                        : downloadType.no
                    )
                      ? "#fff"
                      : "black",
                    ":hover": {
                      backgroundColor: (
                        isEditProduct
                          ? additionalDownloadType.no
                          : downloadType.no
                      )
                        ? "#2b2639"
                        : "#fff",
                      color: (
                        isEditProduct
                          ? additionalDownloadType.no
                          : downloadType.no
                      )
                        ? "#fff"
                        : "black",
                    },
                    borderRadius: "7px",
                  }}
                  onClick={() =>
                    handleDownloadButton({
                      no: true,
                      free: false,
                      paid: false,
                    })
                  }
                >
                  <Typography>No</Typography>
                </Button>

                <span
                  style={{
                    width: "1px",
                    height: "30px",
                    margin: "auto",
                    borderRight: "1px solid #d7d0e8",
                  }}
                ></span>
                <Button
                  sx={{
                    backgroundColor: (
                      isEditProduct
                        ? additionalDownloadType.free
                        : downloadType.free
                    )
                      ? "#2b2639"
                      : "#fff",
                    border:
                      (isEditProduct
                        ? additionalDownloadType.free
                        : downloadType.free) && "#2b2639",
                    width: "112.7px !important",
                    height: "58px !important",
                    textTransform: " none !important",
                    color: (
                      isEditProduct
                        ? additionalDownloadType.free
                        : downloadType.free
                    )
                      ? "#fff"
                      : "black",
                    ":hover": {
                      backgroundColor: (
                        isEditProduct
                          ? additionalDownloadType.free
                          : downloadType.free
                      )
                        ? "#2b2639"
                        : "#fff",
                      color: (
                        isEditProduct
                          ? additionalDownloadType.free
                          : downloadType.free
                      )
                        ? "#fff"
                        : "black",
                    },
                    borderRadius: "7px",
                  }}
                  onClick={() =>
                    handleDownloadButton({
                      no: false,
                      free: true,
                      paid: false,
                    })
                  }
                >
                  <Typography>Free</Typography>
                </Button>
                <span
                  style={{
                    width: "1px",
                    height: "30px",
                    margin: "auto",
                    borderRight: "1px solid #d7d0e8",
                  }}
                ></span>
                {/* <div
                   style={{
                     height: "15px",
                     width: "2px",
                     marginTop: "20px",
                     border: "ridge gray 1px",
                   }}
                 /> */}
                <Button
                  sx={{
                    backgroundColor: (
                      isEditProduct
                        ? additionalDownloadType.paid
                        : downloadType.paid
                    )
                      ? "#2b2639"
                      : temp === false
                      ? "#fff"
                      : "#D3D3D3",
                    border:
                      (isEditProduct
                        ? additionalDownloadType.paid
                        : downloadType.paid) && "#2b2639",
                    width: "112.7px !important",
                    height: "58px !important",
                    textTransform: " none !important",
                    color: (
                      isEditProduct
                        ? additionalDownloadType.paid
                        : downloadType.paid
                    )
                      ? "#fff"
                      : "black",
                    ":hover": {
                      backgroundColor: (
                        isEditProduct
                          ? additionalDownloadType.paid
                          : downloadType.paid
                      )
                        ? "#2b2639"
                        : "#fff",
                      color: (
                        isEditProduct
                          ? additionalDownloadType.paid
                          : downloadType.paid
                      )
                        ? "#fff"
                        : "black",
                    },
                    borderRadius: "7px",

                    opacity: temp === false ? "100%" : "30%",
                  }}
                  onClick={() =>
                    temp === false &&
                    handleDownloadButton({
                      no: false,
                      free: false,
                      paid: true,
                    })
                  }
                >
                  <Typography>Paid</Typography>
                </Button>
              </Box>
            )}
          </Box>
          {!downloadType.no && (
            <>
              <Box>
                <Box
                  sx={{
                    marginTop: "34px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#a49bbe",
                        fontWeight: "bold",
                      }}
                    >
                      License
                    </Typography>
                  </Box>

                  <FormControl sx={{ width: "338px" }}>
                    <Select
                      error={
                        isEditProduct
                          ? additionalErrors.license
                          : errors.license
                      }
                      SelectDisplayProps={{
                        style: {
                          padding: "14px 14px",
                          height: "26px",
                          textAlign: "left",
                        },
                      }}
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      name={isEditProduct ? "license" : "license"}
                      value={
                        isEditProduct
                          ? additionalFormData.license
                          : formData.license
                      }
                      onChange={handleOnChange}
                      IconComponent={ExpandMoreRounded}
                      MenuProps={MenuProps}
                    >
                      {licenseList?.map((item, index) => {
                        return (
                          <MenuItem
                            id={item?.companyLicenseId}
                            key={item?.companyLicenseId}
                            value={item.companyLicenseId}
                            sx={{
                              "&.MuiMenuItem-root": {
                                padding: "12px",
                                borderBottom: 1,
                                borderColor: "#ebe3ff",
                              },

                              "&.Mui-selected": {
                                color:
                                  theme?.palette?.mode === "light"
                                    ? "#7a48ff"
                                    : " #fff",
                                backgroundColor:
                                  theme?.palette?.mode === "light"
                                    ? "#fff"
                                    : "black",
                              },
                            }}
                          >
                            {item.licenseName}
                          </MenuItem>
                        );
                      })}
                    </Select>

                    <FormHelperText
                      sx={{
                        fontSize: "11px",
                        fontWeight: "bold",
                        color: "#ff4f47 !important",
                      }}
                    >
                      {errors.license}
                    </FormHelperText>
                  </FormControl>
                </Box>
                <Typography
                  onClick={() => {
                    window.open("/terms-and-conditions", "_blank");
                    window.sessionStorage.setItem("isLicenseView", "true");
                  }}
                  className={classes.viewLicenseTxt}
                  style={{ cursor: "pointer" }}
                >
                  View Licenses
                </Typography>
              </Box>

              {(isEditProduct
                ? additionalFormData.license
                : formData.license) === 4 && (
                <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Box style={{ marginBottom: 24 }}>
                    <Box className={classes.buttonBox}>
                      <BlackButton
                        variant="outlined"
                        className={`${classes.uploadbutton} ${
                          ProductCustomLicence && classes.disabledButton
                        }`}
                        disabled={ProductCustomLicence}
                        onClick={() => attachFileRef.current.click()}
                      >
                        <Box>
                          <input
                            style={{ display: "none" }}
                            id="icon-button-file"
                            multiple
                            ref={attachFileRef}
                            type="file"
                            hidden
                            onChange={onUploadFile}
                          />
                        </Box>
                        <LightAttachmentIcon style={{ marginRight: "10px" }} />
                        <Typography style={{ flex: 1, fontSize: 14 }}>
                          Attach files
                        </Typography>
                      </BlackButton>

                      <Box style={{ flex: 1, alignSelf: "flex-end" }}>
                        <Box className={classes.infoBox}>
                          <Typography variant="h5" className={classes.info}>
                            File format should be pdf.
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  {ProductCustomLicence && (
                    <Box
                      style={{
                        border: "1px solid #d7d0e8",
                        borderRadius: 6,
                        padding: 16,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box style={{ flex: 1, marginRight: 20 }}>
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            background: "#7a48ff",
                            padding: 8,
                            borderRadius: 6,
                          }}
                        >
                          <Typography style={{ color: "#fff", flex: 1 }}>
                            {ProductCustomLicence?.name}
                          </Typography>
                          <Typography
                            style={{
                              color: "#fff",
                              marginLeft: 16,
                              cursor: "pointer",
                            }}
                            onClick={onFileRemove}
                          >
                            X
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography style={{ color: "#d7d0e8", flex: 1 }}>
                          Uploaded On -{" "}
                          <Moment format="DD MMM, YYYY">{new Date()}</Moment>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}

          {(isEditProduct
            ? additionalDownloadType.paid
            : downloadType.paid) && (
            <>
              <Box
                sx={{
                  marginTop: "34px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#a49bbe",
                      fontWeight: "bold",
                      // marginRight: "53px",
                    }}
                  >
                    {/* commented from 173/5 */}
                    {/* Currency */}
                    Select Currency
                  </Typography>
                </Box>

                <FormControl sx={{ width: "338px" }}>
                  <Select
                    error={
                      isEditProduct
                        ? additionalErrors.currency
                        : errors.currency
                    }
                    SelectDisplayProps={{
                      style: { padding: "14px 14px", height: "26px" },
                    }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    name={isEditProduct ? "currencyID" : "currency"}
                    value={
                      (isEditProduct
                        ? additionalFormData.currencyID
                        : formData.currency) || ""
                    }
                    onChange={handleOnChange}
                    IconComponent={ExpandMoreRounded}
                    // input={
                    //   <OutlinedInput
                    //     className={classes.outlined}
                    //     label="Select "
                    //   />
                    // }
                    // renderValue={(selected) => renderTagChips(selected)}
                    MenuProps={MenuProps}
                  >
                    {currencyArray.map((item) => {
                      return (
                        <MenuItem
                          value={item.id}
                          sx={{
                            "&.MuiMenuItem-root": {
                              padding: "12px",
                              borderBottom: 1,
                              borderColor: "#ebe3ff",
                            },

                            "&.Mui-selected": {
                              color:
                                theme?.palette?.mode === "light"
                                  ? "#7a48ff"
                                  : " #fff",
                              backgroundColor:
                                theme?.palette?.mode === "light"
                                  ? "#fff"
                                  : "black",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                              alignItems: "center",
                            }}
                          >
                            <CurrencyFlag
                              currency={item.name}
                              // style={{marginLeft:"30px"}}
                              // style={{ marginTop: "-1px",marginRight:"8px",  width:"24px",height:"16px" }}
                            />
                            <Typography marginLeft={"8px"}>
                              {item.display}
                            </Typography>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText
                    sx={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      color: "#ff4f47 !important",
                    }}
                  >
                    {isEditProduct
                      ? additionalErrors.currency
                      : errors.currency}
                  </FormHelperText>
                </FormControl>
              </Box>
              <Box
                sx={{
                  marginTop: "34px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: "#a49bbe",
                      fontWeight: "bold",
                      // marginRight: "62px",
                    }}
                  >
                    Amount
                  </Typography>
                </Box>
                <FormControl sx={{ width: "338px" }} error={!!errors.amount}>
                  <TextField
                    className={classes.inputNumber}
                    name="amount"
                    error={
                      isEditProduct ? additionalErrors.amount : errors.amount
                    }
                    value={
                      isEditProduct
                        ? additionalFormData.amount
                        : formData.amount
                    }
                    onChange={handleOnChange}
                    label="Enter Amount*"
                    variant="outlined"
                    type={"number"}
                    InputProps={{
                      inputProps: { min: 0, max: "" },
                    }}
                  />
                  <FormHelperText
                    sx={{
                      fontSize: "11px",
                      fontWeight: "bold",
                      color: "#ff4f47 !important",
                    }}
                  >
                    {errors.amount}
                  </FormHelperText>
                </FormControl>
              </Box>
              {["", "0", " "].includes(
                formData?.amount || additionalFormData?.amount
              ) ? (
                ""
              ) : (
                <Typography
                  style={{
                    fontSize: "13px",
                    color: "#a49bbe",
                    marginTop: "30px",
                    textAlign: "justify",
                    width: "110%",
                  }}
                >
                  Note: The service provider will charge fees for the
                  transaction. The amount received will therefore differ from
                  your selected price by these fees.
                </Typography>
              )}

              {showStripeDetails() && (
                <Typography
                  style={{
                    fontSize: "13px",
                    color: "#a49bbe",
                    marginTop: "30px",
                    textAlign: "justify",
                    width: "110%",
                  }}
                >
                  Note: To proceed with the sales you need to connect with the
                  Stripe account. As a result, your product will be saved to
                  drafts. After successful stripe integration you will be able
                  to publish your saved draft.
                </Typography>
              )}
            </>
          )}
        </Box>

        {showError ? (
          <Alert
            severity="error"
            sx={{ maxWidth: 500, alignSelf: "center", mt: 2, mb: 0 }}
          >
            {showError}
          </Alert>
        ) : null}
      </Box>
    );
  };

  if (isEditProduct) {
    return (
      <Box>
        <Box>{centerSection()}</Box>

        {success === true ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
              alignItems: "center",
              justifyContent: "center",
              height: "80px",
            }}
          >
            <Snackbar
              ContentProps={{ className: classes.snackbarcss }}
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              open={true}
              message={uploadProduct?.data?.responseMessage}
              action={<Tick style={{ marginRight: "16px" }} />}
            />
          </Box>
        ) : (
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                marginTop: "54px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BlackBorderedButton
                sx={{
                  marginRight: "12px",
                  height: "56px",
                  width: "86px",
                  textTransform: "capitalize",
                }}
                onClick={handleEditProductCloseWithDiscardMessage}
              >
                Cancel
              </BlackBorderedButton>
              <PurpleButton
                onClick={
                  additionalFormData?.isPublished
                    ? handleSubmit
                    : !showStripeDetails()
                    ? handleSubmit
                    : handleStripeConnect
                }
                variant="contained"
                sx={{
                  textTransform: "capitalize",
                  width: additionalFormData?.isPublished ? "150px" : "160px",
                  height: "56px",
                }}
              >
                {additionalFormData?.isPublished
                  ? "Save Changes"
                  : !showStripeDetails()
                  ? "Publish"
                  : "Connect to Stripe"}
              </PurpleButton>
            </Box>
            <h6
              style={{
                marginTop: "24px",
                // marginBottom: "30px",
                textAlign: "center",
                fontWeight: "bold",
                color: "#a49bbe",
                cursor: "pointer",
              }}
              onClick={() => {
                designUploadAPI(false);
              }}
            >
              Save to draft
            </h6>
            <h6
              style={{
                position: "absolute",
                fontWeight: "bold",
                color: "#a49bbe",
                right: 54,
                marginTop: "-25px",
                cursor: "pointer",
              }}
              onClick={handlePreviewOpen}
            >
              <SmallSearchIcon style={{ marginRight: "8px" }} />
              Product Preview
            </h6>
          </Box>
        )}
      </Box>
    );
  } else {
    return (
      <>
        <ThemeProvider
          theme={createTheme({
            typography: {
              allVariants: { fontFamily: "Poppins" },
            },
            components: {
              MuiMenuItem: {
                styleOverrides: {
                  root: {
                    padding: "12px",
                    "&.Mui-selected": {
                      color:
                        theme?.palette?.mode === "light" ? "#7a48ff" : " #fff",
                      // fontWeight: theme?.palette?.mode === "light" ? "400" : "400",
                      backgroundColor:
                        theme?.palette?.mode === "light" ? "#fff" : "black",
                    },
                    hover: false,
                    borderBottom: 2,
                    borderColor: "#ebe3ff",
                  },
                },
              },
              MuiListItem: {
                styleOverrides: {
                  root: {
                    padding: 0,
                  },
                },
              },
            },
          })}
        >
          <Modal open={open}>
            <Box className={classes.modelStyle}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box className={classInfo.closeBox}>
                  {theme?.palette?.mode === "light" ? (
                    <LightModeCross
                      onClick={() => setAdditionalInfo(false)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <NightModeCross
                      onClick={() => setAdditionalInfo(false)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </Box>
                <Box className={classInfo.titleBox}>
                  <Typography
                    style={
                      theme?.palette?.mode === "light"
                        ? heading.lightMode
                        : heading.nightMode
                    }
                  >
                    Additional Information
                  </Typography>
                </Box>
                <Box>
                  {centerSection()}

                  {}
                  <Box
                    sx={{
                      marginTop: "24px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {!showStripeDetails() ? (
                      <Box
                        style={{
                          paddingTop: "30px",
                          bottom: 0,
                          margin: "0 auto",
                          // marginBottom: "40px",
                        }}
                      >
                        <BlackBorderedButton
                          style={{
                            width: "69px",
                            marginRight: "24px",
                          }}
                          onClick={() => setAdditionalInfo(false)}
                        >
                          Back
                        </BlackBorderedButton>
                        <PurpleButton
                          style={{
                            width: 86,
                            height: 54,
                          }}
                          onClick={handleSubmit}
                        >
                          Publish
                        </PurpleButton>
                      </Box>
                    ) : (
                      <Box
                        style={{
                          paddingTop: "30px",
                          bottom: 0,
                          marginBottom: "24px",
                          // marginBottom: "40px",
                          // backgroundColor: "red",
                          // width: "100%",
                        }}
                      >
                        <BlackBorderedButton
                          style={{
                            width: "69px",
                            marginRight: "24px",
                          }}
                          onClick={() => setAdditionalInfo(false)}
                        >
                          Back
                        </BlackBorderedButton>
                        <PurpleButton
                          style={{
                            width: 350,
                            height: 54,
                          }}
                          onClick={async () => {
                            {
                              formData?.license == "4" &&
                              ProductCustomLicence?.name === undefined
                                ? licenseTypeCoustomErrorHandle()
                                : await handleStripeConnect();
                            }
                          }}
                        >
                          Connect to Stripe
                        </PurpleButton>
                      </Box>
                    )}
                    {/* {!messageStatusFunction() && ( */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        marginTop: "24px",
                        marginBottom: "24px !important",
                        cursor: "pointer",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#a49bbe",
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          designUploadAPI(false);
                        }}
                      >
                        Save to draft
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                          position: "absolute",
                          right: "54px",
                        }}
                      >
                        <SearchIcon />
                        <Typography
                          sx={{
                            color: "#a49bbe",
                            fontWeight: "bold",
                            marginLeft: "8px",
                            cursor: "pointer",
                          }}
                          onClick={handlePreviewOpenForAdd}
                        >
                          Product Preview
                        </Typography>
                      </Box>
                    </Box>
                    {/* )} */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Modal>
        </ThemeProvider>
        <ProductDetailsPreviewModal
          openPreview={openPreview}
          setOpenPreview={setOpenPreview}
        />
      </>
    );
  }
};
