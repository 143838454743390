import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { GradientColoredButton } from "../../components/UIFormFields";
import { navBarTypes } from "../../redux/reducers/navBar";

const HomeBanner = makeStyles((theme) => ({
  container: {
    display: "flex",
    // flexWrap: "wrap",
    flexDirection: "row",
    width: "100%",
    height: "678px",
    // flexGrow: 0,
    // objectFit: "contain",

    backdropFilter: "blur(60px)",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    borderWidth: "2px",
    borderImageSource:
      "linear-gradient(117deg, var(--white) 1%, rgba(255, 255, 255, 0) 101%)",
    borderImageSlice: "1",
    backgroundImage:
      "linear-gradient(118deg, rgba(255, 255, 255, 0.5) -4%, rgba(255, 255, 255, 0.5) 106%)",
  },
  Typography: {
    margin: "71px 0px 0px 0px !important",
    // width: "357px",
    height: "137px !important",
    [theme.breakpoints.down("lg")]: {
      margin: "100px 0px 0px 0px",
      height: "137px !important",
    },
  },
  comingsoon: {
    fontSize: "71px !important",
    fontWeight: "bold !important",
    zIndex: 10,
    [theme.breakpoints.down("lg")]: {
      fontSize: "71px !important",
      fontWeight: "bold !important",
      zIndex: 10,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "71px !important",
      fontWeight: "bold !important",
      zIndex: 10,
    },
  },
}));

export default function BannerSoon() {
  const background = require("../../../assets/backgrounds/HomebannerBack.png");
  const Man = require("../../../assets/backgrounds/Manbanner.png");
  const unionHorizontal = require("../../../assets/graphics/union_horizontal.png");
  const unionVertical = require("../../../assets/graphics/union_vertical.png");
  const group2057 = require("../../../assets/graphics/group_2057.png");
  const group902 = require("../../../assets/graphics/Group_902.png");
  const group903 = require("../../../assets/graphics/Group_903.png");
  const classes = HomeBanner();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box className={classes.container}>
      <Box
        flex="1"
        display={"flex"}
        flexDirection="column"
        marginLeft="98px"
        style={{ zIndex: 1 }}
      >
        <Box className={classes.Typography}>
          {/* <img commenting for removing the dots from Boys's face
            style={{
              position: "absolute",
              display: "flex",
              margin: "142px 0 0 830px",
            }}
            src={unionVertical}
          /> */}
          <img
            style={{
              position: "absolute",
              display: "flex",
              top: "188px",
              left: "27px",
            }}
            src={group2057}
          />
          <Typography className={classes.comingsoon} style={{ zIndex: 2 }}>
            Use the
            <br /> Designers Library
          </Typography>
        </Box>
        <Box>
          <Typography
            sx={{
              // width: "702px",
              fontSize: "31px",
              fontWeight: 500,
              textAlign: "left",
              color: "#7a48ff",
              marginTop: "200px",
            }}
          >
            Get Started with Blueprints
          </Typography>
          <Typography
            sx={{
              width: "425px",
              // height: "68px",
              color: "#2b2639",
              fontSize: "15px",
            }}
          >
            In our blueprint library we have many designs for you to choose
            from. You have complete control over the design and can customize it
            according to your ideas and specifications. Get started right away
            and add new trends to your portfolio.
          </Typography>
          <Box sx={{ marginTop: "35px" }}>
            <GradientColoredButton
              style={{ color: "white" }}
              onClick={() => {
                dispatch({
                  type: navBarTypes.NAV_BAR_CATEGORIES,
                });
                navigate("/library");
              }}
            >
              Explore
            </GradientColoredButton>
          </Box>
        </Box>
      </Box>
      <img
        style={{
          position: "absolute",
          display: "flex",
          margin: "30px 0px 0px 91px",
        }}
        src={unionHorizontal}
      />

      <Box flex="1" display="flex" justifyContent="end">
        <img
          style={{ position: "absolute", right: "47%", top: "80px" }}
          src={group902}
        />
        <img
          style={{
            position: "absolute",
            display: "flex",
            bottom: "13%",
            right: "54%",
          }}
          src={group903}
        />
        <img src={background} />
        <img
          style={{
            position: "absolute",
            height: "584px",
            width: "584px",
            marginTop: "94px",
          }}
          src={Man}
        />
      </Box>
    </Box>
  );
}
