import React from "react";
import { FormControl, InputAdornment, TextField } from "@mui/material";
import ColorFilter from "../../screens/newProducts/colorFilter";
import AttributeBox from "./AttributeBox";

const ColorSwatch = ({ setData, data }) => {
  const formData = {
    color: data?.[0] || "",
  };

  const setFormData = (dataNew) => {
    setData([dataNew?.color]);
  };

  return (
    <AttributeBox title="Colors*">
      <div>
        <FormControl
          sx={{ width: "300px", margin: "4px 24px 0 0" }}
          variant="outlined"
        >
          <TextField
            id="outlined-adornment-weight"
            value={formData?.color}
            name="color"
            label="Enter hex or select"
            placeholder="Enter hex or select"
            // onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ColorFilter formData={formData} setFormData={setFormData} />
                </InputAdornment>
              ),
            }}
            aria-describedby="outlined-weight-helper-text"
            // inputProps={{
            //   "aria-label": "weight",
            // }}
          />
        </FormControl>
      </div>
    </AttributeBox>
  );
};

export default ColorSwatch;
