import { isCompanyUser, isIndividualUser } from "app/utils/helper";
import { IRootState } from "../reducers";

export const testSelector = () => {
  return null;
};

export const accountGuidSelector = (state: IRootState) => {
  const userInfo = state?.register?.userInfo;
  const userAdditionalInfo = state?.register?.userAdditionalInfo;

  if (userInfo?.accountGuid) {
    return userInfo.accountGuid;
  }

  if (userAdditionalInfo?.accountGUID) {
    return userAdditionalInfo.accountGUID;
  }

  return "";
};

export const isIndividualSelector = (state: IRootState) => {
  const userAdditionalInfo = state?.register?.userAdditionalInfo;

  return isIndividualUser(userAdditionalInfo?.profileType);
};

export const isCompanySelector = (state: IRootState) => {
  const userAdditionalInfo = state?.register?.userAdditionalInfo;

  return isCompanyUser(userAdditionalInfo?.profileType);
};

export const accessTokenSelector = (state: IRootState) => {
  const userData = state?.register;

  return userData?.userInfo?.token || userData?.userInfo?.accessToken;
};
