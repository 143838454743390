import { licenceActionTypes } from "./license";

const initState = {
  loading: false,
  data: null,
  error: null,
  companyLoading: false,
  companyData: null,
  companyError: null,
};

export const licenseSelectionReducerTypes = {
  LICENCE_SELECTION: "LICENCE_SELECTION",
  REQUEST_LICENCE_SELECTION: "REQUEST_LICENCE_SELECTION",
  RESPONSE_LICENCE_SELECTION: "RESPONSE_LICENCE_SELECTION",
  ERROR_LICENCE_SELECTION: "ERROR_LICENCE_SELECTION",
  CLEAR_LICENCE_SELECTION: "CLEAR_LICENCE_SELECTION",

  COMPANY_LICENCE_SELECTION: "COMPANY_LICENCE_SELECTION",
  REQUEST_COMPANY_LICENCE_SELECTION: "REQUEST_COMPANY_LICENCE_SELECTION",
  RESPONSE_COMPANY_LICENCE_SELECTION: "RESPONSE_COMPANY_LICENCE_SELECTION",
  ERROR_COMPANY_LICENCE_SELECTION: "ERROR_COMPANY_LICENCE_SELECTION",
  CLEAR_COMPANY_LICENCE_SELECTION: "CLEAR_COMPANY_LICENCE_SELECTION",
};

export function licenseSelectionReducer(state = initState, action) {
  switch (action.type) {
    case licenceActionTypes.FETCH_LICENSE_REQUEST: {
      return {
        ...state,
        ...initState,
      };
    }
    case licenseSelectionReducerTypes.REQUEST_LICENCE_SELECTION:
      return { ...state, loading: true };
    case licenseSelectionReducerTypes.RESPONSE_LICENCE_SELECTION:
      return { ...state, loading: false, data: action.payload };
    case licenseSelectionReducerTypes.ERROR_LICENCE_SELECTION:
      return { loading: false, data: null, error: action.payload };
    case licenseSelectionReducerTypes.CLEAR_LICENCE_SELECTION:
      return { loading: false, data: null, error: null };

    case licenseSelectionReducerTypes.REQUEST_COMPANY_LICENCE_SELECTION:
      return { ...state, companyLoading: true };
    case licenseSelectionReducerTypes.RESPONSE_COMPANY_LICENCE_SELECTION:
      return { ...state, companyLoading: false, companyData: action.payload };
    case licenseSelectionReducerTypes.ERROR_COMPANY_LICENCE_SELECTION:
      return {
        companyLoading: false,
        companyData: null,
        companyError: action.payload,
      };
    case licenseSelectionReducerTypes.CLEAR_COMPANY_LICENCE_SELECTION:
      return { loading: false, data: null, error: null };

    default:
      return state;
  }
}
