import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import {
  libraryActionTypes,
  libraryProductListReducerTypes,
  saveLibraryProductTypes,
} from "../reducers/library";
import { libraryFavouriteReducerTypes } from "../reducers/libraryReducers/libraryList";

const version3 = process.env.REACT_APP_API_VERSION_3;
const version = process.env.REACT_APP_API_VERSION;

//
function* libraryFavouriteRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.SET_LIBRARY_FAVOURITE.replace("{version}", version),
    payload
  );

  return res;
}

function* libraryFavourite({ payload }) {
  try {
    yield put({
      type: libraryFavouriteReducerTypes?.REQUEST_SET_LIBRARY_FAVOURITE,
      // productFavouriteReducerTypes.REQUEST_SET_PRODUCT_FAVOURITE,
      payload: null,
    });
    const response = yield call(libraryFavouriteRequest, payload);

    yield put({
      type: libraryFavouriteReducerTypes?.RESPONSE_SET_LIBRARY_FAVOURITE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: libraryFavouriteReducerTypes?.ERROR_SET_LIBRARY_FAVOURITE,
    });
  }
}

//

function* uploadLibraryProductRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.LIBRARY_UPLOAD.replace("{version}", version3),
    payload,
    "form-data"
  );

  return res;
}

function* uploadLibraryProduct({ payload }) {
  try {
    yield put({
      type: libraryActionTypes.REQUEST_LIBRARY_UPLOAD,
      payload: null,
    });
    const response = yield call(uploadLibraryProductRequest, payload);

    yield put({
      type: libraryActionTypes.RESPONSE_LIBRARY_UPLOAD,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: libraryActionTypes.ERROR_LIBRARY_UPLOAD,
      payload: e.toString(),
    });
  }
}

//////////////////////////////////////////////////////////

function* libraryProductListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.LIBRARY_PRODUCT_LIST.replace("{version}", version3),
    payload
  );

  return res;
}

function* libraryProductList({ payload }) {
  try {
    yield put({
      type: libraryProductListReducerTypes.REQUEST_LIBRARY_PRODUCT_LIST,
      payload,
    });
    const response = yield call(libraryProductListRequest, payload);

    yield put({
      type: libraryProductListReducerTypes.RESPONSE_LIBRARY_PRODUCT_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: libraryProductListReducerTypes.ERROR_LIBRARY_PRODUCT_LIST,
    });
  }
}

//////////////////////////////////////////////////////////

function* savedLibraryProductListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.SAVED_LIBRARY_PRODUCT_LIST.replace("{version}", version3),
    payload
  );

  return res;
}

function* savedLibraryProductList({ payload }) {
  try {
    yield put({
      type: libraryProductListReducerTypes.REQUEST_SAVED_LIBRARY_PRODUCT_LIST,
      payload,
    });
    const response = yield call(savedLibraryProductListRequest, payload);

    yield put({
      type: libraryProductListReducerTypes.RESPONSE_SAVED_LIBRARY_PRODUCT_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: libraryProductListReducerTypes.ERRORs_SAVED_LIBRARY_PRODUCT_LIST,
    });
  }
}

///////////////////////////////////////////////////////////

function* saveLibraryProductRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.SAVE_LIBRARY_PRODUCT.replace("{version}", version3),
    payload
  );

  return res;
}

function* saveLibraryProduct({ payload }) {
  try {
    yield put({
      type: saveLibraryProductTypes.REQUEST_SAVE_LIBRARY_PRODUCT,
      payload: null,
    });
    const response = yield call(saveLibraryProductRequest, payload);

    yield put({
      type: saveLibraryProductTypes.RESPONSE_SAVE_LIBRARY_PRODUCT,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: saveLibraryProductTypes.ERROR_SAVE_LIBRARY_PRODUCT,
      payload: e,
    });
  }
}

/////////////////////////////////////////////////////////////

function* mainSaga() {
  yield all([
    takeLatest(
      libraryFavouriteReducerTypes.SET_LIBRARY_FAVOURITE,
      libraryFavourite
    ),
    takeLatest(libraryActionTypes.LIBRARY_UPLOAD, uploadLibraryProduct),
    takeLatest(
      libraryProductListReducerTypes.LIBRARY_PRODUCT_LIST,
      libraryProductList
    ),
    takeLatest(
      libraryProductListReducerTypes.SAVED_LIBRARY_PRODUCT_LIST,
      savedLibraryProductList
    ),
    takeLatest(
      saveLibraryProductTypes.SAVE_LIBRARY_PRODUCT,
      saveLibraryProduct
    ),
  ]);
}

export default mainSaga;
