import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { CartActionTypes, CartActions } from "../reducers/cart";

const version = process.env.REACT_APP_API_VERSION;

function* fetchCartDetailsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_CART.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchCartDetails({ payload }: any) {
  try {
    yield put({
      type: CartActionTypes.fetchCartRequest,
    });
    const response = yield call(fetchCartDetailsRequest, payload);

    yield put({
      type: CartActionTypes.fetchCartSuccess,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: CartActionTypes.fetchCartFailure,
      payload: e,
    });
  }
}

function* addToCartFunRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.ADD_TO_CART.replace("{version}", version),
    payload
  );

  return res;
}

function* addToCartFun({ payload }: any) {
  try {
    yield put({
      type: CartActionTypes.addToCartRequest,
    });
    const response = yield call(addToCartFunRequest, payload);

    if (response?.data?.responseCode === 200) {
      yield put({
        type: CartActionTypes.addToCartSuccess,
        payload: response?.data,
      });
    } else {
      yield put({
        type: CartActionTypes.addToCartFailure,
        payload: response?.data,
      });
    }

    yield put(CartActions.fetchCart({ guid: payload?.accountGuid }));
    payload?.callback?.();
  } catch (e) {
    yield put(CartActions.fetchCart({ guid: payload?.accountGuid }));
    yield put({
      type: CartActionTypes.addToCartFailure,
      payload: e,
    });
  }
}

function* removeFromCartFunRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.REMOVE_FROM_CART.replace("{version}", version),
    payload
  );

  return res;
}

function* removeFromCartFun({ payload }: any) {
  try {
    yield put({
      type: CartActionTypes.removeFromCartRequest,
    });
    const response = yield call(removeFromCartFunRequest, payload);

    yield put({
      type: CartActionTypes.removeFromCartSuccess,
      payload: response?.data,
    });
    yield put(CartActions.fetchCart({ guid: payload?.accountGuid }));
    payload?.callback?.();
  } catch (e) {
    yield put({
      type: CartActionTypes.removeFromCartFailure,
      payload: e,
    });
    yield put(CartActions.fetchCart({ guid: payload?.accountGuid }));
  }
}

function* clearCartFunRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.CLEAR_CART.replace("{version}", version),
    payload
  );

  return res;
}

function* clearCartFun({ payload }: any) {
  try {
    yield put({
      type: CartActionTypes.clearCartRequest,
    });
    const response = yield call(clearCartFunRequest, payload);

    yield put({
      type: CartActionTypes.clearCartSuccess,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: CartActionTypes.clearCartFailure,
      payload: e,
    });
  }
}

function* combinedSaga() {
  yield all([
    takeLatest(CartActionTypes.fetchCart, fetchCartDetails),
    takeLatest(CartActionTypes.addToCart, addToCartFun),
    takeLatest(CartActionTypes.removeFromCart, removeFromCartFun),
    takeLatest(CartActionTypes.clearCart, clearCartFun),
  ]);
}

export default combinedSaga;
