const initialState = {
  loading: false,
  data: null,
  error: null,
};

//fetch Billing Information
export const fetchBillingInformationReducerTypes = {
  BILLING_INFORMATION: "BILLING_INFORMATION",
  REQUEST_BILLING_INFORMATION: "REQUEST_BILLING_INFORMATION",
  RESPONSE_BILLING_INFORMATION: "RESPONSE_BILLING_INFORMATION",
  ERROR_BILLING_INFORMATION: "ERROR_BILLING_INFORMATION",
  CLEAR_BILLING_INFORMATION: "CLEAR_BILLING_INFORMATION",
};

export function fetchBillingInformationReducer(state = initialState, action) {
  switch (action.type) {
    case fetchBillingInformationReducerTypes.REQUEST_BILLING_INFORMATION:
      return { ...state, loading: true };
    case fetchBillingInformationReducerTypes.RESPONSE_BILLING_INFORMATION:
      return { error: null, loading: false, data: action.payload };
    case fetchBillingInformationReducerTypes.ERROR_BILLING_INFORMATION:
      return { loading: false, data: null, error: action.payload };
    case fetchBillingInformationReducerTypes.CLEAR_BILLING_INFORMATION:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

// SAving Billing Information

export const saveBillingInformationReducerTypes = {
  SAVE_BILLING_INFORMATION: "SAVE_BILLING_INFORMATION",
  REQUEST_SAVE_BILLING_INFORMATION: "REQUEST_SAVE_BILLING_INFORMATION",
  RESPONSE_SAVE_BILLING_INFORMATION: "RESPONSE_SAVE_BILLING_INFORMATION",
  ERROR_SAVE_BILLING_INFORMATION: "ERROR_SAVE_BILLING_INFORMATION",
  CLEAR_SAVE_BILLING_INFORMATION: "CLEAR_SAVE_BILLING_INFORMATION",
};

export function saveBillingInformationReducer(state = initialState, action) {
  switch (action.type) {
    case saveBillingInformationReducerTypes.REQUEST_SAVE_BILLING_INFORMATION:
      return { ...state, loading: true };
    case saveBillingInformationReducerTypes.RESPONSE_SAVE_BILLING_INFORMATION:
      return { error: null, loading: false, data: action.payload };
    case saveBillingInformationReducerTypes.ERROR_SAVE_BILLING_INFORMATION:
      return { loading: false, data: null, error: action.payload };
    case saveBillingInformationReducerTypes.CLEAR_SAVE_BILLING_INFORMATION:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
