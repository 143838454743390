const initState = {
  loading: false,
  data: null,
  error: null,
};

//Subscribe reducer
export const subscribeTypes = {
  SUBSCRIBE: "SUBSCRIBE",
  REQUEST_SUBSCRIBE: "REQUEST_SUBSCRIBE",
  RESPONSE_SUBSCRIBE: "RESPONSE_SUBSCRIBE",
  ERROR_SUBSCRIBE: "ERROR_SUBSCRIBE",
  CLEAR_SUBSCRIBE: "CLEAR_SUBSCRIBE",
};

export function subscribeReducer(state = initState, action) {
  switch (action.type) {
    case subscribeTypes.REQUEST_SUBSCRIBE:
      return { ...state, loading: true };
    case subscribeTypes.RESPONSE_SUBSCRIBE:
      return { error: null, loading: false, data: action.payload };
    case subscribeTypes.ERROR_SUBSCRIBE:
      return { loading: false, data: null, error: action.payload };
    case subscribeTypes.CLEAR_SUBSCRIBE:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

//Subscribe with email reducer
export const subscribeWithEmailTypes = {
  SUBSCRIBE_WITH_EMAIL: "SUBSCRIBE_WITH_EMAIL",
  REQUEST_SUBSCRIBE_WITH_EMAIL: "REQUEST_SUBSCRIBE_WITH_EMAIL",
  RESPONSE_SUBSCRIBE_WITH_EMAIL: "RESPONSE_SUBSCRIBE_WITH_EMAIL",
  ERROR_SUBSCRIBE_WITH_EMAIL: "ERROR_SUBSCRIBE_WITH_EMAIL",
  CLEAR_SUBSCRIBE_WITH_EMAIL: "CLEAR_SUBSCRIBE_WITH_EMAIL",
};

export function subscribeWithEmailReducer(state = initState, action) {
  switch (action.type) {
    case subscribeWithEmailTypes.REQUEST_SUBSCRIBE_WITH_EMAIL:
      return { ...state, loading: true };
    case subscribeWithEmailTypes.RESPONSE_SUBSCRIBE_WITH_EMAIL:
      return { error: null, loading: false, data: action.payload };
    case subscribeWithEmailTypes.ERROR_SUBSCRIBE_WITH_EMAIL:
      return { loading: false, data: null, error: action.payload };
    case subscribeWithEmailTypes.CLEAR_SUBSCRIBE_WITH_EMAIL:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

//change email reducer
export const changeEmailReducerTypes = {
  CHANGE_EMAIL: "CHANGE_EMAIL",
  REQUEST_CHANGE_EMAIL: "REQUEST_CHANGE_EMAIL",
  RESPONSE_CHANGE_EMAIL: "RESPONSE_CHANGE_EMAIL",
  ERROR_CHANGE_EMAIL: "ERROR_CHANGE_EMAIL",
  CLEAR_CHANGE_EMAIL: "CLEAR_CHANGE_EMAIL",
};

export function changeEmailReducer(state = initState, action) {
  switch (action.type) {
    case changeEmailReducerTypes.REQUEST_CHANGE_EMAIL:
      return { ...state, loading: true };
    case changeEmailReducerTypes.RESPONSE_CHANGE_EMAIL:
      return { error: null, loading: false, data: action.payload };
    case changeEmailReducerTypes.ERROR_CHANGE_EMAIL:
      return { loading: false, data: null, error: action.payload };
    case changeEmailReducerTypes.CLEAR_CHANGE_EMAIL:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
