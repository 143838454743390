// @ts-nocheck
import { Box, MenuItem } from "@mui/material";
import React, { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/styles";
import { useSelector } from "react-redux";
import { useJobCardStyles } from "../../../styles/cardAndListStyle";

const TooltipForDropdown = ({
  previewOption,
  setOpen,
  previewData,
  setIndividualJobData,
}) => {
  const classes = useJobCardStyles();
  const jobsPostedTabs = useSelector((state) => state.jobsPostedTabs);
  const jobsNavBarState = useSelector((state) => state.jobsNavBar);

  return (
    <Box>
      <MenuItem
        className={classes.dropDownMenu}
        value="Edit job"
        onClick={() => {
          setOpen({ editModal: true });
          setIndividualJobData(previewData);
        }}
      >
        Edit job
      </MenuItem>
      {previewOption && (
        <>
          <Box
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#d7d0e8",
            }}
          />

          <MenuItem
            // sx={{ display: "none" }}
            className={classes.dropDownMenu}
            value="Preview job"
            onClick={() => {
              setOpen({ individualPreviewModal: true });
              setIndividualJobData(previewData);
            }}
          >
            Preview job
          </MenuItem>
        </>
      )}
      {/* {
                !jobsNavBarState.JobsPosted &&
                <>
                    <Box
                        style={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#d7d0e8"
                        }} />
                    <MenuItem
                        className={classes.dropDownMenu}
                        value="Preview job"
                        onClick={
                            () => {
                                setOpen({ individualPreviewModal: true })
                                setIndividualJobData(previewData)
                            }
                        }
                    >
                        Preview job
                    </MenuItem>
                </>
            } */}

      <Box
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#d7d0e8",
        }}
      />
      <MenuItem
        className={classes.dropDownMenu}
        value={jobsPostedTabs.inactive ? "Activate Job" : "Inactivate Job"}
        onClick={() => {
          jobsPostedTabs.inactive
            ? setOpen({ activeJobModal: true })
            : setOpen({ inactiveJobModal: true });
          setIndividualJobData(previewData);
        }}
      >
        {jobsPostedTabs.inactive ? "Activate Job" : "Inactivate Job"}
      </MenuItem>
      <Box
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#d7d0e8",
        }}
      />
      <MenuItem
        className={classes.dropDownMenu}
        value="Delete job"
        onClick={() => {
          setOpen({ deleteJobModal: true });
          setIndividualJobData(previewData);
        }}
      >
        <span style={{ color: "red" }}>Delete job</span>
      </MenuItem>
    </Box>
  );
};

export const BootstrapTooltipForDropdown = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    title={
      <TooltipForDropdown
        setOpen={props.setOpen}
        previewData={props.previewData}
        setIndividualJobData={props.setIndividualJobData}
        open={props.open}
        previewOption={props.previewOption}
      />
    }
    placement="bottom-end"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 1px 24px 0 rgba(43, 38, 57, 0.2)",
    width: "266px",
    borderRadius: "6px",
  },
}));

const TooltipDiv = (props) => {
  const { valueSet, handleOpenToolTip } = props;
  const [copied, setCopied] = useState(false);
  const copy = () => {
    const el = document.createElement("input");

    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
  };

  const {
    ReactComponent: TooltipIcons,
  } = require("../../../assets/icons/newTooltipFacebook.svg");
  const {
    ReactComponent: TooltipIcons1,
  } = require("../../../assets/icons/newTooltipLinkedin.svg");
  const {
    ReactComponent: TooltipIcons2,
  } = require("../../../assets/icons/newTooltipChat.svg");
  const {
    ReactComponent: TooltipIcons3,
  } = require("../../../assets/icons/newTooltipEmail.svg");
  const {
    ReactComponent: TooltipIcons4,
  } = require("../../../assets/icons/newTooltipCopy.svg");
  const {
    ReactComponent: Whatsapp,
  } = require("../../../assets/icons/Social_Icon_Active_Whatsapp.svg");

  return (
    <div
      style={{ padding: "5px ", width: "100%" }}
      className="d-flex justify-content-around"
      onMouseLeave={() => handleOpenToolTip}
    >
      <TooltipIcons style={{ cursor: "pointer" }} />{" "}
      <Whatsapp
        style={{
          cursor: "pointer",
          width: 34,
          height: 34,
        }}
        onClick={() => {
          window.open(
            `https://api.whatsapp.com/send?text=${encodeURIComponent(
              `${window.location.origin}/job/${props?.jobId}`
            )}`
          );
        }}
      />
      <TooltipIcons1 style={{ cursor: "pointer" }} />
      <TooltipIcons2 style={{ cursor: "pointer" }} />
      <TooltipIcons3 style={{ cursor: "pointer" }} />
      <TooltipIcons4
        style={{ cursor: "pointer" }}
        onClick={() => {
          valueSet(false);
          copy();
        }}
      />{" "}
    </div>
  );
};

export const BootstrapTooltipForShare = styled(
  ({
    className,
    valueSet,
    jobId,
    handleOpenToolTip,
    toolTipClick,
    ...props
  }) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      title={
        <TooltipDiv
          valueSet={valueSet}
          jobId={jobId}
          handleOpenToolTip={handleOpenToolTip}
        />
      }
      placement="bottom-end"
      open={toolTipClick == jobId}
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 1px 24px 0 rgba(43, 38, 57, 0.2)",
    width: 250,
    borderRadius: "6px",
    padding: "5px",
  },
}));

export const InformationToolTip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    title="Applicants searching for remote jobs in this location will see this job posting."
    placement="right"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#2b2639",
    backgroundColor: theme.palette.common.white,
    width: "180px",
    boxShadow: "0 10px 24px 0 rgba(122, 72, 255, 0.25)",
    fontSize: "11px",
    padding: "16px 9px 17px 16px",
    borderRadius: "6px",
  },
}));

export const PlanToolTip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    title="Free & not downloadable products excluded"
    placement="right"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#2b2639",
    backgroundColor: theme.palette.common.white,
    width: "183px",
    boxShadow: "0 9px 24px 0 rgba(43, 38, 57, 0.25)",
    fontSize: "11px",
    padding: "16px 9px 17px 16px",
    borderRadius: "6px",
  },
}));

const TooltipForPortfolioProduct = ({
  handleOpenPopupDelete,
  handleOpenPopupRemove,
  index,
}) => {
  const classes = useJobCardStyles();

  return (
    <Box className="threeDot">
      <MenuItem
        className={`${classes.dropDownMenu} threeDot`}
        value="Delete"
        onClick={() => handleOpenPopupDelete(index)}
      >
        <div style={{ margin: "auto" }}>Delete</div>
      </MenuItem>
      <Box
        className="threeDot"
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#d7d0e8",
        }}
      />
      <MenuItem
        className={`${classes.dropDownMenu} threeDot`}
        value="Remove From Grid"
        onClick={() => handleOpenPopupRemove(index)}
      >
        <div style={{ margin: "auto" }}>Remove From Grid</div>
      </MenuItem>
    </Box>
  );
};

export const BootstrapTooltipForPortfolioProduct = styled(
  ({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      title={
        <TooltipForPortfolioProduct
          className="threeDot"
          index={props.index}
          handleOpenPopupDelete={props.handleOpenPopupDelete}
          handleOpenPopupRemove={props.handleOpenPopupRemove}
        />
      }
      placement="bottom-end"
    />
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 1px 24px 0 rgba(43, 38, 57, 0.2)",
    width: "266px",
    borderRadius: "6px",
  },
}));

const TooltipForFileType = (props) => {
  const handleClick = (e) => {
    if (e.target.innerText === "3D") {
      props.handleUploadFileTypeClick({
        _3D: true,
        pattern: false,
        artWork: false,
        sketch: false,
        image: false,
        otherFiles: false,
      });
    } else if (e.target.innerText === "Pattern") {
      props.handleUploadFileTypeClick({
        _3D: false,
        pattern: true,
        artWork: false,
        sketch: false,
        image: false,
        otherFiles: false,
      });
    } else if (e.target.innerText === "Art Work") {
      props.handleUploadFileTypeClick({
        _3D: false,
        pattern: false,
        artWork: true,
        sketch: false,
        image: false,
        otherFiles: false,
      });
    } else if (e.target.innerText === "Sketch") {
      props.handleUploadFileTypeClick({
        _3D: false,
        pattern: false,
        artWork: false,
        sketch: true,
        image: false,
        otherFiles: false,
      });
    }
  };

  const classes = useJobCardStyles();
  const options = ["3D", "Pattern", "Art Work", "Sketch"];

  return (
    <Box className="threeDot">
      {options.map((value, index) => {
        return (
          <MenuItem
            className={`${classes.dropDownMenu} threeDot`}
            key={index}
            sx={{
              borderBottom: "solid 1px",
              borderColor: "#d7d0e8",
              maxHeight: "37px",
              fontSize: "13px",
            }}
            onClick={(value) => {
              handleClick(value);
            }}
          >
            {value}
          </MenuItem>
        );
      })}
      ;
    </Box>
  );
};

export const BootstrapTooltipForFileType = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    title={
      <TooltipForFileType
        className="threeDot"
        handleUploadFileTypeClick={props.handleUploadFileTypeClick}
      />
    }
    placement="bottom"
    disableFocusListener
    disableHoverListener
    disableTouchListener
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0 1px 24px 0 rgba(43, 38, 57, 0.2)",
    width: "150px",
    maxHeight: "147px",
    // overflowY: "scroll",
    borderRadius: "6px",
    padding: "0px",
  },
}));
