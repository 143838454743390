const initialState = {
  home: false,
  categories: false,
  designers: false,
  companies: false,
  products: false,
  jobs: false,
};

export const navBarTypes = {
  NAV_BAR_HOME: "NAV_BAR_HOME",
  NAV_BAR_CATEGORIES: "NAV_BAR_CATEGORIES",
  NAV_BAR_DESIGNERS: "NAV_BAR_DESIGNERS",
  NAV_BAR_COMPANIES: "NAV_BAR_COMPANIES",
  NAV_BAR_PRODUCTS: "NAV_BAR_PRODUCTS",
  NAV_BAR_JOBS: "NAV_BAR_JOBS",
  NAV_BAR_CLEAR: "NAV_BAR_CLEAR",
};

export function navBarReducer(state = initialState, action) {
  switch (action.type) {
    case navBarTypes.NAV_BAR_HOME:
      return { ...initialState, home: true };
    case navBarTypes.NAV_BAR_CATEGORIES:
      return {
        ...initialState, //using deconstrctor
        categories: true,
      };
    case navBarTypes.NAV_BAR_DESIGNERS:
      return {
        ...initialState,
        designers: true,
      };
    case navBarTypes.NAV_BAR_COMPANIES:
      return {
        ...initialState,
        companies: true,
      };
    case navBarTypes.NAV_BAR_PRODUCTS:
      return {
        ...initialState,
        products: true,
      };
    case navBarTypes.NAV_BAR_JOBS:
      return {
        ...initialState,
        jobs: true,
      };

    case navBarTypes.NAV_BAR_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
}

const designersInitialState = {
  allDesignes: true,
  following: false,
  followers: false,
  youngTalent: false,
};

export const designersNavBarTypes = {
  ALL_DESIGNERS: "ALL_DESIGNERS",
  FOLLOWING: "FOLLOWING",
  FOLLOWERS: "FOLLOWERS",
  YOUNG_TALENT: "YOUNG_TALENT",
};

export function designersNavBarReducer(state = designersInitialState, action) {
  switch (action.type) {
    case designersNavBarTypes.ALL_DESIGNERS:
      return {
        allDesignes: true,
        following: false,
        followers: false,
        youngTalent: false,
      };
    case designersNavBarTypes.FOLLOWING:
      return {
        allDesignes: false,
        following: true,
        followers: false,
        youngTalent: false,
      };
    case designersNavBarTypes.FOLLOWERS:
      return {
        allDesignes: false,
        following: false,
        followers: true,
        youngTalent: false,
      };
    case designersNavBarTypes.YOUNG_TALENT:
      return {
        allDesignes: false,
        following: false,
        followers: false,
        youngTalent: true,
      };
    default:
      return state;
  }
}

const productsInitialState = {
  newsFeeds: true,
  designersChoice: false,
  youngTalent: false,
  savedProducts: false,
};

export const productsNavBarTypes = {
  NEWS_FEED: "NEWS_FEED",
  DESIGNERS_CHOICE: "DESIGNERS_CHOICE",
  YOUNG_TALENT_SELECT: "YOUNG_TALENT_SELECT",
  SAVED_PRODUCT_SELECT: "SAVED_PRODUCT_SELECT",
};

export function productsNavBarReducer(state = productsInitialState, action) {
  switch (action.type) {
    case productsNavBarTypes.NEWS_FEED:
      return {
        ...productsInitialState,
        newsFeeds: true,
      };
    case productsNavBarTypes.DESIGNERS_CHOICE:
      return {
        ...productsInitialState,
        newsFeeds: false,
        designersChoice: true,
      };

    case productsNavBarTypes.YOUNG_TALENT_SELECT:
      return {
        ...productsInitialState,
        newsFeeds: false,
        youngTalent: true,
      };

    case productsNavBarTypes.SAVED_PRODUCT_SELECT:
      return {
        ...productsInitialState,
        newsFeeds: false,
        savedProducts: true,
      };
    default:
      return state;
  }
}

const designersProfileInitialState = {
  myWork: true,
  nft: false,
  moodBoards: false,
  liveStreams: false,
  drafts: false,
  myJobs: false,
  visibilityId: "",
};

export const designersProfileNavBarTypes = {
  MY_WORK: "MY_WORK",
  NFT: "NFT",
  MOOD_BOARDS: "MOOD_BOARDS",
  LIVE_STREAMS: "LIVE_STREAMS",
  DRAFTS: "DRAFTS",
  MY_JOBS: "MY_JOBS",
  SET_VISIBILITY: "SET_VISIBILITY",
};

export function designersProfileNavBarReducer(
  state = designersProfileInitialState,
  action
) {
  switch (action.type) {
    case designersProfileNavBarTypes.MY_WORK:
      return {
        ...state,
        myWork: true,
        nft: false,
        moodBoards: false,
        liveStreams: false,
        drafts: false,
        myJobs: false,
      };
    case designersProfileNavBarTypes.NFT:
      return {
        ...state,
        myWork: false,
        nft: true,
        moodBoards: false,
        liveStreams: false,
        drafts: false,
        myJobs: false,
      };
    case designersProfileNavBarTypes.MOOD_BOARDS:
      return {
        ...state,
        myWork: false,
        nft: false,
        moodBoards: true,
        liveStreams: false,
        drafts: false,
        myJobs: false,
      };
    case designersProfileNavBarTypes.LIVE_STREAMS:
      return {
        ...state,
        myWork: false,
        nft: false,
        moodBoards: false,
        liveStreams: true,
        drafts: false,
        myJobs: false,
      };
    case designersProfileNavBarTypes.DRAFTS:
      return {
        ...state,
        myWork: false,
        nft: false,
        moodBoards: false,
        liveStreams: false,
        drafts: true,
        myJobs: false,
      };
    case designersProfileNavBarTypes.MY_JOBS:
      return {
        ...state,
        myWork: false,
        nft: false,
        moodBoards: false,
        liveStreams: false,
        drafts: false,
        myJobs: true,
      };

    case designersProfileNavBarTypes.SET_VISIBILITY:
      return {
        ...state,
        visibilityId: action.payload?.visibilityId || "",
      };
    default:
      return state;
  }
}

const companyProfileInitialState = {
  allCompanies: true,
  followings: false,
  followers: false,
};

export const companyProfileNavBarTypes = {
  All_COMPANIES: "All_COMPANIES",
  FOLLOWINGS: "FOLLOWINGS",
  FOLLOWERS: "FOLLOWERS",
};

export function companyProfileNavBarReducer(
  state = companyProfileInitialState,
  action
) {
  switch (action.type) {
    case companyProfileNavBarTypes.All_COMPANIES:
      return {
        allCompanies: true,
        followings: false,
        followers: false,
      };
    case companyProfileNavBarTypes.FOLLOWINGS:
      return {
        allCompanies: false,
        followings: true,
        followers: false,
      };
    case companyProfileNavBarTypes.FOLLOWERS:
      return {
        allCompanies: false,
        followings: false,
        followers: true,
      };
    default:
      return state;
  }
}

const profileViewInitialState = {
  about: true,
  work: false,
  jobs: false,
  followers: false,
  followings: false,
  employees: false,
};

export const profileViewNavBarTypes = {
  ABOUT: "ABOUT",
  WORK: "WORK",
  JOBS: "JOBS",
  FOLLOWERS: "FOLLOWERS",
  FOLLOWINGS: "FOLLOWINGS",
  EMPLOYEES: "EMPLOYEES",
};

export function profileViewNavBarReducer(
  state = profileViewInitialState,
  action
) {
  switch (action.type) {
    case profileViewNavBarTypes.ABOUT:
      return {
        about: true,
        work: false,
        jobs: false,
        followers: false,
        followings: false,
        employees: false,
      };
    case profileViewNavBarTypes.WORK:
      return {
        about: false,
        work: true,
        jobs: false,
        followers: false,
        followings: false,
        employees: false,
      };
    case profileViewNavBarTypes.JOBS:
      return {
        about: false,
        work: false,
        jobs: true,
        followers: false,
        followings: false,
        employees: false,
      };
    case profileViewNavBarTypes.FOLLOWERS:
      return {
        about: false,
        work: false,
        jobs: false,
        followers: true,
        followings: false,
        employees: false,
      };
    case profileViewNavBarTypes.FOLLOWINGS:
      return {
        about: false,
        work: false,
        jobs: false,
        followers: false,
        followings: true,
        employees: false,
      };
    case profileViewNavBarTypes.EMPLOYEES:
      return {
        about: false,
        work: false,
        jobs: false,
        followers: false,
        followings: false,
        employees: true,
      };
    default:
      return state;
  }
}

///jobs nav bar state

const jobsInitialState = {
  allJobs: true,
  myJobs: false,
  JobsPosted: false,
};

export const jobsNavBarTypes = {
  ALL_JOBS: "ALL_JOBS",
  MY_JOBS: "MY_JOBS",
  JOBS_POSTED: "JOBS_POSTED",
};

export function jobsNavBarReducer(state = jobsInitialState, action) {
  switch (action.type) {
    case jobsNavBarTypes.ALL_JOBS:
      return {
        allJobs: true,
        myJobs: false,
        JobsPosted: false,
      };
    case jobsNavBarTypes.MY_JOBS:
      return {
        allJobs: false,
        myJobs: true,
        JobsPosted: false,
      };
    case jobsNavBarTypes.JOBS_POSTED:
      return {
        allJobs: false,
        myJobs: false,
        JobsPosted: true,
      };
    default:
      return state;
  }
}

//job posted active/inactive/draft state

const jobsPostedState = {
  active: true,
  inactive: false,
  draft: false,
};

export const jobsPostedNavBarTypes = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DRAFT: "DRAFT",
};

export function jobsPostedNavBarReducer(state = jobsPostedState, action) {
  switch (action.type) {
    case jobsPostedNavBarTypes.ACTIVE:
      return {
        active: true,
        inactive: false,
        draft: false,
      };
    case jobsPostedNavBarTypes.INACTIVE:
      return {
        active: false,
        inactive: true,
        draft: false,
      };
    case jobsPostedNavBarTypes.DRAFT:
      return {
        active: false,
        inactive: false,
        draft: true,
      };
    default:
      return state;
  }
}

//Profile Public view nav bar

const profilePublicViewInitialState = {
  about: true,
  work: false,
  follower: false,
  followings: false,
};

export const profilePublicViewNavBarTypes = {
  ABOUT: "ABOUT",
  WORK: "WORK",
  FOLLOWER: "FOLLOWER",
  FOLLOWINGS: "FOLLOWINGS",
};

export function profilePublicViewNavBarReducer(
  state = profilePublicViewInitialState,
  action
) {
  switch (action.type) {
    case profilePublicViewNavBarTypes.ABOUT:
      return {
        about: true,
        work: false,
        follower: false,
        followings: false,
      };
    case profilePublicViewNavBarTypes.WORK:
      return {
        about: false,
        work: true,
        follower: false,
        followings: false,
      };

    case profilePublicViewNavBarTypes.FOLLOWER:
      return {
        about: false,
        work: false,
        follower: true,
        followings: false,
      };
    case profilePublicViewNavBarTypes.FOLLOWINGS:
      return {
        about: false,
        work: false,
        follower: false,
        followings: true,
      };

    default:
      return state;
  }
}

//Profile Public view  followers nav buttton

const profilePublicFollowersNavInitialState = {
  all: true,
  designer: false,
  company: false,
};

export const profilePublicFollowersNavBarType = {
  ALL: "ALL",
  DESIGNER: "DESIGNER",
  COMPANY: "COMPANY",
};

export function publicViewfollowersNavBarReducer(
  state = profilePublicFollowersNavInitialState,
  action
) {
  switch (action.type) {
    case profilePublicFollowersNavBarType.ALL:
      return {
        all: true,
        designer: false,
        company: false,
      };
    case profilePublicFollowersNavBarType.DESIGNER:
      return {
        all: false,
        designer: true,
        company: false,
      };
    case profilePublicFollowersNavBarType.COMPANY:
      return {
        all: false,
        designer: false,
        company: true,
      };
    default:
      return state;
  }
}

//designer profile card edit option tab -------->>

const designerProfileCardOptionState = {
  edit: false,
  preview: false,
};

export const designerProfileCardOptionStateType = {
  OPEN_EDIT: "OPEN_EDIT",
  OPEN_PREVIEW: "OPEN_PREVIEW",
  CLOSE_EDIT: "CLOSE_EDIT",
  CLOSE_PREVIEW: "CLOSE_PREVIEW",
  OPEN_ALL: "OPEN_ALL",
  OPEN_FOR_ADD: "OPEN_FOR_ADD",
};

export function designerProfileCardOptionReducer(
  state = designerProfileCardOptionState,
  action
) {
  switch (action.type) {
    case designerProfileCardOptionStateType.OPEN_EDIT:
      return {
        edit: true,
        preview: false,
      };
    case designerProfileCardOptionStateType.OPEN_PREVIEW:
      return {
        edit: false,
        preview: true,
      };
    case designerProfileCardOptionStateType.OPEN_ALL:
      return {
        edit: true,
        preview: true,
      };
    case designerProfileCardOptionStateType.CLOSE_EDIT:
      return {
        edit: false,
        preview: false,
      };
    case designerProfileCardOptionStateType.CLOSE_PREVIEW:
      return {
        edit: true,
        preview: false,
      };
    case designerProfileCardOptionStateType.OPEN_FOR_ADD:
      return {
        edit: false,
        preview: true,
      };
    default:
      return state;
  }
}

const companyEmployeesState = {
  employees: true,
  permissions: false,
};

export const companyEmployeesTypes = {
  EMPLOYEES_MENU_SELECT: "EMPLOYEES_MENU_SELECT",
  PERMSSION_MENU_SELECT: "PERMSSION_MENU_SELECT",
};

export function companyEmployeesReducer(state = companyEmployeesState, action) {
  switch (action.type) {
    case companyEmployeesTypes.EMPLOYEES_MENU_SELECT:
      return {
        employees: true,
        permissions: false,
      };
    case companyEmployeesTypes.PERMSSION_MENU_SELECT:
      return {
        employees: false,
        permissions: true,
      };

    default:
      return state;
  }
}

export const myProfileSectionTypes = {
  SET_TYPE: "SET_TYPE",
};

export function myProfileSectionReducer(
  state = { selected: "product" },
  action
) {
  switch (action.type) {
    case myProfileSectionTypes.SET_TYPE:
      return {
        selected: action.payload.selected,
      };

    default:
      return state;
  }
}
