/* eslint-disable no-console*/
/*
 **	User name
 **	Created	5/25/2023
 **	Axios.ts
 */

import axios from "axios";
import { accessTokenSelector } from "app/redux/selectors/user-selector";
import { select, put } from "redux-saga/effects";
import { userRegisterReducerTypes } from "../redux/reducers/userReducer";

const getBaseUrl = () => {
  try {
    const host = window.location.host;

    if (host) {
      if (host.indexOf("designerscapitol") > -1) {
        return process.env.REACT_APP_LIVE_BASE_URL;
      }

      if (host.indexOf("live") > -1) {
        return process.env.REACT_APP_LIVE_BASE_URL;
      }

      if (host.indexOf("play") > -1) {
        return process.env.REACT_APP_PLAY_BASE_URL;
      }

      if (host.indexOf("dev") > -1) {
        return process.env.REACT_APP_DEV_BASE_URL;
      }
    }

    return process.env.REACT_APP_DEV_BASE_URL;
  } catch (error) {
    console.error("Error in the fetching base url so serving dev url", error);

    return process.env.REACT_APP_DEV_BASE_URL;
  }
};

export function axiosCall({
  accessToken,
  method,
  url,
  config,
  payload,
  headers,
}) {
  const requestData = {
    method,
    url: getBaseUrl() + url,
    ...config,
  };

  if (method === "get") {
    requestData.params = payload;
  } else if (method === "put") {
    requestData.data = payload;
  } else {
    if (payload) requestData.data = payload;
  }
  if (headers === 1) {
    requestData.headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };
  }
  if (headers === "form-data") {
    requestData.headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
    };
  }

  requestData.headers = {
    ...requestData.headers,
    Authorization: `Bearer ${accessToken}`,
    "Access-Control-Allow-Origin": "*",
  };

  return () => axios(requestData);
}

export function* request(
  method: any,
  url: any,
  payload?: any,
  headers?: any,
  config?: any
) {
  try {
    const accessToken = yield select(accessTokenSelector);

    const result = yield axiosCall({
      accessToken,
      method,
      url,
      config,
      payload,
      headers,
    })();

    return result;
  } catch (error) {
    if (error?.response?.status === 401) {
      yield put({ type: userRegisterReducerTypes.LOGOUT_USER });
      return null;
    }
    // console.log("Error in the API", url, error);
    throw new Error(error);
  }
}
