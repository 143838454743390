/*
 **	User name
 **	Created	6/25/2023
 **	OneDayLeftModal.tsx
 */

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Modal, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BlackButton } from "app/components/UIFormFields";
import { SavePopUpSettingsActions } from "app/redux/reducers/settings";
import { accountGuidSelector } from "app/redux/selectors/user-selector";
import { useNavigate } from "react-router";
import RouteLinks from "app/navigation/RouteLinks";

const useHomeAreaStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "540px !important",
    minHeight: "500px !important",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
}));

export default function OneDayLeftModal() {
  const dispatch = useDispatch();
  const classes = useHomeAreaStyles();
  const accountGuid = useSelector(accountGuidSelector);
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    ReactComponent: SubscribeGraphic,
  } = require("../../../assets/logo/Subscribe Graphic.svg");

  const handleClose = () => {
    dispatch(
      SavePopUpSettingsActions.savePopUpSettings({
        isOneDayPlanLeftPopupCancelled: true,
        accountGuid,
      })
    );
    navigate(RouteLinks.LICENSE);
  };

  return (
    <Modal open={true}>
      <Box
        className={classes?.modalStyle}
        style={{
          backgroundColor: theme?.palette?.mode === "light" && "#fff",
        }}
      >
        <Box
          style={{
            backgroundImage: "linear-gradient(68deg, #f54d64 0%, #f58561 99%)",
            width: "100%",
            height: "250px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SubscribeGraphic />
          </Box>
        </Box>
        <Typography
          style={{
            fontSize: "27px",
            fontWeight: "bold",
            color: "#2b2639",
            textAlign: "center",
          }}
        >
          Only 1 day left
        </Typography>
        <Typography
          style={{
            fontSize: "15px",
            color: "#2b2639",
            textAlign: "center",
          }}
        >
          Your free trial will expire in 1 day. To continue using unlimited
          services, subscribe now.
        </Typography>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px",
          }}
        >
          <BlackButton style={{ width: "104px" }} onClick={handleClose}>
            See Plans
          </BlackButton>
        </Box>
      </Box>
    </Modal>
  );
}
