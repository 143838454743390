import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles({
  dropdown: {
    transition: "all 0.7s ease",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 16,
  },
  dropdownActive: {
    height: "0",
    display: "none",
  },
  icon: {},
  iconActive: {
    transform: "rotate(180deg)",
  },
});

export const AttributeBox = ({ children, title }) => {
  const classes = useStyles();
  const [active, setActive] = useState(false);
  const isFilter =
    (sessionStorage.getItem("filterStatus") === "1" && true) || false;
  const {
    ReactComponent: IconSvg,
  } = require("../../../assets/icons/categories/Icons_Light_Dropdown Icon.svg");

  return (
    <Box
      style={{
        display: "flex",
        borderBottom: !isFilter && "1px solid #d7d0e8",
        paddingTop: 16,
        flexDirection: "column",
        maxWidth: isFilter && "330px",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: 16,
        }}
      >
        <Box style={{ flex: 1 }}>
          <Typography
            style={{
              fontWeight: "bold",
              color: "#a49bbe",
              fontSize: 15,
              textAlign: "left",
            }}
          >
            {title}
          </Typography>
        </Box>
        {!isFilter && (
          <Box style={{ cursor: "pointer" }} onClick={() => setActive(!active)}>
            <IconSvg
              className={classNames(classes.icon, {
                [classes.iconActive]: active,
              })}
            />
          </Box>
        )}
      </Box>
      <Box
        className={classNames(classes.dropdown, {
          [classes.dropdownActive]: active,
        })}
      >
        {children}
      </Box>
    </Box>
  );
};
export default AttributeBox;
