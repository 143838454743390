import { IRootState } from "../reducers";

export const testSelector = () => {
  return null;
};

export const companyIdSelector = (state: IRootState) => {
  const userAdditionalInfo = state?.register?.userAdditionalInfo;

  if (userAdditionalInfo?.companyId) {
    return userAdditionalInfo.companyId;
  }

  if (userAdditionalInfo?.accountID) {
    return userAdditionalInfo.accountID;
  }

  return 0;
};

export const permissionsDataSelector = (state: IRootState) =>
  state?.companyPermissions;
