import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { resumesActionTypes } from "../reducers/resumes";

const version = process.env.REACT_APP_API_VERSION;

function* saveUserResumesRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.SAVE_USER_RESUMES.replace("{version}", version),
    payload,
    "form-data"
  );

  return res;
}

function* saveUserResumes({ payload, accountGuid }) {
  try {
    yield put({
      type: resumesActionTypes.SAVE_USER_RESUMES_REQUEST,
    });
    const response = yield call(saveUserResumesRequest, payload);

    yield put({
      type: resumesActionTypes.SAVE_USER_RESUMES_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: resumesActionTypes.GET_ALL_RESUMES,
      payload: { accountGuid },
    });
  } catch (e) {
    yield put({
      type: resumesActionTypes.SAVE_USER_RESUMES_FAILURE,
      payload: e,
    });
  }
}

function* fetchAllResumesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_RESUMES.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchAllResumes({ payload }) {
  try {
    yield put({
      type: resumesActionTypes.GET_ALL_RESUMES_REQUEST,
    });
    const response = yield call(fetchAllResumesRequest, payload);

    yield put({
      type: resumesActionTypes.GET_ALL_RESUMES_SUCCESS,
      payload: response?.data?.map((file) => ({
        ...file,
        name: file.fileName,
        size: file.fileSize,
      })),
    });
  } catch (e) {
    yield put({
      type: resumesActionTypes.GET_ALL_RESUMES_FAILURE,
      payload: e,
    });
  }
}

function* deleteResumeByIdRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.DELETE_RESUME_BY_ID.replace("{version}", version),
    payload
  );

  return res;
}

function* deleteResumeById({ payload }) {
  try {
    yield put({
      type: resumesActionTypes.DELETE_RESUME_BY_ID_REQUEST,
    });
    const response = yield call(deleteResumeByIdRequest, payload);

    yield put({
      type: resumesActionTypes.DELETE_RESUME_BY_ID_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: resumesActionTypes.GET_ALL_RESUMES,
      payload: { accountGuid: payload.accountGuid },
    });
  } catch (e) {
    yield put({
      type: resumesActionTypes.DELETE_RESUME_BY_ID_FAILURE,
      payload: e,
    });
  }
}

function* saveUserResumePositionRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SAVE_RESUMES_POSITION.replace("{version}", version),
    payload
  );

  return res;
}

function* saveUserResumePosition({ payload, accountGuid }) {
  try {
    yield put({
      type: resumesActionTypes.SAVE_RESUMES_POSITION_REQUEST,
    });
    const response = yield call(saveUserResumePositionRequest, payload);

    yield put({
      type: resumesActionTypes.SAVE_RESUMES_POSITION_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: resumesActionTypes.GET_ALL_RESUMES,
      payload: { accountGuid },
    });
  } catch (e) {
    yield put({
      type: resumesActionTypes.SAVE_RESUMES_POSITION_FAILURE,
      payload: e,
    });
  }
}

function* combinedSaga() {
  yield all([
    takeLatest(resumesActionTypes.SAVE_USER_RESUMES, saveUserResumes),
    takeLatest(resumesActionTypes.GET_ALL_RESUMES, fetchAllResumes),
    takeLatest(resumesActionTypes.DELETE_RESUME_BY_ID, deleteResumeById),
    takeLatest(
      resumesActionTypes.SAVE_RESUMES_POSITION,
      saveUserResumePosition
    ),
  ]);
}

export default combinedSaga;
