/*
 **	User name
 **	Created	5/31/2023
 **	CategoryDataProvider.tsx
 */

import React, { useState, useEffect } from "react";
import { isEqual } from "lodash";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { IRootState } from "app/redux/reducers";
import { categoriesActionTypes } from "app/redux/reducers/categories";

import { CategoryAllData, CategoryDataContext } from "./CategoryDataContext";

const useCategoryData = () => {
  const dispatch = useDispatch();
  const categoriesDataMain = useSelector(
    (state: IRootState) => state.categories,
    shallowEqual
  );
  const [treeData, setTreeData] = useState<Array<any>>([]);
  const [productCategoriesTreeData, setProductCategoriesTreeData] = useState<
    Array<any>
  >([]);
  const [attributesTreeData, setAttributesTreeData] =
    useState<Array<any>>(null);
  const [colorData, setColorData] = useState([]);
  const [showAttributes, setShowAttributes] = useState(false);
  const [showProductCategories, setShowProductCategories] = useState(false);
  const [showColorSwatch, setShowColorSwatch] = useState(false);
  const [arrtibutesLength, setArrtibutesLength] = useState(0);
  const [productCategoryConditions, setProductCategoryConditions] = useState({
    excludeCategories: [],
    includeCategories: [],
  });

  const [selectedData, setSelectedData] = useState<any>(null);

  const clearAll = () => {
    setTreeData([]);
    setAttributesTreeData([]);
    setProductCategoriesTreeData([]);
    setColorData([]);
    setShowAttributes(false);
    setShowProductCategories(false);
    setShowColorSwatch(false);
    setArrtibutesLength(0);
    setProductCategoryConditions({
      excludeCategories: [],
      includeCategories: [],
    });
  };

  const libraryCategoryData = categoriesDataMain?.libraryCategoryData || {};
  const productCategoryData = categoriesDataMain?.productCategoryData || {};
  const attributesData = categoriesDataMain?.attributesData || {};

  useEffect(() => {
    const setData = (data) => {
      if (data?.libraryCategory) {
        setTreeData(
          data?.libraryCategory.map((item, index) => {
            const { id } = item;

            return { ...libraryCategoryData?.categoryMaps?.[id], index };
          })
        );
      }

      if (data?.colors) {
        setShowColorSwatch(true);
        setColorData(data?.colors);
      }

      if (data?.productCategory) {
        const productData = [];

        if (data?.productCategory?.length) {
          productData.push({
            ...productCategoryData?.categoryMaps?.[
              data?.productCategory?.[0].id
            ],
            index: 0,
          });

          productData.push({
            ...productCategoryData?.categoryMaps?.[
              data?.productCategory?.[2].id
            ],
            index: 1,
            parentCategory: {
              ...productCategoryData?.categoryMaps?.[
                data?.productCategory?.[1].id
              ],
            },
            selectedProductCategory: {
              categoryId: data?.productCategory?.[1].id,
              childCategoryId: data?.productCategory?.[2].id,
            },
          });
          setShowProductCategories(true);

          if (data?.productCategory?.[2].id) {
            dispatch({
              type: categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY,
              payload: { CategoryId: data?.productCategory?.[2].id },
            });
            setShowAttributes(true);
          }
        }
        setProductCategoriesTreeData(productData);
      }
    };

    setData(selectedData);
  }, [selectedData]);

  useEffect(() => {
    const setData = (data) => {
      if (
        data?.attributes &&
        attributesTreeData === null &&
        attributesData?.attributesMap
      ) {
        const newData = data?.attributes.map((item, index) => {
          const { id, attributeValues } = item;

          return {
            ...attributesData?.attributesMap?.[id],
            index,
            selectedAttribute: [...attributeValues?.map(({ id }) => id)],
          };
        });

        if (!isEqual(newData, attributesTreeData)) {
          setAttributesTreeData(newData);
          setArrtibutesLength(newData?.length || 0);
        }
      }
    };

    setData(selectedData);
  }, [selectedData, attributesData, attributesTreeData]);

  return {
    treeData,
    setTreeData,
    attributesTreeData,
    setAttributesTreeData,
    productCategoriesTreeData,
    setProductCategoriesTreeData,
    showAttributes,
    setShowAttributes,
    showProductCategories,
    setShowProductCategories,
    showColorSwatch,
    setShowColorSwatch,
    arrtibutesLength,
    setArrtibutesLength,
    colorData,
    setColorData,
    productCategoryConditions,
    setProductCategoryConditions,
    clearAll,
    setData: setSelectedData,
    selectedData,
  };
};

export const CategoryDataProvider = ({ children }) => {
  const categoriesDataMain = useSelector(
    (state: IRootState) => state.categories,
    shallowEqual
  );
  const libraryData: CategoryAllData = useCategoryData();
  const productData: CategoryAllData = useCategoryData();

  return (
    <CategoryDataContext.Provider
      value={{ libraryData, productData, categoriesDataMain }}
    >
      {children}
    </CategoryDataContext.Provider>
  );
};
