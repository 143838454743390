import { handlePaginationData, handlePaginationLoadingData } from "./helper";

const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const libraryActionTypes = {
  LIBRARY_UPLOAD: "LIBRARY_UPLOAD",
  REQUEST_LIBRARY_UPLOAD: "REQUEST_LIBRARY_UPLOAD",
  RESPONSE_LIBRARY_UPLOAD: "RESPONSE_LIBRARY_UPLOAD",
  ERROR_LIBRARY_UPLOAD: "ERROR_LIBRARY_UPLOAD",
  CLEAR_LIBRARY_UPLOAD: "CLEAR_LIBRARY_UPLOAD",
};

export function libraryReducer(state = initialState, action) {
  switch (action.type) {
    case libraryActionTypes.REQUEST_LIBRARY_UPLOAD:
      return { ...state, error: null, data: null, loading: true };
    case libraryActionTypes.RESPONSE_LIBRARY_UPLOAD:
      return { error: null, loading: false, data: action.payload };
    case libraryActionTypes.ERROR_LIBRARY_UPLOAD:
      return { loading: false, data: null, error: action.payload };
    case libraryActionTypes.CLEAR_LIBRARY_UPLOAD:
      return { ...state, loading: false, data: null, error: null };
    default:
      return state;
  }
}

export const libraryProductListReducerTypes = {
  LIBRARY_PRODUCT_LIST: "LIBRARY_PRODUCT_LIST",
  REQUEST_LIBRARY_PRODUCT_LIST: "REQUEST_LIBRARY_PRODUCT_LIST",
  RESPONSE_LIBRARY_PRODUCT_LIST: "RESPONSE_LIBRARY_PRODUCT_LIST",
  ERROR_LIBRARY_PRODUCT_LIST: "ERROR_LIBRARY_PRODUCT_LIST",
  CLEAR_LIBRARY_PRODUCT_LIST: "CLEAR_LIBRARY_PRODUCT_LIST",

  SAVED_LIBRARY_PRODUCT_LIST: "SAVED_LIBRARY_PRODUCT_LIST",
  REQUEST_SAVED_LIBRARY_PRODUCT_LIST: "REQUEST_SAVED_LIBRARY_PRODUCT_LIST",
  RESPONSE_SAVED_LIBRARY_PRODUCT_LIST: "RESPONSE_SAVED_LIBRARY_PRODUCT_LIST",
  ERROR_SAVED_LIBRARY_PRODUCT_LIST: "ERROR_SAVED_LIBRARY_PRODUCT_LIST",
};

export function libraryProductListReducer(state = initialState, action) {
  switch (action.type) {
    case libraryProductListReducerTypes.REQUEST_LIBRARY_PRODUCT_LIST: {
      const newData = handlePaginationLoadingData({
        payload: action.payload,
        oldData: state?.data,
      });

      return {
        ...state,
        loading: true,
        data: newData,
      };
    }
    case libraryProductListReducerTypes.RESPONSE_LIBRARY_PRODUCT_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "productLibraryId",
      });

      return {
        ...state,
        loading: false,
        data: newData,
        error: null,
      };
    }
    case libraryProductListReducerTypes.ERROR_LIBRARY_PRODUCT_LIST:
      return { ...state, loading: false, error: action.payload };

    case libraryProductListReducerTypes.CLEAR_LIBRARY_PRODUCT_LIST:
      return initialState;

    case libraryProductListReducerTypes.REQUEST_SAVED_LIBRARY_PRODUCT_LIST: {
      const newData = handlePaginationLoadingData({
        payload: action.payload,
        oldData: state?.data,
      });

      return {
        ...state,
        loading: true,
        data: newData,
      };
    }
    case libraryProductListReducerTypes.RESPONSE_SAVED_LIBRARY_PRODUCT_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "productLibraryId",
      });

      return {
        ...state,
        loading: false,
        data: newData,
        error: null,
      };
    }
    case libraryProductListReducerTypes.ERROR_SAVED_LIBRARY_PRODUCT_LIST:
      return { ...state, loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

//@save product

export const saveLibraryProductTypes = {
  SAVE_LIBRARY_PRODUCT: "SAVE_LIBRARY_PRODUCT",
  REQUEST_SAVE_LIBRARY_PRODUCT: "REQUEST_SAVE_LIBRARY_PRODUCT",
  RESPONSE_SAVE_LIBRARY_PRODUCT: "RESPONSE_SAVE_LIBRARY_PRODUCT",
  ERROR_SAVE_LIBRARY_PRODUCT: "ERROR_SAVE_LIBRARY_PRODUCT",
};

export function saveProductReducer(state = initialState, action) {
  switch (action.type) {
    case saveLibraryProductTypes.REQUEST_SAVE_LIBRARY_PRODUCT:
      return { ...state, loading: true };
    case saveLibraryProductTypes.RESPONSE_SAVE_LIBRARY_PRODUCT:
      return { error: null, loading: false, data: action.payload };
    case saveLibraryProductTypes.ERROR_SAVE_LIBRARY_PRODUCT:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}
