const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const notificationsReducerTypes = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  REQUEST_GET_NOTIFICATIONS: "REQUEST_GET_NOTIFICATIONS",
  RESPONSE_GET_NOTIFICATIONS: "RESPONSE_GET_NOTIFICATIONS",
  ERROR_GET_NOTIFICATIONS: "ERROR_GET_NOTIFICATIONS",
};

export function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case notificationsReducerTypes.REQUEST_GET_NOTIFICATIONS:
      return { ...state, loading: true };
    case notificationsReducerTypes.RESPONSE_GET_NOTIFICATIONS:
      return { error: null, loading: false, data: action.payload };
    case notificationsReducerTypes.ERROR_GET_NOTIFICATIONS:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

const initialState_1 = {
  loading: false,
  data: null,
  error: null,
};

export const requestReceived = {
  REQUEST: "REQUEST",
  REQUEST_REQUEST: "REQUEST_REQUEST",
  RESPONSE_REQUEST: "RESPONSE_REQUEST",
  ERROR_REQUEST: "ERROR_REQUEST",
  CLEAR_REQUEST: "CLEAR_REQUEST",
};

export function requestReceivedReducer(state = initialState_1, action) {
  switch (action.type) {
    case requestReceived.REQUEST_REQUEST:
      return { ...state, loading: true };
    case requestReceived.RESPONSE_REQUEST:
      return { error: null, loading: false, data: action.payload };
    case requestReceived.ERROR_REQUEST:
      return { loading: false, data: null, error: action.payload };
    case requestReceived.CLEAR_REQUEST:
      return { loading: false, data: action.payload, error: null };
  }
}
