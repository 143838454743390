/*
 **	User name
 **	Created	5/25/2023
 **	App.tsx
 */

import React, { Suspense, useContext } from "react";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Theme } from "@mui/material/styles";
import { JssProvider } from "react-jss";
import { Helmet } from "react-helmet";

import { CategoryDataProvider } from "app/components/CategoryComponent/CategoryDataProvider";
import { PurchaseProvider } from "context/PurchaseProvider";
import { UploadProvider } from "context/UploadProvider";
import { AppPermissionsProvider } from "context/AppPermissionsProvider";
import { FreeTrialProvider } from "context/FreeTrialProvider";
import { CookieManagerProvider } from "context/CookieManagerProvider";
import { JobPostProvider } from "context/JobPostProvider";
import UniversalLoader from "app/components/Loader";

import { AppInitProvider } from "./AppInitProvider";
import { ColorModeContext } from "./ColorModeContext";
import { Navigation } from "./app/navigation";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import "react-circular-progressbar/dist/styles.css";

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

// Root function
export function App() {
  const colorMode = useContext(ColorModeContext);
  // #2b2639
  // Generate a theme base on the options received.
  const theme = createTheme({
    palette: {
      primary: {
        main: colorMode?.mode === "light" ? "#2b2639" : "#fff",
      },
      // text: {
      //   primary: colorMode?.mode === 'light' ? "rgba(0, 0, 0, 0.87)" : "#fff",
      //   secondary: colorMode?.mode === 'light' ? "rgba(0, 0, 0, 0.6)" : "rgba(225, 225, 225, 0.7)",
      //   disabled: colorMode?.mode === 'light' ? "rgba(0, 0, 0, 0.38)" : " rgba(225, 225, 225, 0.5)",
      //   icon: colorMode?.mode === 'light' ? "rgba(0, 0, 0, 0.6)" : "rgba(225, 225, 225, 0.5)",
      // },
      // background: {
      //   paper: colorMode?.mode === 'light' ? "#fff" : "#121212",
      //   default: colorMode?.mode === 'light' ? "#fff" : "#121212",
      // }
    },
    typography: {
      fontFamily: [
        "Poppins-Bold",
        "Poppins",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
  });

  const isLive = (() => {
    const host = window.location.host;

    if (host) {
      if (host.indexOf("designerscapitol") > -1) {
        return true;
      }
    }

    return false;
  })();

  return (
    <Suspense fallback={<UniversalLoader />}>
      {isLive && (
        <Helmet>
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-DM67QE1NW8"
          ></script>
          <script>
            {`
     window.dataLayer = window.dataLayer || [];
     function gtag() { dataLayer.push(arguments); }
     gtag('js', new Date());

     gtag('config', 'G-DM67QE1NW8', {'anonymize_ip' : true});`}
          </script>
        </Helmet>
      )}
      <JssProvider>
        <ThemeProvider theme={theme}>
          <AppInitProvider>
            <CookieManagerProvider>
              <AppPermissionsProvider>
                <FreeTrialProvider>
                  <PurchaseProvider>
                    <CategoryDataProvider>
                      <UploadProvider>
                        <JobPostProvider>
                          <CssBaseline />
                          <Navigation />
                        </JobPostProvider>
                      </UploadProvider>
                    </CategoryDataProvider>
                  </PurchaseProvider>
                </FreeTrialProvider>
              </AppPermissionsProvider>
            </CookieManagerProvider>
          </AppInitProvider>
        </ThemeProvider>
      </JssProvider>
    </Suspense>
  );
}
