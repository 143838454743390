// @ts-nocheck
import { Box, FormHelperText, Input, Typography } from "@mui/material";
import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { isImage } from "../../utils/helper";

function UploadThumbnail({
  uploadFile,
  uploadProductFile,
  handleReupload,
  onUploadFile,
  handleOnUpload,
  errors,
}) {
  const {
    ReactComponent: UploadIcon,
  } = require("../../../assets/icons/Icons_Light_Thumbnail-2.svg");
  const {
    ReactComponent: AddIcon,
  } = require("../../../assets/icons/Icons_Light_Add.svg");

  const handleFinalUpload = (event) => {
    ["png", "jpg", "glb", "gltf", "obj", "mtl", "zprj"].includes(
      event.target?.files[0]?.name?.split(".").pop()
    )
      ? (onUploadFile(event), handleOnUpload(event.target.files[0]))
      : alert("file format not valid");
  };

  const urlObj = React.useMemo(() => {
    if (uploadFile) {
      return URL.createObjectURL(uploadFile);
    }

    return null;
  }, [uploadFile]);

  return (
    <>
      <Box
        style={{
          border: !errors?.thumbnail ? "1px dashed #d7d0e8" : "1px dashed red",
          width: 400,
          minHeight: 306,
          display: "flex",
          flexDirection: "column",
          borderRadius: 6,
          overflow: "hidden",
        }}
      >
        {/* uploadFile['type'].split('/')[0] === 'image' */}
        {uploadFile !== null ? (
          <Box style={{ position: "relative" }}>
            {uploadProductFile?.dataThumbnail?.responseCode === 200 ? (
              // isImage(URL.createObjectURL(uploadFile)) !== null &&
              // isImage(URL.createObjectURL(uploadFile)) !== false
              isImage(uploadProductFile?.dataThumbnail?.urlDetails) !== null ? (
                <img
                  src={uploadProductFile?.dataThumbnail?.urlDetails}
                  alt="product"
                  style={{
                    width: 400,
                    height: 306,
                    borderRadius: 5,
                    // border: "2px solid #ccc",
                  }}
                  // onClick={() => onCardClick(item)}
                  // className={classes.viewPort}
                />
              ) : (
                <model-viewer
                  style={{
                    width: 400,
                    height: 306,
                    borderRadius: 5,
                    // border: "2px solid #ccc",
                  }}
                  src={uploadProductFile?.dataThumbnail?.urlDetails}
                  ios-src=""
                  alt="model name"
                  camera-controls
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  environment-image="neutral"
                  oncontextmenu="return false;"
                  // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
                  autoplay
                ></model-viewer>
              )
            ) : (
              <Box
                style={{
                  width: 388,
                  height: 246,
                  borderRadius: 5,
                  border: "2px solid #ccc",
                  backgroundColor: "rgba(0,0,0,0.263)",
                  display: "flex",
                  // filter: "blur(1px)",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: 149,
                    height: 149,
                    zIndex: 100,
                    position: "fixed",
                  }}
                >
                  <CircularProgressbar
                    strokeWidth={5}
                    value={
                      uploadProductFile?.progressThumbnail === undefined ||
                      uploadProductFile?.progressThumbnail === null
                        ? 0
                        : uploadProductFile?.progressThumbnail
                    }
                    text={
                      uploadProductFile?.progressThumbnail === undefined ||
                      uploadProductFile?.progressThumbnail === null
                        ? "0%"
                        : `${parseInt(uploadProductFile.progressThumbnail)}%`
                    }
                    styles={buildStyles({
                      rotation: 0.25,
                      textSize: "16px",
                      pathTransitionDuration: 0.5,
                      textColor: "#fff",
                      pathColor: "#04AA6D",
                      trailColor: "#fff",
                    })}
                  />
                </div>
                <Box
                  style={{
                    position: "relative",
                    opacity: "0.5",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {uploadFile["type"].split("/")[0] === "image" ? (
                    <img
                      width="100%"
                      height="100%"
                      alt="uploaded product"
                      src={URL.createObjectURL(uploadFile)}
                    ></img>
                  ) : (
                    <model-viewer
                      style={{
                        width: 400,
                        height: 306,
                        borderRadius: 5,
                        opacity: 0.8,
                      }}
                      src={URL.createObjectURL(uploadFile)}
                      ios-src=""
                      alt="model name"
                      camera-controls
                      ar
                      ar-modes="webxr scene-viewer quick-look"
                      environment-image="neutral"
                      oncontextmenu="return false;"
                      // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
                      autoplay
                    ></model-viewer>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <label
              style={{
                width: "100%",
                // borderRadius: 6,
                // border: "1px solid #a49bbe",
                // borderStyle: "dashed",
                // width: 250,
                // height: 250,
                // display: "flex",
                // flexDirection: "column",
              }}
              htmlFor="file"
            >
              <Input
                className="box__file"
                type="file"
                name="files"
                id="file"
                hidden
                ref={handleReupload}
                onChange={handleFinalUpload}
                accept="image/*"
              />
              <Box
                style={{
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    marginTop: 54,
                    fontSize: 15,
                    color: "#a49bbe",
                    fontWeight: "bold",
                    marginBottom: 34,
                  }}
                >
                  Upload Product Thumbnail
                </Typography>
                <UploadIcon />
                <Typography
                  style={{
                    marginTop: 24,
                    fontSize: 11,
                    color: "#2b2639",
                    marginBottom: 26,
                  }}
                >
                  Preview might not be available for all file types
                </Typography>
              </Box>
              <Box
                style={{
                  background: "#2b2639",
                  height: 54,
                  borderRadius: "0px 0px 6px 6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <Typography
                  style={{
                    fontSize: 13,
                    color: "#ffffff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <AddIcon style={{ marginRight: 10 }} />
                  Upload Thumbnail
                </Typography>
              </Box>
            </label>
          </Box>
        )}
      </Box>
      <FormHelperText
        sx={{
          fontSize: "11px",
          fontWeight: "bold",
          color: "#ff4f47",
        }}
      >
        {errors?.thumbnail}
      </FormHelperText>
    </>
  );
}

export default React.memo(UploadThumbnail);
