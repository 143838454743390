const initState = {
  loading: false,
  data: null,
  error: null,
};

//UPLOAD CERTIFICATE
export const uploadCertificateReducerTypes = {
  UPLOAD_CERTIFICATE: "UPLOAD_CERTIFICATE",
  REQUEST_UPLOAD_CERTIFICATE: "REQUEST_UPLOAD_CERTIFICATE",
  RESPONSE_UPLOAD_CERTIFICATE: "RESPONSE_UPLOAD_CERTIFICATE",
  ERROR_UPLOAD_CERTIFICATE: "ERROR_UPLOAD_CERTIFICATE",
  CLEAR_UPLOAD_CERTIFICATE: "CLEAR_UPLOAD_CERTIFICATE",
};

export function uploadCertificateReducer(state = initState, action) {
  switch (action.type) {
    case uploadCertificateReducerTypes.REQUEST_UPLOAD_CERTIFICATE:
      return { ...state, loading: true };
    case uploadCertificateReducerTypes.RESPONSE_UPLOAD_CERTIFICATE:
      return { error: null, loading: false, data: action.payload };
    case uploadCertificateReducerTypes.ERROR_UPLOAD_CERTIFICATE:
      return { loading: false, data: null, error: action.payload };
    case uploadCertificateReducerTypes.CLEAR_UPLOAD_CERTIFICATE:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

//LIST OF CERTIFICATES
export const CertificateListReducerTypes = {
  CERTIFICATE_LIST: "CERTIFICATE_LIST",
  REQUEST_CERTIFICATE_LIST: "REQUEST_CERTIFICATE_LIST",
  RESPONSE_CERTIFICATE_LIST: "RESPONSE_CERTIFICATE_LIST",
  ERROR_CERTIFICATE_LIST: "ERROR_CERTIFICATE_LIST",
};

export function certificateListReducer(state = initState, action) {
  switch (action.type) {
    case CertificateListReducerTypes.REQUEST_CERTIFICATE_LIST:
      return { ...state, loading: true };
    case CertificateListReducerTypes.RESPONSE_CERTIFICATE_LIST:
      return { error: null, loading: false, data: action.payload };
    case CertificateListReducerTypes.ERROR_CERTIFICATE_LIST:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}
