import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { aboutMeActionTypes } from "../reducers/about-me";
import { userRegisterReducerTypes } from "../reducers/userReducer";

const version = process.env.REACT_APP_API_VERSION;

function* saveAboutMeDetailsRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SAVE_ABOUT_ME.replace("{version}", version),
    payload
  );

  return res;
}

function* saveAboutMeDetails({ payload }) {
  try {
    yield put({
      type: aboutMeActionTypes.SAVE_ABOUT_ME_REQUEST,
    });
    const response = yield call(saveAboutMeDetailsRequest, payload);

    yield put({
      type: aboutMeActionTypes.SAVE_ABOUT_ME_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO,
      accountGuid: payload.accountGuid,
    });
  } catch (e) {
    yield put({
      type: aboutMeActionTypes.SAVE_ABOUT_ME_FAILURE,
      payload: e,
    });
  }
}

function* saveHeadlineDetailsRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SAVE_HEADLINE.replace("{version}", version),
    payload
  );

  return res;
}

function* saveHeadlineDetails({ payload }) {
  try {
    yield put({
      type: aboutMeActionTypes.SAVE_HEADLINE_REQUEST,
    });
    const response = yield call(saveHeadlineDetailsRequest, payload);

    yield put({
      type: aboutMeActionTypes.SAVE_HEADLINE_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO,
      accountGuid: payload.accountGuid,
    });
  } catch (e) {
    yield put({
      type: aboutMeActionTypes.SAVE_HEADLINE_FAILURE,
      payload: e,
    });
  }
}

function* saveSocialMediaLinksRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SAVE_SOCIAL_MEDIA_LINKS.replace("{version}", version),
    payload
  );

  return res;
}

function* saveSocialMediaLinks({ payload }) {
  try {
    yield put({
      type: aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_REQUEST,
    });
    const response = yield call(saveSocialMediaLinksRequest, payload);

    yield put({
      type: aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO,
      accountGuid: payload.accountGuid,
    });
  } catch (e) {
    yield put({
      type: aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_FAILURE,
      payload: e,
    });
  }
}

function* combinedSaga() {
  yield all([
    takeLatest(aboutMeActionTypes.SAVE_ABOUT_ME, saveAboutMeDetails),
    takeLatest(
      aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS,
      saveSocialMediaLinks
    ),
    takeLatest(aboutMeActionTypes.SAVE_HEADLINE, saveHeadlineDetails),
  ]);
}

export default combinedSaga;
