import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography, Snackbar } from "@mui/material";
import { useTheme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ClickAwayListener from "react-click-away-listener";
import { productEditNavBarType } from "app/redux/reducers/navBar/productEditNavBar";
import CheckIcon from "@mui/icons-material/Check";
import { categoryTypesPreviewData } from "app/redux/reducers/localDataReducer/categoryPreview";
import { uploadProductFileReducerTypes } from "../../../redux/reducers/productReducer/uploadProductFile";
import { LightModeCross, NightModeCross } from "../../Logo/logos";
import { BlackBorderedButton, PurpleButton } from "../../UIFormFields/Buttons";
import Attach3dFiles from "../../AttachFiles/attach3dFiles";
import { AttachmentDetailsUpload } from "./attachmentDetails";
import UploadGrid from "./uploadGrid";
// import { categoryReducerTypes } from "../../../redux/reducers/productReducer/product-category";
import AttachmentViewer from "./attachmentViewer";

export const useUpload3dModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxHeight: "95vh",
    // height: "100%",
    maxWidth: "80%",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 28,
    right: 24,
    position: "absolute",
  },
  navBox: {
    top: 28,
    left: 24,
    position: "absolute",
    display: "flex",
    flexDirection: "row",
  },
  titleText: {
    marginLeft: "16px !important",
    fontFamily: "Poppins",
    fontSize: "21px !important",
    fontWeight: "bold !important",
    textAlign: "left",
    color: "#2b2639",
  },
  textField: {
    objectFit: "contain",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "normal",
    textAlign: "left",
    color: "#a49bbe",
  },
  editFielBox: {
    width: "100%",
    padding: "110px 54px 24px 54px",
    height: "100%",
  },
  editFielUploaBox: {
    width: "100%",
    padding: "111px 54px 84px 54px",
  },
  editBox: {
    display: "flex",
    flexDirection: "row",
  },
  centerSection: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  leftSection: {
    // maxWidth: "654px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "34px 24px 0px 24px",
  },
  leftSectionMainBox: {
    flex: 0.6,
    // border: "2px solid #d7d0e8",
    backgroundImage:
      "  repeating-linear-gradient(161deg, #d7d0e8, #d7d0e8 7px, transparent 7px, transparent 15px, #d7d0e8 15px), repeating-linear-gradient(251deg, #d7d0e8, #d7d0e8 7px, transparent 7px, transparent 15px, #d7d0e8 15px), repeating-linear-gradient(-19deg, #d7d0e8, #d7d0e8 7px, transparent 7px, transparent 15px, #d7d0e8 15px), repeating-linear-gradient(71deg, #d7d0e8, #d7d0e8 7px, transparent 7px, transparent 15px, #d7d0e8 15px)",
    backgroundSize: "1px 100%, 100% 1px, 1px 100% ,100% 1px ",
    backgroundPosition: " 0 0, 0 0, 100% 0, 0 100%",
    backgroundRepeat: "no-repeat",
    borderRadius: "6px",
    // borderStyle: "dotted",
    height: "100%",
  },
  titleBox: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    margin: "24px 0px 0px 24px",
  },
  //   textField: {
  //     objectFit: "contain",
  //     fontFamily: "Poppins",
  //     fontSize: "15px",
  //     fontWeight: "normal",
  //     textAlign: "left",
  //     color: "#a49bbe",
  //   },
  //   editBox: {
  //     display: "flex",
  //     flexDirection: "row",
  //   },
  dotBoxes: {
    color: "#2b2639",
    textAlign: "center",
    width: "150px",
    height: "150px",
    backgroundImage:
      "  repeating-linear-gradient(161deg, #a49bbe, #a49bbe 8px, transparent 8px, transparent 15px, #a49bbe 15px), repeating-linear-gradient(251deg, #a49bbe, #a49bbe 8px, transparent 8px, transparent 15px, #a49bbe 15px), repeating-linear-gradient(-19deg, #a49bbe, #a49bbe 8px, transparent 8px, transparent 15px, #a49bbe 15px), repeating-linear-gradient(71deg, #a49bbe, #a49bbe 8px, transparent 8px, transparent 15px, #a49bbe 15px)",
    backgroundSize: "1px 100%, 100% 1px, 1px 100% ,100% 1px ",
    backgroundPosition: " 0 0, 0 0, 100% 0, 0 100%",
    backgroundRepeat: "no-repeat",
    borderRadius: "6px ",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      color: "#2b2639",
      textAlign: "center",
      width: "150px",
      height: "150px",
      border: "solid 1px #2b2639",
      borderRadius: "6px ",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  // leftSectionMainBoxDotted: {
  //     flex: 0.6,
  //     border: "2px solid black",
  //     borderStyle: "dotted",
  //     borderRadius: "5px",
  // },
  rightSection: {
    // maxWidth: "654px",
    width: "100%",
    flex: 0.4,
    flexDirection: "column",
    height: "100%",
  },
  rightSectionTop: {
    width: "100%",
    marginBottom: "184px",
  },
  rightSectionBottom: {
    width: "100%",
    float: "right",
  },
  title: {
    fontSize: "15px",
    fontWeight: "bold !important",
    textAlign: "left",
    color: "#2b2639",
    margin: "36px 0px 8px 0px",
  },
  boldTitle: {
    fontSize: "21px !important",
    fontWeight: "bold !important",
    textAlign: "center",
    color: "#2b2639",
    margin: "55px 0px 8px 0px !important",
  },
  boldSubTitle: {
    fontSize: "15px !important",
    textAlign: "center",
    color: "#2b2639",
    marginBottom: "500px",
  },
  subTitle: {
    fontSize: "13px",
    textAlign: "left",
    color: "#a49bbe",
    margin: "36px 0px 8px 0px",
  },
  previewTop: {
    display: "flex",
    justifyContent: "space-between !important",
    width: "100%",
    marginTop: "34px",
  },
  subHeaderTitle: {
    fontSize: "13px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#2b2639",
  },
  smallViewPortBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    margin: "0px 24px",
    position: "relative",
    marginTop: "41px",
    height: "100%",
  },
  clearText: {
    fontSize: "13px",
    fontWeight: "bold !important",
    textAlign: "center",
    color: "#7a48ff",
    position: "absolute",
    top: -30,
    right: 0,
    cursor: "pointer",
  },
  smallViewPort: {
    height: "114px !important",
    width: "22%",
    borderRadius: "6px",
    margin: "0px 16px 70px 0px",
  },

  smallViewPortTitleBox: {
    height: "24px",
    width: "100%",
  },
  smallViewPortTitle: {
    fontSize: "11px",
    textAlign: "left",
    color: "#000",
    cursor: "pointer",
  },
  line: {
    height: "1px",
    marginBottom: "16px",
    marginLeft: "34px",
    backgroundColor: "#d7d0e8",
  },
  toolTipStyle: {
    background: "white",
    color: "gray",
    width: "216px",
    height: "54px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    boxShadow: "1px 1px 2px ",
    borderRadius: "3px",
    border: "solid transparent 2px",
    padding: "10px 10px 10px 16px",
    marginTop: "-10px",
  },
  toolTipStyleForImageHover: {
    background: "white",
    color: "gray",
    width: "242px",
    height: "242px",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    boxShadow: "0 30px 40px 0 rgba(122, 72, 255, 0.2)",
    borderRadius: "6px !important",
    marginTop: "-80px",
    marginRight: "-40px",
  },
}));

export default function Upload3dModal({
  handleCloseCallback,
  setShowPreviewModal,
  setUploadFile,
  open,
  setOpen,
  setUploadProductPreview,
  isEditProduct,
  productEditNavBar,
  individualProductData,
  editSelectedFileUplaoded,
  setEditSelectedFileUplaoded,
  editFileUplaoded,
  setEditFileUploaded,
  details,
  handleDraft,
}) {
  const classes = useUpload3dModalStyles();
  const theme = useTheme();
  const attachFileRef = React.useRef();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;
  const [fileUplaoded, setFileUploaded] = React.useState({
    imageUpload: [],
    _3dFileUpload: [],
    patternUpload: [],
    artWorkUpolad: [],
    sketchUpload: [],
    otherFileUpload: [],
  });
  const [selectedFileUplaoded, setSelectedFileUplaoded] = React.useState({
    selectedImageUpload: [],
    selected3dFileUpload: [],
    selectedPatternUpload: [],
    selectedArtWorkUpolad: [],
    selectedSketchUpload: [],
    selectedOtherFileUpload: [],
  });

  const [saveMessage, setSaveMessage] = useState(false);

  const [selectedStack, setSelectdStack] = useState([]);
  const uploadProductFile = useSelector((state) => state.uploadProductFile);
  const [uploadType, setUploadType] = React.useState({
    _3D: isEditProduct && productEditNavBar._3d ? true : false,
    pattern: isEditProduct && productEditNavBar.pattern ? true : false,
    artWork: isEditProduct && productEditNavBar.art ? true : false,
    sketch: isEditProduct && productEditNavBar.sketch ? true : false,
    image: isEditProduct && productEditNavBar.image ? true : false,
    otherFiles: isEditProduct && productEditNavBar.others ? true : false,
  });
  const [hover, setHover] = useState(null);
  const [showHover, setShowHover] = useState(true);
  const [selectdId, setSelectedId] = useState();
  const openHoverPopup = Boolean(hover);
  const [success, setSuccess] = useState(false);
  const [openFile, setOpenFile] = useState(false);
  const [mainProgress, setMainProgress] = useState(0);

  const updateSaveMessage = () => {
    setSaveMessage(false);
  };

  useEffect(() => {
    if (saveMessage === true) {
      setTimeout(updateSaveMessage, 4000);
    }
  }, [saveMessage]);

  const [progress, setProgress] = useState(0);
  const [selectedItem, setSelectedItem] = React.useState(null);

  // useEffect(() => {
  //   dispatch({
  //     type: categoryReducerTypes.CATEGORY_LIST,
  //   });
  // }, []);

  useEffect(() => {
    // setFileUplaodedUpdated(fileUplaoded);
    if (isEditProduct) {
      (uploadType._3D &&
        editFileUplaoded._3dFileUpload?.map((item, index) => {
          const array = editFileUplaoded._3dFileUpload;

          array[index].isSelected = false;
          setEditSelectedFileUplaoded({
            ...editSelectedFileUplaoded,
            selected3dFileUpload: [...array],
          });
        })) ||
        (uploadType.pattern &&
          editFileUplaoded.patternUpload?.map((item, index) => {
            const array = editFileUplaoded.patternUpload;

            array[index].isSelected = false;
            setEditSelectedFileUplaoded({
              ...editSelectedFileUplaoded,
              selectedPatternUpload: [...array],
            });
          })) ||
        (uploadType.artWork &&
          editFileUplaoded.artWorkUpolad?.map((item, index) => {
            const array = editFileUplaoded.artWorkUpolad;

            array[index].isSelected = false;
            setEditSelectedFileUplaoded({
              ...editSelectedFileUplaoded,
              selectedArtWorkUpolad: [...array],
            });
          })) ||
        (uploadType.sketch &&
          editFileUplaoded.sketchUpload?.map((item, index) => {
            const array = editFileUplaoded.sketchUpload;

            array[index].isSelected = false;
            setEditSelectedFileUplaoded({
              ...editSelectedFileUplaoded,
              selectedSketchUpload: [...array],
            });
          })) ||
        (uploadType.image &&
          editFileUplaoded.imageUpload?.map((item, index) => {
            const array = editFileUplaoded.imageUpload;

            array[index].isSelected = false;
            setEditSelectedFileUplaoded({
              ...editSelectedFileUplaoded,
              selectedImageUpload: [...array],
            });
          })) ||
        (uploadType.otherFiles &&
          editFileUplaoded.otherFileUpload?.map((item, index) => {
            const array = editFileUplaoded.otherFileUpload;

            array[index].isSelected = false;
            setEditSelectedFileUplaoded({
              ...editSelectedFileUplaoded,
              selectedOtherFileUpload: [...array],
            });
          }));
    } else if (!isEditProduct) {
      (uploadType._3D &&
        fileUplaoded._3dFileUpload?.map((item, index) => {
          const array = fileUplaoded._3dFileUpload;

          array[index].isSelected = false;
          setSelectedFileUplaoded({
            ...selectedFileUplaoded,
            selected3dFileUpload: [...array],
          });
        })) ||
        (uploadType.pattern &&
          fileUplaoded.patternUpload?.map((item, index) => {
            const array = fileUplaoded.patternUpload;

            array[index].isSelected = false;
            setSelectedFileUplaoded({
              ...selectedFileUplaoded,
              selectedPatternUpload: [...array],
            });
          })) ||
        (uploadType.artWork &&
          fileUplaoded.artWorkUpolad?.map((item, index) => {
            const array = fileUplaoded.artWorkUpolad;

            array[index].isSelected = false;
            setSelectedFileUplaoded({
              ...selectedFileUplaoded,
              selectedArtWorkUpolad: [...array],
            });
          })) ||
        (uploadType.sketch &&
          fileUplaoded.sketchUpload?.map((item, index) => {
            const array = fileUplaoded.sketchUpload;

            array[index].isSelected = false;
            setSelectedFileUplaoded({
              ...selectedFileUplaoded,
              selectedSketchUpload: [...array],
            });
          })) ||
        (uploadType.image &&
          fileUplaoded.imageUpload?.map((item, index) => {
            const array = fileUplaoded.imageUpload;

            array[index].isSelected = false;
            setSelectedFileUplaoded({
              ...selectedFileUplaoded,
              selectedImageUpload: [...array],
            });
          })) ||
        (uploadType.otherFiles &&
          fileUplaoded.otherFileUpload?.map((item, index) => {
            const array = fileUplaoded.otherFileUpload;

            array[index].isSelected = false;
            setSelectedFileUplaoded({
              ...selectedFileUplaoded,
              selectedOtherFileUpload: [...array],
            });
          }));
    }
  }, [fileUplaoded, editFileUplaoded]);

  function checkProduct(product) {
    return product.isSelected === true;
  }

  useEffect(() => {
    dispatch({
      type: categoryTypesPreviewData.SAVE_CATEGORY_TYPES_PREVIEW_DATA,
      payload: {
        fileUplaoded,
        uploadType,
      },
    });
  }, [fileUplaoded, uploadType]);

  useEffect(() => {
    const result =
      selectedFileUplaoded.selected3dFileUpload?.filter(checkProduct);
    // setSelectdStack(result)
  }, [selectedFileUplaoded]);
  const _3dHandler = () => {
    dispatch({
      type: productEditNavBarType._3D,
    });
  };

  const patternHandler = () => {
    dispatch({
      type: productEditNavBarType.PATTERN,
    });
  };

  const artFileHandler = () => {
    dispatch({
      type: productEditNavBarType.ART_FILE,
    });
  };

  const imageHandler = () => {
    dispatch({
      type: productEditNavBarType.IMAGE_FILE,
    });
  };

  const sketchHandler = () => {
    dispatch({
      type: productEditNavBarType.SKETCH_FILE,
    });
  };

  const otherHandler = () => {
    dispatch({
      type: productEditNavBarType.OTHER_FILE,
    });
  };

  const handleUploadFileTypeClick = (obj) => {
    setUploadType(obj);
    // if (isEditProduct) {
    if (obj._3D) _3dHandler();
    else if (obj.pattern) patternHandler();
    else if (obj.artWork) artFileHandler();
    else if (obj.sketch) sketchHandler();
    else if (obj.image) imageHandler();
    else if (obj.otherFiles) otherHandler();
    // }
  };

  // const selectedItemsReturn = () => {
  //     return (uploadType._3D
  //         && selectedItem.selected3dFile)
  //         || (uploadType.pattern
  //             && selectedItem.selectedPattern)
  //         || (uploadType.artWork
  //             && selectedItem.selectedArtWork)
  //         || (uploadType.sketch
  //             && selectedItem.selectedSketch)
  //         || (uploadType.image
  //             && selectedItem.selectedImage)
  //         || (uploadType.otherFiles
  //             && selectedItem.selectedOtherFile)
  // }

  const handleEditItem = ({ item, index }) => {
    if (item.isSelected === false) setSelectedItem(null);
    else setSelectedItem({ item, index });
    // if (item.isSelected)
    //     (uploadType._3D
    //         && setSelectedItem({ ...selectedItem, selected3dFile: { item, index } }))
    //         || (uploadType.pattern
    //             && setSelectedItem({ ...selectedItem, selectedPattern: { item, index } }))
    //         || (uploadType.artWork
    //             && setSelectedItem({ ...selectedItem, selectedArtWork: { item, index } }))
    //         || (uploadType.sketch
    //             && setSelectedItem({ ...selectedItem, selectedSketch: { item, index } }))
    //         || (uploadType.image
    //             && setSelectedItem({ ...selectedItem, selectedImage: { item, index } }))
    //         || (uploadType.otherFiles
    //             && setSelectedItem({ ...selectedItem, selectedOtherFile: { item, index } }))
  };

  const handleDeleteItem = (index) => {
    if (isEditProduct) {
      if (uploadType._3D) {
        const arr = [...editSelectedFileUplaoded.selected3dFileUpload];

        if (index > -1) {
          arr.splice(index, 1);
        }
        setEditSelectedFileUplaoded({
          ...editSelectedFileUplaoded,
          selected3dFileUpload: [...arr],
        });
      } else if (uploadType.pattern) {
        const arr = [...editSelectedFileUplaoded.selectedPatternUpload];

        if (index > -1) {
          arr.splice(index, 1);
        }
        setEditSelectedFileUplaoded({
          ...editSelectedFileUplaoded,
          selectedPatternUpload: [...arr],
        });
      } else if (uploadType.artWork) {
        const arr = [...editSelectedFileUplaoded.selectedArtWorkUpolad];

        if (index > -1) {
          arr.splice(index, 1);
        }
        setEditSelectedFileUplaoded({
          ...editSelectedFileUplaoded,
          selectedArtWorkUpolad: [...arr],
        });
      } else if (uploadType.sketch) {
        const arr = [...editSelectedFileUplaoded.selectedSketchUpload];

        if (index > -1) {
          arr.splice(index, 1);
        }
        setEditSelectedFileUplaoded({
          ...editSelectedFileUplaoded,
          selectedSketchUpload: [...arr],
        });
      } else if (uploadType.image) {
        const arr = [...editSelectedFileUplaoded.selectedImageUpload];

        if (index > -1) {
          arr.splice(index, 1);
        }
        setEditSelectedFileUplaoded({
          ...editSelectedFileUplaoded,
          selectedImageUpload: [...arr],
        });
      } else if (uploadType.otherFiles) {
        const arr = [...editSelectedFileUplaoded.selectedOtherFileUpload];

        if (index > -1) {
          arr.splice(index, 1);
        }
        setEditSelectedFileUplaoded({
          ...editSelectedFileUplaoded,
          selectedOtherFileUpload: [...arr],
        });
      } else if (!isEditProduct) {
        if (uploadType._3D) {
          const arr = [...selectedFileUplaoded.selected3dFileUpload];

          if (index > -1) {
            arr.splice(index, 1);
          }
          setSelectedFileUplaoded({
            ...selectedFileUplaoded,
            selected3dFileUpload: [...arr],
          });
        } else if (uploadType.pattern) {
          const arr = [...selectedFileUplaoded.selectedPatternUpload];

          if (index > -1) {
            arr.splice(index, 1);
          }
          setSelectedFileUplaoded({
            ...selectedFileUplaoded,
            selectedPatternUpload: [...arr],
          });
        } else if (uploadType.artWork) {
          const arr = [...selectedFileUplaoded.selectedArtWorkUpolad];

          if (index > -1) {
            arr.splice(index, 1);
          }
          setSelectedFileUplaoded({
            ...selectedFileUplaoded,
            selectedArtWorkUpolad: [...arr],
          });
        } else if (uploadType.sketch) {
          const arr = [...selectedFileUplaoded.selectedSketchUpload];

          if (index > -1) {
            arr.splice(index, 1);
          }
          setSelectedFileUplaoded({
            ...selectedFileUplaoded,
            selectedSketchUpload: [...arr],
          });
        } else if (uploadType.image) {
          const arr = [...selectedFileUplaoded.selectedImageUpload];

          if (index > -1) {
            arr.splice(index, 1);
          }
          setSelectedFileUplaoded({
            ...selectedFileUplaoded,
            selectedImageUpload: [...arr],
          });
        } else if (uploadType.otherFiles) {
          const arr = [...selectedFileUplaoded.selectedOtherFileUpload];

          if (index > -1) {
            arr.splice(index, 1);
          }
          setSelectedFileUplaoded({
            ...selectedFileUplaoded,
            selectedOtherFileUpload: [...arr],
          });
        }
      }
    }
  };

  const handlePopoverOpen = (e, key) => {
    setHover(e.currentTarget);
    setSelectedId(key);
  };

  const handlePopoverClose = () => {
    setHover(null);
  };

  const handleSelectionAttachment = (item, index) => {
    item.isSelected = !item.isSelected;
    if (item.isSelected === false)
      // setSelectdStack([...selectedStack])
      setSelectdStack(
        selectedStack.filter((element) => element.isSelected === true)
      );
    else {
      setSelectdStack([...selectedStack, item]);
      if (showHover)
        //     item.isSelected = !item.isSelected
        // else
        setShowHover(true);
    }
  };

  const handleSelectAllItem = () => {
    selectedFileUplaoded.selected3dFileUpload.map((item) => {
      if (item.isSelected === false) {
        item.isSelected = !item.isSelected;
      }
    });
  };

  const isSelectedUploadType = () => {
    if (
      uploadType._3D ||
      uploadType.artWork ||
      uploadType.pattern ||
      uploadType.sketch ||
      uploadType.image ||
      uploadType.otherFiles
    )
      return true;
  };
  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };

  function onUploadProgress(progressEvent) {
    const _progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );

    setProgress(_progress);
    dispatch({
      type: uploadProductFileReducerTypes.SET_UPLOAD_PRODUCT_FILE_PROGRESS_FOR_DIFF_CATEGORIES,
      payload: parseInt(progress),
    });
  }

  const handleClose = () => {
    // handleCloseCallback(false);
    setOpen(false);
  };

  const handleBack = () => {
    // handleCloseCallback(false);
    setUploadType({
      _3D: false,
      pattern: false,
      artWork: false,
      sketch: false,
      image: false,
      otherFiles: false,
    });
  };

  const handleCancel = () => {
    if (selectedFileUplaoded) {
      setFileUploaded(null);
    } else {
      handleClose();
    }
  };

  const handleReturnProgress = (progressGet) => {
    setMainProgress(progressGet);
  };

  const handleTooltipClose = () => {
    setOpenFile(false);
  };

  const handleTooltipOpen = () => {
    setOpenFile(true);
  };

  // React.useEffect(() => {
  //     if (fileUplaoded !== null) {
  //         setUploadFile(fileUplaoded);
  //     }
  // }, [fileUplaoded]);

  const handleSubmit = () => {
    // if (fileUplaoded) {
    //     setShowPreviewModal(true);
    //     handleCloseCallback(false);

    //     let params = new FormData();
    //     params.append("AccountGuid", accountGuid);
    //     params.append("productImage", fileUplaoded);
    //     dispatch({
    //         type: uploadProductFileReducerTypes.UPLOAD_PRODUCT_FILE,
    //         payload: params,
    //         onUploadProgress,
    //     });
    // }
    // alert("handle submit");
    setUploadProductPreview(true);
  };

  // const onUploadFile = (event) => {
  //     // let fileType = event.target.files[0].type;

  //     // setFileUploaded(event.target.files[0]);
  //     alert(event.target.files)
  // };

  const {
    ReactComponent: BackArrowLightMode,
  } = require("../../../../assets/icons/Icons_Light_Back-Arrow.svg");
  const {
    ReactComponent: BackArrowNightMode,
  } = require("../../../../assets/icons/Icons_Dark_Back-Arrow.svg");
  const {
    ReactComponent: ThreeDModalIcon,
  } = require("../../../../assets/icons/Icons_Light_3D square Icon.svg");
  const {
    ReactComponent: AtworkIcon,
  } = require("../../../../assets/icons/Icons_Light_Artwork upload Icon.svg");
  const {
    ReactComponent: ImageIcon,
  } = require("../../../../assets/icons/Icons_Light_Image upload Icon.svg");
  const {
    ReactComponent: PatternIcon,
  } = require("../../../../assets/icons/Icons_Light_Pattern upload Icon.svg");
  const {
    ReactComponent: ScetchIcon,
  } = require("../../../../assets/icons/Icons_Light_Sketch upload Icon.svg");
  const {
    ReactComponent: UploadIcon,
  } = require("../../../../assets/icons/Icons_Light_Upload upload Icon.svg");
  const {
    ReactComponent: Tick,
  } = require("../../../../assets/icons/Icons_Light_Tick mark green icon.svg");

  const getCategoryText = (() => {
    if (uploadType._3D) {
      return "3D Files";
    }
    if (uploadType.artWork) {
      return "Art work";
    }
    if (uploadType.image) {
      return "Images";
    }
    if (uploadType.pattern) {
      return "Pattern";
    }
    if (uploadType.sketch) {
      return "Sketch";
    }
    if (uploadType.otherFiles) {
      return "Other Files";
    }

    return "Upload 3D Files";
  })();

  const getCategoryID = (() => {
    if (uploadType._3D) {
      return 1;
    }
    if (uploadType.artWork) {
      return 3;
    }
    if (uploadType.image) {
      return 5;
    }
    if (uploadType.pattern) {
      return 2;
    }
    if (uploadType.sketch) {
      return 4;
    }
    if (uploadType.otherFiles) {
      return 6;
    }

    return 1;
  })();

  const leftSectionUplodFiles = () => {
    return (
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <Box className={classes.leftSectionMainBox}>
          <AttachmentViewer
            selectedFileUplaoded={
              isEditProduct ? editSelectedFileUplaoded : selectedFileUplaoded
            }
            getCategoryID={getCategoryID}
            getCategoryText={getCategoryText}
            openFile={openFile}
            handleTooltipClose={handleTooltipClose}
            handleTooltipOpen={handleTooltipOpen}
            uploadProductFile={uploadProductFile}
            progress={progress}
            setSelectedItem={setSelectedItem}
            fileUplaoded={isEditProduct ? editFileUplaoded : fileUplaoded}
            setSelectedFileUplaoded={
              isEditProduct
                ? setEditSelectedFileUplaoded
                : setSelectedFileUplaoded
            }
            setFileUploaded={
              isEditProduct ? setEditFileUploaded : setFileUploaded
            }
            handleSelectAllItem={handleSelectAllItem}
            handleSelectionAttachment={handleSelectionAttachment}
            handleEditItem={handleEditItem}
            handlePopoverOpen={handlePopoverOpen}
            handlePopoverClose={handlePopoverClose}
            uploadType={uploadType}
            selectedItem={selectedItem}
            individualProductData={individualProductData}
            isEditProduct={isEditProduct}
            setSelectdStack={setSelectdStack}
            setUploadFile={setUploadFile}
            handleUploadFileTypeClick={handleUploadFileTypeClick}
          />
          <Attach3dFiles
            attachFileRef={attachFileRef}
            setFileUploaded={
              isEditProduct ? setEditFileUploaded : setFileUploaded
            }
            fileUplaoded={isEditProduct ? editFileUplaoded : fileUplaoded}
            getCategoryText={getCategoryText}
            getCategoryID={getCategoryID}
            uploadType={uploadType}
            setSelectdStack={setSelectdStack}
          />
        </Box>
      </ClickAwayListener>
    );
  };

  const rightSectionUplodFiles = () => {
    return (
      <Box className={classes.rightSection}>
        <Box className={classes.rightSectionTop}>
          <UploadGrid
            handleUploadFileTypeClick={handleUploadFileTypeClick}
            uploadType={uploadType}
          />
          {/* <AdditionalInfo /> */}
        </Box>

        {saveMessage && (
          <Typography
            sx={{
              backgroundColor: "#64c279",
              width: "442px",
              height: "54px",
              color: "white",
              margin: "auto",
              marginBottom: "10px",
              borderRadius: "10px",
              padding: "10px 16px",
            }}
          >
            {" "}
            Changes made below will affect {selectedStack.length} selected
            files. <CheckIcon />{" "}
          </Typography>
        )}
        {selectedItem !== null && (
          <>
            <Box className={classes.line} />
            <Box className={classes.rightSectionBottom}>
              {
                <AttachmentDetailsUpload
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  selectedFileUplaoded={
                    isEditProduct
                      ? editSelectedFileUplaoded
                      : selectedFileUplaoded
                  }
                  handleDeleteItem={handleDeleteItem}
                  selectedStack={selectedStack}
                  uploadType={uploadType}
                  product_details={details}
                  isEditProduct={isEditProduct}
                  setSaveMessage={setSaveMessage}
                  saveMessage={saveMessage}
                  success={success}
                  setSuccess={setSuccess}
                  fileUplaoded={isEditProduct ? editFileUplaoded : fileUplaoded}
                  setSelectedFileUplaoded={
                    isEditProduct
                      ? setEditSelectedFileUplaoded
                      : setSelectedFileUplaoded
                  }
                />
              }
            </Box>
          </>
        )}
      </Box>
    );
  };

  const uploadWork = () => {
    return (
      <Box className={classes.editFielUploaBox}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              onClick={() =>
                handleUploadFileTypeClick({
                  _3D: true,
                  pattern: false,
                  artWork: false,
                  sketch: false,
                  image: false,
                  otherFiles: false,
                })
              }
              className={classes.dotBoxes}
            >
              <Box>
                <ThreeDModalIcon style={{ width: "34px", height: "34px" }} />
                <Typography marginTop="16px" fontSize="15px">
                  3D
                </Typography>
              </Box>
            </Box>
            <Box
              onClick={() =>
                handleUploadFileTypeClick({
                  _3D: false,
                  pattern: true,
                  artWork: false,
                  sketch: false,
                  image: false,
                  otherFiles: false,
                })
              }
              className={classes.dotBoxes}
              marginLeft="34px"
            >
              <Box>
                <PatternIcon />
                <Typography marginTop="16px" fontSize="15px">
                  Pattern
                </Typography>
              </Box>
            </Box>
            <Box
              onClick={() =>
                handleUploadFileTypeClick({
                  _3D: false,
                  pattern: false,
                  artWork: true,
                  sketch: false,
                  image: false,
                  otherFiles: false,
                })
              }
              className={classes.dotBoxes}
              marginLeft="34px"
            >
              <Box>
                <AtworkIcon />
                <Typography marginTop="16px" fontSize="15px">
                  Art work
                </Typography>
              </Box>
            </Box>
            <Box
              onClick={() =>
                handleUploadFileTypeClick({
                  _3D: false,
                  pattern: false,
                  artWork: false,
                  sketch: true,
                  image: false,
                  otherFiles: false,
                })
              }
              className={classes.dotBoxes}
              marginLeft="34px"
            >
              <Box>
                <ScetchIcon />
                <Typography marginTop="16px" fontSize="15px">
                  Sketch
                </Typography>
              </Box>
            </Box>
            <Box
              onClick={() =>
                handleUploadFileTypeClick({
                  _3D: false,
                  pattern: false,
                  artWork: false,
                  sketch: false,
                  image: true,
                  otherFiles: false,
                })
              }
              className={classes.dotBoxes}
              marginLeft="34px"
            >
              <Box>
                <ImageIcon />
                <Typography marginTop="16px" fontSize="15px">
                  Image
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            marginTop: "34px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Box display="flex" width="888px">
            <Box className={classes.dotBoxes}>
              <Box
                onClick={() =>
                  handleUploadFileTypeClick({
                    _3D: false,
                    pattern: false,
                    artWork: false,
                    sketch: false,
                    image: false,
                    otherFiles: true,
                  })
                }
              >
                <label>
                  <input type="" style={{ visibility: "hidden" }} />
                  <Typography fontSize="15px" fontWeight="bold">
                    Upload any other file
                  </Typography>
                  <UploadIcon style={{ margin: "16px" }} />
                  <Typography fontSize="11px">
                    Preview might not be available for all file types
                  </Typography>
                </label>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  if (isEditProduct) {
    return (
      <Box className={classes.centerSection}>
        {leftSectionUplodFiles()}
        {rightSectionUplodFiles()}
      </Box>
    );
  } else {
    return (
      <Box
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {/* <Button
             style={{ margin: "10px 5px" }}
             onClick={() => setOpen(true)}>
             Updoad 3D
         </Button> */}
        <Modal open={open}>
          <Box
            className={classes.modalStyle}
            style={{
              backgroundColor: theme?.palette?.mode === "light" && "#fff",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <Box className={classes.navBox}>
                {theme?.palette?.mode === "light" ? (
                  <BackArrowLightMode
                    onClick={() =>
                      isSelectedUploadType(uploadType)
                        ? handleBack()
                        : handleClose()
                    }
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <BackArrowNightMode
                    onClick={() =>
                      isSelectedUploadType(uploadType)
                        ? handleBack()
                        : handleClose()
                    }
                    style={{ cursor: "pointer" }}
                  />
                )}
                <Typography className={classes.titleText}>
                  {isSelectedUploadType(uploadType)
                    ? (uploadType._3D && "Upload 3D") ||
                      (uploadType.artWork && "Upload Art Work") ||
                      (uploadType.pattern && "Upload Pattern") ||
                      (uploadType.sketch && "Upload Sketch") ||
                      (uploadType.image && "Upload Image") ||
                      (uploadType.otherFiles && "Upload Other Files")
                    : "Upload Work"}
                </Typography>
              </Box>
              <Box className={classes.closeBox}>
                {theme?.palette?.mode === "light" ? (
                  <LightModeCross
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <NightModeCross
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Box>

              {isSelectedUploadType(uploadType) ? (
                <Box
                  className={classes.editFielBox}
                  // style={{
                  //     // alignItems: "center",
                  //     display: "flex",
                  //     justifyContent: "center",
                  //     flexDirection: "column",
                  // }}
                >
                  <Box>
                    <Box className={classes.centerSection}>
                      {leftSectionUplodFiles()}
                      {rightSectionUplodFiles()}
                    </Box>
                    {success && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "54px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Snackbar
                          ContentProps={{ className: classes.snackbarcss }}
                          anchorOrigin={{
                            horizontal: "center",
                            vertical: "bottom",
                          }}
                          open={true}
                          // message={uploadProduct?.data?.responseMessage}
                          message={"data has been saved successfully"}
                          action={<Tick style={{ marginRight: "16px" }} />}
                        />
                      </Box>
                    )}

                    <Box
                      style={{
                        paddingTop: "30px",
                        bottom: 0,
                        margin: "0 auto",
                      }}
                    >
                      <BlackBorderedButton
                        style={{
                          width: "87px",
                        }}
                        onClick={handleBack}
                      >
                        Cancel
                      </BlackBorderedButton>
                      <PurpleButton
                        style={{
                          width: 101,
                          marginLeft: 16,
                        }}
                        onClick={handleSubmit}
                        disabled={!selectedFileUplaoded}
                      >
                        Continue
                      </PurpleButton>
                    </Box>
                    <Typography
                      sx={{
                        cursor: "pointer",
                        color: "#a49bbe",
                        fontWeight: "bold",
                        marginTop: "24px",
                      }}
                      onClick={() => handleDraft()}
                    >
                      Save to draft
                    </Typography>
                  </Box>
                </Box>
              ) : (
                uploadWork()
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    );
  }
}
