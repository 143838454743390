/*
 **	User name
 **	Created	5/25/2023
 **	index.tsx
 */

import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { categoriesActionTypes } from "app/redux/reducers/categories";
import UniversalLoader from "../Loader";
import AttributesDropdownComponent from "./AttributesDropdown";
import CategoryTile from "./CategoryTile";
import ProductCategoryMainDropdownComponent from "../Dropdowns/ProductCategoryDropdown/Main";
import CatgeoryRadio from "./CategoryRadio";
import CatgeoryDropDown from "./CategoryDropdown";
import ColorSwatch from "./ColorSwatch";
import { CategoryDataContext } from "./CategoryDataContext";

const CategoryBox = ({
  onClick,
  index,
  ids,
  categoryMap,
  selectedCategory,
  options,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        borderBottom: "1px solid #d7d0e8",
        paddingTop: 16,
        paddingBottom: 16,
        flexDirection: "column",
      }}
    >
      <Box style={{ marginBottom: 16 }}>
        <Typography
          style={{
            fontWeight: "bold",
            color: "#a49bbe",
            fontSize: 15,
            textAlign: "left",
          }}
        >
          {options?.title ? `${options?.title}*` : "Category*"}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {options?.uiType === "dropdown" ? (
          <CatgeoryDropDown
            ids={ids}
            categoryMap={categoryMap}
            selectedCategory={selectedCategory}
            onClick={onClick}
          />
        ) : (
          ids.map((id) => {
            return (
              <Box key={id}>
                {options?.uiType === "radio" ? (
                  <CatgeoryRadio
                    key={id}
                    onClick={() => onClick(id, index)}
                    isSelected={selectedCategory?.id === id}
                    name={categoryMap[id]?.name}
                  />
                ) : (
                  <CategoryTile
                    key={id}
                    onClick={() => onClick(id, index)}
                    isSelected={selectedCategory?.id === id}
                    name={categoryMap[id]?.name}
                  />
                )}
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
};

const SelectedItem = ({ name, handleDelete, index }) => {
  return (
    <Box
      style={{
        border: "1px solid #ccc",
        marginRight: 8,
        marginBottom: 9,
        borderRadius: 6,
        padding: "1px 8px",
        display: "flex",
        background: "#7a48ff",
        color: "#fff",
      }}
    >
      <Typography>{name}</Typography>
      <Typography
        onClick={() => handleDelete(index)}
        style={{ marginLeft: 10, cursor: "pointer" }}
      >
        X
      </Typography>
    </Box>
  );
};

const CategoryComponent = (props) => {
  const { isProduct, isEditMode } = props;
  const dispatch = useDispatch();
  const { libraryData, productData, categoriesDataMain } =
    useContext(CategoryDataContext);
  const {
    treeData,
    setTreeData,
    attributesTreeData,
    setAttributesTreeData,
    productCategoriesTreeData,
    setProductCategoriesTreeData,
    showAttributes,
    setShowAttributes,
    showProductCategories,
    setShowProductCategories,
    showColorSwatch,
    setShowColorSwatch,
    setArrtibutesLength,
    colorData,
    setColorData,
    productCategoryConditions,
    setProductCategoryConditions,
    selectedData,
  } = isProduct ? productData : libraryData;

  const categoryData = categoriesDataMain.libraryCategoryData;
  const attributesData = categoriesDataMain.attributesData;
  const attributesLoading = categoriesDataMain.attributesLoading || false;
  const attributeValuesMap = attributesData?.attributeValuesMap;
  const rootCategories = categoryData?.rootCategories || [];
  const categoriesMap = categoryData?.categoryMaps || {};
  const productCategoryData = categoriesDataMain.productCategoryData;

  React.useEffect(() => {
    !isProduct &&
      isEditMode &&
      handleCategoryClick(treeData[1]?.id, treeData[1]?.index);
  }, [treeData[1]?.id]);

  const handleCategoryClick = (id, index) => {
    let newTreeData = [...treeData];

    const currentCat = categoriesMap[id];

    newTreeData[index] = { ...currentCat, index };
    if (index < newTreeData.length - 1) {
      newTreeData = newTreeData.splice(0, index + 1);
    }
    setTreeData(newTreeData);
    setShowAttributes(!currentCat?.children);
    setAttributesTreeData([]);
    setProductCategoriesTreeData([]);

    const isLinkedToProducts = currentCat?.isLinkedToProducts;

    setShowProductCategories(isLinkedToProducts);
    // setShowColorSwatch(currentCat?.isColorSwatchAvailable);

    if (currentCat?.excludeCategories) {
      setProductCategoryConditions({
        excludeCategories: currentCat?.excludeCategories,
        includeCategories: [],
      });
    }

    if (currentCat?.includeCategories) {
      setProductCategoryConditions({
        includeCategories: currentCat?.includeCategories,
        excludeCategories: [],
      });
    }

    if (!isLinkedToProducts && !currentCat?.children) {
      dispatch({
        type: categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY,
        payload: { CategoryId: id },
      });
    }

    if (isLinkedToProducts) {
      dispatch({
        type: categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_CLEAR,
      });
    }
  };

  const handleDelete = (index) => {
    let newTreeData = [...treeData];

    if (index <= newTreeData.length - 1) {
      newTreeData = newTreeData.splice(0, index);
    }
    setTreeData(newTreeData);
    setShowAttributes(false);
    setShowProductCategories(false);
    setAttributesTreeData([]);
    setProductCategoriesTreeData([]);
  };

  const handleAttributeDelete = (index) => {
    let newTreeData = [...attributesTreeData];

    if (index <= newTreeData.length - 1) {
      newTreeData = newTreeData.splice(0, index);
    }
    setAttributesTreeData(newTreeData);
  };

  const handleTreeDelete = (index) => {
    let newTreeData = [...productCategoriesTreeData];

    if (index <= newTreeData.length - 1) {
      newTreeData = newTreeData.splice(0, index);
    }
    setProductCategoriesTreeData(newTreeData);
    setShowAttributes(false);
    setAttributesTreeData([]);
  };

  const handleReset = () => {
    setShowAttributes(false);
    setShowProductCategories(false);
    setTreeData([]);
    setAttributesTreeData([]);
    setProductCategoriesTreeData([]);
  };

  const getCategoriesTree = (ids, index, options) => {
    if (ids?.length) {
      return (
        <CategoryBox
          key={index}
          onClick={handleCategoryClick}
          ids={ids}
          index={index}
          categoryMap={categoriesMap || {}}
          selectedCategory={treeData[index]}
          options={options}
        />
      );
    }

    return null;
  };

  const handleProductCategoryClick = (id) => {
    setShowAttributes(true);
    setAttributesTreeData([]);
    const currentCat = productCategoryData?.categoryMaps?.[id];
    // if (isProduct) {

    setShowColorSwatch(currentCat?.isColorSwatchAvailable);
    // }
    dispatch({
      type: categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_CLEAR,
    });
    dispatch({
      type: categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY,
      payload: { CategoryId: id },
    });
  };

  if (categoriesDataMain?.libraryCategoryLoading) {
    return <UniversalLoader />;
  }

  return (
    <Box style={{ marginTop: 40, marginBottom: 40 }}>
      {/* showing the selected categories */}
      {treeData?.length || (isProduct && productCategoriesTreeData?.length) ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            borderBottom: "1px solid #d7d0e8",
          }}
        >
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Box style={{ flex: 1, marginBottom: 8 }}>
              <Typography
                style={{
                  fontSize: 15,
                  color: "#a49bbe",
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Applied Selections
              </Typography>
            </Box>
            <Box>
              <Typography
                style={{
                  color: "#7a48ff",
                  fontSize: 13,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={handleReset}
              >
                Reset All
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 20,
              flexWrap: "wrap",
            }}
          >
            {treeData?.map((category, index) => {
              if (!category) {
                return null;
              }
              return (
                <SelectedItem
                  key={index}
                  name={category?.name}
                  handleDelete={handleDelete}
                  index={index}
                />
              );
            })}
            {productCategoriesTreeData?.map((category, index) => {
              if (!category) {
                return null;
              }
              return (
                <SelectedItem
                  key={index}
                  name={
                    category?.parentCategory
                      ? `${category?.parentCategory?.name} (${category?.name})`
                      : category?.name
                  }
                  handleDelete={handleTreeDelete}
                  index={index}
                />
              );
            })}
            {attributesTreeData?.map((category, index) => {
              if (!category) {
                return null;
              }
              const attName = category?.selectedAttribute?.map(
                (selectedAttributeId) => {
                  return attributeValuesMap?.[selectedAttributeId];
                }
              );

              if (!attName?.join(",")) {
                return null;
              }

              return (
                <SelectedItem
                  key={index}
                  name={`${category?.name} (${attName?.join(",")})`}
                  handleDelete={handleAttributeDelete}
                  index={index}
                />
              );
            })}
          </Box>
        </Box>
      ) : null}

      <Box>
        {isProduct ? null : (
          <>
            {/* showing categories */}
            {getCategoriesTree(rootCategories, 0, {})}
            {/* showing sub-categories */}
            {treeData?.length
              ? treeData?.map((category, index) => {
                  if (category?.children?.length) {
                    return getCategoriesTree(category.children, index + 1, {
                      uiType: category.subCategoryUIType,
                      title:
                        category.subCategoryTitle || index === 0
                          ? "Sub Category"
                          : "Select Category",
                    });
                  }

                  return null;
                })
              : null}
          </>
        )}
        {/* for products details */}
        {showProductCategories || isProduct ? (
          <ProductCategoryMainDropdownComponent
            productCategoryData={productCategoryData}
            handleProductCategoryClick={handleProductCategoryClick}
            treeData={productCategoriesTreeData}
            setTreeData={setProductCategoriesTreeData}
            // isFilter={false}
            {...(isProduct ? {} : productCategoryConditions)}
          />
        ) : null}
        {/* for attributes details of library */}
        {attributesLoading ? (
          <UniversalLoader />
        ) : (
          showAttributes && (
            <>
              <AttributesDropdownComponent
                treeData={attributesTreeData}
                setTreeData={setAttributesTreeData}
                attributesData={attributesData}
                setArrtibutesLength={setArrtibutesLength}
                selectedData={selectedData}
                setAttributesTreeData={setAttributesTreeData}
              />
              {showColorSwatch && (
                <ColorSwatch data={colorData} setData={setColorData} />
              )}
            </>
          )
        )}
      </Box>
    </Box>
  );
};

export default CategoryComponent;
