const initialState = {
  loading: false,
  error: null,
  metadataInfo: null,
  status: null,

  allResumes: [],
  allResumesStatus: null,

  saveResumeStatus: null,
  saveResumeError: null,
};

export const resumesActionTypes = {
  SAVE_USER_RESUMES: "SAVE_USER_RESUMES",
  SAVE_USER_RESUMES_REQUEST: "SAVE_USER_RESUMES_REQUEST",
  SAVE_USER_RESUMES_SUCCESS: "SAVE_USER_RESUMES_SUCCESS",
  SAVE_USER_RESUMES_FAILURE: "SAVE_USER_RESUMES_FAILURE",
  SAVE_USER_RESUMES_CLEAR: "SAVE_USER_RESUMES_CLEAR",

  GET_ALL_RESUMES: "GET_ALL_RESUMES",
  GET_ALL_RESUMES_REQUEST: "GET_ALL_RESUMES_REQUEST",
  GET_ALL_RESUMES_SUCCESS: "GET_ALL_RESUMES_SUCCESS",
  GET_ALL_RESUMES_FAILURE: "GET_ALL_RESUMES_FAILURE",

  DELETE_RESUME_BY_ID: "DELETE_RESUME_BY_ID",
  DELETE_RESUME_BY_ID_REQUEST: "DELETE_RESUME_BY_ID_REQUEST",
  DELETE_RESUME_BY_ID_SUCCESS: "DELETE_RESUME_BY_ID_SUCCESS",
  DELETE_RESUME_BY_ID_FAILURE: "DELETE_RESUME_BY_ID_FAILURE",

  SAVE_RESUMES_POSITION: "SAVE_RESUMES_POSITION",
  SAVE_RESUMES_POSITION_REQUEST: "SAVE_RESUMES_POSITION_REQUEST",
  SAVE_RESUMES_POSITION_SUCCESS: "SAVE_RESUMES_POSITION_SUCCESS",
  SAVE_RESUMES_POSITION_FAILURE: "SAVE_RESUMES_POSITION_FAILURE",
};

export function resumesReducer(state = initialState, action) {
  switch (action.type) {
    case resumesActionTypes.GET_ALL_RESUMES_REQUEST:
      return {
        ...state,
        error: null,
        allResumesStatus: resumesActionTypes.GET_ALL_RESUMES_REQUEST,
      };
    case resumesActionTypes.GET_ALL_RESUMES_FAILURE:
      return {
        ...state,
        error: action.payload,
        allResumesStatus: resumesActionTypes.GET_ALL_RESUMES_FAILURE,
      };

    case resumesActionTypes.GET_ALL_RESUMES_SUCCESS:
      return {
        ...state,
        allResumes: action.payload,
        allResumesStatus: resumesActionTypes.GET_ALL_RESUMES_SUCCESS,
      };

    case resumesActionTypes.SAVE_USER_RESUMES_REQUEST:
      return {
        ...state,
        saveResumeError: null,
        saveResumeStatus: resumesActionTypes.SAVE_USER_RESUMES_REQUEST,
      };
    case resumesActionTypes.SAVE_USER_RESUMES_FAILURE:
      return {
        ...state,
        saveResumeError: action.payload,
        saveResumeStatus: resumesActionTypes.SAVE_USER_RESUMES_FAILURE,
      };

    case resumesActionTypes.SAVE_USER_RESUMES_SUCCESS:
      return {
        ...state,
        saveResumeError: null,
        saveResumeStatus: resumesActionTypes.SAVE_USER_RESUMES_SUCCESS,
      };

    case resumesActionTypes.SAVE_USER_RESUMES_CLEAR:
      return {
        ...state,
        saveResumeError: null,
        saveResumeStatus: null,
      };

    default:
      return state;
  }
}
