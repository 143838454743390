import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { SearchActionTypes } from "../reducers/search";

const version = process.env.REACT_APP_API_VERSION_3;

function* searchGlobalDetailsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.SEARCH_ITEMS_BY_CATEGORY.replace("{version}", version),
    payload
  );

  return res;
}

function* searchGlobalDetails({ payload }: any) {
  try {
    yield put({
      type: SearchActionTypes.searchGlobalRequest,
    });
    const response = yield call(searchGlobalDetailsRequest, payload);

    yield put({
      type: SearchActionTypes.searchGlobalSuccess,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: SearchActionTypes.searchGlobalFailure,
      payload: e,
    });
  }
}

function* combinedSaga() {
  yield all([takeLatest(SearchActionTypes.searchGlobal, searchGlobalDetails)]);
}

export default combinedSaga;
