import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { invoiceSettingsActionTypes } from "../reducers/invoice-settings";

const version = process.env.REACT_APP_API_VERSION;

function* updateInvoiceSettingsRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.UPDATE_INVOICE_SETTINGS.replace("{version}", version),
    payload
  );

  return res;
}

function* updateInvoiceSettings({ payload }) {
  try {
    yield put({
      type: invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_REQUEST,
    });
    const response = yield call(updateInvoiceSettingsRequest, payload);

    if (response?.data) {
      yield put({
        type: invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_SUCCESS,
        payload: response?.data,
      });

      yield put({
        type: invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS,
        payload: { Guid: payload?.accountGuid },
      });
    }
  } catch (e) {
    yield put({
      type: invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_FAILURE,
      payload: e,
    });
  }
}

function* fetchInvoiceSettingsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_INVOICE_SETTINGS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchInvoiceSettings({ payload }) {
  try {
    yield put({
      type: invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_REQUEST,
    });
    const response = yield call(fetchInvoiceSettingsRequest, payload);

    if (response?.data) {
      yield put({
        type: invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (e) {
    yield put({
      type: invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_FAILURE,
      payload: e,
    });
  }
}

function* paymentSaga() {
  yield all([
    takeLatest(
      invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS,
      updateInvoiceSettings
    ),

    takeLatest(
      invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS,
      fetchInvoiceSettings
    ),
  ]);
}
export default paymentSaga;
