// @ts-nocheck
import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { isImage } from "app/utils/helper";
import { useDispatch, useSelector } from "react-redux";
import {
  productDetailaWithEachUploadTypes,
  productThumbnailDNDActionTypes,
} from "app/redux/reducers/productReducer/product-main";
import UniversalLoader from "app/components/Loader";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { uploadProductFileReducerTypes } from "app/redux/reducers/productReducer/uploadProductFile";
import UploadThumbnail from "../uploadThumbnail";
import {
  BlackBorderedButton,
  GreenButton,
  PurpleButton,
} from "../../UIFormFields/Buttons";
import { LightModeCross, NightModeCross } from "../../Logo/logos";

export const useUpload3dModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "11982px",
    maxHeight: "95vh",
    height: 637,
    maxWidth: "80%",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 28,
    right: 24,
    position: "absolute",
  },
  titleBox: {
    margin: "54px 0px 0px 0px",
  },
}));

const ImageItem = ({ item }) => {
  const dispatch = useDispatch();
  const uploadProductFile = useSelector((state) => state.uploadProductFile);
  const thumbnailUpload = uploadProductFile?.dataThumbnail;
  const userData = useSelector((state) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: "PRODUCT_IMAGE",
    item: { item },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();

      if (item && dropResult) {
        dispatch({
          type: productThumbnailDNDActionTypes.PRODUCT_THUMBNAIL_UPLOAD_DND,
          payload: {
            accountGuid,
            productUploadCategoryType: item?.item?.uploadedCategoryType,
            uploadedCategoryId: item?.item?.productUploadFilesWithTypeID,
            productId: item?.item?.productId || thumbnailUpload?.id || 7689,
          },
        });
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div
      ref={drag}
      style={{
        opacity,
        position: "relative",
        width: 140,
        height: 142,
        padding: "8px 0 0",
        marginRight: 8,
        marginBottom: 8,
        // border: "1px solid #ccc",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 100,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      ></div>
      {isImage(item?.uploadedFileUrl) ? (
        <img
          src={item?.uploadedFileUrl}
          style={{ width: "138px", height: "138px" }}
        ></img>
      ) : (
        <model-viewer
          style={{
            width: "138px",
            height: "138px",
            borderRadius: "5px",
            flexGrow: 0,
            objectFit: "contain",
            backgroundColor: "#ebe3ff",
          }}
          src={item?.uploadedFileUrl}
          ios-src=""
          alt="model name"
          camera-controls
          ar
          ar-modes="webxr scene-viewer quick-look"
          environment-image="neutral"
          oncontextmenu="return false;"
          // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
          autoplay
        ></model-viewer>
      )}
    </div>
  );
};

function CategoryList({ productData, callingData }) {
  return (
    <Box
      sx={{
        maxHeight: 200,
        overflow: "auto",
        display: "flex",
        flexDirection: "row",
        width: "648px",
        height: "206px",
        background: "#ebe3ff77",
        gap: "20px",
        justifyItems: "flex-start",
        marginLeft: "24px",
        paddingLeft: "20px",
        paddingTop: "24px",
        "&::-webkit-scrollbar": {
          width: 10,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "black",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "black",
          borderRadius: 2,
        },
      }}
    >
      {productData?.artFiles?.length > 0 ? (
        <figure
          style={{ cursor: "pointer" }}
          onClick={() => callingData(productData?.artFiles, "artFiles")}
        >
          {isImage(productData?.artFiles?.[0]?.uploadedFileUrl) ? (
            <img
              src={productData?.artFiles?.[0]?.uploadedFileUrl}
              style={{ width: "123.9px", height: "132px" }}
            ></img>
          ) : (
            <model-viewer
              style={{
                width: "123.9px",
                height: "132px",
                borderRadius: 5,
                border: "2px solid #ccc",
              }}
              src={productData?.artFiles?.[0]?.uploadedFileUrl}
              ios-src=""
              alt="model name"
              camera-controls
              ar
              ar-modes="webxr scene-viewer quick-look"
              environment-image="neutral"
              oncontextmenu="return false;"
              // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
              autoplay
            ></model-viewer>
          )}
          <Typography
            style={{
              color: "#2b2639",
              fontSize: 13,
              textAlign: "left",
              marginTop: 5,
            }}
          >{`Art Files (${productData?.artFiles?.length})`}</Typography>
        </figure>
      ) : null}
      {productData?.imageFiles?.length > 0 ? (
        <figure
          style={{ cursor: "pointer" }}
          onClick={() => callingData(productData?.imageFiles, "imageFiles")}
        >
          {isImage(productData?.imageFiles?.[0]?.uploadedFileUrl) ? (
            <img
              src={productData?.imageFiles?.[0]?.uploadedFileUrl}
              style={{ width: "123.9px", height: "132px" }}
            ></img>
          ) : (
            <model-viewer
              style={{
                width: "123.9px",
                height: "132px",
                borderRadius: 5,
                border: "2px solid #ccc",
              }}
              src={productData?.imageFiles?.[0]?.uploadedFileUrl}
              ios-src=""
              alt="model name"
              camera-controls
              ar
              ar-modes="webxr scene-viewer quick-look"
              environment-image="neutral"
              oncontextmenu="return false;"
              // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
              autoplay
            ></model-viewer>
          )}
          <Typography
            style={{
              color: "#2b2639",
              fontSize: 13,
              textAlign: "left",
              marginTop: 5,
            }}
          >{`Image Files (${productData?.imageFiles?.length})`}</Typography>
        </figure>
      ) : null}
      {productData?.otherFiles?.length > 0 ? (
        <figure
          style={{ cursor: "pointer" }}
          onClick={() => callingData(productData?.otherFiles, "otherFiles")}
        >
          {isImage(productData?.otherFiles?.[0]?.uploadedFileUrl) ? (
            <img
              src={productData?.otherFiles?.[0]?.uploadedFileUrl}
              style={{ width: "123.9px", height: "132px" }}
            ></img>
          ) : (
            <model-viewer
              style={{
                width: "123.9px",
                height: "132px",
                borderRadius: 5,
                border: "2px solid #ccc",
              }}
              src={productData?.otherFiles?.[0]?.uploadedFileUrl}
              ios-src=""
              alt="model name"
              camera-controls
              ar
              ar-modes="webxr scene-viewer quick-look"
              environment-image="neutral"
              oncontextmenu="return false;"
              // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
              autoplay
            ></model-viewer>
          )}
          <Typography
            style={{
              color: "#2b2639",
              fontSize: 13,
              textAlign: "left",
              marginTop: 5,
            }}
          >{`Art Files (${productData?.otherFiles?.length})`}</Typography>
        </figure>
      ) : null}
      {productData?.patternFiles?.length > 0 ? (
        <figure
          style={{ cursor: "pointer" }}
          onClick={() => callingData(productData?.patternFiles, "patternFiles")}
        >
          {isImage(productData?.patternFiles?.[0]?.uploadedFileUrl) ? (
            <img
              src={productData?.patternFiles?.[0]?.uploadedFileUrl}
              style={{ width: "123.9px", height: "132px" }}
            ></img>
          ) : (
            <model-viewer
              style={{
                width: "123.9px",
                height: "132px",
                borderRadius: 5,
                border: "2px solid #ccc",
              }}
              src={productData?.patternFiles?.[0]?.uploadedFileUrl}
              ios-src=""
              alt="model name"
              camera-controls
              ar
              ar-modes="webxr scene-viewer quick-look"
              environment-image="neutral"
              oncontextmenu="return false;"
              // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
              autoplay
            ></model-viewer>
          )}
          <Typography
            style={{
              color: "#2b2639",
              fontSize: 13,
              textAlign: "left",
              marginTop: 5,
            }}
          >{`Pattern Files (${productData?.patternFiles?.length})`}</Typography>
        </figure>
      ) : null}
      {productData?.sketchFiles?.length > 0 ? (
        <figure
          style={{ cursor: "pointer" }}
          onClick={() => callingData(productData?.sketchFiles, "sketchFiles")}
        >
          {isImage(productData?.sketchFiles?.[0]?.uploadedFileUrl) ? (
            <img
              src={productData?.sketchFiles?.[0]?.uploadedFileUrl}
              style={{ width: "123.9px", height: "132px" }}
            ></img>
          ) : (
            <model-viewer
              style={{
                width: "123.9px",
                height: "132px",
                borderRadius: 5,
                border: "2px solid #ccc",
              }}
              src={productData?.sketchFiles?.[0]?.uploadedFileUrl}
              ios-src=""
              alt="model name"
              camera-controls
              ar
              ar-modes="webxr scene-viewer quick-look"
              environment-image="neutral"
              oncontextmenu="return false;"
              // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
              autoplay
            ></model-viewer>
          )}
          <Typography
            style={{
              color: "#2b2639",
              fontSize: 13,
              textAlign: "left",
              marginTop: 5,
            }}
          >{`Sketch Files (${productData?.sketchFiles?.length})`}</Typography>
        </figure>
      ) : null}
      {productData?.threeDFilesUploaded?.length > 0 ? (
        <figure
          style={{ cursor: "pointer" }}
          onClick={() =>
            callingData(productData?.threeDFilesUploaded, "threeDFilesUploaded")
          }
        >
          {isImage(productData?.threeDFilesUploaded[0]?.uploadedFileUrl) ? (
            <img
              src={productData?.threeDFilesUploaded[0]?.uploadedFileUrl}
              style={{ width: "123.9px", height: "132px" }}
            ></img>
          ) : (
            <model-viewer
              style={{
                width: "123.9px",
                height: "132px",
                borderRadius: 5,
                border: "2px solid #ccc",
              }}
              src={productData?.threeDFilesUploaded[0]?.uploadedFileUrl}
              ios-src=""
              alt="model name"
              camera-controls
              ar
              ar-modes="webxr scene-viewer quick-look"
              environment-image="neutral"
              oncontextmenu="return false;"
              // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
              autoplay
            ></model-viewer>
          )}
          <Typography
            style={{
              color: "#2b2639",
              fontSize: 13,
              textAlign: "left",
              marginTop: 5,
            }}
          >{`3D Files (${productData?.threeDFilesUploaded?.length})`}</Typography>
        </figure>
      ) : null}
      {/* ))} */}
    </Box>
  );
}

function EditThumbnail({ open, handleClose, productDetails }) {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: "PRODUCT_IMAGE",
    drop: () => ({ name: "Dustbin" }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const dispatch = useDispatch();
  const classes = useUpload3dModalStyles();
  const theme = useTheme();
  const uploadProductFile = useSelector((state) => state.uploadProductFile);
  const productWithUploadType = useSelector(
    (state) => state.productWithUploadType
  );
  const thumbnailUpload = uploadProductFile?.dataThumbnail;
  const userData = useSelector((state) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;
  const [display, setDisplay] = React.useState(false);
  const [data, setData] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [reUploadUI, setReUploadUI] = React.useState(false);

  React.useEffect(() => {
    dispatch({
      type: productDetailaWithEachUploadTypes.GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE,
      payload: {
        ProductId:
          thumbnailUpload?.id ||
          productDetails?.productDetailedEntity?.productID,
        accountGuid,
      },
    });
  }, [accountGuid, thumbnailUpload?.id]);

  const callingData = (e, newType) => {
    if (type === null) {
      setDisplay(true);
      setData(e);
    } else if (newType === type && data !== null) {
      setData(null);
      setDisplay(false);
    } else {
      setData(e);
      setDisplay(true);
    }
    setType(newType);
  };

  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };

  const productData = productWithUploadType?.data || {};

  const loaderOn = !productWithUploadType?.data;

  const uploadFile = React.useRef(null);

  // useEffect(() => {
  //   if (uploadProductFile?.progressThumbnail === 100 && reUploadUI) {
  //     setReUploadUI(false);
  //   }
  // }, [reUploadUI, uploadProductFile?.progressThumbnail]);

  const onUploadFile = (event) => {
    uploadFile.current = event.target.files[0];
  };

  const handleReupload = () => {
    setReUploadUI(true);
    uploadFile.current = null;
    dispatch({
      type: uploadProductFileReducerTypes.CLEAR_UPLOAD_PRODUCT_THUMBNAIL_FILE,
    });
  };

  function onUploadProgress(progressEvent) {
    const progress = (progressEvent.loaded / progressEvent.total) * 100;

    dispatch({
      type: uploadProductFileReducerTypes.SET_UPLOAD_PRODUCT_THUMBNAIL_FILE_PROGRESS,
      payload: parseInt(progress),
    });
  }

  const handleOnUpload = (file) => {
    if (uploadFile.current) {
      const params = new FormData();

      params.append("ProductThumbnail", uploadFile.current);
      params.append("AccountGuid", accountGuid);
      params.append(
        "ProductUploadedThumbnailDetailsId",
        productData?.productDetailedEntity?.thumbnailDetailId || 7689
      );

      dispatch({
        type: uploadProductFileReducerTypes.UPLOAD_PRODUCT_THUMBNAIL_FILE,
        payload: params,
        onUploadProgress,
      });
    }
  };

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal open={open}>
        <Box
          className={classes.modalStyle}
          style={{
            backgroundColor: theme?.palette?.mode === "light" && "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box className={classes.closeBox}>
              {theme?.palette?.mode === "light" ? (
                <LightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <NightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>

            <Box className={classes.titleBox}>
              <Typography
                style={
                  theme?.palette?.mode === "light"
                    ? heading.lightMode
                    : heading.nightMode
                }
              >
                {"Edit Thumbnail"}
              </Typography>
            </Box>
            {loaderOn ? (
              <UniversalLoader />
            ) : (
              <>
                <Box
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: 54,
                    paddingRight: 54,
                    width: "100%",
                  }}
                >
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        sx={{
                          // display: "flex",
                          // flexDirection: "column",
                          // width: "388px",
                          width: "418px",
                          flexGrow: "0",
                        }}
                      >
                        <Box
                          sx={{
                            background: "#ebe3ff77",
                            width: "418px",
                            flexGrow: "0",
                            borderRadius: "6px",
                            margin: "0px 24px 6px 0px",
                            padding: "0",
                            minHeight: 306,
                            ...(isOver && { opacity: 0.7 }),
                            ...(reUploadUI && { display: "none" }),
                          }}
                          ref={drop}
                        >
                          {productData?.productDetailedEntity
                            ?.productThumbnailUploadedURL &&
                            (isImage(
                              productData?.productDetailedEntity
                                ?.productThumbnailUploadedURL
                            ) ? (
                              <img
                                src={
                                  productData?.productDetailedEntity
                                    ?.productThumbnailUploadedURL
                                }
                                style={{
                                  maxWidth: "100%",
                                  height: "306px",
                                  padding: "10px 69.7px 10.3px 87.6px",
                                }}
                              ></img>
                            ) : (
                              <model-viewer
                                style={{
                                  width: "100%",
                                  height: 306,
                                  borderRadius: 5,
                                  padding: "10px 69.7px 10.3px 87.6px",
                                  border: "2px solid #ccc",
                                }}
                                src={
                                  productData?.productDetailedEntity
                                    ?.productThumbnailUploadedURL
                                }
                                ios-src=""
                                alt="model name"
                                camera-controls
                                ar
                                ar-modes="webxr scene-viewer quick-look"
                                environment-image="neutral"
                                oncontextmenu="return false;"
                                // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
                                autoplay
                              ></model-viewer>
                            ))}
                        </Box>

                        {reUploadUI ? (
                          <UploadThumbnail
                            errors={{}}
                            uploadFile={uploadFile.current}
                            uploadProductFile={uploadProductFile}
                            handleReupload={() => {}}
                            onUploadFile={onUploadFile}
                            handleOnUpload={handleOnUpload}
                          />
                        ) : null}

                        {display ? (
                          <p
                            style={{
                              width: "405px",
                              // height: "17px",
                              margin: "6px 41px 386px -51px",
                              objectFit: "contain",
                              fontFamily: "Poppins",
                              fontSize: "11px",
                              fontWeight: "bold",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#a49bbe",
                              padding: "0 43px 0 58.1px",
                            }}
                          >
                            Note: You can drag and drop 3D from the grid to make
                            it as a thumbnail:
                          </p>
                        ) : (
                          <GreenButton
                            disableFocusRipple
                            disableElevation
                            style={{
                              width: 400,
                              // marginLeft: 16,
                              marginTop: 24,
                            }}
                            onClick={handleReupload}
                          >
                            <i
                              className="fa-solid fa-cloud-arrow-up"
                              style={{
                                margin: "0px 10px 3px 0px",
                                fontSize: "22px",
                              }}
                            ></i>
                            ReUpload
                          </GreenButton>
                        )}
                      </Box>

                      <Box>
                        <CategoryList
                          productData={productData}
                          callingData={callingData}
                        />
                        {display ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              width: "648px",
                              height: "411px",
                              border: "solid 1px #d7d0e8",
                              background: "white",
                              justifyItems: "flex-start",
                              marginLeft: "24px",
                              padding: "20px",
                              marginTop: "44px",
                              textAlign: "start",
                              flexWrap: "wrap",
                              overflowY: "auto",
                            }}
                          >
                            {data?.map((items, i) => (
                              <ImageItem item={items} />
                            ))}
                          </Box>
                        ) : null}
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: "32px",
                              alignItems: "center",
                              justifyContent: "start",
                              marginLeft: "25px",
                            }}
                          ></Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    paddingLeft: 54,
                    paddingRight: 54,
                    width: "100%",
                    paddingTop: 30,
                    justifyContent: "center",
                  }}
                >
                  <BlackBorderedButton
                    style={{
                      width: "86px",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </BlackBorderedButton>
                  <PurpleButton
                    onClick={handleClose}
                    style={{
                      width: "86px",
                      marginLeft: 16,
                    }}
                  >
                    Done
                  </PurpleButton>
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default function EditThumbnailModal(props) {
  return (
    <DndProvider backend={HTML5Backend}>
      <EditThumbnail {...props} />
    </DndProvider>
  );
}
