/*
 **	User name
 **	Created	5/25/2023
 **	company.ts
 */

const initialState = {
  companyDataLoading: true,
  loading: false,
  error: null,
  data: null,
  status: null,
  companyTypeData: null,
  companyTypeError: null,
  allCompanyEmployesData: null,
  allCompanyEmployesError: null,
  CompanyLicencesData: null,
  CompanyLicencesError: null,
  CompanyRolesData: null,
  CompanyRolesError: null,
  isSearch: false,
};

export const companyActionTypes = {
  FETCH_COMPANY_DETAILS_BY_USERNAME: "FETCH_COMPANY_DETAILS_BY_USERNAME",
  FETCH_COMPANY_DETAILS_BY_USERNAME_REQUEST:
    "FETCH_COMPANY_DETAILS_BY_USERNAME_REQUEST",
  FETCH_COMPANY_DETAILS_BY_USERNAME_SUCCESS:
    "FETCH_COMPANY_DETAILS_BY_USERNAME_SUCCESS",
  FETCH_COMPANY_DETAILS_BY_USERNAME_FAILURE:
    "FETCH_COMPANY_DETAILS_BY_USERNAME_FAILURE",

  FETCH_COMPANY_TYPE: "FETCH_COMPANY_TYPE",
  FETCH_COMPANY_TYPE_REQUEST: "FETCH_COMPANY_TYPE_REQUEST",
  FETCH_COMPANY_TYPE_SUCCESS: "FETCH_COMPANY_TYPE_SUCCESS",
  FETCH_COMPANY_TYPE_FAILURE: "FETCH_COMPANY_TYPE_FAILURE",

  GET_ALL_COMPANY_EMPLOYEES: "GET_ALL_COMPANY_EMPLOYEES",
  REQUEST_GET_ALL_COMPANY_EMPLOYEES: "REQUEST_GET_ALL_COMPANY_EMPLOYEES",
  SUCCESS_GET_ALL_COMPANY_EMPLOYEES: "SUCCESS_GET_ALL_COMPANY_EMPLOYEES",
  ERROR_GET_ALL_COMPANY_EMPLOYEES: "ERROR_GET_ALL_COMPANY_EMPLOYEES",

  GET_ALL_COMPANY_ROLES: "GET_ALL_COMPANY_ROLES",
  REQUEST_GET_ALL_COMPANY_ROLES: "REQUEST_GET_ALL_COMPANY_ROLES",
  SUCCESS_GET_ALL_COMPANY_ROLES: "SUCCESS_GET_ALL_COMPANY_ROLES",
  ERROR_GET_ALL_COMPANY_ROLES: "ERROR_GET_ALL_COMPANY_ROLES",

  GET_ALL_COMPANY_LICENCES: "GET_ALL_COMPANY_LICENCES",
  REQUEST_GET_ALL_COMPANY_LICENCES: "REQUEST_GET_ALL_COMPANY_LICENCES",
  SUCCESS_GET_ALL_COMPANY_LICENCES: "SUCCESS_GET_ALL_COMPANY_LICENCES",
  ERROR_GET_ALL_COMPANY_LICENCES: "ERROR_GET_ALL_COMPANY_LICENCES",
};

export const getCompanyEmployeesForSearchActionTypes = {
  GET_COMPANY_EMPLOYEE_FOR_SEARCH: "GET_COMPANY_EMPLOYEE_FOR_SEARCH",
  GET_COMPANY_EMPLOYEE_FOR_SEARCH_REQUEST:
    "GET_COMPANY_EMPLOYEE_FOR_SEARCH_REQUEST",
  GET_COMPANY_EMPLOYEE_FOR_SEARCH_SUCCESS:
    "GET_COMPANY_EMPLOYEE_FOR_SEARCH_SUCCESS",
  GET_COMPANY_EMPLOYEE_FOR_SEARCH_FAILURE:
    "GET_COMPANY_EMPLOYEE_FOR_SEARCH_FAILURE",
  CLEAR_GET_COMPANY_EMPLOYEE_FOR_SEARCH:
    "CLEAR_GET_COMPANY_EMPLOYEE_FOR_SEARCH",
};

function reducerFunc(state = initialState, action) {
  switch (action.type) {
    case companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME_REQUEST:
      return {
        ...state,
        companyDataLoading: true,
        error: null,
        status: companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME_REQUEST,
      };
    case companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME_FAILURE:
      return {
        ...state,
        companyDataLoading: false,
        error: action.payload,
        status: companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME_FAILURE,
      };
    case companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME_SUCCESS:
      return {
        ...state,
        companyDataLoading: false,
        data: action.payload,
        status: companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME_SUCCESS,
      };

    case companyActionTypes.FETCH_COMPANY_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case companyActionTypes.FETCH_COMPANY_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        companyTypeData: action.payload,
        companyTypeError: null,
      };
    case companyActionTypes.FETCH_COMPANY_TYPE_FAILURE:
      return {
        ...state,
        loading: false,
        companyTypeData: null,
        companyTypeError: action.payload,
      };
    case companyActionTypes.REQUEST_GET_ALL_COMPANY_EMPLOYEES:
      return {
        ...state,
        loading: true,
        isSearch: false,
      };
    case companyActionTypes.SUCCESS_GET_ALL_COMPANY_EMPLOYEES:
      return {
        ...state,
        loading: false,
        allCompanyEmployesData: action.payload,
        isSearch: false,
        allCompanyEmployesError: null,
      };
    case companyActionTypes.ERROR_GET_ALL_COMPANY_EMPLOYEES:
      return {
        ...state,
        loading: false,
        allCompanyEmployesData: null,
        allCompanyEmployesError: action.payload,
        isSearch: false,
      };

    case companyActionTypes.REQUEST_GET_ALL_COMPANY_ROLES:
      return {
        ...state,
        loading: true,
      };
    case companyActionTypes.SUCCESS_GET_ALL_COMPANY_ROLES:
      return {
        ...state,
        loading: false,
        CompanyRolesData: action.payload,
        CompanyRolesError: null,
      };
    case companyActionTypes.ERROR_GET_ALL_COMPANY_ROLES:
      return {
        ...state,
        loading: false,
        CompanyRolesData: null,
        CompanyRolesError: action.payload,
      };

    case companyActionTypes.REQUEST_GET_ALL_COMPANY_LICENCES:
      return {
        ...state,
        CompanyLicencesData: [],
        CompanyLicencesError: null,
        loading: true,
      };
    case companyActionTypes.SUCCESS_GET_ALL_COMPANY_LICENCES:
      return {
        ...state,
        loading: false,
        CompanyLicencesData: action.payload,
        CompanyLicencesError: null,
      };
    case companyActionTypes.ERROR_GET_ALL_COMPANY_LICENCES:
      return {
        ...state,
        loading: false,
        CompanyLicencesData: null,
        CompanyLicencesError: action.payload,
      };

    case getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH_REQUEST:
      return {
        ...state,
        allCompanyEmployesError: null,
        isSearch: true,
        status:
          getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH_REQUEST,
      };
    case getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH_FAILURE:
      return {
        ...state,
        allCompanyEmployesError: action.payload,
        isSearch: true,
        status:
          getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH_FAILURE,
      };
    case getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH_SUCCESS:
      return {
        ...state,
        allCompanyEmployesData: action.payload,
        isSearch: true,
        status:
          getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH_SUCCESS,
      };

    case getCompanyEmployeesForSearchActionTypes.CLEAR_GET_COMPANY_EMPLOYEE_FOR_SEARCH: {
      return {
        ...initialState,
        status:
          getCompanyEmployeesForSearchActionTypes.CLEAR_GET_COMPANY_EMPLOYEE_FOR_SEARCH,
      };
    }

    default:
      return state;
  }
}

const initialState2 = {
  loading: false,
  error: null,
  data: null,
  status: null,
};

export const companyInviteEmployeesActionTypes = {
  INVITE_EMPLOYEES_FOR_REGISTRATION: "INVITE_EMPLOYEES_FOR_REGISTRATION",
  INVITE_EMPLOYEES_FOR_REGISTRATION_REQUEST:
    "INVITE_EMPLOYEES_FOR_REGISTRATION_REQUEST",
  INVITE_EMPLOYEES_FOR_REGISTRATION_SUCCESS:
    "INVITE_EMPLOYEES_FOR_REGISTRATION_SUCCESS",
  INVITE_EMPLOYEES_FOR_REGISTRATION_FAILURE:
    "INVITE_EMPLOYEES_FOR_REGISTRATION_FAILURE",
  CLEAR_INVITE_EMPLOYEES_FOR_REGISTRATION:
    "CLEAR_INVITE_EMPLOYEES_FOR_REGISTRATION",
};

export function companyInviteEmployeesReducer(state = initialState2, action) {
  switch (action.type) {
    case companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION_REQUEST:
      return {
        ...state,
        error: null,
        status:
          companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION_REQUEST,
      };
    case companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION_FAILURE:
      return {
        ...state,
        error: action.payload,
        status:
          companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION_FAILURE,
      };
    case companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status:
          companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION_SUCCESS,
      };

    case companyInviteEmployeesActionTypes.CLEAR_INVITE_EMPLOYEES_FOR_REGISTRATION: {
      return {
        ...initialState2,
        status:
          companyInviteEmployeesActionTypes.CLEAR_INVITE_EMPLOYEES_FOR_REGISTRATION,
      };
    }

    default:
      return state;
  }
}

const initialState3 = {
  loading: false,
  error: null,
  data: null,
  status: null,
};

export const companyDeleteEmployeesActionTypes = {
  DELETE_COMPANY_EMPLOYEES: "DELETE_COMPANY_EMPLOYEES",
  DELETE_COMPANY_EMPLOYEES_REQUEST: "DELETE_COMPANY_EMPLOYEES_REQUEST",
  DELETE_COMPANY_EMPLOYEES_SUCCESS: "DELETE_COMPANY_EMPLOYEES_SUCCESS",
  DELETE_COMPANY_EMPLOYEES_FAILURE: "DELETE_COMPANY_EMPLOYEES_FAILURE",
  CLEAR_DELETE_COMPANY_EMPLOYEES: "CLEAR_DELETE_COMPANY_EMPLOYEES",
};

export function companyDeleteEmployeesReducer(state = initialState3, action) {
  switch (action.type) {
    case companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES_REQUEST:
      return {
        ...state,
        error: null,
        status:
          companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES_REQUEST,
      };
    case companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES_FAILURE:
      return {
        ...state,
        error: action.payload,
        status:
          companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES_FAILURE,
      };
    case companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status:
          companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES_SUCCESS,
      };

    case companyDeleteEmployeesActionTypes.CLEAR_DELETE_COMPANY_EMPLOYEES: {
      return {
        ...initialState3,
        status:
          companyDeleteEmployeesActionTypes.CLEAR_DELETE_COMPANY_EMPLOYEES,
      };
    }

    default:
      return state;
  }
}

const initialState4 = {
  loading: false,
  error: null,
  data: null,
  status: null,
};

export const updateCompanyEmployeeRoleActionTypes = {
  UPDATE_EMPLOYEES_ROLE: "UPDATE_EMPLOYEES_ROLE",
  UPDATE_EMPLOYEES_ROLE_REQUEST: "UPDATE_EMPLOYEES_ROLE_REQUEST",
  UPDATE_EMPLOYEES_ROLE_SUCCESS: "UPDATE_EMPLOYEES_ROLE_SUCCESS",
  UPDATE_EMPLOYEES_ROLE_FAILURE: "UPDATE_EMPLOYEES_ROLE_FAILURE",
  CLEAR_UPDATE_EMPLOYEES_ROLE: "CLEAR_UPDATE_EMPLOYEES_ROLE",
};

export function updateCompanyEmployeeRoleReducer(
  state = initialState4,
  action
) {
  switch (action.type) {
    case updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE_REQUEST:
      return {
        ...state,
        error: null,
        status:
          updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE_REQUEST,
      };
    case updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE_FAILURE:
      return {
        ...state,
        error: action.payload,
        status:
          updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE_FAILURE,
      };
    case updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status:
          updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE_SUCCESS,
      };

    case updateCompanyEmployeeRoleActionTypes.CLEAR_UPDATE_EMPLOYEES_ROLE: {
      return {
        ...initialState4,
        status:
          updateCompanyEmployeeRoleActionTypes.CLEAR_UPDATE_EMPLOYEES_ROLE,
      };
    }

    default:
      return state;
  }
}

const initialState5 = {
  loading: false,
  error: null,
  data: null,
  status: null,
};

export const updateCompanyEmployeeLicenseActionTypes = {
  UPDATE_EMPLOYEES_LICENSE: "UPDATE_EMPLOYEES_LICENSE",
  UPDATE_EMPLOYEES_LICENSE_REQUEST: "UPDATE_EMPLOYEES_LICENSE_REQUEST",
  UPDATE_EMPLOYEES_LICENSE_SUCCESS: "UPDATE_EMPLOYEES_LICENSE_SUCCESS",
  UPDATE_EMPLOYEES_LICENSE_FAILURE: "UPDATE_EMPLOYEES_LICENSE_FAILURE",
  CLEAR_UPDATE_EMPLOYEES_LICENSE: "CLEAR_UPDATE_EMPLOYEES_LICENSE",
};

export function updateCompanyEmployeeLicenseReducer(
  state = initialState5,
  action
) {
  switch (action.type) {
    case updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE_REQUEST:
      return {
        ...state,
        error: null,
        status:
          updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE_REQUEST,
      };
    case updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        status:
          updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE_FAILURE,
      };
    case updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status:
          updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE_SUCCESS,
      };

    case updateCompanyEmployeeLicenseActionTypes.CLEAR_UPDATE_EMPLOYEES_LICENSE: {
      return {
        ...initialState5,
        status:
          updateCompanyEmployeeLicenseActionTypes.CLEAR_UPDATE_EMPLOYEES_LICENSE,
      };
    }

    default:
      return state;
  }
}

const initialState6 = {
  loading: false,
  error: null,
  data: null,
  status: null,
};

export const saveSkipSettingForInviteEmployeesActionTypes = {
  SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES:
    "SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES",
  SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_REQUEST:
    "SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_REQUEST",
  SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_SUCCESS:
    "SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_SUCCESS",
  SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_FAILURE:
    "SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_FAILURE",
  CLEAR_SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES:
    "CLEAR_SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES",
};

export function saveSkipSettingForInviteEmployeesReducer(
  state = initialState6,
  action
) {
  switch (action.type) {
    case saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_REQUEST:
      return {
        ...state,
        error: null,
        status:
          saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_REQUEST,
      };
    case saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_FAILURE:
      return {
        ...state,
        error: action.payload,
        status:
          saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_FAILURE,
      };
    case saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status:
          saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_SUCCESS,
      };

    case saveSkipSettingForInviteEmployeesActionTypes.CLEAR_SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES: {
      return {
        ...initialState6,
        status:
          saveSkipSettingForInviteEmployeesActionTypes.CLEAR_SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES,
      };
    }

    default:
      return state;
  }
}

const initialState7 = {
  loading: false,
  error: null,
  data: null,
  status: null,
  updateLoading: false,
  updateError: null,
  updateData: null,
  updateStatus: null,
};

export const companyPermissionsActionTypes = {
  GET_COMPANY_PERMISSIONS: "GET_COMPANY_PERMISSIONS",
  GET_COMPANY_PERMISSIONS_REQUEST: "GET_COMPANY_PERMISSIONS_REQUEST",
  GET_COMPANY_PERMISSIONS_SUCCESS: "GET_COMPANY_PERMISSIONS_SUCCESS",
  GET_COMPANY_PERMISSIONS_FAILURE: "GET_COMPANY_PERMISSIONS_FAILURE",
  CLEAR_GET_COMPANY_PERMISSIONS: "CLEAR_GET_COMPANY_PERMISSIONS",

  UPDATE_COMPANY_PERMISSIONS: "UPDATE_COMPANY_PERMISSIONS",
  UPDATE_COMPANY_PERMISSIONS_REQUEST: "UPDATE_COMPANY_PERMISSIONS_REQUEST",
  UPDATE_COMPANY_PERMISSIONS_SUCCESS: "UPDATE_COMPANY_PERMISSIONS_SUCCESS",
  UPDATE_COMPANY_PERMISSIONS_FAILURE: "UPDATE_COMPANY_PERMISSIONS_FAILURE",
  CLEAR_UPDATE_COMPANY_PERMISSIONS: "CLEAR_UPDATE_COMPANY_PERMISSIONS",
};

export function companyPermissionsReducer(state = initialState7, action) {
  switch (action.type) {
    case companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: !action?.payload?.refetching,
        error: null,
        status: companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS_REQUEST,
      };
    case companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS_FAILURE,
      };
    case companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        status: companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS_SUCCESS,
      };

    case companyPermissionsActionTypes.CLEAR_GET_COMPANY_PERMISSIONS: {
      return {
        ...initialState7,
        loading: false,
        status: companyPermissionsActionTypes.CLEAR_GET_COMPANY_PERMISSIONS,
      };
    }

    case companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateError: null,
        updateStatus:
          companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_REQUEST,
      };
    case companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateError: action.payload,
        updateStatus:
          companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_FAILURE,
      };
    case companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateData: action.payload,
        updateStatus:
          companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_SUCCESS,
      };

    default:
      return state;
  }
}

const selectedSeats = {
  selectedSeats: 0,
};

export const manageSeatsActionTypes = {
  Update_Seats: "Update_Seats",
};

export function manageSeatsReducer(state = selectedSeats, action) {
  switch (action.type) {
    case manageSeatsActionTypes.Update_Seats:
      return { selectedSeats: action.payload };

      return {
        ...state,
        updateLoading: false,
        updateData: action.payload,
        updateStatus:
          companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_SUCCESS,
      };

    default:
      return state;
  }
}
export default reducerFunc;
