import { uniqBy } from "lodash";

export const handlePaginationData = ({
  payload,
  oldData,
  uniqueIdentifier,
}) => {
  let newData = null;

  if (payload) {
    if (payload.pageNumber === 1) {
      newData = payload;
    } else {
      if (!payload.data) {
        // this will happen if API doesn't support pagination yet
        newData = { data: payload };
      } else {
        newData = {
          ...payload,
          data: uniqBy([...oldData?.data, ...payload.data], uniqueIdentifier),
        };
      }
    }
  }

  return newData;
};

export const handlePaginationLoadingData = ({ payload, oldData }) => {
  let newData = oldData;

  if (payload && payload.pageNumber === 1) {
    newData = null;
  }
  return newData;
};
