import { userRegisterReducerTypes } from "./userReducer";

const initialState = {
  loading: false,
  error: null,
  data: null,
  status: null,
};

export const homeActionTypes = {
  GET_ALL_HOME_PRODUCTS: "GET_ALL_HOME_PRODUCTS",
  GET_ALL_HOME_PRODUCTS_REQUEST: "GET_ALL_HOME_PRODUCTS_REQUEST",
  GET_ALL_HOME_PRODUCTS_SUCCESS: "GET_ALL_HOME_PRODUCTS_SUCCESS",
  GET_ALL_HOME_PRODUCTS_FAILURE: "GET_ALL_HOME_PRODUCTS_FAILURE",
};

function reducerFunc(state = initialState, action) {
  switch (action.type) {
    case homeActionTypes.GET_ALL_HOME_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        status: homeActionTypes.GET_ALL_HOME_PRODUCTS_REQUEST,
      };
    case homeActionTypes.GET_ALL_HOME_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        status: homeActionTypes.GET_ALL_HOME_PRODUCTS_FAILURE,
      };
    case homeActionTypes.GET_ALL_HOME_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        status: homeActionTypes.GET_ALL_HOME_PRODUCTS_SUCCESS,
      };

    case userRegisterReducerTypes.LOGOUT_USER:
      return { ...initialState };

    default:
      return state;
  }
}

export default reducerFunc;
