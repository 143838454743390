/*
 **	User name
 **	Created	6/1/2023
 **	LibraryProductUploadProvider.tsx
 */

import React, { useContext, useEffect, useState } from "react";
import { CategoryDataContext } from "app/components/CategoryComponent/CategoryDataContext";
import { useAppSelector } from "app/utils/hooks";
import { accountGuidSelector } from "app/redux/selectors/user-selector";
import { useDispatch } from "react-redux";
import { libraryActionTypes } from "app/redux/reducers/library";
import { productDetailaWithEachUploadTypes } from "app/redux/reducers/productReducer/product-main";

import {
  LibraryProductUploadContext,
  LibraryProductInfo,
  defaultProductInfo,
} from "./LibraryProductUploadContext";

const getVisibilityTypeIdBasedUponString = (visibility) => {
  const temp =
    (visibility === "Public" && 1) ||
    (visibility === "Private" && 2) ||
    (visibility === "Team" && 3) ||
    2;

  return temp;
};

export const LibraryProductUploadProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { libraryData } = useContext(CategoryDataContext);
  const accountGuid = useAppSelector(accountGuidSelector);

  const [productInfo, setProductInfo] =
    useState<LibraryProductInfo>(defaultProductInfo);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [uploadedImagesInEdit, setUploadedImagesInEdit] = useState([]);
  const [productIdForEdit, setProductIdForEdit] = useState(null);
  const productWithUploadType = useAppSelector(
    (state) => state.productWithUploadType
  );

  useEffect(() => {
    if (productIdForEdit) {
      dispatch({
        type: productDetailaWithEachUploadTypes.GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE,
        payload: {
          ProductId: productIdForEdit,
          LibraryId: productIdForEdit,
          accountGuid,
          guid: accountGuid,
          isViewing: true,
          isLibraryProduct: true,
        },
      });
    }
  }, [productIdForEdit]);

  const individualProductDataForLibrary = productWithUploadType?.data || null;
  const visibilityType = ["Public", "Private", "Team"];

  useEffect(() => {
    if (individualProductDataForLibrary && productIdForEdit) {
      setProductInfo({
        title: individualProductDataForLibrary?.libraryItemTitle,
        tools:
          individualProductDataForLibrary?.toolListDetails?.map(
            ({ toolId }) => toolId
          ) || [],
        tags:
          individualProductDataForLibrary?.tagListDetails?.map(
            ({ tagId }) => tagId
          ) || [],
        description: individualProductDataForLibrary?.libraryDescription,
        // added for Edit functionality
        visibilityId:
          visibilityType[productWithUploadType?.data?.visibilityId - 1],
        downloadType: individualProductDataForLibrary?.downloadType,
        licenseId: individualProductDataForLibrary?.licenseId,
        price: individualProductDataForLibrary?.price,
        currencyId: individualProductDataForLibrary?.currencyId,
        isPublished: individualProductDataForLibrary?.isPublished,
      });
      setUploadedImagesInEdit([
        ...(individualProductDataForLibrary?.productLibraryAdditionalFilesDetails ||
          []),
      ]);
      libraryData.setData(
        individualProductDataForLibrary?.libraryCategorySavedDetails || {}
      );
    } else {
      setUploadedImagesInEdit([]);
    }
  }, [individualProductDataForLibrary, productIdForEdit]);

  const setProductData = (id) => {
    setProductIdForEdit(id);
  };

  const clearLibraryUpload = () => {
    setProductIdForEdit(null);
    setUploadedImagesInEdit([]);
    setUploadedImages([]);
    setProductInfo(defaultProductInfo);
  };

  const publishLibraryProduct = () => {
    uploadLibaryProduct({ isPublished: true });
  };

  const saveLibraryProductAsDraft = () => {
    uploadLibaryProduct({ isPublished: false });
  };

  const uploadLibaryProduct = (data) => {
    const payload = new FormData();

    payload.append("AccountGuid", accountGuid);

    if (uploadedImages?.length) {
      uploadedImages.forEach((file) => {
        payload.append("LibraryImagesList", file);
      });
    }
    payload.append(
      "ProductLibraryThumbnail",
      uploadedImages.length
        ? uploadedImages[window.sessionStorage.getItem("thumbnail")] ||
            uploadedImages[0]
        : {}
    );
    payload.append("LibraryDescription", productInfo?.description);
    payload.append("DownloadType", productInfo?.downloadType);
    // payload.append("ProductAttributeArmholeTypeId", "free");
    payload.append("ProductAttributeArmholeTypeId", "1");
    payload.append("ProductLibraryTagIdsList", productInfo?.tags?.join(","));
    payload.append("ProductLibraryToolIdsList", productInfo?.tools?.join(","));
    payload.append("IsPublished", data.isPublished);
    payload.append("ProductAttributeCollarId", "1");
    payload.append("ProductLibraryId", productIdForEdit || "0");
    payload.append("ProductLibraryTagIdsList", "1");
    payload.append("Price", productInfo?.price);
    payload.append("LibraryItemTitle", productInfo?.title);
    payload.append("CurrencyId", (productInfo?.currencyId).toString());
    payload.append(
      "VisibilityId",
      getVisibilityTypeIdBasedUponString(
        productInfo?.visibilityId
      ).toString() || "2"
    );
    // payload.append("ProductAttributeLengthId", "1");
    // payload.append("ProductAttributeShapeId", "1");
    // payload.append("ProductLibraryToolIdsList", "1");
    // payload.append("ProductAttributeSleeveLengthId", "1");
    // payload.append("SubCategoryId", "1");
    // payload.append("CategoryId", "1");
    // payload.append("ProductAttributeNecklineId", "1");
    // payload.append("LicenseId", "1");
    // payload.append("SizeId", "1");
    // payload.append("GenderId", "1");
    // payload.append("ProductAttributeFitId", "1");

    if (libraryData) {
      const libraryCategoryId = libraryData?.treeData
        ?.map((data) => {
          return data?.id;
        })
        .filter((data) => data)
        .join(",");

      const libraryProductId = libraryData?.productCategoriesTreeData
        ?.map((data) => {
          if (data?.parentCategory?.id) {
            return [data?.parentCategory?.id, data?.id].join(",");
          }
          return data?.id;
        })
        .filter((data) => data)
        .join(",");

      if (libraryData?.attributesTreeData?.length) {
        libraryData?.attributesTreeData.forEach((data3, i) => {
          if (data3?.selectedAttribute) {
            data3?.selectedAttribute.forEach((selectedAttributeValue, i2) => {
              const mainData = {
                productLibraryId: 0,
                libraryCategoryId,
                libraryProductId,
                libraryAttributeId: parseInt(data3.id),
                libraryAttributeValueId: selectedAttributeValue,
              };

              // Library Attributes Id
              payload.append(
                `LibraryCategorySavedDetails`,
                JSON.stringify(mainData)
              );
            });
          }
        });
      }

      if (libraryData?.colorData?.length) {
        libraryData?.colorData?.forEach((colorD) => {
          // Library Attributes Id
          payload.append("ColorList", colorD);
        });
      }
    }

    dispatch({
      type: libraryActionTypes.LIBRARY_UPLOAD,
      payload,
    });
  };

  return (
    <LibraryProductUploadContext.Provider
      value={{
        productInfo,
        setProductInfo,
        uploadedImages,
        setUploadedImages,
        uploadedImagesInEdit,
        clearLibraryUpload,
        publishLibraryProduct,
        saveLibraryProductAsDraft,
        setProductData,
      }}
    >
      {children}
    </LibraryProductUploadContext.Provider>
  );
};
