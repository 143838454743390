/*
 **	User name
 **	Created	6/25/2023
 **	TrialExpiredModal.tsx
 */

import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/system";
import { BlackButton } from "app/components/UIFormFields";
import { useNavigate } from "react-router";
import { SavePopUpSettingsActions } from "app/redux/reducers/settings";
import {
  accountGuidSelector,
  isCompanySelector,
} from "app/redux/selectors/user-selector";
import RouteLinks from "app/navigation/RouteLinks";
import { useAppSelector } from "app/utils/hooks";

// import { LightModeCross, NightModeCross } from "../Logo/logos";

const useHomeAreaStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "540px !important",
    minHeight: "500px !important",
    // padding: "20px",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },

  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
    zIndex: 100,
  },
}));

export default function TrialExpiredModal() {
  const dispatch = useDispatch();
  const classes = useHomeAreaStyles();
  const accountGuid = useSelector(accountGuidSelector);
  const isCompany = useAppSelector(isCompanySelector);
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    ReactComponent: SubscribeGraphic,
  } = require("../../../assets/logo/Subscribe Graphic.svg");

  const handleClose = () => {
    if (!isCompany) {
      dispatch(
        SavePopUpSettingsActions.savePopUpSettings({
          isExpiredPlanPopupCancelled: true,
          accountGuid,
        })
      );
    }
  };

  return (
    <Modal open={true}>
      <Box
        className={classes?.modalStyle}
        style={{
          backgroundColor: theme?.palette?.mode === "light" && "#fff",
        }}
      >
        {/* <Box className={classes.closeBox}>
          {theme?.palette?.mode === "light" ? (
            <LightModeCross
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <NightModeCross
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          )}
        </Box> */}
        <Box
          style={{
            backgroundImage: "linear-gradient(68deg, #f54d64 0%, #f58561 99%)",
            width: "100%",
            height: "250px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SubscribeGraphic />
          </Box>
        </Box>
        <Typography
          style={{
            fontSize: "27px",
            fontWeight: "bold",
            color: "#2b2639",
            textAlign: "center",
          }}
        >
          Trial Expired
        </Typography>
        <Typography
          style={{
            fontSize: "15px",
            color: "#2b2639",
            textAlign: "center",
          }}
        >
          Sorry, we regret to inform you that your trial has now expired. As a
          result, your account has been automatically shifted from the advanced
          plan to the basic plan.
        </Typography>

        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px",
          }}
        >
          <BlackButton
            style={{ width: "104px" }}
            onClick={() => {
              navigate(RouteLinks.LICENSE);
              handleClose();
            }}
          >
            See Plans
          </BlackButton>
        </Box>
      </Box>
    </Modal>
  );
}
