const initState = {
  loading: false,
  data: null,
  error: null,
};

export const categoryReducerTypes = {
  CATEGORY_LIST: "CATEGORY_LIST",
  REQUEST_CATEGORY_LIST: "REQUEST_CATEGORY_LIST",
  RESPONSE_CATEGORY_LIST: "RESPONSE_CATEGORY_LIST",
  ERROR_CATEGORY_LIST: "ERROR_CATEGORY_LIST",
};

export function categoryReducer(state = initState, action) {
  switch (action.type) {
    case categoryReducerTypes.REQUEST_CATEGORY_LIST:
      return { ...state, loading: true };
    case categoryReducerTypes.RESPONSE_CATEGORY_LIST:
      return { error: null, loading: false, data: action.payload };
    case categoryReducerTypes.ERROR_CATEGORY_LIST:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}
