import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import {
  invoiceListReducerTypes,
  subscriptionInvoiceReducerTypes,
} from "../reducers/invoice";

const version = process.env.REACT_APP_API_VERSION;
const version3 = process.env.REACT_APP_API_VERSION_3;

//my product
function* invoiceListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.INVOICE_LIST.replace("{version}", version),
    payload
  );

  return res;
}

function* invoiceList({ accountGuid }) {
  try {
    yield put({
      type: invoiceListReducerTypes.REQUEST_INVOICE_LIST,
      payload: null,
    });
    const response = yield call(invoiceListRequest, { Guid: accountGuid });

    yield put({
      type: invoiceListReducerTypes.RESPONSE_INVOICE_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: invoiceListReducerTypes.ERROR_INVOICE_LIST, payload: e });
  }
}

// DownloadSubscriptionInvoice
function* SubscriptionInvoiceRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SUBSCRIPTION_INVOICE.replace("{version}", version3),
    payload
  );

  return res;
}

function* subscriptionInvoice(payload) {
  try {
    yield put({
      type: subscriptionInvoiceReducerTypes.REQUEST_SUBSCRIPTION_INVOICE,
      payload: null,
    });
    const response = yield call(SubscriptionInvoiceRequest, payload.payload);

    yield put({
      type: subscriptionInvoiceReducerTypes.RESPONSE_SUBSCRIPTION_INVOICE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: subscriptionInvoiceReducerTypes.ERROR_SUBSCRIPTION_INVOICE,
      payload: e,
    });
  }
}

//

function* invoiceSaga() {
  yield all([takeLatest(invoiceListReducerTypes.INVOICE_LIST, invoiceList)]);
  yield all([
    takeLatest(
      subscriptionInvoiceReducerTypes.SUBSCRIPTION_INVOICE,
      subscriptionInvoice
    ),
  ]);
}
export default invoiceSaga;
