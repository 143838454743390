import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { CircularProgress, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { LightModeCross, NightModeCross } from "../Logo/logos";
import UploadForm from "./uploadForm";
import Upload3dModal from "./upload3D/modal";
import { uploadProductFileReducerTypes } from "../../redux/reducers/productReducer/uploadProductFile";
import NewUploadPreviewModal from "./upload3D/newUploadPreviewModalV2";
import { CategoryDataContext } from "../CategoryComponent/CategoryDataContext";

export const useUploadModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxHeight: "95vh",
    // height: 1200,
    maxWidth: 1200,
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
  },
  titleBox: {
    margin: "54px 0px 10px 0px",
  },
  textField: {
    objectFit: "contain",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "normal",
    textAlign: "left",
    color: "#a49bbe",
  },
  editFielBox: {
    width: "100%",
    padding: "0px 54px 24px 54px",
  },
  editBox: {
    display: "flex",
    flexDirection: "row",
  },
}));

export function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export default function UploadWorkModal({
  handleCloseCallback,
  setUploadFile,
  uploadFile,
}) {
  const { productData } = useContext(CategoryDataContext);
  const accept = "image/png, image/gif, image/jpeg";
  const classes = useUploadModalStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  // const dispatch = useDispatch();
  // const userData = useSelector((state) => state.register);
  // const accountGuid = userData?.userInfo?.accountGuid;
  const [fileUplaoded, setFileUploaded] = useState(null);
  const [uploadModalStatus, setUploadModalStatus] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [uploadProductPreview, setUploadProductPreview] = useState(false);
  const uploadProductFile = useSelector((state) => state.uploadProductFile);

  const [_formData, _setFormData] = useState({
    title: "",
    productTag: [],
    productId: null,
    tools: [],
    description: "",
    categories: [],
    currency: "",
    price: "",
    material: [],
    size: [],
    color: "",
    audience: "",
    deliveryScope: "",
    files: [],
    ProductReferenceId: "",
    license: "",
  });
  const [formData, setFormData] = useState({
    enterUrl: "",
    linkTitle: "",
  });
  const [errors, setErrors] = useState({
    enterUrl: "",
    linkTitle: "",
  });

  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };

  const handleClose = () => {
    productData.clearAll();
    handleCloseCallback(false);
    setUploadFile(null);
    dispatch({
      type: uploadProductFileReducerTypes.CLEAR_UPLOAD_PRODUCT_THUMBNAIL_FILE,
    });
    localStorage.removeItem("uploadType");
    localStorage.removeItem("fileUplaoded");
  };

  const handleCancel = () => {
    if (fileUplaoded) {
      setFileUploaded(null);
    } else {
      handleClose();
    }
  };

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {uploadModalStatus && (
        <Upload3dModal
          open={uploadModalStatus}
          setOpen={setUploadModalStatus}
          setUploadProductPreview={setUploadProductPreview}
          details={_formData}
          handleDraft={handleClose}
        />
      )}
      {uploadProductPreview && (
        <NewUploadPreviewModal
          setAdditionalInfo={setAdditionalInfo}
          handleClose={setUploadProductPreview}
          open={uploadProductPreview}
          additionalInfo={additionalInfo}
          uploadFile={uploadFile}
          formData={_formData}
          setUploadFile={setUploadFile}
          handleDraft={handleClose}
        />
      )}

      <Modal open={true}>
        <Box
          className={classes.modalStyle}
          style={{
            backgroundColor: theme?.palette?.mode === "light" && "#fff",
            height: "95vh",
            maxWidth: "95vw",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box className={classes.closeBox}>
              {theme?.palette?.mode === "light" ? (
                <LightModeCross
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <NightModeCross
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>
            <Box className={classes.titleBox}>
              <Typography
                style={
                  theme?.palette?.mode === "light"
                    ? heading.lightMode
                    : heading.nightMode
                }
              >
                Upload Work
              </Typography>
            </Box>
            <Box
              className={classes.editFielBox}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              {uploadProductFile && (
                <UploadForm
                  uploadFile={uploadFile}
                  setUploadFile={setUploadFile}
                  handleClose={handleClose}
                  setUploadModalStatus={setUploadModalStatus}
                  setFormData={_setFormData}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

//  <UploadForm
//                   uploadFile={uploadFile}
//                   setUploadFile={setUploadFile}
//                   handleClose={handleClose}
//                 />
