const initState = {
  loading: false,
  data: null,
  error: null,
};

export const invoiceListReducerTypes = {
  INVOICE_LIST: "INVOICE_LIST",
  REQUEST_INVOICE_LIST: "REQUEST_INVOICE_LIST",
  RESPONSE_INVOICE_LIST: "RESPONSE_INVOICE_LIST",
  ERROR_INVOICE_LIST: "ERROR_INVOICE_LIST",
};

export function invoiceListReducer(state = initState, action) {
  switch (action.type) {
    case invoiceListReducerTypes.REQUEST_INVOICE_LIST:
      return { ...state, loading: true };
    case invoiceListReducerTypes.RESPONSE_INVOICE_LIST:
      return { error: null, loading: false, data: action.payload };
    case invoiceListReducerTypes.ERROR_INVOICE_LIST:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export const subscriptionInvoiceReducerTypes = {
  SUBSCRIPTION_INVOICE: "SUBSCRIPTION_INVOICE",
  REQUEST_SUBSCRIPTION_INVOICE: "REQUEST_SUBSCRIPTION_INVOICE",
  RESPONSE_SUBSCRIPTION_INVOICE: "RESPONSE_SUBSCRIPTION_INVOICE",
  ERROR_SUBSCRIPTION_INVOICE: "ERROR_SUBSCRIPTION_INVOICE",
};

export function subscriptionInvoiceReducer(state = initState, action) {
  switch (action.type) {
    case subscriptionInvoiceReducerTypes.REQUEST_SUBSCRIPTION_INVOICE:
      return { ...state, loading: true };
    case subscriptionInvoiceReducerTypes.RESPONSE_SUBSCRIPTION_INVOICE:
      return { error: null, loading: false, data: action.payload };
    case subscriptionInvoiceReducerTypes.ERROR_SUBSCRIPTION_INVOICE:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}
