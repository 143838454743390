/*
 **	User name
 **	Created	5/24/2023
 **	index.tsx
 */

import React from "react";
import { Box, CircularProgress } from "@mui/material";

export default function UniversalLoader() {
  return (
    <Box
      style={{
        minHeight: "50vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
