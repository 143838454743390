import { handlePaginationData } from "./helper";

const initState = {
  loading: false,
  jobApplyMediumData: null,
  jobApplyMediumError: null,
  jobWorkPlaceData: null,
  jobWorkPlaceError: null,
  jobExperienceData: null,
  jobExperienceError: null,
  postJobData: null,
  postJobError: null,
  allJobsLoading: false,
  allJobsData: null,
  allJobsError: null,
  allJobsPostedByUserLoading: false,
  allJobsPostedByUserData: null,
  allJobsPostedByUserError: null,
  applyjobPostData: null,
  applyJobPostError: null,
  saveJobPostData: null,
  saveJobPostError: null,
  appliedJobsByUserLoading: false,
  appliedJobsByUserData: null,
  appliedJobsByUserError: null,
  savedJobsByUserLoading: false,
  savedJobsByUserData: null,
  savedJobsByUserError: null,
  jobRateTypeData: null,
  jobRateTypeError: null,
  jobEditedData: null,
  jobEditedError: null,
  draftedJobsLoading: false,
  draftedJobsData: null,
  draftedJobsError: null,
  jobDeleteData: null,
  jobDeleteError: null,
  activeJobData: null,
  activeJobError: null,
  deactiveJobData: null,
  deactiveJobError: null,
  filteredJobData: null,
  filteredJobError: null,
  jobSalaryRangeData: null,
  jobSalaryRangeError: null,
  applyJobOnDCData: null,
  applyJobOnDCError: null,
  jobTypeData: null,
  jobTypeError: null,
  jobAutoCompleteData: null,
  jobAutoCompleteError: null,
  jobApplicantsLoading: false,
  jobApplicantsData: [],
  jobApplicantsError: null,
  deletePortfolioData: null,
  deletePortfolioError: null,
  deletePortfolioLoading: false,
  jobDetailsData: null,
  jobDetailsError: null,
  jobDetailsLoading: false,
};

export const JobsReducerTypes = {
  JOB_APPLY_LIST: "JOB_APPLY_LIST",
  REQUEST_JOB_APPLY_LIST: "REQUEST_JOB_APPLY_LIST",
  RESPONSE_JOB_APPLY_LIST: "RESPONSE_JOB_APPLY_LIST",
  ERROR_JOB_APPLY_LIST: "ERROR_JOB_APPLY_LIST",

  JOB_WORK_PLACE_LIST: "JOB_WORK_PLACE_LIST",
  REQUEST_JOB_WORK_PLACE_LIST: "REQUEST_JOB_WORK_PLACE_LIST",
  RESPONSE_JOB_WORK_PLACE_LIST: "RESPONSE_JOB_WORK_PLACE_LIST",
  ERROR_JOB_WORK_PLACE_LIST: "ERROR_JOB_WORK_PLACE_LIST",

  JOB_EXPERIENCE_LIST: "JOB_EXPERIENCE_LIST",
  REQUEST_JOB_EXPERIENCE_LIST: "REQUEST_JOB_EXPERIENCE_LIST",
  RESPONSE_JOB_EXPERIENCE_LIST: "RESPONSE_JOB_EXPERIENCE_LIST",
  ERROR_JOB_EXPERIENCE_LIST: "ERROR_JOB_EXPERIENCE_LIST",

  JOB_POST_LIST: "JOB_POST_LIST",
  REQUEST_JOB_POST_LIST: "REQUEST_JOB_POST_LIST",
  RESPONSE_JOB_POST_LIST: "RESPONSE_JOB_POST_LIST",
  ERROR_JOB_POST_LIST: "ERROR_JOB_POST_LIST",
  CLEAR_JOB_POST_LIST: "CLEAR_JOB_POST_LIST",

  ALL_JOBS_LIST: "ALL_JOBS_LIST",
  REQUEST_ALL_JOBS_LIST: "REQUEST_ALL_JOBS_LIST",
  RESPONSE_ALL_JOBS_LIST: "RESPONSE_ALL_JOBS_LIST",
  ERROR_ALL_JOBS_LIST: "ERROR_ALL_JOBS_LIST",
  CLEAR_ALL_JOBS_LIST: "CLEAR_ALL_JOBS_LIST",

  ALL_JOBS_LIST_POSTED_BY_USER: "ALL_JOBS_LIST_POSTED_BY_USER",
  REQUEST_ALL_JOBS_LIST_POSTED_BY_USER: "REQUEST_ALL_JOBS_LIST_POSTED_BY_USER",
  RESPONSE_ALL_JOBS_LIST_POSTED_BY_USER:
    "RESPONSE_ALL_JOBS_LIST_POSTED_BY_USER",
  ERROR_ALL_JOBS_LIST_POSTED_BY_USER: "ERROR_ALL_JOBS_LIST_POSTED_BY_USER",

  APPLY_JOBS: "APPLY_JOBS",
  REQUEST_APPLY_JOBS: "REQUEST_APPLY_JOBS",
  RESPONSE_APPLY_JOBS: "RESPONSE_APPLY_JOBS",
  ERROR_APPLY_JOBS: "ERROR_APPLY_JOBS",
  CLEAR_APPLY_JOBS: "CLEAR_APPLY_JOBS",

  SAVE_JOBS: "SAVE_JOBS",
  REQUEST_SAVE_JOBS: "REQUEST_SAVE_JOBS",
  RESPONSE_SAVE_JOBS: "RESPONSE_SAVE_JOBS",
  ERROR_SAVE_JOBS: "ERROR_SAVE_JOBS",
  CLEAR_SAVE_JOBS: "CLEAR_SAVE_JOBS",

  APPLIED_JOBS_LIST: "APPLIED_JOBS_LIST",
  REQUEST_APPLIED_JOBS_LIST: "REQUEST_APPLIED_JOBS_LIST",
  RESPONSE_APPLIED_JOBS_LIST: "RESPONSE_APPLIED_JOBS_LIST",
  ERROR_APPLIED_JOBS_LIST: "ERROR_APPLIED_JOBS_LIST",

  SAVED_JOBS_LIST: "SAVED_JOBS_LIST",
  REQUEST_SAVED_JOBS_LIST: "REQUEST_SAVED_JOBS_LIST",
  RESPONSE_SAVED_JOBS_LIST: "RESPONSE_SAVED_JOBS_LIST",
  ERROR_SAVED_JOBS_LIST: "ERROR_SAVED_JOBS_LIST",

  JOB_RATE_TYPE_LIST: "JOB_RATE_TYPE_LIST",
  REQUEST_JOB_RATE_TYPE_LIST: "REQUEST_JOB_RATE_TYPE_LIST",
  RESPONSE_JOB_RATE_TYPE_LIST: "RESPONSE_JOB_RATE_TYPE_LIST",
  ERROR_JOB_RATE_TYPE_LIST: "ERROR_JOB_RATE_TYPE_LIST",

  JOB_EDITED: "JOB_EDITED",
  REQUEST_JOB_EDITED: "REQUEST_JOB_EDITED",
  RESPONSE_JOB_EDITED: "RESPONSE_JOB_EDITED",
  ERROR_JOB_EDITED: "ERROR_JOB_EDITED",
  CLEAR_JOB_EDITED: "CLEAR_JOB_EDITED",

  JOB_DRAFTED_LIST: "JOB_DRAFTED_LIST",
  REQUEST_JOB_DRAFTED_LIST: "REQUEST_JOB_DRAFTED_LIST",
  RESPONSE_JOB_DRAFTED_LIST: "RESPONSE_JOB_DRAFTED_LIST",
  ERROR_JOB_DRAFTED_LIST: "ERROR_JOB_DRAFTED_LIST",

  JOB_DELETE: "JOB_DELETE",
  REQUEST_JOB_DELETE: "REQUEST_JOB_DELETE",
  RESPONSE_JOB_DELETE: "RESPONSE_JOB_DELETE",
  ERROR_JOB_DELETE: "ERROR_JOB_JOB_DELETE",
  CLEAR_JOB_DELETE: "CLEAR_JOB_DELETE",

  JOB_DEACTIVE: "JOB_DEACTIVE",
  REQUEST_JOB_DEACTIVE: "REQUEST_JOB_DEACTIVE",
  RESPONSE_JOB_DEACTIVE: "RESPONSE_JOB_DEACTIVE",
  ERROR_JOB_DEACTIVE: "ERROR_JOB_DEACTIVE",
  CLEAR_JOB_DEACTIVE: "CLEAR_JOB_DEACTIVE",

  JOB_ACTIVE: "JOB_ACTIVE",
  REQUEST_JOB_ACTIVE: "REQUEST_JOB_ACTIVE",
  RESPONSE_JOB_ACTIVE: "RESPONSE_JOB_ACTIVE",
  ERROR_JOB_ACTIVE: "ERROR_JOB_ACTIVE",
  CLEAR_JOB_ACTIVE: "CLEAR_JOB_ACTIVE",

  JOB_FILTERED_LIST: "JOB_FILTERED_LIST",
  REQUEST_JOB_FILTERED_LIST: "REQUEST_JOB_FILTERED_LIST",
  RESPONSE_JOB_FILTERED_LIST: "RESPONSE_JOB_FILTERED_LIST",
  ERROR_JOB_FILTERED_LIST: "ERROR_JOB_FILTERED_LIST",
  CLEAR_JOB_FILTERED_LIST: "CLEAR_JOB_FILTERED_LIST",

  JOB_SALARY_RANGE_LIST: "JOB_SALARY_RANGE_LIST",
  REQUEST_JOB_SALARY_RANGE_LIST: "REQUEST_JOB_SALARY_RANGE_LIST",
  RESPONSE_JOB_SALARY_RANGE_LIST: "RESPONSE_JOB_SALARY_RANGE_LIST",
  ERROR_JOB_SALARY_RANGE_LIST: "ERROR_JOB_SALARY_RANGE_LIST",

  APPLY_JOB_ON_DC: "APPLY_JOB_ON_DC",
  REQUEST_APPLY_JOB_ON_DC: "REQUEST_APPLY_JOB_ON_DC",
  RESPONSE_APPLY_JOB_ON_DC: "RESPONSE_APPLY_JOB_ON_DC",
  ERROR_APPLY_JOB_ON_DC: "ERROR_APPLY_JOB_ON_DC",
  CLEAR_APPLY_JOB_ON_DC: "CLEAR_APPLY_JOB_ON_DC",

  JOB_TYPE_LIST: "JOB_TYPE_LIST",
  REQUEST_JOB_TYPE_LIST: "REQUEST_JOB_TYPE_LIST",
  RESPONSE_JOB_TYPE_LIST: "RESPONSE_JOB_TYPE_LIST",
  ERROR_JOB_TYPE_LIST: "ERROR_JOB_TYPE_LIST",

  JOBS_AUTOCOMPLETE: "JOBS_AUTOCOMPLETE",
  REQUEST_JOBS_AUTOCOMPLETE: "REQUEST_JOBS_AUTOCOMPLETE",
  RESPONSE_JOBS_AUTOCOMPLETE: "RESPONSE_JOBS_AUTOCOMPLETE",
  ERROR_JOBS_AUTOCOMPLETE: "ERROR_JOBS_AUTOCOMPLETE",
  CLEAR_JOBS_AUTOCOMPLETE: "CLEAR_JOBS_AUTOCOMPLETE",

  JOB_APPLICANTS: "JOB_APPLICANTS",
  REQUEST_JOB_APPLICANTS: "REQUEST_JOB_APPLICANTS",
  RESPONSE_JOB_APPLICANTS: "RESPONSE_JOB_APPLICANTS",
  ERROR_JOB_APPLICANTS: "ERROR_JOB_APPLICANTS",
  CLEAR_JOB_APPLICANTS: "CLEAR_JOB_APPLICANTS",

  PORTFOLIO_DELETE_ITEM: "PORTFOLIO_DELETE_ITEM",
  REQUEST_PORTFOLIO_DELETE_ITEM: "REQUEST_PORTFOLIO_DELETE_ITEM",
  RESPONSE_PORTFOLIO_DELETE_ITEM: "RESPONSE_PORTFOLIO_DELETE_ITEM",
  ERROR_PORTFOLIO_DELETE_ITEM: "ERROR_PORTFOLIO_DELETE_ITEM",
  CLEAR_PORTFOLIO_DELETE_ITEM: "CLEAR_PORTFOLIO_DELETE_ITEM",

  GET_JOB_BY_ID: "GET_JOB_BY_ID",
  REQUEST_GET_JOB_BY_ID: "REQUEST_GET_JOB_BY_ID",
  RESPONSE_GET_JOB_BY_ID: "RESPONSE_GET_JOB_BY_ID",
  ERROR_GET_JOB_BY_ID: "ERROR_GET_JOB_BY_ID",
  CLEAR_GET_JOB_BY_ID: "CLEAR_GET_JOB_BY_ID",
};

export function jobsReducer(state = initState, action) {
  switch (action.type) {
    case JobsReducerTypes.REQUEST_JOB_APPLY_LIST:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_APPLY_LIST:
      return {
        ...state,
        jobApplyMediumError: null,
        loading: false,
        jobApplyMediumData: action.payload,
      };
    case JobsReducerTypes.ERROR_JOB_APPLY_LIST:
      return {
        ...state,
        loading: false,
        jobApplyMediumData: null,
        jobApplyMediumError: action.payload,
      };

    case JobsReducerTypes.REQUEST_JOB_WORK_PLACE_LIST:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_WORK_PLACE_LIST:
      return {
        ...state,
        jobWorkPlaceError: null,
        loading: false,
        jobWorkPlaceData: action.payload,
      };
    case JobsReducerTypes.ERROR_JOB_WORK_PLACE_LIST:
      return {
        ...state,
        loading: false,
        jobWorkPlaceData: null,
        jobWorkPlaceError: action.payload,
      };

    case JobsReducerTypes.REQUEST_JOB_EXPERIENCE_LIST:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_EXPERIENCE_LIST:
      return {
        ...state,
        jobExperienceError: null,
        loading: false,
        jobExperienceData: action.payload,
      };
    case JobsReducerTypes.ERROR_JOB_EXPERIENCE_LIST:
      return {
        ...state,
        loading: false,
        jobExperienceData: null,
        jobExperienceError: action.payload,
      };

    case JobsReducerTypes.REQUEST_JOB_POST_LIST:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_POST_LIST:
      return {
        ...state,
        postJobError: null,
        loading: false,
        postJobData: action.payload,
      };
    case JobsReducerTypes.ERROR_JOB_POST_LIST:
      return {
        ...state,
        loading: false,
        postJobData: null,
        postJobError: action.payload,
      };
    case JobsReducerTypes.CLEAR_JOB_POST_LIST:
      return {
        ...state,
        loading: false,
        postJobData: null,
        postJobError: null,
      };

    case JobsReducerTypes.REQUEST_ALL_JOBS_LIST:
      return { ...state, allJobsLoading: true };
    case JobsReducerTypes.RESPONSE_ALL_JOBS_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.allJobsData,
        uniqueIdentifier: "postedJobDetailsId",
      });

      return {
        ...state,
        allJobsLoading: false,
        allJobsData: newData,
        allJobsError: null,
      };
    }
    case JobsReducerTypes.ERROR_ALL_JOBS_LIST:
      return {
        ...state,
        allJobsLoading: false,
        allJobsError: action.payload,
      };

    case JobsReducerTypes.CLEAR_ALL_JOBS_LIST:
      return {
        ...state,
        allJobsLoading: false,
        allJobsData: null,
        allJobsError: null,
      };

    case JobsReducerTypes.REQUEST_ALL_JOBS_LIST_POSTED_BY_USER: {
      if (action.payload.pageNumber === 1) {
        return {
          ...state,
          allJobsPostedByUserData: null,
          allJobsPostedByUserLoading: true,
        };
      }
      return { ...state, allJobsPostedByUserLoading: true };
    }
    case JobsReducerTypes.RESPONSE_ALL_JOBS_LIST_POSTED_BY_USER: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.allJobsPostedByUserData,
        uniqueIdentifier: "postedJobDetailsId",
      });

      return {
        ...state,
        allJobsPostedByUserLoading: false,
        allJobsPostedByUserData: newData,
        allJobsPostedByUserError: null,
      };
    }
    case JobsReducerTypes.ERROR_ALL_JOBS_LIST_POSTED_BY_USER:
      return {
        ...state,
        allJobsPostedByUserLoading: false,
        allJobsPostedByUserData: null,
        allJobsPostedByUserError: action.payload,
      };

    case JobsReducerTypes.REQUEST_APPLY_JOBS:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_APPLY_JOBS:
      return {
        ...state,
        loading: false,
        applyjobPostData: action.payload,
        applyJobPostError: null,
      };
    case JobsReducerTypes.ERROR_APPLY_JOBS:
      return {
        ...state,
        loading: false,
        applyjobPostData: null,
        applyJobPostError: action.payload,
      };
    case JobsReducerTypes.CLEAR_APPLY_JOBS:
      return {
        ...state,
        loading: false,
        applyjobPostData: null,
        applyJobPostError: null,
      };

    case JobsReducerTypes.REQUEST_SAVE_JOBS:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_SAVE_JOBS:
      return {
        ...state,
        loading: false,
        saveJobPostData: action.payload,
        saveJobPostError: null,
      };
    case JobsReducerTypes.ERROR_SAVE_JOBS:
      return {
        ...state,
        loading: false,
        saveJobPostData: null,
        saveJobPostError: action.payload,
      };
    case JobsReducerTypes.CLEAR_SAVE_JOBS:
      return {
        ...state,
        loading: false,
        saveJobPostData: null,
        saveJobPostError: null,
      };

    case JobsReducerTypes.REQUEST_APPLIED_JOBS_LIST:
      return { ...state, appliedJobsByUserLoading: true };

    case JobsReducerTypes.RESPONSE_APPLIED_JOBS_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.appliedJobsByUserData,
        uniqueIdentifier: "postedJobDetailsId",
      });

      return {
        ...state,
        appliedJobsByUserLoading: false,
        appliedJobsByUserData: newData,
        appliedJobsByUserError: null,
      };
    }

    case JobsReducerTypes.ERROR_APPLIED_JOBS_LIST:
      return {
        ...state,
        appliedJobsByUserLoading: false,
        appliedJobsByUserData: null,
        appliedJobsByUserError: action.payload,
      };

    case JobsReducerTypes.REQUEST_SAVED_JOBS_LIST:
      return { ...state, savedJobsByUserLoading: true };

    case JobsReducerTypes.RESPONSE_SAVED_JOBS_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.savedJobsByUserData,
        uniqueIdentifier: "postedJobDetailsId",
      });

      return {
        ...state,
        savedJobsByUserLoading: false,
        savedJobsByUserData: newData,
        savedJobsByUserError: null,
      };
    }

    case JobsReducerTypes.ERROR_SAVED_JOBS_LIST:
      return {
        ...state,
        savedJobsByUserLoading: false,
        savedJobsByUserData: null,
        savedJobsByUserError: action.payload,
      };

    case JobsReducerTypes.REQUEST_JOB_RATE_TYPE_LIST:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_RATE_TYPE_LIST:
      return {
        ...state,
        loading: false,
        jobRateTypeData: action.payload,
        jobRateTypeError: null,
      };
    case JobsReducerTypes.ERROR_JOB_RATE_TYPE_LIST:
      return {
        ...state,
        loading: false,
        jobRateTypeData: null,
        jobRateTypeError: action.payload,
      };

    case JobsReducerTypes.REQUEST_JOB_EDITED:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_EDITED:
      return {
        ...state,
        loading: false,
        jobEditedData: action.payload,
        jobEditedError: null,
      };
    case JobsReducerTypes.ERROR_JOB_EDITED:
      return {
        ...state,
        loading: false,
        jobEditedData: null,
        jobEditedError: action.payload,
      };
    case JobsReducerTypes.CLEAR_JOB_EDITED:
      return {
        ...state,
        loading: false,
        jobEditedData: null,
        jobEditedError: null,
      };

    case JobsReducerTypes.REQUEST_JOB_DRAFTED_LIST:
      return { ...state, draftedJobsLoading: true };
    case JobsReducerTypes.RESPONSE_JOB_DRAFTED_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.draftedJobsData,
        uniqueIdentifier: "postedJobDetailsId",
      });

      return {
        ...state,
        draftedJobsLoading: false,
        draftedJobsData: newData,
        draftedJobsError: null,
      };
    }
    case JobsReducerTypes.ERROR_JOB_DRAFTED_LIST:
      return {
        ...state,
        draftedJobsLoading: false,
        draftedJobsData: null,
        draftedJobsError: action.payload,
      };

    case JobsReducerTypes.REQUEST_JOB_DELETE:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_DELETE:
      return {
        ...state,
        loading: false,
        jobDeleteData: action.payload,
        jobDeleteError: null,
      };
    case JobsReducerTypes.ERROR_JOB_DELETE:
      return {
        ...state,
        loading: false,
        jobDeleteData: null,
        jobDeleteError: action.payload,
      };
    case JobsReducerTypes.CLEAR_JOB_DELETE:
      return {
        ...state,
        loading: false,
        jobDeleteData: null,
        jobDeleteError: null,
      };

    case JobsReducerTypes.REQUEST_JOB_DEACTIVE:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_DEACTIVE:
      return {
        ...state,
        loading: false,
        deactiveJobData: action.payload,
        deactiveJobError: null,
      };
    case JobsReducerTypes.ERROR_JOB_DEACTIVE:
      return {
        ...state,
        loading: false,
        deactiveJobData: null,
        deactiveJobError: action.payload,
      };
    case JobsReducerTypes.CLEAR_JOB_DEACTIVE:
      return {
        ...state,
        loading: false,
        deactiveJobData: null,
        deactiveJobError: null,
      };

    case JobsReducerTypes.REQUEST_JOB_ACTIVE:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_ACTIVE:
      return {
        ...state,
        loading: false,
        activeJobData: action.payload,
        activeJobError: null,
      };
    case JobsReducerTypes.ERROR_JOB_ACTIVE:
      return {
        ...state,
        loading: false,
        activeJobData: null,
        activeJobError: action.payload,
      };
    case JobsReducerTypes.CLEAR_JOB_ACTIVE:
      return {
        ...state,
        loading: false,
        activeJobData: null,
        activeJobError: null,
      };

    case JobsReducerTypes.REQUEST_JOB_FILTERED_LIST:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_FILTERED_LIST:
      return {
        ...state,
        loading: false,
        filteredJobData: action.payload,
        filteredJobError: null,
      };
    case JobsReducerTypes.ERROR_JOB_FILTERED_LIST:
      return {
        ...state,
        loading: false,
        filteredJobData: null,
        filteredJobError: action.payload,
      };
    case JobsReducerTypes.CLEAR_JOB_FILTERED_LIST:
      return {
        ...state,
        loading: false,
        filteredJobData: null,
        filteredJobError: null,
      };

    case JobsReducerTypes.REQUEST_JOB_SALARY_RANGE_LIST:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_SALARY_RANGE_LIST:
      return {
        ...state,
        loading: false,
        jobSalaryRangeData: action.payload,
        jobSalaryRangeError: null,
      };
    case JobsReducerTypes.ERROR_JOB_SALARY_RANGE_LIST:
      return {
        ...state,
        loading: false,
        jobSalaryRangeData: null,
        jobWorkPlaceError: action.payload,
      };

    case JobsReducerTypes.REQUEST_APPLY_JOB_ON_DC:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_APPLY_JOB_ON_DC:
      return {
        ...state,
        loading: false,
        applyJobOnDCData: action.payload,
        applyJobOnDCError: null,
      };
    case JobsReducerTypes.ERROR_APPLY_JOB_ON_DC:
      return {
        ...state,
        loading: false,
        applyJobOnDCData: null,
        applyJobOnDCError: action.payload,
      };
    case JobsReducerTypes.CLEAR_APPLY_JOB_ON_DC:
      return {
        ...state,
        loading: false,
        applyJobOnDCData: null,
        applyJobOnDCError: null,
      };

    case JobsReducerTypes.REQUEST_JOB_TYPE_LIST:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOB_TYPE_LIST:
      return {
        ...state,
        jobTypeError: null,
        loading: false,
        jobTypeData: action.payload,
      };
    case JobsReducerTypes.ERROR_JOB_TYPE_LIST:
      return {
        ...state,
        loading: false,
        jobTypeData: null,
        jobTypeError: action.payload,
      };

    case JobsReducerTypes.REQUEST_JOBS_AUTOCOMPLETE:
      return { ...state, loading: true };
    case JobsReducerTypes.RESPONSE_JOBS_AUTOCOMPLETE:
      return {
        ...state,
        loading: false,
        jobAutoCompleteData: action.payload,
        jobAutoCompleteError: null,
      };
    case JobsReducerTypes.ERROR_JOBS_AUTOCOMPLETE:
      return {
        ...state,
        loading: false,
        jobAutoCompleteData: null,
        jobAutoCompleteError: action.payload,
      };
    case JobsReducerTypes.CLEAR_JOBS_AUTOCOMPLETE:
      return {
        ...state,
        loading: false,
        jobAutoCompleteData: null,
        jobAutoCompleteError: null,
      };

    case JobsReducerTypes.REQUEST_JOB_APPLICANTS:
      return { ...state, jobApplicantsLoading: true, jobApplicantsData: [] };
    case JobsReducerTypes.RESPONSE_JOB_APPLICANTS:
      return {
        ...state,
        jobApplicantsLoading: false,
        jobApplicantsData: action.payload,
        jobApplicantsError: null,
      };
    case JobsReducerTypes.ERROR_JOB_APPLICANTS:
      return {
        ...state,
        jobApplicantsLoading: false,
        jobApplicantsData: null,
        jobApplicantsError: action.payload,
      };
    case JobsReducerTypes.CLEAR_JOB_APPLICANTS:
      return {
        ...state,
        jobApplicantsLoading: false,
        jobApplicantsData: null,
        jobApplicantsError: null,
      };

    case JobsReducerTypes.REQUEST_PORTFOLIO_DELETE_ITEM:
      return { ...state, deletePortfolioLoading: true };
    case JobsReducerTypes.RESPONSE_PORTFOLIO_DELETE_ITEM:
      return {
        ...state,
        deletePortfolioLoading: false,
        deletePortfolioData: action.payload,
        deletePortfolioError: null,
      };
    case JobsReducerTypes.ERROR_PORTFOLIO_DELETE_ITEM:
      return {
        ...state,
        deletePortfolioLoading: false,
        deletePortfolioData: null,
        deletePortfolioError: action.payload,
      };
    case JobsReducerTypes.CLEAR_PORTFOLIO_DELETE_ITEM:
      return {
        ...state,
        deletePortfolioLoading: false,
        deletePortfolioData: null,
        deletePortfolioError: null,
      };

    case JobsReducerTypes.REQUEST_GET_JOB_BY_ID:
      return { ...state, jobDetailsLoading: true };
    case JobsReducerTypes.RESPONSE_GET_JOB_BY_ID:
      return {
        ...state,
        jobDetailsLoading: false,
        jobDetailsData: action.payload,
        jobDetailsError: null,
      };
    case JobsReducerTypes.ERROR_GET_JOB_BY_ID:
      return {
        ...state,
        jobDetailsLoading: false,
        jobDetailsData: null,
        jobDetailsError: action.payload,
      };
    case JobsReducerTypes.CLEAR_GET_JOB_BY_ID:
      return {
        ...state,
        jobDetailsLoading: false,
        jobDetailsData: null,
        jobDetailsError: null,
      };

    default:
      return state;
  }
}
