import { createAction, createReducer } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";

export const SavePopUpSettingsActionsTypes = {
  savePopUpSettings: "settings/ft-popups",
  savePopUpSettingsRequest: "settings/ft-popups/request",
  savePopUpSettingsSuccess: "settings/ft-popups/success",
  savePopUpSettingsFailure: "settings/ft-popups/failure",

  getAllLanguages: "languages/fetch",
  getAllLanguagesRequest: "languages/fetch/request",
  getAllLanguagesSuccess: "languages/fetch/success",
  getAllLanguagesFailure: "languages/fetch/failure",

  saveLanguages: "languages/save",
  saveLanguagesRequest: "languages/save/request",
  saveLanguagesSuccess: "languages/save/success",
  saveLanguagesFailure: "languages/save/failure",
};

const savePopUpSettings = createAction<{
  accountGuid: string;
  isTwoDayPlanLeftPopupCancelled?: boolean;
  isOneDayPlanLeftPopupCancelled?: boolean;
  isExpiredPlanPopupCancelled?: boolean;
  isWelcomePopup?: boolean;
}>(SavePopUpSettingsActionsTypes.savePopUpSettings);
const savePopUpSettingsRequest = createAction(
  SavePopUpSettingsActionsTypes.savePopUpSettingsRequest
);
const savePopUpSettingsSuccess = createAction<Array<any>>(
  SavePopUpSettingsActionsTypes.savePopUpSettingsSuccess
);
const savePopUpSettingsFailure = createAction<any>(
  SavePopUpSettingsActionsTypes.savePopUpSettingsFailure
);

const getAllLanguages = createAction(
  SavePopUpSettingsActionsTypes.getAllLanguages
);
const getAllLanguagesRequest = createAction(
  SavePopUpSettingsActionsTypes.getAllLanguagesRequest
);
const getAllLanguagesSuccess = createAction<Array<any>>(
  SavePopUpSettingsActionsTypes.getAllLanguagesSuccess
);
const getAllLanguagesFailure = createAction<any>(
  SavePopUpSettingsActionsTypes.getAllLanguagesFailure
);

const saveLanguages = createAction<{
  accountGuid: string;
  languageList: Array<string>;
}>(SavePopUpSettingsActionsTypes.saveLanguages);
const saveLanguagesRequest = createAction(
  SavePopUpSettingsActionsTypes.saveLanguagesRequest
);
const saveLanguagesSuccess = createAction<Array<any>>(
  SavePopUpSettingsActionsTypes.saveLanguagesSuccess
);
const saveLanguagesFailure = createAction<any>(
  SavePopUpSettingsActionsTypes.saveLanguagesFailure
);

const initialState = {
  data: null,
  loading: true,
  status: null,
  error: null,
  languages: [],
  languagesLoading: false,
};

const savePopUpSettingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(savePopUpSettingsRequest, (state, action: Action) => ({
    ...state,
    loading: true,
    error: null,
  }));

  builder.addCase(savePopUpSettingsSuccess, (state, action: Action) => {
    if (savePopUpSettingsSuccess.match(action)) {
      return {
        ...state,
        data: action.payload as any,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(savePopUpSettingsFailure, (state, action: Action) => {
    if (savePopUpSettingsFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(getAllLanguagesRequest, (state, action: Action) => ({
    ...state,
    languagesLoading: true,
    error: null,
  }));

  builder.addCase(getAllLanguagesSuccess, (state, action: Action) => {
    if (getAllLanguagesSuccess.match(action)) {
      return {
        ...state,
        languages: action.payload as any,
        languagesLoading: false,
      };
    }

    return state;
  });

  builder.addCase(getAllLanguagesFailure, (state, action: Action) => {
    if (getAllLanguagesFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        languagesLoading: false,
      };
    }

    return state;
  });

  builder.addCase(saveLanguagesRequest, (state, action: Action) => ({
    ...state,
    languagesLoading: true,
    error: null,
  }));

  builder.addCase(saveLanguagesSuccess, (state, action: Action) => {
    if (saveLanguagesSuccess.match(action)) {
      return {
        ...state,
        languagesLoading: false,
      };
    }

    return state;
  });

  builder.addCase(saveLanguagesFailure, (state, action: Action) => {
    if (saveLanguagesFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        languagesLoading: false,
      };
    }

    return state;
  });
});

export const SavePopUpSettingsActions = {
  savePopUpSettings,
  savePopUpSettingsRequest,
  savePopUpSettingsSuccess,
  savePopUpSettingsFailure,

  getAllLanguages,
  getAllLanguagesRequest,
  getAllLanguagesSuccess,
  getAllLanguagesFailure,

  saveLanguages,
  saveLanguagesRequest,
  saveLanguagesSuccess,
  saveLanguagesFailure,
};

export default savePopUpSettingsReducer;
