import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { paymentsActionTypes } from "../reducers/payments";

const version = process.env.REACT_APP_API_VERSION;

function* fetchCCInfoRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_CC.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchCCInfo({ accountGuid }) {
  try {
    yield put({
      type: paymentsActionTypes.FETCH_CC_REQUEST,
    });
    const response = yield call(fetchCCInfoRequest, { accountGuid });

    yield put({
      type: paymentsActionTypes.FETCH_CC_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: paymentsActionTypes.FETCH_CC_FAILURE,
      payload: e,
    });
  }
}

function* fetchPaypalAccountInfoRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_PAYPAL_ACCOUNT.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchPaypalAccountInfo({ accountGuid }) {
  try {
    yield put({
      type: paymentsActionTypes.FETCH_PAYPAL_ACCOUNT_REQUEST,
    });
    const response = yield call(fetchPaypalAccountInfoRequest, { accountGuid });

    yield put({
      type: paymentsActionTypes.FETCH_PAYPAL_ACCOUNT_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: paymentsActionTypes.FETCH_PAYPAL_ACCOUNT_FAILURE,
      payload: e,
    });
  }
}

function* updateCCInfoRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.UPDATE_CC.replace("{version}", version),
    payload
  );

  return res;
}

function* updateCCInfo({ payload }) {
  try {
    yield put({
      type: paymentsActionTypes.UPDATE_CC_REQUEST,
    });
    const response = yield call(updateCCInfoRequest, payload);

    if (response?.data?.responseCode === 200) {
      yield put({
        type: paymentsActionTypes.UPDATE_CC_SUCCESS,
        payload: response?.data,
      });
      yield put({
        type: paymentsActionTypes.FETCH_CC,
        accountGuid: payload?.accountGuid,
      });
    } else {
      yield put({
        type: paymentsActionTypes.UPDATE_CC_FAILURE,
        payload: response?.data?.responseMessage,
      });
    }
  } catch (e) {
    yield put({
      type: paymentsActionTypes.UPDATE_CC_FAILURE,
      payload: e,
    });
  }
}

function* updatePaypalAccountInfoRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.UPDATE_PAYPAL_ACCOUNT.replace("{version}", version),
    payload
  );

  return res;
}

function* updatePaypalAccountInfo({ payload }) {
  try {
    yield put({
      type: paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_REQUEST,
    });
    const response = yield call(updatePaypalAccountInfoRequest, payload);

    if (response?.data?.responseCode === 200) {
      yield put({
        type: paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_SUCCESS,
        payload: response?.data,
      });
      yield put({
        type: paymentsActionTypes.FETCH_PAYPAL_ACCOUNT,
        accountGuid: payload?.accountGuid,
      });
    } else {
      yield put({
        type: paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_FAILURE,
        payload: response?.data?.responseMessage,
      });
    }
  } catch (e) {
    yield put({
      type: paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_FAILURE,
      payload: e,
    });
  }
}

function* paymentSaga() {
  yield all([takeLatest(paymentsActionTypes.FETCH_CC, fetchCCInfo)]);
  yield all([
    takeLatest(
      paymentsActionTypes.FETCH_PAYPAL_ACCOUNT,
      fetchPaypalAccountInfo
    ),
  ]);
  yield all([takeLatest(paymentsActionTypes.UPDATE_CC, updateCCInfo)]);
  yield all([
    takeLatest(
      paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT,
      updatePaypalAccountInfo
    ),
  ]);
}
export default paymentSaga;
