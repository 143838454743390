const initState = {
  loading: false,
  data: null,
  error: null,
};

//upload cover image
export const uploadCoverImageTypes = {
  UPLOAD_COVER_IMAGE: "UPLOAD_COVER_IMAGE",
  REQUEST_UPLOAD_COVER_IMAGE: "REQUEST_UPLOAD_COVER_IMAGE",
  RESPONSE_UPLOAD_COVER_IMAGE: "RESPONSE_UPLOAD_COVER_IMAGE",
  ERROR_UPLOAD_COVER_IMAGE: "ERROR_UPLOAD_COVER_IMAGE",
  CLEAR_UPLOAD_COVER_IMAGE: "CLEAR_UPLOAD_COVER_IMAGE",
};

export function uploadCoverImageReducer(state = initState, action) {
  switch (action.type) {
    case uploadCoverImageTypes.REQUEST_UPLOAD_COVER_IMAGE:
      return { ...state, loading: true };
    case uploadCoverImageTypes.RESPONSE_UPLOAD_COVER_IMAGE:
      return { error: null, loading: false, data: action.payload };
    case uploadCoverImageTypes.ERROR_UPLOAD_COVER_IMAGE:
      return { loading: false, data: null, error: action.payload };
    case uploadCoverImageTypes.CLEAR_UPLOAD_COVER_IMAGE:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
