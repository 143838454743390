/*
 **	User name
 **	Created	6/1/2023
 **	AppPermissionsProvider.tsx
 */

import React, { useEffect } from "react";
import { useAppSelector } from "app/utils/hooks";
import { isCompanyUser, isEmployeeUser } from "app/utils/helper";

import { useDispatch } from "react-redux";
import {
  companyActionTypes,
  companyPermissionsActionTypes,
} from "app/redux/reducers/company";
import { companyIdSelector } from "app/redux/selectors/company-selectors";
import { accountGuidSelector } from "app/redux/selectors/user-selector";

import { AppPermissionsContext } from "./AppPermissionsContext";

export const AppPermissionsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const companyId = useAppSelector(companyIdSelector);
  const accountGuid = useAppSelector(accountGuidSelector);
  const userData = useAppSelector((state) => state.register);
  const companyPermissionsData = useAppSelector(
    (state) => state.companyPermissions
  );
  const isCompany = isCompanyUser(userData?.userAdditionalInfo?.profileType);
  const isEmployee = isEmployeeUser(userData?.userAdditionalInfo?.profileType);

  useEffect(() => {
    if (isEmployee || isCompany) {
      dispatch({
        type: companyActionTypes.GET_ALL_COMPANY_EMPLOYEES,
        payload: {
          companyId,
          accountGuid, //: "f3894869-708f-4510-96c5-c448f4e89e92",
        },
      });
      dispatch({
        type: companyActionTypes.GET_ALL_COMPANY_ROLES,
      });

      dispatch({
        type: companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS,
        payload: {
          companyId,
          AccountGuid: accountGuid,
        },
      });
    }
  }, [isCompany, isEmployee, accountGuid, companyId]);

  const companyEmployeeRoleId =
    userData?.userAdditionalInfo?.companyEmployeeRoleId;

  const invoicesPayments = companyPermissionsData?.data?.[0];
  const canDownloadInvoicesRoles = invoicesPayments?.subPermission?.[0]?.access
    ?.filter((role) => role.status === true)
    .map(({ roleId }) => roleId);

  const canChangePaymentSettingsRoles =
    invoicesPayments?.subPermission?.[1]?.access
      ?.filter((role) => role.status === true)
      .map(({ roleId }) => roleId);

  const canUpgradePaymentPlansRoles =
    invoicesPayments?.subPermission?.[2]?.access
      ?.filter((role) => role.status === true)
      .map(({ roleId }) => roleId);

  const companyPage = companyPermissionsData?.data?.[1];
  const canEditCompanyPageRoles = companyPage?.subPermission?.[0]?.access
    ?.filter((role) => role.status === true)
    .map(({ roleId }) => roleId);

  const productSettings = companyPermissionsData?.data?.[3];
  const uploadPublicRoles = productSettings?.subPermission?.[0]?.access
    ?.filter((role) => role.status === true)
    .map(({ roleId }) => roleId);

  const employeeSettings = companyPermissionsData?.data?.[0];
  const canInviteEmployeesRoles = employeeSettings?.subPermission?.[0]?.access
    ?.filter((role) => role.status === true)
    .map(({ roleId }) => roleId);

  const canEditEmployeesRoles = employeeSettings?.subPermission?.[1]?.access
    ?.filter((role) => role.status === true)
    .map(({ roleId }) => roleId);

  const permissions = {
    canViewPermissions:
      (isEmployee && [1]?.includes(companyEmployeeRoleId)) || isCompany,
    invoicesPayments: {
      canDownloadInvoices: isEmployee
        ? canDownloadInvoicesRoles?.includes(companyEmployeeRoleId)
        : true,
      canChangePaymentSettings: isEmployee
        ? canChangePaymentSettingsRoles?.includes(companyEmployeeRoleId)
        : true,
      canUpgradePaymentPlans: isEmployee
        ? canUpgradePaymentPlansRoles?.includes(companyEmployeeRoleId)
        : true,
    },
    companyPage: {
      canEditCompanyPage: isEmployee
        ? canEditCompanyPageRoles?.includes(companyEmployeeRoleId)
        : true,
    },
    employeeSettings: {
      canInviteEmployees: isEmployee
        ? canInviteEmployeesRoles?.includes(companyEmployeeRoleId)
        : true,
      canEditEmployees: isEmployee
        ? canEditEmployeesRoles?.includes(companyEmployeeRoleId)
        : true,
      canAddSeats: isEmployee ? [1, 2]?.includes(companyEmployeeRoleId) : true,
    },
    productSettings: {
      uploadPublic: isEmployee
        ? uploadPublicRoles?.includes(companyEmployeeRoleId)
        : true,
    },
  };

  return (
    <AppPermissionsContext.Provider value={{ permissions }}>
      {children}
    </AppPermissionsContext.Provider>
  );
};
