/*
 **	User name
 **	Created	6/1/2023
 **	LibraryProductUploadContext.tsx
 */

import React from "react";

export const defaultProductInfo = {
  title: "",
  tools: [],
  tags: [],
  description: "",
  // added for Edit functionality
  visibilityId: "",
  downloadType: "1",
  licenseId: "",
  price: "0",
  currencyId: "1",
  isPublished: false,
};

export type LibraryProductInfo = {
  title: string;
  tools: Array<any>;
  tags: Array<any>;
  description: string;
  // added for Edit functionality
  visibilityId: string;
  downloadType: string;
  licenseId: string;
  price: string;
  currencyId: string | number;
  isPublished: boolean;
};

interface LibraryProductUploadContext {
  productInfo: LibraryProductInfo;
  setProductInfo: (productInfo: LibraryProductInfo) => void;
  uploadedImages: Array<any>;
  setUploadedImages: (uploadedImages: Array<any>) => void;
  clearLibraryUpload: () => void;
  publishLibraryProduct: () => void;
  saveLibraryProductAsDraft: () => void;
  uploadedImagesInEdit: Array<any>;
  setProductData: (id: string) => void;
}

export const LibraryProductUploadContext =
  React.createContext<LibraryProductUploadContext>({
    productInfo: defaultProductInfo,
    setProductInfo: () => null,
    uploadedImages: [],
    uploadedImagesInEdit: [],
    setUploadedImages: () => null,
    clearLibraryUpload: () => null,
    publishLibraryProduct: () => null,
    saveLibraryProductAsDraft: () => null,
    setProductData: () => null,
  });
