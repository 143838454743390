const initState = {
  loading: false,
  data: null,
  error: null,
  updateLoading: false,
  updateData: null,
  updateError: null,
  updateStatus: null,
  complaintsByMe: null,
  complaintsByMeStatus: null,
  complaintsRaisedToMe: null,
  complaintsRaisedToMeStatus: null,
};

//@product reversal seller
export const complaintReducerTypes = {
  FETCH_ALL_PRODUCT_REVERSAL_SELLER: "FETCH_ALL_PRODUCT_REVERSAL_SELLER",
  REQUEST_FETCH_ALL_PRODUCT_REVERSAL_SELLER:
    "REQUEST_FETCH_ALL_PRODUCT_REVERSAL_SELLER",
  RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_SELLER:
    "RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_SELLER",
  ERROR_FETCH_ALL_PRODUCT_REVERSAL_SELLER:
    "ERROR_FETCH_ALL_PRODUCT_REVERSAL_SELLER",

  FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER: "FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER",
  REQUEST_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER:
    "REQUEST_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER",
  RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER:
    "RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER",
  ERROR_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER:
    "ERROR_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER",

  FETCH_PRODUCT_REVERSAL_SELLER: "FETCH_PRODUCT_REVERSAL_SELLER",
  REQUEST_FETCH_PRODUCT_REVERSAL_SELLER:
    "REQUEST_FETCH_PRODUCT_REVERSAL_SELLER",
  RESPONSE_FETCH_PRODUCT_REVERSAL_SELLER:
    "RESPONSE_FETCH_PRODUCT_REVERSAL_SELLER",
  ERROR_FETCH_PRODUCT_REVERSAL_SELLER: "ERROR_FETCH_PRODUCT_REVERSAL_SELLER",
  UPDATE_PRODUCT_REVERSAL_SELLER: "UPDATE_PRODUCT_REVERSAL_SELLER",
  REQUEST_UPDATE_PRODUCT_REVERSAL_SELLER:
    "REQUEST_UPDATE_PRODUCT_REVERSAL_SELLER",
  RESPONSE_UPDATE_PRODUCT_REVERSAL_SELLER:
    "RESPONSE_UPDATE_PRODUCT_REVERSAL_SELLER",
  ERROR_UPDATE_PRODUCT_REVERSAL_SELLER: "ERROR_UPDATE_PRODUCT_REVERSAL_SELLER",
  CLEAR_UPDATE_PRODUCT_REVERSAL_SELLER: "CLEAR_UPDATE_PRODUCT_REVERSAL_SELLER",
};

export function complaintReducer(state = initState, action) {
  switch (action.type) {
    case complaintReducerTypes.REQUEST_FETCH_PRODUCT_REVERSAL_SELLER:
      return { ...state, loading: true };
    case complaintReducerTypes.RESPONSE_FETCH_PRODUCT_REVERSAL_SELLER:
      return { ...state, error: null, loading: false, data: action.payload };
    case complaintReducerTypes.ERROR_FETCH_PRODUCT_REVERSAL_SELLER:
      return { ...state, loading: false, data: null, error: action.payload };

    case complaintReducerTypes.REQUEST_FETCH_ALL_PRODUCT_REVERSAL_SELLER:
      return {
        ...state,
        loading: true,
        complaintsByMeStatus:
          complaintReducerTypes.REQUEST_FETCH_ALL_PRODUCT_REVERSAL_SELLER,
      };
    case complaintReducerTypes.RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_SELLER:
      return {
        ...state,
        error: null,
        loading: false,
        complaintsByMe: action.payload,
        complaintsByMeStatus:
          complaintReducerTypes.RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_SELLER,
      };
    case complaintReducerTypes.ERROR_FETCH_ALL_PRODUCT_REVERSAL_SELLER:
      return {
        ...state,
        loading: false,
        complaintsByMe: null,
        error: action.payload,
        complaintsByMeStatus:
          complaintReducerTypes.ERROR_FETCH_ALL_PRODUCT_REVERSAL_SELLER,
      };

    case complaintReducerTypes.REQUEST_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER:
      return {
        ...state,
        loading: true,
        complaintsRaisedToMeStatus:
          complaintReducerTypes.REQUEST_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER,
      };
    case complaintReducerTypes.RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER:
      return {
        ...state,
        error: null,
        loading: false,
        complaintsRaisedToMe: action.payload,
        complaintsRaisedToMeStatus:
          complaintReducerTypes.RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER,
      };
    case complaintReducerTypes.ERROR_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER:
      return {
        ...state,
        loading: false,
        complaintsRaisedToMe: null,
        error: action.payload,
        complaintsRaisedToMeStatus:
          complaintReducerTypes.ERROR_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER,
      };

    case complaintReducerTypes.REQUEST_UPDATE_PRODUCT_REVERSAL_SELLER:
      return {
        ...state,
        updateLoading: true,
        updateStatus:
          complaintReducerTypes.REQUEST_UPDATE_PRODUCT_REVERSAL_SELLER,
      };
    case complaintReducerTypes.RESPONSE_UPDATE_PRODUCT_REVERSAL_SELLER:
      return {
        ...state,
        updateError: null,
        updateLoading: false,
        updateData: action.payload,
        updateStatus:
          complaintReducerTypes.RESPONSE_UPDATE_PRODUCT_REVERSAL_SELLER,
      };
    case complaintReducerTypes.ERROR_UPDATE_PRODUCT_REVERSAL_SELLER:
      return {
        ...state,
        updateLoading: false,
        updateData: null,
        updateError: action.payload,
        updateStatus:
          complaintReducerTypes.CLEAR_UPDATE_PRODUCT_REVERSAL_SELLER,
      };
    case complaintReducerTypes.CLEAR_UPDATE_PRODUCT_REVERSAL_SELLER:
      return {
        ...state,
        updateLoading: false,
        updateData: null,
        updateError: null,
        updateStatus: null,
      };
    default:
      return state;
  }
}
