/*
 **	User name
 **	Created	5/31/2023
 **	CategoryDataContext.tsx
 */

import React from "react";
import { CategoriesReducer } from "app/redux/reducers/categories";

export type CategoryAllData = {
  treeData: Array<any>;
  setTreeData: (data: any) => void;
  attributesTreeData: Array<any>;
  setAttributesTreeData: (data: any) => void;
  productCategoriesTreeData: Array<any>;
  setProductCategoriesTreeData: (data: any) => void;
  showAttributes: boolean;
  setShowAttributes: (data: any) => void;
  showProductCategories: boolean;
  setShowProductCategories: (data: any) => void;
  showColorSwatch: boolean;
  setShowColorSwatch: (data: any) => void;
  arrtibutesLength: number;
  setArrtibutesLength: (data: any) => void;
  colorData: Array<any>;
  setColorData: (data: any) => void;
  productCategoryConditions: {
    excludeCategories: Array<any>;
    includeCategories: Array<any>;
  };
  setProductCategoryConditions: (data: any) => void;
  clearAll: () => void;
  setData: (data: any) => void;
  selectedData: Array<any>;
};

type CategoryDataContextType = {
  libraryData: CategoryAllData;
  productData: CategoryAllData;
  categoriesDataMain: CategoriesReducer;
};

export const CategoryDataContext = React.createContext<CategoryDataContextType>(
  {
    libraryData: {
      treeData: [],
      setTreeData: (data) => {},
      attributesTreeData: [],
      setAttributesTreeData: (data) => {},
      productCategoriesTreeData: [],
      setProductCategoriesTreeData: (data) => {},
      showAttributes: null,
      setShowAttributes: (data) => {},
      showProductCategories: null,
      setShowProductCategories: (data) => {},
      showColorSwatch: null,
      setShowColorSwatch: (data) => {},
      arrtibutesLength: null,
      setArrtibutesLength: (data) => {},
      colorData: null,
      setColorData: (data) => {},
      productCategoryConditions: null,
      setProductCategoryConditions: (data) => {},
      clearAll: () => null,
      setData: (data) => {},
      selectedData: [],
    },
    productData: {
      treeData: [],
      setTreeData: (data) => {},
      attributesTreeData: [],
      setAttributesTreeData: (data) => {},
      productCategoriesTreeData: [],
      setProductCategoriesTreeData: (data) => {},
      showAttributes: null,
      setShowAttributes: (data) => {},
      showProductCategories: null,
      setShowProductCategories: (data) => {},
      showColorSwatch: null,
      setShowColorSwatch: (data) => {},
      arrtibutesLength: null,
      setArrtibutesLength: (data) => {},
      colorData: null,
      setColorData: (data) => {},
      productCategoryConditions: null,
      setProductCategoryConditions: (data) => {},
      clearAll: () => null,
      setData: (data) => {},
      selectedData: [],
    },
    categoriesDataMain: null,
  }
);
