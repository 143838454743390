/*
 **	User name
 **	Created	7/7/2023
 **	index.tsx
 */

import React, { Suspense, useState } from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Outlet, useLocation } from "react-router-dom";
import RouteLinks from "app/navigation/RouteLinks";

import NewHeader from "../NewHeader/newHeader";
import Footer from "../Footer";
import ProHeader from "../NewHeader";
import SearchBox from "../../screens/NewHomePage/searchBar";
import UniversalLoader from "../Loader";

const useGenericPageStyles = makeStyles((theme) => ({
  mainBox: {
    backgroundImage: "linear-gradient(89deg, #ffeef3 -8%, #ddfce8 121%)",
    padding: "34px 15px",
    display: "flex",
    justifyContent: "center",
  },
  SearchBox: {
    position: "absolute",
    top: "40px",
    left: "50%",
    zIndex: 3,
    transform: "translate(-50%,0%)",
  },
}));

const GenericPage = (props) => {
  const classes = useGenericPageStyles();
  const location = useLocation();
  const [searchState, setSearchState] = useState(false);

  const backgroundStyles = (() => {
    if (location.pathname === "/license") {
      const licenseBG = require("../../../assets/backgrounds/newLicense_bg.webp");

      return { background: `url(${licenseBG})` };
    }

    if (location.pathname === "/") {
      return {
        backgroundImage: `linear-gradient(89deg, #ffeef3 - 8 %, #ddfce8 121 %)`,
      };
    }

    return {
      background: `var(--white)`,
    };
  })();

  const { id = "generic-content" } = props;

  const pathName =
    location.pathname !== RouteLinks.HOME && location.pathname.endsWith("/")
      ? location.pathname.slice(0, -1)
      : location.pathname;
  // Add links from which we need to remove header

  if (
    [
      RouteLinks.HOME,
      RouteLinks.CART,
      RouteLinks.PROFILE,
      RouteLinks.LOGIN,
      RouteLinks.REGISTRATION,
      RouteLinks.EMPLOYEE_SIGNUP,
      RouteLinks.FORGOT_PASSWORD,
      RouteLinks.RESET_PASSWORD,
      RouteLinks.BILLING_PAGE,
    ].includes(pathName as RouteLinks)
  ) {
    return (
      <Suspense fallback={<UniversalLoader />}>
        <Outlet />
      </Suspense>
    );
  }

  return (
    <Box>
      <Box
        // development

        style={{
          ...backgroundStyles,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          padding: !props.headerText ? "34px 15px" : 0,
        }}
        className={classes.mainBox}
      >
        <Box
          style={{ maxWidth: "var(--container-width)", width: "100%" }}
          id="generic-page-main-container"
        >
          {props.pathName !== "Profile" &&
            (props.headerText ? (
              <NewHeader
                headerText={props.headerText}
                icon={props.icon || ""}
                class={props.class || ""}
                headerTitleStyle={props.headerTitleStyle}
              />
            ) : (
              <Box sx={{ "@media (max-width:900px)": { width: "90vw" } }}>
                <ProHeader setSearchState={setSearchState} />
                {searchState === true && (
                  <Box className={classes.SearchBox}>
                    <SearchBox
                      searchState={searchState}
                      setSearchState={setSearchState}
                    />
                  </Box>
                )}
              </Box>
            ))}
          <Box style={{ minHeight: 200 }} id={id}>
            <Suspense fallback={<UniversalLoader />}>
              <Outlet />
            </Suspense>
          </Box>
        </Box>
      </Box>
      {props.pathName !== "Profile" ? !props.hideFooter && <Footer /> : ""}
    </Box>
  );
};

export default GenericPage;
