import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { complaintReducerTypes } from "../reducers/complaint";

const version = process.env.REACT_APP_API_VERSION;

//product reversal seller get
function* getProductReversalSellerRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_PRODUCT_REVERSAL_SELLER.replace("{version}", version),
    payload
  );

  return res;
}

function* getProductReversalSeller({ payload }) {
  try {
    yield put({
      type: complaintReducerTypes.REQUEST_FETCH_PRODUCT_REVERSAL_SELLER,
      payload: null,
    });
    const response = yield call(getProductReversalSellerRequest, payload);

    yield put({
      type: complaintReducerTypes.RESPONSE_FETCH_PRODUCT_REVERSAL_SELLER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: complaintReducerTypes.ERROR_FETCH_PRODUCT_REVERSAL_SELLER,
    });
  }
}

//product reversal seller get
function* getAllProductReversalSellerRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_ALL_PRODUCT_REVERSAL_SELLER.replace("{version}", version),
    payload
  );

  return res;
}

function* getAllProductReversalSeller({ payload }) {
  try {
    yield put({
      type: complaintReducerTypes.REQUEST_FETCH_ALL_PRODUCT_REVERSAL_SELLER,
      payload: null,
    });
    const response = yield call(getAllProductReversalSellerRequest, payload);

    yield put({
      type: complaintReducerTypes.RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_SELLER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: complaintReducerTypes.ERROR_FETCH_ALL_PRODUCT_REVERSAL_SELLER,
    });
  }
}

//product reversal seller get
function* getAllProductReversalBySellerRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER.replace(
      "{version}",
      version
    ),
    payload
  );

  return res;
}

function* getAllProductReversalBySeller({ payload }) {
  try {
    yield put({
      type: complaintReducerTypes.REQUEST_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER,
      payload: null,
    });
    const response = yield call(getAllProductReversalBySellerRequest, payload);

    yield put({
      type: complaintReducerTypes.RESPONSE_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: complaintReducerTypes.ERROR_FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER,
    });
  }
}

//product reversal seller update
function* updateProductReversalSellerRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.UPDATE_PRODUCT_REVERSAL_SELLER.replace("{version}", version),
    payload
  );

  return res;
}

function* updateProductReversalSeller({ payload }) {
  try {
    yield put({
      type: complaintReducerTypes.REQUEST_UPDATE_PRODUCT_REVERSAL_SELLER,
      payload: null,
    });
    const response = yield call(updateProductReversalSellerRequest, payload);

    yield put({
      type: complaintReducerTypes.RESPONSE_UPDATE_PRODUCT_REVERSAL_SELLER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: complaintReducerTypes.ERROR_UPDATE_PRODUCT_REVERSAL_SELLER,
    });
  }
}

function* productSaga() {
  yield all([
    takeLatest(
      complaintReducerTypes.FETCH_PRODUCT_REVERSAL_SELLER,
      getProductReversalSeller
    ),
    takeLatest(
      complaintReducerTypes.UPDATE_PRODUCT_REVERSAL_SELLER,
      updateProductReversalSeller
    ),
    takeLatest(
      complaintReducerTypes.FETCH_ALL_PRODUCT_REVERSAL_SELLER,
      getAllProductReversalSeller
    ),
    takeLatest(
      complaintReducerTypes.FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER,
      getAllProductReversalBySeller
    ),
  ]);
}
export default productSaga;
