/*
 **	User name
 **	Created	6/26/2023
 **	RouteLinks.ts
 */

enum RouteLinks {
  HOME = "/",
  DESIGNER_PAGE = "/designer-page",
  COMPANY_PAGE = "/company-page",
  REGISTRATION = "/registration",
  EMPLOYEE_SIGNUP = "/employee-signup",
  LOGIN = "/login",
  STRIPE_CONNECT = "/stripe-connect",
  LICENSE = "/license",
  CURRENT_PLAN = "/current-plan",
  BILLING_PAGE = "/billing-page",
  PROFILE = "/profile",
  ORDERS_AND_INVOICES = "/orders-and-subscriptions",
  PRIVACY_POLICY = "/privacy-policy",
  TERMS = "/terms-and-conditions",
  CART = "/shopping-cart",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  HELP = "/help",
  SETTINGS = "/settings",
  DESIGNER = "/designer",
  COMPANIES = "/companies",

  COMPANY_PROFILE = "/company-profile",
  EMPLOYEE_PROFILE = "/employee-profile",
  DESIGNER_PROFILE = "/designer-profile",

  VIEW_PROFILE = "/view-profile",
  SETTINGS_PERSONALIZATION = "/settings-personalization",
  PRODUCTS = "/products",
  SINGLE_PRODUCT = "/products/:product_id",
  SINGLE_LIBRARY_PRODUCT = "/library-product/:product_id",
  CONTACT = "/contact-us",
  SETTINGS_PROFILE = "/settings/profile",
  COMPANY_INFO = "/about-us",
  CAREER_INFO = "/career-info",
  ACCOUNT_SETTINGS = "/account-settings",
  DESIGNERS = "/designers",
  SINGLE_DESIGNER = "/designer/:userName",
  SINGLE_COMPANY = "/companies/:company_id",
  JOBS = "/jobs",
  JOB_DETAILS = "/job/:job_id",
  EMPLOYEES = "/employees",
  LIBRARY_PRODUCTS = "/library",
  COMING_SOON = "/comingSoon",
  ORDER_DETAILS = "/orders-and-subscriptions/order-details/:orderId",
  IN_Progress = "/InProgress",
}

export default RouteLinks;
