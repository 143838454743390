/*
 **	User name
 **	Created	5/25/2023
 **	readMore.tsx
 */

import React from "react";
import { Typography } from "@mui/material";

const ReadMore = ({
  children,
  maxViweLength,
  textStyle,
  seeMoreStyle = "",
}) => {
  const text = children || "";
  const [isReadMore, setIsReadMore] = React.useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <Typography className={textStyle}>
      {isReadMore && typeof text === "string"
        ? text?.slice(0, maxViweLength)
        : text}
      <span onClick={toggleReadMore} className={`${seeMoreStyle} read-or-hide`}>
        {text?.length > maxViweLength &&
          (isReadMore ? "...see more" : " see less")}
      </span>
    </Typography>
  );
};

export default ReadMore;
