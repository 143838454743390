/*
 **	User name
 **	Created	5/25/2023
 **	LibraryUploadLeftSection.tsx
 */

import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import ClickAwayListener from "react-click-away-listener";

import { BlackButton } from "app/components/UIFormFields/Buttons";
import { CheckboxField } from "app/components/UIFormFields";
import { LibraryProductUploadContext } from "context/LibraryProductUploadContext";

import useUploadLibraryProductStyles from "./useUploadLibraryProductStyles";

const MainImage = ({ image }) => {
  const isImage =
    [".png", ".jpg", ".jpeg"].includes(image.fileType) ||
    image.type?.includes("image/");

  const imageSrc = image.type
    ? URL.createObjectURL(image)
    : image.libraryFileURL;
  //  replacing const threeDImageSrc = image.type  with const threeDImageSrc =  image.type || image.type === "" , because 3D image was not getting uploaded
  const threeDImageSrc =
    image.type || image.type === ""
      ? URL.createObjectURL(image)
      : image.libraryFileURL;

  return (
    <Box>
      <Box
        style={{
          backgroundSize: "cover",
          width: "100%",
          borderRadius: "6px",
          border: "1px solid #d7d0e8",
        }}
      >
        {isImage ? (
          <img
            style={{
              maxHeight: 332,
              maxWidth: 332,
            }}
            src={imageSrc}
          />
        ) : (
          <model-viewer
            key="0"
            style={{
              backgroundSize: "cover",
              width: "100%",
              borderRadius: "6px",
              border: "1px solid #d7d0e8",
              height: "394px",
            }}
            src={threeDImageSrc}
            ios-src=""
            alt="model name"
            camera-controls
            ar
            ar-modes="webxr scene-viewer quick-look"
            environment-image="neutral"
            oncontextmenu="return false;"
            autoplay
          ></model-viewer>
        )}
      </Box>
    </Box>
  );
};

const ExtraImages = ({
  images,
  handleDelete,
  hoverStatus,
  setHoverState,
  handleChecked,
  selected,
  setSelected,
  // setThumbnail,
}) => {
  const classes = useUploadLibraryProductStyles();
  const {
    ReactComponent: DeleteIcon,
  } = require("../../../../assets/icons/Icons_Dark_Delete Icon.svg");
  const {
    ReactComponent: Thumbnail,
  } = require("../../../../assets/icons/Icons_Light_Thumbnail.svg");

  return (
    <>
      {images?.map((image, index) => {
        const isImage =
          [".png", ".jpg", ".jpeg"].includes(image.fileType) ||
          image.type?.includes("image/");

        const imageSrc = image.type
          ? URL.createObjectURL(image)
          : image.libraryFileURL;
        //  replacing const threeDImageSrc = image.type  with const threeDImageSrc =  image.type || image.type === "" , because 3D image was not getting uploaded
        const threeDImageSrc =
          image.type || image.type === ""
            ? URL.createObjectURL(image)
            : image.libraryFileURL;

        return (
          <>
            <Box
              style={{
                margin: "5px",
                width: "138px",
                overflow: "hidden",
              }}
              className={
                index ===
                (parseInt(window.sessionStorage.getItem("thumbnail")) || 0)
                  ? classes.firstItem
                  : ""
              }
              onMouseOver={() => setHoverState(index)}
              onMouseOut={() => setHoverState("")}
            >
              {isImage ? (
                <>
                  <img
                    alt={"Some image"}
                    src={imageSrc}
                    style={{ width: "138px", height: "114px" }}
                  />
                </>
              ) : (
                <>
                  <model-viewer
                    key={index}
                    style={{ width: "138px", height: "114px" }}
                    src={threeDImageSrc}
                    ios-src=""
                    alt="model name"
                    camera-controls
                    ar
                    ar-modes="webxr scene-viewer quick-look"
                    environment-image="neutral"
                    oncontextmenu="return false;"
                    autoplay
                  ></model-viewer>
                </>
              )}

              <CheckboxField
                checked={selected.includes(index)}
                onClick={() => handleChecked(image, index)}
                sx={{
                  position: "relative",
                  left: -50,
                  top: -120,
                }}
              />

              <Typography
                style={{
                  fontSize: "11px",
                  color: "#2b2639",
                  width: "138px",
                  overflow: "hidden",
                }}
              >
                {image?.name}
              </Typography>
              {index === hoverStatus && selected.includes(hoverStatus) && (
                <Box
                  sx={{
                    width: "130px",

                    display: "flex",
                    boxShadow: "0 1px 10px 0 rgba(122, 72, 255, 0.59)",
                    justifyContent: "space-around",
                    borderRadius: "6px",
                  }}
                >
                  <DeleteIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleDelete}
                  />
                  <Thumbnail
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      // setThumbnail(selected[0]);
                      window.sessionStorage.setItem("thumbnail", selected[0]);
                      setSelected([]);
                    }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      })}
    </>
  );
};

// Left Section Funtionality
const LibraryUploadLeftSection = () => {
  const { setUploadedImages, uploadedImages, uploadedImagesInEdit } =
    useContext(LibraryProductUploadContext);
  const {
    ReactComponent: LibraryIcon,
  } = require("../../../../assets/icons/Icons_Light_Library-Icon.svg");

  const classes = useUploadLibraryProductStyles();
  const {
    ReactComponent: AddIcon,
  } = require("../../../../assets/icons/Icons_Light_Add.svg");
  const attachFileRef = React.useRef<HTMLInputElement>();

  const onUploadFile = (event) => {
    setUploadedImages([...uploadedImages, ...event.target.files]);
  };

  const handleClearAll = () => {
    setUploadedImages([]);
  };

  const [selected, setSelected] = React.useState([]);
  const handleChecked = (_item, index) => {
    const temp = selected?.includes(index);

    temp
      ? setSelected(selected.filter((file) => file !== index))
      : setSelected([...selected, index]);
  };
  const [hoverStatus, setHoverState] = React.useState<number | string>("");

  // const [updatedImages, setUpdatedImages] = React.useState([]);

  const handleDelete = () => {
    const temp = selected.sort().reverse();
    const tempArray = uploadedImages;

    temp?.map((item) => tempArray.splice(item, 1));
    setSelected([]);
  };

  const displayImages = (() => {
    return [...uploadedImagesInEdit, ...uploadedImages];
  })();

  // const [thumbnail, setThumbnail] = React.useState(0);

  return (
    <Box>
      <ClickAwayListener onClickAway={() => {}}>
        <Box>
          {uploadedImages[0] || uploadedImagesInEdit?.length ? (
            <Box className={classes.subLeftSection}>
              {/* Left Section Header and SubHeader Section */}
              <Box>
                <Typography className={classes.headerLeftSection}>
                  Upload Library Files
                </Typography>
                <Typography className={classes.subHeaderLeftSection}>
                  Upload Library files and other related image.
                </Typography>
                <Typography className={classes.subHeaderLeftSection}>
                  The first in the grid act as a thumbnail.
                </Typography>
              </Box>

              {/* Details Section */}
              <Box className={classes.detailsLeftSection}>
                <Typography className={classes.subHeaderLeftSection}>
                  File Types
                </Typography>
                <Typography className={classes.subHeaderLeftSection}>
                  Library Files({displayImages?.length})
                </Typography>
              </Box>

              {/* Below image Section */}
              <Box>
                {/* <MainImage image={displayImages[thumbnail]} /> */}
                <MainImage
                  image={
                    displayImages[
                      window.sessionStorage.getItem("thumbnail") || 0
                    ]
                  }
                />
              </Box>
              {/* Preview Section */}
              <Box>
                <Typography
                  className={classes.clearAll}
                  onClick={handleClearAll}
                  style={{ cursor: "pointer" }}
                >
                  Clear All
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    overflow: "scroll",
                    maxHeight: "450px",
                    padding: "5px",
                  }}
                >
                  <ExtraImages
                    images={displayImages}
                    handleDelete={handleDelete}
                    hoverStatus={hoverStatus}
                    setHoverState={setHoverState}
                    handleChecked={handleChecked}
                    selected={selected}
                    setSelected={setSelected}
                    // setThumbnail={setThumbnail}
                  />
                </Box>
              </Box>
            </Box>
          ) : (
            <Box className={classes.subLeftSection}>
              <Typography
                className={classes.headerLeftSection}
                sx={{ marginTop: "50px" }}
              >
                Upload Library Files
              </Typography>
              <LibraryIcon style={{ margin: "34px" }} />
              <Box style={{ margin: "20px" }}>
                {" "}
                <Typography className={classes.subHeaderLeftSection}>
                  Upload Library files and other related image.
                </Typography>
                <Typography className={classes.subHeaderLeftSection}>
                  The first in the grid act as a thumbnail.
                </Typography>
              </Box>
            </Box>
          )}
          {/* Upload Multiple Files Button Section */}

          <Box>
            <BlackButton
              onClick={() =>
                attachFileRef?.current && attachFileRef?.current?.click?.()
              }
            >
              <Box>
                <input
                  style={{ display: "none", width: "30px" }}
                  accept="*"
                  id="icon-button-file"
                  multiple
                  ref={attachFileRef}
                  type="file"
                  hidden
                  onChange={onUploadFile}
                />
              </Box>
              <AddIcon style={{ marginRight: "10px" }} />
              Upload Multiple Files
            </BlackButton>
          </Box>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

export default LibraryUploadLeftSection;
