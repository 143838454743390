import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GradientColoredButton } from "../../components/UIFormFields";

const HomeBanner = makeStyles((theme) => ({
  container: {
    display: "flex",
    // flexWrap: "wrap",
    flexDirection: "row",
    width: "100%",
    height: "678px",
    // flexGrow: 0,
    // objectFit: "contain",

    backdropFilter: "blur(60px)",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    borderWidth: "2px",
    borderImageSource:
      "linear-gradient(117deg, var(--white) 1%, rgba(255, 255, 255, 0) 101%)",
    borderImageSlice: "1",
    backgroundImage:
      "linear-gradient(118deg, rgba(255, 255, 255, 0.5) -4%, rgba(255, 255, 255, 0.5) 106%)",
  },
  Typography: {
    margin: "91px 0px 0px 0px",
    // width: "357px",
    height: "137px !important",
    [theme.breakpoints.down("lg")]: {
      margin: "110px 0px 0px 0px",
      height: "137px !important",
    },
  },
  comingsoon: {
    fontSize: "81px !important",
    fontWeight: "bold !important",
    zIndex: 10,
    [theme.breakpoints.down("lg")]: {
      fontSize: "71px !important",
      fontWeight: "bold !important",
      zIndex: 10,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "71px !important",
      fontWeight: "bold !important",
      zIndex: 10,
    },
  },
}));

export default function Banner2() {
  const maskgroup = require("../../../assets/backgrounds/Maskgroup.png");
  const Shoes = require("../../../assets/backgrounds/Shoes1.png");
  const frame = require("../../../assets/graphics/Frame.png");
  const frame1 = require("../../../assets/graphics/Frame1.png");
  const classes = HomeBanner();
  const navigate = useNavigate();

  return (
    <Box className={classes.container}>
      <Box flex={1}>
        <img style={{ position: "absolute" }} src={maskgroup} />
        <img style={{ position: "relative" }} src={Shoes} />
      </Box>
      <Box
        flex={1}
        display="flex"
        justifyContent={"center"}
        flexDirection="column"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
            // width: "450px",
            height: "500px",
          }}
        >
          <img
            style={{ position: "absolute", top: "56px", right: "131px" }}
            src={frame}
          />
          <img
            style={{ position: "absolute", top: "131px", right: "190px" }}
            src={frame1}
          />
          <Box className={classes.Typography}>
            <Typography className={classes.comingsoon}>Coming Soon</Typography>
          </Box>

          <Typography
            sx={{
              width: "299px",
              backgroundImage:
                "linear-gradient(79deg, rgba(245, 77, 100) 0%, rgba(245, 133, 97) 100%)",
              fontSize: "41px",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            What's New?
          </Typography>
          <Box width={"450px"}>
            <Typography>
              We are happy to announce that our start up has now taken the step
              of publishing. But we are far from finished! Stay up to date on
              which features have just been added and which we are currently
              working on.
            </Typography>
            <GradientColoredButton
              disableElevation={true}
              disableFocusRipple={true}
              disableRipple={true}
              disableTouchRipple={true}
              style={{
                width: "86px",
                marginTop: 20,
              }}
              onClick={() => {
                navigate("/comingsoon");
              }}
            >
              Explore
            </GradientColoredButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
