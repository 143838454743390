const initState = {
  loading: false,
  data: null,
  error: null,
};

export const employeeTypes = {
  FETCH_EMPLOYEE_DETAILS: "FETCH_EMPLOYEE_DETAILS",
  REQUEST_FETCH_EMPLOYEE_DETAILS: "REQUEST_FETCH_EMPLOYEE_DETAILS",
  RESPONSE_FETCH_EMPLOYEE_DETAILS: "RESPONSE_FETCH_EMPLOYEE_DETAILS",
  ERROR_FETCH_EMPLOYEE_DETAILS: "ERROR_FETCH_EMPLOYEE_DETAILS",
  CLEAR_FETCH_EMPLOYEE_DETAILS: "CLEAR_FETCH_EMPLOYEE_DETAILS",
};

export function employeeReducer(state = initState, action) {
  switch (action.type) {
    case employeeTypes.REQUEST_FETCH_EMPLOYEE_DETAILS:
      return { ...state, loading: true };
    case employeeTypes.RESPONSE_FETCH_EMPLOYEE_DETAILS:
      return { error: null, loading: false, data: action.payload };
    case employeeTypes.ERROR_FETCH_EMPLOYEE_DETAILS:
      return { loading: false, data: null, error: action.payload };
    case employeeTypes.CLEAR_FETCH_EMPLOYEE_DETAILS:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
