/*
 **	User name
 **	Created	5/27/2023
 **	productDetails.ts
 */

// @ts-nocheck
import { makeStyles } from "@mui/styles";

export const useProductStyles = makeStyles(() => ({
  accordion: {
    marginTop: 15,
  },
  dateSection: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
  },
  swiperBox: {
    maxWidth: "1400px",
    backgroundColor: "red",
  },
}));

export const useProductDetailsStyles = makeStyles(() => ({
  modalBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    width: "566px",
    height: "462px",
    margin: "auto",
    boxShadow: "0 16px 24px 0 rgba(43, 38, 57, 0.1)",
    borderRadius: "20px",
    padding: "24px",
  },
  alertImg: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: "20px",
  },
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1250px !important",
    minHeight: "1647px !important",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto !important",
    // overflow: "auto",
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    // margin: "34px 54px 54px 54px"
    justifyContent: "center",
  },
  leftBox: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "24px",
    flex: 1,
    width: "70%",
  },
  rightBox: {
    display: "flex",
    flexDirection: "column",
    width: "30%",
  },
  rightTopBox: {
    width: "100%",
    // maxWidth: "368px",
    // flex: 0.8,
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    border: "solid 1px #d7d0e8 !important",
    borderRadius: "20px !important",
    padding: "24px",
  },
  rightBottomBox: {
    width: "350px",
    // flex: 1,
    minHeight: "900px",
    maxHeight: "1100px",
    overflowY: "auto",
    flexWrap: "wrap",
    border: "solid 1px #d7d0e8 !important",
    borderRadius: "20px !important",
    marginTop: "24px",
    padding: "24px",
  },
  rightBottomPreviewBox: {
    width: "350px",
    // flex: 1,
    minHeight: "652px",
    maxHeight: "652px",
    overflowY: "auto",
    flexWrap: "wrap",
    border: "solid 1px #d7d0e8 !important",
    borderRadius: "20px !important",
    marginTop: "24px",
    padding: "24px",
  },
  subButtonBox: {
    // paddingTop: "30px",
    bottom: 0,
    marginBottom: "54px !important",
    margin: "0 auto",
  },
  productName: {
    fontSize: "27px !important",
    fontWeight: "bold !important",
    color: "#2b2639",
    flexGrow: 0,
  },
  title: {
    fontSize: "13px !important",
    fontWeight: "bold !important",
    color: "#a49bbe",
    margin: "24px 0px 8px 0px !important",
  },
  clickBox: {
    display: "flex",
    flexDirection: "row !important",
    flexWrap: "wrap",
  },
  colorBox: {
    width: "34px",
    height: "34px",
    marginRight: "16px !important",
    borderRadius: "6px",
  },
  description: {
    fontSize: "15px",
    textAlign: "left",
    color: "#2b2639",
    // color: ?.palette?.mode === "light" ? "#2b2639" : "#fff",
  },
  chipBox: {
    // background: "#ebe3ff",
    backgroundColor: "#ebe3ff !important",
    borderRadius: "6px !important",
    fontSize: "15px !important",
    margin: "3px 5px 3px 0px !important",
    width: "auto",
    height: "34px !important",
    // color: "#a49bbe !important",
    color: "#7a48ff !important",
    marginRight: "8px !important",
  },
  commentBox: {
    display: "flex",
    flexDirection: "row",
  },
  comments: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  emojiIcon: {
    cursor: "pointer",
    position: "absolute",
    top: 16,
    right: 16,
  },
  boundaryLine: {
    width: "100%",
    height: "1px",
    margin: "16px 0px",
    border: "solid 1px #d7d0e8",
    backgroundColor: "#d7d0e8",
  },
  userName: {
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "bold !important",
    textAlign: "left",
    color: "#2b2639",
  },
  userNameBox: {
    display: "flex",
    flexDirection: "column",
  },
  commentText: {
    fontSize: "15px",
    textAlign: "left",
    color: "#2b2639",
    margin: "6px 0px",
  },
  commentTime: {
    fontSize: "13px !important",
    color: "#a49bbe",
    marginRight: "8px !important",
  },
  commentReply: {
    fontSize: "13px !important",
    fontWeight: 500,
    color: "#a49bbe",
  },
  moreOption: {
    position: "absolute",
    top: 0,
    right: "16px",
    cursor: "pointer",
  },
  rightTopPriceBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "28px",
    justifyContent: "space-between",
  },
  socialIconBox: {
    display: "flex",
    flexDirection: "row",
    // marginLeft: "130px"
    width: "100%",
    justifyContent: "end",
  },
  socialIcon: {
    width: "34px",
    height: "34px",
    marginRight: "8px",
    borderRadius: "5px !important",
    border: "solid 1px #d7d0e8",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  details: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "17px",
    position: "relative",
  },
  detailsIcon: {
    marginRight: "8px",
  },
  detailsText: {
    position: "absolute",
    top: 0,
    right: 0,
    maxWidth: "196px",
  },
  profilePicture: {
    width: "54px !important",
    height: "54px !important",
    borderRadius: "50%",
    margin: "0px 8px 0px 0px",
  },
  userProfession: {
    fontSize: "13px !important",
    textAlign: "left",
    color: "#a49bbe",
  },
  userProfile: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "left",
    marginTop: "8px",
    marginBottom: "8px",
    width: "100%",
  },
  userSocialIconBox: {
    display: "flex",
    flexDirection: "row",
    // marginLeft: "60px"
  },
  rightPanelTitle: {
    fontSize: "13px !important",
    fontWeight: "bold !important",
    color: "#a49bbe",
  },
  rightPanelDescription: {
    fontSize: "13px",
    textAlign: "left",
    color: "#2b2639 !important",
  },
  detailsUser: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "relative",
    flexWrap: "wrap",
    width: "100%",
  },
  attachment: {
    display: "flex",
    flexDirection: "column",
  },
  rightBottomPanelTitle: {
    fontSize: "13px !important",
    fontWeight: "bold !important",
    color: "#a49bbe",
    paddingTop: "16px",
  },
  rightBottomDetailsText: {
    textAlign: "left",
    maxWidth: "196px",
    marginTop: "16px !important",
  },
  rightBottomBoundaryLine: {
    width: "100%",
    height: "1px !important",
    margin: "24px 0px !important",
    border: "solid 1px #d7d0e8",
    backgroundColor: "#d7d0e8",
  },
  snackbarcss: {
    minWidth: "255px",
    height: "54px",
    backgroundColor: "#64c279 !important",
    color: "#fff",
    borderRadius: "10px",
    boxShadow: "0 10px 20px 0 rgba(100, 194, 121, 0.71)",
  },
  modalPreview: {},
}));

export const useProductDetailsModalStyles = makeStyles(() => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxHeight: 707,
    maxWidth: 1250,
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
  },
  titleBox: {
    margin: "54px 0px 0px 0px",
  },
  textField: {
    objectFit: "contain",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "normal",
    textAlign: "left",
    color: "#a49bbe",
  },
  editFielBox: {
    width: "100%",
    padding: "0px 54px 24px 54px",
  },
  editBox: {
    display: "flex",
    flexDirection: "row",
  },
}));
