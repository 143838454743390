/*
 **	User name
 **	Created	6/1/2023
 **	JobPostProvider.tsx
 */

import React, { useState } from "react";
import { useAppSelector } from "app/utils/hooks";
import { accountGuidSelector } from "app/redux/selectors/user-selector";
import { JobsReducerTypes } from "app/redux/reducers/jobReducer";
import { useDispatch } from "react-redux";

import { JobPostContext, JobData } from "./JobPostContext";

export const JobPostProvider = ({ children }) => {
  const accountGuid = useAppSelector(accountGuidSelector);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    companyName: "",
    industryTypeId: null,
    jobTitle: "",
    employmentTypeId: null,
    experienceTypeId: null,
    skillsIdList: [],
    applyBy: null,
    otherIndustryType: "",
    applyByLink: "",
    jobWorkplaceTypeId: null,
    jobSalaryRangeId: null,
    jobLocation: [],
    minimumRange: "",
    maximumRange: "",
    jobRateId: "",
    jobDescription: "",
    isActive: true,
    isSavedToDraft: false,
    currencyId: null,
  });

  const clearJobFormData = () => {
    setFormData({
      companyName: "",
      industryTypeId: null,
      jobTitle: "",
      employmentTypeId: null,
      experienceTypeId: null,
      skillsIdList: [],
      applyBy: null,
      otherIndustryType: "",
      applyByLink: "",
      jobWorkplaceTypeId: null,
      jobSalaryRangeId: null,
      jobLocation: [],
      minimumRange: "",
      maximumRange: "",
      jobRateId: "",
      jobDescription: "",
      isActive: true,
      isSavedToDraft: false,
      currencyId: null,
    });
  };

  const handlePostJob = (
    data: JobData,
    isSavedToDraft: boolean,
    isActive: boolean
  ) => {
    const payloadData = {
      accountGuid,
      companyName: data?.companyName,
      jobTitle: data?.jobTitle,
      industryTypeId: data?.industryTypeId,
      companyTypeId: data?.industryTypeId,
      employmentTypeId: data?.employmentTypeId,
      experienceTypeId: data?.experienceTypeId,
      jobApplyMediumId: data?.applyBy,
      jobWorkplaceTypeId: data?.jobWorkplaceTypeId,
      jobSalaryRangeId: 1,
      jobRateId: data?.jobRateId,
      minimumRange: data?.minimumRange,
      maximumRange: data?.maximumRange,
      jobDescription: data?.jobDescription,
      jobLocation: data?.jobLocation,
      skillsIdList: data?.skillsIdList,
      isSavedToDraft,
      isActive,
      externalLink: data?.applyByLink || "",
      currencyId: data?.currencyId,
    };

    dispatch({
      type: JobsReducerTypes.JOB_POST_LIST,
      payload: payloadData,
    });
  };

  const postJob = (data: JobData) => handlePostJob(data, false, true);
  const saveJobAsDraft = (data: JobData) => handlePostJob(data, true, false);

  return (
    <JobPostContext.Provider
      value={{
        postJob,
        saveJobAsDraft,
        formData,
        setFormData,
        clearJobFormData,
      }}
    >
      {children}
    </JobPostContext.Provider>
  );
};
