import React, { Suspense } from "react";
import { useSelector } from "react-redux";

import { accessTokenSelector } from "app/redux/selectors/user-selector";
import UniversalLoader from "app/components/Loader";

import HomeAreaLoggedIn from "./home-loggedin";
import GuestHomePage from "./guestHomepage";

const HomeArea = () => {
  const accessToken = useSelector(accessTokenSelector);

  return (
    <Suspense fallback={<UniversalLoader />}>
      {accessToken ? <HomeAreaLoggedIn /> : <GuestHomePage />}
    </Suspense>
  );
};

export default HomeArea;
