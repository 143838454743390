/* eslint-disable no-console */
/*
 **	User name
 **	Created	5/25/2023
 **	newNavbar.tsx
 */

import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "app/redux/reducers";
import RouteLinks from "app/navigation/RouteLinks";

import { useNavBarStyles } from "../../../styles/navbarStyles";
import { navBarTypes } from "../../redux/reducers/navBar";
import { filterReducerTypes } from "../../redux/reducers/filterReducers/filter";
import CategoryDropdown from "./CategoryDropdown";
import LibraryDropdown from "./LibraryDropdown";

export default function NewNavBar() {
  const classes = useNavBarStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const navBarState = useSelector((state: IRootState) => state.navBarReducer);

  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleHomeClick = () => {
    dispatch({
      type: navBarTypes.NAV_BAR_HOME,
    });
  };

  const handleDesignersClick = () => {
    dispatch({
      type: navBarTypes.NAV_BAR_DESIGNERS,
    });
    dispatch({
      type: filterReducerTypes.CLEAR_DESIGNERS_FILTERED_LIST,
    });
  };
  const handleCompaniesClick = () => {
    dispatch({
      type: navBarTypes.NAV_BAR_COMPANIES,
    });
    dispatch({
      type: filterReducerTypes.CLEAR_COMPANIES_FILTERED_LIST,
    });
  };

  const handleJobClick = () => {
    dispatch({
      type: navBarTypes.NAV_BAR_JOBS,
    });
  };

  const viewTypeFunction = () => {
    return !isMdScreen ? "newHeaderNavBar HeaderActiveNavBar" : "";
  };

  return (
    <Box className="col-md-12 mx-auto">
      <Box
        className={classes.header}
        style={{
          position: "relative",
          maxHeight: "50px",
        }}
      >
        <nav className="w-100">
          <ul
            className={`${classes.headerWidth} d-flex flex-column flex-md-row`}
            // sx={{
            //   width: "100%",
            //   "@media (max-width: 900px)": {
            //     width: "100%",
            //   },
            // }}
          >
            <li
              className={classes.header_navbar_li}
              style={{
                display: "flex",
              }}
            >
              <Link to={RouteLinks.HOME} className={classes.NavBarElement}>
                <span
                  data-bs-dismiss="offcanvas"
                  className={
                    theme?.palette?.mode === "light"
                      ? navBarState.home
                        ? `${classes.navbar_li_a_light} ${viewTypeFunction()}`
                        : `${classes.navbar_li_a_light} newHeaderNavBar`
                      : navBarState.home
                      ? `${classes.navbar_li_a_dark} ${viewTypeFunction()}`
                      : `${classes.navbar_li_a_dark} newHeaderNavBar`
                  }
                  onClick={handleHomeClick}
                >
                  Home
                </span>
              </Link>
            </li>
            <li
              className={classes.navbar_li + classes.NavBarElement}
              style={{ marginTop: "5px" }}
            >
              <CategoryDropdown />
            </li>

            <li
              className={classes.navbar_li}
              style={{
                display: "flex",
              }}
            >
              <Link to={RouteLinks.DESIGNERS} className={classes.NavBarElement}>
                <span
                  data-bs-dismiss="offcanvas"
                  className={
                    theme?.palette?.mode === "light"
                      ? navBarState.designers
                        ? `${classes.navbar_li_a_light} ${viewTypeFunction()}`
                        : `${classes.navbar_li_a_light} newHeaderNavBar`
                      : navBarState.designers
                      ? `${classes.navbar_li_a_dark} ${viewTypeFunction()} `
                      : `${classes.navbar_li_a_dark} newHeaderNavBar`
                  }
                  onClick={handleDesignersClick}
                >
                  Designers
                </span>
              </Link>
            </li>
            <li
              className={classes.navbar_li}
              style={{
                display: "flex",
              }}
            >
              <Link to={RouteLinks.COMPANIES} className={classes.NavBarElement}>
                <span
                  data-bs-dismiss="offcanvas"
                  className={
                    theme?.palette?.mode === "light"
                      ? navBarState.companies
                        ? `${classes.navbar_li_a_light} ${viewTypeFunction()}`
                        : `${classes.navbar_li_a_light} newHeaderNavBar`
                      : navBarState.companies
                      ? `${classes.navbar_li_a_dark} ${viewTypeFunction()}`
                      : `${classes.navbar_li_a_dark} newHeaderNavBar`
                  }
                  onClick={handleCompaniesClick}
                >
                  Companies
                </span>
              </Link>
            </li>

            <li
              className={classes.navbar_li}
              style={{
                display: "flex",
              }}
            >
              <Link to={RouteLinks.JOBS} className={classes.NavBarElement}>
                <span
                  data-bs-dismiss="offcanvas"
                  className={
                    theme?.palette?.mode === "light"
                      ? navBarState.jobs
                        ? `${classes.navbar_li_a_light} ${viewTypeFunction()}`
                        : `${classes.navbar_li_a_light} newHeaderNavBar`
                      : navBarState.jobs
                      ? `${classes.navbar_li_a_dark} ${viewTypeFunction()}`
                      : `${classes.navbar_li_a_dark} newHeaderNavBar`
                  }
                  onClick={handleJobClick}
                >
                  Jobs
                </span>
              </Link>
            </li>

            <li
              className={classes.navbar_li + classes.NavBarElement}
              style={{ marginTop: "5px" }}
            >
              <LibraryDropdown />
            </li>
          </ul>
        </nav>
      </Box>
    </Box>
  );
}
