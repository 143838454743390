/*
 **	User name
 **	Created	5/24/2023
 **	index.jsx
 */

import React, { Suspense, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";

import { IRootState } from "app/redux/reducers";
import { LightUploadIcon } from "../Logo/logos";
import { GradientColoredButton } from "../UIFormFields";

import UploadPreviewModal from "./previewModal";
import UploadOptionModal from "./uploadOptions";
import LibraryUploadModal from "./libraryUpload";
import UniversalLoader from "../Loader";

export const useHeaderStyles = makeStyles((theme) => ({
  arrowIcon: {
    padding: "35px 0px 35px 24px",
    cursor: "pointer",
  },
  mainIconBox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "-20px",
  },
  HeaderTitle: {
    padding: "34px 0px 34px 19px",
  },
  mainIcon: {
    padding: "13px 0px 13px 24px",
    cursor: "pointer",
  },
  navBar: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignText: "center",
    marginLeft: "-10px",
  },
}));

export default function UploadWork() {
  const classes = useHeaderStyles();

  // const [showModal, setShowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [showUploadOptions, setShowUploadOptions] = useState(false);
  const [libraryUploadModal, setLibraryUploadModal] = useState(false);
  // const [additinalInfoModal, setAdditinalInfoModal] = useState(false);
  // const [libraryProductPreview, setLibraryProductPreview] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);
  const dispatch = useDispatch();

  const handleClick = (item) => {
    if (item === "productUpload") {
      setShowPreviewModal(true), setShowUploadOptions(false);
    } else if (item === "libraryUpload") {
      setLibraryUploadModal(true);
      setShowUploadOptions(false);
    } else if (item === "backProductPage") {
      setLibraryUploadModal(false);
      setShowUploadOptions(true);
    }
  };

  // called on Next Button
  const [libraryProducts, setLibraryProducts] = React.useState({
    uploads: [],
    title: "",
    tools: [],
    tags: [],
    description: "",
    product: [],
    visibility: "Public",
    download: "free",
    license: "",
    category: "",
    subCategory: "",
    gender: "",
    productCategory: "",
    productAttributes: "",
    types: "",
    shape: "",
    // for Attributes
    fit: "",
    length: "",
    neckline: "",
    occasion: "",
    skirtShape: "",
    sleeveLength: "",
    trouserRise: "",
    skirtRise: "",
    shortsRise: "",
    features: "",
    bottomRise: "",
    characterstics: "",
    jeanRise: "",
    armholeType: "",
    age: "",
  });

  const userData = useSelector(
    (state: IRootState) => state.register?.userAdditionalInfo
  );

  return (
    <>
      <GradientColoredButton
        id="icon-button-file"
        // onClick={() => setShowPreviewModal(true)}
        onClick={() => {
          userData?.isAccountBlocked && userData?.currentPlanId === 1
            ? alert("account Blocked")
            : (setShowUploadOptions(true),
              sessionStorage.setItem("filterStatus", "0"));
        }}
        style={{
          width: 180,
          height: "54px",
          color: "#fff",
          margin: "10px 7px",
        }}
      >
        <LightUploadIcon style={{ marginRight: "10px " }} />
        Upload Work
      </GradientColoredButton>
      {/* {showModal && (
        <UploadWorkModal
          setUploadFile={setUploadFile}
          handleCloseCallback={setShowModal}
          showModal={showModal}
          setShowPreviewModal={setShowPreviewModal}
        />
      )} */}

      <Suspense fallback={<UniversalLoader />}>
        {showPreviewModal && (
          <UploadPreviewModal
            handleCloseCallback={setShowPreviewModal}
            uploadFile={uploadFile}
            setUploadFile={setUploadFile}
          />
        )}

        {/* option for Product and Library Upload */}
        {showUploadOptions && (
          <UploadOptionModal
            handleClick={(item) => handleClick(item)}
            handleClose={() => setShowUploadOptions(false)}
          />
        )}

        {/* upload Library Modal flow*/}
        {libraryUploadModal && (
          <LibraryUploadModal
            handleClose={() => setLibraryUploadModal(false)}
            handleClick={(item) => handleClick(item)}
          />
        )}

        {/* {additinalInfoModal && (
        <AdditionalInfo
          handleClose={() => setAdditinalInfoModal(false)}
          handleClick={(item) => handleClick(item)}
          libraryProducts={libraryProducts}
          setLibraryProducts={setLibraryProducts}
        />
      )} */}

        {/* {libraryProductPreview && (
        <LibraryProductPreview
          handleClick={handleClick}
          handleClose={() => setLibraryProductPreview(false)}
          libraryProducts={libraryProducts}
          setLibraryProducts={setLibraryProducts}
          uploadFile={uploadFile}
        />
      )} */}

        {/* {showPreviewModal && (
        <EditThumbnailModal
          open={showPreviewModal}
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          handleClose={() => setShowPreviewModal(false)}
        />
      )} */}
      </Suspense>
    </>
  );
}
