/*
 **	User name
 **	Created	5/25/2023
 **	index.ts
 */

import createSagaMiddleware from "redux-saga";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "../reducers";
import { mainSaga } from "../saga/mainsaga";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["register", "girdListView"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat([sagaMiddleware]);
  },
});

sagaMiddleware.run(mainSaga);

export const persistor = persistStore(store);
