/*
 **	User name
 **	Created	6/25/2023
 **	home-loggedin.tsx
 */

import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { cryptr, isCompanyUser } from "app/utils/helper";
import { homeActionTypes } from "app/redux/reducers/home";
import { accountGuidSelector } from "app/redux/selectors/user-selector";
import { IRootState } from "app/redux/reducers";
import { useSearchParams } from "react-router-dom";
import { PurchaseContext } from "context/PurchaseContext";
import { OrdersActions } from "app/redux/reducers/order";
import { useNavigate } from "react-router";
import { useTheme } from "@mui/system";
import { myProductsReducerTypes } from "app/redux/reducers/productReducer/product-main";
import { FreeTrialContext } from "context/FreeTrialContext";

import { useAppSelector } from "app/utils/hooks";
import Footer from "../../components/Footer";
import NewHeader from "../../components/NewHeader";
import MediaShorts from "./mediaShorts";
import PreviewDesign from "./previewDesign";
import Trendings from "./trendings";
import SearchBox from "./searchBar";
import SubscribeBanner from "./newsLetter";
import { navBarTypes } from "../../redux/reducers/navBar";
import BannerSoon from "./bannerSoon";
import Banner2 from "./banner2";
import Banner3 from "./banner3";
import InviteEmployeeAlert from "./InviteEmployee/InviteEmployeeAlert";
import OrderSuccess from "./OrderSuccess";

const useHomeAreaStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "540px !important",
    minHeight: "500px !important",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  mainBox: {
    backgroundImage: "linear-gradient(89deg, #ffeef3 -8%, #ddfce8 121%)",
    padding: "34px 42px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  headerBox: {
    width: "100%",
    backgroundImage:
      "linear-gradient(124deg, rgba(255, 255, 255, 0.48) 2%, rgba(255, 255, 255, 0) 105%)",
    boxShadow: " 0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(60px)",
    WebkitBackdropFilter: "blur(60px)",
    borderRadius: "20px",
    position: "relative",
    zIndex: 2,
    // opacity: 0.9,
    maxWidth: "var(--container-width)",
  },
  groupImage900: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  frameImage: {
    width: "95px",
    height: "50px",
    position: "absolute",
    top: "199px",
    right: 0,
  },
  groupImage901: {
    width: "545px",
    height: "507px",
    position: "absolute",
    top: "472px",
    right: 0,
  },
  mediaShorts: {
    width: "100%",
    marginTop: "56px",
    maxWidth: "var(--container-width)",
  },
  SearchBox: {
    position: "absolute",
    top: "20px",
    right: "20%",
    zIndex: 3,
  },
  subscribeBanner: {
    marginTop: "99px",
    marginBottom: "99px",
  },
  comingsoon_Box: {
    maxHeight: "440px",
    maxWidth: "427px",
    padding: "50px 92px 50px 92px",
    boxShadow: "0 24px 34px 0 rgba(43, 38, 57, 0.05)",
    borderWidth: "2px",
    backgroundImage:
      "linear-gradient(143deg, rgba(255, 255, 255, 0.48) 7%, rgba(255, 255, 255, 0) 108%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderStyle: "solid",
    borderImageSource:
      "linear-gradient(136deg, rgba(255, 255, 255, 0)  0%, rgba(255, 255, 255, 0) 118%)",
    cursor: "pointer",
  },
  comingsoonText: {
    backgroundImage:
      "linear-gradient(78deg, var(--watermelon) 0%, var(--faded-orange) 100%)",
    backgroundClip: "text",
    fontSize: "21px !important",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
    textAlign: "center",
  },
  imageBox: {
    height: "242px",
    width: "242px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default function HomeAreaLoggedIn() {
  const dispatch = useDispatch();
  const classes = useHomeAreaStyles();
  const userData = useSelector((state: IRootState) => state.register);
  const theme = useTheme();
  const userAdditionalInfo = userData?.userAdditionalInfo;
  const accountGuid = useSelector(accountGuidSelector);
  const { currentSession } = useContext(PurchaseContext);
  const { showWelcomeModal } = useContext(FreeTrialContext);
  const isInviteEmployeeSettingSkipped =
    userAdditionalInfo?.isInviteEmployeeSettingSkipped;
  const [searchState, setSearchState] = useState(false);
  const [showInviteAlert, setShowInviteAlert] = useState(false);
  const [searchParams] = useSearchParams();
  const groupImage900 = require("../../../assets/backgrounds/group_900.webp");
  const frameImage = require("../../../assets/backgrounds/frame.webp");
  const unionRevert = require("../../../assets/graphics/union_revert.png");
  const light = require("../../../assets/graphics/highlight.png");
  const groupImage901 = require("../../../assets/backgrounds/group_901.webp");
  const ecllip_61 = require("../../../assets/graphics/ellipse_61.webp");
  const slide_1 = require("../../../assets/graphics/slide_1.png");
  const slide_2 = require("../../../assets/graphics/slide_2.png");
  const slide_3 = require("../../../assets/graphics/slide_3.png");
  const slide_4 = require("../../../assets/graphics/slide_4.png");

  const crousalBannerData = useSelector(
    (state: IRootState) => state?.crousalProduct?.data
  );

  useEffect(() => {
    dispatch({
      type: navBarTypes.NAV_BAR_HOME,
    });
    dispatch({
      type: homeActionTypes.GET_ALL_HOME_PRODUCTS,
      payload: {
        guid: accountGuid,
      },
    });

    //

    dispatch({
      type: myProductsReducerTypes.CROUSAL_PRODUCT_LIST,
      payload: {
        guid: accountGuid,
      },
    });
  }, [accountGuid]);

  useEffect(() => {
    if (
      !showWelcomeModal &&
      isInviteEmployeeSettingSkipped !== undefined &&
      !isInviteEmployeeSettingSkipped &&
      isCompanyUser(userAdditionalInfo?.profileType)
    ) {
      setShowInviteAlert(true);
    }
  }, [
    isInviteEmployeeSettingSkipped,
    userAdditionalInfo?.profileType,
    showWelcomeModal,
  ]);

  const crousalProduct = useAppSelector((state) => state?.crousalProduct?.data);

  const individualProductDetails = (index) => {
    return {
      uploadedBy: crousalProduct?.[index]?.productUploadedByName || "",
      uploadByPicture:
        crousalProduct?.[index]?.productUploadedByProfilePic || "",
      uploadedByProfession:
        crousalProduct?.[index]?.productUploadedByProfession ||
        crousalProduct?.[index]?.companyTypeName ||
        "",
      likeCount: crousalProduct?.[index]?.likeCount,
      Explore: !!crousalProduct?.[index]?.productID
        ? `${window.location.origin}/products/${cryptr.encrypt(
            crousalProduct?.[index]?.productID
          )}`
        : `${window.location.origin}/library-product/${cryptr.encrypt(
            crousalProduct?.[index]?.productLibraryId
          )}`,
      src2: crousalProduct?.[index]?.productThumbnailUploadedURL || slide_1,
    };
  };

  const array = [
    {
      src1: ecllip_61,
      src3: unionRevert,
      src4: light,
      ...individualProductDetails(0),
      title: "DC as your central fashion platform",
      subtitle:
        "Experience the benefits of a central platform with designers, product management and connection to a supplier network.",
    },
    {
      src1: ecllip_61,
      src3: unionRevert,
      src4: light,
      ...individualProductDetails(1),
      title: "Program-independent asset management",
      subtitle:
        "Digital assets and products can be managed independently of design, editing and 3D programs.",
    },
    {
      src1: ecllip_61,
      src3: unionRevert,
      src4: light,
      ...individualProductDetails(2),
      title: "Searching for a new opportunity?",
      subtitle:
        "Discover new orders from fashion companies. Design new trends to special requirements exclusively at Designers Capitol.",
    },
    {
      src1: ecllip_61,
      src3: unionRevert,
      src4: light,
      title: "Take advantage of many designers!",
      subtitle:
        "Share new ideas with designers from around the world. Orders can be placed for new designs or feedback can be obtained for new fashion trends.",
      ...individualProductDetails(3),
    },
  ];
  const groupback = require("../../../assets/backgrounds/group_800.png");
  const groupback1 = require("../../../assets/backgrounds/group_801.png");
  const groupback2 = require("../../../assets/backgrounds/group_803.png");
  const groupFor = require("../../../assets/backgrounds/GroupForYou.png");
  const bannerback = require("../../../assets/graphics/group_884.png");
  const group = require("../../../assets/graphics/Group.png");

  const comingSoon = [
    {
      // img: require("../../../assets/backgrounds/Cloud 4@3x.jpg"),
      img: require("../../../assets/backgrounds/Frame 198@2x.png"),
      title: "Cloud Service",
      path: "/comingsoon",
    },
    {
      img: require("../../../assets/backgrounds/Graduation-ca 2@3x.png"),
      title: "DC Academy",
      path: "/comingsoon",
    },
    {
      img: require("../../../assets/backgrounds/Render 1@3x.png"),
      title: "Live Rendering",
      path: "/comingsoon",
    },
  ];

  const navigate = useNavigate();

  const showSuccess =
    searchParams.get("success") && searchParams.get("session_id");

  useEffect(() => {
    const stripeAccountId = searchParams.get("stripeAccountId");

    if (showSuccess && currentSession) {
      if (currentSession?.metadata) {
        const productInCartDetails = Object.keys(currentSession?.metadata)
          .filter((key) => {
            return key.indexOf("product_") > -1;
          })
          .map((data) => JSON.parse(currentSession?.metadata[data] || "{}"));

        dispatch(
          OrdersActions.placeTheOrder({
            accountGuid,
            cartTotal: JSON.parse(currentSession?.metadata?.cartTotal || "0"),
            productInCartDetails,
            orderStatus: 1,
            stripeData: JSON.stringify({ ...currentSession, stripeAccountId }),
          })
        );
      }
    }
  }, [showSuccess, currentSession]);

  const isAccountBlocked = userData?.userAdditionalInfo?.isAccountBlocked;

  return (
    <Box>
      <Box className={classes.mainBox}>
        {showInviteAlert && (
          <InviteEmployeeAlert
            open={showInviteAlert && !isAccountBlocked}
            handleClose={() => setShowInviteAlert(false)}
          />
        )}
        {showSuccess && <OrderSuccess />}
        <img className={classes.groupImage900} src={groupImage900} />
        <img className={classes.frameImage} src={frameImage} />
        <img className={classes.groupImage901} src={groupImage901} />

        <Box className={classes.headerBox}>
          <NewHeader setSearchState={setSearchState} />
          {searchState === true && (
            <Box className={classes.SearchBox}>
              <SearchBox
                searchState={searchState}
                setSearchState={setSearchState}
              />
            </Box>
          )}
          <Box className="pagination" style={{ opacity: 0.9 }}>
            <Swiper
              spaceBetween={30}
              autoplay={{ delay: 10000 }}
              className="mySwiper"
              pagination={{ clickable: true }}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
            >
              {array.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <img
                      style={{
                        right: "25px",
                        top: "26%",
                        position: "absolute",
                      }}
                      src={unionRevert}
                    />
                    <img
                      style={{
                        position: "absolute",
                        left: "420px",
                        top: "28%",
                        filter: "blur(70px)",
                      }}
                      src={light}
                    />
                    <PreviewDesign
                      src1={item.src1}
                      src2={item.src2}
                      title={item.title}
                      subtitle={item.subtitle}
                      explore={item.Explore}
                      index={index}
                      uploadedBy={item?.uploadedBy}
                      uploadByPicture={item?.uploadByPicture}
                      uploadedByProfession={item?.uploadedByProfession}
                      likeCount={item?.likeCount}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </Box>
        </Box>
        <Box className={classes.mediaShorts}>
          <Trendings
            title={"Recent Uploads"}
            type={"recentlyUploadedProducts"}
          />
        </Box>

        <Box className={classes.mediaShorts}>
          <img
            style={{ position: "absolute", left: "0px" }}
            alt="some banner"
            src={groupback}
          />
          <img className="groupback2" alt="some banner" src={groupback2} />
          <Box
            style={{
              position: "absolute",
              right: "265px",
              margin: "12.9px 9.2px 60.1px 7.7px",
              // transform: "rotate(-41.71deg)",
            }}
          >
            <img
              style={{ margin: "12.9px 9.2px 60.1px 7.7px" }}
              alt="some banner"
              src={groupFor}
            />
          </Box>
          <Trendings title={"For You"} type={"forYouProducts"} />
        </Box>

        <Box className={classes.mediaShorts}>
          <img
            style={{
              position: "absolute",
              display: "flex",
              margin: "25% -42px",
            }}
            alt="some banner"
            src={bannerback}
          />
          <BannerSoon />
        </Box>

        <Box className={classes.mediaShorts}>
          <Trendings title={"Trendings"} type={"trendingProducts"} />
        </Box>

        <Box className={classes.mediaShorts}>
          <img style={{ position: "absolute", right: 0 }} src={groupback1} />
          <Trendings title={"Suggestions"} type={"suggestionsProducts"} />
        </Box>

        <Box
          style={{ display: "flex", borderRadius: "6px", overflow: "hidden" }}
        >
          {comingSoon?.map((item, index) => (
            <Box
              key={index}
              className={classes.comingsoon_Box}
              onClick={() => navigate(item?.path)}
            >
              <Box className={classes?.imageBox}>
                <img
                  src={item?.img}
                  style={{
                    margin: "auto",
                    maxWidth: "242px",
                    maxHeight: "242px",
                  }}
                />
              </Box>

              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "27px",
                  textAlign: "center",
                }}
              >
                {item?.title}
              </Typography>
              <Typography className={classes?.comingsoonText}>
                Coming Soon
              </Typography>
            </Box>
          ))}
        </Box>

        <Box className={classes.mediaShorts}>
          <img style={{ position: "absolute", right: 0 }} src={group} />
          <div className="bannerback"></div>
          <Banner2 />
        </Box>

        <Box className={classes.mediaShorts}>
          <Box className="mostback"></Box>
          <Trendings title={"Most Viewed"} type={"mostViewedProducts"} />
        </Box>

        <Box className={classes.mediaShorts}>
          <Trendings
            title={"Recently Viewed"}
            type={"recentlyViewedProducts"}
          />
        </Box>
        <Box className={classes.mediaShorts} marginTop={"100px"}>
          <Banner3 />
        </Box>

        <Box className={classes.mediaShorts}>
          <Box className="mediaback"></Box>
          <MediaShorts />
        </Box>

        <Box
          className={classNames(classes.subscribeBanner, classes.mediaShorts)}
        >
          <SubscribeBanner />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
