/*
 **	User name
 **	Created	5/25/2023
 **	newHeader.tsx
 */

import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTheme, makeStyles } from "@mui/styles";
import { IRootState } from "app/redux/reducers";

import { userRegisterReducerTypes } from "../../redux/reducers/userReducer";
import NotificationMenu from "./notifications";
import ColorModeSwitch from "../ColorModeSwitch";
import { DarkBackArrow, LightBackArrow } from "../Logo/logos";
import LogoComponent from "../Logo";
import { PlainButton } from "../UIFormFields/Buttons";

export const useHeaderStyles = makeStyles((theme) => ({
  arrowIcon: {
    padding: "35px 0px 35px 24px",
    cursor: "pointer",
  },
  mainIconBox: {
    display: "flex",
    flexDirection: "row",
  },
  HeaderTitle: {
    padding: "34px 0px 34px 19px",
  },
  mainIcon: {
    padding: "13px 0px 13px 24px",
    cursor: "pointer",
  },
}));

const Header = (props) => {
  const classes = useHeaderStyles();
  const theme = useTheme();
  const userData = useSelector((state: IRootState) => state.register);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const userAdditionalInfo = userData?.userAdditionalInfo;

  const handleCloseUserMenu = (key) => {
    if (key === "Upgrade") {
      navigate({ pathname: "/license" }, { replace: false });
    }

    if (key === "Logout") {
      dispatch({
        type: userRegisterReducerTypes.LOGOUT_USER,
        navigate,
      });
    }

    if (key === "Profile") {
      navigate({ pathname: "/designer-profile" }, { replace: false });
    }

    if (key === "Account Settings") {
      navigate({ pathname: "/account-settings" }, { replace: false });
    }

    if (key === "Help") {
      navigate({ pathname: "/help" }, { replace: false });
    }

    if (key === "Invoice") {
      navigate({ pathname: "/:username/invoice" }, { replace: false });
    }
  };

  const handleLogoClick = () => {
    if (
      location.pathname === "/account-settings" &&
      props?.selected?.title === "Settings"
    ) {
      return props?.actions?.moveOut();
    } else if (props?.selected?.password === true) {
      return props.actions.goBackToPassword();
    } else if (props?.selected?.profileEdit === true) {
      return props?.actions?.goBackToProfile();
    }

    return null;
  };

  const settingMenu = [
    "Upgrade",
    "Profile",
    "Account Settings",
    "Invoice",
    "Help",
    "Logout",
  ];

  const detailsFilled = props?.headerText;

  return (
    <Box className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <div className="profile-img">
          <Box className={classes.mainIconBox}>
            <Box
              onClick={handleLogoClick}
              className={
                location.pathname === "/account-settings"
                  ? classes.arrowIcon
                  : classes.mainIcon
              }
            >
              {location.pathname === "/account-settings" ? (
                theme?.palette?.mode === "light" ? (
                  <LightBackArrow />
                ) : (
                  <DarkBackArrow />
                )
              ) : (
                <LogoComponent
                  style={{
                    padding: "10px 0px 0px 10px",
                    width: "55px",
                    height: "60px",
                  }}
                  isLoggedIn={props?.headerText}
                />
              )}
            </Box>

            <span
              className={classes.HeaderTitle}
              style={{
                fontWeight: "500",
                color: theme?.palette?.mode === "light" ? "black" : "#fff",
                ...props.headerTitleStyle,
              }}
            >
              {props?.headerText ? props?.headerText : "Create Your Profile"}
            </span>
          </Box>
        </div>
        <PlainButton
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
        >
          <span className="navbar-toggler-icon" />
        </PlainButton>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navsbar">
            <li className="nav-item">
              <label>
                <ColorModeSwitch />
              </label>
            </li>
            <li style={{ cursor: "pointer" }}>
              <NotificationMenu isLoggedIn={props?.headerText} />
            </li>
            <li className="nav-item">
              <div className={"profile-user"}>
                <a className="nav-link" href="#">
                  {userAdditionalInfo?.profilePictureUrl ? (
                    <img
                      style={{
                        width: "48px",
                        height: "48px",
                        borderRadius: "50%",
                        borderColor: "#999",
                        borderStyle: "solid",
                        borderWidth: "1px",
                        backgroundColor: "#999",
                      }}
                      src={userAdditionalInfo?.profilePictureUrl}
                    />
                  ) : (
                    <i className="fa-solid fa-user"></i>
                  )}
                  <span>{userData.userInfo?.username}</span>
                  {/* {props?.headerText && ( */}
                  <i
                    className="fa-solid fa-angle-down"
                    style={{ color: "#999" }}
                  ></i>
                  {/* )} */}
                </a>
                <div className="profile">
                  <div className="user-profile-head">
                    {userAdditionalInfo?.profilePictureUrl ? (
                      <img
                        style={{ cursor: "pointer" }}
                        src={userAdditionalInfo?.profilePictureUrl}
                        alt={userData.userInfo?.username}
                      />
                    ) : (
                      <i className="fa-solid fa-user"></i>
                    )}
                    <div className="head-content">
                      <h4>{userData.userInfo?.username}</h4>
                      <p>{userAdditionalInfo?.profession}</p>
                    </div>
                  </div>

                  {settingMenu.map((setting) => (
                    <ul
                      key={setting}
                      onClick={() => handleCloseUserMenu(setting)}
                    >
                      {(detailsFilled !== undefined ||
                        (detailsFilled === undefined &&
                          setting === "Logout")) && (
                        <li>
                          {" "}
                          <span>{setting}</span>{" "}
                        </li>
                      )}
                    </ul>
                  ))}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Box>
  );
};

export default Header;
