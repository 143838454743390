import React, { useRef, useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import {
  GradientColoredButton,
  BlackButton,
} from "../../../components/UIFormFields";

import SubscribeBanner from "../newsLetter";
import NewHeader from "../../../components/NewHeader";
import { PlainButton } from "../../../components/UIFormFields/Buttons";
import SearchBox from "../searchBar";

const MainPageCss = makeStyles((theme) => ({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundImage: "linear-gradient(89deg, #ffeef3 -8%, #ddfce8 121%)",
    // width: "100% !important",
    overflow: "hidden",
  },
  NavBarBox: {
    maxWidth: "1366px",
    minHeight: "450px",
    margin: "34px 42px 100px 43px",
    backgroundImage:
      "linear-gradient(124deg, rgba(255, 255, 255, 0.48) 2%, rgba(255, 255, 255, 0) 105%)",
    borderRadius: "20px",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(60px)",
    WebkitBackdropFilter: "blur(60px)",
  },
  mainBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "row",
    padding: "0px 42px",
    maxWidth: "1366px !important",
  },
  textBox: { margin: "0 0 0 40px", maxWidth: "450px" },
  header: {
    fontSize: "61px !important",
    fontWeight: "bold !important",
    color: "#2b2639 !important",
  },
  small_header: {
    fontSize: "41px !important",
    fontWeight: "bold !important",
    color: "#2b2639 !important",
  },

  description: {
    textAlign: "left",
    fontSize: "15px !important",
    maxWidth: "100% !important",
    width: "95% !important",
  },
  description_slide_1: {
    textAlign: "justify",
    fontSize: "15px !important",
    maxWidth: "100% !important",
    width: "95% !important",
  },
  description_contactus: {
    textAlign: "center",
    fontSize: "15px !important",
    maxWidth: "100% !important",
    width: "65% !important",
  },
  description_1: {
    width: "666px !important",
    textAlign: "center",
    margin: "auto !important",
    marginBottom: "25px",
  },
  chooseUs_card: {
    width: "154px !important",
    height: "154px !important",
    backgroundImage:
      "linear-gradient(140deg, rgba(255, 255, 255, 0.48) 7%, rgba(255, 255, 255, 0) 103%) !important",
    backdropFilter: "blur(60) !important",
    boxShadow: "0 24px 34px 0 rgba(43, 38, 57, 0.05) !important",
    display: "flex !important",
    flexDirection: "column",
    alignItems: "center !important",
    padding: "23px 11px 11px !important",
    margin: "34px 24px 24px 0 !important",
    borderRadius: "20px !important",
    cursor: "pointer !important",
  },
  cardTextName: {
    fontSize: "15px !important",
    fontWeight: "bold !important",
    textAlign: "center",
    margin: "18px 0 0 !important",
  },
  comingSoon: {
    WebkitBackgroundClip: "text !important",
    WebkitTextFillColor: "transparent !important",
    backgroundImage:
      "linear-gradient(78deg, var(--watermelon) 0%, var(--faded-orange) 100%) !important",
    fontSize: "11px !important",
  },
  designerCard: {
    maxWidth: "1366px !important",
    height: "680px !important",
    margin: "auto !important",
    padding: "42px !important",
    marginTop: "100px !important",
    backgroundImage:
      "linear-gradient(121deg, rgba(255, 255, 255, 0.48) 2%, rgba(255, 255, 255, 0) 104%) !important",
    position: "relative",
    borderRadius: "20px !important",
    WebkitBackdropFilter: "blur(60px) !important",
    backdropFilter: "blur(60px) !important",
  },
  designerCard_1: { display: "none" },
  designerCard_innerCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  designerImage: {
    maxWidth: "615px !important",
    maxHeight: "549px !important",
    margin: "0 100px 0 0 !important",
  },
  designer_textBox: { width: "450px" },
  actionButton: { marginTop: "40px !important" },
  action_Button_MarketPlace: {
    marginTop: "20px !important",
    marginBottom: "30px !important",
  },
  companyImage: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    margin: "auto auto auto 100px !important",
  },
  connectWorldwide: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    width: "130%",
  },
  marketplaceCard: {
    width: "800px !important",
    minHeight: "450px",
    margin: "57px 42px 100px 43px",
    borderRadius: "20px",
    backdropFilter: "blur(60px)",
    WebkitBackdropFilter: "blur(60px)",
    position: "relative",
    padding: "20px 0 ",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  marketPlaceItemsBox: {
    display: "flex",
    flexWrap: "wrap",
    width: "807px",
    justifyContent: "center",
  },
  marketPlaceItems: {
    border: "solid 1px #2b2639",
    display: "flex",
    justifyContent: "center",
    height: "54px",
    maxWidth: "160px",
    alignItems: "center",
    borderRadius: "6px",
    padding: "10px 10px 10px",
    margin: "24px 14px 14px 8px",
    fontSize: "15px",
    color: "#2b2639",
  },
  connectUs_Card: {
    maxWidth: "1232px !important",
    minHeight: "250px !important",
    margin: "57px 0 100px 0 !important",
    borderRadius: "20px !important",
    backdropFilter: "blur(60px) !important",
    WebkitBackdropFilter: "blur(60px) !important",
    position: "relative",
    padding: "20px 0 !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
  },

  // connectUs_InnerCard: { display: "flex" },
  DesignersWorldwide_Card: {
    display: "flex",
    width: "371px !important",
    height: "148px !important",
    borderRadius: "20px !important",
    alignItems: "center",
    backdropFilter: "blur(60px) !important",
    padding: "24px 54px 24px 24px !important",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1) !important",
    backgroundImage:
      "linear-gradient(119deg, rgba(255, 255, 255, 0.48) 2%, rgba(255, 255, 255, 0) 107%) !important",
    margin: "20px 10px !important",
  },

  subscriptionBox: {
    maxWidth: "1366px",
    minHeight: "450px",
    margin: "57px 42px -30px 43px",
    borderRadius: "20px",
    backdropFilter: "blur(60px)",
    WebkitBackdropFilter: "blur(60px)",
    position: "relative",
    padding: "20px 0 ",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  SearchBox: {
    position: "absolute",
    // top: "20px",
    // right: "0%",
    top: "20px",
    right: "10%",
    zIndex: 3,
  },
  shoeBox: {
    display: "flex",
    // width: "45%",
    maxWidth: "702px",
    maxHeight: "560px",
    position: "relative",
    margin: "0 40px 70px 0",
  },
  shoe: {
    width: "615px",
    height: "549px",
    zIndex: "1",
    position: "relative",
    bottom: "-110px",
    left: "45px",
  },
  buttonBox: {
    display: "flex",
    width: "65%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
  },

  buttonDimensions: { width: "130px !important", height: "45px" },

  chooseUsBox: {
    maxWidth: "1366px",
    minHeight: "450px",
    margin: "auto",
    padding: "42px",
  },
  chooseUsItems: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "900px",
    margin: "auto",
  },
  chooseUsItems2: { display: "none" },
  planDetails_1: {
    display: "flex !important",
  },
  planDetails_2: { display: "none !important" },
  group_800_blue: { position: "absolute", right: "-15%", top: "-5%" },
  group_800_red: { position: "absolute", top: "95%", left: "-15%" },
  group_800_blue_1: { position: "relative", right: "-75%", top: "-100px" },
  group_800_blue_subscription: {
    margin: "30px 0 50px 0",
    display: "flex",
    justifyContent: "center",
  },

  frame: { position: "relative", bottom: "30px" },

  cubeDot: { position: "relative", top: "80px", left: "200px", width: 200 },
  pinkSnake: { position: "absolute", left: 0 },
  groupImage901: {
    position: "absolute",
    right: "0%",
    top: "52%",
  },
  zigzag: { position: "absolute", right: "0%", top: "20%" },
  Dots12: { position: "absolute", right: "-30px", top: "150px" },
  blueHemiSphere: {
    position: "absolute",
    width: "150px",
    top: "0%",
    right: "-35%",
  },
  viewPlan: { marginTop: "50px !important" },
  // Responsiveness
  [theme.breakpoints.down("md")]: {
    viewPlan: {
      width: "100% !important",
      margin: "5px auto auto auto !important",
    },
    SearchBox: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 3,
      width: "90vw",
      // backgroundColor: "yellow",
    },
    blueHemiSphere: { width: "100px", top: "70%", right: "-7%", zIndex: -1 },
    // Styles for small screens
    description_contactus: {
      textAlign: "justify",
      fontSize: "15px !important",
      width: "95% !important",
    },
    zigzag: { top: "40%", right: 0 },
    groupImage901: {
      right: "-30%",
      top: "75%",
      height: "350px",
    },
    pinkSnake: { left: "-150px", top: "34px" },
    group_800_blue_subscription: { display: "none !important" },
    cubeDot: { top: "80px", left: "150px" },
    frame: { position: "relative", bottom: "350px" },
    mainBox: {
      width: "100% !important",
      flexDirection: "column",
      padding: "0px",
    },
    textBox: { margin: "5px !important" },

    designerCard: {
      display: "none",
    },
    designerCard_1: {
      display: "flex",
      flexDirection: "column",
      width: "100% !important",
    },
    designerCard_companies: {
      display: "flex",
      flexDirection: "column",
      width: "90% !important",
      //  maxWidth: "1366px !important",
      backgroundImage:
        "linear-gradient(121deg, rgba(255, 255, 255, 0.48) 2%, rgba(255, 255, 255, 0) 104%) !important",
      // position: "relative",
      borderRadius: "20px !important",
      WebkitBackdropFilter: "blur(60px) !important",
      backdropFilter: "blur(60px) !important",
      // height: "700px !important",
    },
    designerCard_innerCard: {
      flexDirection: "column",
      height: "100% !important",
    },
    designerImage: {
      maxWidth: "100% !important",
      maxHeight: "300px",
      margin: "5px auto",
    },
    designer_textBox: {
      width: "100% !important",
      margin: "auto",
    },
    header: {
      fontSize: "26px !important",
      fontWeight: "bold !important",
      color: "#2b2639 !important",
      textAlign: "center",
      margin: "0 auto 20px !important",
    },

    small_header: {
      fontSize: "26px !important",
      fontWeight: "bold !important",
      color: "#2b2639 !important",
      textAlign: "center",
    },

    actionButton: { width: "90% !important" },
    action_Button_MarketPlace: { width: "100% !important" },

    description: {
      textAlign: "justify !important",
      margin: "15px auto auto!important",
    },
    description_1: {
      textAlign: "justify",
      width: "340px !important",
    },
    companyImage: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      margin: "15px auto 0px !important",
    },
    connectWorldwide: { display: "none" },
    connectUs_Card: {
      width: "90% !important",
      margin: "30px 0 30px 0 !important",
      overflow: "initial",
    },
    // connectUs_InnerCard: {
    //   display: "flex",
    //   width: "90% !important",
    //   background: "yellow",
    // },
    subscriptionBox: {
      width: "90vw !important",
      alignItems: "initial",
      padding: "0px !important",
      margin: "auto !important",
    },

    marketplaceCard: {
      width: "90% !important",
      padding: "0px !important",
      // backgroundColor: "red",
    },
    marketPlaceItemsBox: { width: "100%", marginTop: "30px !important" },
    marketPlaceItems: {
      width: "45%",
      margin: "5px",
      fontSize: "13px !important",
      padding: "0px !important",
    },
    shoeBox: {
      width: "100% !important",
      margin: "20% auto auto !important",
      // backgroundColor: "blue",
    },
    shoe: {
      width: "100% !important",
      height: "360px",
    },
    DCShoeLogo: { width: "100% !important", height: "360px" },
    shoeDotBox: {
      position: "relative !important",
      top: "-100px !important",
      left: "-70px !important",
    },
    buttonBox: {
      justifyContent: "space-between",
      width: "100% !important",
    },
    buttonDimensions: {
      width: "50% !important",
      margin: "auto 5px !important",
      height: "39px",
    },
    chooseUsBox: { width: "100% !important ", padding: "0px !important" },
    chooseUsItems: { display: "none !important" },
    chooseUsItems2: { display: "initial" },
    chooseUs_card: {
      margin: "20px 5px 40px 5px !important",
    },
    planDetails_1: { display: "none !important" },
    planDetails_2: { display: "initial !important", width: "90vw !important" },
    NavBarBox: {
      width: "90% !important",
      margin: "34px auto 34px auto",
    },
    group_800_blue: { display: "none" },
    group_800_red: { display: "none" },
    group_800_blue_1: { position: "relative", right: "50%", top: "-25px" },
    Dots12: { position: "absolute", right: "-94px", top: "110px" },
  },
}));

export default function mainPage() {
  const Designers = require("../../../../assets/backgrounds/Designers 3D@3x.webp");
  const company = require("../../../../assets/backgrounds/Company-2 1@3x.png");
  const shoe = require("../../../../assets/backgrounds/Shoes 3D@3x.png");
  const group_800_red = require("../../../../assets/backgrounds/group_800.png");
  const group_800_blue = require("../../../../assets/backgrounds/group_801.png");
  const blueStripe = require("../../../../assets/backgrounds/blueStripe.png");
  const GroupForYou = require("../../../../assets/backgrounds/GroupForYou.png");
  const green_hemisphere = require("../../../../assets/backgrounds/green_hemisphere.png");
  const blue_hemisphere = require("../../../../assets/backgrounds/blue_hemisphere.png");
  const classes = MainPageCss();
  const {
    ReactComponent: Cube,
  } = require("../../../../assets/backgrounds/Group.svg");
  //c:\Users\admin\Downloads\pinkCircle.svg

  const {
    ReactComponent: PinkCircle,
  } = require("../../../../assets/backgrounds/pinkCircle.svg");
  const {
    ReactComponent: PurpleCube,
  } = require("../../../../assets/backgrounds/purpleCube.svg");
  const {
    ReactComponent: BrownCircle,
  } = require("../../../../assets/backgrounds/BrownCircle.svg");

  //

  const {
    ReactComponent: Dots,
  } = require("../../../../assets/backgrounds/dots.svg");
  const {
    ReactComponent: Frame,
  } = require("../../../../assets/backgrounds/Frame.svg");
  const {
    ReactComponent: DLogoSVG,
  } = require("../../../../assets/backgrounds/D_logo.svg");
  const {
    ReactComponent: Dots12,
  } = require("../../../../assets/backgrounds/3X4_Dots.svg");

  const {
    ReactComponent: DoubleZigZag,
  } = require("../../../../assets/backgrounds/OBJECTS.svg");

  const chooseUs = [
    {
      picture: require("../../../../assets/backgrounds/Cloud 4@3x.jpg"),
      name: "Cloud Services",
      status: 1,
      id: 0,
      path: "/comingsoon",
    },
    {
      picture: require("../../../../assets/backgrounds/Exchange 3@3x.png"),
      name: "Creativity Exchange",
      status: 0,
      id: 1,
      path: "/products",
    },
    {
      picture: require("../../../../assets/backgrounds/Fashion-Library 3@3x.png"),
      name: "DC Academy",
      status: 1,
      id: 2,
      path: "/comingsoon",
    },
    {
      picture: require("../../../../assets/backgrounds/Graduation-ca 2@3x.png"),
      name: "Fashion Library",
      status: 0,
      id: 3,
      path: "/library",
    },
    {
      picture: require("../../../../assets/backgrounds/Render 1@3x.png"),
      name: "Live Rendering",
      status: 1,
      id: 4,
      path: "/comingsoon",
    },
    {
      picture: require("../../../../assets/backgrounds/Marketplace.png"),
      name: "Marketplace for Designs",
      status: 0,
      id: 5,
      path: "/products",
    },
    {
      picture: require("../../../../assets/backgrounds/Portfolio.png"),
      name: "Portfolio Creation",
      status: 0,
      id: 6,
      path: "/login",
    },
    {
      picture: require("../../../../assets/backgrounds/Jobs.png"),
      name: "Jobs",
      status: 0,
      id: 7,
      path: "/jobs",
    },
    {
      picture: require("../../../../assets/backgrounds/Teams.png"),
      name: "Create your Team",
      status: 0,
      id: 8,
      path: "/login",
    },
  ];
  const [individualPlan, setPlanStatus] = useState(true);
  const [searchState, setSearchState] = useState(false);

  const DesignersWorldwide = [
    {
      profilePic: require("../../../../assets/users/Addison@3x.png"),
      name: "Addison",
      profile: "UX/UI Designer",
    },
    {
      profilePic: require("../../../../assets/users/Wylon Molyte@3x.png"),
      name: "Wylon Molyte",
      profile: "Brand Designer",
    },
    {
      profilePic: require("../../../../assets/users/John Marley@3x.png"),
      name: "John Marley",
      profile: "3D Artist",
    },
    {
      profilePic: require("../../../../assets/users/Galvyn Steven@3x.png"),
      name: "Sacrlett",
      profile: "UX/UI Designer",
    },
    {
      profilePic: require("../../../../assets/users/Daisy Mathew@3x.png"),
      name: "Lisa",
      profile: "Brand Designer & Illustrator",
    },
    {
      profilePic: require("../../../../assets/users/Adnil@3x.png"),
      name: "Adnil",
      profile: "3D Artist & Animator",
    },
    {
      profilePic: require("../../../../assets/users/Lilly@3x.png"),
      name: "Lilly Mathew",
      profile: "Brand Designer & Illustrator",
    },
  ];

  const subscriptionIndividual = [
    {
      type: "Basic",
      price: "0.00 €",
      details:
        "Get started with the website and share your first designs with your own portfolio or apply for a project.",
    },
    {
      type: "Advanced",
      price: "24.95 €",
      details:
        "Be a fully trusted member of the DC community and start your career as a freelancer in the fashion industry.",
    },
    {
      type: "Professional",
      price: "49.95 €",
      details:
        "Benefit from all features DC can offer for your business. Simplify your workflow and set no limits to your creativity.",
    },
    {
      type: "Student",
      price: "0.00 €",
      details:
        "Check out the fashion industry! Become a member of the young talent program and establish first contacts.",
    },
  ];

  const subscriptionCompany = [
    {
      type: "Basic",
      price: "199.95 €",
      details:
        "Start on DC with your business. Let your designers better connect with each other and increase your productivity.",
    },
    {
      type: "Advanced",
      price: "299.95 €",
      details:
        "Endless possibilities await you and your team. Expand your staff with qualified designers and be a trusted partner of DC.",
    },
    {
      type: "Professional",
      price: "499.95 €",
      details:
        "Give your designers more opportunities to unleash their creativity and manage execution processes with more efficiency.",
    },
  ];
  const AllProducts = [
    "T-shirts & Polo",
    "Jackets",
    "Jeans",
    "Trousers",
    "Dresses",
    "Shirts",
    "Watches",
    "Boots",
    "Swimwear",
    "Bagpacks",
    "Skirts",
    "Coats",
    "Others",
  ];

  const AllibraryProducts = [
    "Avatars",
    "Pattern",
    "Accessories",
    "Hardware & Trims",
    "Artwork",
    "Fabrics",
    "Labelling",
    "Puckering",
    "Topstitch",
    "Washing texture",
    "Others",
  ];
  const {
    ReactComponent: ProfileUserDark,
  } = require("../../../../assets/icons/ProfileUserDark.svg");
  const {
    ReactComponent: ProfileUserLight,
  } = require("../../../../assets/icons/ProfileUserLight.svg");

  const {
    ReactComponent: DarkCompany,
  } = require("../../../../assets/icons/DarkCompany.svg");

  const {
    ReactComponent: LightCompany,
  } = require("../../../../assets/icons/LightCompany.svg");

  const PinkSnake = require("../../../../assets/backgrounds/group_900.webp");
  const groupImage901 = require("../../../../assets/backgrounds/group_901.webp");
  const frameImage = require("../../../../assets/backgrounds/frame.webp");
  const DoubleZigZagFile = require("../../../../assets/backgrounds/DoubleZigZagFile.png");

  //

  const navigate = useNavigate();
  // const useMountEffect = (fun) => React.useEffect(fun, []);
  const myRef = useRef(null);
  const scrollToPlans = () => myRef.current.scrollIntoView();
  // useMountEffect(scrollToPlans);

  const selectItems = (index) => {
    return chooseUs[index];
  };

  const chooseUsItemsList = (item, index) => {
    return (
      <Box
        key={index}
        className={classes?.chooseUs_card}
        onClick={() => navigate(item?.path)}
      >
        <img
          src={item?.picture}
          alt={item?.name}
          style={{ minWidth: "84px", height: "60px" }}
        />
        <Typography className={classes?.cardTextName}>{item?.name}</Typography>
        <Typography className={classes?.comingSoon}>
          {item?.status === 1 && "Coming Soon"}
        </Typography>
      </Box>
    );
  };

  return (
    <Box className={classes?.page}>
      {/* shoe Slide */}
      <Box style={{ position: "relative" }}>
        <img src={PinkSnake} className={classes.pinkSnake} />
        <img src={frameImage} className={classes.zigzag} />
        <img src={groupImage901} className={classes.groupImage901} /> {/* DC */}
        <Box className={classes?.NavBarBox}>
          <NewHeader setSearchState={setSearchState} />
          {searchState === true && (
            <Box className={classes.SearchBox}>
              <SearchBox
                searchState={searchState}
                setSearchState={setSearchState}
              />
            </Box>
          )}
          <Box className={classes?.mainBox}>
            {/* text Box*/}
            <Box className={classes?.textBox}>
              <Box>
                <Typography className={classes?.header}>
                  What exactly is DC?
                </Typography>
                <Typography className={classes?.description}>
                  At Designers Capitol, we want to be the platform around 3D
                  fashion. A central point of contact for designers and
                  companies looking for inspiration, support for projects and
                  much more. Learn more about us and our features!
                </Typography>
              </Box>

              <Box className={classes.buttonBox}>
                <GradientColoredButton
                  className={classes.buttonDimensions}
                  onClick={() => navigate("/registration")}
                >
                  Join for free
                </GradientColoredButton>
                <BlackButton
                  className={classes.buttonDimensions}
                  onClick={scrollToPlans}
                >
                  See Plans
                </BlackButton>
              </Box>
            </Box>
            {/* Shoes pic */}
            <Box className={classes.shoeBox}>
              {" "}
              <DLogoSVG
                className={classes.DCShoeLogo}
                style={{ position: "absolute" }}
              />
              <Box className={classes.shoeDotBox}>
                <Dots12 className={classes.Dots12} />
                <img src={shoe} alt={Designers} className={classes.shoe} />
              </Box>
            </Box>
          </Box>
          {/* <Box style={{ display: "flex", justifyContent: "center" }}>
            {[1, 2, 3, 4, 5, 6].map((item, index) => (
              <Box
                key={index}
                style={{
                  border: "2px solid #a49bbe ",
                  borderRadius: "50%",
                  width: "16px",
                  height: "16px",
                  backgroundColor: index === 3 && "#2b2639",
                  margin: "0 5px 20px 0",
                }}
              ></Box>
            ))}
          </Box> */}
        </Box>
      </Box>
      {/* Shoe Box End here */}

      {/* Why choose Us */}
      <Box
        style={{
          position: "relative",
        }}
      >
        <img src={group_800_blue} className={classes.group_800_blue} />

        <Box className={classes.chooseUsBox}>
          <Box>
            <Typography
              style={{
                fontSize: "41px",
                fontWeight: "bold",
                textAlign: "center",
                margin: "auto",
                marginBottom: "16px",
                width: "100%",
              }}
              className={classes?.small_header}
            >
              Why You Choose Us?
            </Typography>
            <Typography className={classes.description_1}>
              Our goal is to support both designers and companies in the fashion
              industry in the best possible  way. For this we are committed
              every day and want to constantly develop further in addition to
              the features listed below!
            </Typography>
          </Box>
          {/* for large screen */}
          <Box className={classes.chooseUsItems}>
            {chooseUs?.map((item, index) => chooseUsItemsList(item, index))}
          </Box>
          {/* for iPhone8 Screen */}
          <Box className={classes.chooseUsItems2}>
            <Swiper
              spaceBetween={300}
              autoplay={{ delay: 1000 }}
              className="mySwiper"
              pagination={{ clickable: true }}
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
            >
              {chooseUs?.map(
                (item, index) =>
                  !(index % 2) && (
                    <SwiperSlide
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "10px",
                      }}
                    >
                      {chooseUsItemsList(selectItems(index), index)}
                      {chooseUs?.length - 1 !== index &&
                        chooseUsItemsList(selectItems(index + 1), index + 1)}
                    </SwiperSlide>
                  )
              )}{" "}
            </Swiper>{" "}
          </Box>
        </Box>

        <img className={classes.group_800_red} src={group_800_red} />
      </Box>

      {/* whats in for Designers */}
      {/* webView*/}
      <Box style={{ position: "relative" }}>
        {" "}
        <PinkCircle
          className="d-none d-md-block"
          style={{ position: "absolute", right: "-25px", top: "-25%" }}
        />
        <Box className={classes?.designerCard}>
          <Box className={classes?.designerCard_innerCard}>
            <img
              src={Designers}
              alt={Designers}
              className={classes.designerImage}
            />

            <Box>
              <Box className={classes.designer_textBox}>
                <Box className={classes.frame}>
                  <Frame />
                </Box>
                <Typography className={classes?.header}>
                  What’s in for Designers?
                </Typography>
                <Typography className={classes?.description}>
                  We offer comprehensive support for aspiring designers. A
                  program independent asset management tool or access to the
                  fashion library are just two of many ways to advance your
                  career. Learn more about benefits we can offer you!{" "}
                  <span
                    style={{ color: "#7a48ff" }}
                    onClick={() => navigate("/designer-page")}
                  >
                    {" "}
                    Explore Designer’s Features
                  </span>
                </Typography>
              </Box>
              <GradientColoredButton
                className={classes.actionButton}
                onClick={() => navigate("/designers")}
              >
                Explore Designers
              </GradientColoredButton>
              <Box
                className={classes.cubeDot}
                style={
                  {
                    // position: "relative",
                    // top: "80px",
                    // left: "200px",
                    // width: 200,
                  }
                }
              >
                <Cube
                  style={{
                    position: "relative",
                    left: "160px",
                    bottom: "80px",
                  }}
                />
                <Dots
                  style={{
                    position: "relative",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>{" "}
      </Box>
      {/* format 2 */}
      <Box className={classes?.designerCard_1}>
        <Box
          className={classes?.designerCard_innerCard}
          style={{
            position: "relative",
            width: "90%",
            margin: "auto",
          }}
        >
          <Dots12
            style={{
              position: "relative",
              top: "0%",
              left: "-30%",
              margin: "20px",
            }}
          />

          <Box className={classes.designer_textBox}>
            <Typography
              className={classes?.small_header}
              style={{ width: "70%", margin: "auto" }}
            >
              What’s in for Designers?
            </Typography>
            <Typography className={classes?.description}>
              We offer comprehensive support for aspiring designers. A program
              independent asset management tool or access to the fashion library
              are just two of many ways to advance your career. Learn more about
              benefits we can offer you!{" "}
              <span
                style={{ color: "#7a48ff" }}
                onClick={() => navigate("/designer-page")}
              >
                {" "}
                Explore Designer’s Features
              </span>
            </Typography>
          </Box>
          <img
            src={Designers}
            alt={Designers}
            style={{
              maxWidth: "90%",
              maxHeight: "50%",

              margin: "auto",
            }}
          />
          <GradientColoredButton
            className={classes.actionButton}
            onClick={() => navigate("/designers")}
          >
            Explore Designers
          </GradientColoredButton>

          <Box
            style={{
              position: "relative",

              left: "70px",
              width: 200,
              marginTop: "100px",
            }}
          >
            <Cube
              style={{
                position: "relative",
                left: "160px",
                bottom: "80px",
              }}
            />
            <Dots
              style={{
                position: "relative",
              }}
            />
          </Box>
        </Box>
      </Box>

      {/* whats in for companies */}
      {/* webview */}
      {/* format 1 */}
      <Box style={{ position: "relative" }}>
        <Box
          className="d-flex justify-between position-absolute  w-100"
          style={{ top: "55%" }}
        >
          <BrownCircle className="position-relative" style={{ left: "-5%" }} />
          <PurpleCube
            className="position-relative"
            style={{ left: "75%", top: "5%" }}
          />
        </Box>
        <Box className={classes?.designerCard}>
          <Box
            className={classes?.designerCard_innerCard}
            style={{
              height: "100%",
            }}
          >
            <Box className={classes.designer_textBox}>
              <Box>
                <Box
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={blueStripe}
                    style={{
                      position: "absolute",
                      right: "-50px",
                      top: "-80px",
                    }}
                  />
                  <Dots12
                    className="position-absolute"
                    style={{ bottom: "125%" }}
                  />

                  <Typography className={classes?.header}>
                    What's in for Companies?
                  </Typography>
                </Box>
                <Typography className={classes?.description}>
                  Connect and centralize all workflows with your collaborators
                  via the project folder and communicate easily and directly
                  with potential freelancers for your jobs. This and much more
                  Designers Capitol can do for your company.
                  <span
                    style={{ color: "#7a48ff" }}
                    onClick={() => navigate("/company-page")}
                  >
                    {" "}
                    Explore Company’s Features
                  </span>
                </Typography>
              </Box>
              <GradientColoredButton
                className={classes.actionButton}
                onClick={() => navigate("/companies")}
              >
                Explore Companies
              </GradientColoredButton>
            </Box>

            <Box
              className={classes.companyImage}
              style={{
                display: "flex",
                height: "100%",
              }}
            >
              <img
                src={company}
                alt={Designers}
                style={{ margin: "auto", width: "600px", height: "550px" }}
              />
              <DoubleZigZag
                style={{
                  position: "relative",

                  left: "-40px",
                  top: "10px",
                }}
              />
            </Box>
          </Box>
        </Box>{" "}
      </Box>
      {/* format 2 */}
      <Box
        className="d-intial d-md-none"
        style={{
          position: "relative",
          display: "flex",
          // backgroundColor: "red",
        }}
      >
        <img src={group_800_red} style={{ position: "absolute", top: "5%" }} />
        <img
          src={GroupForYou}
          style={{
            position: "absolute",
            width: "400px",
            left: "20%",
            top: "-5%",
          }}
        />
        <img
          src={green_hemisphere}
          style={{
            position: "absolute",
            width: "300px",
            left: "-20%",
            top: "70%",
          }}
        />
        {/*  */}
        <Box
          className={classes?.designerCard_companies}
          style={{ margin: "auto" }}
        >
          <Box className={classes?.designerCard_innerCard}>
            <Box className={classes.designer_textBox}>
              <Box>
                <Dots12
                  style={{
                    position: "relative",
                    margin: "80px 40px 40px 0 ",
                  }}
                />

                <Box
                  style={{
                    position: "relative",
                  }}
                >
                  <img
                    src={blueStripe}
                    style={{
                      position: "absolute",
                      right: "25px",
                      top: "-40px",
                      width: "54px",
                      height: "54px",
                    }}
                  />
                  <Typography
                    className={classes?.header}
                    style={{ width: "50%" }}
                  >
                    What's in for Companies?
                  </Typography>
                </Box>
                <Typography className={classes?.description}>
                  Connect and centralize all workflows with your collaborators
                  via the project folder and communicate easily and directly
                  with potential freelancers for your jobs. This and much more
                  Designers Capitol can do for your company.
                  <span
                    style={{ color: "#7a48ff" }}
                    onClick={() => navigate("/company-page")}
                  >
                    {" "}
                    Explore Company’s Features
                  </span>
                </Typography>
              </Box>
            </Box>
            <Box className={classes.companyImage}>
              <img
                src={company}
                alt={Designers}
                style={{
                  maxWidth: "350px",
                  maxHeight: "350px",

                  margin: "auto",
                }}
              />
            </Box>

            <GradientColoredButton
              className={classes.actionButton}
              onClick={() => navigate("/companies")}
            >
              Explore Companies
            </GradientColoredButton>
            <img
              src={DoubleZigZagFile}
              style={{ width: "115px", height: "35px", left: "32%" }}
              className="m-4 position-relative"
            />
          </Box>
          {/* </Box> */}
        </Box>
      </Box>

      {/* Designers Worlwide */}

      <Box
        className={`${classes?.connectUs_Card} position-relative position-md-initial`}
      >
        <Box
          className="d-flex d-md-none  position-absolute  w-100"
          style={{ zIndex: -1 }}
        >
          <BrownCircle
            className="position-relative"
            style={{ left: "-10%", width: "120px", top: "-10%" }}
          />
          <PurpleCube
            className="position-relative"
            style={{ left: "26%", top: "45%", width: "150px" }}
          />
        </Box>
        <Typography className={classes.small_header}>
          {" "}
          Connect with Designers Worldwide{" "}
        </Typography>

        <Typography className={classes.description_contactus}>
          Find matching designers for your project, create and post job ads, and
          collaborate with design talents worldwide. On Designers Capitol you
          can find the right freelancers and future employees for all your
          projects.
        </Typography>

        <Box className={classes.connectWorldwide}>
          {DesignersWorldwide?.map((item, index) => (
            <Box
              key={index}
              className={classes?.DesignersWorldwide_Card}
              style={{}}
            >
              <Box
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  display: "flex",
                  margin: "0 5px 0 0",
                }}
              >
                <img src={item?.profilePic} />
              </Box>

              <Box>
                <Typography
                  style={{
                    fontSize: "21px",
                    fontWeight: "bold",
                    color: "#2b2639",
                    margin: "24px 0 0 16px",
                    width: "148px",
                  }}
                >
                  {item?.name}
                </Typography>
                <Typography
                  style={{
                    fontSize: "13px",
                    margin: "0px 80px 24px 16px",
                    color: "#a49bbe",
                    width: "177px",
                  }}
                >
                  {item?.profile}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <GradientColoredButton
          // style={{ margin: "30px" }}
          className={classes.actionButton}
          style={{ margin: "auto" }}
          onClick={() => navigate("/jobs")}
        >
          Explore Jobs
        </GradientColoredButton>
      </Box>

      {/* Subscription */}
      <Box className="position-relative">
        <Box
          className={classes?.subscriptionBox}
          ref={myRef}
          // style={{ backgroundColor: "yellow" }}
        >
          <Typography
            // style={{ fontSize: "41px", fontWeight: "bold", color: "#2b2639" }}
            className={classes.header}
          >
            Our Subscription Plans
          </Typography>
          <img
            src={group_800_blue}
            style={{ position: "absolute", right: "100%", top: "20%" }}
            className={classes.group_800_blue_subscription}
          />
          <nav>
            <div
              // className="nav nav-tabs tabs"
              className="nav tabs"
              id="nav-tab"
              role="tablist"
              style={{
                margin: "30px 0 50px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PlainButton
                style={{
                  textTransform: "revert",
                  width: "149px",
                }}
                className={classNames("nav-link", {
                  active: individualPlan,
                })}
                data-bs-toggle="tab"
                data-bs-target="#nav-home"
                type="button"
                onClick={() => setPlanStatus(true)}
                role="tab"
                aria-controls="nav-home"
                disabled={
                  location.pathname === "/account-settings" ? true : false
                }
              >
                {individualPlan ? <ProfileUserDark /> : <ProfileUserLight />}
                Individual
              </PlainButton>

              <PlainButton
                style={{
                  textTransform: "revert",
                  width: "149px",
                }}
                className={classNames("nav-link", {
                  active: !individualPlan,
                })}
                onClick={() => setPlanStatus(false)}
                data-bs-toggle="tab"
                data-bs-target="#nav-profile"
                type="button"
                role="tab"
                aria-controls="nav-profile"
              >
                {individualPlan ? <LightCompany /> : <DarkCompany />}
                Company
              </PlainButton>
            </div>
          </nav>
          <Box className={classes.planDetails_1}>
            {(individualPlan
              ? subscriptionIndividual
              : subscriptionCompany
            )?.map((item, index) => (
              <Box
                key={index}
                style={{
                  width: "331px",
                  height: "327px",
                  padding: "65px 38px 41px",
                  backdropFilter: "blur(60)",
                  border: "solid 1px #ffffff",
                  backgroundImage:
                    "linear-gradient(138deg, rgba(255, 255, 255, 0.76) -6%, rgba(255, 255, 255, 0) 111%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  style={{
                    fontSize: "21px",
                    fontWeight: "bold",
                    color: "2#b2639",
                  }}
                >
                  {item?.type}
                </Typography>
                <Box
                  style={{
                    borderBottom: "2px solid",
                    width: "60px",
                    margin: "16px 0",
                  }}
                />
                <Typography
                  style={{
                    backgroundImage:
                      "linear-gradient(72deg, var(--watermelon) 0%, var(--faded-orange) 99%)",
                    fontSize: "31px",
                    fontWeight: "bold",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  {item?.price}
                </Typography>
                <Typography
                  style={{
                    textAlign: "center",
                    margin: "16px 0",
                    fontSize: "15px",
                    width: "215px",
                    color: "#2b2639",
                  }}
                >
                  {item?.details}
                </Typography>
              </Box>
            ))}
          </Box>
          <Box className={classes.planDetails_2}>
            <Box className="pagination">
              <Swiper
                spaceBetween={300}
                autoplay={{ delay: 1000 }}
                className="mySwiper"
                pagination={{ clickable: true }}
                modules={[Pagination, Autoplay]}
                slidesPerView={1}
              >
                {(individualPlan
                  ? subscriptionIndividual
                  : subscriptionCompany
                )?.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <Box
                      key={index}
                      style={{
                        width: "331px",
                        height: "300px",
                        padding: "65px 38px 41px",
                        backdropFilter: "blur(60)",
                        border: "solid 1px #ffffff",
                        backgroundImage:
                          "linear-gradient(138deg, rgba(255, 255, 255, 0.76) -6%, rgba(255, 255, 255, 0) 111%)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "21px",
                          fontWeight: "bold",
                          color: "2#b2639",
                        }}
                      >
                        {item?.type}
                      </Typography>
                      <Box
                        style={{
                          borderBottom: "2px solid",
                          width: "60px",
                          margin: "16px 0",
                        }}
                      />
                      <Typography
                        style={{
                          backgroundImage:
                            "linear-gradient(72deg, var(--watermelon) 0%, var(--faded-orange) 99%)",
                          fontSize: "31px",
                          fontWeight: "bold",
                          backgroundClip: "text",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        }}
                      >
                        {item?.price}
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          margin: "16px 0",
                          fontSize: "15px",
                          width: "215px",
                          color: "#2b2639",
                        }}
                      >
                        {item?.details}
                      </Typography>
                    </Box>
                  </SwiperSlide>
                ))}{" "}
              </Swiper>{" "}
            </Box>
          </Box>
          {/* <img src={group_800_red} /> */}
          <GradientColoredButton
            // style={{ marginTop: "50px", width: "90%" }}
            // onClick={scrollToPlans}
            className={classes.viewPlan}
            onClick={() => navigate("/login")}
          >
            View Plans
          </GradientColoredButton>
        </Box>{" "}
        {/* <img
          src={group_800_red}
          style={{ position: "relative", right: "-100%", top: "4%" }}
          className="d-none d-md-block"
        /> */}
      </Box>

      {/* Marketplace  */}
      <Box className="position-relative">
        <Box
          className={classes.marketplaceCard}
          style={{ margin: "80px auto" }}
        >
          <Box className={classes.group_800_blue_1}>
            <img src={group_800_blue} />
          </Box>
          <img src={blue_hemisphere} className={classes.blueHemiSphere} />
          <Typography className={classes.small_header}>
            Marketplace for Digital Fashion Assets
          </Typography>
          <Typography
            style={{
              textAlign: "center",
            }}
            className={classes?.description}
          >
            Easily find new designs for your collection. Whether it's ideas,
            sketches or production-ready digital assets, you'll find it all on
            Designers Capitol. Buy and sell digital assets. We are the capitol
            for fashion design.
          </Typography>
          <Box className={classes.marketPlaceItemsBox}>
            {AllProducts?.map((item, index) => (
              <Box key={index} className={classes.marketPlaceItems}>
                {item}
              </Box>
            ))}
          </Box>
          <GradientColoredButton
            className={classes.action_Button_MarketPlace}
            onClick={() => navigate("/products")}
          >
            View All Products
          </GradientColoredButton>

          <Box className={classes.marketPlaceItemsBox}>
            {AllibraryProducts?.map((item, index) => (
              <Box key={index} className={classes.marketPlaceItems}>
                {item}
              </Box>
            ))}
          </Box>
          <GradientColoredButton
            className={classes.action_Button_MarketPlace}
            onClick={() => navigate("/library")}
          >
            View All Library Products
          </GradientColoredButton>
        </Box>
      </Box>
      <Box style={{ marginBottom: "50px" }}>
        <SubscribeBanner />
      </Box>
    </Box>
  );
}
