import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import { LightModeCross, NightModeCross } from "app/components/Logo/logos";
import { makeStyles, useTheme } from "@mui/styles";
import { BlackBorderedButton, PlainButton } from "app/components/UIFormFields";
import { useNavigate } from "react-router";
import LogoComponent from "../../../components/Logo";

const GuestUserCss = makeStyles((theme) => ({
  mainBox: {
    maxHeight: "566px",
    maxWidth: "455px",
    backgroundColor: "#ffffff",
    margin: "10% auto",
    borderRadius: "20px",
    boxShadow: "0 24px 44px 0 rgba(43, 38, 57, 0.4)",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  DC_logo: {
    width: "74px",
    height: "74px",
    margin: "70px auto 0px auto",
    objectFit: "contain",
  },
  Header: {
    textAlign: "center",
    fontSize: "27px !important",
    fontWeight: "bold !important",
    margin: "24px 3px 6px 0",
  },
  subHeader: {
    textAlign: "center",
    fontSize: "15px",
    margin: "6px 9px 34px 36px",
  },
  divider: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "80%",
    margin: "auto",
  },
  [theme.breakpoints.down("md")]: {
    mainBox: {
      width: "90%",
      margin: "10% auto !important",
    },
  },
}));

export default function GuestUserPopUp({ onClose }) {
  const theme = useTheme();
  const classes = GuestUserCss();
  const navigate = useNavigate();

  const {
    ReactComponent: FacebookBtnLogo,
  } = require("../../../../assets/icons/Icons_Light_Facebook Icon.svg");
  const {
    ReactComponent: GoogleBtnLogo,
  } = require("../../../../assets/icons/Icons_Light_Google Icon.svg");
  const DC_logo = require("../../../../assets/icons/new_logo.png");

  return (
    <>
      <Modal open={true}>
        <Box className={classes.mainBox}>
          <Box style={{ position: "absolute", right: 24, top: 24 }}>
            {theme?.palette?.mode === "light" ? (
              <LightModeCross onClick={onClose} style={{ cursor: "pointer" }} />
            ) : (
              <NightModeCross onClick={onClose} style={{ cursor: "pointer" }} />
            )}
          </Box>
          {/* <img src={DC_logo} className={classes.DC_logo} /> */}
          <div style={{ margin: "auto", marginTop: "2rem" }}>
            <LogoComponent
              style={{
                width: 94,
                height: 94,
              }}
              isLoginStyle={false}
              // onClick={() => navigate("/")}
              isLoggedIn={undefined}
            />
          </div>
          <Typography className={classes.Header}>
            Join Designers Capitol
          </Typography>
          <Typography className={classes.subHeader}>
            Your Ultimate fashion destination
          </Typography>
          <BlackBorderedButton
            style={{ width: "304px", margin: "34px auto" }}
            onClick={() => navigate("/registration")}
          >
            Continue with email
          </BlackBorderedButton>{" "}
          <Box className={classes.divider}>
            <div style={{ float: "left", width: "44%" }}>
              <hr />
            </div>
            <span style={{ fontSize: "15px", margin: "20px" }}>OR</span>
            <div style={{ float: "right", width: "44%" }}>
              <hr />
            </div>
          </Box>
          <Box className="social-btn" style={{ marginTop: "0px" }}>
            <PlainButton
              disableElevation
              disableTouchRipple
              className="facebookBtn"
              style={{
                borderRadius: "6px",
                background: "#3b5998",
                textTransform: "none",
                width: "150px",
                height: "54px",
                // margin: "10px 16px 10px 16px ",
                cursor: "initial",
              }}
              variant="contained"
              onClick={() => {}}
            >
              <FacebookBtnLogo
                style={{
                  background: "transparent",
                  marginRight: "5px",
                }}
              />
              <Typography fontSize="15px"> Facebook</Typography>
            </PlainButton>
            <PlainButton
              // disableElevation
              // disableTouchRipple

              className="googleBtn"
              style={{
                borderRadius: "6px",
                background: "#d84938",
                textTransform: "none",
                width: "130px",
                height: "54px",
                // margin: "10px 16px 10px 16px ",
                cursor: "initial",
              }}
              variant="contained"
              onClick={() => {}}
            >
              <GoogleBtnLogo
                style={{
                  background: "transparent",
                  marginRight: "5px",
                }}
              />
              <Typography fontSize="15px"> Google</Typography>
            </PlainButton>
          </Box>
          <Typography style={{ textAlign: "center", margin: "20px" }}>
            Already have an account?{" "}
            <span
              style={{
                color: "#7a48ff",
                fontWeight: "bold",
                cursor: "pointer",
              }}
              onClick={() => navigate("/login")}
            >
              Sign In
            </span>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}
