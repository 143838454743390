import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useDispatch } from "react-redux";
import { useProductDetailsModalStyles } from "styles/productDetails";
import { LightModeCross, NightModeCross } from "app/components/Logo/logos";
import ShareProductModal from "app/components/NewProductDetailsUpdated/ShareProductModalV2";
import { productDetailsNavBarType } from "app/redux/reducers/navBar/productDetails";
import { PurpleButton } from "app/components/UIFormFields/Buttons";
import { useAppSelector } from "app/utils/hooks";

import NavBarPreview from "./navBar";
import IndividualProductPreviewUpload from "./preview";
import UploadPreviewFileViewer from "./fileViewer";

export default function ProductDetailsPreviewModal({
  openPreview,
  setOpenPreview,
}) {
  const classes = useProductDetailsModalStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const jobsNavBarState = useAppSelector((state) => state.jobsNavBar);
  const designerProfileCardOption = useAppSelector(
    (state) => state.designerProfileCardOption
  );
  const productDetailsNavBar = useAppSelector(
    (state) => state.productDetailsNavBar
  );
  const previewCategoryData = useAppSelector(
    (state) => state.previewCategoryData
  );
  const [openShare, setOpenShare] = React.useState({
    shareModal: false,
  });

  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };
  const handleOpenShareModal = () =>
    setOpenShare({ ...openPreview, shareModal: true });
  const handleCloseShareModal = () =>
    setOpenShare({ ...openPreview, shareModal: false });

  const handleClosePreview = () => {
    setOpenPreview(false);
    dispatch({
      type: productDetailsNavBarType.PRODUCT_INFO,
    });
  };

  // Product view page for uploading product by Company

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal open={openPreview}>
        <Box
          sx={{
            overflow: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "8px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              border: "1px solid rgba(0, 0, 0, 0)",
              backgroundClip: "padding-box",
              width: "6px",
              borderRadius: "6px",
              backgroundColor: "#a49bbe",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#D7D0E8",
            },
          }}
          style={{
            backgroundColor:
              theme?.palette?.mode === "light" ? "#fff" : "black",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxHeight: "95vh",
            maxWidth: 1236,
            borderRadius: "15px",
            color: "white",
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box className={classes.closeBox}>
              {theme?.palette?.mode === "light" ? (
                <LightModeCross
                  onClick={handleClosePreview}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <NightModeCross
                  onClick={handleClosePreview}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>
            <Box className={classes.titleBox}>
              <Typography
                style={
                  theme?.palette?.mode === "light"
                    ? heading.lightMode
                    : heading.nightMode
                }
              >
                Product Preview
              </Typography>
            </Box>

            <Box
              style={{
                marginTop: "-20px",
                width: "100%",
                padding: "0px 54px 54px 54px",
              }}
            >
              <NavBarPreview />
              <Box style={{ marginTop: "20px" }}>
                {productDetailsNavBar.productInfo && (
                  <IndividualProductPreviewUpload
                    handleClosePreview={handleClosePreview}
                    style={{ marginTop: "20px" }}
                  />
                )}

                {productDetailsNavBar._3d && (
                  <Box style={{ marginTop: "34px", width: "100%" }}>
                    <UploadPreviewFileViewer
                      files={
                        previewCategoryData?.data?.fileUplaoded?._3dFileUpload
                      }
                    />
                  </Box>
                )}
                {productDetailsNavBar.pattern && (
                  <Box style={{ marginTop: "34px", width: "100%" }}>
                    <UploadPreviewFileViewer
                      files={
                        previewCategoryData?.data?.fileUplaoded?.patternUpload
                      }
                    />
                  </Box>
                )}
                {productDetailsNavBar.art && (
                  <Box style={{ marginTop: "34px", width: "100%" }}>
                    <UploadPreviewFileViewer
                      files={
                        previewCategoryData?.data?.fileUplaoded?.artWorkUpolad
                      }
                    />
                  </Box>
                )}
                {productDetailsNavBar.image && (
                  <Box style={{ marginTop: "34px", width: "100%" }}>
                    <UploadPreviewFileViewer
                      files={
                        previewCategoryData?.data?.fileUplaoded?.imageUpload
                      }
                    />
                  </Box>
                )}
                {productDetailsNavBar.sketch && (
                  <Box style={{ marginTop: "34px", width: "100%" }}>
                    <UploadPreviewFileViewer
                      files={
                        previewCategoryData?.data?.fileUplaoded?.sketchUpload
                      }
                    />
                  </Box>
                )}
                {productDetailsNavBar.others && (
                  <Box style={{ marginTop: "34px", width: "100%" }}>
                    <UploadPreviewFileViewer
                      files={
                        previewCategoryData?.data?.fileUplaoded?.otherFileUpload
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <ShareProductModal
            open={openShare.shareModal}
            handleCloseShareModal={handleCloseShareModal}
            shareUrl={""}
          />
          <Box
            style={{
              width: "75px",
              margin: "34px auto 34px auto",
            }}
          >
            <PurpleButton onClick={handleClosePreview}>Close</PurpleButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
