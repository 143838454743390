/*
 **	User name
 **	Created	5/25/2023
 **	libraryUpload.tsx
 */

import React, { useEffect, useContext } from "react";
import { Alert, Box, Modal, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { libraryCategoriesyListReducerTypes } from "app/redux/reducers/libraryReducers/libraryList";
import { IRootState } from "app/redux/reducers";
import { isEqual } from "lodash";
import { LibraryProductUploadContext } from "context/LibraryProductUploadContext";
import { libraryActionTypes } from "app/redux/reducers/library";
import { useAppSelector } from "app/utils/hooks";

import { BlackBorderedButton, PurpleButton } from "../UIFormFields/Buttons";
import { LightModeCross, NightModeCross } from "../Logo/logos";
import LibraryAdditionalInfo from "./UploadLibraryProduct/LibraryAdditionalInfo";
import UniversalLoader from "../Loader";
import LibraryProductEditNavBar from "./navigationBar";
import { designerProfileCardOptionStateType } from "../../redux/reducers/navBar";
import { CategoryDataContext } from "../CategoryComponent/CategoryDataContext";
import useUploadLibraryProductStyles from "./UploadLibraryProduct/useUploadLibraryProductStyles";
import LibraryUploadLeftSection from "./UploadLibraryProduct/LibraryUploadLeftSection";
import LibraryUploadRightSection from "./UploadLibraryProduct/LibraryUploadRightSection";

// main Upload Function
export default function LibraryOptionModal({
  handleClick,
  handleClose,
  isEditMode = false,
  individualProductData = null,
}) {
  const classes = useUploadLibraryProductStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  // const [uploadedImagesInEdit, setUploadedImageInEdit] = useState([]);
  const productLibraryIdForEdit = isEditMode
    ? individualProductData?.data?.productLibraryId
    : null;

  const productWithUploadType = useSelector(
    (state: IRootState) => state.productWithUploadType
  );
  const libraryStateData = useAppSelector((state) => state.library);

  useEffect(() => {
    dispatch({ type: libraryActionTypes.CLEAR_LIBRARY_UPLOAD, payload: {} });
    dispatch({
      type: libraryCategoriesyListReducerTypes.LIBRARY_CATEGORIES_LIST,
    });
  }, []);

  const { libraryData } = useContext(CategoryDataContext);
  const {
    productInfo,
    uploadedImages,
    setProductData,
    saveLibraryProductAsDraft,
    clearLibraryUpload,
    uploadedImagesInEdit,
  } = useContext(LibraryProductUploadContext);

  useEffect(() => {
    if (productLibraryIdForEdit) {
      setProductData(productLibraryIdForEdit);
    }
  }, [productLibraryIdForEdit]);

  // useEffect(() => {
  //   if (individualProductDataForLibrary && productLibraryIdForEdit) {
  //     setProductInfo({
  //       uploads: [],
  //       title: individualProductDataForLibrary?.libraryItemTitle,
  //       tools:
  //         individualProductDataForLibrary?.toolListDetails?.map(
  //           ({ toolId }) => toolId
  //         ) || [],
  //       tags:
  //         individualProductDataForLibrary?.tagListDetails?.map(
  //           ({ tagId }) => tagId
  //         ) || [],
  //       description: individualProductDataForLibrary?.libraryDescription,
  //       // added for Edit functionality
  //       visibilityId: individualProductDataForLibrary?.visibility,
  //       downloadType: individualProductDataForLibrary?.downloadType,
  //       licenseId: individualProductDataForLibrary?.licenseId,
  //       price: individualProductDataForLibrary?.price,
  //       currencyId: individualProductDataForLibrary?.currencyId,
  //       isPublished: individualProductDataForLibrary?.isPublished,
  //     });
  //     setUploadedImageInEdit([
  //       ...(individualProductDataForLibrary?.productLibraryAdditionalFilesDetails ||
  //         []),
  //     ]);
  //     libraryData.setData(
  //       individualProductDataForLibrary?.libraryCategorySavedDetails || {}
  //     );
  //   } else {
  //     setUploadedImageInEdit([]);
  //   }
  // }, [individualProductDataForLibrary, productLibraryIdForEdit]);
  useEffect(() => {
    if (libraryStateData?.data?.responseCode === 200) {
      dispatch({ type: libraryActionTypes.CLEAR_LIBRARY_UPLOAD, payload: {} });
      handleClose?.();
      clearLibraryUpload();
    }
  }, [libraryStateData?.data?.responseCode]);

  const [additinalInfoModal, setAdditinalInfoModal] = React.useState(false);

  const handleSaveToDraft = () => {
    saveLibraryProductAsDraft();
    libraryData.clearAll();
  };

  const handleProductDetails = (status) => {};

  const handleClick2 = (click) => {
    if (click === "next") {
      setAdditinalInfoModal(true);
    } else if (click === "backAddInfo") {
      setAdditinalInfoModal(false);
    } else if (click === "publish") {
      setAdditinalInfoModal(false);
      handleProductDetails("true");
      handleClose();
      libraryData.clearAll();
    } else if (click === "closeIcon") {
      handleClose();
      libraryData.clearAll();
    } else if (click === "draft") {
      handleClose();
      handleProductDetails("false");
      libraryData.clearAll();
    }
  };

  const designerProfileCardOption = useSelector(
    (state: IRootState) => state.designerProfileCardOption
  );

  const handleEditClose = () => {
    dispatch({
      type: designerProfileCardOptionStateType.CLOSE_EDIT,
    });
    libraryData.clearAll();
  };

  const handleCrossClick = () => {
    handleClose();
    libraryData.clearAll();
  };

  const isNextEnabled = (() => {
    const lastChild =
      libraryData?.treeData?.[libraryData?.treeData?.length - 1]?.children;

    const libraryCategoryIsOkay =
      libraryData?.treeData?.length > 0 &&
      (lastChild === null || lastChild?.length === 0 || isEqual(lastChild, {}));

    const productCategoryIsOkay = libraryData?.treeData?.[
      libraryData?.treeData?.length - 1
    ]?.isLinkedToProducts
      ? libraryData?.productCategoriesTreeData?.length === 2
      : true;

    if (
      (uploadedImages?.length || uploadedImagesInEdit?.length) > 0 &&
      productInfo?.title &&
      productInfo?.description &&
      productInfo?.tools &&
      productInfo?.tags &&
      libraryCategoryIsOkay &&
      productCategoryIsOkay &&
      libraryData?.arrtibutesLength ==
        libraryData?.attributesTreeData?.filter(
          (attributesTreeData) => attributesTreeData?.selectedAttribute?.length
        )?.length
    ) {
      return true;
    }

    return false;
  })();

  return (
    <>
      {!isEditMode && additinalInfoModal ? (
        <LibraryAdditionalInfo
          handleClose={() => handleClick2("closeIcon")}
          handleClick={(item) => handleClick2(item)}
        />
      ) : (
        <Modal open={true}>
          <Box
            className={classes.modalStyle}
            style={{
              backgroundColor: theme?.palette?.mode === "light" && "#fff",
              width: additinalInfoModal ? "50vw" : "95vw",
            }}
          >
            <Box>
              <Box className={classes.closeBox}>
                {theme?.palette?.mode === "light" ? (
                  <LightModeCross
                    onClick={
                      designerProfileCardOption.edit
                        ? handleEditClose
                        : handleCrossClick
                    }
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <NightModeCross
                    onClick={
                      designerProfileCardOption.edit
                        ? handleEditClose
                        : handleCrossClick
                    }
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.textBox}>
              <Typography className={classes.header}>
                {isEditMode
                  ? "Edit Library Product"
                  : "Library Product Information"}
              </Typography>

              {productWithUploadType?.loading && isEditMode ? (
                <UniversalLoader />
              ) : (
                <Box
                  style={{
                    minHeight: "418px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  {isEditMode ? (
                    <Box style={{ paddingLeft: 60, paddingRight: 60 }}>
                      <LibraryProductEditNavBar
                        active={additinalInfoModal}
                        setActive={setAdditinalInfoModal}
                      />
                    </Box>
                  ) : null}
                  {isEditMode && additinalInfoModal ? (
                    <>
                      <LibraryAdditionalInfo
                        handleClose={() => handleClick2("closeIcon")}
                        handleClick={(item) => handleClick2(item)}
                        withoutModal={true}
                      />
                    </>
                  ) : (
                    <>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Box className={classes.LeftSection}>
                          <LibraryUploadLeftSection />
                        </Box>

                        <Box className={classes.rightSection}>
                          <LibraryUploadRightSection isEditMode={isEditMode} />
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              )}

              {isEditMode && additinalInfoModal ? (
                <></>
              ) : (
                <>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "0 40px",
                    }}
                  >
                    <Box style={{ maxWidth: 500 }}>
                      {libraryStateData?.data?.responseCode &&
                        libraryStateData?.data?.responseCode !== 200 && (
                          <Alert severity="error">
                            {libraryStateData?.data?.responseMessage}
                          </Alert>
                        )}

                      {libraryStateData?.error && (
                        <Alert severity="error">
                          {libraryStateData?.error}
                        </Alert>
                      )}
                    </Box>
                  </Box>
                  <Box className={classes.buttonBox}>
                    <BlackBorderedButton
                      onClick={() => {
                        handleClick("backProductPage");
                        if (!isEditMode) {
                          libraryData.clearAll();
                        }
                      }}
                      className={classes.button}
                    >
                      {isEditMode ? "Cancel" : "Back"}
                    </BlackBorderedButton>

                    <PurpleButton
                      onClick={() => {
                        handleClick2("next");
                        // handleProductDetails();
                      }}
                      className={classes.button}
                      disabled={!isNextEnabled}
                    >
                      Next
                    </PurpleButton>
                  </Box>
                </>
              )}
            </Box>
            {isEditMode && additinalInfoModal ? (
              <></>
            ) : (
              <Typography onClick={handleSaveToDraft} className={classes.draft}>
                Save to draft
              </Typography>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
}
