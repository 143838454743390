import React, { useMemo, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/system";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  TextareaAutosize,
  Chip,
  OutlinedInput,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { isImage } from "app/utils/helper";
import { uploadProductFileReducerTypes } from "app/redux/reducers/productReducer/uploadProductFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAppSelector } from "app/utils/hooks";

import { CheckboxField } from "../../UIFormFields";
import { filterReducerTypes } from "../../../redux/reducers/filterReducers/filter";
import ReadMore from "../../UIFormFields/readMore";
import ConfirmationModalWithIcon from "../../ConfirmationModal/confirmationModalWithIcon";

const use3dAttachmentDetailsStyles = makeStyles({
  fieldHeading: {
    fontSize: "13px !important",
    margin: "8px 0px 6px 0px !important",
    fontWeight: "bold !important",
    color: "#a49bbe",
    float: "left !important" as any,
    display: "flex",
  },
  attechment: {
    width: "100%",
    height: "180px",
    backgroundColor: "#ebe3ff",
    borderRadius: "6px",
  },
  attechmentTitle: {
    display: "flex",
    margin: "17px 16px 0px 16px",
    justifyContent: "space-between",
  },
  chipBox: {
    background: "#ebe3ff",
    borderRadius: "6px !important",
    fontSize: "15px !important",
    margin: "3px 5px 3px 0px !important",
    width: "auto",
    height: "34px !important",
    color: "#a49bbe !important",
    marginRight: "8px !important",
  },
  textFields: {
    marginTop: "16px",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  formControl: {
    width: "100%",
  },
  selectAllText: {
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  outlined: {
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
  },
  randerValueBox: {
    display: "flex",
    flexWrap: "wrap",
    padding: 0,
    margin: 0,
  },
  randerValueSpan: {
    display: "flex",
    color: "#7a48ff",
    fontWeight: "bold",
    alignItems: "center",
  },
  reset: {
    justifyContent: "end",
    margin: "16px 16px 0px 0px",
    display: "flex",
    padding: "0px 10px 0px 10px",
    fontWeight: "bold",
    color: "#a49bbe",
  },
  container: {
    width: "100%",
    padding: "0px 0px 0px 34px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },

  itemStyle: {
    display: "flex",
    width: "138px",
    height: "98px",
    margin: "16px ",
    borderRadius: "6px",
    backgroundColor: "red",
  },
  titleStyle: {
    color: "#a49bbe",
    fontSize: "13px",
    fontWeight: "bold",
    marginBottom: "6px",
    display: "flex",
  },
  chipStyle: {
    marginRight: "8px",
    color: "#a49bbe",
    textAlign: "center",
    backgroundColor: "#ebe3ff",
    padding: "7px",
    borderRadius: "6px",
  },
  title: {
    fontSize: "13px !important",
    fontWeight: "bold !important",
    color: "#a49bbe",
    margin: "16px 0px 8px 0px !important",
    display: "flex",
  },
  clickBox: {
    display: "flex",
    flexDirection: "row !important" as any,
    flexWrap: "wrap",
  },
  descriptionInfo: {
    color: "#2b2639",
    textAlign: "justify",
  },
  descriptionSeeMore: {
    fontSize: "15px",
    textAlign: "left",
    color: "#7a48ff",
    paddingTop: "6px",
  },
  snackbarcss: {
    minWidth: "185px !important",
    height: "54px !important",
    // marginBottom: "20px",
    border: "solid 1px #ebe3ff !important",
    backgroundColor: "#fff !important",
    color: "#2b2639 !important",
    borderRadius: "10px !important",
    boxShadow: "0 10px 16px 0 rgba(122, 72, 255, 0.1) !important",
  },
});

// const productTag = [
//     {
//         tagName: "#first",
//         tagId: 1,
//     },
//     {
//         tagName: "#second",
//         tagId: 2,
//     },
//     {
//         tagName: "#third",
//         tagId: 3,
//     },
// ];

export const AttachmentDetailsUpload = ({
  selectedItem,
  setSelectedItem,
  selectedStack,
  handleDeleteItem,
  selectedFileUplaoded,
  uploadType,
  product_details,
  isEditProduct,
  setSaveMessage,
  saveMessage,
  success,
  setSuccess,
  fileUplaoded,
  setSelectedFileUplaoded,
  handleEditItem,
}) => {
  const dispatch = useDispatch();
  const classes = use3dAttachmentDetailsStyles();
  const theme = useTheme();
  const userData = useAppSelector((state) => state.register);
  const uploadProduct = useAppSelector((state) => state.uploadDesign);
  const accountGuid = userData?.userInfo?.accountGuid;
  // const [selectTags, setSelectTags] = useState([]);
  // const [selectTools, setSelectTools] = useState([]);
  // const [toolList, setToolList] = useState([]);
  // const [categoryList, setCategoryList] = useState([]);
  // const [selectCategory, setSelectCategory] = useState([]);
  const [showInfo1, setShowInfo1] = useState(false);
  const filterTypes = useAppSelector((state) => state.filterTypes);
  const toolTypes = filterTypes.toolTypeList;
  const categoryTypes = useAppSelector((state) => state.category);
  const productTag = useAppSelector((state) => state.tags);
  const uploadProductFile = useAppSelector((state) => state.uploadProductFile);
  const detailsOfProduct = useAppSelector(
    (state) => state.productWithUploadType
  );
  const productDetails = detailsOfProduct?.data;

  // useEffect(() => {
  //     if (success) {
  //         setTimeout(() => {
  //             setIsPreview(true)
  //             setSuccess(false);
  //             // dispatch({ type: uploadProductFileReducerTypes.CLEAR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES });
  //             // dispatch({ type: uploadDesignReducerTypes.CLEAR_UPLOAD_DESIGN });
  //         }, 2000);
  //     }
  // }, [success]);

  const [formData, setFormData] = useState({
    filename: product_details?.title,
    tools: product_details?.tools || [],
    productTag: product_details?.productTag || [],
    categories: [],
    description: product_details?.description,
  });
  const [errors, setErrors] = useState({
    filename: "",
    categories: "",
    description: "",
  });
  const [isPreview, setIsPreview] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // useEffect(() => {
  //     setFormData({
  //         filename: "",
  //         tools: [],
  //         productTag: [],
  //         categories: [],
  //         description: "",
  //     });
  // }, [])

  const tagIdReturn = (array) => {
    const arr = [];

    array?.map((item) => arr.push(item.tagId));
    return [...arr];
  };
  const categoryIdReturn = (array) => {
    const arr = [];

    array?.map((item) => arr.push(item.categoryId));
    return [...arr];
  };
  const toolIdReturn = (array) => {
    const arr = [];

    array?.map((item) => arr.push(item.toolId));
    return [...arr];
  };

  useEffect(() => {
    if (selectedItem) {
      setFormData({
        filename: selectedItem?.item?.fileName,
        tools: toolIdReturn(selectedItem?.item?.toolListDetails) || [],
        productTag: tagIdReturn(selectedItem?.item?.tagListDetails) || [],
        categories:
          categoryIdReturn(selectedItem?.item?.categoryListDetails) || [],
        description: selectedItem?.item?.description,
      });
    }
  }, [selectedItem?.item]);

  const returnUrlHandler = (selected) => {
    // let url = uploadProductFile?.dataDiffCategories?.urlDetails || URL.createObjectURL(selected?.item)
    const url =
      selected?.item?.uploadedFileUrl || URL.createObjectURL(selected?.item);

    return url;
  };

  const returnFileTypeHandler = (selected) => {
    const url =
      selected?.item?.uploadedFileUrl || URL.createObjectURL(selected?.item);

    return (
      isImage(url) !== null ||
      selected?.item?.uploadedFileUrl === (".PNG" || ".jpg" || ".png")
    );
  };

  // const selectedItemsReturn = (type) => {
  //     return (type._3D
  //         && selectedItem.selected3dFile)
  //         || (type.pattern
  //             && selectedItem.selectedPattern)
  //         || (type.artWork
  //             && selectedItem.selectedArtWork)
  //         || (type.sketch
  //             && selectedItem.selectedSketch)
  //         || (type.image
  //             && selectedItem.selectedImage)
  //         || (type.otherFiles
  //             && selectedItem.selectedOtherFile)
  // }

  // const selectedItemDelete = (type) => {
  //     return (type._3D
  //         && setSelectedItem({ ...selectedItem, selected3dFile: null }))
  //         || (type.pattern
  //             && setSelectedItem({ ...selectedItem, selectedPattern: null }))
  //         || (type.artWork
  //             && setSelectedItem({ ...selectedItem, selectedArtWork: null }))
  //         || (type.sketch
  //             && setSelectedItem({ ...selectedItem, selectedSketch: null }))
  //         || (type.image
  //             && setSelectedItem({ ...selectedItem, selectedImage: null }))
  //         || (type.otherFiles
  //             && setSelectedItem({ ...selectedItem, selectedOtherFile: null }))
  // }

  const [isDisable, setDisable] = useState(false);

  useEffect(() => {
    const isValid =
      formData?.filename?.length &&
      formData?.description?.length &&
      uploadProductFile?.loading === false
        ? true
        : false;

    setDisable(isValid);
  }, [
    formData?.filename?.length,
    formData?.description?.length,
    uploadProductFile,
  ]);

  const {
    ReactComponent: DeleteModalIcon,
  } = require("../../../../assets/icons/Icons_Light_Delete_file Icon.svg");
  // const {
  //     ReactComponent: DeleteIcon,
  // } = require("../../../../assets/icons/Icons_Light_Delete Icon small.svg");
  const {
    ReactComponent: AttechmentEdit,
  } = require("../../../../assets/icons/Attechment_Edit Icon.svg");

  // useEffect(() => {
  //     alert(selectedFileUplaoded)
  // }, [selectedFileUplaoded])

  useEffect(() => {
    if (uploadProduct?.data !== null) {
      if (uploadProduct?.data.responseCode === 200) {
        setIsPreview(true);
        //  alert(JSON.stringify(uploadProduct));
      } else {
        // alert(JSON.stringify(uploadProduct?.data));
      }
    }
  }, [uploadProduct]);

  const updateCategoryDetailsApi = () => {
    // api for upload design for different categories0
    const params = new FormData();

    params.append("UploadedFileName", formData.filename);
    params.append(
      "UploadedFile",
      selectedItem?.item?.uploadedFileUrl || selectedItem?.item
    );
    params.append("AccountGuid", accountGuid);
    params.append("UploadedCategoryType", "1");
    params.append(
      "ProductId",
      uploadProductFile?.dataThumbnail?.id ||
        uploadProductFile?.dataDiffCategories?.id ||
        selectedItem?.item?.productId
    );
    params.append("Description", formData.description);
    params.append("ToolsList", formData.tools);
    params.append("TagsList", formData.productTag);
    params.append("ProductCategoryList", JSON.stringify(formData.categories));
    params.append(
      "ProductUploadFilesWithTypeID",
      selectedItem?.item?.productUploadFilesWithTypeID || 0
    );

    dispatch({
      type: uploadProductFileReducerTypes.UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      payload: params,
    });
    setIsPreview(true);
  };

  useEffect(() => {
    if (uploadProductFile?.dataDiffCategoriesDelete?.responseCode === 200) {
      setSelectedItem(null);
      setDeleteModal(false);
      dispatch({
        type: uploadProductFileReducerTypes.CLEAR_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      });
    }
  }, [uploadProductFile?.dataDiffCategoriesDelete]);

  const deleteCategoryDetailsApi = () => {
    // api for upload design for different categories0
    const params = {
      accountGuid,
      productId:
        uploadProductFile?.dataThumbnail?.id ||
        uploadProductFile?.dataDiffCategories?.id ||
        selectedItem?.item?.productId,
      productUploadFilesWithTypeID:
        selectedItem?.item?.productUploadFilesWithTypeID || 0,
    };

    dispatch({
      type: uploadProductFileReducerTypes.DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      payload: params,
    });
    // setIsPreview(false)
  };

  const handleSubmit = () => {
    setSaveMessage(true);

    const tempErrors = {
      filename: "",
      categories: "",
      description: "",
    };

    let hasError = false;

    if (!formData.filename) {
      tempErrors.filename = "This field is required!";
      hasError = true;
    } else if (formData.filename.length < 3) {
      tempErrors.filename =
        "The title must be between 3 and 55 characters in length.";
      hasError = true;
    } else if (formData.filename.length > 55) {
      tempErrors.filename =
        "The title must be between 3 and 55 characters in length.";
      hasError = true;
    }

    if (!formData.categories) {
      tempErrors.categories = "This field is required!";
      hasError = true;
    }
    if (!formData.description) {
      tempErrors.description = "This field is required!";
      hasError = true;
    }
    if (hasError) {
      setErrors({ ...errors, ...tempErrors });
    } else {
      setErrors({ ...tempErrors });
      updateCategoryDetailsApi();
    }
  };

  useEffect(() => {
    const params = {
      tags: formData.productTag,
    };

    dispatch({
      type: filterReducerTypes.PRODUCT_FILTERED_LIST,
      params,
    });
  }, []);

  const handleOnChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
  };

  const handleChangeTools = (event) => {
    const {
      target: { value },
    } = event;

    setFormData({ ...formData, tools: [...value] });
  };

  const handleDeleteTools = (item, index) => {
    const newData = [...formData.tools];

    if (index > -1) {
      newData.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFormData({ ...formData, tools: [...newData] });
  };

  const handleChangeCategories = (event) => {
    const {
      target: { value },
    } = event;

    setFormData({ ...formData, categories: [...value] });
  };

  const handleDeleteCategories = (item, index) => {
    const newData = [...formData.categories];

    if (index > -1) {
      newData.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFormData({ ...formData, categories: [...newData] });
  };

  const handleChangeTags = (event) => {
    const {
      target: { value },
    } = event;

    setFormData({ ...formData, productTag: [...value] });
  };

  const handleDeleteTags = (item, index) => {
    const newData = [...formData.productTag];

    if (index > -1) {
      newData.splice(index, 1); // 2nd parameter means remove one item only
    }
    setFormData({ ...formData, productTag: [...newData] });
  };

  const clk = () => {
    if (!showInfo1) {
      setShowInfo1(true);
    } else {
      setShowInfo1(false);
    }
  };

  const handleDeleteItemtrue = () => {
    let TempArr = [];

    if (uploadType.image) {
      TempArr = selectedFileUplaoded.selectedImageUpload;
    } else if (uploadType._3D) {
      TempArr = selectedFileUplaoded.selected3dFileUpload;
    } else if (uploadType.artWork) {
      TempArr = selectedFileUplaoded.selectedArtWorkUpolad;
    } else if (uploadType.otherFiles) {
      TempArr = selectedFileUplaoded.selectedOtherFileUpload;
    } else if (uploadType.pattern) {
      TempArr = selectedFileUplaoded.selectedPatternUpload;
    } else if (uploadType.sketch) {
      TempArr = selectedFileUplaoded.selectedSketchUpload;
    }

    const filteredArray = TempArr.filter(
      (selectedStack) => selectedStack.isSelected === false
    );

    if (uploadType.image) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedImageUpload: filteredArray,
      });
      // setFileUploaded({ ...fileUplaoded, imageUpload: filteredArray });
    } else if (uploadType._3D) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selected3dFileUpload: filteredArray,
      });
      // setFileUploaded({ ...fileUplaoded, _3dFileUpload: filteredArray });
    } else if (uploadType.artWork) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedArtWorkUpolad: filteredArray,
      });
      // setFileUploaded({ ...fileUplaoded, artWorkUpolad: filteredArray });
    } else if (uploadType.otherFiles) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedOtherFileUpload: filteredArray,
      });
      // setFileUploaded({ ...fileUplaoded, otherFileUpload: filteredArray });
    } else if (uploadType.pattern) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedPatternUpload: filteredArray,
      });
      // setFileUploaded({ ...fileUplaoded, patternUpload: filteredArray });
    } else if (uploadType.sketch) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedSketchUpload: filteredArray,
      });
      // setFileUploaded({ ...fileUplaoded, sketchUpload: filteredArray });
    }
    setDeleteModal(false);
    setSelectedItem(null);
  };

  // render chips value ---
  const returnToolValue = (item) =>
    toolTypes?.map((value) => {
      if (item === value.toolDetailId) return value.toolName;
    });
  const returnTagValue = (item) =>
    productTag?.data?.map((value) => {
      if (item === value.productTagID) return value.productTagName;
    });
  const returnCategoryValue = (item) =>
    categoryTypes?.data?.map((value) => {
      if (item === value.categoryID) return value.categoryName;
    });

  const renderToolChips = (selected) => {
    function renderChipsValue() {
      return selected.map((value, index) => {
        if (index < 2) {
          return (
            <Chip
              style={{
                background: "#2b2639",
                color: "#fff",
                borderRadius: 6,
                fontSize: 15,
              }}
              key={index}
              label={returnToolValue(value)}
              onDelete={() => handleDeleteTools(value, index)}
              deleteIcon={<DeleteIcon />}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            />
          );
        }
      });
    }

    if (selected.length < 3) {
      return (
        <Box gap={0.5} className={classes.randerValueBox}>
          {renderChipsValue()}
        </Box>
      );
    } else {
      return (
        <Box gap={0.5} className={classes.randerValueBox}>
          {renderChipsValue()}
          <span
            className={classes.randerValueSpan}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onClick={clk}
          >
            +{selected.length - 2}
          </span>
        </Box>
      );
    }
  };

  const renderTagChips = (selected) => {
    function renderChipsValue() {
      return selected.map((value, index) => {
        if (index < 2) {
          return (
            <Box>
              <Chip
                style={{
                  background: "#2b2639",
                  color: "#fff",
                  borderRadius: 6,
                  fontSize: 15,
                }}
                key={index}
                label={returnTagValue(value)}
                onDelete={() => handleDeleteTags(value, index)}
                deleteIcon={<DeleteIcon />}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            </Box>
          );
        }
      });
    }

    if (selected.length < 3) {
      return (
        <Box gap={0.5} className={classes.randerValueBox}>
          {renderChipsValue()}
        </Box>
      );
    } else {
      return (
        <Box gap={0.5} className={classes.randerValueBox}>
          {renderChipsValue()}
          <span
            className={classes.randerValueSpan}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onClick={clk}
          >
            +{selected.length - 2}
          </span>
        </Box>
      );
    }
  };

  const renderCategory = (selected) => {
    function renderCatValue() {
      return selected.map((value, index) => {
        if (index < 2) {
          return (
            <Chip
              style={{
                background: "#2b2639",
                color: "#fff",
                borderRadius: 6,
                fontSize: 15,
              }}
              key={index}
              label={returnCategoryValue(value)}
              onDelete={() => handleDeleteCategories(value, index)}
              deleteIcon={<DeleteIcon />}
              onMouseDown={(event) => {
                event.stopPropagation();
              }}
            />
          );
        }
      });
    }

    if (selected.length < 3) {
      return (
        <Box gap={0.5} className={classes.randerValueBox}>
          {renderCatValue()}
        </Box>
      );
    } else {
      return (
        <Box gap={0.5} className={classes.randerValueBox}>
          {renderCatValue()}
          <span
            className={classes.randerValueSpan}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onClick={clk}
          >
            +{selected.length - 2}
          </span>
        </Box>
      );
    }
  };

  const previewImageOfEditDetails = useMemo(() => {
    if (returnFileTypeHandler(selectedItem))
      return (
        <Box>
          <img
            src={returnUrlHandler(selectedItem)}
            alt="product"
            style={{
              // maxWidth: "606px",
              width: "138px",
              height: "98px",
              border: "2px solid #ccc",
              borderRadius: "6px",
              margin: "0px 16px 0px 0px",
            }}
          />
        </Box>
      );
    else {
      return (
        <Box>
          <model-viewer
            style={{
              width: "138px",
              height: "98px",
              border: "2px solid #ccc",
              borderRadius: "6px",
              margin: "0px 16px 0px 0px",
            }}
            src={returnUrlHandler(selectedItem)}
            ios-src=""
            alt="model name"
            camera-controls
            ar
            ar-modes="webxr scene-viewer quick-look"
            environment-image="neutral"
            oncontextmenu="return false;"
            autoplay
          ></model-viewer>
        </Box>
      );
    }
  }, [selectedItem?.item]);

  const returnEditPreview = useMemo(() => {
    // if (returnFileTypeHandler(selectedItem)) {    commented as picture was not getting uploaded but 3D file was
    // selectedItem?.item['type']?.split('/')[0] === 'image'
    // if (returnFileTypeHandler(selectedItem)) {
    if (selectedStack[0]?.type?.split("/")[0] === "image") {
      return (
        <Box
          sx={{
            width: "160px",
            height: "110px",
            porition: "absolute",
          }}
        >
          {selectedStack.length > 1 ? (
            <Typography
              sx={{
                backgroundColor: "#64c279",
                width: "24px",
                height: "24px",
                position: "relative",
                zIndex: "10",
                color: "white",
                top: "20px",
                left: "75%",
                borderRadius: "6px",
                fontSize: "15px",
              }}
            >
              {selectedStack.length}
            </Typography>
          ) : (
            ""
          )}
          <img
            src={URL.createObjectURL(selectedStack[0])}
            // src="https://www.bing.com/th?id=OIP.CTpVBeL-wHpY8MOiqoDeAQHaHa&w=250&h=250&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2"
            alt="product"
            style={{
              width: "138px",
              height: "98px",
              border: "2px solid #ccc",
              borderRadius: "6px",
              margin: "0px 16px 0px 0px",
              position: "absolute",
            }}
          />

          {selectedStack[1] && (
            <img
              src={URL.createObjectURL(selectedStack[selectedStack.length - 1])}
              // src="https://www.bing.com/th?id=OIP.Lio_RcsSCykMSkkBM1gl4QHaJ4&w=216&h=288&c=8&rs=1&qlt=90&o=6&dpr=1.5&pid=3.1&rm=2"
              alt="product"
              style={{
                width: "138px",
                height: "98px",
                border: "2px solid #ccc",
                borderRadius: "6px",
                margin: "0px 16px 0px 0px   ",
                position: "relative",
                top: "-10px",
                left: "10px",
              }}
            />
          )}
        </Box>
      );
    } else {
      return (
        <Box
          sx={{
            width: "160px",
            height: "110px",
            porition: "absolute",
          }}
        >
          {selectedStack.length > 1 ? (
            <Typography
              sx={{
                backgroundColor: "#64c279",
                width: "24px",
                height: "24px",
                position: "relative",
                zIndex: "10",
                color: "white",
                top: "20px",
                left: "75%",
                borderRadius: "6px",
                fontSize: "15px",
              }}
            >
              {selectedStack.length}
            </Typography>
          ) : (
            ""
          )}
          <model-viewer
            style={{
              width: "138px",
              height: "98px",
              border: "2px solid #ccc",
              borderRadius: "6px",
              margin: "0px 16px 0px 0px",
              position: "absolute",
            }}
            src={
              selectedStack[0]?.uploadedFileUrl ||
              URL.createObjectURL(selectedStack[0])
            }
            ios-src=""
            alt="model name"
            camera-controls
            ar
            ar-modes="webxr scene-viewer quick-look"
            environment-image="neutral"
            oncontextmenu="return false;"
            autoplay
          ></model-viewer>
          {selectedStack.length > 1 && (
            <model-viewer
              style={{
                width: "138px",
                height: "98px",
                border: "2px solid #ccc",
                borderRadius: "6px",
                margin: "0px 16px 0px 0px   ",
                position: "relative",
                top: "-10px",
                left: "10px",
              }}
              src={
                selectedStack[selectedStack.length - 1]?.uploadedFileUrl ||
                URL.createObjectURL(selectedStack[selectedStack.length - 1])
              }
              ios-src=""
              alt="model name"
              camera-controls
              ar
              ar-modes="webxr scene-viewer quick-look"
              environment-image="neutral"
              oncontextmenu="return false;"
              autoplay
            ></model-viewer>
          )}
        </Box>
      );
    }
  }, [selectedItem]);

  const uploadDetailFromPreview = () => {
    return (
      <div className={classes.container}>
        <Box className={classes.attechment}>
          <Box className={classes.attechmentTitle}>
            <Typography fontSize="15px" fontWeight="bold" color="#a49bbe">
              Attachment Details
            </Typography>
            <Box>
              <AttechmentEdit
                onClick={() => setIsPreview(false)}
                style={{
                  width: "24px",
                  height: "24px",
                  marginRight: "16px",
                  cursor: "pointer",
                }}
              />
              <DeleteIcon
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                }}
                onClick={() => setDeleteModal(true)}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              // width: "138px",
              // height: "98px",
              margin: "16px ",
              borderRadius: "6px",
            }}
          >
            {previewImageOfEditDetails}
          </Box>
        </Box>
        <Box className={classes.textFields}>
          <Typography className={classes.title}>File name</Typography>
          <Typography
            style={{
              fontSize: "15px",
              textAlign: "left",
              color: "#2b2639",
            }}
          >
            {formData.filename}
          </Typography>

          <Typography className={classes.title}>Tools</Typography>
          <Box className={classes.clickBox}>
            {formData?.tools?.map((value, index) => {
              return (
                <Chip
                  style={{
                    background: "#ebe3ff",
                    color: "#7a48ff",
                    borderRadius: 6,
                    fontSize: 15,
                    marginRight: "8px",
                    marginBottom: "8px",
                  }}
                  key={index}
                  label={returnToolValue(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              );
            })}
          </Box>
          <Typography className={classes.title}>Tags</Typography>
          <Box className={classes.clickBox}>
            {formData?.productTag?.map((value, index) => {
              return (
                <Chip
                  style={{
                    background: "#ebe3ff",
                    color: "#7a48ff",
                    borderRadius: 6,
                    fontSize: 15,
                    marginRight: "8px",
                    marginBottom: "8px",
                  }}
                  key={index}
                  label={returnTagValue(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              );
            })}
          </Box>
          <Typography className={classes.title}>Categories</Typography>
          <Box className={classes.clickBox}>
            {formData?.categories?.map((value, index) => {
              return (
                <Chip
                  style={{
                    background: "#ebe3ff",
                    color: "#7a48ff",
                    borderRadius: 6,
                    fontSize: 15,
                    marginRight: "8px",
                    marginBottom: "8px",
                  }}
                  key={index}
                  label={returnCategoryValue(value)}
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                />
              );
            })}
          </Box>
          <Box marginTop="16px">
            <p className={classes.titleStyle}>Description</p>
            <Box>
              <ReadMore
                maxViweLength={485}
                textStyle={classes.descriptionInfo}
                seeMoreStyle={classes.descriptionSeeMore}
              >
                {formData.description}
              </ReadMore>
            </Box>
          </Box>
        </Box>
      </div>
    );
  };

  const editProductDetails = () => {
    return (
      <ThemeProvider
        theme={createTheme({
          typography: {
            allVariants: { fontFamily: "Poppins" },
          },
          components: {
            MuiMenuItem: {
              styleOverrides: {
                root: {
                  padding: "12px",
                  "&.Mui-selected": {
                    color:
                      theme?.palette?.mode === "light" ? "#7a48ff" : " #fff",
                    fontWeight:
                      theme?.palette?.mode === "light" ? "400" : "400",
                    backgroundColor:
                      theme?.palette?.mode === "light" ? "#fff" : "black",
                  },
                  hover: false,
                  borderBottom: 2,
                  borderColor: "#ebe3ff",
                },
              },
            },
            MuiListItem: {
              styleOverrides: {
                padding: 0,
              },
            },
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            maxWidth: "100%",
            flexDirection: "column",
            margin: "0px 0px 34px 34px",
          }}
        >
          <Box className={classes.attechment}>
            <Box className={classes.attechmentTitle}>
              <Typography fontSize="15px" fontWeight="bold" color="#a49bbe">
                Attachment Details
              </Typography>
              <DeleteIcon
                style={{
                  width: "24px",
                  height: "24px",
                  cursor: "pointer",
                  color: "black",
                }}
                onClick={() => setDeleteModal(true)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                // width: "138px",
                // height: "98px",
                margin: "16px ",
                borderRadius: "6px",
              }}
            >
              {returnEditPreview}
            </Box>
          </Box>
          <Box className={classes.textFields}>
            <Typography className={classes.fieldHeading} marginBottom="6px">
              File name
            </Typography>
            <FormControl fullWidth error={!!errors.filename}>
              <TextField
                error={!!errors.filename}
                value={formData?.filename}
                onChange={handleOnChange}
                name="filename"
                className={classes.outlined}
                id="outlined-basic"
                variant="outlined"
              />
            </FormControl>
            <FormHelperText
              sx={{
                fontSize: "11px",
                fontWeight: "bold",
                color: "#ff4f47",
              }}
            >
              {errors.filename}
            </FormHelperText>
            <div>
              <FormControl className={classes.formControl}>
                <Typography className={classes.fieldHeading}>Tools</Typography>
                <Select
                  SelectDisplayProps={{
                    style: { padding: "14px 14px", height: "30px" },
                  }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  name="productTag"
                  MenuProps={{
                    MenuListProps: { disablePadding: true },
                    PaperProps: { sx: { maxHeight: 250 } },
                  }}
                  IconComponent={ExpandMoreRounded}
                  value={formData?.tools}
                  onChange={handleChangeTools}
                  input={
                    <OutlinedInput id="select-multiple-chip" label="Tools" />
                  }
                  renderValue={(selected) => renderToolChips(selected)}
                >
                  {toolTypes?.map((item) => (
                    <MenuItem
                      sx={{
                        borderBottom: 1,
                        borderBottomColor: "#ebe3ff",
                      }}
                      key={item.toolDetailId}
                      value={item.toolDetailId}
                    >
                      <CheckboxField
                        checked={formData.tools.indexOf(item.toolDetailId) > -1}
                        style={{ padding: "0px 10px 0px 10px" }}
                      />
                      {item.toolName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            {/* Tag and category commented for Sprint 33 */}
            {/* <div>
              <Typography className={classes.fieldHeading}>Tags</Typography>
              <FormControl className={classes.textFields}>
                <Select
                  SelectDisplayProps={{
                    style: { padding: "14px 14px", height: "30px" },
                  }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  IconComponent={"none"}
                  label="Product Tag*"
                  name="productTag"
                  value={formData.productTag}
                  MenuProps={{
                    MenuListProps: { disablePadding: true },
                  }}
                  IconComponent={ExpandMoreRounded}
                  onChange={handleChangeTags}
                  input={
                    <OutlinedInput
                      id="select-multiple-chip"
                      label="Product Tag*"
                    />
                  }
                  renderValue={(selected) => renderTagChips(selected)}
                >
                  {productTag?.data?.map((item) => (
                    <MenuItem
                      sx={{
                        borderBottom: 1,
                        borderBottomColor: "#ebe3ff",
                      }}
                      key={item.productTagID}
                      value={item.productTagID}
                    >
                      <CheckboxField
                        checked={formData?.productTag?.indexOf(item.tagId) > -1}
                        style={{ padding: "0px 10px 0px 10px" }}
                      />
                      {item.productTagName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div> */}

            {/* <FormControl className={classes.textFields}>
              <Typography className={classes.fieldHeading}>Category</Typography>
              <Select
                SelectDisplayProps={{
                  style: { padding: "14px 14px", height: "30px" },
                }}
                labelId="id"
                id="id"
                multiple
                MenuProps={MenuProps}
                IconComponent={ExpandMoreRounded}
                name="categories"
                value={formData?.categories}
                onChange={handleChangeCategories}
                input={
                  <OutlinedInput
                    id="select-multiple-chip"
                    label="Select Categories"
                  />
                }
                renderValue={(selected) => renderCategory(selected)}
              >
                {categoryTypes?.data?.map((item) => (
                  <MenuItem
                    key={item.categoryID}
                    value={item.categoryID}
                    sx={{
                      borderBottom: 1,
                      borderBottomColor: "#ebe3ff",
                    }}
                  >
                    <CheckboxField
                      checked={
                        formData.categories.indexOf(item.categoryID) > -1
                      }
                      style={{ padding: "0px 10px 0px 10px" }}
                    />
                    {item.categoryName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
          </Box>
          <Box>
            <Typography className={classes.fieldHeading}>
              Description
            </Typography>
            <FormControl
              className={classes.textFields}
              error={!!errors.description}
            >
              <TextField
                className={classes.outlined}
                error={!!errors.description}
                onChange={handleOnChange}
                value={formData.description}
                id="outlined-name"
                name="description"
                multiline
                InputProps={{
                  inputComponent: TextareaAutosize,
                  inputProps: {
                    // minRows: 3,
                    maxRows: 1,
                    maxLength: 1500,
                    style: {
                      minHeight: "99px",
                      maxHeight: "99px",
                      overflowY: "scroll",
                      resize: "vertical",
                    },
                  },
                }}
              />
            </FormControl>
            <FormHelperText
              sx={{
                fontSize: "11px",
                fontWeight: "bold",
                color: "#ff4f47",
              }}
            >
              {errors.description}
            </FormHelperText>
          </Box>
          <h6
            style={{
              display: "flex",
              justifyContent: "end",
              fontSize: "13px",
              fontWeight: "bold",
              color: "#a49bbe",
              margin: "6px 9px 0px 0px ",
            }}
          >
            Note : Max character limit 1500
          </h6>
          \{" "}
          <Box display="flex" justifyContent="end" marginTop="16px">
            <p
              onClick={() => {
                setSelectedItem(null);
              }}
              style={{
                color: "#2b2639",
                marginRight: " 24px",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              Cancel
            </p>
            <h6
              onClick={isDisable ? handleSubmit : () => {}}
              style={{
                color: isDisable ? "#64c279" : "#a49bbe",
                fontWeight: "bold",
                marginTop: "2px",
                cursor: "pointer",
              }}
            >
              Save
            </h6>
            {/* <Button variant="text">Cancel</Button>
          <Button variant="text">Save</Button> */}
          </Box>
        </Box>
      </ThemeProvider>
    );
  };

  return (
    <>
      {isPreview ? uploadDetailFromPreview() : editProductDetails()}

      {deleteModal && (
        <ConfirmationModalWithIcon
          content={"Are you sure you want to delete this file?"}
          description="The file will be permanently deleted from your profile."
          submitText="Delete"
          Icon={DeleteModalIcon}
          onCancel={() => {
            setDeleteModal(false);
          }}
          onSubmit={handleDeleteItemtrue}
          // handleDeleteItem(selectedItem?.index)

          // deleteCategoryDetailsApi();
        />
      )}
    </>
  );
};
