/*
 **	User name
 **	Created	6/5/2023
 **	PurchaseProvider.tsx
 */

import React, { useEffect, useState } from "react";
import Stripe from "stripe";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "app/redux/reducers";
import { saveStripeCustomerIdTypes } from "app/redux/reducers/userReducer";
import { accountGuidSelector } from "app/redux/selectors/user-selector";
import { useAppSelector } from "app/utils/hooks";
import { cryptr } from "app/utils/helper";
// import Swal from "sweetalert2";
// import { CartActions } from "app/redux/reducers/cart";

import { PurchaseContext } from "./PurchaseContext";

// cost old = sk_test_51N0kkTSJQ6w66fmLVGjD44n8lDVmqlFYPj0CgcMo8glHOj7e9XjJir2nWY77BSrN1J2N5C7yZEmdEeAvDaAE7ZN600cEOUMGZK
// MANI cost new = sk_test_51NJKMWDaRzpJWRSSH7Gw2pYG4RdJQ0KNpO1s59NBEfRmw5vNHDHvkS9DPbOdNtp2iRh2wN4Biio7uK0xwqnIPnZp00kUgt9HUj

const isLive = (() => {
  const host = window.location.host;

  if (host) {
    if (host.indexOf("designerscapitol") > -1) {
      return true;
    }
  }

  return false;
})();

const stripe = new Stripe(
  isLive
    ? process.env.REACT_APP_STRIPE_LIVE
    : process.env.REACT_APP_STRIPE_TEST,
  {
    apiVersion: "2022-11-15",
    typescript: true,
  }
);

const YOUR_DOMAIN = window?.location?.origin || "https://designerscapitol.com";

export const PurchaseProvider = ({ children }) => {
  const dispatch = useDispatch();
  const cartData = useAppSelector((state: IRootState) => state.cart);
  const userData = useSelector((state: IRootState) => state.register);
  const accountGuid = useSelector(accountGuidSelector);
  const saveStripeCustomerIdData = useAppSelector(
    (state) => state.saveStripeCustomerId
  );

  const customerId = userData?.userAdditionalInfo?.customerId;
  const subscriptionId =
    userData?.userAdditionalInfo?.subscriptionId &&
    userData?.userAdditionalInfo?.subscriptionId !== "0"
      ? userData?.userAdditionalInfo?.subscriptionId
      : null;
  const stripeId = userData?.userAdditionalInfo?.stripeId;
  const isRegisteredWithStripe =
    userData?.userAdditionalInfo?.isRegisteredWithStripe;

  const [searchParams] = useSearchParams();
  const [newPlan, setNewPlan] = useState<{
    licenseID: number;
    licenseName: string;
    licenseCost: string;
    licenseDescription: string;
  }>({
    licenseID: null,
    licenseName: "",
    licenseCost: "",
    licenseDescription: "",
  });
  const [showingError, setShowingError] = useState(false);
  const [prorationDate, setProrationDate] = useState(null);
  const [subscriptionDetails, setSubscriptionDetails] =
    useState<null | Stripe.Subscription>(null);
  const [currentSession, setCurrentSession] =
    useState<null | Stripe.Checkout.Session>(null);

  const sessionId = searchParams.get("session_id");
  const stripeAccount =
    searchParams.get("stripeAccount") || searchParams.get("stripeAccountId");

  // useEffect(() => {
  //   if (
  //     cartData?.addError?.responseCode === 400 &&
  //     cartData?.addError?.responseMessage &&
  //     !showingError
  //   ) {
  //     setShowingError(true);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Failed!!",
  //       text: `${cartData?.addError?.responseMessage}`,
  //       allowOutsideClick: false,
  //     }).then((result) => {
  //       setShowingError(false);
  //       if (result.isConfirmed) {
  //         dispatch(CartActions.addToCartClear({}));
  //       }
  //     });
  //   }
  // }, [cartData, showingError]);

  useEffect(() => {
    if (sessionId) {
      reteriveSession({ sessionId, stripeAccount });
    }
  }, [sessionId]);

  useEffect(() => {
    if (currentSession && !newPlan.licenseName) {
      setNewPlan(currentSession?.metadata as any);
    }
  }, [currentSession]);

  const getSubscriptionDetails = async (subscriptionId) => {
    const subscription = await stripe.subscriptions.retrieve(subscriptionId);

    return subscription;
  };

  // useEffect(() => {
  //   if (currentSession?.subscription) {
  //     getSubscriptionDetails(currentSession?.subscription);
  //   }
  // }, [currentSession]);

  useEffect(() => {
    if (subscriptionId && subscriptionId?.length > 3) {
      getSubscriptionDetails(subscriptionId).then((subscription) => {
        setSubscriptionDetails(subscription);
      });
    }
  }, [subscriptionId]);

  useEffect(() => {
    if (stripeId && !isRegisteredWithStripe) {
      retrieveStripeAccount(stripeId)
        .then((account) => {
          if (account.charges_enabled) {
            dispatch({
              type: saveStripeCustomerIdTypes.SAVE_STRIPE_ACCOUNT_ID_REGISTERED,
              payload: { accountGuid, isStripeRegistered: true },
            });
          }
        })
        .catch((error) => {});
    }
  }, [stripeId, isRegisteredWithStripe]);

  const createCheckoutSession = async (data: {
    customerEmail: string;
    customerName: string;
    lineItems: Array<Stripe.Checkout.SessionCreateParams.LineItem>;
    metadata: Record<string, string>;
  }) => {
    let customer;

    if (customerId) {
      try {
        customer = await stripe.customers.retrieve(customerId);
      } catch (error) {
        /* eslint-disable no-console*/
        console.error("error", error);
        customer = await createAndSaveCustomer(data);
      }
    } else {
      customer = await stripe.customers.create({
        email: data?.customerEmail,
        name: data?.customerName,
      });
      dispatch({
        type: saveStripeCustomerIdTypes.SAVE_STRIPE_CUSTOMER_ID,
        payload: {
          customerId: customer?.id,
          accountGuid,
        },
      });
    }

    const session = await stripe.checkout.sessions.create({
      line_items: [...data.lineItems],
      customer: customer?.id,
      // subscription_data: {
      //   trial_period_days: 30,
      // },
      mode: "subscription",
      success_url: `${YOUR_DOMAIN}/current-plan?success=true&session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `${YOUR_DOMAIN}/billing-page?canceled=true&session_id={CHECKOUT_SESSION_ID}`,
      billing_address_collection: "required",
      metadata: data?.metadata,
      // automatic_tax: { enabled: true },
      // invoice_creation: { enabled: true },
    });

    setCurrentSession(session);

    return session;
  };

  const reteriveSession = async ({ sessionId, stripeAccount }) => {
    const session = await stripe.checkout.sessions.retrieve(
      sessionId,
      stripeAccount
        ? {
            stripeAccount,
          }
        : undefined
    );

    setCurrentSession(session);

    return session;
  };

  const resetSession = () => {
    setCurrentSession(null);
  };

  const retrieveSubscriptionUpdateSummary = async (data) => {
    const proration_date = Math.floor(Date.now() / 1000);

    setProrationDate(proration_date);
    const price = await stripe.prices.create({
      currency: "EUR",
      unit_amount: parseFloat(newPlan?.licenseCost) * 100,
      product_data: {
        name: `${newPlan?.licenseName} Plan`,
        metadata: { descripiton: newPlan?.licenseDescription },
      },
      recurring: { interval: "month" },
    });

    const subscription = await stripe.subscriptions.retrieve(subscriptionId);

    const invoice = await stripe.invoices.retrieveUpcoming({
      customer: customerId,
      subscription: subscriptionId,
      subscription_items: [
        {
          id: subscription.items.data[0].id,
          price: price?.id,
        },
      ],
      subscription_proration_date: proration_date,
    });

    return invoice;
  };

  const updateSubscriptionSummary = async (data) => {
    const subscription = await stripe.subscriptions.retrieve(subscriptionId);
    const price = await stripe.prices.create({
      currency: "EUR",
      unit_amount: parseFloat(newPlan?.licenseCost) * 100,
      product_data: {
        name: `${newPlan?.licenseName} Plan`,
        metadata: { descripiton: newPlan?.licenseDescription },
      },
      recurring: { interval: "month" },
    });

    const updateSubscription = stripe.subscriptions.update(subscription.id, {
      cancel_at_period_end: false,
      items: [
        {
          id: subscription.items.data[0].id,
          price: price?.id,
        },
      ],
      proration_date: prorationDate,
    });

    return updateSubscription;
  };

  const createSubscriptionCheckoutSession = async (data) => {
    const price = await stripe.prices.create({
      currency: "EUR",
      unit_amount: parseFloat(newPlan?.licenseCost) * 100,
      product_data: {
        name: `${newPlan?.licenseName} Plan`,
        metadata: { descripiton: newPlan?.licenseDescription },
      },
      recurring: { interval: "month" },
    });

    const lineItems: Array<Stripe.Checkout.SessionCreateParams.LineItem> = [
      {
        price: price?.id,
        quantity: 1,
      },
    ];

    return await createCheckoutSession({
      ...data,
      lineItems,
      metadata: {
        ...newPlan,
        ...data?.metaData,
      },
    });
  };

  const createAndSaveCustomer = async (data) => {
    const customer = await stripe.customers.create({
      email: data?.customerEmail,
      name: data?.customerName,
      // source: "tok_mastercard",
    });

    dispatch({
      type: saveStripeCustomerIdTypes.SAVE_STRIPE_CUSTOMER_ID,
      payload: {
        customerId: customer?.id,
        accountGuid,
      },
    });

    return customer;
  };

  const saveOrReteriveCustomerIdBySellerId = async (data) => {
    const stripeAccount = data.sellerStripeId;

    if (saveStripeCustomerIdData?.customerData?.customerId) {
      try {
        const connectedAccountCustomer = await stripe.customers.retrieve(
          saveStripeCustomerIdData?.customerData?.customerId,
          {
            stripeAccount,
          }
        );

        return connectedAccountCustomer?.id;
      } catch (error) {
        /* eslint-disable no-console*/
        console.error("error", error);
      }
    }

    let customer;

    if (customerId) {
      try {
        customer = await stripe.customers.retrieve(customerId);
      } catch (error) {
        /* eslint-disable no-console*/
        console.error("error", error);
        customer = await createAndSaveCustomer(data);
      }
    } else {
      customer = await createAndSaveCustomer(data);
    }

    // comment suggested
    // const token = await stripe.tokens.create(
    //   {
    //     customer: customer?.id,
    //   },
    //   {
    //     stripeAccount,
    //   }
    // );

    let connectedAccountCustomerid;

    if (!connectedAccountCustomerid) {
      const connectedAccountCustomer = await stripe.customers.create(
        {
          email: data?.customerEmail,
          name: data?.customerName,
          // source: token?.id, //comment suggested
        },
        {
          stripeAccount,
        }
      );

      dispatch({
        type: saveStripeCustomerIdTypes.SAVE_STRIPE_CUSTOMER_ID_BY_SELLER,
        payload: {
          accountGuid,
          customerId: connectedAccountCustomer.id,
          stripeId: stripeAccount,
        },
      });

      connectedAccountCustomerid = connectedAccountCustomer?.id;
    }

    return connectedAccountCustomerid;
  };

  const createPaymentCheckoutSession = async (data) => {
    const stripeAccount = data.sellerStripeId;

    const lineItems: Array<Stripe.Checkout.SessionCreateParams.LineItem> =
      data.products.map((product) => ({
        price_data: {
          currency:
            sessionStorage.getItem("currencyType") === "1" ? "USD" : "EUR",
          unit_amount: parseFloat(product?.productPrice) * 100,
          product_data: {
            name: product?.productName,
            images: product?.productThumbnailUploadedURL
              ? [product?.productThumbnailUploadedURL]
              : [],
          },
        },
        quantity: 1,
      }));
    let customer;

    const metadata: any = {
      cartTotal: data.cartTotal,
      accountGuid: data.accountGuid,
    };

    metadata.BillingData = JSON.stringify(data.BillingData);

    lineItems.forEach((item, index) => {
      metadata[index] = JSON.stringify(item);
    });

    data?.productInCartDetails.forEach((item, index) => {
      metadata[`product_${index}`] = JSON.stringify(item);
    });

    const connectedAccountCustomerid = await saveOrReteriveCustomerIdBySellerId(
      data
    );

    const session = await stripe.checkout.sessions.create(
      {
        line_items: [...lineItems],
        customer: connectedAccountCustomerid,
        mode: "payment",
        success_url: `${YOUR_DOMAIN}/?success=true&session_id={CHECKOUT_SESSION_ID}&stripeAccountId=${stripeAccount}`,
        cancel_url: `${YOUR_DOMAIN}/shopping-cart?canceled=true&session_id={CHECKOUT_SESSION_ID}&stripeAccountId=${stripeAccount}`,
        billing_address_collection: "required",
        metadata,
        invoice_creation: {
          enabled: true,
          invoice_data: {
            metadata,
          },
        },
      },
      {
        stripeAccount,
      }
    );

    setCurrentSession(session);

    return session;
  };

  const retrieveStripeAccount = async (accountId) => {
    const account = await stripe.accounts.retrieve(accountId);

    return account;
  };

  const createStripeConnectAccount = async ({ email, accountId, data }) => {
    let newId = accountId;

    const { productId, isLibraryProduct, productLibraryId } = data;

    const newUrl = (() => {
      if (isLibraryProduct && productLibraryId) {
        return `library-product/${cryptr.encrypt(
          productLibraryId
        )}?id=${productLibraryId}`;
      }
      if (productId) {
        return `products/${cryptr.encrypt(productId)}?id=${productId}`;
      }

      return `products?no-product=true`;
    })();

    if (!newId) {
      const account = await stripe.accounts.create({
        email,
        type: "standard",
      });

      // account?.charges_enabled

      dispatch({
        type: saveStripeCustomerIdTypes.SAVE_STRIPE_ACCOUNT_ID,
        payload: {
          accountGuid,
          stripeId: account.id,
        },
      });
      newId = account.id;
    }

    if (newId) {
      const accountLinks = await stripe.accountLinks.create({
        type: "account_onboarding",
        account: newId,
        return_url: `${YOUR_DOMAIN}/${newUrl}&success=true&stripeAccountId=${newId}`,
        refresh_url: `${YOUR_DOMAIN}/${newUrl}&failure=true&stripeAccountId=${newId}`,
      });

      return accountLinks;
    }

    return null;
  };

  const createRefund = async ({ amount, payment_intent, stripeAccount }) => {
    const refund = await stripe.refunds.create(
      {
        payment_intent,
        amount: amount * 100,
        reason: "requested_by_customer",
      },
      {
        stripeAccount,
      }
    );

    return refund;
  };

  return (
    <PurchaseContext.Provider
      value={{
        customerId,
        subscriptionId,
        prorationDate,
        subscriptionDetails,
        createSubscriptionCheckoutSession,
        setNewPlan,
        newPlan,
        currentSession,
        resetSession,
        createPaymentCheckoutSession,
        createStripeConnectAccount,
        retrieveStripeAccount,
        createRefund,
        retrieveSubscriptionUpdateSummary,
        updateSubscriptionSummary,
      }}
    >
      {children}
    </PurchaseContext.Provider>
  );
};
