import { put, all, call, takeLatest, select } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { categoryReducerTypes } from "../reducers/productReducer/product-category";
import {
  currenciesReducerTypes,
  designerProductsReducerTypes,
  materialListReducerTypes,
  myDraftProductsReducerTypes,
  myProductsReducerTypes,
  productDeleteReducerTypes,
  productDetailaReducerTypes,
  productDetailaWithEachUploadTypes,
  productDownloadActionTypes,
  productFavouriteReducerTypes,
  productFilterReducerTypes,
  productListReducerTypes,
  productPurchsaseReducerTypes,
  productReducerTypes,
  productReversalReducerTypes,
  ProductSizeReducerTypes,
  productThumbnailDNDActionTypes,
  productViewReducerTypes,
  saveProductTypes,
  unpublishProductReducerTypes,
} from "../reducers/productReducer/product-main";
import { uploadDesignReducerTypes } from "../reducers/productReducer/uploadDesign";
import { uploadProductFileReducerTypes } from "../reducers/productReducer/uploadProductFile";
import { tagsReducerTypes } from "../reducers/productReducer/productTags";

const version = process.env.REACT_APP_API_VERSION;
const version3 = process.env.REACT_APP_API_VERSION_3;

function* categoryListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.CATEGORY_LIST.replace("{version}", payload.version)
  );

  return res;
}

function* categoryList({ payload }) {
  try {
    yield put({
      type: categoryReducerTypes.REQUEST_CATEGORY_LIST,
      payload: null,
    });
    const response = yield call(categoryListRequest, {
      version,
      ...payload,
    });

    yield put({
      type: categoryReducerTypes.RESPONSE_CATEGORY_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: categoryReducerTypes.ERROR_CATEGORY_LIST });
  }
}

////

function* productListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.PRODUCT_LIST.replace("{version}", version3),
    payload
  );

  return res;
}

function* productList({ payload }) {
  try {
    yield put({
      type: productListReducerTypes.REQUEST_PRODUCT_LIST,
      payload,
    });
    const response = yield call(productListRequest, payload);

    yield put({
      type: productListReducerTypes.RESPONSE_PRODUCT_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: productListReducerTypes.ERROR_PRODUCT_LIST });
  }
}

///////////////////////////////

function* youngTalentProductListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.YOUNG_TALENT_PRODUCT_LIST.replace("{version}", version3),
    payload
  );

  return res;
}

function* youngTalentProductList({ payload }) {
  try {
    yield put({
      type: productListReducerTypes.REQUEST_YOUNG_TALENT_PRODUCT_LIST,
      payload,
    });
    const response = yield call(youngTalentProductListRequest, payload);

    yield put({
      type: productListReducerTypes.RESPONSE_YOUNG_TALENT_PRODUCT_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: productListReducerTypes.ERROR_YOUNG_TALENT_PRODUCT_LIST,
    });
  }
}

function* savedProductListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.SAVED_PRODUCT_LIST.replace("{version}", version3),
    payload
  );

  return res;
}

function* savedProductList({ payload }) {
  try {
    yield put({
      type: productListReducerTypes.REQUEST_SAVED_PRODUCT_LIST,
      payload,
    });
    const response = yield call(savedProductListRequest, payload);

    yield put({
      type: productListReducerTypes.RESPONSE_SAVED_PRODUCT_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: productListReducerTypes.ERROR_SAVED_PRODUCT_LIST });
  }
}
//
function* crousalProductRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.CROUSAL_PRODUCTS.replace("{version}", version),
    payload
  );

  return res;
}

function* crousalProduct({ payload }) {
  try {
    yield put({
      type: myProductsReducerTypes.REQUEST_CROUSAL_PRODUCT_LIST,
      payload,
    });
    const response = yield call(crousalProductRequest, payload);

    yield put({
      type: myProductsReducerTypes.RESPONSE_CROUSAL_PRODUCT_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: myProductsReducerTypes.ERROR_CROUSAL_PRODUCT_LIST });
  }
}
//
function* productRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_PRODUCT_BY_ID.replace("{version}", version),
    payload
  );

  return res;
}

function* product({ params }) {
  try {
    yield put({
      type: productReducerTypes.REQUEST_GET_PRODUCT_BY_ID,
      payload: null,
    });
    const response = yield call(productRequest, params);

    yield put({
      type: productReducerTypes.RESPONSE_GET_PRODUCT_BY_ID,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: productReducerTypes.ERROR_GET_PRODUCT_BY_ID });
  }
}

//get product details for product section

function* productDtailsForProductRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION.replace(
      "{version}",
      version
    ),
    payload
  );

  return res;
}

function* productDetailsForProduct({ params }) {
  try {
    yield put({
      type: productDetailaReducerTypes.REQUEST_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION,
      payload: null,
    });
    const response = yield call(productDtailsForProductRequest, params);

    yield put({
      type: productDetailaReducerTypes.RESPONSE_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: productDetailaReducerTypes.ERROR_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION,
    });
  }
}

//GET_PRODUCT_WITH_EACH_UPLOAD_TYPE

function* productDtailsWithUploadTypeRequest(payload) {
  let urlNew = EndPoints.GET_PRODUCT_WITH_EACH_UPLOAD_TYPE;

  if (payload.isLibraryProduct) {
    urlNew = EndPoints.GET_LIBRARY_PRODUCT_BY_ID;
  }

  const res = yield request(
    "get",
    urlNew.replace("{version}", version),
    payload
  );

  return res;
}

function* productDtailsWithUploadType({ payload }) {
  try {
    yield put({
      type: productDetailaWithEachUploadTypes.REQUEST_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE,
      payload,
    });
    if (payload?.isViewing && payload?.accountGuid) {
      yield productView({
        payload: {
          accountGuid: payload?.accountGuid,
          productId: payload?.ProductId,
          libraryProductId: payload?.LibraryId,
          isLibraryProduct: payload.isLibraryProduct,
        },
      });
    }
    const response = yield call(productDtailsWithUploadTypeRequest, payload);

    yield put({
      type: productDetailaWithEachUploadTypes.RESPONSE_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: productDetailaWithEachUploadTypes.ERROR_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE,
    });
  }
}

//my product
function* myProductRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.MY_PRODUCTS.replace("{version}", version),
    payload
  );

  return res;
}

function* myProduct({ payload }) {
  try {
    yield put({
      type: myProductsReducerTypes.REQUEST_MY_PRODUCT,
      payload: null,
    });
    const response = yield call(myProductRequest, {
      guid: payload?.accountGuid,
      Page: 1,
      Limit: 20,
    });

    yield put({
      type: myProductsReducerTypes.RESPONSE_MY_PRODUCT,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: myProductsReducerTypes.ERROR_MY_PRODUCT });
  }
}

//my product
function* designerProductRequest(payload) {
  let urlNew = EndPoints.DESIGNER_PRODUCT;

  if (payload?.isLibraryProduct) {
    urlNew = EndPoints.MY_LIBRARY_PRODUCTS;
  }

  const res = yield request(
    "get",
    urlNew.replace("{version}", version),
    payload
  );

  return res;
}

function* designerProduct({ payload }) {
  try {
    yield put({
      type: designerProductsReducerTypes.REQUEST_DESIGNER_PRODUCT,
      payload: null,
    });
    const response = yield call(designerProductRequest, payload);

    yield put({
      type: designerProductsReducerTypes.RESPONSE_DESIGNER_PRODUCT,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: designerProductsReducerTypes.ERROR_DESIGNER_PRODUCT });
  }
}

function* myDraftProductsRequest(payload) {
  let urlNew = EndPoints.MY_DRAFT_PRODUCTS;

  if (payload.isLibraryProduct) {
    urlNew = EndPoints.MY_LIBRARY_DRAFT_PRODUCTS;
  }

  const res = yield request(
    "get",
    urlNew.replace("{version}", version),
    payload
  );

  return res;
}

function* myDraftProducts({ payload }) {
  try {
    yield put({
      type: myDraftProductsReducerTypes.REQUEST_MY_DRAFT_PRODUCT,
      payload: null,
    });
    const response = yield call(myDraftProductsRequest, payload);

    yield put({
      type: myDraftProductsReducerTypes.RESPONSE_MY_DRAFT_PRODUCT,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: myDraftProductsReducerTypes.ERROR_MY_DRAFT_PRODUCT });
  }
}

//upload design
function* uploadDesignRequest(params) {
  const res = yield request(
    "post",
    EndPoints.UPLOAD_PRODUCT.replace("{version}", version),
    params,
    "form-data"
  );

  return res;
}

function* uploadDesign({ payload }) {
  try {
    yield put({
      type: uploadDesignReducerTypes.REQUEST_UPLOAD_DESIGN,
      payload: null,
    });
    const response = yield call(uploadDesignRequest, payload);

    yield put({
      type: uploadDesignReducerTypes.RESPONSE_UPLOAD_DESIGN,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: uploadDesignReducerTypes.ERROR_UPLOAD_DESIGN });
  }
}

//edit product/design
function* editDesignRequest(params) {
  const res = yield request(
    "put",
    EndPoints.EDIT_PRODUCT.replace("{version}", version),
    params,
    "form-data"
  );

  return res;
}

function* editDesign({ payload, noSuccess }) {
  try {
    yield put({
      type: uploadDesignReducerTypes.REQUEST_EDIT_DESIGN,
      payload: null,
    });
    const response = yield call(editDesignRequest, payload);

    const designersProfileNavBar = yield select(
      (state) => state.designersProfileNavBar
    );
    const userData = yield select((state) => state.register);
    const accountGuid = userData?.userInfo?.accountGuid;

    if (designersProfileNavBar) {
      yield put({
        type: designerProductsReducerTypes.DESIGNER_PRODUCT,
        payload: {
          accountGuid,
          loggedInGuid: accountGuid,
          VisibilityId: designersProfileNavBar?.visibilityId || 0,
        },
      });
    }

    if (!noSuccess) {
      yield put({
        type: uploadDesignReducerTypes.RESPONSE_EDIT_DESIGN,
        payload: response?.data,
      });
    } else {
      yield put({
        type: uploadDesignReducerTypes.RESPONSE_EDIT_DESIGN,
        payload: null,
      });
    }
  } catch (e) {
    yield put({ type: uploadDesignReducerTypes.ERROR_EDIT_DESIGN });
  }
}

//product reversal
function* productReversalRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.PRODUCT_REVERSAL.replace("{version}", version),
    payload
  );

  return res;
}

function* productReversal({ params }) {
  try {
    yield put({
      type: productReversalReducerTypes.REQUEST_PRODUCT_REVERSAL,
      payload: null,
    });
    const response = yield call(productReversalRequest, params);

    yield put({
      type: productReversalReducerTypes.RESPONSE_PRODUCT_REVERSAL,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: productReversalReducerTypes.ERROR_PRODUCT_REVERSAL });
  }
}

//product filter
function* productFilterRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.PRODUCT_FILTER.replace("{version}", version),
    payload
  );

  return res;
}

function* productFilter(payload) {
  try {
    yield put({
      type: productFilterReducerTypes.REQUEST_PRODUCT_FILTER,
      payload: null,
    });
    const response = yield call(productFilterRequest, payload.params);

    yield put({
      type: productFilterReducerTypes.RESPONSE_PRODUCT_FILTER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: productFilterReducerTypes.ERROR_PRODUCT_FILTER });
  }
}

//product purchase
function* productPurchaseRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.PRODUCR_PURCHASE.replace("{version}", version),
    payload
  );

  return res;
}

function* productPurchase(payload) {
  try {
    yield put({
      type: productPurchsaseReducerTypes.REQUEST_PRODUCT_PURCHASE,
      payload: null,
    });
    const response = yield call(productPurchaseRequest, payload.params);

    yield put({
      type: productPurchsaseReducerTypes.RESPONSE_PRODUCT_PURCHASE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: productPurchsaseReducerTypes.ERROR_PRODUCT_PURCHASE });
  }
}

//product purchase
function* productFavouriteRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.SET_PRODUCT_FAVOURITE.replace("{version}", version),
    payload
  );

  return res;
}

function* productFavourite({ payload }) {
  try {
    yield put({
      type: productFavouriteReducerTypes.REQUEST_SET_PRODUCT_FAVOURITE,
      payload: null,
    });
    const response = yield call(productFavouriteRequest, payload);

    yield put({
      type: productFavouriteReducerTypes.RESPONSE_SET_PRODUCT_FAVOURITE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: productFavouriteReducerTypes.ERROR_SET_PRODUCT_FAVOURITE,
    });
  }
}

////
function* productViewRequest(payload) {
  let urlNew = EndPoints.SET_PRODUCT_VIEW;

  if (payload.isLibraryProduct) {
    urlNew = EndPoints.SET_LIBRARY_PRODUCT_VIEW;
  }

  const res = yield request(
    "post",
    urlNew.replace("{version}", version),
    payload
  );

  return res;
}

function* productView({ payload }) {
  try {
    yield put({
      type: productViewReducerTypes.REQUEST_SET_PRODUCT_VIEW,
      payload,
    });
    const response = yield call(productViewRequest, payload);

    yield put({
      type: productViewReducerTypes.RESPONSE_SET_PRODUCT_VIEW,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: productViewReducerTypes.ERROR_SET_PRODUCT_VIEW,
    });
  }
}

// upload file for different categories

function* uploadProductFileForDiffCategoriesRequest(
  params,
  onUploadProgress,
  length
) {
  const res = yield request(
    "post",
    EndPoints.UPLOAD_PRODUCT_FILE_FOR_DIFFERENT_CATEGORIES.replace(
      "{version}",
      version
    ),
    params,
    "form-data",
    {
      onUploadProgress,
    }
  );

  return res;
}

function* uploadProductFileForDiffCategories({ payload, onUploadProgress }) {
  try {
    yield put({
      type: uploadProductFileReducerTypes.REQUEST_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      payload: null,
    });
    const response = yield call(
      uploadProductFileForDiffCategoriesRequest,
      payload,
      onUploadProgress
    );

    yield put({
      type: uploadProductFileReducerTypes.RESPONSE_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: uploadProductFileReducerTypes.ERROR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
    });
  }
}

//delete upload file for different categories

function* deleteUploadProductFileForDiffCategoriesRequest(params) {
  const res = yield request(
    "delete",
    EndPoints.DELETE_PRODUCT_FILE_FOR_DIFFERENT_CATEGORIES.replace(
      "{version}",
      version
    ),
    params
  );

  return res;
}

function* deleteUploadProductFileForDiffCategories({ payload }) {
  try {
    yield put({
      type: uploadProductFileReducerTypes.REQUEST_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      payload: null,
    });
    const response = yield call(
      deleteUploadProductFileForDiffCategoriesRequest,
      payload
    );

    yield put({
      type: uploadProductFileReducerTypes.RESPONSE_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: uploadProductFileReducerTypes.ERROR_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
    });
  }
}

//upload file for different categories

function* clearUploadProductFileForDiffCategoriesRequest(params) {
  const res = yield request(
    "put",
    EndPoints.CLEAR_UPLOAD_PRODUCT_FILE_FOR_DIFFERENT_CATEGORIES.replace(
      "{version}",
      version
    ),
    params
  );

  return res;
}

function* clearUploadProductFileForDiffCategories({ payload }) {
  try {
    yield put({
      type: uploadProductFileReducerTypes.REQUEST_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      payload: null,
    });
    const response = yield call(
      clearUploadProductFileForDiffCategoriesRequest,
      payload
    );

    yield put({
      type: uploadProductFileReducerTypes.RESPONSE_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: uploadProductFileReducerTypes.ERROR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
    });
  }
}

//upload THUMBNAIL FILE

function* uploadProductThumbnailRequest(params, onUploadProgress) {
  const res = yield request(
    "post",
    EndPoints.PRODUCT_THUMBNAIL_UPLOAD.replace("{version}", version),
    params,
    "form-data",
    {
      onUploadProgress,
    }
  );

  return res;
}

function* uploadProductThumbnail({ payload, onUploadProgress }) {
  try {
    yield put({
      type: uploadProductFileReducerTypes.REQUEST_UPLOAD_PRODUCT_THUMBNAIL_FILE,
      payload: null,
    });
    const response = yield call(
      uploadProductThumbnailRequest,
      payload,
      onUploadProgress
    );

    yield put({
      type: uploadProductFileReducerTypes.RESPONSE_UPLOAD_PRODUCT_THUMBNAIL_FILE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: uploadProductFileReducerTypes.ERROR_UPLOAD_PRODUCT_THUMBNAIL_FILE,
    });
  }
}

//upload file

function* uploadProductFileRequest(params, onUploadProgress) {
  const res = yield request(
    "post",
    EndPoints.UPLOAD_PRODUCT_FILE.replace("{version}", version),
    params,
    "form-data",
    {
      onUploadProgress,
    }
  );

  return res;
}

function* uploadProductFile({ payload, onUploadProgress }) {
  try {
    yield put({
      type: uploadProductFileReducerTypes.REQUEST_UPLOAD_PRODUCT_FILE,
      payload: null,
    });
    const response = yield call(
      uploadProductFileRequest,
      payload,
      onUploadProgress
    );

    yield put({
      type: uploadProductFileReducerTypes.RESPONSE_UPLOAD_PRODUCT_FILE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: uploadProductFileReducerTypes.ERROR_UPLOAD_PRODUCT_FILE,
    });
  }
}

//get all material list

function* materialListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_PRODUCT_MATERIALS.replace("{version}", version)
  );

  return res;
}

function* materialList() {
  try {
    yield put({
      type: materialListReducerTypes.REQUEST_MATERIAL_LIST,
      payload: null,
    });
    const response = yield call(materialListRequest);

    yield put({
      type: materialListReducerTypes.RESPONSE_MATERIAL_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: materialListReducerTypes.ERROR_MATERIAL_LIST });
  }
}

//get all product size list

function* productSizeRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_PRODUCT_SIZE.replace("{version}", version)
  );

  return res;
}

function* productSize() {
  try {
    yield put({
      type: ProductSizeReducerTypes.REQUEST_PRODUCT_SIZE,
      payload: null,
    });
    const response = yield call(productSizeRequest);

    yield put({
      type: ProductSizeReducerTypes.RESPONSE_PRODUCT_SIZE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: ProductSizeReducerTypes.ERROR_PRODUCT_SIZE });
  }
}

//get all Product Tags
function* productTagRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_PRODUCT_TAG.replace("{version}", version)
  );

  return res;
}

function* productTag() {
  try {
    yield put({ type: tagsReducerTypes.REQUEST_TAGS_LIST, payload: null });
    const response = yield call(productTagRequest);

    yield put({
      type: tagsReducerTypes.RESPONSE_TAGS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: tagsReducerTypes.ERROR_TAGS_LIST });
  }
}

//get all currencies list

function* currenciesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_CURRENCIES.replace("{version}", version)
  );

  return res;
}

function* currencies() {
  try {
    yield put({
      type: currenciesReducerTypes.REQUEST_CURRENCIES,
      payload: null,
    });
    const response = yield call(currenciesRequest);

    yield put({
      type: currenciesReducerTypes.RESPONSE_CURRENCIES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: currenciesReducerTypes.ERROR_CURRENCIES });
  }
}

function* deleteProductByProductIdRequest(payload) {
  let urlNew = EndPoints.DELETE_PRODUCT_BY_PRODUCT_ID;

  if (payload.isLibraryProduct) {
    urlNew = EndPoints.DELETE_LIBRARY_PRODUCT_BY_PRODUCT_ID;
  }

  const res = yield request(
    "delete",
    urlNew.replace("{version}", version),
    payload
  );

  return res;
}

function* deleteProductByProductId({ payload }) {
  try {
    yield put({
      type: productDeleteReducerTypes.REQUEST_DELETE_PRODUCT_BY_PRODUCT_ID,
      payload: null,
    });
    const response = yield call(deleteProductByProductIdRequest, payload);

    yield put({
      type: productDeleteReducerTypes.RESPONSE_DELETE_PRODUCT_BY_PRODUCT_ID,
      payload: response?.data,
    });
    yield put({
      type: designerProductsReducerTypes.DESIGNER_PRODUCT,
      payload,
    });
    yield put({
      type: myDraftProductsReducerTypes.MY_DRAFT_PRODUCT,
      payload,
    });
  } catch (e) {
    yield put({
      type: productDeleteReducerTypes.ERROR_DELETE_PRODUCT_BY_PRODUCT_ID,
    });
  }
}

function* unpublishProductRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.UNPUBLISH_PRODUCT_BY_PRODUCT_ID.replace("{version}", version),
    payload
  );

  return res;
}

function* unpublishProduct({ payload }) {
  try {
    yield put({
      type: unpublishProductReducerTypes.REQUEST_UNPUBLISH_PRODUCT,
      payload: null,
    });
    const response = yield call(unpublishProductRequest, payload);

    yield put({
      type: unpublishProductReducerTypes.RESPONSE_UNPUBLISH_PRODUCT,
      payload: response?.data,
    });
    yield put({
      type: designerProductsReducerTypes.DESIGNER_PRODUCT,
      payload,
    });
    yield put({
      type: myDraftProductsReducerTypes.MY_DRAFT_PRODUCT,
      payload,
    });
  } catch (e) {
    yield put({ type: unpublishProductReducerTypes.ERROR_UNPUBLISH_PRODUCT });
  }
}

function* productThumbnailDNDRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.PRODUCT_THUMBNAIL_UPLOAD_DND.replace("{version}", version),
    payload
  );

  return res;
}

function* productThumbnailDND({ payload }) {
  try {
    yield put({
      type: productThumbnailDNDActionTypes.REQUEST_PRODUCT_THUMBNAIL_UPLOAD_DND,
      payload: null,
    });
    const response = yield call(productThumbnailDNDRequest, payload);

    yield put({
      type: productThumbnailDNDActionTypes.RESPONSE_PRODUCT_THUMBNAIL_UPLOAD_DND,
      payload: response?.data,
    });

    yield put({
      type: productDetailaWithEachUploadTypes.GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE,
      payload: {
        ProductId: payload?.productId || 7689,
        accountGuid: payload.accountGuid,
      },
    });
  } catch (e) {
    yield put({
      type: productThumbnailDNDActionTypes.ERROR_PRODUCT_THUMBNAIL_UPLOAD_DND,
    });
  }
}

/////

function* productDownloadRequest(payload) {
  let urlNew = EndPoints.PRODUCT_DETAILS_DOWNLOAD;

  if (payload.isLibraryProduct) {
    urlNew = EndPoints.LIBRARY_PRODUCT_DETAILS_DOWNLOAD;
  }

  const res = yield request(
    "post",
    urlNew.replace("{version}", version3),
    payload
  );

  return res;
}

function* productDownload({ payload }) {
  try {
    yield put({
      type: productDownloadActionTypes.REQUEST_PRODUCT_DETAILS_DOWNLOAD,
      payload: null,
    });
    const response = yield call(productDownloadRequest, payload);

    yield put({
      type: productDownloadActionTypes.RESPONSE_PRODUCT_DETAILS_DOWNLOAD,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: productDownloadActionTypes.ERROR_PRODUCT_DETAILS_DOWNLOAD,
    });
  }
}

/////

function* saveProductRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.SAVE_PRODUCT.replace("{version}", version3),
    payload
  );

  return res;
}

function* saveProduct({ payload }) {
  try {
    yield put({
      type: saveProductTypes.REQUEST_SAVE_PRODUCT,
      payload: null,
    });
    const response = yield call(saveProductRequest, payload);

    yield put({
      type: saveProductTypes.RESPONSE_SAVE_PRODUCT,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: saveProductTypes.ERROR_SAVE_PRODUCT, payload: e });
  }
}

/////

function* productSaga() {
  yield all([
    takeLatest(myProductsReducerTypes.CROUSAL_PRODUCT_LIST, crousalProduct),
    takeLatest(categoryReducerTypes.CATEGORY_LIST, categoryList),
    takeLatest(productListReducerTypes.PRODUCT_LIST, productList),
    takeLatest(
      productListReducerTypes.YOUNG_TALENT_PRODUCT_LIST,
      youngTalentProductList
    ),
    takeLatest(productListReducerTypes.SAVED_PRODUCT_LIST, savedProductList),
    takeLatest(productReducerTypes.GET_PRODUCT_BY_ID, product),
    takeLatest(
      productDetailaReducerTypes.GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION,
      productDetailsForProduct
    ),
    takeLatest(
      productDetailaWithEachUploadTypes.GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE,
      productDtailsWithUploadType
    ),
    takeLatest(myProductsReducerTypes.MY_PRODUCT, myProduct),
    takeLatest(myDraftProductsReducerTypes.MY_DRAFT_PRODUCT, myDraftProducts),
    takeLatest(uploadDesignReducerTypes.UPLOAD_DESIGN, uploadDesign),
    takeLatest(uploadDesignReducerTypes.EDIT_DESIGN, editDesign),
    takeLatest(productReversalReducerTypes.PRODUCT_REVERSAL, productReversal),
    takeLatest(productFilterReducerTypes.PRODUCT_FILTER, productFilter),
    takeLatest(productPurchsaseReducerTypes.PRODUCT_PURCHASE, productPurchase),
    takeLatest(
      productFavouriteReducerTypes.SET_PRODUCT_FAVOURITE,
      productFavourite
    ),
    takeLatest(
      uploadProductFileReducerTypes.UPLOAD_PRODUCT_FILE,
      uploadProductFile
    ),
    takeLatest(
      uploadProductFileReducerTypes.UPLOAD_PRODUCT_THUMBNAIL_FILE,
      uploadProductThumbnail
    ),
    takeLatest(
      uploadProductFileReducerTypes.UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      uploadProductFileForDiffCategories
    ),
    takeLatest(
      uploadProductFileReducerTypes.DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      deleteUploadProductFileForDiffCategories
    ),
    takeLatest(materialListReducerTypes.MATERIAL_LIST, materialList),
    takeLatest(ProductSizeReducerTypes.PRODUCT_SIZE, productSize),
    takeLatest(tagsReducerTypes.TAGS_LIST, productTag),
    takeLatest(currenciesReducerTypes.CURRENCIES, currencies),
    takeLatest(
      productDeleteReducerTypes.DELETE_PRODUCT_BY_PRODUCT_ID,
      deleteProductByProductId
    ),
    takeLatest(
      unpublishProductReducerTypes.UNPUBLISH_PRODUCT,
      unpublishProduct
    ),
    takeLatest(
      uploadProductFileReducerTypes.CLEAR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
      clearUploadProductFileForDiffCategories
    ),
    takeLatest(designerProductsReducerTypes.DESIGNER_PRODUCT, designerProduct),
    takeLatest(
      productThumbnailDNDActionTypes.PRODUCT_THUMBNAIL_UPLOAD_DND,
      productThumbnailDND
    ),
    takeLatest(
      productDownloadActionTypes.PRODUCT_DETAILS_DOWNLOAD,
      productDownload
    ),
    takeLatest(saveProductTypes.SAVE_PRODUCT, saveProduct),
    takeLatest(productViewReducerTypes.SET_PRODUCT_VIEW, productView),
  ]);
}
export default productSaga;
