/*
 **	User name
 **	Created	5/25/2023
 **	LibraryDropdown.tsx
 */

import React, { useState } from "react";
import { Box, useTheme, Typography, useMediaQuery } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { navBarTypes } from "app/redux/reducers/navBar";
import { IRootState } from "app/redux/reducers";
import { Link } from "react-router-dom";
import RouteLinks from "app/navigation/RouteLinks";

import {
  useNavBarStyles,
  useCategoryDropdownSyle,
} from "../../../styles/navbarStyles";
import { DropDown } from "../Logo/logos";

const ItemComp = ({
  item,
  handleClick,
}: {
  item: {
    id: string;
    name: string;
    url: string;
    hoverURL: string;
  };
  handleClick: () => void;
}) => {
  const categoryStyles = useCategoryDropdownSyle();

  return (
    <div
      key={item.name}
      className={categoryStyles.productBox}
      onClick={handleClick}
      style={{ padding: "0px" }}
    >
      <Link to={`${RouteLinks.LIBRARY_PRODUCTS}?categoryIds=${item.id}`}>
        <img className={categoryStyles.img} src={item.url} alt={item.name} />
        <img
          className={categoryStyles.hoverImg}
          src={item.hoverURL}
          alt={item.name}
        />
        <p className={categoryStyles.product} style={{ textAlign: "left" }}>
          {item.name}
        </p>
      </Link>
    </div>
  );
};

export default function LibraryDropdown() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useNavBarStyles();
  const categoryStyles = useCategoryDropdownSyle();
  const theme = useTheme();
  const categoriesData = useSelector(
    (state: IRootState) => state.categories,
    shallowEqual
  );
  const navBarState = useSelector((state: IRootState) => state.navBarReducer);

  const [dropdownClassName, setDropdownClassName] =
    useState("dropdown-content");

  const handleMenuClicks = () => {
    dispatch({
      type: navBarTypes.NAV_BAR_CATEGORIES,
    });
    setDropdownClassName("dropdown-content-hidden");
    setTimeout(() => {
      setDropdownClassName("dropdown-content");
    }, 400);
  };

  const handleCategoriesClick = () => {
    handleMenuClicks();
    navigate("/library");
  };

  const catData = categoriesData?.libraryData || [];

  const handleNaming = (name) => {
    return name?.replaceAll(" ", "")?.replaceAll("&", "");
  };

  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <div
        className="dropdown  w-100 w-md-auto responsivedropdown_1"
        style={{
          alignItems: "center",
          height: "100px",
        }}
      >
        <div
          onClick={handleCategoriesClick}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <span
            className={
              theme?.palette?.mode === "light"
                ? navBarState.categories
                  ? `${classes.navbar_li_a_light} newHeaderNavBar HeaderActiveNavBar`
                  : `${classes.navbar_li_a_light} newHeaderNavBar`
                : navBarState.categories
                ? `${classes.navbar_li_a_dark} newHeaderNavBar HeaderActiveNavBar`
                : `${classes.navbar_li_a_dark} newHeaderNavBar`
            }
            // className="d-flex justify-content-between"
            onClick={handleCategoriesClick}
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              style={{
                position: "relative",
                right: "15px",
                fontFamily: "inherit",
              }}
            >
              {" "}
              Library
            </Typography>

            <DropDown />
          </span>
        </div>
        <div className="droppop" style={{ maxWidth: 200 }}>
          <div
            className={classNames(dropdownClassName, categoryStyles.content)}
            style={{
              width: "var(--container-width)",
              minHeight: "702px",
              position: "relative",
              top: "45px",
              right: 442,
              boxShadow: "0 16px 34px 0 rgba(104, 62, 216, 0.1)",
              borderRadius: "0 0 20px 20px",
              // display: "flex",
            }}
          >
            <Box
              style={{
                boxShadow: "0px 10px 10px 0px rgba(104, 62, 216, 0.1) inset",
                padding: "20px",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexFlow: "column",
                  flexWrap: "wrap",
                  maxHeight: 590,
                }}
              >
                {catData?.map((item) => (
                  <div key={item.name} style={{ width: 250 }}>
                    <Typography
                      component={Link}
                      className={categoryStyles.subHeading}
                      to={`${RouteLinks.LIBRARY_PRODUCTS}?categoryIds=${item.id}`}
                      onClick={handleMenuClicks}
                    >
                      {item.name}
                    </Typography>
                    {item?.subCategory?.map((item2) => (
                      <ItemComp
                        handleClick={handleMenuClicks}
                        key={item2.name}
                        item={item2}
                      />
                    ))}
                  </div>
                ))}
              </div>
            </Box>
          </div>
        </div>
      </div>

      <div
        className="accordion accordion-flush responsivedropdown_2"
        id="accordionFlushExample"
      >
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne_Library"
              aria-expanded="false"
              aria-controls="flush-collapseOne_Library"
              style={{
                height: "30px",
                backgroundColor: "#ffff",
                boxShadow: "none",
              }}
            >
              <span
                className={
                  theme?.palette?.mode === "light"
                    ? navBarState.categories
                      ? `${classes.navbar_li_a_light}  ${
                          isMdScreen ? "" : "newHeaderNavBar HeaderActiveNavBar"
                        }`
                      : `${classes.navbar_li_a_light} newHeaderNavBar`
                    : navBarState.categories
                    ? `${classes.navbar_li_a_dark}  ${
                        isMdScreen ? "" : "newHeaderNavBar HeaderActiveNavBar"
                      }`
                    : `${classes.navbar_li_a_dark} newHeaderNavBar`
                }
                onClick={handleCategoriesClick}
                // className="d-flex justify-content-between"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                }}
                data-bs-dismiss="offcanvas"
              >
                <span style={{ position: "relative", right: "15px" }}>
                  {" "}
                  Library
                </span>
              </span>
            </button>
          </h2>
          <div
            id="flush-collapseOne_Library"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {catData?.map((item, index) => (
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        // data-bs-target="#panelsStayOpen-collapseOne"
                        data-bs-target={`#${handleNaming(
                          item?.name
                        )}panelsStayOpen-${handleNaming(item?.name)}`}
                        aria-expanded="true"
                        // aria-controls="panelsStayOpen-collapseOne"
                        aria-controls={`${handleNaming(
                          item?.name
                        )}panelsStayOpen-${handleNaming(item?.name)}`}
                        style={{
                          padding: "0px",
                          backgroundColor: "#ffff",
                          boxShadow: "none",
                        }}
                      >
                        <Typography
                          component={Link}
                          className={categoryStyles.subHeading}
                          to={`${RouteLinks.PRODUCTS}?categoryIds=${item?.id}`}
                          onClick={handleMenuClicks}
                        >
                          {item?.name}
                        </Typography>
                      </button>
                    </h2>

                    {item?.subCategory?.map((item2) => (
                      <div
                        // id="panelsStayOpen-collapseOne"
                        id={`${handleNaming(
                          item?.name
                        )}panelsStayOpen-${handleNaming(item?.name)}`}
                        className="accordion-collapse collapse"
                        style={{ padding: "0px" }}
                        data-bs-dismiss="offcanvas"
                      >
                        <div
                          className="accordion-body"
                          style={{ padding: "0px" }}
                        >
                          {" "}
                          <ItemComp
                            handleClick={handleMenuClicks}
                            key={item2.name}
                            item={item2}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
