import { userRegisterReducerTypes } from "./userReducer";

const initialState = {
  fetchError: null,
  fetchData: null,
  fetchStatus: null,

  updateError: null,
  updateData: null,
  updateStatus: null,
};

export const invoiceSettingsActionTypes = {
  UPDATE_INVOICE_SETTINGS: "UPDATE_INVOICE_SETTINGS",
  UPDATE_INVOICE_SETTINGS_REQUEST: "UPDATE_INVOICE_SETTINGS_REQUEST",
  UPDATE_INVOICE_SETTINGS_SUCCESS: "UPDATE_INVOICE_SETTINGS_SUCCESS",
  UPDATE_INVOICE_SETTINGS_FAILURE: "UPDATE_INVOICE_SETTINGS_FAILURE",
  UPDATE_INVOICE_SETTINGS_CLEAR: "UPDATE_INVOICE_SETTINGS_CLEAR",

  FETCH_INVOICE_SETTINGS: "FETCH_INVOICE_SETTINGS",
  FETCH_INVOICE_SETTINGS_REQUEST: "FETCH_INVOICE_SETTINGS_REQUEST",
  FETCH_INVOICE_SETTINGS_SUCCESS: "FETCH_INVOICE_SETTINGS_SUCCESS",
  FETCH_INVOICE_SETTINGS_FAILURE: "FETCH_INVOICE_SETTINGS_FAILURE",
  FETCH_INVOICE_SETTINGS_CLEAR: "FETCH_INVOICE_SETTINGS_CLEAR",
};

function reducerFunc(state = initialState, action) {
  switch (action.type) {
    case invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_REQUEST:
      return {
        ...state,
        updateError: null,
        updateStatus:
          invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_REQUEST,
      };
    case invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_FAILURE:
      return {
        ...state,
        updateError: action.payload,
        updateStatus:
          invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_FAILURE,
      };
    case invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_SUCCESS:
      return {
        ...state,
        updateStatus:
          invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_SUCCESS,
        updateData: action.payload,
      };

    case invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_REQUEST:
      return {
        ...state,
        fetchError: null,
        fetchStatus: invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_REQUEST,
      };
    case invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_FAILURE:
      return {
        ...state,
        fetchError: action.payload,
        fetchStatus: invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_FAILURE,
      };
    case invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_SUCCESS:
      return {
        ...state,
        fetchStatus: invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_SUCCESS,
        fetchData: action.payload,
      };
    case invoiceSettingsActionTypes.UPDATE_INVOICE_SETTINGS_CLEAR:
    case invoiceSettingsActionTypes.FETCH_INVOICE_SETTINGS_CLEAR:
    case userRegisterReducerTypes.LOGOUT_USER:
      return { ...initialState };

    default:
      return state;
  }
}

export default reducerFunc;
