/*
 **	User name
 **	Created	5/25/2023
 **	libraryList.ts
 */

const initState = {
  loading: false,
  data: null,
  error: null,
};

//Library Categorylist
export const libraryCategoriesyListReducerTypes = {
  LIBRARY_CATEGORIES_LIST: "LIBRARY_CATEGORIES_LIST",
  REQUEST_LIBRARY_CATEGORIES_LIST: "REQUEST_LIBRARY_CATEGORIES_LIST",
  RESPONSE_LIBRARY_CATEGORIES_LIST: "RESPONSE_LIBRARY_CATEGORIES_LIST",
  ERROR_LIBRARY_CATEGORIES_LIST: "ERROR_LIBRARY_CATEGORIES_LIST",
};

export function libraryCategoriesyListReducer(state = initState, action) {
  switch (action.type) {
    case libraryCategoriesyListReducerTypes.REQUEST_LIBRARY_CATEGORIES_LIST:
      return { ...state, loading: true };
    case libraryCategoriesyListReducerTypes.RESPONSE_LIBRARY_CATEGORIES_LIST:
      return { ...state, error: null, loading: false, data: action.payload };
    case libraryCategoriesyListReducerTypes.ERROR_LIBRARY_CATEGORIES_LIST:
      return { ...state, loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export const libraryFavouriteReducerTypes = {
  SET_LIBRARY_FAVOURITE: "SET_LIBRARY_FAVOURITE",
  REQUEST_SET_LIBRARY_FAVOURITE: "REQUEST_SET_LIBRARY_FAVOURITE",
  RESPONSE_SET_LIBRARY_FAVOURITE: "RESPONSE_SET_LIBRARY_FAVOURITE",
  ERROR_SET_LIBRARY_FAVOURITE: "ERROR_SET_LIBRARY_FAVOURITE",
  CLEAR_SET_LIBRARY_FAVOURITE: "CLEAR_SET_LIBRARY_FAVOURITE",
};

export function libraryFavouriteReducer(state = initState, action) {
  switch (action.type) {
    case libraryFavouriteReducerTypes.REQUEST_SET_LIBRARY_FAVOURITE:
      return { ...state, loading: true };
    case libraryFavouriteReducerTypes.RESPONSE_SET_LIBRARY_FAVOURITE:
      return { error: null, loading: false, data: action.payload };
    case libraryFavouriteReducerTypes.ERROR_SET_LIBRARY_FAVOURITE:
      return { loading: false, data: null, error: action.payload };
    case libraryFavouriteReducerTypes.CLEAR_SET_LIBRARY_FAVOURITE:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
