/*
 **	User name
 **	Created	5/27/2023
 **	navbarStyles.ts
 */

import { makeStyles } from "@mui/styles";

export const useNavBarStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignText: "center",
    // padding: "38px 0px 39px 0px",
    margin: "40px 0px 20px ",
  },
  navbar_li: {
    display: "inline-block",
    paddingRight: "16px",
  },
  navbar_li_a_dark: {
    cursor: "pointer",
    position: "relative !important" as any,
    color: "#999",
    "&:hover": {
      color: "#fff",
    },
  },
  navbar_li_a_light: {
    cursor: "pointer",
    position: "relative !important" as any,
    color: "#999",
    "&:hover": {
      color: "black",
    },
  },
  nav_ul_li_a: {
    "&::after": {
      content: "",
      position: "absolute",
      bottom: "85px",
      right: 0,
      left: "auto",
      width: 0,
      height: "3px",
      backgroundColor: "#f25757",
      transition: "all .25s cubic - bezier(.694, .048, .335, 1)",
    },
    "&:hover": {
      color: "#fff",
      "&::after": {
        content: "",
        position: "absolute",
        bottom: "-8px",
        right: 0,
        left: "auto",
        width: 0,
        height: "3px",
        backgroundColor: "#f25757",
        transition: "all .25s cubic - bezier(.694, .048, .335, 1)",
      },
      "::before": {
        width: "100%",
        left: 0,
      },
    },
  },
  nav_ul_li_a_selected: {
    content: "",
    position: "absolute",
    bottom: "-8px",
    right: 0,
    left: 0,
    width: "100%",
    height: "3px",
    backgroundColor: "#f25757",
    transition: "all .25s cubic - bezier(.694, .048, .335, 1)",
  },
  activeHeaderNavBar: {
    fontWeight: "600",
    height: "3px",
    borderBottom: "3px solid #f25757",
    paddingBottom: "5px",
    position: "absolute",
    bottom: "30px",
    width: "100%",
    left: "0",
  },
  //new header
  header_navbar_li: {
    display: "inline-block",
    // paddingRight: "16px",
    paddingRight: "10px",
  },
  header_navbar_li_a_dark: {
    cursor: "pointer",
    position: "relative !important" as any,
    color: "#999",
    "&:hover": {
      color: "#fff",
      fontWeight: "bold",
    },
  },
  header_navbar_li_a_light: {
    cursor: "pointer",
    position: "relative !important" as any,
    color: "#999",
    "&:hover": {
      color: "black",
      fontWeight: "bold",
    },
  },
  NavBarElement: {
    alignItems: "initial !important",
    display: "initial !important",
    height: "initial !important",
    minWidth: "60px !important",
    padding: "5px !important",
  },
  headerWidth: {
    width: "100% !important",
  },
  [theme.breakpoints.down("md")]: {
    NavBarElement: {
      alignItems: "center !important",
      display: "flex !important",
    },
    navbar_li_a_light: { fontSize: "12px !important" },
    navbar_li_a_dark: { fontSize: "12px !important" },
    headerWidth: { width: "300px !important" },
  },
}));

export const useCategoryDropdownSyle = makeStyles((theme) => ({
  subHeading: {
    margin: "12px 0 !important",
    fontSize: "13px !important",
    fontWeight: "bold !important",
    textAlign: "left",
    color: "#2b2639 !important",
    display: "inline-block",

    "&:hover": {
      color: "#7a48ff !important",
    },
  },

  productBox: {
    display: "flex",
    alignItems: "center",
    width: "250px",
    cursor: "pointer",

    "&:hover $hoverImg": {
      display: "inline-block",
    },
    "&:hover $img": {
      display: "none",
    },
    "&:hover $product": {
      color: "#7a48ff !important",
    },
  },

  product: {
    margin: "4px !important",
    minWidth: "100px",
    color: "#2b2639 !important",
    display: "inline-block",
  },

  activeStyleButton: {
    color: "white !important",
    backgroundColor: "#2b2639 !important",
    padding: "8px !important",
    borderRadius: "6px !important",
    margin: "0px 10px 24px 0px !important",
    width: "48px !important",
    height: "39px !important",
    textTransform: "capitalize",
  },

  inactiveStyleButton: {
    // backgroundColor: "#2b2639",
    padding: "8px !important",
    borderRadius: "6px !important",
    margin: "0px 10px 24px 0px !important",
    width: "48px !important",
    height: "39px !important",
    textTransform: "capitalize",
    color: "#a49bbe !important",
  },

  img: {
    width: 18,
    height: 18,
  },

  hoverImg: {
    width: 18,
    height: 18,
    display: "none",
  },

  content: {
    maxHeight: "initial",
    background: "#fff",
  },
}));
