const initState = {
  loading: false,
  data: null,
  error: null,
};

//upload Profile Pic
export const uploadProfilePicTypes = {
  UPLOAD_PROFILE_PIC: "UPLOAD_PROFILE_PIC",
  REQUEST_UPLOAD_PROFILE_PIC: "REQUEST_UPLOAD_PROFILE_PIC",
  RESPONSE_UPLOAD_PROFILE_PIC: "RESPONSE_UPLOAD_PROFILE_PIC",
  ERROR_UPLOAD_PROFILE_PIC: "ERROR_UPLOAD_PROFILE_PIC",
  CLEAR_UPLOAD_PROFILE_PIC: "CLEAR_UPLOAD_PROFILE_PIC",
};

export function uploadProfilePicReducer(state = initState, action) {
  switch (action.type) {
    case uploadProfilePicTypes.REQUEST_UPLOAD_PROFILE_PIC:
      return { ...state, loading: true };
    case uploadProfilePicTypes.RESPONSE_UPLOAD_PROFILE_PIC:
      return { error: null, loading: false, data: action.payload };
    case uploadProfilePicTypes.ERROR_UPLOAD_PROFILE_PIC:
      return { loading: false, data: null, error: action.payload };
    case uploadProfilePicTypes.CLEAR_UPLOAD_PROFILE_PIC:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
