import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { homeActionTypes } from "../reducers/home";

const version = process.env.REACT_APP_API_VERSION;

function* fetchAllHomeProductsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_HOME_PRODUCTS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchAllHomeProducts({ payload }: any) {
  try {
    yield put({
      type: homeActionTypes.GET_ALL_HOME_PRODUCTS_REQUEST,
      payload,
    });
    const response = yield call(fetchAllHomeProductsRequest, payload);

    yield put({
      type: homeActionTypes.GET_ALL_HOME_PRODUCTS_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: homeActionTypes.GET_ALL_HOME_PRODUCTS_FAILURE,
      payload: e,
    });
  }
}

function* mainSaga() {
  yield all([
    takeLatest(homeActionTypes.GET_ALL_HOME_PRODUCTS, fetchAllHomeProducts),
  ]);
}
export default mainSaga;
