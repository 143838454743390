/*
 **	User name
 **	Created	7/26/2023
 **	usersaga.ts
 */

import { put, all, call, takeLatest } from "redux-saga/effects";
import jwt_decode from "jwt-decode";
import Cookies from "universal-cookie";

import { createBrowserHistory } from "history";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import {
  userRegisterReducerTypes,
  checkUserNameReducerTypes,
  resetPasswordReducerTypes,
  userTypeReducerTypes,
  checkEmailReducerTypes,
  userLatestDesignersTypeReducerTypes,
  userYoungDesignersTypeReducerTypes,
  followersDesignerTypes,
  followingsDesignerTypes,
  saveFollowerCountTypes,
  getMutualFollowersTypes,
  saveStripeCustomerIdTypes,
} from "../reducers/userReducer";
import {
  CertificateListReducerTypes,
  uploadCertificateReducerTypes,
} from "../reducers/userReducer/certificate";
import { deleteUserReducerTypes } from "../reducers/userReducer/deleteUser";
import {
  changeEmailReducerTypes,
  subscribeTypes,
  subscribeWithEmailTypes,
} from "../reducers/userReducer/newsLetter";
import {
  degreesType,
  fieldsOfStudyType,
  universitiesType,
} from "../reducers/userReducer/editList";
import {
  AcademicsDetailReducerTypes,
  uploadAcademicsReducerTypes,
  deleteAcademicsReducerTypes,
} from "../reducers/userReducer/academic";
import { uploadCoverImageTypes } from "../reducers/userReducer/uploadImge";
import { uploadProfilePicTypes } from "../reducers/userReducer/profilePic";
import { accountDetailsReducerTypes } from "../reducers/userReducer/userdetails";
import {
  fetchBillingInformationReducerTypes,
  saveBillingInformationReducerTypes,
} from "../reducers/billingReducers/billingInformation";

const history = createBrowserHistory();

const version = process.env.REACT_APP_API_VERSION;
const version3 = process.env.REACT_APP_API_VERSION_3;
const version4 = process.env.REACT_APP_API_VERSION_4;

//saga for register new user
function registerRequest(payload) {
  return request(
    "post",
    EndPoints.REGISTER.replace("{version}", payload.version),
    payload.params
  );
}

function* rgisterUser({ params }: any) {
  try {
    yield put({
      type: userRegisterReducerTypes.REQUEST_REGISTER,
      payload: null,
    });
    const response = yield call(registerRequest, {
      version,
      params,
    });

    yield put({
      type: userRegisterReducerTypes.RESPONSE_REGISTER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: userRegisterReducerTypes.ERROR_REGISTER });
  }
}

//saga for user name check

function checkUserName(payload) {
  return request(
    "get",
    EndPoints.CHECK_USERNAME.replace("{version}", payload.version),
    payload.params,
    0
  );
}

function* checkUser({ params }: any) {
  try {
    yield put({
      type: checkUserNameReducerTypes.REQUEST_CHECKUSER,
      payload: null,
    });
    const response = yield call(checkUserName, {
      version,
      params: { userName: params },
    });

    yield put({
      type: checkUserNameReducerTypes.RESPONSE_CHECKUSER,
      payload: { data: response?.data ? response?.data : false },
    });
  } catch (e) {
    yield put({ type: checkUserNameReducerTypes.ERROR_CHECKUSER });
  }
}

//saga for email check

function checkEmailRequest(payload) {
  return request(
    "get",
    EndPoints.CHECK_EMAIL.replace("{version}", payload.version),
    payload.params,
    0
  );
}

function* checkEmail({ params }: any) {
  try {
    yield put({
      type: checkEmailReducerTypes.REQUEST_CHECKEMAIL,
      payload: null,
    });
    const response = yield call(checkEmailRequest, {
      version,
      params: { emailAddress: params },
    });

    yield put({
      type: checkEmailReducerTypes.RESPONSE_CHECKEMAIL,
      payload: { data: response?.data ? response?.data : false },
    });
  } catch (e) {
    yield put({ type: checkEmailReducerTypes.ERROR_CHECKEMAIL });
  }
}

function* loginRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.LOGIN.replace("{version}", payload.version),
    payload.params
  );

  return res;
}

function* resetTokenRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.IS_VALID_RESET_TOKEN.replace("{version}", payload.version),
    payload.params
  );

  return res;
}

function* forgotPassRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FORGOT_PASSWORD.replace("{version}", payload.version),
    payload.params
  );

  return res;
}

function* resetPassRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.RESET_PASSWORD.replace("{version}", payload.version),
    payload.params
  );

  return res;
}

function* updatePassRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.UPDATE_PASSWORD.replace("{version}", payload.version),
    payload.params
  );

  return res;
}

function* loginUser({ params, navigate }: any) {
  try {
    yield put({
      type: userRegisterReducerTypes.LOGIN_USER_REQUEST,
      payload: null,
    });
    const response = yield call(loginRequest, { version, params });

    if (response.data && (response.data.accessToken || response.data.token)) {
      const newData = {
        ...response.data,
        accountGuid: response.data.id || response.data.accountGuid,
        accessToken: response.data.token || response.data.accessToken,
        email: response.data.emailId || response.data.email,
        username: response.data.userName || response.data.username,
      };
      const cookies = new Cookies();
      const decoded: any = jwt_decode(newData?.accessToken);

      cookies.set("login-auth", newData?.accessToken, {
        path: "/",
        expires: new Date(decoded.exp * 1000),
      });

      yield put({
        type: userRegisterReducerTypes.LOGIN_USER_SUCCESS,
        payload: newData,
      });
      yield put({
        type: userRegisterReducerTypes.SET_USER_INFO,
        payload: newData,
      });
      newData?.username &&
      newData?.email !== null &&
      newData.arePersonalDetailsFilled
        ? yield call(navigate, { pathname: "/" }, { replace: false })
        : yield call(
            navigate,
            { pathname: "/account-settings" },
            { replace: false }
          );
    } else {
      yield put({
        type: userRegisterReducerTypes.LOGIN_USER_FAILURE,
        payload: response.data?.responseMessage,
      });
    }
  } catch (e) {
    yield put({
      type: userRegisterReducerTypes.LOGIN_USER_FAILURE,
      payload: e.toString(),
    });
  }
}

function* logoutUser({ navigate, payload }: any) {
  yield put({ type: userRegisterReducerTypes.SET_USER_INFO, payload: null });
  if (payload && payload.redirectTo === "DELETION_SURVEY" && navigate) {
  } else if (navigate) {
    yield call(navigate, { pathname: "/" }, { replace: false });
  } else {
    yield call(history.push, "/login");
    window.location.href = "/login";
  }
}

function* userInfoRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.ACCOUNT_INFO.replace("{version}", payload.version).replace(
      "{Guidid}",
      payload.accountGuid
    ),
    payload.params
  );

  return res;
}

function* resetToken({ params }: any) {
  try {
    yield put({
      type: resetPasswordReducerTypes.CHECK_RESET_TOKEN_REQUEST,
      payload: null,
    });
    const response = yield call(resetTokenRequest, { version, params });

    yield put({
      type: resetPasswordReducerTypes.CHECK_RESET_TOKEN_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: resetPasswordReducerTypes.CHECK_RESET_TOKEN_FAILURE,
      payload: e,
    });
  }
}

function* forgotPassword({ params }: any) {
  try {
    yield put({
      type: resetPasswordReducerTypes.FORGOT_PASSWORD_REQUEST,
      payload: null,
    });
    const response = yield call(forgotPassRequest, { version, params });

    yield put({
      type: resetPasswordReducerTypes.FORGOT_PASSWORD_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: resetPasswordReducerTypes.FORGOT_PASSWORD_FAILURE,
      payload: e,
    });
  }
}

function* resetPassword({ params }: any) {
  try {
    yield put({
      type: resetPasswordReducerTypes.RESET_PASSWORD_REQUEST,
      payload: null,
    });
    const response = yield call(resetPassRequest, { version, params });

    yield put({
      type: resetPasswordReducerTypes.RESET_PASSWORD_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: resetPasswordReducerTypes.RESET_PASSWORD_FAILURE,
      payload: e,
    });
  }
}

function* updatePassword({ params }: any) {
  try {
    yield put({
      type: resetPasswordReducerTypes.UPDATE_PASSWORD_REQUEST,
      payload: null,
    });
    const response = yield call(updatePassRequest, { version, params });

    yield put({
      type: resetPasswordReducerTypes.UPDATE_PASSWORD_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: resetPasswordReducerTypes.RESET_PASSWORD_FAILURE,
      payload: e,
    });
  }
}

function* fetchUserInfo({ accountGuid }: any) {
  try {
    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO_REQUEST,
      payload: null,
    });
    const response = yield call(userInfoRequest, { version, accountGuid });

    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO_FAILURE,
      payload: e,
    });
  }
}

function* updateUserInfoRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.UPDATE_ACCOUNT_INFO.replace("{version}", version),
    payload
  );

  return res;
}

function* updateUserInfo({ params }: any) {
  try {
    yield put({
      type: userRegisterReducerTypes.UPDATE_USER_ACCOUNT_INFO_REQUEST,
    });
    const response = yield call(updateUserInfoRequest, params);

    yield put({
      type: userRegisterReducerTypes.UPDATE_USER_ACCOUNT_INFO_SUCCESS,
      payload: {
        ...response?.data,
        ...params,
        modifiedDate: response?.data?.modifiedDate,
      },
    });
  } catch (e) {
    yield put({
      type: userRegisterReducerTypes.UPDATE_USER_ACCOUNT_INFO_FAILURE,
      payload: e,
    });
  }
}

//UPDATE USER INFO WITH PROFILE PICTURE

function* updateUserWithProfileRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.UPDATE_ACCOUNT_WITH_PROFILE_PIC.replace("{version}", version),
    payload
  );

  return res;
}

function* updateUserWithProfile({ params }: any) {
  try {
    yield put({
      type: userRegisterReducerTypes.UPDATE_USER_INFO_WITH_PROFILE_REQUEST,
    });
    const response = yield call(updateUserWithProfileRequest, params);

    yield put({
      type: userRegisterReducerTypes.UPDATE_USER_INFO_WITH_PROFILE_SUCCESS,
      payload: {
        ...response?.data,
        ...params,
        modifiedDate: response?.data?.modifiedDate,
      },
    });
  } catch (e) {
    yield put({
      type: userRegisterReducerTypes.UPDATE_USER_INFO_WITH_PROFILE_FAILURE,
      payload: e,
    });
  }
}

//@ company list
function* companyListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.COMPANY_LIST.replace("{version}", version3),
    payload
  );

  return res;
}

function* companyList({ payload }: any) {
  try {
    yield put({
      type: userTypeReducerTypes.REQUEST_COMPANY_LIST,
      payload: null,
    });
    const response = yield call(companyListRequest, {
      ...payload,
      version,
    });

    yield put({
      type: userTypeReducerTypes.RESPONSE_COMPANY_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: userTypeReducerTypes.ERROR_COMPANY_LIST });
  }
}
// filtered Young Designers\

function* filteredYoungDesignersRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FILTERED_YOUNG_DESIGNERS.replace("{version}", version3),
    payload
  );

  return res;
}

function* filteredYoungDesigners({ payload }: any) {
  try {
    yield put({
      type: userTypeReducerTypes.REQUEST_DESIGNERS_LIST,
      payload: null,
    });
    const response = yield call(filteredYoungDesignersRequest, payload);

    yield put({
      type: userTypeReducerTypes.RESPONSE_DESIGNERS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: userTypeReducerTypes.ERROR_DESIGNERS_LIST, payload: e });
  }
}
//filtered designer list
function* filteredDesignersRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FILTERED_DESIGNERS.replace("{version}", version3),
    payload
  );

  return res;
}

function* filteredDesigners({ payload }: any) {
  try {
    yield put({
      type: userTypeReducerTypes.REQUEST_DESIGNERS_LIST,
      payload: null,
    });
    const response = yield call(filteredDesignersRequest, payload);

    yield put({
      type: userTypeReducerTypes.RESPONSE_DESIGNERS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: userTypeReducerTypes.ERROR_DESIGNERS_LIST, payload: e });
  }
}

//designer list
function* designersListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.DESIGNERS_LIST.replace("{version}", version3),
    payload
  );

  return res;
}

function* designerList({ payload }: any) {
  try {
    yield put({
      type: userTypeReducerTypes.REQUEST_DESIGNERS_LIST,
      payload: null,
    });
    const response = yield call(designersListRequest, payload);

    yield put({
      type: userTypeReducerTypes.RESPONSE_DESIGNERS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: userTypeReducerTypes.ERROR_DESIGNERS_LIST, payload: e });
  }
}

//designer list
function* latestDesignerListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.LATEST_DESIGNERS_LIST.replace("{version}", payload.version)
  );

  return res;
}

function* latestDesignerList({}) {
  try {
    yield put({
      type: userLatestDesignersTypeReducerTypes.REQUEST_LATEST_DESIGNERS_LIST,
      payload: null,
    });
    const response = yield call(latestDesignerListRequest, {
      version,
    });

    yield put({
      type: userLatestDesignersTypeReducerTypes.RESPONSE_LATEST_DESIGNERS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: userLatestDesignersTypeReducerTypes.ERROR_LATEST_DESIGNERS_LIST,
    });
  }
}

function* youngDesignerListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.YOUNG_DESIGNERS_LIST.replace("{version}", version3),
    payload
  );

  return res;
}

function* youngDesignerList({ payload }: any) {
  try {
    yield put({
      type: userYoungDesignersTypeReducerTypes.REQUEST_YOUNG_DESIGNERS_LIST,
      payload: null,
    });
    const response = yield call(youngDesignerListRequest, {
      version,
      ...payload,
    });

    yield put({
      type: userYoungDesignersTypeReducerTypes.RESPONSE_YOUNG_DESIGNERS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: userYoungDesignersTypeReducerTypes.ERROR_YOUNG_DESIGNERS_LIST,
    });
  }
}

function* designersDetailsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.DESIGNER_DETAILS.replace("{version}", payload.version),
    payload.params
  );

  return res;
}

function* designerDetails({ params }: any) {
  try {
    yield put({
      type: userTypeReducerTypes.REQUEST_DESIGNERS_DETAILS,
      payload: null,
    });
    const response = yield call(designersDetailsRequest, { version, params });

    yield put({
      type: userTypeReducerTypes.RESPONSE_DESIGNERS_DETAILS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: userTypeReducerTypes.ERROR_DESIGNERS_DETAILS });
  }
}

//upload certificate saga

function* uploadCertificateRequest(params) {
  const res = yield request(
    "post",
    EndPoints.CERTIFICATE_UPLOAD.replace("{version}", version),
    params,
    "form-data"
  );

  return res;
}

function* uploadCertificate({ payload }: any) {
  try {
    yield put({
      type: uploadCertificateReducerTypes.REQUEST_UPLOAD_CERTIFICATE,
      payload: null,
    });
    const response = yield call(uploadCertificateRequest, payload);

    yield put({
      type: uploadCertificateReducerTypes.RESPONSE_UPLOAD_CERTIFICATE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: uploadCertificateReducerTypes.ERROR_UPLOAD_CERTIFICATE });
  }
}

//my certificate list

function* myCertificateListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.CERTIFICATE_LISTS.replace("{version}", version),
    payload
  );

  return res;
}

function* myCertificateList({ accountGuid }: any) {
  try {
    yield put({
      type: CertificateListReducerTypes.REQUEST_CERTIFICATE_LIST,
      payload: null,
    });
    const response = yield call(myCertificateListRequest, {
      accountGuid,
    });

    yield put({
      type: CertificateListReducerTypes.RESPONSE_CERTIFICATE_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: CertificateListReducerTypes.ERROR_CERTIFICATE_LIST });
  }
}

//my certificate list

function* deleteUserRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.DELETE_ACCOUNT.replace("{version}", version),
    payload
  );

  return res;
}

function* deleteUser({ accountGuid }: any) {
  try {
    yield put({
      type: deleteUserReducerTypes.REQUEST_DELETE_USER,
      payload: null,
    });
    const response = yield call(deleteUserRequest, {
      accountGuid,
    });

    yield put({
      type: deleteUserReducerTypes.RESPONSE_DELETE_USER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: deleteUserReducerTypes.ERROR_DELETE_USER });
  }
}

//subscribe saga

function* subscribeRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.SUBSCRIBE.replace("{version}", version),
    payload
  );

  return res;
}

function* subscribe({ accountGuid }: any) {
  try {
    yield put({ type: subscribeTypes.REQUEST_SUBSCRIBE, payload: null });
    const response = yield call(subscribeRequest, { accountGuid });

    yield put({
      type: subscribeTypes.RESPONSE_SUBSCRIBE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: subscribeTypes.ERROR_SUBSCRIBE });
  }
}

//get degrees list
function* getDegreesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_DEGREES.replace("{version}", payload.version)
  );

  return res;
}

function* getDegrees({}) {
  try {
    yield put({ type: degreesType.REQUEST_DEGREES, payload: null });
    const response = yield call(getDegreesRequest, { version });

    yield put({ type: degreesType.RESPONSE_DEGREES, payload: response?.data });
  } catch (e) {
    yield put({ type: degreesType.ERROR_DEGREES });
  }
}

//get universitites list
function* universitiesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_UNIVERSITIES.replace("{version}", payload.version)
  );

  return res;
}

function* getUniversitites({}) {
  try {
    yield put({ type: universitiesType.REQUEST_UNIVERSITIES, payload: null });
    const response = yield call(universitiesRequest, { version });

    yield put({
      type: universitiesType.RESPONSE_UNIVERSITIES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: universitiesType.ERROR_UNIVERSITIES });
  }
}

//get fieldsOfStudy list

function* fieldsOfStudyRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FIELDS_OF_STUDY.replace("{version}", payload.version)
  );

  return res;
}

function* getFieldsOfStudy({}) {
  try {
    yield put({
      type: fieldsOfStudyType.REQUEST_FIELDS_OF_STUDY,
      payload: null,
    });
    const response = yield call(fieldsOfStudyRequest, { version });

    yield put({
      type: fieldsOfStudyType.RESPONSE_FIELDS_OF_STUDY,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: fieldsOfStudyType.ERROR_FIELDS_OF_STUDY });
  }
}

//upload academics saga

function* uploadAcademicsRequest(params) {
  const res = yield request(
    "post",
    EndPoints.UPLOAD_ACADEMICS.replace("{version}", version),
    params,
    "form-data"
  );

  return res;
}

function* uploadAcademics({ payload }: any) {
  try {
    yield put({
      type: uploadAcademicsReducerTypes.REQUEST_UPLOAD_ACADEMICS,
      payload: null,
    });
    const response = yield call(uploadAcademicsRequest, payload);

    yield put({
      type: uploadAcademicsReducerTypes.RESPONSE_UPLOAD_ACADEMICS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: uploadAcademicsReducerTypes.ERROR_UPLOAD_ACADEMICS });
  }
}

//subscribe with email

function* subscribeWithEmailRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.SUBSCRIBE_WITH_EMAIL.replace("{version}", version),
    payload
  );

  return res;
}

function* subscribeWithEmail({ emailAddress }: any) {
  try {
    yield put({
      type: subscribeWithEmailTypes.REQUEST_SUBSCRIBE_WITH_EMAIL,
      payload: null,
    });
    const response = yield call(subscribeWithEmailRequest, {
      emailAddress,
    });

    yield put({
      type: subscribeWithEmailTypes.RESPONSE_SUBSCRIBE_WITH_EMAIL,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: subscribeWithEmailTypes.ERROR_SUBSCRIBE_WITH_EMAIL });
  }
}

//upload cover image saga

function* uploadCoverImageRequest(params) {
  const res = yield request(
    "put",
    EndPoints.UPLOAD_COVER_PICTURE.replace("{version}", version),
    params,
    "form-data"
  );

  return res;
}

function* uploadCoverImage({ payload }: any) {
  try {
    yield put({
      type: uploadCoverImageTypes.REQUEST_UPLOAD_COVER_IMAGE,
      payload: null,
    });
    const response = yield call(uploadCoverImageRequest, payload);

    yield put({
      type: uploadCoverImageTypes.RESPONSE_UPLOAD_COVER_IMAGE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: uploadCoverImageTypes.ERROR_UPLOAD_COVER_IMAGE,
      payload: e,
    });
  }
}

//upload Profile pic saga
function* uploadProfilePicRequest(params) {
  const res = yield request(
    "put",
    EndPoints.UPLOAD_PROFILE_PIC.replace("{version}", version),
    params,
    "form-data"
  );

  return res;
}

function* uploadProfilePic({ payload }: any) {
  try {
    yield put({
      type: uploadProfilePicTypes.REQUEST_UPLOAD_PROFILE_PIC,
      payload: null,
    });
    const response = yield call(uploadProfilePicRequest, payload);

    yield put({
      type: uploadProfilePicTypes.RESPONSE_UPLOAD_PROFILE_PIC,
      payload: response?.data,
    });
    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO,
      accountGuid: response?.data?.accountGUID,
    });
  } catch (e) {
    yield put({
      type: uploadProfilePicTypes.ERROR_UPLOAD_PROFILE_PIC,
      payload: e,
    });
  }
}
// saga for Billing information
function billInformationRequest({ AccountGuid }) {
  return request(
    "get",
    EndPoints.GET_BILLING_INFO.replace("{Guidid}", AccountGuid).replace(
      "{version}",
      version
    )
  );
}
function* billInformation({ params }: any) {
  try {
    yield put({
      type: fetchBillingInformationReducerTypes.REQUEST_BILLING_INFORMATION,
      payload: null,
    });
    const response = yield call(billInformationRequest, params);

    yield put({
      type: fetchBillingInformationReducerTypes.RESPONSE_BILLING_INFORMATION,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: fetchBillingInformationReducerTypes.ERROR_BILLING_INFORMATION,
    });
  }
}

function* saveBillInformationRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.SAVE_BILLING_INFO.replace("{version}", version),
    payload
  );

  return res;
}

function* saveBillInformation({ params }: any) {
  try {
    yield put({
      type: saveBillingInformationReducerTypes.REQUEST_SAVE_BILLING_INFORMATION,
      payload: null,
    });
    const response = yield call(saveBillInformationRequest, params);

    yield put({
      type: saveBillingInformationReducerTypes.RESPONSE_SAVE_BILLING_INFORMATION,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: saveBillingInformationReducerTypes.ERROR_SAVE_BILLING_INFORMATION,
    });
  }
}

//get academicsDetail list

function* academicsDetailRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.ACADEMICS_DETAIL.replace("{version}", version),
    payload,
    0
  );

  return res;
}

function* getAcademicsDetail({ params }: any) {
  try {
    yield put({
      type: AcademicsDetailReducerTypes.REQUEST_ACADEMICS_DETAILS,
      payload: null,
    });
    const response = yield call(academicsDetailRequest, params);

    yield put({
      type: AcademicsDetailReducerTypes.RESPONSE_ACADEMICS_DETAILS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: AcademicsDetailReducerTypes.ERROR_ACADEMICS_DETAILS });
  }
}

function* saveAcademicPositionRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SAVE_ACADEMIC_POSITION.replace("{version}", version),
    payload
  );

  return res;
}

function* saveAcademicPosition({ payload }: any) {
  try {
    yield put({
      type: AcademicsDetailReducerTypes.SAVE_ACADEMIC_POSITION_REQUEST,
      payload: null,
    });
    const response = yield call(saveAcademicPositionRequest, payload);

    yield put({
      type: AcademicsDetailReducerTypes.SAVE_ACADEMIC_POSITION_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: AcademicsDetailReducerTypes.ACADEMICS_DETAILS,
      params: { accountGuid: payload.accountGuid },
    });
  } catch (e) {
    yield put({
      type: AcademicsDetailReducerTypes.SAVE_ACADEMIC_POSITION_FAILURE,
    });
  }
}

//get deleteAcademicsDetail list

function* deleteAcademicsRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.DELETE_ACADEMICS.replace("{version}", version),
    payload
  );

  return res;
}

function* deleteAcademics({ params }: any) {
  try {
    yield put({
      type: deleteAcademicsReducerTypes.REQUEST_DELETE_ACADEMICS,
      payload: null,
    });
    const response = yield call(deleteAcademicsRequest, params);

    yield put({
      type: deleteAcademicsReducerTypes.RESPONSE_DELETE_ACADEMICS,
      payload: response?.data,
    });
    yield put({
      type: AcademicsDetailReducerTypes.ACADEMICS_DETAILS,
      params: { accountGuid: params.accountGuid },
    });
  } catch (e) {
    yield put({ type: deleteAcademicsReducerTypes.ERROR_DELETE_ACADEMICS });
  }
}

//CHANGE EMAIL

function* changeEmailRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.CHANGE_EMAIL.replace("{version}", version),
    payload
  );

  return res;
}

function* changeEmail({ params }: any) {
  try {
    yield put({
      type: changeEmailReducerTypes.REQUEST_CHANGE_EMAIL,
      payload: null,
    });
    const response = yield call(changeEmailRequest, params);

    yield put({
      type: changeEmailReducerTypes.RESPONSE_CHANGE_EMAIL,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: changeEmailReducerTypes.ERROR_CHANGE_EMAIL });
  }
}

//to get account details

function accountDetailsRequest({ Guidid }) {
  return request(
    "get",
    EndPoints.ACCOUNT_DETAILS.replace("{Guidid}", Guidid).replace(
      "{version}",
      version
    )
  );
}
function* accountDetails({ params }: any) {
  try {
    yield put({
      type: accountDetailsReducerTypes.REQUEST_ACCOUNT_DETAILS,
      payload: null,
    });
    const response = yield call(accountDetailsRequest, params);

    yield put({
      type: accountDetailsReducerTypes.RESPONSE_ACCOUNT_DETAILS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: accountDetailsReducerTypes.ERROR_ACCOUNT_DETAILS });
  }
}

function followerDesignersRequest(payload) {
  return request(
    "get",
    EndPoints.FOLLOWERS_LIST.replace("{version}", version3),
    payload
  );
}
function* followerDesigners({ payload }: any) {
  try {
    yield put({
      type: followersDesignerTypes.REQUEST_FOLLOWERS_DESIGNERS_LIST,
      payload: null,
    });
    const response = yield call(followerDesignersRequest, payload);

    yield put({
      type: followersDesignerTypes.RESPONSE_FOLLOWERS_DESIGNERS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: followersDesignerTypes.ERROR_FOLLOWERS_DESIGNERS_LIST });
  }
}

function followingDesignersRequest(payload) {
  return request(
    "get",
    EndPoints.FOLLOWED_LIST.replace("{version}", version3),
    payload
  );
}
function* followingDesigners({ payload }: any) {
  try {
    yield put({
      type: followingsDesignerTypes.REQUEST_FOLLOWINGS_DESIGNERS_LIST,
      payload: null,
    });
    const response = yield call(followingDesignersRequest, payload);

    yield put({
      type: followingsDesignerTypes.RESPONSE_FOLLOWINGS_DESIGNERS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: followingsDesignerTypes.ERROR_FOLLOWINGS_DESIGNERS_LIST,
    });
  }
}

function saveFollowerCountRequest(payload) {
  return request(
    "post",
    EndPoints.SAVE_FOLLOW_COUNTER.replace("{version}", version),
    payload
  );
}

function* saveFollowerCount({ payload }: any) {
  try {
    yield put({
      type: saveFollowerCountTypes.REQUEST_SAVE_FOLLOW_COUNTER,
      payload: null,
    });
    const response = yield call(saveFollowerCountRequest, payload);

    yield put({
      type: saveFollowerCountTypes.RESPONSE_SAVE_FOLLOW_COUNTER,
      payload,
    });
  } catch (e) {
    yield put({ type: saveFollowerCountTypes.ERROR_SAVE_FOLLOW_COUNTER });
  }
}

//get mutual followers

function mutualfollowersRequest(payload) {
  return request(
    "get",
    EndPoints.GET_MUTUAL_FOLLOWERS.replace("{version}", version4),
    payload
  );
}

function* mutualFollowers({ payload }: any) {
  try {
    yield put({
      type: getMutualFollowersTypes.REQUEST_MUTUAL_FOLLOWERS,
      payload: null,
    });
    const response = yield call(mutualfollowersRequest, payload);

    yield put({
      type: getMutualFollowersTypes.RESPONSE_MUTUAL_FOLLOWERS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: getMutualFollowersTypes.ERROR_MUTUAL_FOLLOWERS });
  }
}

///////
function saveStripCustomerIdRequest(payload) {
  return request(
    "put",
    EndPoints.SAVE_STRIPE_CUSTOMER_ID.replace("{version}", version),
    payload
  );
}

function* saveStripCustomerId({ payload }: any) {
  try {
    yield put({
      type: saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_CUSTOMER_ID,
      payload: null,
    });
    const response = yield call(saveStripCustomerIdRequest, payload);

    yield put({
      type: saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_CUSTOMER_ID,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_CUSTOMER_ID,
    });
  }
}
//////////

function saveCurrentPlanDetailsRequest(payload) {
  return request(
    "put",
    EndPoints.SAVE_CURRENT_PLAN_DETAILS.replace("{version}", version),
    payload
  );
}

function* saveCurrentPlanDetails({ payload }: any) {
  try {
    yield put({
      type: saveStripeCustomerIdTypes.REQUEST_SAVE_CURRENT_PLAN_DETAILS,
      payload: null,
    });
    const response = yield call(saveCurrentPlanDetailsRequest, payload);

    yield put({
      type: saveStripeCustomerIdTypes.RESPONSE_SAVE_CURRENT_PLAN_DETAILS,
      payload: response?.data,
    });
    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO,
      accountGuid: payload.accountGuid,
    });
  } catch (e) {
    yield put({
      type: saveStripeCustomerIdTypes.ERROR_SAVE_CURRENT_PLAN_DETAILS,
    });
  }
}
//////////

function saveStripeAccountIdRequest(payload) {
  return request(
    "put",
    EndPoints.SAVE_STRIPE_ACCOUNT_ID.replace("{version}", version),
    payload
  );
}

function* saveStripeAccountId({ payload }: any) {
  try {
    yield put({
      type: saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_ACCOUNT_ID,
      payload: null,
    });
    const response = yield call(saveStripeAccountIdRequest, payload);

    yield put({
      type: saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_ACCOUNT_ID,
      payload: response?.data,
    });
    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO,
      accountGuid: payload.accountGuid,
    });
  } catch (e) {
    yield put({
      type: saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_ACCOUNT_ID,
    });
  }
}
//////////

function saveStripeAccountIdRegisteredRequest(payload) {
  return request(
    "put",
    EndPoints.SAVE_STRIPE_ACCOUNT_ID_REGISTERED.replace("{version}", version),
    payload
  );
}

function* saveStripeAccountIdRegistered({ payload }: any) {
  try {
    yield put({
      type: saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_ACCOUNT_ID_REGISTERED,
      payload: null,
    });
    const response = yield call(saveStripeAccountIdRegisteredRequest, payload);

    yield put({
      type: saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_ACCOUNT_ID_REGISTERED,
      payload: response?.data,
    });
    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO,
      accountGuid: payload.accountGuid,
    });
  } catch (e) {
    yield put({
      type: saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_ACCOUNT_ID_REGISTERED,
    });
  }
}
//////////

//////////

function saveCustomerIdBasedUponSellerIdRequest(payload) {
  return request(
    "post",
    EndPoints.SAVE_STRIPE_CUSTOMER_ID_BY_SELLER.replace("{version}", version),
    payload
  );
}

function* saveCustomerIdBasedUponSellerId({ payload }: any) {
  try {
    yield put({
      type: saveStripeCustomerIdTypes.REQUEST_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER,
      payload: null,
    });
    const response = yield call(
      saveCustomerIdBasedUponSellerIdRequest,
      payload
    );

    yield put({
      type: saveStripeCustomerIdTypes.RESPONSE_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER,
      payload: response?.data,
    });
    yield put({
      type: saveStripeCustomerIdTypes.GET_STRIPE_CUSTOMER_ID_BY_SELLER,
      payload,
    });
  } catch (e) {
    yield put({
      type: saveStripeCustomerIdTypes.ERROR_SAVE_STRIPE_CUSTOMER_ID_BY_SELLER,
    });
  }
}
//////////

//////////

function getCustomerIdBasedUponSellerIdRequest(payload) {
  return request(
    "get",
    EndPoints.GET_STRIPE_CUSTOMER_ID_BY_SELLER.replace("{version}", version),
    payload
  );
}

function* getCustomerIdBasedUponSellerId({ payload }: any) {
  try {
    yield put({
      type: saveStripeCustomerIdTypes.REQUEST_GET_STRIPE_CUSTOMER_ID_BY_SELLER,
      payload: null,
    });
    const response = yield call(getCustomerIdBasedUponSellerIdRequest, payload);

    yield put({
      type: saveStripeCustomerIdTypes.RESPONSE_GET_STRIPE_CUSTOMER_ID_BY_SELLER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: saveStripeCustomerIdTypes.ERROR_GET_STRIPE_CUSTOMER_ID_BY_SELLER,
    });
  }
}
//////////

function* userSaga() {
  yield all([
    takeLatest(
      userTypeReducerTypes?.FILTERED_YOUNG_DESIGNERS,
      filteredYoungDesigners
    ),
    takeLatest(userTypeReducerTypes?.FILTERED_DESIGNERS, filteredDesigners),
    takeLatest(
      fetchBillingInformationReducerTypes?.BILLING_INFORMATION,
      billInformation
    ),
    takeLatest(
      saveBillingInformationReducerTypes?.SAVE_BILLING_INFORMATION,
      saveBillInformation
    ),
    takeLatest(userRegisterReducerTypes.REGISTER_USER, rgisterUser),
    takeLatest(checkUserNameReducerTypes.CHECK_USERNAME, checkUser),
    takeLatest(checkEmailReducerTypes.CHECK_EMAIL, checkEmail),
    takeLatest(userRegisterReducerTypes.LOGIN_USER, loginUser),
    takeLatest(userRegisterReducerTypes.LOGOUT_USER, logoutUser),
    takeLatest(userRegisterReducerTypes.FETCH_USER_INFO, fetchUserInfo),
    takeLatest(
      userRegisterReducerTypes.UPDATE_USER_ACCOUNT_INFO,
      updateUserInfo
    ),
    takeLatest(
      userRegisterReducerTypes.UPDATE_USER_INFO_WITH_PROFILE,
      updateUserWithProfile
    ),
    takeLatest(resetPasswordReducerTypes.FORGOT_PASSWORD, forgotPassword),
    takeLatest(resetPasswordReducerTypes.CHECK_RESET_TOKEN, resetToken),
    takeLatest(resetPasswordReducerTypes.RESET_PASSWORD, resetPassword),
    takeLatest(resetPasswordReducerTypes.UPDATE_PASSWORD, updatePassword),
    takeLatest(userTypeReducerTypes.COMPANY_LIST, companyList),
    takeLatest(userTypeReducerTypes.DESIGNERS_LIST, designerList),
    takeLatest(userTypeReducerTypes.DESIGNERS_DETAILS, designerDetails),
    takeLatest(
      uploadCertificateReducerTypes.UPLOAD_CERTIFICATE,
      uploadCertificate
    ),
    takeLatest(CertificateListReducerTypes.CERTIFICATE_LIST, myCertificateList),
    takeLatest(deleteUserReducerTypes.DELETE_USER, deleteUser),
    takeLatest(subscribeTypes.SUBSCRIBE, subscribe),
    takeLatest(degreesType.DEGREES, getDegrees),
    takeLatest(universitiesType.UNIVERSITIES, getUniversitites),
    takeLatest(fieldsOfStudyType.FIELDS_OF_STUDY, getFieldsOfStudy),
    takeLatest(uploadAcademicsReducerTypes.UPLOAD_ACADEMICS, uploadAcademics),
    takeLatest(
      subscribeWithEmailTypes.SUBSCRIBE_WITH_EMAIL,
      subscribeWithEmail
    ),

    takeLatest(uploadCoverImageTypes.UPLOAD_COVER_IMAGE, uploadCoverImage),
    takeLatest(uploadProfilePicTypes.UPLOAD_PROFILE_PIC, uploadProfilePic),

    takeLatest(
      AcademicsDetailReducerTypes.ACADEMICS_DETAILS,
      getAcademicsDetail
    ),
    takeLatest(
      AcademicsDetailReducerTypes.SAVE_ACADEMIC_POSITION,
      saveAcademicPosition
    ),
    takeLatest(deleteAcademicsReducerTypes.DELETE_ACADEMICS, deleteAcademics),
    takeLatest(changeEmailReducerTypes.CHANGE_EMAIL, changeEmail),
    takeLatest(accountDetailsReducerTypes.ACCOUNT_DETAILS, accountDetails),
    takeLatest(
      userLatestDesignersTypeReducerTypes.LATEST_DESIGNERS_LIST,
      latestDesignerList
    ),
    takeLatest(
      userYoungDesignersTypeReducerTypes.YOUNG_DESIGNERS_LIST,
      youngDesignerList
    ),
    takeLatest(
      followersDesignerTypes.FOLLOWERS_DESIGNERS_LIST,
      followerDesigners
    ),
    takeLatest(
      followingsDesignerTypes.FOLLOWINGS_DESIGNERS_LIST,
      followingDesigners
    ),
    takeLatest(saveFollowerCountTypes.SAVE_FOLLOW_COUNTER, saveFollowerCount),
    takeLatest(getMutualFollowersTypes.MUTUAL_FOLLOWERS, mutualFollowers),
    takeLatest(
      saveStripeCustomerIdTypes.SAVE_STRIPE_CUSTOMER_ID,
      saveStripCustomerId
    ),
    takeLatest(
      saveStripeCustomerIdTypes.SAVE_CURRENT_PLAN_DETAILS,
      saveCurrentPlanDetails
    ),

    takeLatest(
      saveStripeCustomerIdTypes.SAVE_STRIPE_ACCOUNT_ID,
      saveStripeAccountId
    ),

    takeLatest(
      saveStripeCustomerIdTypes.SAVE_STRIPE_ACCOUNT_ID_REGISTERED,
      saveStripeAccountIdRegistered
    ),

    takeLatest(
      saveStripeCustomerIdTypes.SAVE_STRIPE_CUSTOMER_ID_BY_SELLER,
      saveCustomerIdBasedUponSellerId
    ),

    takeLatest(
      saveStripeCustomerIdTypes.GET_STRIPE_CUSTOMER_ID_BY_SELLER,
      getCustomerIdBasedUponSellerId
    ),
  ]);
}
export default userSaga;
