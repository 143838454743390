import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { inviteActionTypes } from "../reducers/invite";

const version = process.env.REACT_APP_API_VERSION;

function* inviteAFriendRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.INVITE_A_FRIEND.replace("{version}", version),
    payload
  );

  return res;
}

function* inviteAFriend({ payload }: any) {
  try {
    yield put({
      type: inviteActionTypes.INVITE_A_FRIEND_REQUEST,
    });
    const response = yield call(inviteAFriendRequest, payload);

    if (response?.data?.responseCode === 200) {
      yield put({
        type: inviteActionTypes.INVITE_A_FRIEND_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (e) {
    yield put({
      type: inviteActionTypes.INVITE_A_FRIEND_FAILURE,
      payload: e,
    });
  }
}

function* contactUsRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.CONTACT_US.replace("{version}", version),
    payload
  );

  return res;
}

function* contactUs({ payload }: any) {
  try {
    yield put({
      type: inviteActionTypes.CONTACT_US_REQUEST,
    });
    const response = yield call(contactUsRequest, payload);

    if (response?.data?.responseCode === 200) {
      yield put({
        type: inviteActionTypes.CONTACT_US_SUCCESS,
        payload: response?.data,
      });
    } else {
      yield put({
        type: inviteActionTypes.CONTACT_US_FAILURE,
        payload: response?.data?.responseMessage,
      });
    }
  } catch (e) {
    yield put({
      type: inviteActionTypes.CONTACT_US_FAILURE,
      payload: e,
    });
  }
}

function* paymentSaga() {
  yield all([
    takeLatest(inviteActionTypes.INVITE_A_FRIEND, inviteAFriend),
    takeLatest(inviteActionTypes.CONTACT_US, contactUs),
  ]);
}
export default paymentSaga;
