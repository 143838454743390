/*
 **	User name
 **	Created	6/1/2023
 **	AppPermissionsContext.tsx
 */

import React from "react";

interface AppPermissionsContextInterface {
  permissions: {
    canViewPermissions: boolean;
    invoicesPayments: {
      canDownloadInvoices: boolean;
      canChangePaymentSettings: boolean;
      canUpgradePaymentPlans: boolean;
    };
    companyPage: {
      canEditCompanyPage: boolean;
    };
    employeeSettings: {
      canInviteEmployees: boolean;
      canEditEmployees: boolean;
      canAddSeats: boolean;
    };
    productSettings: {
      uploadPublic: boolean;
    };
  };
}

export const AppPermissionsContext =
  React.createContext<AppPermissionsContextInterface>({
    permissions: {
      canViewPermissions: false,
      invoicesPayments: {
        canDownloadInvoices: false,
        canChangePaymentSettings: false,
        canUpgradePaymentPlans: false,
      },
      companyPage: {
        canEditCompanyPage: false,
      },
      employeeSettings: {
        canInviteEmployees: false,
        canEditEmployees: false,
        canAddSeats: false,
      },
      productSettings: {
        uploadPublic: false,
      },
    },
  });
