import { createAction, createReducer } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";

export const productCommentsActionTypes = {
  fetchProductComments: "productComments/fetch",
  fetchProductCommentsRequest: "productComments/fetch/request",
  fetchProductCommentsSuccess: "productComments/fetch/success",
  fetchProductCommentsFailure: "productComments/fetch/failure",

  addProductComment: "productComments/add",
  addProductCommentRequest: "productComments/add/request",
  addProductCommentSuccess: "productComments/add/success",
  addProductCommentFailure: "productComments/add/failure",
  addProductCommentClear: "productComments/add/clear",

  removeProductComment: "productComments/remove",
  removeProductCommentRequest: "productComments/remove/request",
  removeProductCommentSuccess: "productComments/remove/success",
  removeProductCommentFailure: "productComments/remove/failure",
};

const fetchProductComments = createAction<any>(
  productCommentsActionTypes.fetchProductComments
);
const fetchProductCommentsRequest = createAction(
  productCommentsActionTypes.fetchProductCommentsRequest
);
const fetchProductCommentsSuccess = createAction<Array<any>>(
  productCommentsActionTypes.fetchProductCommentsSuccess
);
const fetchProductCommentsFailure = createAction<any>(
  productCommentsActionTypes.fetchProductCommentsFailure
);

const addProductComment = createAction<any>(
  productCommentsActionTypes.addProductComment
);
const addProductCommentRequest = createAction(
  productCommentsActionTypes.addProductCommentRequest
);
const addProductCommentSuccess = createAction<Array<any>>(
  productCommentsActionTypes.addProductCommentSuccess
);
const addProductCommentFailure = createAction<any>(
  productCommentsActionTypes.addProductCommentFailure
);

const removeProductComment = createAction<any>(
  productCommentsActionTypes.removeProductComment
);
const removeProductCommentRequest = createAction(
  productCommentsActionTypes.removeProductCommentRequest
);
const removeProductCommentSuccess = createAction<Array<any>>(
  productCommentsActionTypes.removeProductCommentSuccess
);
const removeProductCommentFailure = createAction<any>(
  productCommentsActionTypes.removeProductCommentFailure
);

const initialState = {
  comments: [],
  loading: true,
  status: null,
  error: null,
  addError: null,
};

const productCommentsReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchProductCommentsRequest, (state, action: Action) => ({
    ...state,
    loading: true,
    error: null,
  }));

  builder.addCase(fetchProductCommentsSuccess, (state, action: Action) => {
    if (fetchProductCommentsSuccess.match(action)) {
      return {
        ...state,
        comments: action.payload as any,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchProductCommentsFailure, (state, action: Action) => {
    if (fetchProductCommentsFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(addProductCommentRequest, (state, action: Action) => ({
    ...state,
    loading: true,
    error: null,
  }));

  builder.addCase(addProductCommentSuccess, (state, action: Action) => {
    if (addProductCommentSuccess.match(action)) {
      return {
        ...state,
        comments: action.payload as any,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(addProductCommentFailure, (state, action: Action) => {
    if (addProductCommentFailure.match(action)) {
      return {
        ...state,
        addError: action.payload,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(removeProductCommentRequest, (state, action: Action) => ({
    ...state,
    loading: true,
    error: null,
  }));

  builder.addCase(removeProductCommentSuccess, (state, action: Action) => {
    if (removeProductCommentSuccess.match(action)) {
      return {
        ...state,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(removeProductCommentFailure, (state, action: Action) => {
    if (removeProductCommentFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    return state;
  });
});

export const productCommentsActions = {
  fetchProductComments,
  fetchProductCommentsRequest,
  fetchProductCommentsSuccess,
  fetchProductCommentsFailure,

  addProductComment,
  addProductCommentRequest,
  addProductCommentSuccess,
  addProductCommentFailure,

  removeProductComment,
  removeProductCommentRequest,
  removeProductCommentSuccess,
  removeProductCommentFailure,
};

export default productCommentsReducer;
