// @ts-nocheck
import { Box, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import RightPannelInfoPreview from "./rightPanelInfoPreview";

// import { useUpload3dModalStyles } from "./modal";

const useProductFileViewerStyle = makeStyles((theme) => ({
  smallViewPort: {
    height: 228,
    width: 273,
    borderRadius: "6px",
    margin: "0px 16px 70px 0px",
  },

  smallViewPortTitleBox: {
    height: "24px",
    width: "100%",
  },
  smallViewPortTitle: {
    fontSize: "11px",
    textAlign: "left",
    color: "#000",
    cursor: "pointer",
    padding: "10px",
  },

  toolTipStyleForImageHover: {
    background: "white",
    color: "gray",
    width: "242px !important",
    height: "242px !important",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    boxShadow: "0 30px 40px 0 rgba(122, 72, 255, 0.2)",
    borderRadius: "6px !important",
    marginTop: "-80px",
    marginRight: "-40px",
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    width: "100% !important",
  },
}));

export default function UploadPreviewFileViewer({ files }) {
  const classes = useProductFileViewerStyle();
  const [hover, setHover] = useState(null);
  const [showHover, setShowHover] = useState(true);
  const [selectdId, setSelectedId] = useState();
  const [selectedData, setSelectedData] = React.useState(null);
  const productDetailsNavBar = useSelector(
    (state) => state.productDetailsNavBar
  );
  const openHoverPopup = Boolean(hover);

  const handlePopoverOpen = (e, key) => {
    setHover(e.currentTarget);
    setSelectedId(key);
  };
  const handlePopoverClose = () => {
    setHover(null);
  };

  function getName() {
    const temp =
      (productDetailsNavBar?.art && "Art") ||
      (productDetailsNavBar?.image && "Image") ||
      (productDetailsNavBar?.others && "Others") ||
      (productDetailsNavBar?.sketch && "Sketch") ||
      (productDetailsNavBar?._3d && "3D Model") ||
      (productDetailsNavBar?.others && "Others") ||
      (productDetailsNavBar?.pattern && "Pattern");

    return temp;
  }
  return (
    <Box className={classes.mainBox}>
      <Box
        style={{
          overflowY: "scroll",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          position: "relative",
          // height: "100%",
          maxHeight: "700px",
          flex: selectedData !== null ? 0.6 : 1,
        }}
      >
        {files?.map((item, index) => {
          return (
            <Box
              style={{
                height: selectedData !== null || undefined ? 138 : 228,
                width: selectedData !== null || undefined ? 138 : 250,
                borderRadius: "6px",
                margin: "0px 16px 70px 0px",
              }}
            >
              {!productDetailsNavBar._3d ? (
                <Box>
                  {/* isImage(
                                        individualProductData?.data?.productImageUrl
                                        || individualProductData?.productImageUrl
                                    ) !== null ? */}
                  <Tooltip
                    placement="bottom"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "transparent",
                        },
                      },
                    }}
                    title={
                      <Box className={classes.toolTipStyleForImageHover}>
                        <img
                          style={{
                            height: 273,
                            width: 273,
                            borderRadius: "6px",
                          }}
                          // src={URL.createObjectURL(item)}
                          // src="https://4.img-dpreview.com/files/p/E~C667x0S5333x4000T1200x900~articles/3925134721/0266554465.jpeg"
                          alt="..."
                        />
                      </Box>
                    }
                  >
                    <img
                      // src={URL.createObjectURL(item)}
                      // src="https://4.img-dpreview.com/files/p/E~C667x0S5333x4000T1200x900~articles/3925134721/0266554465.jpeg"
                      alt="product"
                      style={{
                        // maxWidth: "606px",
                        height: selectedData !== null ? 138 : 228,
                        width: selectedData !== null ? 138 : 250,
                        border: "2px solid #ccc",
                        borderRadius: "6px",
                        margin: "16px 16px 0px 0px",
                      }}
                      aria-owns={
                        openHoverPopup ? "mouse-over-popover" : undefined
                      }
                      onMouseEnter={(e) => handlePopoverOpen(e, index)}
                      onMouseLeave={handlePopoverClose}
                      onClick={() => setSelectedData(item)}
                    />
                  </Tooltip>
                  <Box className={classes.smallViewPortTitleBox}>
                    <Typography className={classes.smallViewPortTitle}>
                      {item?.fileUploadedName || getName()}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  style={{
                    position: "relative",
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: "transparent",
                        },
                      },
                    }}
                    title={
                      <Box className={classes.toolTipStyleForImageHover}>
                        <model-viewer
                          style={{
                            height: "273px !important",
                            width: "273px !important",
                            // maxHeight: "394px",
                          }}
                          // src={URL.createObjectURL(item)}
                          // src="https://4.img-dpreview.com/files/p/E~C667x0S5333x4000T1200x900~articles/3925134721/0266554465.jpeg"
                          ios-src=""
                          alt="model name"
                          camera-controls
                          ar
                          ar-modes="webxr scene-viewer quick-look"
                          environment-image="neutral"
                          oncontextmenu="return false;"
                          autoplay
                        ></model-viewer>
                      </Box>
                    }
                  >
                    <model-viewer
                      style={{
                        border: "2px solid #ccc",
                        borderRadius: "6px",
                        margin: "16px 16px 0px 0px",
                        width: "100%",
                        height: selectedData !== null || undefined ? 138 : 228,
                      }}
                      // src={URL.createObjectURL(item)}
                      // src="https://4.img-dpreview.com/files/p/E~C667x0S5333x4000T1200x900~articles/3925134721/0266554465.jpeg"
                      ios-src=""
                      alt="model name"
                      camera-controls
                      ar
                      ar-modes="webxr scene-viewer quick-look"
                      environment-image="neutral"
                      oncontextmenu="return false;"
                      autoplay
                      onClick={() => setSelectedData(item)}
                    ></model-viewer>
                  </Tooltip>
                  <Box className={classes.smallViewPortTitleBox}>
                    <Typography className={classes.smallViewPortTitle}>
                      {item?.fileUploadedName || getName()}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
      {selectedData !== null && (
        <Box style={{ flex: 0.4 }}>
          <RightPannelInfoPreview
            is3D={productDetailsNavBar._3d}
            // individualProductData={individualProductData}
            selectedData={selectedData}
          />
        </Box>
      )}
    </Box>
  );
}
