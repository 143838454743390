const initialState = {
  companies: "grid",
  products: "grid",
  designers: "grid",
};

export const girdListViewActionTypes = {
  SET_VIEW: "SET_VIEW",
};

function girdListViewReducer(state = initialState, action) {
  switch (action.type) {
    case girdListViewActionTypes.SET_VIEW:
      if (action.payload && action.payload.type && action.payload.viewType) {
        return {
          ...state,
          [action.payload.type]: action.payload.viewType,
        };
      }

      return state;
    default:
      return state;
  }
}

export default girdListViewReducer;
