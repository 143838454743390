const initialState = {
  data: null,
};

export const categoryTypesPreviewData = {
  SAVE_CATEGORY_TYPES_PREVIEW_DATA: "SAVE_CATEGORY_TYPES_DATA",
};

export function previewCategoryDataReducer(state = initialState, action) {
  switch (action.type) {
    case categoryTypesPreviewData.SAVE_CATEGORY_TYPES_PREVIEW_DATA:
      return {
        data: JSON.parse(JSON.stringify(action.payload)),
      };

    default:
      return state;
  }
}

// const initialState2 = {
//   upgradeStatus: false,
// };

// export const localData = {
//   SET_UPGRADE_STATUS_TRUE: "SET_UPGRADE_STATUS_TRUE",
//   SET_UPGRADE_STATUS_FALSE: "SET_UPGRADE_STATUS_FALSE",
// };

// export function localDataReducer(state = initialState2, action) {
//   switch (action.type) {
//     case localData.SET_UPGRADE_STATUS_TRUE:
//       return { ...initialState2, upgradeStatus: true };
//     case localData.SET_UPGRADE_STATUS_FALSE:
//       return { ...initialState2, upgradeStatus: false };

//     default:
//       return state;
//   }
// }
