/*
 **	User name
 **	Created	5/25/2023
 **	helper.ts
 */

/* eslint-disable no-useless-escape*/
import CryptoJS from "crypto-js";

const regularEmailExp =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
const regularPasswordExp =
  // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&*_-<.])[A-Za-z\d@$!%*#?&*_-<.]{8,}$/;
  // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&*_-<.])[A-Za-z\d@$!%*#?&*_-<.]{8,}$/;
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&*_\-<\.])[A-Za-z\d@$!%*#?&*_\-<\.]{8,}$/;
const regularusernameExp = /^[a-z,A-Z,0-9._-]{3,36}$/;
const regularnameExp = /^[0-9A-Za-zÀ-ž\u0370-\u03FF\u0400-\u04FF ]{3,36}$/;
const companynameExp = /^[0-9A-za-z&. ]{3,36}$/;
const licenseKeyExp = /^([A-Za-z0-9]{5}-){4}[A-Za-z0-9]{5}$/;
const urlRegExp =
  /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
const imageUrlRegExp = /\.(jpeg|jpg|gif|png|PNG|JPG|JPEG|JPEG\svg)$/;
// const imageUrlRegExp = /([a-z\-_0-9\/\:\.]*\.(jpeg|jpg|gif|png|PNG|JPEG\svg))/i
const gradeRegExp = /^A-?|[BCD][+-]?|[AW]?F$/;

export const isValidPassword = (value) => regularPasswordExp.test(value);
export const isValidEmail = (value) => regularEmailExp.test(value);
export const isValidUsername = (value) => regularusernameExp.test(value);
export const isValidName = (value) => regularnameExp.test(value);
export const isValidCompanyName = (value) => companynameExp.test(value);
export const isValidDate = (d) => {
  const date = new Date(d);

  return date instanceof Date && !isNaN(date as any);
};
export const isValidLicenseKey = (value) => licenseKeyExp.test(value);
export const isValidUrl = (value) => urlRegExp.test(value);
export const isValidGrade = (value) => gradeRegExp.test(value);

let selectedType = false;

export const getterType = (type) => {
  selectedType = type;
};
export const setterType = () => selectedType;
export const isImage = (value) => String(value)?.match(imageUrlRegExp);

export const isIndividualUser = (profileType) => profileType === 1;
export const isCompanyUser = (profileType) => profileType === 2;
export const isEmployeeUser = (profileType) => profileType === 3;

export const cryptr = {
  encrypt: (data) =>
    encodeURIComponent(
      CryptoJS.AES.encrypt(JSON.stringify(data), "my-secret-key@123").toString()
    ),
  decrypt: (data) =>
    decodeURIComponent(
      JSON.parse(
        CryptoJS.AES.decrypt(data, "my-secret-key@123").toString(
          CryptoJS.enc.Utf8
        )
      )
    ),
};

export function downloadURI(uri, name) {
  const link = document.createElement("a");

  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function intlFormat(num) {
  return new Intl.NumberFormat().format(Math.round(num * 10) / 10);
}

export function makeNumUserFriendly(number) {
  if (isNaN(parseInt(number))) {
    return 0;
  }

  if (number >= 1000000) return `${intlFormat(number / 1000000)}M`;
  if (number >= 1000) return `${intlFormat(number / 1000)}k`;
  return intlFormat(number);
}

export function getAge(DOB) {
  const today = new Date();
  const birthDate = new Date(DOB);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}
