const initialState = {
  loading: false,
  data: null,
  error: null,
};

export const webLinkUploadReducerTypes = {
  WEBLINK_UPLOAD: "WEBLINK_UPLOAD",
  REQUEST_WEBLINK_UPLOAD: "REQUEST_WEBLINK_UPLOAD",
  RESPONSE_WEBLINK_UPLOAD: "RESPONSE_WEBLINK_UPLOAD",
  ERROR_WEBLINK_UPLOAD: "ERROR_WEBLINK_UPLOAD",
  CLEAR_WEBLINK_UPLOAD: "CLEAR_WEBLINK_UPLOAD",
};

export function webLinkUploadReducer(state = initialState, action) {
  switch (action.type) {
    case webLinkUploadReducerTypes.REQUEST_WEBLINK_UPLOAD:
      return { ...state, loading: true };
    case webLinkUploadReducerTypes.RESPONSE_WEBLINK_UPLOAD:
      return { error: null, loading: false, data: action.payload };
    case webLinkUploadReducerTypes.ERROR_WEBLINK_UPLOAD:
      return { loading: false, data: null, error: action.payload };
    case webLinkUploadReducerTypes.CLEAR_WEBLINK_UPLOAD:
      return { loading: false, data: null, error: null };

    default:
      return state;
  }
}

//delete reducer

const initialDeleteState = {
  loading: false,
  data: null,
  error: null,
};

export const webLinkDeleteReducerTypes = {
  WEBLINK_DELETE: "WEBLINK_DELETE",
  REQUEST_WEBLINK_DELETE: "REQUEST_WEBLINK_DELETE",
  RESPONSE_WEBLINK_DELETE: "RESPONSE_WEBLINK_DELETE",
  ERROR_WEBLINK_DELETE: "ERROR_WEBLINK_DELETE",
  CLEAR_WEBLINK_DELETE: "CLEAR_WEBLINK_DELETE",
};

export function webLinkDeleteReducer(state = initialDeleteState, action) {
  switch (action.type) {
    case webLinkDeleteReducerTypes.REQUEST_WEBLINK_DELETE:
      return { ...state, loading: true };
    case webLinkDeleteReducerTypes.RESPONSE_WEBLINK_DELETE:
      return { error: null, loading: false, data: action.payload };
    case webLinkDeleteReducerTypes.ERROR_WEBLINK_DELETE:
      return { loading: false, data: null, error: action.payload };
    case webLinkDeleteReducerTypes.CLEAR_WEBLINK_DELETE:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

//detail reducer

const initialDetailState = {
  loading: false,
  data: null,
  error: null,
};

export const webLinkDetailReducerTypes = {
  WEBLINK_DETAILS: "WEBLINK_DETAILS",
  REQUEST_WEBLINK_DETAILS: "REQUEST_WEBLINK_DETAILS",
  RESPONSE_WEBLINK_DETAILS: "RESPONSE_WEBLINK_DETAILS",
  ERROR_WEBLINK_DETAILS: "ERROR_WEBLINK_DETAILS",
  CLEAR_WEBLINK_DETAILS: "CLEAR_WEBLINK_DETAILS",
};

export function webLinkDetailReducer(state = initialDetailState, action) {
  switch (action.type) {
    case webLinkDetailReducerTypes.REQUEST_WEBLINK_DETAILS:
      return { ...state, loading: true };
    case webLinkDetailReducerTypes.RESPONSE_WEBLINK_DETAILS:
      return { error: null, loading: false, data: action.payload };
    case webLinkDetailReducerTypes.ERROR_WEBLINK_DETAILS:
      return { loading: false, data: null, error: action.payload };
    case webLinkDetailReducerTypes.CLEAR_WEBLINK_DETAILS:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

//detail reducer

const initialPositionState = {
  loading: false,
  data: null,
  error: null,
};

export const webLinkPositionReducerTypes = {
  WEBLINK_POSITION: "WEBLINK_POSITION",
  REQUEST_WEBLINK_POSITION: "REQUEST_WEBLINK_POSITION",
  RESPONSE_WEBLINK_POSITION: "RESPONSE_WEBLINK_POSITION",
  ERROR_WEBLINK_POSITION: "ERROR_WEBLINK_POSITION",
  CLEAR_WEBLINK_POSITION: "CLEAR_WEBLINK_POSITION",
};

export function webLinkPositionReducer(state = initialPositionState, action) {
  switch (action.type) {
    case webLinkPositionReducerTypes.REQUEST_WEBLINK_POSITION:
      return { ...state, loading: true };
    case webLinkPositionReducerTypes.RESPONSE_WEBLINK_POSITION:
      return { error: null, loading: false, data: action.payload };
    case webLinkPositionReducerTypes.ERROR_WEBLINK_POSITION:
      return { loading: false, data: null, error: action.payload };
    case webLinkPositionReducerTypes.CLEAR_WEBLINK_POSITION:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
