const initState = {
  loading: false,
  data: null,
  error: null,
  progress: null,
  dataDiffCategories: null,
  errorDiffCategories: null,
  progressDiffCategories: null,
  dataDiffCategoriesDelete: null,
  errorDiffCategoriesDelete: null,
  progressThumbnail: null,
  dataThumbnail: null,
  errorThumbnail: null,
};

export const uploadProductFileReducerTypes = {
  UPLOAD_PRODUCT_FILE: "UPLOAD_PRODUCT_FILE",
  REQUEST_UPLOAD_PRODUCT_FILE: "REQUEST_UPLOAD_PRODUCT_FILE",
  RESPONSE_UPLOAD_PRODUCT_FILE: "RESPONSE_UPLOAD_PRODUCT_FILE",
  ERROR_UPLOAD_PRODUCT_FILE: "ERROR_UPLOAD_PRODUCT_FILE",
  CLEAR_UPLOAD_PRODUCT_FILE: "CLEAR_UPLOAD_PRODUCT_FILE",
  SET_UPLOAD_PRODUCT_FILE_PROGRESS: "SET_UPLOAD_PRODUCT_FILE_PROGRESS",

  UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES",
  REQUEST_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "REQUEST_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES",
  RESPONSE_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "RESPONSE_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES",
  ERROR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "ERROR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES",
  CLEAR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "CLEAR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES",
  SET_UPLOAD_PRODUCT_FILE_PROGRESS_FOR_DIFF_CATEGORIES:
    "SET_UPLOAD_PRODUCT_FILE_PROGRESS_FOR_DIFF_CATEGORIES",

  DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES",
  REQUEST_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "REQUEST_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES",
  RESPONSE_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "RESPONSE_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES",
  ERROR_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "ERROR_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES",
  CLEAR_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
    "CLEAR_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES",

  UPLOAD_PRODUCT_THUMBNAIL_FILE: "UPLOAD_PRODUCT_THUMBNAIL_FILE",
  REQUEST_UPLOAD_PRODUCT_THUMBNAIL_FILE:
    "REQUEST_UPLOAD_PRODUCT_THUMBNAIL_FILE",
  RESPONSE_UPLOAD_PRODUCT_THUMBNAIL_FILE:
    "RESPONSE_UPLOAD_PRODUCT_THUMBNAIL_FILE",
  ERROR_UPLOAD_PRODUCT_THUMBNAIL_FILE: "ERROR_UPLOAD_PRODUCT_THUMBNAIL_FILE",
  CLEAR_UPLOAD_PRODUCT_THUMBNAIL_FILE: "CLEAR_UPLOAD_PRODUCT_THUMBNAIL_FILE",
  SET_UPLOAD_PRODUCT_THUMBNAIL_FILE_PROGRESS:
    "SET_UPLOAD_PRODUCT_THUMBNAIL_FILE_PROGRESS",
};

export function uploadProductFileReducer(state = initState, action) {
  switch (action.type) {
    case uploadProductFileReducerTypes.REQUEST_UPLOAD_PRODUCT_FILE:
      return { ...state, loading: true };
    case uploadProductFileReducerTypes.RESPONSE_UPLOAD_PRODUCT_FILE:
      return { ...state, error: null, loading: false, data: action.payload };
    case uploadProductFileReducerTypes.ERROR_UPLOAD_PRODUCT_FILE:
      return { ...state, data: null, error: action.payload };
    case uploadProductFileReducerTypes.CLEAR_UPLOAD_PRODUCT_FILE:
      return { ...state, data: null, error: null };
    case uploadProductFileReducerTypes.SET_UPLOAD_PRODUCT_FILE_PROGRESS:
      return { ...state, progress: action.payload };

    //UPLOAD CATEGORY ----
    case uploadProductFileReducerTypes.REQUEST_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case uploadProductFileReducerTypes.RESPONSE_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
      return {
        ...state,
        loading: false,
        dataDiffCategories: action.payload,
      };
    case uploadProductFileReducerTypes.ERROR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
      return {
        ...state,
        errorDiffCategories: action.payload,
      };
    case uploadProductFileReducerTypes.CLEAR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
      return {
        ...state,
        dataDiffCategories: null,
        errorDiffCategories: null,
        progressDiffCategories: null,
      };
    case uploadProductFileReducerTypes.SET_UPLOAD_PRODUCT_FILE_PROGRESS_FOR_DIFF_CATEGORIES:
      return {
        ...state,
        progressDiffCategories: action.payload,
      };

    // DELETE CATEGORY ----
    case uploadProductFileReducerTypes.REQUEST_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
      return {
        ...state,
        loading: true,
      };
    case uploadProductFileReducerTypes.RESPONSE_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
      return {
        ...state,
        loading: false,
        dataDiffCategoriesDelete: action.payload,
      };
    case uploadProductFileReducerTypes.ERROR_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
      return {
        ...state,
        errorDiffCategoriesDelete: action.payload,
      };
    case uploadProductFileReducerTypes.CLEAR_DELETE_PRODUCT_FILE_FOR_DIFF_CATEGORIES:
      return {
        ...state,
        dataDiffCategoriesDelete: null,
        errorDiffCategoriesDelete: null,
      };

    case uploadProductFileReducerTypes.REQUEST_UPLOAD_PRODUCT_THUMBNAIL_FILE:
      return { ...state, loading: true };
    case uploadProductFileReducerTypes.RESPONSE_UPLOAD_PRODUCT_THUMBNAIL_FILE:
      return {
        ...state,
        loading: false,
        errorThumbnail: null,
        dataThumbnail: action.payload,
      };
    case uploadProductFileReducerTypes.ERROR_UPLOAD_PRODUCT_THUMBNAIL_FILE:
      return { ...state, dataThumbnail: null, errorThumbnail: action.payload };
    case uploadProductFileReducerTypes.CLEAR_UPLOAD_PRODUCT_THUMBNAIL_FILE:
      return { ...state, dataThumbnail: null, errorThumbnail: null };
    case uploadProductFileReducerTypes.SET_UPLOAD_PRODUCT_THUMBNAIL_FILE_PROGRESS:
      return { ...state, progressThumbnail: action.payload };
    default:
      return state;
  }
}
