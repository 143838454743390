/*
 **	User name
 **	Created	5/25/2023
 **	notifications.tsx
 */

import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { IRootState } from "app/redux/reducers";

import { DarkNotification } from "../Logo/logos";

export default function NotificationMenu({ isLoggedIn }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notificationsData = useSelector(
    (state: IRootState) => state.notifications
  );

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    isLoggedIn && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const unread = React.useMemo(
    () =>
      notificationsData?.data?.filter(
        ({ isNotificationSeen }) => !isNotificationSeen
      ).length || 0,
    [notificationsData?.data]
  );

  const sortedData = React.useMemo(
    () => notificationsData?.data?.reverse(),
    [notificationsData?.data]
  );

  return (
    <React.Fragment>
      <IconButton
        sx={{
          backgroundColor: "transparent",
          p: 0,
        }}
        onClick={handleClick}
      >
        <DarkNotification />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu-2"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 270,
            borderRadius: 2,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "#fff",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        MenuListProps={{
          style: {
            padding: 0,
          },
        }}
      >
        <Box
          style={{
            textAlign: "center",
            padding: "10px 5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Notifications</Typography>
          <Typography
            style={{
              borderRadius: 50,
              backgroundColor: "#1976d2",
              color: "#fff",
              padding: 2,
              width: 20,
              display: "inline-block",
              fontSize: 12,
              marginLeft: 5,
            }}
          >
            {unread}
          </Typography>
        </Box>
        <Box
          style={{ background: "#f1f1f1", maxHeight: 300, overflow: "auto" }}
        >
          {sortedData?.map((item) => {
            return (
              <MenuItem key={item.notificationId}>
                <Avatar src="./assets/images/john.jpg" />
                <Typography
                  style={{ whiteSpace: "break-spaces", fontSize: 12 }}
                >
                  {item.notificationText}
                </Typography>
              </MenuItem>
            );
          })}
        </Box>
        <Box
          style={{
            textAlign: "center",
            padding: "10px 5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography style={{ cursor: "pointer", fontSize: 12 }}>
            View All
          </Typography>
        </Box>
      </Menu>
    </React.Fragment>
  );
}
