import React from "react";
import classNames from "classnames";
import { Box, Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { makeStyles } from "@mui/styles";
import AttributeBox from "app/components/CategoryComponent/AttributeBox";

const useStyles = makeStyles({
  list: { paddingTop: "0 !important", paddingBottom: "0 !important" },
  mainBox: {
    width: 403,
    display: "flex",
    flexDirection: "row",
    height: 244,
    overflow: "hidden",
  },
  leftBox: {
    width: 140,
    boxShadow: "0 10px 24px 0 rgba(43, 38, 57, 0.1)",
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
  },
  rightBox: { width: 263, overflow: "auto", padding: "4px 0" },
  parentCategory: {
    fontSize: 15,
    color: "#2b2639",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  parentCategoryRow: {
    padding: "12px 16px",
    borderBottom: "1px solid #d7d0e8",
    overflow: "hidden",
    height: 50,
    // flex: 1,
  },
  selectedBg: {
    background: "#ebe3ff",
  },
  childCategory: {
    color: "#2b2639",
    fontSize: "13px !important",
    marginLeft: "38px !important",
  },
  bold: {
    fontWeight: "bold",
  },
  selected: {
    color: "#7a48ff",
  },
  selectedChild: {
    marginLeft: "8px !important",
  },
  tick: {
    marginLeft: "12px !important",
  },
});

const ProductCategoryDropdown = ({
  rootCategories,
  categoryMaps,
  handleProductCategoryClick,
  selected,
}) => {
  const {
    ReactComponent: TickSvg,
  } = require("../../../../assets/icons/categories/Icons_Light_Tick mark Icon.svg");
  const styles = useStyles();
  const selectedValue = {
    categoryId: selected?.parentCategory?.id,
    childCategoryId: selected?.id,
    name: `${selected?.parentCategory?.name} (${selected?.name})`,
  };

  const handleChange = (value) => {
    handleProductCategoryClick?.(value);
  };

  return (
    <AttributeBox title="Product Category*">
      <FormControl
        sx={{
          minWidth: 320,
          "@media (max-width:900px)": { width: "88%", minWidth: "initial" },
        }}
      >
        <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          value={selectedValue?.categoryId ? selectedValue : ""}
          input={<OutlinedInput label="" />}
          MenuProps={{
            classes: {
              list: styles.list,
            },
          }}
          renderValue={(selected) => {
            const { name } = selected;

            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                <Typography style={{ color: "##2b2639", fontSize: 15 }}>
                  {name}
                </Typography>
              </Box>
            );
          }}
          fullWidth
        >
          <Box
            className={styles.mainBox}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <Box className={styles.leftBox}>
              {rootCategories?.map((categoryId) => (
                <Box
                  key={categoryId}
                  className={classNames(styles.parentCategoryRow, {
                    [styles.selectedBg]:
                      categoryId === selectedValue?.categoryId,
                  })}
                >
                  <Typography
                    className={classNames(styles.parentCategory, {
                      [styles.selected]:
                        categoryId === selectedValue?.categoryId,
                    })}
                  >
                    {categoryMaps?.[categoryId]?.name}
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box className={styles.rightBox}>
              {rootCategories?.map((categoryId) => (
                <Box key={categoryId}>
                  <Box key={categoryId} style={{ padding: 4 }}>
                    <Typography
                      className={classNames(styles.childCategory, styles.bold)}
                      style={{
                        color: "#2b2639",
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      {categoryMaps?.[categoryId]?.name}
                    </Typography>
                  </Box>
                  {categoryMaps?.[categoryId]?.children?.map(
                    (childCategoryId) => {
                      const selected =
                        childCategoryId === selectedValue?.childCategoryId;

                      return (
                        <MenuItem
                          key={childCategoryId}
                          value={childCategoryId}
                          style={{ padding: 4, minHeight: 0 }}
                          onClick={() => {
                            handleChange({
                              categoryId,
                              childCategoryId,
                            });
                          }}
                        >
                          {selected ? (
                            <TickSvg className={styles.tick} />
                          ) : null}
                          <Typography
                            className={classNames(styles.childCategory, {
                              [styles.selected]: selected,
                              [styles.selectedChild]: selected,
                            })}
                          >
                            {categoryMaps?.[childCategoryId]?.name}
                          </Typography>
                        </MenuItem>
                      );
                    }
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Select>
      </FormControl>
    </AttributeBox>
  );
};

export default ProductCategoryDropdown;
