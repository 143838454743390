import React, { useMemo } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import UniversalLoader from "app/components/Loader";
import ProductCardView from "app/components/Card&ListView/productViewCard";
import { Link } from "react-router-dom";
import { useAppSelector } from "app/utils/hooks";

const useMediaShortsStyles = makeStyles((theme) => ({
  mainBox: {
    position: "relative",
  },
  headingText: {
    color: "#2b2639",
    fontWeight: "bold",
    bold: 700,
    padding: "0px 0px 16px 0px",
  },
  headingBox: {
    padding: "44px 0px 0px 0px",
  },
  ellips_61_blue: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  mediaCardBox: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    width: "632px",
    height: "398px",
    padding: "0 18px 0 0",
    borderRadius: "20px",
    backdropFilter: "blur(60px)",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    borderWidth: "2px",
    backgroundImage:
      "linear-gradient(123deg, rgba(255, 255, 255, 0.5) -4%, rgba(255, 255, 255, 0.5) 106%), linear-gradient(122deg, #fff 1%, rgba(255, 255, 255, 0) 101%)",
    [theme.breakpoints.down("lg")]: {
      width: "522px",
      height: "338px",
    },
  },
  mediaCardLeft: {
    flex: 0.5,
  },
  mediaCarRight: {
    flex: 0.5,
  },
  mediaIcons: {
    display: "flex",
    flexDirection: "column",
    height: "398px",
    justifyContent: "center",
    width: "107px",
    paddingTop: "34px",
    [theme.breakpoints.down("lg")]: {
      height: "338px",
    },
  },
  description: {
    position: "absolute",
    bottom: 0,
    padding: "0px 18px 24px 24px",
  },
  mediaSection: {
    display: "flex",
    flexDirection: "row",
  },
  media: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "34px",
  },
  cardImage: {
    width: "316px",
    height: "398px",
    [theme.breakpoints.down("lg")]: {
      width: "262px",
      height: "338px",
    },
  },
  icons: {
    cursor: "pointer",
  },
}));

export default function Trendings({ title, type }) {
  const classes = useMediaShortsStyles();
  const homePageData = useAppSelector((state) => state.home);

  const hasData = homePageData?.data?.[type];

  const productsData = useMemo(() => {
    if (hasData) {
      return hasData?.slice(0, 4);
    }

    return [];
  }, [hasData]);

  if (homePageData?.loading) {
    return <UniversalLoader />;
  }

  if (!productsData?.length) {
    return null;
  }

  return (
    <Box className={classes.mainBox} style={{}}>
      <Box>
        <Box
          className={classes.headingBox}
          style={{
            flexDirection: "row",
            display: "flex",
            alignItems: "flex-end",
            marginBottom: 34,
          }}
        >
          <Box style={{ flex: 1 }}>
            <Typography
              variant="h3"
              className={classes.headingText}
              style={{ marginBottom: 0 }}
            >
              <b>{title}</b>
            </Typography>
          </Box>
          <Box>
            <Link
              className={classes.headingText}
              style={{
                color: "#a49bbe",
                fontSize: "21px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              to="/products"
            >
              View All
            </Link>
          </Box>
        </Box>
        <Box className="trending_sec">
          <ProductCardView productsList={productsData || []} gridCountSm={3} />
        </Box>
      </Box>
    </Box>
  );
}
