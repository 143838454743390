/*
 **	User name
 **	Created	5/25/2023
 **	skills-saga.ts
 */

import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { skillsActionTypes } from "../reducers/skills";

const version = process.env.REACT_APP_API_VERSION;

function* fetchSkillsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_SKILLS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchSkills({ payload }: any) {
  try {
    yield put({
      type: skillsActionTypes.FETCH_ALL_SKILLS_REQUEST,
    });
    const response = yield call(fetchSkillsRequest, payload);

    yield put({
      type: skillsActionTypes.FETCH_ALL_SKILLS_SUCCESS,
      payload: response.data,
    });
  } catch (e) {
    yield put({
      type: skillsActionTypes.FETCH_ALL_SKILLS_FAILURE,
      payload: e,
    });
  }
}

function* saveUserExperienceDetailsRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.SAVE_SKILLS.replace("{version}", version),
    payload
  );

  return res;
}

function* saveUserExperienceDetails({ payload }: any) {
  try {
    yield put({
      type: skillsActionTypes.SAVE_SKILLS_REQUEST,
    });
    const response = yield call(saveUserExperienceDetailsRequest, payload);

    yield put({
      type: skillsActionTypes.SAVE_SKILLS_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: skillsActionTypes.GET_SKILL_DETAILS,
      payload: { accountGuid: payload.accountGuid },
    });
  } catch (e) {
    yield put({
      type: skillsActionTypes.SAVE_SKILLS_FAILURE,
      payload: e,
    });
  }
}

function* fetchSkillsDetailsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_SKILL_DETAILS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchSkillsDetails({ payload }: any) {
  try {
    yield put({
      type: skillsActionTypes.GET_SKILL_DETAILS_REQUEST,
    });
    const response = yield call(fetchSkillsDetailsRequest, payload);

    yield put({
      type: skillsActionTypes.GET_SKILL_DETAILS_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: skillsActionTypes.GET_SKILL_DETAILS_FAILURE,
      payload: e,
    });
  }
}

function* combinedSaga() {
  yield all([
    takeLatest(skillsActionTypes.FETCH_ALL_SKILLS, fetchSkills),
    takeLatest(skillsActionTypes.SAVE_SKILLS, saveUserExperienceDetails),
    takeLatest(skillsActionTypes.GET_SKILL_DETAILS, fetchSkillsDetails),
  ]);
}

export default combinedSaga;
