import { createAction, createReducer } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";

export const SearchActionTypes = {
  searchGlobal: "searchGlobal/search",
  searchGlobalRequest: "searchGlobal/search/request",
  searchGlobalSuccess: "searchGlobal/search/success",
  searchGlobalFailure: "searchGlobal/search/failure",
};

const searchGlobal = createAction<{ guid: string; category: string }>(
  SearchActionTypes.searchGlobal
);
const searchGlobalRequest = createAction(SearchActionTypes.searchGlobalRequest);
const searchGlobalSuccess = createAction<Array<any>>(
  SearchActionTypes.searchGlobalSuccess
);
const searchGlobalFailure = createAction<any>(
  SearchActionTypes.searchGlobalFailure
);

const initialState = {
  loading: null,
  error: null,
  data: null,
};

const searchReducer = createReducer(initialState, (builder) => {
  builder.addCase(searchGlobalRequest, (state, action: Action) => ({
    ...state,
    loading: true,
    error: null,
  }));

  builder.addCase(searchGlobalSuccess, (state, action: Action) => {
    if (searchGlobalSuccess.match(action)) {
      return {
        ...state,
        data: action.payload as any,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(searchGlobalFailure, (state, action: Action) => {
    if (searchGlobalFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    return state;
  });
});

export const SearchActions = {
  searchGlobal,
  searchGlobalRequest,
  searchGlobalSuccess,
  searchGlobalFailure,
};

export default searchReducer;
