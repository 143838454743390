import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { filterReducerTypes } from "../reducers/filterReducers/filter";

const version = process.env.REACT_APP_API_VERSION;
const version3 = process.env.REACT_APP_API_VERSION_3;

//get all designer sort type
function* sortRequest() {
  const res = yield request(
    "get",
    EndPoints.DESIGNERS_SORT_TYPE.replace("{version}", version)
  );

  return res;
}

function* sortType() {
  try {
    yield put({ type: filterReducerTypes.REQUEST_SORT_TYPES, payload: null });
    const response = yield call(sortRequest);

    yield put({
      type: filterReducerTypes.RESPONSE_SORT_TYPES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_SORT_TYPES });
  }
}

//get all designer time sort types

function* timeSortRequest() {
  const res = yield request(
    "get",
    EndPoints.TIME_SORT_TYPE.replace("{version}", version)
  );

  return res;
}

function* timeSortType() {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_TIME_SORT_TYPES,
      payload: null,
    });
    const response = yield call(timeSortRequest);

    yield put({
      type: filterReducerTypes.RESPONSE_TIME_SORT_TYPES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_TIME_SORT_TYPES });
  }
}

//get all designer availability types

function* availabilityListRequest() {
  const res = yield request(
    "get",
    EndPoints.AVAILABILITY_TYPES.replace("{version}", version)
  );

  return res;
}

function* availabilityType() {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_AVAILABILITY_TYPES,
      payload: null,
    });
    const response = yield call(availabilityListRequest);

    yield put({
      type: filterReducerTypes.RESPONSE_AVAILABILITY_TYPES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_AVAILABILITY_TYPES });
  }
}

//get all designer tool types

function* toolTypeRequest() {
  const res = yield request(
    "get",
    EndPoints.TOOL_TYPES.replace("{version}", version)
  );

  return res;
}

function* toolType() {
  try {
    yield put({ type: filterReducerTypes.REQUEST_TOOL_TYPES, payload: null });
    const response = yield call(toolTypeRequest);

    yield put({
      type: filterReducerTypes.RESPONSE_TOOL_TYPES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_TOOL_TYPES });
  }
}

//get designers detail by name

function* getDesignersDetailByNameRequest(payload: any) {
  const res = yield request(
    "get",
    EndPoints.DESIGNERS_LIST_BY_NAME.replace("{version}", version),
    payload,
    0
  );

  return res;
}

function* getDesignersDetailByName({ params }: any) {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_DESIGNER_FILTER_BY_NAME,
      payload: null,
    });
    const response = yield call(getDesignersDetailByNameRequest, params);

    yield put({
      type: filterReducerTypes.RESPONSE_DESIGNER_FILTER_BY_NAME,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_DESIGNER_FILTER_BY_NAME });
  }
}

//get all designations

function* designationRequest() {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_DESIGNATIONS.replace("{version}", version)
  );

  return res;
}

function* designationType() {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_DESIGNATION_FILTER,
      payload: null,
    });
    const response = yield call(designationRequest);

    yield put({
      type: filterReducerTypes.RESPONSE_DESIGNATION_FILTER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_DESIGNATION_FILTER });
  }
}

//get all COMPANY TYPES

function* companyTypeRequest() {
  const res = yield request(
    "get",
    EndPoints.COMPANY_TYPES.replace("{version}", version)
  );

  return res;
}

function* companyType() {
  try {
    yield put({ type: filterReducerTypes.REQUEST_COMPANY_TYPE, payload: null });
    const response = yield call(companyTypeRequest);

    yield put({
      type: filterReducerTypes.RESPONSE_COMPANY_TYPE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_COMPANY_TYPE });
  }
}

//get all LIST OF EMPLOYESS IN COMPANY

function* employeInCompanyRequest() {
  const res = yield request(
    "get",
    EndPoints.EMPLOYESS_IN_COMPANY.replace("{version}", version)
  );

  return res;
}

function* employeInCompany() {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_EMPLOYESS_IN_COMPANY,
      payload: null,
    });
    const response = yield call(employeInCompanyRequest);

    yield put({
      type: filterReducerTypes.RESPONSE_EMPLOYESS_IN_COMPANY,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_EMPLOYESS_IN_COMPANY });
  }
}

//get all Companiess list

function* companiessListRequest(payload: any) {
  const res = yield request(
    "get",
    EndPoints.COMPANY_LIST.replace("{version}", version3),
    payload
  );

  return res;
}

function* companiessList({ payload }: any) {
  try {
    yield put({ type: filterReducerTypes.REQUEST_COMPANY_LIST, payload: null });
    const response = yield call(companiessListRequest, payload);

    yield put({
      type: filterReducerTypes.RESPONSE_COMPANY_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_COMPANY_LIST });
  }
}

//filterd designers request data

function* designersFilteredRequest(payload: any) {
  const res = yield request(
    "post",
    EndPoints.GET_FILTERED_DESIGNER.replace("{version}", version),
    payload
  );

  return res;
}

function* filteredDesigners({ params }: any) {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_DESIGNERS_FILTERED_LIST,
      payload: null,
    });
    const response = yield call(designersFilteredRequest, params);

    yield put({
      type: filterReducerTypes.RESPONSE_DESIGNERS_FILTERED_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_DESIGNERS_FILTERED_LIST });
  }
}

//filterd companies request data

// function* companiesFilteredRequest(payload: any) {
//   const res = yield request(
//     "post",
//     EndPoints.GET_FILTERED_COMPANIES.replace("{version}", version),
//     payload
//   );

//   return res;
// }

// function* FilteredCompanies({ params }: any) {
//   try {
//     yield put({
//       type: filterReducerTypes.REQUEST_COMPANIES_FILTERED_LIST,
//       payload: null,
//     });
//     const response = yield call(companiesFilteredRequest, params);

//     yield put({
//       type: filterReducerTypes.RESPONSE_COMPANIES_FILTERED_LIST,
//       payload: response?.data,
//     });
//   } catch (e) {
//     yield put({ type: filterReducerTypes.ERROR_COMPANIES_FILTERED_LIST });
//   }
// }

// filtered Companies Data
function* companiesFilteredRequest(payload: any) {
  const res = yield request(
    "get",
    EndPoints.GET_FILTERED_COMPANIES_1.replace("{version}", version3),
    payload
  );

  return res;
}

function* FilteredCompanies({ payload }: any) {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_COMPANIES_FILTERED_LIST,
      payload: null,
    });
    const response = yield call(companiesFilteredRequest, payload);

    yield put({
      type: filterReducerTypes.RESPONSE_COMPANIES_FILTERED_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_COMPANIES_FILTERED_LIST });
  }
}

//filterd products request data

function* productsFilteredRequest(payload: any) {
  const res = yield request(
    "get",
    EndPoints.PRODUCT_FILTER.replace("{version}", version3),
    payload
  );

  return res;
}

function* FilteredProducts({ params }: any) {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_PRODUCT_FILTERED_LIST,
      payload: null,
    });
    const response = yield call(productsFilteredRequest, params);

    yield put({
      type: filterReducerTypes.RESPONSE_PRODUCT_FILTERED_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_PRODUCT_FILTERED_LIST });
  }
}

//get companies autocomplete data

function* companiesAutocompleteRequest(payload: any) {
  const res = yield request(
    "get",
    EndPoints.COMPANIES_AUTOCOMPLETE_FILTER.replace("{version}", version),
    payload,
    0
  );

  return res;
}

function* companiesAutocomplete({ params }: any) {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_COMPANIES_AUTOCOMPLETE,
      payload: null,
    });
    const response = yield call(companiesAutocompleteRequest, params);

    yield put({
      type: filterReducerTypes.RESPONSE_COMPANIES_AUTOCOMPLETE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_COMPANIES_AUTOCOMPLETE });
  }
}

//get products autocomplete data

function* productsAutocompleteRequest(payload: any) {
  const res = yield request(
    "get",
    EndPoints.PRODUCTS_AUTOCOMPLETE_FILTER.replace("{version}", version3),
    payload,
    0
  );

  return res;
}

function* productsAutocomplete({ params }: any) {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_PRODUCT_AUTOCOMPLETE,
      payload: null,
    });
    const response = yield call(productsAutocompleteRequest, params);

    yield put({
      type: filterReducerTypes.RESPONSE_PRODUCT_AUTOCOMPLETE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_PRODUCT_AUTOCOMPLETE });
  }
}

// Get Material Auto Complete
function* materialsAutocompleteRequest(payload: any) {
  const res = yield request(
    "get",
    EndPoints.COMPANIES_AUTOCOMPLETE_FILTER.replace("{version}", version),
    payload,
    0
  );

  return res;
}

function* materialsAutocomplete({ params }: any) {
  try {
    yield put({
      type: filterReducerTypes.REQUEST_MATERIAL_AUTOCOMPLETE,
      payload: null,
    });
    const response = yield call(materialsAutocompleteRequest, params);

    yield put({
      type: filterReducerTypes.RESPONSE_MATERIAL_AUTOCOMPLETE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_MATERIAL_AUTOCOMPLETE });
  }
}

//get all Size

function* sizeTypeRequest() {
  const res = yield request(
    "get",
    EndPoints.GET_PRODUCT_SIZE.replace("{version}", version)
  );

  return res;
}

function* sizeType() {
  try {
    yield put({ type: filterReducerTypes.REQUEST_SIZE_TYPES, payload: null });
    const response = yield call(sizeTypeRequest);

    yield put({
      type: filterReducerTypes.RESPONSE_SIZE_TYPES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_SIZE_TYPES });
  }
}

function* tagTypeRequest() {
  const res = yield request(
    "get",
    EndPoints.GET_PRODUCT_TAG.replace("{version}", version)
  );

  return res;
}

function* tagType() {
  try {
    yield put({ type: filterReducerTypes.REQUEST_PRODUCT_TAG, payload: null });
    const response = yield call(tagTypeRequest);

    yield put({
      type: filterReducerTypes.RESPONSE_PRODUCT_TAG,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: filterReducerTypes.ERROR_PRODUCT_TAG });
  }
}

function* filerSaga() {
  yield all([
    takeLatest(filterReducerTypes.SORT_TYPES, sortType),
    takeLatest(filterReducerTypes.TIME_SORT_TYPES, timeSortType),
    takeLatest(filterReducerTypes.AVAILABILITY_TYPES, availabilityType),
    takeLatest(filterReducerTypes.TOOL_TYPES, toolType),
    takeLatest(
      filterReducerTypes.DESIGNER_FILTER_BY_NAME,
      getDesignersDetailByName
    ),
    takeLatest(filterReducerTypes.DESIGNATION_FILTER, designationType),
    takeLatest(filterReducerTypes.COMPANY_TYPE, companyType),
    takeLatest(filterReducerTypes.EMPLOYESS_IN_COMPANY, employeInCompany),
    takeLatest(filterReducerTypes.COMPANY_LIST, companiessList),
    takeLatest(filterReducerTypes.DESIGNERS_FILTERED_LIST, filteredDesigners),
    takeLatest(filterReducerTypes.COMPANIES_FILTERED_LIST, FilteredCompanies),
    takeLatest(filterReducerTypes.PRODUCT_FILTERED_LIST, FilteredProducts),
    takeLatest(
      filterReducerTypes.COMPANIES_AUTOCOMPLETE,
      companiesAutocomplete
    ),
    takeLatest(filterReducerTypes.PRODUCT_AUTOCOMPLETE, productsAutocomplete),

    takeLatest(filterReducerTypes.MATERIAL_AUTOCOMPLETE, materialsAutocomplete),
    takeLatest(filterReducerTypes.SIZE_TYPES, sizeType),

    takeLatest(filterReducerTypes.PRODUCT_TAG, tagType),
  ]);
}
export default filerSaga;
