const {
  ReactComponent: EmailLogo,
} = require("../../../assets/graphics/Email_Confirmation_Graphic.svg");

const {
  ReactComponent: NightModeCross,
} = require("../../../assets/icons/Icons_White_Cross_Icon.svg");

const {
  ReactComponent: LightModeCross,
} = require("../../../assets/icons/Day_Mode_Cross.svg");

const {
  ReactComponent: DarkBackArrow,
} = require("../../../assets/icons/Icons_Dark_Back-Arrow.svg");

const {
  ReactComponent: LightBackArrow,
} = require("../../../assets/icons/Icons_Light_Back-Arrow.svg");

const {
  ReactComponent: ColoredLogo,
} = require("../../../assets/icons/Colored_Logo.svg");

const {
  ReactComponent: ThanksGraphic,
} = require("../../../assets/graphics/Newsletter_Thanks Graphic.svg");

const {
  ReactComponent: ComponentSuccess,
} = require("../../../assets/icons/Component_Success.svg");

const {
  ReactComponent: ComponentError,
} = require("../../../assets/icons/Component_No_Success.svg");

const {
  ReactComponent: Subscribe,
} = require("../../../assets/graphics/Subscribe_Newsletter.svg");

const {
  ReactComponent: LightMessage,
} = require("../../../assets/icons/Icons_Light_Message2.svg");

const {
  ReactComponent: LightSearch,
} = require("../../../assets/icons/Icons_Light_Search.svg");

const {
  ReactComponent: LightCart,
} = require("../../../assets/icons/Icons_Light_Cart.svg");

const {
  ReactComponent: DarkNotification,
} = require("../../../assets/icons/Icons_Dark_notification.svg");

const {
  ReactComponent: LightLike,
} = require("../../../assets/icons/Icons_Light_Like.svg");

const {
  ReactComponent: DropDown,
} = require("../../../assets/icons/Icons_Dark_Dropdown.svg");

const {
  ReactComponent: GroupLeft,
} = require("../../../assets/icons/group_left.svg");

const {
  ReactComponent: GroupRight,
} = require("../../../assets/icons/group_right.svg");

const {
  ReactComponent: LightUploadIcon,
} = require("../../../assets/icons/Light_Upload_Icon.svg");

const {
  ReactComponent: LightGradeIcon,
} = require("../../../assets/icons/Icons_Light_Grade.svg");

const {
  ReactComponent: LightEducationIcon,
} = require("../../../assets/icons/Icons_Light_Education Field.svg");

const {
  ReactComponent: LightCalanderIcon,
} = require("../../../assets/icons/Icons_Light_Calender.svg");

const {
  ReactComponent: LightAccordionDropdownIcon,
} = require("../../../assets/icons/Icons_Dark_Dropdown_Accordion.svg");

const {
  ReactComponent: LightAddIcon,
} = require("../../../assets/icons/Icons_Light_Add_Icon.svg");

const {
  ReactComponent: LightEditIcon,
} = require("../../../assets/icons/Icons_Light_Edit_Icon.svg");

const {
  ReactComponent: LightAttachmentIcon,
} = require("../../../assets/icons/Icons_Light_Attachment.svg");

const {
  ReactComponent: Group_2099,
} = require("../../../assets/icons/Group_2099.svg");

const {
  ReactComponent: ColorPickerBoard,
} = require("../../../assets/icons/Icons_Light_Color swatch Icon.svg");

const {
  ReactComponent: NewDeleteIcon,
} = require("../../../assets/icons/new_Delete Icon.svg");

export {
  EmailLogo,
  NightModeCross,
  DarkBackArrow,
  LightBackArrow,
  ColoredLogo,
  LightModeCross,
  ThanksGraphic,
  ComponentSuccess,
  ComponentError,
  Subscribe,
  LightMessage,
  LightSearch,
  LightCart,
  DarkNotification,
  LightLike,
  DropDown,
  GroupLeft,
  GroupRight,
  LightUploadIcon,
  LightGradeIcon,
  LightCalanderIcon,
  LightEducationIcon,
  LightAccordionDropdownIcon,
  LightAddIcon,
  LightEditIcon,
  LightAttachmentIcon,
  Group_2099,
  ColorPickerBoard,
  NewDeleteIcon,
};
