import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { experienceActionTypes } from "../reducers/experience";

const version = process.env.REACT_APP_API_VERSION;

function* fetchAllEmployeeTypes(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_EMPLOYEE_TYPE.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchAllCompanyTypes(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_COMPANY_TYPE.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchAllDesignations(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_DESIGNATIONS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchAllLocations(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_LOCATIONS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchExperienceMetaData({ payload }: any) {
  try {
    yield put({
      type: experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE_REQUEST,
    });
    const response = yield all([
      call(fetchAllEmployeeTypes, payload),
      call(fetchAllCompanyTypes, payload),
      call(fetchAllDesignations, payload),
      call(fetchAllLocations, payload),
    ]);
    const combinedMetaData = {
      employeeTypes: response?.[0]?.data || [],
      companyTypes: response?.[1]?.data || [],
      designations: response?.[2]?.data || [],
      locations: response?.[3]?.data || [],
    };

    yield put({
      type: experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE_SUCCESS,
      payload: combinedMetaData,
    });
  } catch (e) {
    yield put({
      type: experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE_FAILURE,
      payload: e,
    });
  }
}

function* saveUserExperienceDetailsRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.SAVE_USER_EXPERIENCE.replace("{version}", version),
    payload,
    "form-data"
  );

  return res;
}

function* saveUserExperienceDetails({ payload, accountGuid }: any) {
  try {
    yield put({
      type: experienceActionTypes.SAVE_USER_EXPERIENCE_REQUEST,
    });
    const response = yield call(saveUserExperienceDetailsRequest, payload);

    yield put({
      type: experienceActionTypes.SAVE_USER_EXPERIENCE_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: experienceActionTypes.GET_EXPERIENCE_DETAILS,
      payload: { accountGuid },
    });
  } catch (e) {
    yield put({
      type: experienceActionTypes.SAVE_USER_EXPERIENCE_FAILURE,
      payload: e,
    });
  }
}

function* fetchAllExperienceDetailsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_EXPERIENCE_DETAILS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchAllExperienceDetails({ payload }: any) {
  try {
    yield put({
      type: experienceActionTypes.GET_EXPERIENCE_DETAILS_REQUEST,
    });
    const response = yield call(fetchAllExperienceDetailsRequest, payload);

    yield put({
      type: experienceActionTypes.GET_EXPERIENCE_DETAILS_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: experienceActionTypes.GET_EXPERIENCE_DETAILS_FAILURE,
      payload: e,
    });
  }
}

function* deleteExperienceByIdRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.DELETE_EXPERIENCE_BY_ID.replace("{version}", version),
    payload
  );

  return res;
}

function* deleteExperienceById({ payload }: any) {
  try {
    yield put({
      type: experienceActionTypes.DELETE_EXPERIENCE_BY_ID_REQUEST,
    });
    const response = yield call(deleteExperienceByIdRequest, payload);

    yield put({
      type: experienceActionTypes.DELETE_EXPERIENCE_BY_ID_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: experienceActionTypes.GET_EXPERIENCE_DETAILS,
      payload: { accountGuid: payload.accountGuid },
    });
  } catch (e) {
    yield put({
      type: experienceActionTypes.DELETE_EXPERIENCE_BY_ID_FAILURE,
      payload: e,
    });
  }
}

function* saveUserExperiencePositionRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SAVE_EXPERIENCE_POSITION.replace("{version}", version),
    payload
  );

  return res;
}

function* saveUserExperiencePosition({ payload, accountGuid }: any) {
  try {
    yield put({
      type: experienceActionTypes.SAVE_EXPERIENCE_POSITION_REQUEST,
    });
    const response = yield call(saveUserExperiencePositionRequest, payload);

    yield put({
      type: experienceActionTypes.SAVE_EXPERIENCE_POSITION_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: experienceActionTypes.GET_EXPERIENCE_DETAILS,
      payload: { accountGuid },
    });
  } catch (e) {
    yield put({
      type: experienceActionTypes.SAVE_EXPERIENCE_POSITION_FAILURE,
      payload: e,
    });
  }
}

function* combinedSaga() {
  yield all([
    takeLatest(
      experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE,
      fetchExperienceMetaData
    ),
    takeLatest(
      experienceActionTypes.SAVE_USER_EXPERIENCE,
      saveUserExperienceDetails
    ),
    takeLatest(
      experienceActionTypes.GET_EXPERIENCE_DETAILS,
      fetchAllExperienceDetails
    ),
    takeLatest(
      experienceActionTypes.DELETE_EXPERIENCE_BY_ID,
      deleteExperienceById
    ),
    takeLatest(
      experienceActionTypes.SAVE_EXPERIENCE_POSITION,
      saveUserExperiencePosition
    ),
  ]);
}

export default combinedSaga;
