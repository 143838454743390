const initialState = {
  loading: false,
  error: null,
  metadataInfo: null,
  status: null,

  allExperiences: [],
  allExperiencesStatus: null,

  saveExperienceStatus: null,
  saveExperienceError: null,
  saveExperiencePositionStatus: null,
  saveExperiencePositionError: null,
};

export const experienceActionTypes = {
  FETCH_META_DATA_FOR_EXPERIENCE: "FETCH_META_DATA_FOR_EXPERIENCE",
  FETCH_META_DATA_FOR_EXPERIENCE_REQUEST:
    "FETCH_META_DATA_FOR_EXPERIENCE_REQUEST",
  FETCH_META_DATA_FOR_EXPERIENCE_SUCCESS:
    "FETCH_META_DATA_FOR_EXPERIENCE_SUCCESS",
  FETCH_META_DATA_FOR_EXPERIENCE_FAILURE:
    "FETCH_META_DATA_FOR_EXPERIENCE_FAILURE",
  SAVE_USER_EXPERIENCE: "SAVE_USER_EXPERIENCE",
  SAVE_USER_EXPERIENCE_REQUEST: "SAVE_USER_EXPERIENCE_REQUEST",
  SAVE_USER_EXPERIENCE_SUCCESS: "SAVE_USER_EXPERIENCE_SUCCESS",
  SAVE_USER_EXPERIENCE_FAILURE: "SAVE_USER_EXPERIENCE_FAILURE",
  SAVE_USER_EXPERIENCE_CLEAR: "SAVE_USER_EXPERIENCE_CLEAR",
  GET_EXPERIENCE_DETAILS: "GET_EXPERIENCE_DETAILS",
  GET_EXPERIENCE_DETAILS_REQUEST: "GET_EXPERIENCE_DETAILS_REQUEST",
  GET_EXPERIENCE_DETAILS_SUCCESS: "GET_EXPERIENCE_DETAILS_SUCCESS",
  GET_EXPERIENCE_DETAILS_FAILURE: "GET_EXPERIENCE_DETAILS_FAILURE",
  DELETE_EXPERIENCE_BY_ID: "DELETE_EXPERIENCE_BY_ID",
  DELETE_EXPERIENCE_BY_ID_REQUEST: "DELETE_EXPERIENCE_BY_ID_REQUEST",
  DELETE_EXPERIENCE_BY_ID_SUCCESS: "DELETE_EXPERIENCE_BY_ID_SUCCESS",
  DELETE_EXPERIENCE_BY_ID_FAILURE: "DELETE_EXPERIENCE_BY_ID_FAILURE",
  SAVE_EXPERIENCE_POSITION: "SAVE_EXPERIENCE_POSITION",
  SAVE_EXPERIENCE_POSITION_REQUEST: "SAVE_EXPERIENCE_POSITION_REQUEST",
  SAVE_EXPERIENCE_POSITION_SUCCESS: "SAVE_EXPERIENCE_POSITION_SUCCESS",
  SAVE_EXPERIENCE_POSITION_FAILURE: "SAVE_EXPERIENCE_POSITION_FAILURE",
  SAVE_EXPERIENCE_POSITION_CLEAR: "SAVE_EXPERIENCE_POSITION_CLEAR",
};

export function experienceReducer(state = initialState, action) {
  switch (action.type) {
    case experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE_REQUEST:
      return {
        ...state,
        error: null,
        status: experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE_REQUEST,
      };
    case experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE_FAILURE,
      };
    case experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE_SUCCESS:
      return {
        ...state,
        metadataInfo: action.payload,
        status: experienceActionTypes.FETCH_META_DATA_FOR_EXPERIENCE_SUCCESS,
      };

    case experienceActionTypes.GET_EXPERIENCE_DETAILS_REQUEST:
      return {
        ...state,
        error: null,
        allExperiencesStatus:
          experienceActionTypes.GET_EXPERIENCE_DETAILS_REQUEST,
      };
    case experienceActionTypes.GET_EXPERIENCE_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload,
        allExperiencesStatus:
          experienceActionTypes.GET_EXPERIENCE_DETAILS_FAILURE,
      };

    case experienceActionTypes.GET_EXPERIENCE_DETAILS_SUCCESS:
      return {
        ...state,
        allExperiences: action.payload,
        allExperiencesStatus:
          experienceActionTypes.GET_EXPERIENCE_DETAILS_SUCCESS,
      };

    case experienceActionTypes.SAVE_USER_EXPERIENCE_REQUEST:
      return {
        ...state,
        saveExperienceError: null,
        saveExperienceStatus:
          experienceActionTypes.SAVE_USER_EXPERIENCE_REQUEST,
      };
    case experienceActionTypes.SAVE_USER_EXPERIENCE_FAILURE:
      return {
        ...state,
        saveExperienceError: action.payload,
        saveExperienceStatus:
          experienceActionTypes.SAVE_USER_EXPERIENCE_FAILURE,
      };

    case experienceActionTypes.SAVE_USER_EXPERIENCE_SUCCESS:
      return {
        ...state,
        saveExperienceError: null,
        saveExperienceStatus:
          experienceActionTypes.SAVE_USER_EXPERIENCE_SUCCESS,
      };

    case experienceActionTypes.SAVE_USER_EXPERIENCE_CLEAR:
      return {
        ...state,
        saveExperienceError: null,
        saveExperienceStatus: null,
      };

    default:
      return state;
  }
}
