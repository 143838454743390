/*
 **	User name
 **	Created	6/5/2023
 **	PurchaseContext.tsx
 */

import React, { Dispatch, SetStateAction } from "react";
import Stripe from "stripe";

type PurchaseContextValueType = {
  customerId: string;
  subscriptionId: string;
  prorationDate: string;
  subscriptionDetails: null | Stripe.Subscription;
  createSubscriptionCheckoutSession: (
    data: any
  ) => Promise<Stripe.Response<Stripe.Checkout.Session>>;
  setNewPlan: Dispatch<
    SetStateAction<{
      licenseName: string;
      licenseCost: string;
    }>
  >;
  newPlan: {
    licenseID: number;
    licenseName: string;
    licenseCost: string;
    licenseDescription: string;
  };
  currentSession: Stripe.Checkout.Session | null;
  resetSession: () => void;

  createPaymentCheckoutSession: (
    data: any
  ) => Promise<Stripe.Response<Stripe.Checkout.Session>>;

  createStripeConnectAccount: ({
    email,
    accountId,
    data,
  }: {
    email: string;
    accountId: any;
    data?: any;
  }) => Promise<null | Stripe.Response<Stripe.AccountLink>>;

  retrieveStripeAccount: (
    accountId: string
  ) => Promise<Stripe.Response<Stripe.Account>>;
  createRefund: (data: {
    amount: any;
    payment_intent: string;
    stripeAccount: string;
  }) => Promise<Stripe.Response<Stripe.Refund>>;
  retrieveSubscriptionUpdateSummary: (
    data: any
  ) => Promise<Stripe.Response<Stripe.UpcomingInvoice>>;
  updateSubscriptionSummary: (
    data: any
  ) => Promise<Stripe.Response<Stripe.Subscription>>;
};

export const PurchaseContext = React.createContext<PurchaseContextValueType>({
  customerId: "",
  subscriptionId: "",
  prorationDate: "",
  subscriptionDetails: null,
  createSubscriptionCheckoutSession: (data) => null,
  setNewPlan: (plan) => null,
  newPlan: {
    licenseID: null,
    licenseName: null,
    licenseCost: null,
    licenseDescription: null,
  },
  resetSession: () => null,
  currentSession: null,
  createPaymentCheckoutSession: (data) => null,
  createStripeConnectAccount: (data) => null,
  retrieveStripeAccount: (accountId) => null,
  createRefund: (data) => null,
  retrieveSubscriptionUpdateSummary: (data) => null,
  updateSubscriptionSummary: (data) => null,
});
