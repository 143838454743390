import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import {
  companyActionTypes,
  companyDeleteEmployeesActionTypes,
  companyInviteEmployeesActionTypes,
  getCompanyEmployeesForSearchActionTypes,
  companyPermissionsActionTypes,
  saveSkipSettingForInviteEmployeesActionTypes,
  updateCompanyEmployeeLicenseActionTypes,
  updateCompanyEmployeeRoleActionTypes,
} from "../reducers/company";

const version = process.env.REACT_APP_API_VERSION;

function* fetchCompanyDetailsByUserNameRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_COMPANY_DETAILS_BY_USERNAME.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchAllCompanyTypesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_COMPANY_TYPE.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchCompanyDetailsByUserName({ payload }) {
  try {
    yield put({
      type: companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME_REQUEST,
    });
    const response = yield call(fetchCompanyDetailsByUserNameRequest, {
      userName: payload.userName,
      accountGUID: payload.accountGUID,
    });

    yield put({
      type: companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME_FAILURE,
      payload: e,
    });
  }
}

function* listOfCompanyType() {
  try {
    yield put({
      type: companyActionTypes.FETCH_COMPANY_TYPE_REQUEST,
      payload: null,
    });
    const response = yield call(fetchAllCompanyTypesRequest);

    yield put({
      type: companyActionTypes.FETCH_COMPANY_TYPE_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: companyActionTypes.FETCH_COMPANY_TYPE_FAILURE,
      payload: e,
    });
  }
}

function* companyInviteEmployeesRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.INVITE_EMPLOYEES_FOR_REGISTRATION.replace("{version}", version),
    payload
  );

  return res;
}

function* companyInviteEmployees({ payload }) {
  try {
    yield put({
      type: companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION_REQUEST,
    });
    const response = yield call(companyInviteEmployeesRequest, payload);

    yield put({
      type: companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: companyActionTypes.GET_ALL_COMPANY_EMPLOYEES,
      payload: {
        companyId: payload.companyId,
        accountGuid: payload.accountGuid,
      },
    });
  } catch (e) {
    yield put({
      type: companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION_FAILURE,
      payload: e,
    });
  }
}
///// Company/GetAllCompanyEmployees ===========

function* getAllCompanyEmployeesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_COMPANY_EMPOLOYEES.replace("{version}", version),
    payload
  );

  return res;
}

function* getAllCompanyEmployees({ payload }) {
  try {
    yield put({
      type: companyActionTypes.REQUEST_GET_ALL_COMPANY_EMPLOYEES,
      payload: null,
    });
    const response = yield call(getAllCompanyEmployeesRequest, payload);

    yield put({
      type: companyActionTypes.SUCCESS_GET_ALL_COMPANY_EMPLOYEES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: companyActionTypes.ERROR_GET_ALL_COMPANY_EMPLOYEES,
      payload: e,
    });
  }
}

///// Company/GetAllCompanyRoles===========

function* getAllCompanyRolesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_COMPANY_ROLES.replace("{version}", version)
  );

  return res;
}

function* getAllCompanyRoles() {
  try {
    yield put({
      type: companyActionTypes.REQUEST_GET_ALL_COMPANY_ROLES,
      payload: null,
    });
    const response = yield call(getAllCompanyRolesRequest);

    yield put({
      type: companyActionTypes.SUCCESS_GET_ALL_COMPANY_ROLES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: companyActionTypes.ERROR_GET_ALL_COMPANY_ROLES,
      payload: e,
    });
  }
}

///// Company/GetAllCompanyLicences ===========

function* getAllCompanyLicencesRequest() {
  const res = yield request(
    "get",
    EndPoints.GET_COMPANY_LICENCES.replace("{version}", version)
  );

  return res;
}

function* getAllCompanyLicences() {
  try {
    yield put({
      type: companyActionTypes.REQUEST_GET_ALL_COMPANY_LICENCES,
      payload: null,
    });
    const response = yield call(getAllCompanyLicencesRequest);

    yield put({
      type: companyActionTypes.SUCCESS_GET_ALL_COMPANY_LICENCES,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: companyActionTypes.ERROR_GET_ALL_COMPANY_LICENCES,
      payload: e,
    });
  }
}

function* companyDeleteEmployeesRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.DELETE_COMPANY_EMPLOYEES.replace("{version}", version),
    payload
  );

  return res;
}

function* companyDeleteEmployees({ payload }) {
  try {
    yield put({
      type: companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES_REQUEST,
    });
    const response = yield call(companyDeleteEmployeesRequest, payload);

    yield put({
      type: companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES_SUCCESS,
      payload: response?.data,
    });

    yield put({
      type: companyActionTypes.GET_ALL_COMPANY_EMPLOYEES,
      payload: {
        companyId: payload.companyId,
        accountGuid: payload.accountGuid,
      },
    });
  } catch (e) {
    yield put({
      type: companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES_FAILURE,
      payload: e,
    });
  }
}

/////////////////////////////////////////////////////////

function* updateCompanyEmployeeRoleRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.UPDATE_EMPLOYEES_ROLE.replace("{version}", version),
    payload
  );

  return res;
}

function* updateCompanyEmployeeRole({ payload }) {
  try {
    yield put({
      type: updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE_REQUEST,
    });
    const response = yield call(updateCompanyEmployeeRoleRequest, payload);

    yield put({
      type: updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE_SUCCESS,
      payload: response?.data,
    });

    yield put({
      type: companyActionTypes.GET_ALL_COMPANY_EMPLOYEES,
      payload: {
        companyId: payload.companyId,
        accountGuid: payload.accountGuid,
      },
    });
  } catch (e) {
    yield put({
      type: updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE_FAILURE,
      payload: e,
    });
  }
}

///////////////////////////////////////////////////////////

function* updateCompanyEmployeeLicenseRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.UPDATE_EMPLOYEES_LICENSE.replace("{version}", version),
    payload
  );

  return res;
}

function* updateCompanyEmployeeLicense({ payload }) {
  try {
    yield put({
      type: updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE_REQUEST,
    });
    const response = yield call(updateCompanyEmployeeLicenseRequest, payload);

    yield put({
      type: updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE_SUCCESS,
      payload: response?.data,
    });

    yield put({
      type: companyActionTypes.GET_ALL_COMPANY_EMPLOYEES,
      payload: {
        companyId: payload.companyId,
        accountGuid: payload.accountGuid,
      },
    });
  } catch (e) {
    yield put({
      type: updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE_FAILURE,
      payload: e,
    });
  }
}

///////////////////////////////////////////////////////////

function* saveSkipSettingForInviteEmployeesRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES.replace(
      "{version}",
      version
    ),
    payload
  );

  return res;
}

function* saveSkipSettingForInviteEmployees({ payload }) {
  try {
    yield put({
      type: saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_REQUEST,
    });
    const response = yield call(
      saveSkipSettingForInviteEmployeesRequest,
      payload
    );

    yield put({
      type: saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_SUCCESS,
      payload: response?.data,
    });

    yield put({
      type: companyActionTypes.GET_ALL_COMPANY_EMPLOYEES,
      payload: {
        companyId: payload.companyId,
        accountGuid: payload.accountGuid,
      },
    });
  } catch (e) {
    yield put({
      type: saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES_FAILURE,
      payload: e,
    });
  }
}

//////////////////////////////////////////////////

function* getCompanyEmployeesForSearchRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_COMPANY_EMPLOYEE_FOR_SEARCH.replace("{version}", version),
    payload
  );

  return res;
}

function* getCompanyEmployeesForSearch({ payload }) {
  try {
    yield put({
      type: getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH_REQUEST,
      payload: null,
    });
    const response = yield call(getCompanyEmployeesForSearchRequest, payload);

    yield put({
      type: getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH_FAILURE,
      payload: e,
    });
  }
}

//////////////////////////////////////////////////////////

function* getCompanyPermissionsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_COMPANY_PERMISSIONS.replace("{version}", version),
    payload
  );

  return res;
}

function* getCompanyPermissions({ payload }) {
  try {
    yield put({
      type: companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS_REQUEST,
      payload,
    });
    const response = yield call(getCompanyPermissionsRequest, payload);

    yield put({
      type: companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS_FAILURE,
      payload: e,
    });
  }
}

//////////////////////////////////////////////////

function* updateCompanyPermissionsRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.UPDATE_COMPANY_PERMISSIONS.replace("{version}", version),
    payload
  );

  return res;
}

function* updateCompanyPermissions({ payload }) {
  try {
    yield put({
      type: companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_REQUEST,
      payload: null,
    });
    const response = yield call(updateCompanyPermissionsRequest, payload);

    yield put({
      type: companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_SUCCESS,
      payload: response?.data,
    });

    yield put({
      type: companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS,
      payload: {
        ...payload,
        refetching: true,
      },
    });
  } catch (e) {
    yield put({
      type: companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS_FAILURE,
      payload: e,
    });
  }
}

//////////////////////////////////////////////////

function* paymentSaga() {
  yield all([
    takeLatest(
      companyActionTypes.FETCH_COMPANY_DETAILS_BY_USERNAME,
      fetchCompanyDetailsByUserName
    ),
    takeLatest(companyActionTypes.FETCH_COMPANY_TYPE, listOfCompanyType),
    takeLatest(
      companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION,
      companyInviteEmployees
    ),
    takeLatest(
      companyActionTypes.GET_ALL_COMPANY_EMPLOYEES,
      getAllCompanyEmployees
    ),
    takeLatest(companyActionTypes.GET_ALL_COMPANY_ROLES, getAllCompanyRoles),
    takeLatest(
      companyActionTypes.GET_ALL_COMPANY_LICENCES,
      getAllCompanyLicences
    ),
    takeLatest(
      companyDeleteEmployeesActionTypes.DELETE_COMPANY_EMPLOYEES,
      companyDeleteEmployees
    ),
    takeLatest(
      updateCompanyEmployeeRoleActionTypes.UPDATE_EMPLOYEES_ROLE,
      updateCompanyEmployeeRole
    ),
    takeLatest(
      updateCompanyEmployeeLicenseActionTypes.UPDATE_EMPLOYEES_LICENSE,
      updateCompanyEmployeeLicense
    ),

    takeLatest(
      saveSkipSettingForInviteEmployeesActionTypes.SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES,
      saveSkipSettingForInviteEmployees
    ),

    takeLatest(
      getCompanyEmployeesForSearchActionTypes.GET_COMPANY_EMPLOYEE_FOR_SEARCH,
      getCompanyEmployeesForSearch
    ),
    takeLatest(
      companyPermissionsActionTypes.GET_COMPANY_PERMISSIONS,
      getCompanyPermissions
    ),
    takeLatest(
      companyPermissionsActionTypes.UPDATE_COMPANY_PERMISSIONS,
      updateCompanyPermissions
    ),
  ]);
}
export default paymentSaga;
