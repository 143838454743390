/*
 **	User name
 **	Created	5/25/2023
 **	academic.ts
 */

const initialState = {
  loading: false,
  data: null,
  error: null,
};

//UPLOAD ACADEMICS
export const uploadAcademicsReducerTypes = {
  UPLOAD_ACADEMICS: "UPLOAD_ACADEMICS",
  REQUEST_UPLOAD_ACADEMICS: "REQUEST_UPLOAD_ACADEMICS",
  RESPONSE_UPLOAD_ACADEMICS: "RESPONSE_UPLOAD_ACADEMICS",
  ERROR_UPLOAD_ACADEMICS: "ERROR_UPLOAD_ACADEMICS",
  CLEAR_UPLOAD_ACADEMICS: "CLEAR_UPLOAD_ACADEMICS",
};

export function uploadAcademicsReducer(state = initialState, action) {
  switch (action.type) {
    case uploadAcademicsReducerTypes.REQUEST_UPLOAD_ACADEMICS:
      return { ...state, loading: true };
    case uploadAcademicsReducerTypes.RESPONSE_UPLOAD_ACADEMICS:
      return { error: null, loading: false, data: action.payload };
    case uploadAcademicsReducerTypes.ERROR_UPLOAD_ACADEMICS:
      return { loading: false, data: null, error: action.payload };
    case uploadAcademicsReducerTypes.CLEAR_UPLOAD_ACADEMICS:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

//UPLOAD ACADEMICS

const initState = {
  loading: false,
  data: null,
  error: null,
};

export const AcademicsDetailReducerTypes = {
  ACADEMICS_DETAILS: "ACADEMICS_DETAILS",
  REQUEST_ACADEMICS_DETAILS: "REQUEST_ACADEMICS_DETAILS",
  RESPONSE_ACADEMICS_DETAILS: "RESPONSE_ACADEMICS_DETAILS",
  ERROR_ACADEMICS_DETAILS: "ERROR_ACADEMICS_DETAILS",
  CLEAR_ACADEMICS_DETAILS: "CLEAR_ACADEMICS_DETAILS",

  SAVE_ACADEMIC_POSITION: "SAVE_ACADEMIC_POSITION",
  SAVE_ACADEMIC_POSITION_REQUEST: "SAVE_ACADEMIC_POSITION_REQUEST",
  SAVE_ACADEMIC_POSITION_SUCCESS: "SAVE_ACADEMIC_POSITION_SUCCESS",
  SAVE_ACADEMIC_POSITION_FAILURE: "SAVE_ACADEMIC_POSITION_FAILURE",
  SAVE_ACADEMIC_POSITION_CLEAR: "SAVE_EXPERIENCE_POSITION_CLEAR",
};

export function academicsDetailReducer(state = initState, action) {
  switch (action.type) {
    case AcademicsDetailReducerTypes.REQUEST_ACADEMICS_DETAILS:
      return { ...state, loading: true };
    case AcademicsDetailReducerTypes.RESPONSE_ACADEMICS_DETAILS:
      return { error: null, loading: false, data: action.payload };
    case AcademicsDetailReducerTypes.ERROR_ACADEMICS_DETAILS:
      return { loading: false, data: null, error: action.payload };
    case AcademicsDetailReducerTypes.CLEAR_ACADEMICS_DETAILS:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

//delete ACADEMICS

const initDelState = {
  loading: false,
  data: null,
  error: null,
};

export const deleteAcademicsReducerTypes = {
  DELETE_ACADEMICS: "DELETE_ACADEMICS",
  REQUEST_DELETE_ACADEMICS: "REQUEST_DELETE_ACADEMICS",
  RESPONSE_DELETE_ACADEMICS: "RESPONSE_DELETE_ACADEMICS",
  ERROR_DELETE_ACADEMICS: "ERROR_DELETE_ACADEMICS",
  CLEAR_DELETE_ACADEMICS: "CLEAR_DELETE_ACADEMICS",
};

export function deleteAcademicsReducer(state = initDelState, action) {
  switch (action.type) {
    case deleteAcademicsReducerTypes.REQUEST_DELETE_ACADEMICS:
      return { ...state, loading: true };
    case deleteAcademicsReducerTypes.RESPONSE_DELETE_ACADEMICS:
      return { error: null, loading: false, data: action.payload };
    case deleteAcademicsReducerTypes.ERROR_DELETE_ACADEMICS:
      return { loading: false, data: null, error: action.payload };
    case deleteAcademicsReducerTypes.CLEAR_DELETE_ACADEMICS:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
