/* eslint-disable no-console */
import React from "react";
import { Box, Typography } from "@mui/material";
import LikesIcon from "@mui/icons-material/Favorite";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "app/utils/hooks";

import { usePreviewDesignStyles } from "../../../styles/previewDesign";
import { GradientColoredButton } from "../../components/UIFormFields";

export default function PreviewDesign(props) {
  const {
    title,
    subtitle,
    src1,
    src2,
    explore,
    index,
    uploadedBy,
    uploadByPicture,
    uploadedByProfession,
    likeCount,
  } = props;
  const classes = usePreviewDesignStyles();
  const navigate = useNavigate();
  const userData = useAppSelector((state) => state.register);
  const userAdditionalInfo = userData?.userAdditionalInfo;
  const tempExplore = explore.split("/");

  return (
    <Box className={classes.previewBox}>
      <Box className={classes.LeftSection}>
        <Box className={classes.headingBox}>
          <Typography variant="h2" className={classes.headingText}>
            <b>{title}</b>
          </Typography>
        </Box>
        <Typography variant="subtitle1" className={classes.SubtitleText}>
          {subtitle}
        </Typography>
        <GradientColoredButton
          style={{ width: "86px", height: "54px", color: "#fff" }}
          onClick={() => window.open(explore)}
        >
          Explore
        </GradientColoredButton>
        <Box className={classes.userInfo}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h5" className={classes.userName}>
                {/* <b>{userData.userInfo?.username}</b> */}
                <b>{uploadedBy || "Designers Capitol"}</b>
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ color: "#a49bbe", padding: "2px 0px 0px 24px" }}
              >
                <b>
                  {" "}
                  <b> {uploadedByProfession}</b>
                </b>
              </Typography>
              {/* <img src={userAdditionalInfo?.profilePictureUrl} /> */}
            </Box>
            {/* <Avatar
              className={classes.profileImage}
              src={userAdditionalInfo?.profilePictureUrl}
            /> */}
            <img
              style={{
                height: "64px",
                width: "64px",
                borderStartEndRadius: "30%",
                borderBottomLeftRadius: "30%",
              }}
              src={uploadByPicture || userAdditionalInfo?.profilePictureUrl}
            />
          </Box>
          <Box className={classes.likeButton}>
            {/* <LightLike /> */}
            <LikesIcon sx={{ color: "white" }} />
            <Box>
              <b
                style={{
                  color: "white",
                  padding: "7px 3px 3px 5px",
                  marginTop: "50%",
                }}
              >
                {likeCount}
              </b>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={classes.RightSection}>
        <img className={classes.designBackground} src={src1} />
        <img className={classes.designImage} src={src2} />
      </Box>
    </Box>
  );
}
