/*
 **	User name
 **	Created	5/25/2023
 **	confirmationModalWithIcon.tsx
 */

import React from "react";
import { makeStyles, useTheme } from "@mui/styles";
import { Box, Modal, Typography } from "@mui/material";
import classNames from "classnames";
import { LightModeCross, NightModeCross } from "../Logo/logos";
import { BlackBorderedButton, OrangeButton } from "../UIFormFields/Buttons";

const useConfirmationModalWithIcon = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxHeight: "100vh",
    maxWidth: 560,
    minWidth: 560,
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
    minHeight: 411,
  },
  modalContent: {
    color: "#2b2639",
    display: "flex",
    textAlign: "center",
  },
  actionContainer: {
    display: "flex",
    marginBottom: 34,
  },
  actions: {
    flex: 1,
  },
  actionsLeft: {
    marginRight: 24,
  },
  ctaButtom: {
    textAlign: "center",
    padding: "16px 20px !important",
  },
  cancelButton: {},
  closeBox: {
    position: "absolute",
    right: 24,
    top: 24,
  },
  icon: {},
}));

const ConfirmationModalWithIcon = ({
  onSubmit,
  content,
  onCancel,
  Icon,
  description,
  submitText,
}) => {
  const classes = useConfirmationModalWithIcon();
  const theme = useTheme();

  const handleOnYes = () => {
    if (onSubmit) {
      onSubmit(true);
    }
  };

  const handleOnNo = () => {
    onCancel();
  };

  return (
    <Modal open={true}>
      <Box
        className={classes.modalStyle}
        style={{
          backgroundColor: theme?.palette?.mode === "light" && "#fff",
        }}
      >
        <Box className={classes.closeBox}>
          {theme?.palette?.mode === "light" ? (
            <LightModeCross onClick={onCancel} style={{ cursor: "pointer" }} />
          ) : (
            <NightModeCross onClick={onCancel} style={{ cursor: "pointer" }} />
          )}
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {Icon ? (
            <Box
              className={classes.icon}
              style={{ marginBottom: 24, marginTop: 54 }}
            >
              {Icon ? <Icon /> : null}
            </Box>
          ) : null}
          <Box className={classes.modalContent} style={{ marginBottom: 16 }}>
            <Typography
              style={{
                fontSize: 27,
                textAlign: "center",
                maxWidth: 350,
                fontWeight: "bold",
                color: "#2b2639",
              }}
            >
              {content}
            </Typography>
          </Box>
          <Box className={classes.modalContent} style={{ marginBottom: 24 }}>
            <Typography
              style={{
                fontSize: 15,
                textAlign: "center",
                // maxWidth: 343, commented for issue Sprint 20 id 19
                maxWidth: 450,
                color: "#2b2639",
              }}
            >
              {description}
            </Typography>
          </Box>
          <Box className={classes.actionContainer}>
            <Box className={classNames(classes.actions, classes.actionsLeft)}>
              <BlackBorderedButton
                className={classNames(classes.ctaButtom, classes.cancelButton)}
                onClick={handleOnNo}
              >
                Cancel
              </BlackBorderedButton>
            </Box>
            <Box className={classes.actions}>
              <OrangeButton className={classes.ctaButtom} onClick={handleOnYes}>
                {submitText ? submitText : "Submit"}
              </OrangeButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmationModalWithIcon;
