import React from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { PurpleButton } from "../../components/UIFormFields";
import { productsNavBarTypes } from "../../redux/reducers/navBar";

const HomeBanner = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "100%",
    height: "678px",
    flexGrow: 0,
    objectFit: "contain",

    backdropFilter: "blur(60px)",
    boxShadow: "0 24px 34px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    borderWidth: "2px",
    borderImageSource:
      "linear-gradient(117deg, var(--white) 1%, rgba(255, 255, 255, 0) 101%)",
    borderImageSlice: "1",
    backgroundImage:
      "linear-gradient(118deg, rgba(255, 255, 255, 0.5) -4%, rgba(255, 255, 255, 0.5) 106%)",
  },
  Typography: {
    margin: "91px 0px 0px 0px",
    width: "620px",
    height: "137px",
  },
}));

export default function Banner3() {
  const group1 = require("../../../assets/graphics/young_1.png");
  const group2 = require("../../../assets/backgrounds/Frame.png");
  const classes = HomeBanner();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Box className={classes.container}>
      <Box
        sx={{
          width: "1053px",
          height: "678px",
          position: "absolute",
          right: "0px",
        }}
      >
        <img src={group2} />
      </Box>

      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Box
          sx={{
            display: "flex",
            marginLeft: "62px",
            flexDirection: "column",
            // alignSelf: "center",
            width: "620px",
            height: "500px",
          }}
        >
          <Box className={classes.Typography}>
            <Typography fontSize="91px" fontWeight="bold">
              Young Talent
            </Typography>
          </Box>

          {/* <Typography
            sx={{
              width: "299px",
              backgroundImage:
                "linear-gradient(79deg, rgba(245, 77, 100) 0%, rgba(245, 133, 97) 100%)",
              fontSize: "41px",
              backgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            COMING SOON1
          </Typography> */}
          <Box>
            <Typography>
              The young talents are the designers of tomorrow in high demand! We
              are happy to support you right from the start of your career in
              the best possible way, so that you can grow your portfolio and
              make your first contacts with companies or other designer.
            </Typography>
            <PurpleButton
              disableElevation={true}
              disableFocusRipple={true}
              disableRipple={true}
              disableTouchRipple={true}
              style={{
                marginTop: "34px",
                width: "86px",
              }}
              onClick={() => {
                dispatch({
                  type: productsNavBarTypes.YOUNG_TALENT_SELECT,
                });
                navigate("/products");
                window?.scroll({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            >
              Explore
            </PurpleButton>
          </Box>
        </Box>
      </Box>
      <Box
        flex={1}
        display="flex"
        // justifyContent={"center"}
        flexDirection="column"
      >
        <img
          src={group1}
          style={{
            width: "608px",
            height: "608px",
            right: "20px",
            position: "absolute",
          }}
        />
      </Box>
    </Box>
  );
}
