import React, { useEffect } from "react";
import { Box } from "@mui/material";
import CategoryTile from "./CategoryTile";
import AttributeBox from "./AttributeBox";
import CatgeoryDropDown, { CatgeoryDropDownMultiple } from "./CategoryDropdown";
import CatgeoryRadio from "./CategoryRadio";

type AttributeRowProps = {
  onClick: (args?: any) => any;
  index: number;
  selectedAttribute: any;
  attributesMap?: any;
  id: any;
  attributeValuesMap: any;
  selectedData: any;
};

export const AttributeRow = ({
  onClick,
  index,
  selectedAttribute,
  attributesMap,
  id,
  attributeValuesMap,
  selectedData,
}: AttributeRowProps) => {
  if (!attributesMap?.[id]) {
    return null;
  }

  const currentAttribute = attributesMap?.[id];

  ///////////////////////  block to pre-select already saved and attributes ans showing them in "Applied Selection"
  const editDetails = [];

  selectedData?.attributes?.forEach((item) => {
    if (item?.id === id) {
      editDetails.push(item?.attributeValues?.[0]?.id);
      // () =>
      //   onClick({
      //     attributesValueIds: item?.attributeValues?.[0]?.id,
      //     index,
      //     attributeId: id,
      //   });
    }
  });

  useEffect(() => {
    onClick({
      attributesValueIds: editDetails,
      index,
      attributeId: id,
    });
    // );
  }, [id]);
  // ///////////////////////////
  const isFilter =
    (sessionStorage.getItem("filterStatus") === "1" && true) || false;

  const values = (() => {
    if ((!isFilter && currentAttribute?.fieldTypeId === 2) || isFilter) {
      if (currentAttribute?.isMultiSelect) {
        return (
          <CatgeoryDropDownMultiple
            ids={currentAttribute?.attributesValues}
            categoryMap={attributeValuesMap}
            selectedCategory={
              selectedAttribute?.selectedAttribute || editDetails
            }
            onClick={(data) => {
              if (data.target.value.includes("Reset")) {
                onClick({
                  attributesValueIds: [],
                  index,
                  attributeId: id,
                });
              } else {
                onClick({
                  attributesValueIds: data.target.value,
                  index,
                  attributeId: id,
                });
              }
            }}
          />
        );
      }

      return (
        <CatgeoryDropDown
          ids={currentAttribute?.attributesValues}
          categoryMap={attributeValuesMap}
          selectedCategory={
            (selectedAttribute?.selectedAttribute || editDetails)?.[0]
          }
          onClick={(data) => {
            onClick({
              attributesValueIds: [data.target.value],
              index,
              attributeId: id,
            });
          }}
        />
      );
    }

    if (!isFilter && currentAttribute?.fieldTypeId === 1) {
      return currentAttribute?.attributesValues?.map((attributesValueId) => (
        <CatgeoryRadio
          key={attributesValueId}
          onClick={() =>
            onClick({
              attributesValueIds: [attributesValueId],
              index,
              attributeId: id,
            })
          }
          isSelected={
            (selectedAttribute?.selectedAttribute || editDetails)?.includes(
              attributesValueId
            ) || false
          }
          name={attributeValuesMap?.[attributesValueId]}
        />
      ));
    }
    return currentAttribute?.attributesValues?.map((attributesValueId) => {
      return (
        <CategoryTile
          key={attributesValueId}
          onClick={() => {
            onClick({
              attributesValueIds: [attributesValueId],
              index,
              attributeId: id,
            });
          }}
          isSelected={(
            selectedAttribute?.selectedAttribute || editDetails
          )?.includes(attributesValueId)}
          name={attributeValuesMap?.[attributesValueId]}
        />
      );
    });
  })();

  return (
    <Box>
      <AttributeBox
        title={`${
          attributesMap?.[id]?.name
            ? isFilter
              ? `${attributesMap?.[id]?.name}`
              : `${attributesMap?.[id]?.name}*`
            : "-"
        }`}
      >
        {values}
      </AttributeBox>
    </Box>
  );
};

const AttributesDropdownComponent = ({
  treeData,
  setTreeData,
  attributesData,
  setArrtibutesLength,
  selectedData,
  setAttributesTreeData,
}) => {
  const rootCategories = attributesData?.rootCategories || [];
  const attributesMap = attributesData?.attributesMap || {};
  const attributeValuesMap = attributesData?.attributeValuesMap || {};

  const handleCategoryClick = ({ attributesValueIds, index, attributeId }) => {
    let newTreeData = [...treeData];

    const attribute = {
      // id: attributeId,
      ...attributesMap?.[attributeId],
      selectedAttribute: attributesValueIds,
    };

    if (index > newTreeData?.length) {
      for (let i = 0; i < index; i++) {
        if (!newTreeData[i]) {
          newTreeData[i] = null;
        }
      }
    }

    newTreeData[index] = { ...attribute, index };

    if (
      index < newTreeData.length - 1 &&
      attribute?.children &&
      Object.keys(attribute?.children).length > 0
    ) {
      newTreeData = newTreeData.splice(0, index + 1);
    }
    setTreeData(newTreeData);
  };

  const getAttributesTree = (ids, index) => {
    const isFilter =
      (sessionStorage.getItem("filterStatus") === "1" && true) || false;

    if (ids?.length) {
      return ids?.map((id, childIndex) => {
        return (
          ((isFilter &&
            ["Material", "Size"].includes(attributesMap?.[id]?.name)) ||
            !isFilter) && (
            <div style={{ marginRight: "30px" }}>
              <AttributeRow
                key={id}
                onClick={handleCategoryClick}
                id={id}
                index={childIndex + index}
                selectedAttribute={treeData?.[childIndex + index]}
                attributeValuesMap={attributeValuesMap}
                attributesMap={attributesMap}
                selectedData={selectedData}
              />
            </div>
          )
        );
      });
    }

    return null;
  };

  const attributesTree = (() => {
    const treeDataArray = [];

    treeData?.length
      ? treeData?.forEach((category, index) => {
          const selectedAttribute = category?.selectedAttribute;

          if (category?.children && Object.keys(category?.children)?.length) {
            Array.isArray(category.children?.[selectedAttribute?.[0]]) &&
              category.children?.[selectedAttribute?.[0]]?.forEach(
                (childId, indexNew) => {
                  treeDataArray.push(
                    getAttributesTree(
                      [childId],
                      index + indexNew + rootCategories.length
                    )
                  );
                }
              );
          }
        })
      : null;

    return treeDataArray;
  })();

  const Attributes = [
    ...rootCategories?.map((cat, index) => getAttributesTree([cat], index)),
    ...attributesTree,
  ];

  useEffect(() => {
    setArrtibutesLength(Attributes.length);
  }, [Attributes.length]);

  const isFilter =
    (sessionStorage.getItem("filterStatus") === "1" && true) || false;

  return (
    <Box>
      {/* <Box className="d-flex flex-wrap w-3">{Attributes}</Box> */}
      <Box className={isFilter && "d-initial d-md-flex"}>{Attributes}</Box>
    </Box>
  );
};

export default AttributesDropdownComponent;
