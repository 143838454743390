/*
 **	User name
 **	Created	6/1/2023
 **	FreeTrialContext.tsx
 */

import React from "react";

export const FreeTrialContext = React.createContext({
  showWelcomeModal: false,
});
