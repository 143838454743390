import { handlePaginationData, handlePaginationLoadingData } from "../helper";

type ProductDataType = {
  loading: boolean;
  data: any;
  error: any;
};

const initState: ProductDataType = {
  loading: false,
  data: null,
  error: null,
};

export const productListReducerTypes = {
  PRODUCT_LIST: "PRODUCT_LIST",
  REQUEST_PRODUCT_LIST: "REQUEST_PRODUCT_LIST",
  RESPONSE_PRODUCT_LIST: "RESPONSE_PRODUCT_LIST",
  ERROR_PRODUCT_LIST: "ERROR_PRODUCT_LIST",
  CLEAR_PRODUCT_LIST: "CLEAR_PRODUCT_LIST",

  YOUNG_TALENT_PRODUCT_LIST: "YOUNG_TALENT_PRODUCT_LIST",
  REQUEST_YOUNG_TALENT_PRODUCT_LIST: "REQUEST_YOUNG_TALENT_PRODUCT_LIST",
  RESPONSE_YOUNG_TALENT_PRODUCT_LIST: "RESPONSE_YOUNG_TALENT_PRODUCT_LIST",
  ERROR_YOUNG_TALENT_PRODUCT_LIST: "ERROR_YOUNG_TALENT_PRODUCT_LIST",

  SAVED_PRODUCT_LIST: "SAVED_PRODUCT_LIST",
  REQUEST_SAVED_PRODUCT_LIST: "REQUEST_SAVED_PRODUCT_LIST",
  RESPONSE_SAVED_PRODUCT_LIST: "RESPONSE_SAVED_PRODUCT_LIST",
  ERROR_SAVED_PRODUCT_LIST: "ERROR_SAVED_PRODUCT_LIST",
};

export function productListReducer(state = initState, action) {
  switch (action.type) {
    case productListReducerTypes.REQUEST_PRODUCT_LIST: {
      const newData = handlePaginationLoadingData({
        payload: action.payload,
        oldData: state?.data,
      });

      return {
        ...state,
        loading: true,
        data: newData,
      };
    }
    case productListReducerTypes.RESPONSE_PRODUCT_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "productID",
      });

      return {
        ...state,
        loading: false,
        data: newData,
        error: null,
      };
    }
    case productListReducerTypes.ERROR_PRODUCT_LIST:
      return { ...state, loading: false, error: action.payload };

    case productListReducerTypes.CLEAR_PRODUCT_LIST:
      return initState;

    case productListReducerTypes.REQUEST_YOUNG_TALENT_PRODUCT_LIST: {
      const newData = handlePaginationLoadingData({
        payload: action.payload,
        oldData: state?.data,
      });

      return {
        ...state,
        loading: true,
        data: newData,
      };
    }
    case productListReducerTypes.RESPONSE_YOUNG_TALENT_PRODUCT_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "productID",
      });

      return {
        ...state,
        loading: false,
        data: newData,
        error: null,
      };
    }

    case productListReducerTypes.ERROR_YOUNG_TALENT_PRODUCT_LIST:
      return { loading: false, data: null, error: action.payload };

    case productListReducerTypes.REQUEST_SAVED_PRODUCT_LIST: {
      const newData = handlePaginationLoadingData({
        payload: action.payload,
        oldData: state?.data,
      });

      return {
        ...state,
        loading: true,
        data: newData,
      };
    }
    case productListReducerTypes.RESPONSE_SAVED_PRODUCT_LIST: {
      const newData = handlePaginationData({
        payload: action.payload,
        oldData: state?.data,
        uniqueIdentifier: "productID",
      });

      return {
        ...state,
        loading: false,
        data: newData,
        error: null,
      };
    }
    case productListReducerTypes.ERROR_SAVED_PRODUCT_LIST:
      return { ...state, loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}
//@my product list

export const myProductsReducerTypes = {
  MY_PRODUCT: "MY_PRODUCT",
  REQUEST_MY_PRODUCT: "REQUEST_MY_PRODUCT",
  RESPONSE_MY_PRODUCT: "RESPONSE_MY_PRODUCT",
  ERROR_MY_PRODUCT: "ERROR_MY_PRODUCT",

  CROUSAL_PRODUCT_LIST: "CROUSAL_PRODUCT_LIST",
  REQUEST_CROUSAL_PRODUCT_LIST: "REQUEST_CROUSAL_PRODUCT_LIST",
  RESPONSE_CROUSAL_PRODUCT_LIST: "RESPONSE_CROUSAL_PRODUCT_LIST",
  ERROR_CROUSAL_PRODUCT_LIST: "ERROR_CROUSAL_PRODUCT_LIST",
};

export function myProductReducer(state = initState, action) {
  switch (action.type) {
    case myProductsReducerTypes.REQUEST_CROUSAL_PRODUCT_LIST:
      return { ...state, loading: true };
    case myProductsReducerTypes.RESPONSE_CROUSAL_PRODUCT_LIST:
      return { error: null, loading: false, data: action.payload };
    case myProductsReducerTypes.ERROR_CROUSAL_PRODUCT_LIST:
      return { loading: false, data: null, error: action.payload };
    //
    case myProductsReducerTypes.REQUEST_MY_PRODUCT:
      return { ...state, loading: true };
    case myProductsReducerTypes.RESPONSE_MY_PRODUCT:
      return { error: null, loading: false, data: action.payload };
    case myProductsReducerTypes.ERROR_MY_PRODUCT:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

//@my product list

export const designerProductsReducerTypes = {
  DESIGNER_PRODUCT: "DESIGNER_PRODUCT",
  REQUEST_DESIGNER_PRODUCT: "REQUEST_DESIGNER_PRODUCT",
  RESPONSE_DESIGNER_PRODUCT: "RESPONSE_DESIGNER_PRODUCT",
  ERROR_DESIGNER_PRODUCT: "ERROR_DESIGNER_PRODUCT",
};

export function designerProductsReducer(state = initState, action) {
  switch (action.type) {
    case designerProductsReducerTypes.REQUEST_DESIGNER_PRODUCT:
      return { ...state, loading: true };
    case designerProductsReducerTypes.RESPONSE_DESIGNER_PRODUCT:
      return { error: null, loading: false, data: action.payload };
    case designerProductsReducerTypes.ERROR_DESIGNER_PRODUCT:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export const myDraftProductsReducerTypes = {
  MY_DRAFT_PRODUCT: "MY_DRAFT_PRODUCT",
  REQUEST_MY_DRAFT_PRODUCT: "REQUEST_MY_DRAFT_PRODUCT",
  RESPONSE_MY_DRAFT_PRODUCT: "RESPONSE_MY_DRAFT_PRODUCT",
  ERROR_MY_DRAFT_PRODUCT: "ERROR_MY_DRAFT_PRODUCT",
};

export function myDraftProductsReducer(state = initState, action) {
  switch (action.type) {
    case myDraftProductsReducerTypes.REQUEST_MY_DRAFT_PRODUCT:
      return { ...state, loading: true };
    case myDraftProductsReducerTypes.RESPONSE_MY_DRAFT_PRODUCT:
      return { error: null, loading: false, data: action.payload };
    case myDraftProductsReducerTypes.ERROR_MY_DRAFT_PRODUCT:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export const productReducerTypes = {
  GET_PRODUCT_BY_ID: "GET_PRODUCT_BY_ID",
  REQUEST_GET_PRODUCT_BY_ID: "REQUEST_GET_PRODUCT_BY_ID",
  RESPONSE_GET_PRODUCT_BY_ID: "RESPONSE_GET_PRODUCT_BY_ID",
  ERROR_GET_PRODUCT_BY_ID: "ERROR_GET_PRODUCT_BY_ID",
};

export function productReducer(state = initState, action) {
  switch (action.type) {
    case productReducerTypes.REQUEST_GET_PRODUCT_BY_ID:
      return { ...state, loading: true };
    case productReducerTypes.RESPONSE_GET_PRODUCT_BY_ID:
      return { error: null, loading: false, data: action.payload };
    case productReducerTypes.ERROR_GET_PRODUCT_BY_ID:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export const productDetailaReducerTypes = {
  GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
    "GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION",
  REQUEST_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
    "REQUEST_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION",
  RESPONSE_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
    "RESPONSE_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION",
  ERROR_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
    "ERROR_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION",
  CLEAR_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
    "CLEAR_PRODUCT_BY_ID_FOR_PRODUCT_SECTION",
};

export function productDetailsForProductSectionReducer(
  state = initState,
  action
) {
  switch (action.type) {
    case productDetailaReducerTypes.REQUEST_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
      return { ...state, loading: true };
    case productDetailaReducerTypes.RESPONSE_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
      return { error: null, loading: false, data: action.payload };
    case productDetailaReducerTypes.ERROR_GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
      return { loading: false, data: null, error: action.payload };
    case productDetailaReducerTypes.CLEAR_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

// GET_PRODUCT_WITH_EACH_UPLOAD_TYPE

export const productDetailaWithEachUploadTypes = {
  GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE:
    "GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE",
  REQUEST_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE:
    "REQUEST_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE",
  RESPONSE_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE:
    "RESPONSE_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE",
  ERROR_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE:
    "ERROR_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE",
  CLEAR_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE:
    "CLEAR_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE",
};

export function productDetailaWithEachUploadReducer(state = initState, action) {
  switch (action.type) {
    case productDetailaWithEachUploadTypes.REQUEST_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE:
      return { ...state, loading: true };
    case productDetailaWithEachUploadTypes.RESPONSE_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE:
      return { error: null, loading: false, data: action.payload };
    case productDetailaWithEachUploadTypes.ERROR_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE:
      return { loading: false, data: null, error: action.payload };
    case productDetailaWithEachUploadTypes.CLEAR_GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

//@product reversal

export const productReversalReducerTypes = {
  PRODUCT_REVERSAL: "PRODUCT_REVERSAL",
  REQUEST_PRODUCT_REVERSAL: "REQUEST_PRODUCT_REVERSAL",
  RESPONSE_PRODUCT_REVERSAL: "RESPONSE_PRODUCT_REVERSAL",
  ERROR_PRODUCT_REVERSAL: "ERROR_PRODUCT_REVERSAL",
};
export function productReversalReducer(state = initState, action) {
  switch (action.type) {
    case productReversalReducerTypes.REQUEST_PRODUCT_REVERSAL:
      return { ...state, loading: true };
    case productReversalReducerTypes.RESPONSE_PRODUCT_REVERSAL:
      return { error: null, loading: false, data: action.payload };
    case productReversalReducerTypes.ERROR_PRODUCT_REVERSAL:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

//product filter

export const productFilterReducerTypes = {
  PRODUCT_FILTER: "PRODUCT_FILTER",
  REQUEST_PRODUCT_FILTER: "REQUEST_PRODUCT_FILTER",
  RESPONSE_PRODUCT_FILTER: "RESPONSE_PRODUCT_FILTER",
  ERROR_PRODUCT_FILTER: "ERROR_PRODUCT_FILTER",
};
export function productFilterReducer(state = initState, action) {
  switch (action.type) {
    case productFilterReducerTypes.REQUEST_PRODUCT_FILTER:
      return { ...state, loading: true };
    case productFilterReducerTypes.RESPONSE_PRODUCT_FILTER:
      return { error: null, loading: false, data: action.payload };
    case productFilterReducerTypes.ERROR_PRODUCT_FILTER:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

//product purchase

export const productPurchsaseReducerTypes = {
  PRODUCT_PURCHASE: "PRODUCT_PURCHASE",
  REQUEST_PRODUCT_PURCHASE: "REQUEST_PRODUCT_FILTER",
  RESPONSE_PRODUCT_PURCHASE: "RESPONSE_PRODUCT_PURCHASE",
  ERROR_PRODUCT_PURCHASE: "ERROR_PRODUCT_PURCHASE",
  CLEAR_PRODUCT_PURCHASE: "CLEAR_PRODUCT_PURCHASE",
};
export function productPurchaseReducer(state = initState, action) {
  switch (action.type) {
    case productPurchsaseReducerTypes.REQUEST_PRODUCT_PURCHASE:
      return { ...state, loading: true };
    case productPurchsaseReducerTypes.RESPONSE_PRODUCT_PURCHASE:
      return { error: null, loading: false, data: action.payload };
    case productPurchsaseReducerTypes.ERROR_PRODUCT_PURCHASE:
      return { loading: false, data: null, error: action.payload };
    case productPurchsaseReducerTypes.CLEAR_PRODUCT_PURCHASE:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

export const productFavouriteReducerTypes = {
  SET_PRODUCT_FAVOURITE: "SET_PRODUCT_FAVOURITE",
  REQUEST_SET_PRODUCT_FAVOURITE: "REQUEST_SET_PRODUCT_FAVOURITE",
  RESPONSE_SET_PRODUCT_FAVOURITE: "RESPONSE_SET_PRODUCT_FAVOURITE",
  ERROR_SET_PRODUCT_FAVOURITE: "ERROR_SET_PRODUCT_FAVOURITE",
  CLEAR_SET_PRODUCT_FAVOURITE: "CLEAR_SET_PRODUCT_FAVOURITE",
};

export function productFavouriteReducer(state = initState, action) {
  switch (action.type) {
    case productFavouriteReducerTypes.REQUEST_SET_PRODUCT_FAVOURITE:
      return { ...state, loading: true };
    case productFavouriteReducerTypes.RESPONSE_SET_PRODUCT_FAVOURITE:
      return { error: null, loading: false, data: action.payload };
    case productFavouriteReducerTypes.ERROR_SET_PRODUCT_FAVOURITE:
      return { loading: false, data: null, error: action.payload };
    case productFavouriteReducerTypes.CLEAR_SET_PRODUCT_FAVOURITE:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

/////

export const productViewReducerTypes = {
  SET_PRODUCT_VIEW: "SET_PRODUCT_VIEW",
  REQUEST_SET_PRODUCT_VIEW: "REQUEST_SET_PRODUCT_VIEW",
  RESPONSE_SET_PRODUCT_VIEW: "RESPONSE_SET_PRODUCT_VIEW",
  ERROR_SET_PRODUCT_VIEW: "ERROR_SET_PRODUCT_VIEW",
  CLEAR_SET_PRODUCT_VIEW: "CLEAR_SET_PRODUCT_VIEW",
};

export function productViewReducer(state = initState, action) {
  switch (action.type) {
    case productViewReducerTypes.REQUEST_SET_PRODUCT_VIEW:
      return { ...state, loading: true };
    case productViewReducerTypes.RESPONSE_SET_PRODUCT_VIEW:
      return { error: null, loading: false, data: action.payload };
    case productViewReducerTypes.ERROR_SET_PRODUCT_VIEW:
      return { loading: false, data: null, error: action.payload };
    case productViewReducerTypes.CLEAR_SET_PRODUCT_VIEW:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

//PRODUCT MATERIALS

const initMaterialState = {
  loading: false,
  data: null,
  error: null,
};

export const materialListReducerTypes = {
  MATERIAL_LIST: "MATERIAL_LIST",
  REQUEST_MATERIAL_LIST: "REQUEST_MATERIAL_LIST",
  RESPONSE_MATERIAL_LIST: "RESPONSE_MATERIAL_LIST",
  ERROR_MATERIAL_LIST: "ERROR_MATERIAL_LIST",
};

export function materialListReducer(state = initMaterialState, action) {
  switch (action.type) {
    case materialListReducerTypes.REQUEST_MATERIAL_LIST:
      return { ...state, loading: true };
    case materialListReducerTypes.RESPONSE_MATERIAL_LIST:
      return { error: null, loading: false, data: action.payload };
    case materialListReducerTypes.ERROR_MATERIAL_LIST:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

//PRODUCT size

const initSizeState = {
  loading: false,
  data: null,
  error: null,
};

export const ProductSizeReducerTypes = {
  PRODUCT_SIZE: "PRODUCT_SIZE",
  REQUEST_PRODUCT_SIZE: "REQUEST_PRODUCT_SIZE",
  RESPONSE_PRODUCT_SIZE: "RESPONSE_PRODUCT_SIZE",
  ERROR_PRODUCT_SIZE: "ERROR_PRODUCT_SIZE",
};

export function productSizeReducer(state = initSizeState, action) {
  switch (action.type) {
    case ProductSizeReducerTypes.REQUEST_PRODUCT_SIZE:
      return { ...state, loading: true };
    case ProductSizeReducerTypes.RESPONSE_PRODUCT_SIZE:
      return { error: null, loading: false, data: action.payload };
    case ProductSizeReducerTypes.ERROR_PRODUCT_SIZE:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

///Product tag
const initTagState = {
  loading: false,
  data: null,
  error: null,
};

export const ProductTagReducerTypes = {
  PRODUCT_TAG: "PRODUCT_TAG",
  REQUEST_PRODUCT_TAG: "REQUEST_PRODUCT_TAG",
  RESPONSE_PRODUCT_TAG: "RESPONSE_PRODUCT_TAG",
  ERROR_PRODUCT_TAG: "ERROR_PRODUCT_TAG",
};

export function productTagReducer(state = initSizeState, action) {
  switch (action.type) {
    case ProductTagReducerTypes.REQUEST_PRODUCT_TAG:
      return { ...state, loading: true };
    case ProductTagReducerTypes.RESPONSE_PRODUCT_TAG:
      return { error: null, loading: false, data: action.payload };
    case ProductTagReducerTypes.ERROR_PRODUCT_TAG:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

//get Currencies

const initCurrenciesState = {
  loading: false,
  data: null,
  error: null,
};

export const currenciesReducerTypes = {
  CURRENCIES: "CURRENCIES",
  REQUEST_CURRENCIES: "REQUEST_CURRENCIES",
  RESPONSE_CURRENCIES: "RESPONSE_CURRENCIES",
  ERROR_CURRENCIES: "ERROR_CURRENCIES",
};

export function currenciesReducer(state = initCurrenciesState, action) {
  switch (action.type) {
    case currenciesReducerTypes.REQUEST_CURRENCIES:
      return { ...state, loading: true };
    case currenciesReducerTypes.RESPONSE_CURRENCIES:
      return { error: null, loading: false, data: action.payload };
    case currenciesReducerTypes.ERROR_CURRENCIES:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export const productDeleteReducerTypes = {
  DELETE_PRODUCT_BY_PRODUCT_ID: "DELETE_PRODUCT_BY_PRODUCT_ID",
  REQUEST_DELETE_PRODUCT_BY_PRODUCT_ID: "REQUEST_DELETE_PRODUCT_BY_PRODUCT_ID",
  RESPONSE_DELETE_PRODUCT_BY_PRODUCT_ID:
    "RESPONSE_DELETE_PRODUCT_BY_PRODUCT_ID",
  ERROR_DELETE_PRODUCT_BY_PRODUCT_ID: "ERROR_DELETE_PRODUCT_BY_PRODUCT_ID",
  CLEAR_DELETE_PRODUCT_BY_PRODUCT_ID: "CLEAR_DELETE_PRODUCT_BY_PRODUCT_ID",
};
export function productDeleteReducer(state = initState, action) {
  switch (action.type) {
    case productDeleteReducerTypes.REQUEST_DELETE_PRODUCT_BY_PRODUCT_ID:
      return { ...state, loading: true };
    case productDeleteReducerTypes.RESPONSE_DELETE_PRODUCT_BY_PRODUCT_ID:
      return { error: null, loading: false, data: action.payload };
    case productDeleteReducerTypes.ERROR_DELETE_PRODUCT_BY_PRODUCT_ID:
      return { loading: false, data: null, error: action.payload };
    case productDeleteReducerTypes.CLEAR_DELETE_PRODUCT_BY_PRODUCT_ID:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

export const unpublishProductReducerTypes = {
  UNPUBLISH_PRODUCT: "UNPUBLISH_PRODUCT",
  REQUEST_UNPUBLISH_PRODUCT: "REQUEST_UNPUBLISH_PRODUCT",
  RESPONSE_UNPUBLISH_PRODUCT: "RESPONSE_UNPUBLISH_PRODUCT",
  ERROR_UNPUBLISH_PRODUCT: "ERROR_UNPUBLISH_PRODUCT",
  CLEAR_UNPUBLISH_PRODUCT: "CLEAR_UNPUBLISH_PRODUCT",
};
export function unpublishProductReducer(state = initState, action) {
  switch (action.type) {
    case unpublishProductReducerTypes.REQUEST_UNPUBLISH_PRODUCT:
      return { ...state, loading: true };
    case unpublishProductReducerTypes.RESPONSE_UNPUBLISH_PRODUCT:
      return { error: null, loading: false, data: action.payload };
    case unpublishProductReducerTypes.ERROR_UNPUBLISH_PRODUCT:
      return { loading: false, data: null, error: action.payload };
    case unpublishProductReducerTypes.CLEAR_UNPUBLISH_PRODUCT:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}

export const productThumbnailDNDActionTypes = {
  PRODUCT_THUMBNAIL_UPLOAD_DND: "PRODUCT_THUMBNAIL_UPLOAD_DND",
  REQUEST_PRODUCT_THUMBNAIL_UPLOAD_DND: "REQUEST_PRODUCT_THUMBNAIL_UPLOAD_DND",
  RESPONSE_PRODUCT_THUMBNAIL_UPLOAD_DND:
    "RESPONSE_PRODUCT_THUMBNAIL_UPLOAD_DND",
  ERROR_PRODUCT_THUMBNAIL_UPLOAD_DND: "ERROR_PRODUCT_THUMBNAIL_UPLOAD_DND",
};
export function productThumbnailDNDReducer(state = initState, action) {
  switch (action.type) {
    case productThumbnailDNDActionTypes.PRODUCT_THUMBNAIL_UPLOAD_DND:
      return { ...state, loading: true };
    case productThumbnailDNDActionTypes.RESPONSE_PRODUCT_THUMBNAIL_UPLOAD_DND:
      return { error: null, loading: false, data: action.payload };
    case productThumbnailDNDActionTypes.ERROR_PRODUCT_THUMBNAIL_UPLOAD_DND:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}

export const productDownloadActionTypes = {
  PRODUCT_DETAILS_DOWNLOAD: "PRODUCT_DETAILS_DOWNLOAD",
  REQUEST_PRODUCT_DETAILS_DOWNLOAD: "REQUEST_PRODUCT_DETAILS_DOWNLOAD",
  RESPONSE_PRODUCT_DETAILS_DOWNLOAD: "RESPONSE_PRODUCT_DETAILS_DOWNLOAD",
  ERROR_PRODUCT_DETAILS_DOWNLOAD: "ERROR_PRODUCT_DETAILS_DOWNLOAD",
  CLEAR_PRODUCT_DETAILS_DOWNLOAD: "CLEAR_PRODUCT_DETAILS_DOWNLOAD",
};

export function productDownloadReducer(state = initState, action) {
  switch (action.type) {
    case productDownloadActionTypes.PRODUCT_DETAILS_DOWNLOAD:
      return { ...state, loading: true };
    case productDownloadActionTypes.RESPONSE_PRODUCT_DETAILS_DOWNLOAD:
      return { error: null, loading: false, data: action.payload };
    case productDownloadActionTypes.ERROR_PRODUCT_DETAILS_DOWNLOAD:
      return { loading: false, data: null, error: action.payload };
    case productDownloadActionTypes.CLEAR_PRODUCT_DETAILS_DOWNLOAD:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
//@save product

export const saveProductTypes = {
  SAVE_PRODUCT: "SAVE_PRODUCT",
  REQUEST_SAVE_PRODUCT: "REQUEST_SAVE_PRODUCT",
  RESPONSE_SAVE_PRODUCT: "RESPONSE_SAVE_PRODUCT",
  ERROR_SAVE_PRODUCT: "ERROR_SAVE_PRODUCT",
};

export function saveProductReducer(state = initState, action) {
  switch (action.type) {
    case saveProductTypes.REQUEST_SAVE_PRODUCT:
      return { ...state, loading: true };
    case saveProductTypes.RESPONSE_SAVE_PRODUCT:
      return { error: null, loading: false, data: action.payload };
    case saveProductTypes.ERROR_SAVE_PRODUCT:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}
