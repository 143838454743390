import { createAction, createReducer } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";

export const CartActionTypes = {
  fetchCart: "cart/fetch",
  fetchCartRequest: "cart/fetch/request",
  fetchCartSuccess: "cart/fetch/success",
  fetchCartFailure: "cart/fetch/failure",

  addToCart: "cart/add",
  addToCartRequest: "cart/add/request",
  addToCartSuccess: "cart/add/success",
  addToCartFailure: "cart/add/failure",
  addToCartClear: "cart/add/clear",

  clearCart: "cart/clear",
  clearCartRequest: "cart/clear/request",
  clearCartSuccess: "cart/clear/success",
  clearCartFailure: "cart/clear/failure",

  removeFromCart: "cart/remove",
  removeFromCartRequest: "cart/remove/request",
  removeFromCartSuccess: "cart/remove/success",
  removeFromCartFailure: "cart/remove/failure",
};

const fetchCart = createAction<{ guid: string }>(CartActionTypes.fetchCart);
const fetchCartRequest = createAction(CartActionTypes.fetchCartRequest);
const fetchCartSuccess = createAction<Array<any>>(
  CartActionTypes.fetchCartSuccess
);
const fetchCartFailure = createAction<any>(CartActionTypes.fetchCartFailure);

const addToCart = createAction<{
  accountGuid: string;
  productId: number;
  libraryProductId: number;
  callback?: () => void;
}>(CartActionTypes.addToCart);
const addToCartRequest = createAction(CartActionTypes.addToCartRequest);
const addToCartSuccess = createAction<Array<any>>(
  CartActionTypes.addToCartSuccess
);
const addToCartFailure = createAction<any>(CartActionTypes.addToCartFailure);
const addToCartClear = createAction<any>(CartActionTypes.addToCartClear);

const clearCart = createAction<{ accountGuid: string }>("cart/clear");
const clearCartRequest = createAction("cart/clear/request");
const clearCartSuccess = createAction("cart/clear/success");
const clearCartFailure = createAction<any>("cart/clear/failure");

const removeFromCart = createAction<{
  accountGuid: string;
  productId: number;
  libraryProductId: number;
  callback?: () => void;
}>("cart/remove");
const removeFromCartRequest = createAction("cart/remove/request");
const removeFromCartSuccess = createAction<Array<any>>("cart/remove/success");
const removeFromCartFailure = createAction<any>("cart/remove/failure");

const initialState = {
  cart: {
    cartDetailsList: [],
    cartTotal: 0,
    sellerStripeId: null,
    isRegisteredWithStripe: false,
  },
  addToCartLoading: true,
  status: null,
  error: null,
  addError: null,
};

const cartReducer = createReducer(initialState, (builder) => {
  builder.addCase(fetchCartRequest, (state, action: Action) => ({
    ...state,
    addToCartLoading: true,
    error: null,
  }));

  builder.addCase(fetchCartSuccess, (state, action: Action) => {
    if (fetchCartSuccess.match(action)) {
      return {
        ...state,
        cart: action.payload as any,
        addToCartLoading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchCartFailure, (state, action: Action) => {
    if (fetchCartFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        addToCartLoading: false,
      };
    }

    return state;
  });

  builder.addCase(addToCartRequest, (state, action: Action) => ({
    ...state,
    addToCartLoading: true,
    error: null,
  }));

  builder.addCase(addToCartSuccess, (state, action: Action) => {
    if (addToCartSuccess.match(action)) {
      return {
        ...state,
        cart: action.payload as any,
        addToCartLoading: false,
      };
    }

    return state;
  });

  builder.addCase(addToCartFailure, (state, action: Action) => {
    if (addToCartFailure.match(action)) {
      return {
        ...state,
        addError: action.payload,
        addToCartLoading: false,
      };
    }

    return state;
  });

  builder.addCase(addToCartClear, (state, action: Action) => {
    if (addToCartClear.match(action)) {
      return {
        ...state,
        addError: null,
        addToCartLoading: false,
      };
    }

    return state;
  });

  builder.addCase(clearCartRequest, (state, action: Action) => ({
    ...state,
    addToCartLoading: true,
    error: null,
  }));

  builder.addCase(clearCartSuccess, (state, action: Action) => ({
    ...state,
    cart: {
      cartDetailsList: [],
      cartTotal: 0,
      sellerStripeId: null,
      isRegisteredWithStripe: false,
    },
    addToCartLoading: false,
  }));

  builder.addCase(clearCartFailure, (state, action: Action) => {
    if (clearCartFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        addToCartLoading: false,
      };
    }

    return state;
  });

  builder.addCase(removeFromCartRequest, (state, action: Action) => ({
    ...state,
    addToCartLoading: true,
    error: null,
  }));

  builder.addCase(removeFromCartSuccess, (state, action: Action) => {
    if (removeFromCartSuccess.match(action)) {
      return {
        ...state,
        cart: action.payload as any,
        addToCartLoading: false,
      };
    }

    return state;
  });

  builder.addCase(removeFromCartFailure, (state, action: Action) => {
    if (removeFromCartFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        addToCartLoading: false,
      };
    }

    return state;
  });
});

export const CartActions = {
  fetchCart,
  fetchCartRequest,
  fetchCartSuccess,
  fetchCartFailure,

  addToCart,
  addToCartRequest,
  addToCartSuccess,
  addToCartFailure,
  addToCartClear,

  clearCart,
  clearCartRequest,
  clearCartSuccess,
  clearCartFailure,

  removeFromCart,
  removeFromCartRequest,
  removeFromCartSuccess,
  removeFromCartFailure,
};

export default cartReducer;
