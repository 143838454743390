import React from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { categoriesActionTypes } from "app/redux/reducers/categories";
import CatgeoryRadio from "app/components/CategoryComponent/CategoryRadio";
import AttributeBox from "app/components/CategoryComponent/AttributeBox";
import ProductCategoryDropdown from "./index";

const useStyles = makeStyles({
  dropdown: {
    transition: "all 0.7s ease",
    overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: 16,
  },
  dropdownActive: {
    height: "0",
    display: "none",
  },
});

type AttributeRowProps = {
  onClick: (args?: any) => any;
  index: number;
  selectedCategory: any;
  categoryMaps?: any;
  ids: Array<any>;
};

const AttributeRow = ({
  onClick,
  index,
  selectedCategory,
  categoryMaps,
  ids,
}: AttributeRowProps) => {
  const values = ids?.map((categoryId) => {
    return (
      <CatgeoryRadio
        key={categoryId}
        onClick={() =>
          onClick({
            categoryId,
            index,
          })
        }
        isSelected={selectedCategory?.id === categoryId}
        name={categoryMaps?.[categoryId]?.name}
      />
    );
  });

  return <AttributeBox title={"Gender*"}>{values}</AttributeBox>;
};

const ProductCategoryMainDropdownComponent = ({
  treeData,
  setTreeData,
  productCategoryData,
  handleProductCategoryClick,
  excludeCategories = [],
  includeCategories = [],
}) => {
  const dispatch = useDispatch();
  const rootCategories = productCategoryData?.rootCategories || [];
  const categoryMaps = productCategoryData?.categoryMaps || {};

  const handleCategoryClick = ({ categoryId, index }) => {
    let newTreeData = [...treeData];

    const currentCat = categoryMaps[categoryId];

    newTreeData[index] = { ...currentCat, index };

    if (index < newTreeData.length - 1) {
      newTreeData = newTreeData.splice(0, index + 1);
    }
    setTreeData(newTreeData);
    dispatch({
      type: categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_CLEAR,
    });
  };

  const getAttributesTree = (ids, index) => {
    if (ids?.length) {
      return (
        <AttributeRow
          onClick={handleCategoryClick}
          ids={ids}
          index={index}
          selectedCategory={treeData[index]}
          categoryMaps={categoryMaps}
        />
      );
    }

    return null;
  };

  const handleProductCategoryClickHandler = (data) => {
    const index = 1;
    let newTreeData = [...treeData];

    const currentCat = categoryMaps[data?.categoryId];
    const currentChildCat = categoryMaps[data?.childCategoryId];

    newTreeData[index] = {
      ...currentChildCat,
      index,
      selectedProductCategory: {
        ...data,
      },
      parentCategory: currentCat,
    };

    if (index < newTreeData.length - 1) {
      newTreeData = newTreeData.splice(0, index + 1);
    }
    setTreeData(newTreeData);

    handleProductCategoryClick(data?.childCategoryId);
  };

  const productRootCategories = (() => {
    const selectedParentCategory = treeData?.[0];

    if (!selectedParentCategory) {
      return [];
    }

    if (
      excludeCategories &&
      Object.keys(excludeCategories)?.length &&
      selectedParentCategory &&
      excludeCategories[selectedParentCategory?.id]
    ) {
      return selectedParentCategory?.children?.filter((id) => {
        return !excludeCategories[selectedParentCategory?.id].includes(id);
      });
    }

    if (
      includeCategories &&
      Object.keys(includeCategories)?.length &&
      selectedParentCategory &&
      includeCategories[selectedParentCategory?.id]
    ) {
      // return selectedParentCategory?.children?.filter((id) => {
      //   return includeCategories[selectedParentCategory?.id].includes(id);
      // });
      return includeCategories[selectedParentCategory?.id];
    }

    return selectedParentCategory?.children || [];
  })();

  const isFilter =
    (sessionStorage.getItem("filterStatus") === "1" && true) || false;

  return (
    <Box
      className={
        isFilter &&
        "d-initial d-md-flex justify-content-initial justify-content-md-between"
      }
      style={{ width: isFilter && "115%" }}
    >
      {getAttributesTree(rootCategories, 0)}
      {treeData?.length ? (
        <ProductCategoryDropdown
          rootCategories={productRootCategories}
          categoryMaps={categoryMaps}
          handleProductCategoryClick={handleProductCategoryClickHandler}
          selected={treeData?.[1] || {}}
        />
      ) : null}
    </Box>
  );
};

export default ProductCategoryMainDropdownComponent;
