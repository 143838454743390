/*
 **	User name
 **	Created	5/25/2023
 **	useUploadLibraryProductStyles.ts
 */

import { makeStyles } from "@mui/styles";

const useUploadLibraryProductStyles = makeStyles(() => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95vw",
    maxHeight: "95vh",
    borderRadius: "15px",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
  },
  header: {
    fontSize: "27px !important",
    fontWeight: "bold !important",
    backgroundImage:
      " linear-gradient(78deg, #f54d64 0%, #f58561 100%) !important",
    WebkitBackgroundClip: "text ",
    WebkitTextFillColor: "transparent ",
    textAlign: "center",
  },
  description: {
    color: "#2b2639",
    textAlign: "center",
    fontSize: "15px",
    margin: "6px 0 6px 0 !important",
  },
  button: {
    width: "69px !important",
    margin: "24px 12px 24px 12px !important",
  },
  textBox: {
    textAlign: "center",
    position: "relative",
    top: "50px",
  },
  buttonBox: { display: "flex", justifyContent: "center" },
  headerLeftSection: {
    fontWeight: "bold !important",
    fontSize: "15px !important",
    color: "#2b2639 !important",
    marginBottom: "6px !important",
  },
  subHeaderLeftSection: {
    fontSize: "13px !important",
    color: "#a49bbe !important",
  },
  detailsLeftSection: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold !important",
    margin: "25px 0 6px 0 !important",
  },
  LeftSection: {
    width: "45%",
    height: "auto",
    border: "1px dashed #d7d0e8",
    borderRadius: "6px",
    margin: "20px 5px 20px 5px",
  },
  rightSection: {
    width: "45% !important",
    // minHeight: "418px ",
    margin: "20px 5px 20px 5px !important",
  },
  subLeftSection: {
    padding: "50px 10px 20px 10px ",
  },
  clearAll: {
    textAlign: "end",
    color: "#7a48ff !important",
    fontWeight: "bold !important",
    fontSize: "13px !important",
    margin: "16px 0 16px 0 !important",
  },
  draft: {
    color: "#a49bbe !important",
    fontSize: "15px !important",
    fontWeight: "bold !important",
    margin: "50px 0 50px 0 !important",
    textAlign: "center",
    cursor: "pointer",
  },
  randerValueBox: {
    display: "flex",
    flexWrap: "wrap",
    padding: 0,
    margin: 0,
  },
  randerValueSpan: {
    display: "flex",
    color: "#7a48ff",
    fontWeight: "bold",
    alignItems: "center",
  },
  inactiveChip: {
    margin: "5px !important",
    borderRadius: "6px !important",
    color: "#a49bbe !important",
  },
  activeChip: {
    margin: "5px !important",
    borderRadius: "6px !important",
    color: "#ffffff !important",
    backgroundColor: "#2b2639 !important",
  },
  sizeActiveChip: {
    margin: "5px !important",
    borderRadius: "6px !important",
    color: "#ffffff !important",
    backgroundColor: "#7a48ff !important",
    minWidth: "40px !important",
    padding: "2px !important",
    height: "40px !important",
    fontSize: "15px !important",
  },
  sizeInactiveChip: {
    margin: "5px !important",
    borderRadius: "6px !important",
    color: "#a49bbe !important",
    minWidth: "40px !important",
    height: "40px !important",
    padding: "2px !important",
    fontSize: "15px !important",
  },

  appliedSection: {
    borderRadius: "6px !important",
    color: "#ffffff !important",
    backgroundColor: "#7a48ff !important",
    padding: "5px !important",
    margin: "5px !important",
  },
  alignment: {
    display: "flex",
    flexWrap: "wrap",
  },
  firstItem: {
    padding: "4px",
    borderRadius: "6px",
    outline: "dashed 1px #7a48ff",
    border: "solid 1px #d7d0e8",
    outlineOffset: "5px",
  },
  reset: {
    textAlign: "end",
    color: "#7a48ff",
    fontWeight: "bold !important",
    cursor: "pointer",
    margin: "10px 10px 10px 0 !important",
  },
}));

export default useUploadLibraryProductStyles;
