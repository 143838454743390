const initialState = {
  saveAboutMeStatus: null,
  saveAboutMeError: null,

  saveHeadlineStatus: null,
  saveHeadlineError: null,

  saveSocialMediaLinksStatus: null,
  saveSocialMediaLinksError: null,
};

export const aboutMeActionTypes = {
  SAVE_ABOUT_ME: "SAVE_ABOUT_ME",
  SAVE_ABOUT_ME_REQUEST: "SAVE_ABOUT_ME_REQUEST",
  SAVE_ABOUT_ME_SUCCESS: "SAVE_ABOUT_ME_SUCCESS",
  SAVE_ABOUT_ME_FAILURE: "SAVE_ABOUT_ME_FAILURE",
  SAVE_ABOUT_ME_CLEAR: "SAVE_ABOUT_ME_CLEAR",

  SAVE_HEADLINE: "SAVE_HEADLINE",
  SAVE_HEADLINE_REQUEST: "SAVE_HEADLINE_REQUEST",
  SAVE_HEADLINE_SUCCESS: "SAVE_HEADLINE_SUCCESS",
  SAVE_HEADLINE_FAILURE: "SAVE_HEADLINE_FAILURE",
  SAVE_HEADLINE_CLEAR: "SAVE_HEADLINE_CLEAR",

  SAVE_SOCIAL_MEDIA_LINKS: "SAVE_SOCIAL_MEDIA_LINKS",
  SAVE_SOCIAL_MEDIA_LINKS_REQUEST: "SAVE_SOCIAL_MEDIA_LINKS_REQUEST",
  SAVE_SOCIAL_MEDIA_LINKS_SUCCESS: "SAVE_SOCIAL_MEDIA_LINKS_SUCCESS",
  SAVE_SOCIAL_MEDIA_LINKS_FAILURE: "SAVE_SOCIAL_MEDIA_LINKS_FAILURE",
  SAVE_SOCIAL_MEDIA_LINKS_CLEAR: "SAVE_SOCIAL_MEDIA_LINKS_CLEAR",
};

export function aboutMeReducer(state = initialState, action) {
  switch (action.type) {
    case aboutMeActionTypes.SAVE_ABOUT_ME_REQUEST:
      return {
        ...state,
        saveAboutMeError: null,
        saveAboutMeStatus: aboutMeActionTypes.SAVE_ABOUT_ME_REQUEST,
      };
    case aboutMeActionTypes.SAVE_ABOUT_ME_FAILURE:
      return {
        ...state,
        saveAboutMeError: action.payload,
        saveAboutMeStatus: aboutMeActionTypes.SAVE_ABOUT_ME_FAILURE,
      };

    case aboutMeActionTypes.SAVE_ABOUT_ME_SUCCESS:
      return {
        ...state,
        saveAboutMeError: null,
        saveAboutMeStatus: aboutMeActionTypes.SAVE_ABOUT_ME_SUCCESS,
      };

    case aboutMeActionTypes.SAVE_ABOUT_ME_CLEAR:
      return {
        ...state,
        saveAboutMeError: null,
        saveAboutMeStatus: null,
      };

    case aboutMeActionTypes.SAVE_HEADLINE_REQUEST:
      return {
        ...state,
        saveHeadlineError: null,
        saveHeadlineStatus: aboutMeActionTypes.SAVE_HEADLINE_REQUEST,
      };
    case aboutMeActionTypes.SAVE_HEADLINE_FAILURE:
      return {
        ...state,
        saveHeadlineError: action.payload,
        saveHeadlineStatus: aboutMeActionTypes.SAVE_HEADLINE_FAILURE,
      };

    case aboutMeActionTypes.SAVE_HEADLINE_SUCCESS:
      return {
        ...state,
        saveHeadlineError: null,
        saveHeadlineStatus: aboutMeActionTypes.SAVE_HEADLINE_SUCCESS,
      };

    case aboutMeActionTypes.SAVE_HEADLINE_CLEAR:
      return {
        ...state,
        saveHeadlineError: null,
        saveHeadlineStatus: null,
      };

    case aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_REQUEST:
      return {
        ...state,
        saveSocialMediaLinksError: null,
        saveSocialMediaLinksStatus:
          aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_REQUEST,
      };
    case aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_FAILURE:
      return {
        ...state,
        saveSocialMediaLinksError: action.payload,
        saveSocialMediaLinksStatus:
          aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_FAILURE,
      };

    case aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_SUCCESS:
      return {
        ...state,
        saveSocialMediaLinksError: null,
        saveSocialMediaLinksStatus:
          aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_SUCCESS,
      };

    case aboutMeActionTypes.SAVE_SOCIAL_MEDIA_LINKS_CLEAR:
      return {
        ...state,
        saveSocialMediaLinksError: null,
        saveSocialMediaLinksStatus: null,
      };

    default:
      return state;
  }
}
