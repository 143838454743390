/*
 **	User name
 **	Created	7/11/2023
 **	order-saga.ts
 */

import { put, all, call, takeLatest, takeEvery } from "redux-saga/effects";
import { downloadURI } from "app/utils/helper";

import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { OrdersActions, OrdersActionsTypes } from "../reducers/order";
import { CartActions } from "../reducers/cart";

const version = process.env.REACT_APP_API_VERSION;
const version3 = process.env.REACT_APP_API_VERSION_3;

function* placeTheOrderFunRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.PLACE_THE_ORDER.replace("{version}", version),
    payload
  );

  return res;
}

function* placeTheOrderFun({ payload }: any) {
  try {
    yield put({
      type: OrdersActionsTypes.placeTheOrderRequest,
    });
    const response = yield call(placeTheOrderFunRequest, payload);

    yield put({
      type: OrdersActionsTypes.placeTheOrderSuccess,
      payload: response?.data,
    });
    payload?.callback?.();
    yield put(CartActions.fetchCart({ guid: payload.accountGuid }));
  } catch (e) {
    yield put({
      type: OrdersActionsTypes.placeTheOrderFailure,
      payload: e,
    });
  }
}

function* fetchPurchasedOrdersFunRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_PURCHASED_ORDERS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchPurchasedOrdersFun({ payload }: any) {
  try {
    yield put({
      type: OrdersActionsTypes.fetchPurchasedOrdersRequest,
    });
    const response = yield call(fetchPurchasedOrdersFunRequest, payload);

    yield put({
      type: OrdersActionsTypes.fetchPurchasedOrdersSuccess,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: OrdersActionsTypes.fetchPurchasedOrdersFailure,
      payload: e,
    });
  }
}

function* fetchSoldOrdersFunRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_SOLD_ORDERS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchSoldOrdersFun({ payload }: any) {
  try {
    yield put({
      type: OrdersActionsTypes.fetchSoldOrdersRequest,
    });
    const response = yield call(fetchSoldOrdersFunRequest, payload);

    yield put({
      type: OrdersActionsTypes.fetchSoldOrdersSuccess,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: OrdersActionsTypes.fetchSoldOrdersFailure,
      payload: e,
    });
  }
}

function* fetchMySubscriptionsFunRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_MY_SUBSCRIPTIONS.replace("{version}", version3),
    payload
  );

  return res;
}

function* fetchMySubscriptionsFun({ payload }: any) {
  try {
    yield put({
      type: OrdersActionsTypes.fetchMySubscriptionsRequest,
    });
    const response = yield call(fetchMySubscriptionsFunRequest, payload);

    yield put({
      type: OrdersActionsTypes.fetchMySubscriptionsSuccess,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: OrdersActionsTypes.fetchMySubscriptionsFailure,
      payload: e,
    });
  }
}

function* fetchOrderDetailsFunRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_ORDER_DETAILS.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchOrderDetailsFun({ payload }: any) {
  try {
    yield put({
      type: OrdersActionsTypes.fetchOrderDetailsRequest,
    });
    const response = yield call(fetchOrderDetailsFunRequest, payload);

    yield put({
      type: OrdersActionsTypes.fetchOrderDetailsSuccess,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: OrdersActionsTypes.fetchOrderDetailsFailure,
      payload: e,
    });
  }
}

function* refundOrderFunRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.REFUND_ORDER.replace("{version}", version),
    payload
  );

  return res;
}

function* refundOrderFun({ payload }: any) {
  try {
    yield put({
      type: OrdersActionsTypes.refundOrderRequest,
    });
    const response = yield call(refundOrderFunRequest, payload);

    yield put({
      type: OrdersActionsTypes.refundOrderSuccess,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: OrdersActionsTypes.refundOrderFailure,
      payload: e,
    });
  }

  yield put({
    type: OrdersActionsTypes.fetchOrderDetails,
    payload: {
      guid: payload.accountGuid,
      orderId: payload.productOrderId,
    },
  });
}

function* orderDownloadedFunRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.ORDER_DOWNLOADED.replace("{version}", version),
    payload
  );

  return res;
}

function* orderDownloadedFun({ payload }: any) {
  try {
    yield put({
      type: OrdersActionsTypes.orderDownloadedRequest,
    });
    const response = yield call(orderDownloadedFunRequest, payload);

    yield put({
      type: OrdersActionsTypes.orderDownloadedSuccess,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: OrdersActionsTypes.orderDownloadedFailure,
      payload: e,
    });
  }

  yield put(
    OrdersActions.fetchPurchasedOrders({
      guid: payload.accountGuid,
    })
  );

  yield put(
    OrdersActions.fetchSoldOrders({
      guid: payload.accountGuid,
    })
  );
}

function* orderDownloadInvoiceFunRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.ORDER_DOWNLOAD_INVOICE.replace("{version}", version),
    payload
  );

  return res;
}

function* orderDownloadInvoiceFun({ payload }: any) {
  try {
    yield put({
      type: OrdersActionsTypes.orderDownloadInvoiceRequest,
    });
    const response = yield call(orderDownloadInvoiceFunRequest, payload);

    yield put({
      type: OrdersActionsTypes.orderDownloadInvoiceSuccess,
      payload: response?.data,
    });

    if (response.data?.urlDetails) {
      downloadURI(response.data?.urlDetails, payload.orderId);
    }
  } catch (e) {
    yield put({
      type: OrdersActionsTypes.orderDownloadInvoiceFailure,
      payload: e,
    });
  }

  // yield put(
  //   OrdersActions.fetchPurchasedOrders({
  //     guid: payload.accountGuid,
  //   })
  // );

  // yield put(
  //   OrdersActions.fetchSoldOrders({
  //     guid: payload.accountGuid,
  //   })
  // );
}

function* combinedSaga() {
  yield all([
    takeLatest(OrdersActionsTypes.placeTheOrder, placeTheOrderFun),
    takeLatest(
      OrdersActionsTypes.fetchPurchasedOrders,
      fetchPurchasedOrdersFun
    ),
    takeLatest(OrdersActionsTypes.fetchSoldOrders, fetchSoldOrdersFun),
    takeLatest(
      OrdersActionsTypes.fetchMySubscriptions,
      fetchMySubscriptionsFun
    ),
    takeLatest(OrdersActionsTypes.fetchOrderDetails, fetchOrderDetailsFun),
    takeEvery(OrdersActionsTypes.refundOrder, refundOrderFun),
    takeEvery(OrdersActionsTypes.orderDownloaded, orderDownloadedFun),
    takeEvery(OrdersActionsTypes.orderDownloadInvoice, orderDownloadInvoiceFun),
  ]);
}

export default combinedSaga;
