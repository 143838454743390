import { createAction, createReducer } from "@reduxjs/toolkit";
import type { Action } from "@reduxjs/toolkit";

export const OrdersActionsTypes = {
  placeTheOrder: "order/create",
  placeTheOrderRequest: "order/create/request",
  placeTheOrderSuccess: "order/create/success",
  placeTheOrderFailure: "order/create/failure",

  fetchPurchasedOrders: "order/purchased",
  fetchPurchasedOrdersRequest: "order/purchased/request",
  fetchPurchasedOrdersSuccess: "order/purchased/success",
  fetchPurchasedOrdersFailure: "order/purchased/failure",

  fetchSoldOrders: "order/sold",
  fetchSoldOrdersRequest: "order/sold/request",
  fetchSoldOrdersSuccess: "order/sold/success",
  fetchSoldOrdersFailure: "order/sold/failure",

  fetchMySubscriptions: "subscriptions/my",
  fetchMySubscriptionsRequest: "subscriptions/my/request",
  fetchMySubscriptionsSuccess: "subscriptions/my/success",
  fetchMySubscriptionsFailure: "subscriptions/my/failure",

  fetchOrderDetails: "order/details",
  fetchOrderDetailsRequest: "order/details/request",
  fetchOrderDetailsSuccess: "order/details/success",
  fetchOrderDetailsFailure: "order/details/failure",

  refundOrder: "order/refund",
  refundOrderRequest: "order/refund/request",
  refundOrderSuccess: "order/refund/success",
  refundOrderFailure: "order/refund/failure",

  orderDownloaded: "order/downloaded",
  orderDownloadedRequest: "order/downloaded/request",
  orderDownloadedSuccess: "order/downloaded/success",
  orderDownloadedFailure: "order/downloaded/failure",

  orderDownloadInvoice: "order/invoice",
  orderDownloadInvoiceRequest: "order/invoice/request",
  orderDownloadInvoiceSuccess: "order/invoice/success",
  orderDownloadInvoiceFailure: "order/invoice/failure",
};

const placeTheOrder = createAction<{
  accountGuid: string;
  cartTotal: number;
  productInCartDetails: Array<{
    libraryProductId: number;
    productId: number;
    productPrice: number;
  }>;
  orderStatus: number;
  stripeData: string;
}>(OrdersActionsTypes.placeTheOrder);
const placeTheOrderRequest = createAction(
  OrdersActionsTypes.placeTheOrderRequest
);
const placeTheOrderSuccess = createAction<Array<any>>(
  OrdersActionsTypes.placeTheOrderSuccess
);
const placeTheOrderFailure = createAction<any>(
  OrdersActionsTypes.placeTheOrderFailure
);

const fetchPurchasedOrders = createAction<{
  guid: string;
}>(OrdersActionsTypes.fetchPurchasedOrders);
const fetchPurchasedOrdersRequest = createAction(
  OrdersActionsTypes.fetchPurchasedOrdersRequest
);
const fetchPurchasedOrdersSuccess = createAction<Array<any>>(
  OrdersActionsTypes.fetchPurchasedOrdersSuccess
);
const fetchPurchasedOrdersFailure = createAction<any>(
  OrdersActionsTypes.fetchPurchasedOrdersFailure
);

const fetchSoldOrders = createAction<{
  guid: string;
}>(OrdersActionsTypes.fetchSoldOrders);
const fetchSoldOrdersRequest = createAction(
  OrdersActionsTypes.fetchSoldOrdersRequest
);
const fetchSoldOrdersSuccess = createAction<Array<any>>(
  OrdersActionsTypes.fetchSoldOrdersSuccess
);
const fetchSoldOrdersFailure = createAction<any>(
  OrdersActionsTypes.fetchSoldOrdersFailure
);

const fetchMySubscriptions = createAction<{
  accountGuid: string;
}>(OrdersActionsTypes.fetchMySubscriptions);
const fetchMySubscriptionsRequest = createAction(
  OrdersActionsTypes.fetchMySubscriptionsRequest
);
const fetchMySubscriptionsSuccess = createAction<Array<any>>(
  OrdersActionsTypes.fetchMySubscriptionsSuccess
);
const fetchMySubscriptionsFailure = createAction<any>(
  OrdersActionsTypes.fetchMySubscriptionsFailure
);

const fetchOrderDetails = createAction<{
  guid: string;
  orderId: string;
}>(OrdersActionsTypes.fetchOrderDetails);
const fetchOrderDetailsRequest = createAction(
  OrdersActionsTypes.fetchOrderDetailsRequest
);
const fetchOrderDetailsSuccess = createAction<Array<any>>(
  OrdersActionsTypes.fetchOrderDetailsSuccess
);
const fetchOrderDetailsFailure = createAction<any>(
  OrdersActionsTypes.fetchOrderDetailsFailure
);

const orderDownloaded = createAction<{
  accountGuid: string;
  productId: number;
  productLibraryId: number;
  productOrderId: string;
  isDownloaded: boolean;
}>(OrdersActionsTypes.orderDownloaded);
const orderDownloadedRequest = createAction(
  OrdersActionsTypes.orderDownloadedRequest
);
const orderDownloadedSuccess = createAction<Array<any>>(
  OrdersActionsTypes.orderDownloadedSuccess
);
const orderDownloadedFailure = createAction<any>(
  OrdersActionsTypes.orderDownloadedFailure
);

const refundOrder = createAction<{
  accountGuid: string;
  productId: number;
  productLibraryId: number;
  productOrderId: string;
  isReturned: boolean;
}>(OrdersActionsTypes.refundOrder);
const refundOrderRequest = createAction(OrdersActionsTypes.refundOrderRequest);
const refundOrderSuccess = createAction<Array<any>>(
  OrdersActionsTypes.refundOrderSuccess
);
const refundOrderFailure = createAction<any>(
  OrdersActionsTypes.refundOrderFailure
);

const orderDownloadInvoice = createAction<{
  accountGuid: string;
  orderId: any[];
}>(OrdersActionsTypes.orderDownloadInvoice);
const orderDownloadInvoiceRequest = createAction(
  OrdersActionsTypes.orderDownloadInvoiceRequest
);
const orderDownloadInvoiceSuccess = createAction<Array<any>>(
  OrdersActionsTypes.orderDownloadInvoiceSuccess
);
const orderDownloadInvoiceFailure = createAction<any>(
  OrdersActionsTypes.orderDownloadInvoiceFailure
);

const initialState = {
  data: null,
  loading: true,
  pruchasedOrdersLoading: true,
  soldOrdersLoading: true,
  mySubscriptionsLoading: true,
  status: null,
  error: null,
  pruchasedOrders: null,
  soldOrders: null,
  mySubscriptions: null,
  orderDetails: null,
  orderDetailsLoading: true,
  refundOrderLoading: true,
  orderDownloadedLoading: true,
  orderDownloadInvoiceLoading: true,
};

const ordersReducer = createReducer(initialState, (builder) => {
  builder.addCase(placeTheOrderRequest, (state, action: Action) => ({
    ...state,
    loading: true,
    error: null,
  }));

  builder.addCase(placeTheOrderSuccess, (state, action: Action) => {
    if (placeTheOrderSuccess.match(action)) {
      return {
        ...state,
        data: action.payload as any,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(placeTheOrderFailure, (state, action: Action) => {
    if (placeTheOrderFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchPurchasedOrdersRequest, (state, action: Action) => ({
    ...state,
    pruchasedOrdersLoading: true,
    error: null,
  }));

  builder.addCase(fetchPurchasedOrdersSuccess, (state, action: Action) => {
    if (fetchPurchasedOrdersSuccess.match(action)) {
      return {
        ...state,
        pruchasedOrders: action.payload as any,
        pruchasedOrdersLoading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchPurchasedOrdersFailure, (state, action: Action) => {
    if (fetchPurchasedOrdersFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        pruchasedOrdersLoading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchSoldOrdersRequest, (state, action: Action) => ({
    ...state,
    soldOrdersLoading: true,
    error: null,
  }));

  builder.addCase(fetchSoldOrdersSuccess, (state, action: Action) => {
    if (fetchSoldOrdersSuccess.match(action)) {
      return {
        ...state,
        soldOrders: action.payload as any,
        soldOrdersLoading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchSoldOrdersFailure, (state, action: Action) => {
    if (fetchSoldOrdersFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        soldOrdersLoading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchMySubscriptionsRequest, (state, action: Action) => ({
    ...state,
    mySubscriptionsLoading: true,
    error: null,
  }));

  builder.addCase(fetchMySubscriptionsSuccess, (state, action: Action) => {
    if (fetchMySubscriptionsSuccess.match(action)) {
      return {
        ...state,
        mySubscriptions: action.payload as any,
        mySubscriptionsLoading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchMySubscriptionsFailure, (state, action: Action) => {
    if (fetchMySubscriptionsFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        mySubscriptionsLoading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchOrderDetailsRequest, (state, action: Action) => ({
    ...state,
    orderDetailsLoading: true,
    error: null,
  }));

  builder.addCase(fetchOrderDetailsSuccess, (state, action: Action) => {
    if (fetchOrderDetailsSuccess.match(action)) {
      return {
        ...state,
        orderDetails: action.payload as any,
        orderDetailsLoading: false,
      };
    }

    return state;
  });

  builder.addCase(fetchOrderDetailsFailure, (state, action: Action) => {
    if (fetchOrderDetailsFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        orderDetailsLoading: false,
      };
    }

    return state;
  });

  builder.addCase(refundOrderRequest, (state, action: Action) => ({
    ...state,
    refundOrderLoading: true,
    error: null,
  }));

  builder.addCase(refundOrderSuccess, (state, action: Action) => {
    if (refundOrderSuccess.match(action)) {
      return {
        ...state,
        refundOrderLoading: false,
      };
    }

    return state;
  });

  builder.addCase(refundOrderFailure, (state, action: Action) => {
    if (refundOrderFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        refundOrderLoading: false,
      };
    }

    return state;
  });

  builder.addCase(orderDownloadedRequest, (state, action: Action) => ({
    ...state,
    orderDownloadedLoading: true,
    error: null,
  }));

  builder.addCase(orderDownloadedSuccess, (state, action: Action) => {
    if (orderDownloadedSuccess.match(action)) {
      return {
        ...state,
        orderDownloadedLoading: false,
      };
    }

    return state;
  });

  builder.addCase(orderDownloadedFailure, (state, action: Action) => {
    if (orderDownloadedFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        orderDownloadedLoading: false,
      };
    }

    return state;
  });

  builder.addCase(orderDownloadInvoiceRequest, (state, action: Action) => ({
    ...state,
    orderDownloadInvoiceLoading: true,
    error: null,
  }));

  builder.addCase(orderDownloadInvoiceSuccess, (state, action: Action) => {
    if (orderDownloadInvoiceSuccess.match(action)) {
      return {
        ...state,
        orderDownloadInvoiceLoading: false,
      };
    }

    return state;
  });

  builder.addCase(orderDownloadInvoiceFailure, (state, action: Action) => {
    if (orderDownloadInvoiceFailure.match(action)) {
      return {
        ...state,
        error: action.payload,
        orderDownloadInvoiceLoading: false,
      };
    }

    return state;
  });
});

export const OrdersActions = {
  placeTheOrder,
  placeTheOrderRequest,
  placeTheOrderSuccess,
  placeTheOrderFailure,

  fetchPurchasedOrders,
  fetchPurchasedOrdersRequest,
  fetchPurchasedOrdersSuccess,
  fetchPurchasedOrdersFailure,

  fetchSoldOrders,
  fetchSoldOrdersRequest,
  fetchSoldOrdersSuccess,
  fetchSoldOrdersFailure,

  fetchMySubscriptions,
  fetchMySubscriptionsRequest,
  fetchMySubscriptionsSuccess,
  fetchMySubscriptionsFailure,

  fetchOrderDetails,
  fetchOrderDetailsRequest,
  fetchOrderDetailsSuccess,
  fetchOrderDetailsFailure,

  refundOrder,
  refundOrderRequest,
  refundOrderSuccess,
  refundOrderFailure,

  orderDownloaded,
  orderDownloadedRequest,
  orderDownloadedSuccess,
  orderDownloadedFailure,

  orderDownloadInvoice,
  orderDownloadInvoiceRequest,
  orderDownloadInvoiceSuccess,
  orderDownloadInvoiceFailure,
};

export default ordersReducer;
