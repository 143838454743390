import React from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import { CheckboxField } from "../UIFormFields";

const CatgeoryDropDown = ({ ids, selectedCategory, categoryMap, onClick }) => {
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={selectedCategory}
      onChange={(data) => {
        onClick(data);
      }}
      sx={{
        minWidth: 320,
        "@media (max-width:900px)": { minWidth: "inherit", width: "265px" },
      }}
    >
      {ids.map((id) => {
        return (
          <MenuItem key={id} value={id}>
            {categoryMap[id]}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export const CatgeoryDropDownMultiple = ({
  ids,
  selectedCategory,
  categoryMap,
  onClick,
}) => {
  return (
    <Select
      labelId="demo-simple-select-label"
      IconComponent={ExpandMoreRounded}
      id="demo-simple-select"
      value={selectedCategory}
      onChange={(data) => {
        onClick(data);
      }}
      sx={{
        minWidth: 320,
        "@media (max-width:900px)": { minWidth: "inherit", width: "265px" },
      }}
      multiple
      MenuProps={{
        PaperProps: {
          // sx: { maxHeight: 250, width: "100px" },
        },
        MenuListProps: { disablePadding: true },
      }}
      // fullWidth
      renderValue={(selected) => (
        <Box style={{ display: "flex" }}>
          {
            <Typography>
              {categoryMap[selected[0]]}
              &nbsp;
            </Typography>
          }
          {selected.length > 1 && (
            <Typography
              style={{
                color: "#7a48ff",
                fontSize: "15px",
                fontWeight: "bold",
              }}
            >
              +{selected?.length - 1}
            </Typography>
          )}
        </Box>
      )}
    >
      <MenuItem
        style={{
          textAlign: "right",
          color: "#7a48ff",
          fontWeight: "bold",
          cursor: "pointer",
          margin: "10px 0",
        }}
        value="Reset"
      >
        <Typography
          style={{
            textAlign: "right",
            color: "#7a48ff",
            fontWeight: "bold",
            cursor: "pointer",
            width: "100%",
          }}
        >
          Reset
        </Typography>
      </MenuItem>
      {ids.map((id) => {
        return (
          <MenuItem
            key={id}
            value={id}
            sx={{
              borderBottom: 1.9,
              borderColor: "#d7d0e8",
              padding: "18px 0 16px 10px",
            }}
          >
            <CheckboxField
              style={{ padding: "0px 16px 0px 10px" }}
              checked={selectedCategory?.includes(id)}
            />
            {categoryMap[id]}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default CatgeoryDropDown;
