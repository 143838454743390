/*
 **	User name
 **	Created	5/27/2023
 **	NewsLetterStyle.ts
 */

import { makeStyles } from "@mui/styles";

const useNewsLetterStyles = makeStyles((theme) => ({
  modal: {
    width: 680,
    // minWidth: 480,
    height: "600px",
    [theme.breakpoints.down("lg")]: {
      width: 620,
      height: "500px",
    },
    [theme.breakpoints.down("md")]: {
      width: "90vw !important",
      height: "440px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 430,
      height: "405px",
    },
  },

  headerStyle: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    borderTopLeftRadius: "17px",
    borderTopRightRadius: "17px",
    height: "254px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: "linear-gradient(75deg, #f54d64 0%, #f58561 99%)",
    [theme.breakpoints.down("lg")]: {
      height: "224px",
    },
    [theme.breakpoints.down("md")]: {
      height: "194px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "164px",
    },
  },
  headerIcon: {
    padding: "34px",
    width: "100%",
    height: "100%",
  },
  closeIconStyle: {
    position: "absolute",
    right: 20,
    top: 20,
    cursor: "pointer",
  },
  icon: {
    width: "75px",
    height: "75px",
    [theme.breakpoints.down("lg")]: {
      width: "65px",
      height: "65px",
    },
    [theme.breakpoints.down("md")]: {
      width: "55px",
      height: "55px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50px",
      height: "50px",
    },
  },
  iconBox: {
    paddingTop: "30px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "20px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10px",
    },
  },
  titleBox: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "20px",
    paddingBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.down("md")]: {
      // paddingLeft: "10px",
      // paddingRight: "10px",
      // paddingTop: "5px",
      // paddingBottom: "5px",
      padding: "5px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "20px",
      paddingRight: "20px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
  subtitleBox: {
    paddingLeft: "100px",
    paddingRight: "100px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "70px",
      paddingRight: "70px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "30px",
      paddingRight: "30px",
    },
  },
  title: {
    paddingLeft: "80px",
    paddingRight: "80px",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      fontSize: "20px !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
  },
  subtitle: {
    padding: "0 120px 0 120px",
    height: "46px",
    fontSize: 17,
    [theme.breakpoints.down("lg")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px !important",
      padding: "0 20px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 13,
    },
  },
}));

const useEmailModelStyles = makeStyles((theme) => ({
  modelStyle: {
    width: 680,
    minWidth: 480,
    height: "600px",
    [theme.breakpoints.down("lg")]: {
      width: 620,
      height: "550px",
    },
    [theme.breakpoints.down("md")]: {
      width: 560,
      height: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 430,
      height: "435px",
    },
  },

  headerStyle: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    borderTopLeftRadius: "17px",
    borderTopRightRadius: "17px",
    height: "254px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: "linear-gradient(75deg, #f54d64 0%, #f58561 99%)",
    [theme.breakpoints.down("lg")]: {
      height: "224px",
    },
    [theme.breakpoints.down("md")]: {
      height: "194px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "164px",
    },
  },
  closeIconStyle: {
    position: "absolute",
    right: 20,
    top: 20,
    cursor: "pointer",
  },
  headerIcon: {
    padding: "30px",
    width: "42%",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      padding: "25px",
      width: "40%",
      height: "95%",
    },
    [theme.breakpoints.down("md")]: {
      padding: "25px",
      width: "40%",
      height: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "25px",
      width: "40%",
      height: "100%",
    },
  },
  textFieldBox: {
    paddingLeft: "20%",
    paddingRight: "20%",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "20%",
      paddingRight: "20%",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "20%",
      paddingRight: "20%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16%",
      paddingRight: "16%",
    },
  },
  textField: {
    width: "100%",
    marginTop: "10%",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      marginTop: "10%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "10%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "5%",
    },
  },
  groundBox: {
    paddingTop: "30px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "15px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "15px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
    },
  },
  titleBox: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "20px",
    paddingBottom: "20px",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10px",
      paddingRight: "10px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  buttonBox: {
    paddingTop: "30px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "25px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "25px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "16px",
    },
  },
}));

const useSubscribeModelStyles = makeStyles((theme) => ({
  modelStyle: {
    width: 710,
    minWidth: 480,
    height: "630px",
    [theme.breakpoints.down("lg")]: {
      width: 650,
      height: "610px",
    },
    [theme.breakpoints.down("md")]: {
      width: 600,
      height: "570px",
    },
    [theme.breakpoints.down("sm")]: {
      width: 500,
      height: "510px",
    },
  },

  headerStyle: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    borderTopLeftRadius: "17px",
    borderTopRightRadius: "17px",
    height: "300px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    background: "linear-gradient(75deg, #f54d64 0%, #f58561 99%)",
    [theme.breakpoints.down("lg")]: {
      height: "290px",
    },
    [theme.breakpoints.down("md")]: {
      height: "254px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "230px",
    },
  },
  closeIconStyle: {
    position: "absolute",
    right: 20,
    top: 20,
    cursor: "pointer",
  },
  headerIcon: {
    padding: "15px",
    width: "100%",
    height: "63%",
    [theme.breakpoints.down("lg")]: {
      padding: "15px",
      width: "100%",
      height: "63%",
    },
    [theme.breakpoints.down("md")]: {
      padding: "10px",
      width: "100%",
      height: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px",
      width: "100%",
      height: "50%",
    },
  },
  headerBox: {
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
    [theme.breakpoints.down("lg")]: {
      marginTop: 10,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 5,
    },
  },
  bottomBox: {
    paddingTop: "10px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "5px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
    },
  },
  bottomBox1: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "10px",
    paddingBottom: "10px",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  },
  descriptionBox: {
    paddingTop: "20px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10px",
    },
  },
  buttonBox: {
    paddingTop: "22px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "22px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "18px",
    },
  },
  Typography: {
    fontSize: 17,
    [theme.breakpoints.down("lg")]: {
      fontSize: 17,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  TypographyLink: {
    fontSize: 17,
    [theme.breakpoints.down("lg")]: {
      fontSize: 17,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
  },
  groundBox: {
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingTop: "15px",
    paddingBottom: "15px",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "15px",
      paddingBottom: "15px",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "40px",
      paddingRight: "40px",
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  linkBox: {
    paddingTop: "22px",
    [theme.breakpoints.down("lg")]: {
      paddingTop: "22px",
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: "18px",
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15px",
    },
  },
}));

const useEmailConfirmationStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "560px",
    height: "412px",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
  },
  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    padding: "24px 0px 34px 0px",
  },
  emailLogoBox: {
    padding: "54px 0px 34px 0px",
  },
  subtitleBox: {
    color: "#fff",
    padding: "0px 130px 0px 130px",
  },
  buttonChange: {
    paddingTop: 15,
    paddingLeft: 16,
    paddingRight: 16,
    paddingBottom: 15,
    fontSize: 15,
    textTransform: "none",
    minHeight: 54,
  },
  buttonVerify: {
    margin: 23,
  },
}));

export {
  useNewsLetterStyles,
  useEmailModelStyles,
  useSubscribeModelStyles,
  useEmailConfirmationStyles,
};
