import { userRegisterReducerTypes } from "./userReducer";

const initialState = {
  ccLoading: false,
  ccError: null,
  ccData: null,
  ccStatus: null,
  ccUpdateLoading: false,
  ccUpdateError: null,
  ccUpdateStatus: null,

  paypalLoading: false,
  paypalError: null,
  paypalData: null,
  paypalStatus: null,
  paypalUpdateLoading: false,
  paypalUpdateError: null,
  paypalUpdateStatus: null,
};

export const paymentsActionTypes = {
  FETCH_CC: "FETCH_CC",
  FETCH_CC_REQUEST: "FETCH_CC_REQUEST",
  FETCH_CC_SUCCESS: "FETCH_CC_SUCCESS",
  FETCH_CC_FAILURE: "FETCH_CC_FAILURE",

  FETCH_PAYPAL_ACCOUNT: "FETCH_PAYPAL_ACCOUNT",
  FETCH_PAYPAL_ACCOUNT_REQUEST: "FETCH_PAYPAL_ACCOUNT_REQUEST",
  FETCH_PAYPAL_ACCOUNT_SUCCESS: "FETCH_PAYPAL_ACCOUNT_SUCCESS",
  FETCH_PAYPAL_ACCOUNT_FAILURE: "FETCH_PAYPAL_ACCOUNT_FAILURE",

  UPDATE_CC: "UPDATE_CC",
  UPDATE_CC_REQUEST: "UPDATE_CC_REQUEST",
  UPDATE_CC_SUCCESS: "UPDATE_CC_SUCCESS",
  UPDATE_CC_FAILURE: "UPDATE_CC_FAILURE",

  UPDATE_PAYPAL_ACCOUNT: "UPDATE_PAYPAL_ACCOUNT",
  UPDATE_PAYPAL_ACCOUNT_REQUEST: "UPDATE_PAYPAL_ACCOUNT_REQUEST",
  UPDATE_PAYPAL_ACCOUNT_SUCCESS: "UPDATE_PAYPAL_ACCOUNT_SUCCESS",
  UPDATE_PAYPAL_ACCOUNT_FAILURE: "UPDATE_PAYPAL_ACCOUNT_FAILURE",
};

function reducerFunc(state = initialState, action) {
  switch (action.type) {
    case paymentsActionTypes.FETCH_CC_REQUEST:
      return {
        ...state,
        ccError: null,
        ccStatus: paymentsActionTypes.FETCH_CC_REQUEST,
        ccUpdateStatus: null,
      };
    case paymentsActionTypes.FETCH_CC_FAILURE:
      return {
        ...state,
        ccError: action.payload,
        ccStatus: paymentsActionTypes.FETCH_CC_FAILURE,
      };
    case paymentsActionTypes.FETCH_CC_SUCCESS:
      return {
        ...state,
        ccData: action.payload,
        ccStatus: paymentsActionTypes.FETCH_CC_SUCCESS,
      };

    case paymentsActionTypes.FETCH_PAYPAL_ACCOUNT_REQUEST:
      return {
        ...state,
        paypalError: null,
        paypalStatus: paymentsActionTypes.FETCH_PAYPAL_ACCOUNT_REQUEST,
        paypalUpdateStatus: null,
      };
    case paymentsActionTypes.FETCH_PAYPAL_ACCOUNT_FAILURE:
      return {
        ...state,
        paypalError: action.payload,
        paypalStatus: paymentsActionTypes.FETCH_PAYPAL_ACCOUNT_FAILURE,
      };
    case paymentsActionTypes.FETCH_PAYPAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        paypalData: action.payload,
        paypalStatus: paymentsActionTypes.FETCH_PAYPAL_ACCOUNT_SUCCESS,
      };

    case paymentsActionTypes.UPDATE_CC_REQUEST:
      return {
        ...state,
        ccUpdateError: null,
        ccUpdateStatus: paymentsActionTypes.UPDATE_CC_REQUEST,
      };
    case paymentsActionTypes.UPDATE_CC_FAILURE:
      return {
        ...state,
        ccUpdateError: action.payload,
        ccUpdateStatus: paymentsActionTypes.UPDATE_CC_FAILURE,
      };
    case paymentsActionTypes.UPDATE_CC_SUCCESS:
      return {
        ...state,
        ccUpdateStatus: paymentsActionTypes.UPDATE_CC_SUCCESS,
      };

    case paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_REQUEST:
      return {
        ...state,
        paypalUpdateError: null,
        paypalUpdateStatus: paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_REQUEST,
      };
    case paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_FAILURE:
      return {
        ...state,
        paypalUpdateError: action.payload,
        paypalUpdateStatus: paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_FAILURE,
      };
    case paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_SUCCESS:
      return {
        ...state,
        paypalUpdateStatus: paymentsActionTypes.UPDATE_PAYPAL_ACCOUNT_SUCCESS,
      };

    case userRegisterReducerTypes.LOGOUT_USER:
      return { ...initialState };

    default:
      return state;
  }
}

export default reducerFunc;
