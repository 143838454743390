/*
 **	User name
 **	Created	5/26/2023
 **	LibraryAdditionalInfo.tsx
 */

import React, { useContext, useEffect, useState } from "react";
import {
  // Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/system";
import CurrencyFlag from "react-currency-flags";
import { makeStyles } from "@mui/styles";
import { ExpandMoreRounded } from "@mui/icons-material";
import { IRootState } from "app/redux/reducers";
import { useAppSelector } from "app/utils/hooks";
import { PurchaseContext } from "context/PurchaseContext";
import { AppPermissionsContext } from "context/AppPermissionsContext";
import { LibraryProductUploadContext } from "context/LibraryProductUploadContext";
import { useDispatch } from "react-redux";
import { libraryActionTypes } from "app/redux/reducers/library";
import Swal from "sweetalert2";
import { isIndividualSelector } from "app/redux/selectors/user-selector";

import { designerProductsReducerTypes } from "app/redux/reducers/productReducer/product-main";
import { BlackBorderedButton } from "../../UIFormFields/Buttons";
import { LightModeCross, NightModeCross } from "../../Logo/logos";
import { PurpleButton } from "../../UIFormFields";
import LibraryProductPreview from "./LibraryProductPreview";

export const useUploadModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "800px !important",
    minHeight: "560px !important",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
  },
  header: {
    fontSize: "27px !important",
    fontWeight: "bold !important",
    backgroundImage:
      " linear-gradient(78deg, #f54d64 0%, #f58561 100%) !important",
    WebkitBackgroundClip: "text ",
    WebkitTextFillColor: "transparent ",
    textAlign: "center",
    marginBottom: "20px !important",
  },
  draft: {
    fontSize: "15px !important",
    color: "#a49bbe !important",
    fontWeight: "bold !important",
  },
  button: {
    width: "69px !important",
    margin: "24px 12px 24px 12px !important",
  },
  textBox: {
    textAlign: "center",
    position: "relative",
    top: "50px",
  },
  leftSection: {
    color: "#a49bbe",
    fontWeight: "bold !important",
    marginRight: "68px !important",
    width: "60px !important",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "7.5px",
  },
  inactiveButton: {
    width: "131px",
    height: "54px",
    color: "black !important",
    border: "black",
  },
  activeButton: {
    width: "131px",
    height: "54px",
    color: "white !important",
    backgroundColor: "black !important",
  },
  buttonBox: { display: "flex", justifyContent: "center" },
  uploadbutton: {
    width: "160px",
    padding: "10px 16px",
    textAlign: "left",
  },
  infoBox: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "6px",
  },
  info: {
    color: "#a49bbe",
    fontFamily: "Poppins !important",
    fontSize: "13px !important",
    fontWeight: "bold !important",
  },
}));

export default function LibraryAdditionalInfo({
  handleClick,
  handleClose,
  withoutModal = false,
}) {
  const { permissions } = useContext(AppPermissionsContext);
  const {
    productInfo,
    setProductInfo,
    saveLibraryProductAsDraft,
    publishLibraryProduct,
    clearLibraryUpload,
  } = useContext(LibraryProductUploadContext);
  const { createStripeConnectAccount } = useContext(PurchaseContext);
  const classes = useUploadModalStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const userData = useAppSelector((state: IRootState) => state.register);
  const libraryData = useAppSelector((state) => state.library);
  const isIndividual = useAppSelector(isIndividualSelector);
  const [libraryProductPreview, setLibraryProductPreview] =
    React.useState(false);

  useEffect(() => {
    dispatch({ type: libraryActionTypes.CLEAR_LIBRARY_UPLOAD, payload: {} });
  }, []);

  const accountGuid = userData?.userAdditionalInfo?.accountGUID;

  useEffect(() => {
    if (libraryData?.data?.responseCode === 200) {
      handleClose?.();
      Swal.fire({
        icon: "success",
        title: "Congratulations...",
        text: `${libraryData?.data.responseMessage}`,
        allowOutsideClick: false,
      }).then(() => {
        dispatch({
          type: libraryActionTypes.CLEAR_LIBRARY_UPLOAD,
          payload: {},
        });
      });
      clearLibraryUpload();
    }
  }, [libraryData?.data?.responseCode]);

  const currencyArray = [
    { name: "USD", display: "USD-US Dollar", id: 1 },
    { name: "EUR", display: "Euro-Euro", id: 2 },
  ];

  const [temp, setTemp] = useState(false);

  const handleChange = (e) => {
    // setProductInfo({ ...productInfo, [e.target.name]: e.target.value });

    if (e.target.value === "Private" || e.target.value === "Team") {
      setTemp(true);
      setProductInfo({
        ...productInfo,
        downloadType: "1",
        [e.target.name]: e.target.value,
      });
    } else {
      setTemp(false);
      setProductInfo({
        ...productInfo,

        [e.target.name]: e.target.value,
      });
    }
  };

  const profileType = userData?.userAdditionalInfo?.profileType;
  const roleId = userData?.userAdditionalInfo?.companyEmployeeRoleId;

  const manageDownloadType = (roleId) => {
    return roleId === 3 ? "Private" : roleId === 4 ? "Team" : "Public";
  };

  useEffect(() => {
    setProductInfo({
      ...productInfo,
      visibilityId: productInfo?.visibilityId || manageDownloadType(roleId),
    });
  }, []);

  const {
    ReactComponent: SearchIcon,
  } = require("../../../../assets/icons/rightSearch_Icon.svg");

  const handleDownload = (e) => {
    e.target.value == 1
      ? setProductInfo({
          ...productInfo,
          downloadType: e.target.value,
          price: "",
        })
      : setProductInfo({
          ...productInfo,
          downloadType: e.target.value,
        });
  };

  const handlePreviewClick = (click) => {
    click == "preview" && setLibraryProductPreview(true);
  };

  const isRegisteredWithStripe =
    userData?.userAdditionalInfo?.isRegisteredWithStripe;

  const stripeId = userData?.userAdditionalInfo?.stripeId;
  const stripeRegistered = Boolean(isRegisteredWithStripe && stripeId);

  const publishButton = (() => {
    // const status =
    //   ["", "0"].includes(productInfo?.price) ||
    //   productInfo?.downloadType === "1"
    //     ? false
    //     : !stripeRegistered;
    const status =
      [2, "2"].includes(productInfo?.downloadType) &&
      !["", "0", 0].includes(productInfo?.price) &&
      !isRegisteredWithStripe
        ? false
        : true;

    return status;
  })();

  const handleStripeConnect = async () => {
    const accountLink = await createStripeConnectAccount({
      email: userData?.userAdditionalInfo?.emailAddress,
      accountId: stripeId || null,
      data: {
        // productId: productDetails?.productID,
        isLibraryProduct: false,
        productLibraryId: 0,
      },
    });

    if (accountLink?.url) {
      window.location.href = accountLink.url;
    }
  };

  const handleRedirectTodraft = async () => {
    await handleStripeConnect();
    handleSaveToDraft();
  };

  const handlePublishProduct = async () => {
    await publishLibraryProduct();

    await setTimeout(() => {
      dispatch({
        type: designerProductsReducerTypes.DESIGNER_PRODUCT,
        payload: {
          accountGuid,
          loggedInGuid: accountGuid,
          isLibraryProduct: true,
          pageNumber: 1,
          pageSize: 500,
          visibilityId: 0,
        },
      });
    }, 1000);

    await setTimeout(() => window.location.reload(), 1200);
  };

  const handleSaveToDraft = () => {
    saveLibraryProductAsDraft();
  };

  const publishState = libraryData?.loading;
  const companyEmployeeRoleId =
    userData?.userAdditionalInfo?.companyEmployeeRoleId;
  // const visibiityArray = isIndividual
  //   ? ["Public", "Private"]
  //   : permissions?.productSettings?.uploadPublic
  //   ? companyEmployeeRoleId === 3
  //     ? ["Public", "Private"]
  //     : ["Public", "Private", "Team"]
  //   : companyEmployeeRoleId === 3
  //   ? ["", "Private", ""]
  //   : ["", "Private", "Team"];

  const visibiityArray = isIndividual
    ? ["Public", "Private"]
    : [
        permissions?.productSettings?.uploadPublic ||
        companyEmployeeRoleId === 1
          ? "Public"
          : "",
        "Private",
        companyEmployeeRoleId === 3 ? "" : "Team",
      ];

  const BodyData = () => {
    return (
      <>
        {/* Details Box */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {/* Visibility  */}
          <Box className={classes.row}>
            <Typography className={classes.leftSection}>Visibility*</Typography>

            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              // name="controlled-radio-buttons-group"
              name="visibilityId"
              // type="number"
              onChange={(e) => handleChange(e)}
              value={productInfo?.visibilityId}
              // defaultValue="Public"
            >
              <Box sx={{ width: "263px", display: "flex" }}>
                {visibiityArray.map(
                  (item) =>
                    item && (
                      <FormControlLabel
                        sx={{ color: "#2d2d2d" }}
                        value={item}
                        control={
                          <Radio
                            sx={{
                              color: "#a49bbe",
                              "&.Mui-checked": {
                                color: "#7a48ff",
                              },
                            }}
                          />
                        }
                        label={item}
                        key={item}
                      />
                    )
                )}
              </Box>
            </RadioGroup>
          </Box>

          {/* Download} */}
          <Box className={classes.row}>
            <Typography className={classes.leftSection}>Download*</Typography>

            {userData?.userAdditionalInfo.companyEmployeeRoleId === 3 ? (
              <ButtonGroup variant="outlined" onClick={handleDownload}>
                <Button
                  className={
                    ["1", 1]?.includes(productInfo?.downloadType)
                      ? classes.activeButton
                      : classes.inactiveButton
                  }
                  value="1"
                >
                  Free
                </Button>
                <Button
                  style={{ backgroundColor: "#e5e5e5", opacity: "30%" }}
                  className={
                    !["1", 1]?.includes(productInfo?.downloadType)
                      ? classes.activeButton
                      : classes.inactiveButton
                  }
                  value="1"
                  // disabled
                >
                  Paid
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup variant="outlined" onClick={handleDownload}>
                <Button
                  className={
                    ["1", 1]?.includes(productInfo?.downloadType)
                      ? classes.activeButton
                      : classes.inactiveButton
                  }
                  value="1"
                >
                  Free
                </Button>
                <Button
                  style={{
                    backgroundColor: temp === true && "#D3D3D3",
                    opacity: temp === true && "30%",
                  }}
                  className={
                    !["1", 1]?.includes(productInfo?.downloadType)
                      ? classes.activeButton
                      : classes.inactiveButton
                  }
                  value={temp === true ? "1" : "2"}
                >
                  Paid
                </Button>
              </ButtonGroup>
            )}
          </Box>

          {/* License */}
          <Box className={classes.row}>
            <Typography className={classes.leftSection}>License*</Typography>
            <Box style={{ display: "flex", flexDirection: "column" }}>
              <FormControl disabled>
                <InputLabel id="demo-simple-select-disabled-label">
                  Standard
                </InputLabel>
                <Select
                  SelectDisplayProps={{
                    style: { padding: "14px 14px", height: "26px" },
                  }}
                  style={{ width: "263px" }}
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  IconComponent={ExpandMoreRounded}
                >
                  <MenuItem>Standard</MenuItem>
                </Select>
              </FormControl>

              <Typography
                style={{
                  color: "#7a48ff",
                  fontSize: "13px",
                  fontWeight: "bold",
                  textAlign: "end",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("/terms-and-conditions", "_blank");
                  window.sessionStorage.setItem("isLicenseView", "true");
                }}
              >
                View Licenses
              </Typography>
            </Box>
          </Box>

          {["2", 2]?.includes(productInfo?.downloadType) && (
            <>
              <Box className={classes.row}>
                <Typography className={classes.leftSection}>
                  Currency*
                </Typography>
                <FormControl>
                  <Select
                    SelectDisplayProps={{
                      style: { padding: "14px 14px", height: "26px" },
                    }}
                    style={{ width: "263px" }}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    onChange={(e) => handleChange(e)}
                    IconComponent={ExpandMoreRounded}
                    value={productInfo?.currencyId || "1"}
                    name="currencyId"
                  >
                    {currencyArray.map((item) => {
                      return (
                        <MenuItem
                          key={item?.id}
                          value={item?.id}
                          sx={{
                            "&.MuiMenuItem-root": {
                              padding: "12px",
                              borderBottom: 1,
                              borderColor: "#ebe3ff",
                            },

                            "&.Mui-selected": {
                              color:
                                theme?.palette?.mode === "light"
                                  ? "#7a48ff"
                                  : " #fff",
                              backgroundColor:
                                theme?.palette?.mode === "light"
                                  ? "#fff"
                                  : "black",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "left",
                              alignItems: "center",
                            }}
                          >
                            <CurrencyFlag currency={item.name} />
                            <Typography marginLeft={"8px"}>
                              {item.display}
                            </Typography>
                          </Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Box>

              {/* Amount */}
              <Box className={classes.row}>
                <Typography className={classes.leftSection}>Amount*</Typography>

                <TextField
                  style={{ width: "263px" }}
                  onChange={handleChange}
                  value={productInfo?.price}
                  name="price"
                  type="number"
                  label="Enter Amount*"
                  variant="outlined"
                  InputProps={{
                    inputProps: { min: 0, max: "" },
                  }}
                  autoFocus
                />
              </Box>
            </>
          )}
        </Box>
        {!["", "0"].includes(productInfo?.price) &&
          productInfo?.downloadType === "2" && (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "13px",
                  color: "#a49bbe",
                  marginTop: "30px",
                  textAlign: "justify",
                  width: "70%",
                }}
              >
                Note: The service provider will charge fees for the transaction.
                The amount received will therefore differ from your selected
                price by these fees.
              </Typography>
            </Box>
          )}

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 40px",
          }}
        >
          {/* commenting for sometime */}
          {/* <Box style={{ maxWidth: 500 }}>
            {libraryData?.data?.responseCode &&
              libraryData?.data?.responseCode !== 200 && (
                <Alert severity="error">
                  {libraryData?.data?.responseMessage}
                </Alert>
              )}

            {libraryData?.error && (
              <Alert severity="error">{libraryData?.error}</Alert>
            )}
          </Box> */}
        </Box>

        {/* Buttons */}
        {!publishButton && (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "13px",
                color: "#a49bbe",
                marginTop: "30px",
                textAlign: "justify",
                width: "70%",
              }}
            >
              Note: To proceed with the sales you need to connect with the
              Stripe account. As a result, your product will be saved to drafts.
              After successful stripe integration you will be able to publish
              your saved draft.
            </Typography>
          </Box>
        )}

        <Box className={classes.buttonBox} style={{ width: "100%" }}>
          <BlackBorderedButton
            onClick={() => {
              handleClick("backAddInfo");
            }}
            className={classes.button}
          >
            Back
          </BlackBorderedButton>

          {!publishButton ? (
            <PurpleButton
              onClick={handleRedirectTodraft}
              className={classes.button}
              style={{ minWidth: "300px" }}
            >
              {publishState ? (
                <i className="fa-solid fa-spinner fa-spin"></i>
              ) : (
                "Connect to Stripe"
              )}
            </PurpleButton>
          ) : (
            <PurpleButton
              onClick={publishState ? () => {} : handlePublishProduct}
              className={classes.button}
            >
              {publishState ? (
                <i className="fa-solid fa-spinner fa-spin"></i>
              ) : (
                "Publish"
              )}
            </PurpleButton>
          )}
        </Box>
        {withoutModal ? (
          <Box
            style={{
              width: "32%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                onClick={handleSaveToDraft}
                className={classes.draft}
                style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  cursor: "pointer",
                }}
              >
                Save to draft
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.draft}
                sx={{
                  textAlign: "end",
                  // marginRight: "10px",
                  position: "absolute",
                  // bottom: "25px",
                  cursor: "pointer",
                  right: 0,
                  marginRight: "20px",
                }}
                onClick={() => handlePreviewClick("preview")}
              >
                <SearchIcon style={{ marginRight: "10px" }} />
                Library Product Preview
              </Typography>
            </Box>
          </Box>
        ) : (
          <>
            <Typography
              onClick={handleSaveToDraft}
              className={classes.draft}
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                cursor: "pointer",
              }}
            >
              Save to draft
            </Typography>
            <Typography
              className={classes.draft}
              sx={{
                textAlign: "end",
                marginRight: "10px",
                position: "absolute",
                // bottom: "25px",
                cursor: "pointer",
                right: 0,
              }}
              onClick={() => handlePreviewClick("preview")}
            >
              <SearchIcon style={{ marginRight: "10px" }} />
              Library Product Preview
            </Typography>
          </>
        )}
      </>
    );
  };

  if (withoutModal) {
    return libraryProductPreview ? (
      <LibraryProductPreview
        handleClose={() => setLibraryProductPreview(false)}
      />
    ) : (
      <Box
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
          padding: 60,
        }}
      >
        <BodyData />
      </Box>
    );
  }

  return (
    <>
      {libraryProductPreview ? (
        <LibraryProductPreview
          handleClose={() => setLibraryProductPreview(false)}
        />
      ) : (
        <Modal open={true}>
          <Box
            className={classes.modalStyle}
            style={{
              backgroundColor: theme?.palette?.mode === "light" && "#fff",
            }}
          >
            <Box>
              <Box className={classes.closeBox}>
                {theme?.palette?.mode === "light" ? (
                  <LightModeCross
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                ) : (
                  <NightModeCross
                    onClick={handleClose}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </Box>
            </Box>
            <Box className={classes.textBox}>
              <Typography className={classes.header}>
                Additional Information
              </Typography>

              <BodyData />
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
}
