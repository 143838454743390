const productDetailsNavInitialState = {
  productInfo: true,
  _3d: false,
  pattern: false,
  art: false,
  image: false,
  sketch: false,
  others: false,
};

export const productDetailsNavBarType = {
  PRODUCT_INFO: "PRODUCT_INFO",
  _3D: "_3D",
  PATTERN: "PATTERN",
  ART_FILE: "ART_FILE",
  IMAGE_FILE: "IMAGE_FILE",
  SKETCH_FILE: "SKETCH_FILE",
  OTHER_FILE: "OTHER_FILE",
};

export function productDetailsNavBarReducer(
  state = productDetailsNavInitialState,
  action
) {
  switch (action.type) {
    case productDetailsNavBarType.PRODUCT_INFO:
      return {
        productInfo: true,
        _3d: false,
        pattern: false,
        art: false,
        image: false,
        sketch: false,
        others: false,
      };
    case productDetailsNavBarType._3D:
      return {
        productInfo: false,
        _3d: true,
        pattern: false,
        art: false,
        image: false,
        sketch: false,
        others: false,
      };
    case productDetailsNavBarType.PATTERN:
      return {
        productInfo: false,
        _3d: false,
        pattern: true,
        art: false,
        image: false,
        sketch: false,
        others: false,
      };
    case productDetailsNavBarType.ART_FILE:
      return {
        productInfo: false,
        _3d: false,
        pattern: false,
        art: true,
        image: false,
        sketch: false,
        others: false,
      };
    case productDetailsNavBarType.IMAGE_FILE:
      return {
        productInfo: false,
        _3d: false,
        pattern: false,
        art: false,
        image: true,
        sketch: false,
        others: false,
      };
    case productDetailsNavBarType.SKETCH_FILE:
      return {
        productInfo: false,
        _3d: false,
        pattern: false,
        art: false,
        image: false,
        sketch: true,
        others: false,
      };
    case productDetailsNavBarType.OTHER_FILE:
      return {
        productInfo: false,
        _3d: false,
        pattern: false,
        art: false,
        image: false,
        sketch: false,
        others: true,
      };
    default:
      return state;
  }
}
