import { makeStyles } from "@mui/styles";

const newLicenseStyle = makeStyles((theme) => ({
  mainContainer: {
    width: "100%",
    padding: 0,
    backgroundColor: "transparent !important" as any,
    flexDirection: "column",
  },
  maincontainer: {
    width: "100%",
    borderRadius: "20px",
    marginTop: "34px",
    WebkitBackdropFilter: "blur(60px)",
    backdropFilter: "blur(60px)",
    border: "solid 1px white",
    backgroundImage:
      "linear-gradient(135deg, rgba(255, 255, 255, 0.76) -39%, rgba(255, 255, 255, 0) 102%)",
  },
  cardGridContainer: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "255px !important" as any,
  },
  cardGrid: {
    minHeight: "365px !important" as any,
    padding: "10px",
    textAlign: "center !important" as any,
    position: "relative",
    backgroundColor: "transparent !important" as any,
    "&:hover": {
      boxShadow: "0 18px 34px 0 rgba(43, 38, 57, 0.2)",
    },
    // "&:nth-child(1)": {
    //   borderRadius: "0px 20px 20px 0px",
    // },
  },

  cardGridV2: {
    width: 299,
    padding: "24px 0 0",
    objectFit: "contain",
    WebkitBackdropFilter: "blur(60px)",
    border: "0px solid #fff",
    backgroundImage:
      "linear-gradient(148deg, rgba(255, 255, 255, 0.76) -6%, rgba(255, 255, 255, 0) 113%)",
  },
  textColor: {
    color: theme?.palette?.mode === "light" ? "#2b2639" : "#FFFFFF",
  },
  headerDivider: {
    width: 62,
    height: 2,
    backgroundColor: theme?.palette?.mode === "light" ? "#2b2639" : "#FFFFFF",
    marginTop: 16,
  },
  priceContainer: {
    fontSize: "31px !important" as any,
    // fontWeight: "bold !important" as any,
    marginTop: "16px !important" as any,
  },
  btnCTA: {
    backgroundImage: "linear-gradient(67deg, #f54d64 0%, #f58561 99%)",
    color: `${
      theme?.palette?.mode === "light" ? "#fff" : "#2b2639"
    } !important` as any,
    borderRadius: "20px!important" as any,
    width: 170,
    minHeight: 54,
    fontSize: "15px !important" as any,
    textTransform: "none !important" as any,
    display: "flex",
    padding: 10,
  },
  actv_btnCTA: {
    backgroundImage: "linear-gradient(67deg, #64c279 0%, #64c279 99%)",
    color: `${
      theme?.palette?.mode === "light" ? "#fff" : "#2b2639"
    } !important` as any,
    borderRadius: "20px!important" as any,
    width: 170,
    minHeight: 54,
    fontSize: "15px !important" as any,
    textTransform: "none !important" as any,
    display: "flex",
    padding: 10,
  },
  modalStyle: {
    boxShadow: "0 24px 44px 0 rgba(43, 38, 57, 0.4)",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxHeight: "100vh",
    maxWidth: 560,
    minWidth: 560,
    borderRadius: "20px",
    backgroundColor:
      theme?.palette?.mode === "light" ? "#fff" : "rgba(43, 38, 57,.630)",
    color: "#2b2639",
    overflowY: "auto",
    minHeight: 413,
  },
  closeBox: {
    position: "absolute",
    right: 24,
    top: 24,
  },
  currentPlan: {
    backgroundImage: "linear-gradient(78deg, #f54d64 0%, #f58561 100%)",
    fontSize: "27px !important",
    fontWeight: "bold !important",
    WebkitBackgroundClip: "text !important",
    WebkitTextFillColor: "transparent !important",
    textAlign: "center",
    margin: "54px auto 54px auto !important",
  },
  modalView: {
    display: "flex",
    justifyContent: "center ",
    width: "600px",
    height: "352px",
    backgroundColor: "#ffffff",
    borderRadius: "20px",
    boxShadow: "0 24px 44px 0 rgba(43, 38, 57, 0.4)",
    flexDirection: "column",
    alignItems: "center",
    margin: "25vh auto",
  },
  modalBox: {
    margin: "10px 0 10px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    width: "100%",
  },
  crossButton: { cursor: "pointer", position: "absolute", right: "20px" },
  tick: { width: "74px", height: "74px", margin: "24px" },
  statusMessage: {
    fontSize: "27px !important",
    fontWeight: "bold !important",
    textAlign: "center",
  },
  gradientText: {
    backgroundImage:
      "linear-gradient(80deg, #f54d64 0%, #f58561 100%) !important",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    fontSize: "27px !important",
    fontWeight: "bold !important",
  },
  textMessage: {
    fontSize: "15px !important",
    margin: "10px 0 24px 0 !important",
    textAlign: "center",
  },
  basicPlan: {
    border: "solid 1px #d7d0e8 ",
    borderRadius: "6px",
    padding: "24px 24px 19px",
    height: "189px",
  },
  typoText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default newLicenseStyle;
