import { put, all, call, takeLatest, select } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { personalizationActionTypes } from "../reducers/profile-personalization";
import { isIndividualSelector } from "../selectors/user-selector";

const version = process.env.REACT_APP_API_VERSION;

function* fetchProfilePersonalizationRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_PROFILE_PERSONALIZATION.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchProfilePersonalization({ accountGuid }) {
  try {
    yield put({
      type: personalizationActionTypes.GET_PROFILE_PERSONALIZATION_REQUEST,
    });
    const response = yield call(fetchProfilePersonalizationRequest, {
      Guidid: accountGuid,
    });

    yield put({
      type: personalizationActionTypes.GET_PROFILE_PERSONALIZATION_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: personalizationActionTypes.GET_PROFILE_PERSONALIZATION_FAILURE,
      payload: e,
    });
  }
}

function* updateProfilePersonalizationRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.UPDATE_PROFILE_PERSONALIZATION.replace("{version}", version),
    payload
  );

  return res;
}

function* updateProfilePersonalization({ payload }) {
  try {
    yield put({
      type: personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_REQUEST,
    });
    const response = yield call(updateProfilePersonalizationRequest, payload);

    if (response?.data?.responseCode === 200) {
      yield put({
        type: personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_SUCCESS,
        payload: response?.data,
      });
      yield put({
        type: personalizationActionTypes.GET_PROFILE_PERSONALIZATION,
        accountGuid: payload?.accountGuid,
      });

      if (payload.navigate) {
        payload.navigate("/license");
      }
    } else {
      yield put({
        type: personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_FAILURE,
        payload: response?.data?.responseMessage,
      });
    }
  } catch (e) {
    yield put({
      type: personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_FAILURE,
      payload: e,
    });
  }
}

//////////////////////////////////

function* fetchProfileVisibilityRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_PROFILE_VISIBILITY.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchCompanyProfileVisibilityRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_COMPANY_PROFILE_VISIBILITY.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchProfileVisibility({ payload }) {
  const isIndividual = yield select(isIndividualSelector);

  try {
    yield put({
      type: personalizationActionTypes.GET_PROFILE_VISIBILITY_REQUEST,
      payload,
    });
    const response = yield call(
      // isIndividual
      //   ? fetchProfileVisibilityRequest
      //   : fetchCompanyProfileVisibilityRequest,
      window.location.href?.includes("/account-settings")
        ? isIndividual
          ? fetchProfileVisibilityRequest
          : fetchCompanyProfileVisibilityRequest
        : window.location.href?.includes("/companies")
        ? fetchCompanyProfileVisibilityRequest
        : fetchProfileVisibilityRequest,
      payload
    );

    yield put({
      type: personalizationActionTypes.GET_PROFILE_VISIBILITY_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: personalizationActionTypes.GET_PROFILE_VISIBILITY_FAILURE,
      payload: e,
    });
  }
}
//////////////////////////////////

function* updateProfileVisibilityRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.UPDATE_PROFILE_VISIBILITY.replace("{version}", version),
    payload
  );

  return res;
}

function* updateCompanyProfileVisibilityRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.UPDATE_COMPANY_PROFILE_VISIBILITY.replace("{version}", version),
    payload
  );

  return res;
}

function* updateProfileVisibility({ payload }) {
  const isIndividual = yield select(isIndividualSelector);

  try {
    yield put({
      type: personalizationActionTypes.UPDATE_PROFILE_VISIBILITY_REQUEST,
    });
    const response = yield call(
      isIndividual
        ? updateProfileVisibilityRequest
        : updateCompanyProfileVisibilityRequest,
      payload
    );

    yield put({
      type: personalizationActionTypes.UPDATE_PROFILE_VISIBILITY_SUCCESS,
      payload: response?.data,
    });
    yield put({
      type: personalizationActionTypes.GET_PROFILE_VISIBILITY,
      payload: {
        accountGuid: payload.accountGuid,
        AccountGuid: payload.AccountGuid,
        hideLoading: true,
      },
    });
  } catch (e) {
    yield put({
      type: personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_FAILURE,
      payload: e,
    });
  }
}
//////////////////////////////////

function* mainSaga() {
  yield all([
    takeLatest(
      personalizationActionTypes.GET_PROFILE_PERSONALIZATION,
      fetchProfilePersonalization
    ),
    takeLatest(
      personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION,
      updateProfilePersonalization
    ),
    takeLatest(
      personalizationActionTypes.GET_PROFILE_VISIBILITY,
      fetchProfileVisibility
    ),
    takeLatest(
      personalizationActionTypes.UPDATE_PROFILE_VISIBILITY,
      updateProfileVisibility
    ),
  ]);
}
export default mainSaga;
