/*
 **	User name
 **	Created	6/1/2023
 **	CookieManagerProvider.tsx
 */

import React, { useEffect, useState } from "react";
import CookieNotice from "app/components/CookieNotice";
import { CookieManagerContext } from "./CookieManagerContext";

export const CookieManagerProvider = ({ children }) => {
  const [showNotice, setShowNotice] = useState(false);

  const hasAcceptedOrDeclined = localStorage.getItem("cookie-notice");

  useEffect(() => {
    if (hasAcceptedOrDeclined) {
      setShowNotice(false);
    } else {
      setShowNotice(true);
    }
  }, [hasAcceptedOrDeclined]);

  const closeCookieNotice = () => {
    localStorage.setItem("cookie-notice", "true");
    setShowNotice(false);
  };

  return (
    <CookieManagerContext.Provider value={{}}>
      {children}
      {showNotice && <CookieNotice onClose={closeCookieNotice} />}
    </CookieManagerContext.Provider>
  );
};
