import { userRegisterReducerTypes } from "./userReducer";

const initialState: {
  loading: boolean;
  error: any;
  data: any;
  status: any;
  libraryData: any;
  libraryCategoryLoading: boolean;
  libraryCategoryData: any;
  productCategoryLoading: boolean;
  productCategoryData: any;
  attributesLoading: boolean;
  attributesData: any;
} = {
  loading: false,
  error: null,
  data: null,
  status: null,
  libraryData: null,
  libraryCategoryLoading: false,
  libraryCategoryData: null,
  productCategoryLoading: false,
  productCategoryData: null,
  attributesLoading: false,
  attributesData: null,
};

export const categoriesActionTypes = {
  GET_ALL_CATEGORIES: "GET_ALL_CATEGORIES",
  GET_ALL_CATEGORIES_REQUEST: "GET_ALL_CATEGORIES_REQUEST",
  GET_ALL_CATEGORIES_SUCCESS: "GET_ALL_CATEGORIES_SUCCESS",
  GET_ALL_CATEGORIES_FAILURE: "GET_ALL_CATEGORIES_FAILURE",
  GET_ALL_LIBRARY_CATEGORIES: "GET_ALL_LIBRARY_CATEGORIES",
  GET_ALL_LIBRARY_CATEGORIES_REQUEST: "GET_ALL_LIBRARY_CATEGORIES_REQUEST",
  GET_ALL_LIBRARY_CATEGORIES_SUCCESS: "GET_ALL_LIBRARY_CATEGORIES_SUCCESS",
  GET_ALL_LIBRARY_CATEGORIES_FAILURE: "GET_ALL_LIBRARY_CATEGORIES_FAILURE",
  GET_ALL_LIBRARY_CATEGORIES_HIERARCHY: "GET_ALL_LIBRARY_CATEGORIES_HIERARCHY",
  GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_REQUEST:
    "GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_REQUEST",
  GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_SUCCESS:
    "GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_SUCCESS",
  GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_FAILURE:
    "GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_FAILURE",

  GET_ALL_PRODUCT_CATEGORIES_HIERARCHY: "GET_ALL_PRODUCT_CATEGORIES_HIERARCHY",
  GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_REQUEST:
    "GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_REQUEST",
  GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_SUCCESS:
    "GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_SUCCESS",
  GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_FAILURE:
    "GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_FAILURE",

  GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY:
    "GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY",
  GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_REQUEST:
    "GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_REQUEST",
  GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_SUCCESS:
    "GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_SUCCESS",
  GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_FAILURE:
    "GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_FAILURE",
  GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_CLEAR:
    "GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_CLEAR",
};

function reducerFunc(state = initialState, action) {
  switch (action.type) {
    case categoriesActionTypes.GET_ALL_CATEGORIES_REQUEST:
      return {
        ...state,
        error: null,
        status: categoriesActionTypes.GET_ALL_CATEGORIES_REQUEST,
      };
    case categoriesActionTypes.GET_ALL_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: categoriesActionTypes.GET_ALL_CATEGORIES_FAILURE,
      };
    case categoriesActionTypes.GET_ALL_CATEGORIES_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: categoriesActionTypes.GET_ALL_CATEGORIES_SUCCESS,
      };

    case categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_REQUEST:
      return {
        ...state,
        error: null,
        status: categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_REQUEST,
      };
    case categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_FAILURE,
      };
    case categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_SUCCESS:
      return {
        ...state,
        libraryData: action.payload,
        status: categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_SUCCESS,
      };

    case categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_REQUEST:
      return {
        ...state,
        error: null,
        libraryCategoryLoading: true,
        status:
          categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_REQUEST,
      };
    case categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_FAILURE:
      return {
        ...state,
        error: action.payload,
        libraryCategoryLoading: false,
        status:
          categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_FAILURE,
      };
    case categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_SUCCESS:
      return {
        ...state,
        libraryCategoryData: action.payload,
        libraryCategoryLoading: false,
        status:
          categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_SUCCESS,
      };

    case categoriesActionTypes.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_REQUEST:
      return {
        ...state,
        error: null,
        productCategoryLoading: true,
        status:
          categoriesActionTypes.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_REQUEST,
      };
    case categoriesActionTypes.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_FAILURE:
      return {
        ...state,
        error: action.payload,
        productCategoryLoading: false,
        status:
          categoriesActionTypes.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_FAILURE,
      };
    case categoriesActionTypes.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_SUCCESS:
      return {
        ...state,
        productCategoryData: action.payload,
        productCategoryLoading: false,
        status:
          categoriesActionTypes.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_SUCCESS,
      };

    case categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_REQUEST:
      return {
        ...state,
        error: null,
        attributesLoading: true,
        status:
          categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_REQUEST,
      };
    case categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_FAILURE:
      return {
        ...state,
        error: action.payload,
        attributesLoading: false,
        status:
          categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_FAILURE,
      };
    case categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_SUCCESS:
      return {
        ...state,
        attributesData: action.payload,
        attributesLoading: false,
        status:
          categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_SUCCESS,
      };

    case categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_CLEAR:
      return {
        ...state,
        attributesData: null,
        attributesLoading: false,
        status:
          categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_CLEAR,
      };

    case userRegisterReducerTypes.LOGOUT_USER:
      return { ...initialState };

    default:
      return state;
  }
}

export type CategoriesReducer = ReturnType<typeof reducerFunc>;

export default reducerFunc;
