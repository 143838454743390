/*
 **	User name
 **	Created	5/25/2023
 **	mainsaga.ts
 */

import { all } from "redux-saga/effects";

import userSaga from "./usersaga";
import licenseSaga from "./license-saga";
import productSaga from "./productsaga";
import paymentsSaga from "./payments-saga";
import companySaga from "./company-saga";
import profilePersonalizationSaga from "./profile-personalization-saga";
import inviteSaga from "./invite-saga";
import invoiceSaga from "./invoice-saga";
import invoiceSettingsSaga from "./invoice-settings-saga";
import complaintSaga from "./complaints-saga";
import notificationSaga from "./notifications-saga";
import experienceSaga from "./experience-saga";
import skillsSaga from "./skills-saga";
import aboutMeSaga from "./about-me-saga";
import webLinkSaga from "./webLink-saga";
import resumeSaga from "./resume-saga";
import filerSaga from "./filter-saga";
import jobsSaga from "./jobs-saga";
import employeeSaga from "./employee-saga";
import categoriesSaga from "./categories-saga";
import homeSaga from "./home-saga";
import librarySaga from "./library-saga";
import cartSaga from "./cart-saga";
import orderSaga from "./order-saga";
import settingsSaga from "./settings-saga";
import searchSaga from "./search-saga";
import productCommentsSaga from "./product-comments-saga";

export function* mainSaga() {
  yield all([
    userSaga(),
    licenseSaga(),
    productSaga(),
    paymentsSaga(),
    companySaga(),
    profilePersonalizationSaga(),
    inviteSaga(),
    invoiceSaga(),
    invoiceSettingsSaga(),
    complaintSaga(),
    notificationSaga(),
    experienceSaga(),
    skillsSaga(),
    aboutMeSaga(),
    webLinkSaga(),
    resumeSaga(),
    filerSaga(),
    jobsSaga(),
    employeeSaga(),
    categoriesSaga(),
    homeSaga(),
    librarySaga(),
    cartSaga(),
    orderSaga(),
    settingsSaga(),
    searchSaga(),
    productCommentsSaga(),
  ]);
}
