const initialState = {
  error: null,
  data: null,
  status: null,
  contactStatus: null,
  contactError: null,
};

export const inviteActionTypes = {
  INVITE_A_FRIEND: "INVITE_A_FRIEND",
  INVITE_A_FRIEND_REQUEST: "INVITE_A_FRIEND_REQUEST",
  INVITE_A_FRIEND_SUCCESS: "INVITE_A_FRIEND_SUCCESS",
  INVITE_A_FRIEND_FAILURE: "INVITE_A_FRIEND_FAILURE",
  INVITE_A_FRIEND_CLEAR: "INVITE_A_FRIEND_CLEAR",

  CONTACT_US: "CONTACT_US",
  CONTACT_US_REQUEST: "CONTACT_US_REQUEST",
  CONTACT_US_SUCCESS: "CONTACT_US_SUCCESS",
  CONTACT_US_FAILURE: "CONTACT_US_FAILURE",
  CONTACT_US_CLEAR: "CONTACT_US_CLEAR",
};

function reducerFunc(state = initialState, action) {
  switch (action.type) {
    case inviteActionTypes.INVITE_A_FRIEND_REQUEST:
      return {
        ...state,
        error: null,
        status: inviteActionTypes.INVITE_A_FRIEND_REQUEST,
      };
    case inviteActionTypes.INVITE_A_FRIEND_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: inviteActionTypes.INVITE_A_FRIEND_FAILURE,
      };
    case inviteActionTypes.INVITE_A_FRIEND_SUCCESS:
      return {
        ...state,
        status: inviteActionTypes.INVITE_A_FRIEND_SUCCESS,
        data: action.payload,
      };
    case inviteActionTypes.INVITE_A_FRIEND_CLEAR:
      return { ...initialState };

    case inviteActionTypes.CONTACT_US_REQUEST:
      return {
        ...state,
        contactError: null,
        contactStatus: inviteActionTypes.CONTACT_US_REQUEST,
      };
    case inviteActionTypes.CONTACT_US_FAILURE:
      return {
        ...state,
        contactError: action.payload,
        contactStatus: inviteActionTypes.CONTACT_US_FAILURE,
      };
    case inviteActionTypes.CONTACT_US_SUCCESS:
      return {
        ...state,
        contactError: null,
        data: action.payload,
        contactStatus: inviteActionTypes.CONTACT_US_SUCCESS,
      };
    case inviteActionTypes.CONTACT_US_CLEAR:
      return { ...initialState };
    default:
      return state;
  }
}

export default reducerFunc;
