import React, { useContext, useMemo, useState } from "react";
import { Box, Modal, Chip, Typography, Avatar } from "@mui/material";
import { useTheme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import Moment from "react-moment";
import {
  BlackBorderedButton,
  GradientColoredButton,
  PurpleButton,
} from "app/components/UIFormFields/Buttons";
import { useAppSelector } from "app/utils/hooks";
import { LibraryProductUploadContext } from "context/LibraryProductUploadContext";
import { CategoryDataContext } from "app/components/CategoryComponent/CategoryDataContext";

import LibraryNavBar from "../../../screens/LibraryContainer/navBar";
import { useProductDetailsStyles } from "../../../../styles/productDetails";

export const useUploadModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95vw",
    maxHeight: "95vh",
    // height: 1200,
    // maxWidth: 1200,
    borderRadius: "15px",
    color: "white",
    overflowY: "auto",
    padding: "50px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalPreview: {
    maxWidth: "750px",
    height: "507px",
    borderRadius: "20px",
    backgroundColor: "#ebe3ff",
    marginBottom: "35px",
  },
  ViewAll: {
    fontSize: 13,
    color: "#7a48ff",
    textAlign: "end",
    fontWeight: "bold !important",
    marginBottom: "10px !important",
    cursor: "pointer",
  },
  allItems: {
    width: "100%",
    height: "130px",
    display: "flex",
    overflow: "auto ",
  },
  singleItem: {
    maxWidth: "153px",
    maxHeight: "112px",
    backgroundColor: "#ebe3ff",
    margin: "10px",
  },
  leftBox: {
    display: "flex",
    flexDirection: "column",
    paddingRight: "24px",
    flex: 1,
    maxWidth: "750px",
  },
  allItemsFullView: {
    color: "#a49bbe",
    fontWeight: "bold !important",
    fontSize: 13,
    textAlign: "end",
  },
  allImagesFullView: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    // margin: "auto",
  },
  allImagesSingleImage: {
    width: "273px",
    height: "206px",
  },
  uploadName: {
    color: "#2b2639",
    fontSize: "15px !important",
    marginTop: "5px",
  },
  arrow: {
    color: "#2b2639",
    width: "29px",
    height: "29px",
    position: "relative",
    bottom: "50px",
    left: "5px",
    cursor: "pointer",
  },
}));

export default function LibraryProductPreview({ handleClose }) {
  const { productInfo, uploadedImages } = useContext(
    LibraryProductUploadContext
  );
  const { libraryData, categoriesDataMain } = useContext(CategoryDataContext);
  const classes = useProductDetailsStyles();
  const previewClass = useUploadModalStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    ReactComponent: ShareIcon,
  } = require("../../../../assets/icons/Icons_Light_Share Icon.svg");
  const {
    ReactComponent: WhishlistIcon,
  } = require("../../../../assets/icons/whishlist-icon.svg");
  // "../../../../../assets/icons/whishlist-icon.svg"
  const {
    ReactComponent: HeartLikeIcon,
  } = require("../../../../assets/icons/Icons_Light_Like heart Icon.svg");
  const {
    ReactComponent: AddCardButtonIcon,
  } = require("../../../../assets/icons/Icons_Light_add_Cart button Icon.svg");
  const {
    ReactComponent: DownloadButtonIcon,
  } = require("../../../../assets/icons/Icons_Light_Download Icon.svg");
  const {
    ReactComponent: CommentIcon,
  } = require("../../../../assets/icons/Comments.svg");
  const {
    ReactComponent: DownloadIcon,
  } = require("../../../../assets/icons/Download.svg");
  const {
    ReactComponent: ViewIcon,
  } = require("../../../../assets/icons/Views.svg");
  const {
    ReactComponent: LikesIcon,
  } = require("../../../../assets/icons/Likes.svg");
  const {
    ReactComponent: LightFollowIcon,
  } = require("../../../../assets/icons/Icons_Light_Follow.svg");
  const {
    ReactComponent: LightFollowedIcon,
  } = require("../../../../assets/icons/Icons_Light_Followed.svg");
  const {
    ReactComponent: LightChatIcon,
  } = require("../../../../assets/icons/Icons_Light_Chat Icon.svg");
  const demoUserImg = require("../../../../assets/placeholders/Adult1.png");

  const userData = useAppSelector((state) => state?.register);
  const [formData, setFormData] = useState({
    review: "",
    isFollowed: false,
  });
  const [viewAllStatus, setViewAllStatus] = useState(false);
  const [prevNavState, setPrevNavState] = useState(true);
  const filterTypes = useAppSelector((state) => state.filterTypes);
  const productTag = useAppSelector((state) => state.tags);
  const toolTypes = filterTypes?.toolTypeList;

  const handleFollowUser = () =>
    setFormData({ ...formData, isFollowed: !formData?.isFollowed });

  const dummyImage =
    "https://th.bing.com/th/id/OIP.ooYUQpt6VpOUlVpART8hFQHaE8?w=266&h=180&c=7&r=0&o=5&dpr=1.5&pid=1.7";
  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
  };

  const mapedToolsObj = useMemo(() => {
    const obj = {};

    toolTypes?.forEach((value) => {
      obj[value.toolDetailId] = value.toolName;
    });
    return obj;
  }, [toolTypes]);

  const mapedTagsObj = useMemo(() => {
    const obj = {};

    productTag?.data?.forEach((value) => {
      obj[value.productTagID] = value.productTagName;
    });
    return obj;
  }, [productTag]);

  return (
    <Modal open={true}>
      <Box
        className={previewClass.modalStyle}
        style={{
          backgroundColor: theme?.palette?.mode === "light" && "#fff",
        }}
      >
        <Box>
          <Typography
            style={
              theme?.palette?.mode === "light"
                ? heading.lightMode
                : heading.nightMode
            }
          >
            Library Product Preview
          </Typography>
        </Box>
        <LibraryNavBar prevNavState={prevNavState} />
        {!viewAllStatus ? (
          <Box className={classes.mainBox} style={{ width: "80%" }}>
            {/* LeftSection */}
            <Box className={previewClass.leftBox}>
              {uploadedImages[0]?.type.includes("image/") ? (
                <img
                  src={
                    uploadedImages[0]
                      ? URL.createObjectURL(uploadedImages[0])
                      : dummyImage
                  }
                  className={previewClass.modalPreview}
                  alt="product"
                />
              ) : (
                <model-viewer
                  className={previewClass.modalPreview}
                  src={
                    uploadedImages[0]
                      ? URL.createObjectURL(uploadedImages[0])
                      : dummyImage
                  }
                  ios-src=""
                  alt="3d model"
                  auto-rotate
                  camera-controls
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  environment-image="neutral"
                  oncontextmenu="return false"
                  autoplay
                ></model-viewer>
              )}
              <Box sx={{ width: "100%" }}>
                <Typography
                  className={previewClass.ViewAll}
                  onClick={() => setViewAllStatus(true)}
                >
                  View All Items({uploadedImages?.length})
                </Typography>
                <Box className={previewClass.allItems}>
                  {uploadedImages?.map((item, index) =>
                    item.type.includes("image/") ? (
                      <img
                        key={index}
                        src={URL.createObjectURL(item) || dummyImage}
                        className={previewClass.singleItem}
                        alt="product"
                      />
                    ) : (
                      <model-viewer
                        key={index}
                        className={previewClass.singleItem}
                        src={URL.createObjectURL(item) || dummyImage}
                        ios-src=""
                        alt="3d model"
                        auto-rotate
                        camera-controls
                        ar
                        ar-modes="webxr scene-viewer quick-look"
                        environment-image="neutral"
                        oncontextmenu="return false"
                        autoplay
                      ></model-viewer>
                    )
                  )}
                </Box>
              </Box>
              <Typography className={classes.productName}>
                {productInfo?.title}
              </Typography>
              <Typography className={classes.title}>Tools</Typography>
              <Box className={classes.clickBox}>
                {productInfo?.tools?.map((value, index) => {
                  const item = mapedToolsObj[value];

                  return (
                    <Chip
                      className={classes.chipBox}
                      key={index}
                      label={item || value}
                    />
                  );
                })}
              </Box>
              <Typography className={classes.title}>Tags</Typography>
              <Box className={classes.clickBox}>
                {productInfo?.tags?.map((value, index) => {
                  const item = mapedTagsObj[value];

                  return (
                    <Chip
                      className={classes.chipBox}
                      key={index}
                      label={item || value}
                    />
                  );
                })}
              </Box>
              <Typography className={classes.title}>Categories</Typography>

              <Box className={classes.clickBox}>
                {libraryData?.treeData?.map((value, index) => {
                  return (
                    <Chip
                      className={classes.chipBox}
                      key={index}
                      label={value?.name}
                    />
                  );
                })}
              </Box>

              {libraryData?.productCategoriesTreeData?.length && (
                <>
                  <Typography className={classes.title}>Gender</Typography>
                  <Box className={classes.clickBox}>
                    <Chip
                      className={classes.chipBox}
                      // key={productDetails?.genderId} to be relaced
                      label={libraryData?.productCategoriesTreeData?.[0]?.name}
                    />
                  </Box>
                </>
              )}

              {libraryData?.productCategoriesTreeData?.length >= 2 && (
                <>
                  <Typography className={classes.title}>Category</Typography>
                  <Box className={classes.clickBox}>
                    <Chip
                      className={classes.chipBox}
                      // key={productDetails?.genderId} to be relaced
                      label={`${libraryData?.productCategoriesTreeData?.[1].parentCategory?.name} (${libraryData?.productCategoriesTreeData?.[1]?.name})`}
                    />
                  </Box>
                </>
              )}

              {libraryData?.attributesTreeData?.length
                ? libraryData?.attributesTreeData.map((attributesTreeData) => (
                    <>
                      <Typography className={classes.title}>
                        {attributesTreeData.name}
                      </Typography>
                      <Box className={classes.clickBox}>
                        {attributesTreeData?.selectedAttribute?.map(
                          (selectedValue) => (
                            <Chip
                              className={classes.chipBox}
                              // key={productDetails?.genderId} to be relaced
                              label={
                                categoriesDataMain?.attributesData
                                  ?.attributeValuesMap?.[selectedValue]
                              }
                            />
                          )
                        )}
                      </Box>
                    </>
                  ))
                : null}

              <Typography className={classes.title}>Description</Typography>
              <Typography className={classes.description}>
                {productInfo?.description}
              </Typography>
            </Box>
            {/* RightSection */}
            <Box className={classes.rightBox}>
              <Box className={classes.rightTopBox}>
                <Box className={classes.rightTopPriceBox}>
                  <Typography className={classes.productName}>
                    {`${productInfo?.currencyId === 1 ? "$" : "€"}${
                      productInfo?.price ?? 0
                    }`}
                  </Typography>
                  <Box className={classes.socialIconBox}>
                    <Box className={classes.socialIcon}>
                      <ShareIcon />
                    </Box>
                    <Box className={classes.socialIcon}>
                      <WhishlistIcon />
                    </Box>
                    <Box className={classes.socialIcon}>
                      <HeartLikeIcon />
                    </Box>
                  </Box>
                </Box>
                <GradientColoredButton
                  style={{
                    width: "auto",
                    marginBottom: "16px",
                    padding: "10px 42px",
                    borderRadius: "6px",
                    color: "#fff",
                  }}
                  // disabled
                >
                  <DownloadButtonIcon style={{ marginRight: "10px" }} />
                  Download Now
                </GradientColoredButton>
                <BlackBorderedButton
                  style={{
                    padding: "10px 42px",
                    width: "auto",
                    marginBottom: "24px",
                  }}
                  // disabled
                >
                  <AddCardButtonIcon style={{ marginRight: "10px" }} />
                  Add To Cart
                </BlackBorderedButton>
                <Box className={classes.details}>
                  <ViewIcon className={classes.detailsIcon} />
                  <Typography
                    className={classes.description}
                    variant="subtitle1"
                  >
                    Project Views
                  </Typography>
                  <Typography
                    className={`${classes.description} ${classes.detailsText}`}
                    variant="subtitle1"
                  >
                    {0}
                  </Typography>
                </Box>
                <Box className={classes.details}>
                  <LikesIcon className={classes.detailsIcon} />
                  <Typography
                    className={classes.description}
                    variant="subtitle1"
                  >
                    Appreciations
                  </Typography>
                  <Typography
                    className={`${classes.description} ${classes.detailsText}`}
                    variant="subtitle1"
                  >
                    {0}
                  </Typography>
                </Box>
                <Box className={classes.details}>
                  <DownloadIcon className={classes.detailsIcon} />
                  <Typography
                    className={classes.description}
                    variant="subtitle1"
                  >
                    Downloads
                  </Typography>
                  <Typography
                    className={`${classes.description} ${classes.detailsText}`}
                    variant="subtitle1"
                  >
                    {0}
                  </Typography>
                </Box>
                <Box className={classes.details}>
                  <CommentIcon className={classes.detailsIcon} />
                  <Typography
                    className={classes.description}
                    variant="subtitle1"
                  >
                    Comments
                  </Typography>
                  <Typography
                    className={`${classes.description} ${classes.detailsText}`}
                    variant="subtitle1"
                  >
                    {0}
                  </Typography>
                </Box>
                <Box className={classes.boundaryLine} />
                <Box className={classes.userProfile}>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {/* <img
                      className={classes.profilePicture}
                      src={demoUserImg}
                      alt="user"
                    /> */}
                    <Avatar
                      className={classes.profilePicture}
                      src={userData?.userAdditionalInfo?.profilePictureUrl}
                    >
                      {userData?.userAdditionalInfo?.profileType === 2
                        ? `${userData?.userAdditionalInfo?.userName}`
                        : `${userData?.userAdditionalInfo?.firstName} ${userData?.userAdditionalInfo?.lastName}`}
                    </Avatar>

                    <Box>
                      <Typography className={classes.userName}>
                        {userData?.userAdditionalInfo?.profileType === 2
                          ? `${userData?.userAdditionalInfo?.userName}`
                          : `${userData?.userAdditionalInfo?.firstName} ${userData?.userAdditionalInfo?.lastName}`}
                      </Typography>
                      <Typography className={classes.userProfession}>
                        {userData.userAdditionalInfo?.profession}
                      </Typography>
                    </Box>
                  </Box>

                  <Box className={classes.userSocialIconBox}>
                    <Box className={classes.socialIcon}>
                      <LightChatIcon />
                    </Box>
                    {formData.isFollowed ? (
                      <Box
                        className={classes.socialIcon}
                        onClick={() => handleFollowUser}
                        style={{
                          border:
                            formData.isFollowed &&
                            "solid 1px #d7d0e8 !important",
                        }}
                      >
                        <LightFollowedIcon />
                      </Box>
                    ) : (
                      <Box
                        className={classes.socialIcon}
                        onClick={() => handleFollowUser}
                      >
                        <LightFollowIcon />
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box className={classes.boundaryLine} />
                <Box className={classes.detailsUser}>
                  <Typography
                    className={classes.rightBottomPanelTitle}
                    variant="subtitle1"
                  >
                    Published
                  </Typography>
                  <Typography
                    className={`${classes.rightPanelDescription} ${classes.rightBottomDetailsText}`}
                    variant="subtitle1"
                  >
                    <Moment format="DD MMM, YYYY">{new Date()}</Moment>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          //////// Upload files preview
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              className={previewClass.arrow}
              onClick={() => setViewAllStatus(false)}
              style={{ position: "relative", top: "-200px" }}
            >
              <i className="fa-sharp fa-solid fa-arrow-left fa-xl"></i>
            </Box>
            <Typography className={previewClass.allItemsFullView}>
              All Items({uploadedImages?.length})
            </Typography>
            <Box className={previewClass.allImagesFullView}>
              {uploadedImages?.map((item, index) =>
                item.type.includes("image/") ? (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "5px",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(item) || dummyImage}
                      className={previewClass.allImagesSingleImage}
                      alt="product"
                    />
                    <Typography className={previewClass.uploadName}>
                      3D Model
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "5px",
                    }}
                  >
                    <model-viewer
                      className={previewClass.allImagesSingleImage}
                      src={URL.createObjectURL(item) || dummyImage}
                      ios-src=""
                      alt="3d model"
                      auto-rotate
                      camera-controls
                      ar
                      ar-modes="webxr scene-viewer quick-look"
                      environment-image="neutral"
                      oncontextmenu="return false"
                      autoplay
                    ></model-viewer>
                    <Typography className={previewClass.uploadName}>
                      3D Model
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          </Box>
        )}
        <Box
          style={{
            width: "75px",
            marginTop: "34px",
            marginBottom: "34px",
          }}
        >
          <PurpleButton onClick={handleClose}>Close</PurpleButton>
        </Box>
      </Box>
    </Modal>
  );
}
