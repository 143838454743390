import React, { useContext } from "react";
import { Box, useTheme } from "@mui/material";
import { AppInitContext } from "AppInitContext";

import { useNavBarStyles } from "../../../styles/navbarStyles";

export default function LibraryNavBar({
  navState = false,
  setNavState = (navState) => null,
  prevNavState = false,
}) {
  const { isUserLoggedIn, showGuestLogin } = useContext(AppInitContext);
  const classes = useNavBarStyles();
  const theme = useTheme();

  const handleAllLibraryProductsClick = () => {
    setNavState(true);
  };

  const handleSavedLibraryProductsClick = () => {
    if (!isUserLoggedIn) {
      showGuestLogin();
      return;
    }
    setNavState(false);
  };

  return (
    <Box className="col-md-12 mx-auto" style={{ zIndex: 2 }}>
      <Box
        className={classes.header}
        style={{
          marginTop: 60,
        }}
      >
        <nav
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <ul
              style={{
                width: "100%",
                margin: 0,
                display: "flex",
                padding: 0,
                listStyle: "none",
              }}
            >
              {prevNavState === true ? (
                <li style={{ paddingBottom: 30, marginRight: 30 }}>
                  <span
                    className={
                      theme?.palette?.mode === "light"
                        ? prevNavState
                          ? `${classes.navbar_li_a_light} HeaderNavBar newHeaderActiveNavBar`
                          : `${classes.navbar_li_a_light} HeaderNavBar`
                        : prevNavState
                        ? `${classes.navbar_li_a_dark} HeaderNavBar newHeaderActiveNavBarNight`
                        : `${classes.navbar_li_a_dark} HeaderNavBar`
                    }
                    onClick={handleAllLibraryProductsClick}
                  >
                    Library Product Information
                  </span>
                </li>
              ) : (
                <>
                  <li style={{ paddingBottom: 30, marginRight: 30 }}>
                    <span
                      className={
                        theme?.palette?.mode === "light"
                          ? navState
                            ? `${classes.navbar_li_a_light} HeaderNavBar newHeaderActiveNavBar`
                            : `${classes.navbar_li_a_light} HeaderNavBar`
                          : navState
                          ? `${classes.navbar_li_a_dark} HeaderNavBar newHeaderActiveNavBarNight`
                          : `${classes.navbar_li_a_dark} HeaderNavBar`
                      }
                      onClick={handleAllLibraryProductsClick}
                    >
                      All Library Products
                    </span>
                  </li>
                  <li style={{ paddingBottom: 30 }}>
                    <span
                      className={
                        theme?.palette?.mode === "light"
                          ? !navState
                            ? `${classes.navbar_li_a_light} HeaderNavBar newDesignersNavBar`
                            : `${classes.navbar_li_a_light} HeaderNavBar`
                          : !navState
                          ? `${classes.navbar_li_a_dark} HeaderNavBar newHeaderActiveNavBarNight`
                          : `${classes.navbar_li_a_dark} HeaderNavBar`
                      }
                      onClick={handleSavedLibraryProductsClick}
                      style={{ zIndex: 2 }}
                    >
                      Saved Library Products
                    </span>
                  </li>
                </>
              )}
            </ul>
          </Box>
          <hr
            style={{
              color: "#999",
              width: "100%",
              height: "2px",
              marginTop: "-6px",
            }}
          />
        </nav>
      </Box>
    </Box>
  );
}
