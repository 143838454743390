import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { JobsReducerTypes } from "../reducers/jobReducer";

const version = process.env.REACT_APP_API_VERSION;
const version3 = process.env.REACT_APP_API_VERSION_3;

//get all apply job list

function* jobApplyListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_JOBS_APPLY_MEDIUM.replace("{version}", version)
  );

  return res;
}

function* jobApplyList({}) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_JOB_APPLY_LIST, payload: null });
    const response = yield call(jobApplyListRequest);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_APPLY_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_APPLY_LIST });
  }
}

//get all job workplace list

function* jobWorkPlaceListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_JOBS_WORKPLACE.replace("{version}", version)
  );

  return res;
}

function* jobWorkPlaceList({}) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_JOB_WORK_PLACE_LIST,
      payload: null,
    });
    const response = yield call(jobWorkPlaceListRequest);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_WORK_PLACE_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_WORK_PLACE_LIST });
  }
}

//get all job experiences list

function* jobExperiencesListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_JOBS_EXPERIENCES.replace("{version}", version)
  );

  return res;
}

function* jobExperiencesList({}) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_JOB_EXPERIENCE_LIST,
      payload: null,
    });
    const response = yield call(jobExperiencesListRequest);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_EXPERIENCE_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_EXPERIENCE_LIST });
  }
}

// post a job
function* postjobRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.POST_JOB.replace("{version}", version),
    payload,
    0
  );

  return res;
}
function* postjob({ payload }) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_JOB_POST_LIST, payload: null });
    const response = yield call(postjobRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_POST_LIST,
      payload: response?.data,
    });
    // yield put({ type: JobsReducerTypes.CLEAR_JOB_POST_LIST, payload: { accountGuid: payload.accountGuid } })
  } catch (e) {
    yield put({
      type: JobsReducerTypes.ERROR_JOB_POST_LIST,
      payload: e.toString(),
    });
  }
}

//  Get list of all jobs
function* allJobsListRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_JOB_POSTED.replace("{version}", version3),
    payload
  );

  return res;
}

function* allJobsList({ payload }) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_ALL_JOBS_LIST, payload: null });
    const response = yield call(allJobsListRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_ALL_JOBS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_ALL_JOBS_LIST, payload: e });
  }
}

//  Get list of all jobs posted by user
function* allJobsListPostedByUserRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_JOBS_POSTED_BY_USER.replace("{version}", version3),
    payload
  );

  return res;
}

function* allJobsListPostedByUser({ payload }) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_ALL_JOBS_LIST_POSTED_BY_USER,
      payload,
    });
    const response = yield call(allJobsListPostedByUserRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_ALL_JOBS_LIST_POSTED_BY_USER,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: JobsReducerTypes.ERROR_ALL_JOBS_LIST_POSTED_BY_USER,
      payload: e,
    });
  }
}

// APPLY for JOBS
function* applyJobRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.APPLY_FOR_JOB.replace("{version}", version),
    payload
  );

  return res;
}
function* applyjob({ payload }) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_APPLY_JOBS, payload: null });
    const response = yield call(applyJobRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_APPLY_JOBS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_APPLY_JOBS, payload: e });
  }
}

// save JOBS
function* saveJobRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.SAVE_JOB.replace("{version}", version),
    payload
  );

  return res;
}
function* saveJob({ payload }) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_SAVE_JOBS, payload: null });
    const response = yield call(saveJobRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_SAVE_JOBS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_SAVE_JOBS, payload: e });
  }
}

//  Get list of applied jobs by user
function* allJobsListAppliedByUserRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_JOBS_APPLIED_BY_USER.replace("{version}", version3),
    payload
  );

  return res;
}

function* allJobsListAppliedByUser({ payload }) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_APPLIED_JOBS_LIST,
      payload: null,
    });
    const response = yield call(allJobsListAppliedByUserRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_APPLIED_JOBS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_APPLIED_JOBS_LIST, payload: e });
  }
}

//  Get list of SAVED jobs by user
function* allJobsListSavedByUserRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_JOBS_SAVED_BY_USER.replace("{version}", version3),
    payload
  );

  return res;
}

function* allJobsListSavedByUser({ payload }) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_SAVED_JOBS_LIST,
      payload: null,
    });
    const response = yield call(allJobsListSavedByUserRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_SAVED_JOBS_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_SAVED_JOBS_LIST, payload: e });
  }
}

//get all job rate type list

function* jobRateTypeListRequest() {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_RATES_TYPE.replace("{version}", version)
  );

  return res;
}

function* jobRateTypeList({}) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_JOB_RATE_TYPE_LIST,
      payload: null,
    });
    const response = yield call(jobRateTypeListRequest);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_RATE_TYPE_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_RATE_TYPE_LIST, payload: e });
  }
}

//edit job post saga

function* editJobPostRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.EDIT_JOB_POST.replace("{version}", version),
    payload
  );

  return res;
}

function* editJobPost({ payload }) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_JOB_EDITED, payload: null });
    const response = yield call(editJobPostRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_EDITED,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_EDITED, payload: e });
  }
}

//  Get list of drafted jobs by user
function* allDraftedJobsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_DRAFTED_JOBS.replace("{version}", version3),
    payload
  );

  return res;
}
function* allDraftedJobs({ payload }) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_JOB_DRAFTED_LIST,
      payload: null,
    });
    const response = yield call(allDraftedJobsRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_DRAFTED_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_DRAFTED_LIST, payload: e });
  }
}

//delete job post saga

function* deleteJobPostRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.DELETE_POSTED_JOB.replace("{version}", version),
    payload
  );

  return res;
}

function* deleteJobPost({ payload }) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_JOB_DELETE, payload: null });
    const response = yield call(deleteJobPostRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_DELETE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_DELETE, payload: e });
  }
}

//deactivate job post saga

function* deactivateJobPostRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.DEACTIVATE_JOB_BY_ID.replace("{version}", version),
    payload
  );

  return res;
}

function* deactivateJobPost({ payload }) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_JOB_DEACTIVE, payload: null });
    const response = yield call(deactivateJobPostRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_DEACTIVE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_DEACTIVE, payload: e });
  }
}

//activate job post saga

function* activateJobPostRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.ACTIVATE_JOB_BY_ID.replace("{version}", version),
    payload
  );

  return res;
}

function* activateJobPost({ payload }) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_JOB_ACTIVE, payload: null });
    const response = yield call(activateJobPostRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_ACTIVE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_ACTIVE, payload: e });
  }
}

//filterd job request data

// function* jobFilteredRequest(payload) {
//   const res = yield request(
//     "post",
//     EndPoints.POST_FILTERED_JOBS.replace("{version}", version),
//     payload
//   );

//   return res;
// }

// function* filteredJob({ params }) {
//   try {
//     yield put({
//       type: JobsReducerTypes.REQUEST_JOB_FILTERED_LIST,
//       payload: null,
//     });
//     const response = yield call(jobFilteredRequest, params);

//     yield put({
//       type: JobsReducerTypes.RESPONSE_JOB_FILTERED_LIST,
//       payload: response?.data,
//     });
//   } catch (e) {
//     yield put({ type: JobsReducerTypes.ERROR_JOB_FILTERED_LIST });
//   }
// }

// filtered Jobs
function* jobFilteredRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_FILTERED_JOBS.replace("{version}", version3),
    payload
  );

  return res;
}

function* filteredJob({ params }) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_JOB_FILTERED_LIST,
      payload: null,
    });
    const response = yield call(jobFilteredRequest, params);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_FILTERED_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_FILTERED_LIST });
  }
}

//get all job salary range list

function* jobSalaryRangeRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_JOBS_SALARY.replace("{version}", version)
  );

  return res;
}

function* jobSalaryRange({}) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_JOB_SALARY_RANGE_LIST,
      payload: null,
    });
    const response = yield call(jobSalaryRangeRequest);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_SALARY_RANGE_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_SALARY_RANGE_LIST });
  }
}

// APPLY_JOB_ON_DC
function* applyJobOnDCRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.APPLY_JOB_ON_DC.replace("{version}", version),
    payload,
    "form-data"
  );

  return res;
}
function* applyJobOnDC({ payload }) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_APPLY_JOB_ON_DC,
      payload: null,
    });
    const response = yield call(applyJobOnDCRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_APPLY_JOB_ON_DC,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_APPLY_JOB_ON_DC, payload: e });
  }
}

//get all JobType List

function* fetchAllEmployeeJobsTypes(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_EMPLOYEE_TYPE.replace("{version}", version),
    payload
  );

  return res;
}

function* getEmployeeJobsList({}) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_JOB_TYPE_LIST, payload: null });
    const response = yield call(fetchAllEmployeeJobsTypes);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_TYPE_LIST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_TYPE_LIST });
  }
}

//get JOBS autocomplete data

function* jobsAutocompleteRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.JOBS_AUTOCOMPLETE_FILTER.replace("{version}", version),
    payload,
    0
  );

  return res;
}

function* jobsAutocomplete({ params }) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_JOBS_AUTOCOMPLETE,
      payload: null,
    });
    const response = yield call(jobsAutocompleteRequest, params);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOBS_AUTOCOMPLETE,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOBS_AUTOCOMPLETE, payload: e });
  }
}

//get all job salary range list
function* jobApplicantsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.JOB_APPLICANTS.replace("{version}", version),
    payload
  );

  return res;
}

function* jobApplicants({ payload }) {
  try {
    yield put({ type: JobsReducerTypes.REQUEST_JOB_APPLICANTS, payload: null });
    const response = yield call(jobApplicantsRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_JOB_APPLICANTS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({ type: JobsReducerTypes.ERROR_JOB_APPLICANTS });
  }
}

//delete selected portfolio ITEM by id saga

function* deletePortfolioProductRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.DELETE_PORTFOLIO_BY_ID.replace("{version}", version),
    payload
  );

  return res;
}

function* deletePortfolioProduct({ payload }) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_PORTFOLIO_DELETE_ITEM,
      payload: null,
    });
    const response = yield call(deletePortfolioProductRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_PORTFOLIO_DELETE_ITEM,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: JobsReducerTypes.ERROR_PORTFOLIO_DELETE_ITEM,
      payload: e,
    });
  }
}

function* fetchJobDetailsByIdRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_JOB_BY_ID.replace("{version}", version3),
    payload
  );

  return res;
}

function* fetchJobDetailsById({ payload }) {
  try {
    yield put({
      type: JobsReducerTypes.REQUEST_GET_JOB_BY_ID,
      payload: null,
    });
    const response = yield call(fetchJobDetailsByIdRequest, payload);

    yield put({
      type: JobsReducerTypes.RESPONSE_GET_JOB_BY_ID,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: JobsReducerTypes.ERROR_GET_JOB_BY_ID,
      payload: e,
    });
  }
}

function* jobsSaga() {
  yield all([
    takeLatest(JobsReducerTypes.JOB_APPLY_LIST, jobApplyList),
    takeLatest(JobsReducerTypes.JOB_WORK_PLACE_LIST, jobWorkPlaceList),
    takeLatest(JobsReducerTypes.JOB_EXPERIENCE_LIST, jobExperiencesList),
    takeLatest(JobsReducerTypes.JOB_POST_LIST, postjob),
    takeLatest(JobsReducerTypes.ALL_JOBS_LIST, allJobsList),
    takeLatest(
      JobsReducerTypes.ALL_JOBS_LIST_POSTED_BY_USER,
      allJobsListPostedByUser
    ),
    takeLatest(JobsReducerTypes.APPLY_JOBS, applyjob),
    takeLatest(JobsReducerTypes.SAVE_JOBS, saveJob),
    takeLatest(JobsReducerTypes.APPLIED_JOBS_LIST, allJobsListAppliedByUser),
    takeLatest(JobsReducerTypes.SAVED_JOBS_LIST, allJobsListSavedByUser),
    takeLatest(JobsReducerTypes.JOB_RATE_TYPE_LIST, jobRateTypeList),
    takeLatest(JobsReducerTypes.JOB_EDITED, editJobPost),
    takeLatest(JobsReducerTypes.JOB_DRAFTED_LIST, allDraftedJobs),
    takeLatest(JobsReducerTypes.JOB_DELETE, deleteJobPost),
    takeLatest(JobsReducerTypes.JOB_DEACTIVE, deactivateJobPost),
    takeLatest(JobsReducerTypes.JOB_ACTIVE, activateJobPost),
    takeLatest(JobsReducerTypes.JOB_FILTERED_LIST, filteredJob),
    takeLatest(JobsReducerTypes.JOB_SALARY_RANGE_LIST, jobSalaryRange),
    takeLatest(JobsReducerTypes.APPLY_JOB_ON_DC, applyJobOnDC),
    takeLatest(JobsReducerTypes.JOB_TYPE_LIST, getEmployeeJobsList),
    takeLatest(JobsReducerTypes.JOBS_AUTOCOMPLETE, jobsAutocomplete),
    takeLatest(JobsReducerTypes.JOB_APPLICANTS, jobApplicants),
    takeLatest(JobsReducerTypes.PORTFOLIO_DELETE_ITEM, deletePortfolioProduct),
    takeLatest(JobsReducerTypes.GET_JOB_BY_ID, fetchJobDetailsById),
  ]);
}
export default jobsSaga;
