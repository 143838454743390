import { Box, Modal, Typography, useTheme } from "@mui/material";
import { useNewsLetterStyles } from "../../../styles/NewsLetterStyle";
import { NightModeCross, ThanksGraphic } from "../Logo/logos";

const modelStyle = {
  position: "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  borderRadius: 4,
};

export default function MessageModel(props) {
  const { icon, title, subtitle, setOpen, open, clearStates } = props;
  const handleClose = () => {
    setOpen(false);
    clearStates?.();
  };
  const classes = useNewsLetterStyles();
  const theme = useTheme();

  return (
    <Modal open={open}>
      <Box sx={modelStyle} className={classes.modal} container>
        <Box className={classes.headerStyle}>
          <NightModeCross
            className={classes.closeIconStyle}
            onClick={handleClose}
          />
          <ThanksGraphic className={classes.headerIcon} />
        </Box>
        <Box className={classes.iconBox}>
          {icon}
          <Box className={classes.titleBox}>
            <Typography
              className={classes.title}
              sx={{
                fontSize: "30px",
                fontWeight: "bold",
                color: theme?.palette?.mode === "light" ? "#2b2639" : "#fff",
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box className={classes.subtitleBox}>
            <Typography className={classes.subtitle}>{subtitle}</Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
