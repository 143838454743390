/*
 **	User name
 **	Created	5/24/2023
 **	index.js
 */

import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";
import { IRootState } from "app/redux/reducers";
import { useSelector } from "react-redux";
import LanguageSwitcher from "../LanguageSwitcher";
import LogoComponent from "../Logo";

const useStyles = makeStyles((theme) => ({
  footer: {
    background: "#fff",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    minHeight: 255,
    paddingTop: 52,
  },
  header: {
    // backgroundColor: theme.palette.background.default + " !important",
    // color: theme.palette.text.primary + " !important",
    background: "transparent !important",
  },
  linkStyle: {
    fontSize: 15,
    "&:hover": {
      backgroundImage: "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      fontWeight: "bold",
    },
  },
  mainContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    maxWidth: "var(--container-width) !important",
    paddingLeft: 0,
    paddingRight: 0,

    // backgroundColor: "red",
  },
  DClogo: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingLeft: 52,
    paddingRight: 52,
  },
  infoBox: {
    display: "flex",
    flexDirection: "row",
    marginRight: 30,
    paddingTop: 10,
    paddingBottom: 10,
  },
  Switcher: {
    marginLeft: 35,
  },
  termsAndCondition: {
    paddingBottom: 24,
    // backgroundColor: "green",
  },
  Copyright_1: {
    fontSize: 15,
    color: "#2b2639",
    opacity: 0.5,
  },
  Copyright_2: {
    display: "none",
  },
  privacyPolicy: { display: "flex", justifyContent: "flex-end" },

  // Responsiveness
  [theme.breakpoints.down("md")]: {
    mainContainer: {},
    DClogo: {
      margin: "auto",
    },
    infoBox: {
      width: "100% !important",
      margin: "auto !important",
      // backgroundColor: "white",
      justifyContent: "space-between",
    },
    Switcher: {
      margin: "auto",
    },
    termsAndCondition: {
      display: "flex !important",
      flexDirection: "column-reverse !important",
    },
    Copyright_1: {
      display: "none",
    },
    Copyright_2: {
      fontSize: 15,
      color: "#2b2639",
      opacity: 0.5,
      display: "inherit",
      textAlign: "center",
    },
    privacyPolicy: { justifyContent: "space-between" },
  },
}));

const Footer = () => {
  const {
    ReactComponent: Facebook,
  } = require("../../../assets/social-icons/updated_fb.svg");
  const {
    ReactComponent: Instagram,
  } = require("../../../assets/social-icons/updated_insta.svg");
  const {
    ReactComponent: LinkedIn,
  } = require("../../../assets/social-icons/updated_linkedin.svg");

  const classes = useStyles();
  const navigate = useNavigate();

  const year = new Date().getFullYear();
  const userData = useSelector((state: IRootState) => state.register);

  return (
    <Box
      className={classes.footer}
      style={{
        borderTop: "1px solid #ebe3ff",
      }}
    >
      <Container
        className={classes.mainContainer}
        // style={{
        //   paddingTop: 10,
        //   paddingBottom: 10,
        //   maxWidth: "var(--container-width)",
        //   paddingLeft: 0,
        //   paddingRight: 0,

        //   backgroundColor: "red",
        // }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",

            // backgroundColor: "yellow",
          }}
        >
          {/* Logo/menu/Langauge */}
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              flex: 1,
              flexWrap: "wrap",

              // backgroundColor: "blue",
            }}
          >
            <Box
              className={classes.DClogo}
              // style={{
              //   display: "flex",
              //   alignItems: "center",
              //   flexDirection: "column",
              //   paddingLeft: 52,
              //   paddingRight: 52,
              // }}
            >
              <Box>
                <LogoComponent
                  onClick={() => navigate("/")}
                  style={{
                    padding: "0px 0px 5px 0px",
                    width: 84,
                    height: 84,
                    cursor: "pointer",
                  }}
                  isLoggedIn={undefined}
                />
              </Box>
              <Box>
                <Typography>Designers Capitol</Typography>
              </Box>
            </Box>
            {/* Menu */}

            <Box
              className={classes.infoBox}
              // style={{
              //   display: "flex",
              //   flexDirection: "row",
              //   marginRight: 30,
              //   paddingTop: 10,
              //   paddingBottom: 10,
              // }}
            >
              <Box style={{ marginRight: 24 }}>
                <Link className={classes.linkStyle} to="/career-info">
                  Career
                </Link>
              </Box>
              <Box style={{ marginRight: 24 }}>
                <Link className={classes.linkStyle} to="/about-us">
                  About Us
                </Link>
              </Box>
              <Box>
                <Link className={classes.linkStyle} to="/contact-us">
                  Contact Us
                </Link>
              </Box>
            </Box>

            {/* Switcher */}
            <Box
              className={classes.Switcher}
              // style={{
              //   marginLeft: 35,
              // }}
            >
              <LanguageSwitcher />
            </Box>
          </Box>

          {/* Social Links */}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              marginRight: "10px",
            }}
          >
            <Box style={{ marginRight: 24 }}>
              <a
                href="https://www.facebook.com/DesignersCapitol"
                target="_blank"
                rel="noreferrer"
              >
                <Facebook
                  style={{ width: "34px", height: "34", objectFit: "contain" }}
                />
              </a>
            </Box>
            <Box style={{ marginRight: 24 }}>
              <a
                href="https://www.instagram.com/designerscapitol"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
            </Box>
            <a
              href="https://www.linkedin.com/company/designerscapitol/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedIn />
            </a>
          </Box>
        </Box>

        {/* Divider */}
        <Box
          style={{
            height: 1,
            backgroundColor: "#ebe3ff",
            width: "100%",
            marginTop: 24,
            marginBottom: 24,
          }}
        ></Box>

        {/* Copyright and TC&PP links */}
        {/* <Box style={{ display: "flex", flexDirection: "row" }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Box>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                  component="nav"
                  subheader={
                    <ListSubheader className={classes.header} component="div">
                      Company
                    </ListSubheader>
                  }
                >
                  <ListItemButton>
                    <Link
                      style={{ marginRight: 10 }}
                      href="/company-info"
                      underline="none"
                    >
                      <ListItemText primary="Company Info" />
                    </Link>
                  </ListItemButton>
                  <ListItemButton>
                    <Link
                      style={{ marginRight: 10 }}
                      href="/contact-form"
                      underline="none"
                    >
                      <ListItemText primary="Contact Us" />
                    </Link>
                  </ListItemButton>
                </List>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                  component="nav"
                  subheader={
                    <ListSubheader className={classes.header} component="div">
                      Career
                    </ListSubheader>
                  }
                >
                  <ListItemButton>
                    <Link style={{ marginRight: 10 }} href="#" underline="none">
                      <ListItemText primary="ReactJS Developer" />
                    </Link>
                  </ListItemButton>
                  <ListItemButton>
                    <Link style={{ marginRight: 10 }} href="#" underline="none">
                      <ListItemText primary="DotNet Developer" />
                    </Link>
                  </ListItemButton>
                  <ListItemButton>
                    <Link style={{ marginRight: 10 }} href="#" underline="none">
                      <ListItemText primary="UI/UX Designer" />
                    </Link>
                  </ListItemButton>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box> */}

        {/* <Box style={{ display: "flex", alignItems: "center" }}>
          <Box style={{ flex: 1 }}>
            <LanguageSwitcher />
          </Box>
          <Box>
            <Link style={{ marginRight: 5 }} href="#">
              <Icon
                style={{ marginRight: 5 }}
                baseClassName="fab"
                className="fa-facebook"
              ></Icon>
            </Link>
            <Link style={{ marginRight: 5 }} href="#">
              <Icon
                style={{ marginRight: 5 }}
                baseClassName="fab"
                className="fa-instagram"
              ></Icon>
            </Link>
            <Link style={{ marginRight: 5 }} href="#">
              <Icon
                style={{ marginRight: 5 }}
                baseClassName="fab"
                className="fa-twitter"
              ></Icon>
            </Link>
            <Link style={{ marginRight: 5 }} href="#">
              <Icon baseClassName="fab" className="fa-linkedin"></Icon>
            </Link>
          </Box>
        </Box> */}

        <Box>
          <Grid
            container
            spacing={2}
            className={classes.termsAndCondition}
            // style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item sm={12} md={6}>
              {/* <Box style={{ display: "flex", alignItems: "center" }}>
                {[
                  "/assets/images/3d-logo.png",
                  "/assets/images/client-logo.png",
                  "/assets/images/logo2.png",
                ].map((imgSrc) => (
                  <Box key={imgSrc} style={{ marginRight: 10 }}>
                    <img src={imgSrc} alt="logo" style={{ maxWidth: 50 }} />
                  </Box>
                ))}
              </Box> */}
              <Typography
                className={classes.Copyright_1}
                // style={{
                //   fontSize: 15,
                //   color: "#2b2639",
                //   opacity: 0.5,
                //   // textAlign: "center",
                // }}
              >
                Copyright © {year}. Designers Capitol. All rights reserved.
              </Typography>
              <Typography className={classes.Copyright_2}>
                Copyright © {year}. Designers Capitol
              </Typography>
              <br />
              <Typography className={classes.Copyright_2}>
                All rights reserved.
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Box
                className={classes.privacyPolicy}
                // style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Link
                  style={{ marginRight: 60 }}
                  to="/terms-and-conditions"
                  className={classes.linkStyle}
                  onClick={() =>
                    window.sessionStorage.setItem("isLicenseView", "false")
                  }
                >
                  Terms & Conditions
                </Link>
                <Link
                  to={
                    userData?.loginStatus
                      ? "/privacy-policy"
                      : "/privacy-policy?guestUser"
                  }
                  className={classes.linkStyle}
                >
                  Privacy Policy
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
