/*
 **	User name
 **	Created	5/25/2023
 **	LibraryUploadRightSection.tsx
 */

import React, { useContext } from "react";
import {
  Box,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextareaAutosize,
  Chip,
} from "@mui/material";
import { ExpandMoreRounded } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { IRootState } from "app/redux/reducers";

import { CheckboxField } from "app/components/UIFormFields";
import CategoryComponent from "app/components/CategoryComponent";
import { LibraryProductUploadContext } from "context/LibraryProductUploadContext";

import useUploadLibraryProductStyles from "./useUploadLibraryProductStyles";

const LibraryUploadRightSection = ({ isEditMode }) => {
  const classes = useUploadLibraryProductStyles();
  const { productInfo, setProductInfo } = useContext(
    LibraryProductUploadContext
  );
  const filterTypes = useSelector((state: IRootState) => state.filterTypes);

  const tools = filterTypes?.toolTypeList;
  const tagList = filterTypes?.productTagList;
  const {
    ReactComponent: DeleteIconChip,
  } = require("../../../../assets/icons/Icons_Light_Cross_White_Icon.svg");

  const handleChange = (e) => {
    e.preventDefault();
    e.target.name === "title" &&
      setProductInfo({ ...productInfo, title: e.target.value });
    e.target.name === "description" &&
      setProductInfo({ ...productInfo, description: e.target.value });

    if (e.target.name === "tools") {
      const temp = productInfo?.tools?.filter(
        (item) => item === e.target.value
      );

      temp.length === 0
        ? setProductInfo({ ...productInfo, tools: e.target.value })
        : productInfo?.tools?.filter(
            (item) => item !== e.target.value[e.target.value.length - 1]
          );
    }

    if (e.target.name === "tags") {
      const temp = productInfo?.tags?.filter((item) => item === e.target.value);

      temp.length === 0
        ? setProductInfo({ ...productInfo, tags: e.target.value })
        : productInfo?.tags?.filter(
            (item) => item !== e.target.value[e.target.value.length - 1]
          );
    }
  };

  const [showInfo1, setShowInfo1] = React.useState(false);
  const clk = () => {
    if (!showInfo1) {
      setShowInfo1(true);
    } else setShowInfo1(false);
  };
  const renderChips = (selected, type) => {
    const tools = filterTypes?.toolTypeList;
    const tagList = filterTypes?.productTagList;

    const mainData = (() => {
      if (type == "tools") {
        return tools;
      } else {
        return tagList;
      }
    })();

    function renderChipsValue() {
      return selected.map((value, index) => {
        if (index < 2) {
          const label = (() => {
            const findedData = mainData.find((item) => {
              if (item.toolDetailId && item.toolDetailId === value) {
                return true;
              }
              if (item.productTagID && item.productTagID === value) {
                return true;
              }
            });

            return findedData?.toolName || findedData?.productTagName;
          })();

          return (
            <Box key={index}>
              <Chip
                sx={{
                  background: "#2b2639",
                  color: "#fff",
                  borderRadius: "6px",
                  fontSize: 15,
                }}
                key={index}
                label={label}
                onDelete={() => handleDelete(value, index)}
                deleteIcon={<DeleteIconChip />}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            </Box>
          );
        }
      });
    }

    if (selected.length < 3) {
      return (
        <Box gap={0.5} className={classes.randerValueBox}>
          {renderChipsValue()}
        </Box>
      );
    } else {
      return (
        <Box gap={0.5} className={classes.randerValueBox}>
          {renderChipsValue()}
          <span
            className={classes.randerValueSpan}
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            onClick={clk}
          >
            +{selected.length - 2}
          </span>
        </Box>
      );
    }
  };

  const handleDelete = (value, index) => {
    const temp = productInfo?.tools?.indexOf(value) > -1;
    const temp2 =
      temp === true
        ? productInfo?.tools.filter((item) => item !== value)
        : productInfo?.tags.filter((item) => item !== value);

    temp === true
      ? setProductInfo({ ...productInfo, tools: temp2 })
      : setProductInfo({ ...productInfo, tags: temp2 });
  };

  return (
    <Box>
      {/* Library Title */}
      <TextField
        id="outlined-basic"
        label="Library Title*"
        variant="outlined"
        name="title"
        sx={{ width: "100%", marginBottom: "20px" }}
        onChange={handleChange}
        value={productInfo.title}
      />
      {/* Tools */}
      <FormControl fullWidth>
        <InputLabel id="id">Tools*</InputLabel>
        <Select
          sx={{ marginBottom: "20px" }}
          labelId="id"
          id="id"
          multiple
          value={productInfo.tools}
          onChange={handleChange}
          label="Tools*"
          name="tools"
          renderValue={(selected) => renderChips(selected, "tools")}
          IconComponent={ExpandMoreRounded}
          MenuProps={{
            MenuListProps: { disablePadding: true },
            PaperProps: {
              sx: {
                maxHeight: 250,
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "white",
                },
              },
            },
          }}
        >
          <Typography
            className={classes.reset}
            onClick={() => setProductInfo({ ...productInfo, tools: [] })}
          >
            Reset
          </Typography>
          {tools?.map((option) => (
            <MenuItem
              key={option.toolDetailId}
              value={option.toolDetailId}
              sx={{
                borderBottom: 1.9,
                borderColor: "#d7d0e8",
                padding: "18px 0 16px 10px",
              }}
            >
              <CheckboxField
                checked={productInfo?.tools?.indexOf(option.toolDetailId) > -1}
                style={{ padding: "0px 16px 0px 10px" }}
              />
              <span
                style={{
                  color:
                    productInfo?.tools.includes(option.toolDetailId) &&
                    "#7a48ff",
                }}
              >
                {option.toolName}
              </span>
              {/* {option.toolName} */}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Tags */}
      <FormControl fullWidth>
        <InputLabel id="id">Tags</InputLabel>
        <Select
          sx={{ marginBottom: "20px" }}
          labelId="id"
          id="id"
          multiple
          value={productInfo.tags}
          onChange={handleChange}
          label="tags"
          name="tags"
          renderValue={(selected) => renderChips(selected, "tags")}
          IconComponent={ExpandMoreRounded}
          MenuProps={{
            MenuListProps: { disablePadding: true },
            PaperProps: {
              sx: {
                maxHeight: 250,
                "& .MuiMenuItem-root.Mui-selected": {
                  backgroundColor: "white",
                },
              },
            },
          }}
        >
          <Typography
            className={classes.reset}
            onClick={() => setProductInfo({ ...productInfo, tags: [] })}
          >
            Reset
          </Typography>
          {tagList?.map((option) => (
            <MenuItem
              key={option.productTagID}
              value={option.productTagID}
              sx={{
                borderBottom: 1.9,
                borderColor: "#d7d0e8",
                padding: "18px 0 16px 10px",
              }}
            >
              <CheckboxField
                checked={productInfo?.tags?.indexOf(option.productTagID) > -1}
                style={{ padding: "0px 16px 0px 10px" }}
              />
              <span
                style={{
                  color:
                    productInfo?.tags.includes(option.productTagID) &&
                    "#7a48ff",
                }}
              >
                {option.productTagName}
              </span>
              {/* {option.toolName} */}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Description */}
      <TextField
        id="outlined-basic"
        label="Description*"
        variant="outlined"
        name="description"
        sx={{ width: "100%" }}
        onChange={handleChange}
        InputProps={{
          inputComponent: TextareaAutosize,
          inputProps: {
            minRows: 8,
            maxLength: 1500,
            style: {
              resize: "vertical",
            },
          },
        }}
        value={productInfo?.description}
      />
      <Typography
        sx={{
          color: "#a49bbe",
          fontWeight: "bold",
          textAlign: "end",
          marginTop: "5px",
        }}
      >
        {productInfo?.description?.length === 0
          ? "Note: Max character limit 1500"
          : `${productInfo?.description?.length}/1500`}
      </Typography>

      <hr style={{ color: "#d9d9d9" }} />
      <CategoryComponent isEditMode={isEditMode} />
    </Box>
  );
};

export default LibraryUploadRightSection;
