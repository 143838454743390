import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import {
  productCommentsActions,
  productCommentsActionTypes,
} from "../reducers/productReducer/products-comments";

const version = process.env.REACT_APP_API_VERSION_3;

function* fetchProductCommentsRequest(payload) {
  let urlNew = EndPoints.GET_PRODUCT_COMMENTS;

  if (payload.isLibraryProduct) {
    urlNew = EndPoints.GET_LIBRARY_PRODUCT_COMMENTS;
  }

  const res = yield request(
    "get",
    urlNew.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchProductComments({ payload }: any) {
  try {
    yield put(productCommentsActions.fetchProductCommentsRequest());
    const response = yield call(fetchProductCommentsRequest, payload);

    yield put(
      productCommentsActions.fetchProductCommentsSuccess(response?.data)
    );
  } catch (e) {
    yield put(productCommentsActions.fetchProductCommentsFailure(e));
  }
}

function* addProductCommentsRequest(payload) {
  let urlNew = EndPoints.SAVE_PRODUCT_COMMENTS;

  if (payload.isLibraryProduct) {
    urlNew = EndPoints.SAVE_LIBRARY_PRODUCT_COMMENTS;
  }

  const res = yield request(
    "post",
    urlNew.replace("{version}", version),
    payload
  );

  return res;
}

function* addProductComments({ payload }: any) {
  try {
    yield put(productCommentsActions.addProductCommentRequest());
    const response = yield call(addProductCommentsRequest, payload);

    if (response?.data?.responseCode === 200) {
      yield put(productCommentsActions.addProductCommentSuccess(response.data));
    } else {
      yield put(
        productCommentsActions.addProductCommentFailure(response?.data)
      );
    }
    yield put(productCommentsActions.fetchProductComments(payload));
  } catch (e) {
    yield put(productCommentsActions.addProductCommentFailure(e));
  }
}

function* removeProductCommentFunRequest(payload) {
  const res = yield request(
    "delete",
    EndPoints.DELETE_PRODUCT_COMMENT_BY_ID.replace("{version}", version),
    payload
  );

  return res;
}

function* removeProductCommentFun({ payload }: any) {
  try {
    yield put(productCommentsActions.removeProductCommentRequest());
    const response = yield call(removeProductCommentFunRequest, payload);

    yield put(
      productCommentsActions.removeProductCommentSuccess(response?.data)
    );
    yield put(productCommentsActions.fetchProductComments(payload));
  } catch (e) {
    yield put(productCommentsActions.removeProductCommentFailure(e));
  }
}

function* combinedSaga() {
  yield all([
    takeLatest(
      productCommentsActionTypes.fetchProductComments,
      fetchProductComments
    ),
    takeLatest(
      productCommentsActionTypes.addProductComment,
      addProductComments
    ),
    takeLatest(
      productCommentsActionTypes.removeProductComment,
      removeProductCommentFun
    ),
  ]);
}

export default combinedSaga;
