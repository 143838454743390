/*
 **	User name
 **	Created	5/25/2023
 **	index.tsx
 */

import React from "react";
import { Link } from "react-router-dom";

export default function LogoComponent({
  isLoggedIn,
  style = {},
  isLoginStyle = false,
  ...rest
}) {
  const {
    ReactComponent: LogoLoginStyleSvg,
  } = require("../../../assets/logo/Icons_Light_Favicon.svg");

  const {
    ReactComponent: LogoSvg,
  } = require("../../../assets/logo/Icons_Light_New_Logo.svg");

  if (isLoginStyle) {
    return (
      <Link to="/">
        <LogoLoginStyleSvg
          style={{
            cursor: "pointer",
            ...style,
          }}
          {...rest}
        />
      </Link>
    );
  }
  return (
    <Link to={"/"}>
      <LogoSvg style={style} {...rest} />
    </Link>
  );
}
