const initState = {
  loading: false,
  data: null,
  error: null,
};

export const uploadDesignReducerTypes = {
  UPLOAD_DESIGN: "UPLOAD_DESIGN",
  REQUEST_UPLOAD_DESIGN: "REQUEST_UPLOAD_DESIGN",
  RESPONSE_UPLOAD_DESIGN: "RESPONSE_UPLOAD_DESIGN",
  ERROR_UPLOAD_DESIGN: "ERROR_UPLOAD_DESIGN",
  CLEAR_UPLOAD_DESIGN: "CLEAR_UPLOAD_DESIGN",

  EDIT_DESIGN: "EDIT_DESIGN",
  REQUEST_EDIT_DESIGN: "REQUEST_EDIT_DESIGN",
  RESPONSE_EDIT_DESIGN: "RESPONSE_EDIT_DESIGN",
  ERROR_EDIT_DESIGN: "ERROR_EDIT_DESIGN",
  CLEAR_EDIT_DESIGN: "CLEAR_EDIT_DESIGN",
};

export function uploadDesignReducer(state = initState, action) {
  switch (action.type) {
    case uploadDesignReducerTypes.REQUEST_UPLOAD_DESIGN:
      return { ...state, loading: true };
    case uploadDesignReducerTypes.RESPONSE_UPLOAD_DESIGN:
      return { error: null, loading: false, data: action.payload };
    case uploadDesignReducerTypes.ERROR_UPLOAD_DESIGN:
      return { loading: false, data: null, error: action.payload };
    case uploadDesignReducerTypes.CLEAR_UPLOAD_DESIGN:
      return { loading: false, data: null, error: null };

    case uploadDesignReducerTypes.REQUEST_EDIT_DESIGN:
      return { ...state, loading: true };
    case uploadDesignReducerTypes.RESPONSE_EDIT_DESIGN:
      return { error: null, loading: false, data: action.payload };
    case uploadDesignReducerTypes.ERROR_EDIT_DESIGN:
      return { loading: false, data: null, error: action.payload };
    case uploadDesignReducerTypes.CLEAR_EDIT_DESIGN:
      return { loading: false, data: null, error: null };

    default:
      return state;
  }
}
