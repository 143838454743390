/*
 **	User name
 **	Created	5/25/2023
 **	attach3dFiles.tsx
 */

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "app/redux/reducers";
import { BlackButton } from "../UIFormFields/Buttons";

const useAdd3dStyles = makeStyles((theme) => ({
  button: {
    width: "100% !important",
    height: "54px !important",
    borderRadius: "none !important",
  },
}));

export default function Attach3dFiles(props) {
  const {
    setFileUploaded,
    fileUplaoded,
    getCategoryText,
    onUploadProgress,
    getCategoryID,
    uploadType,
  } = props;
  const attachFileRef = useRef<HTMLInputElement>();
  const dispatch = useDispatch();
  const classes = useAdd3dStyles();
  const userData = useSelector((state: IRootState) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;
  const uploadProductFile = useSelector(
    (state: IRootState) => state.uploadProductFile
  );

  const onUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const arrImage = [];
    const arr3d = [];
    const multipleTypes = [];

    const allFiles = Array.from(event.target.files);

    allFiles.forEach((element) => {
      const fileType = element.type;
      const fileTypeName = element.name.split(".");

      if (
        ["obj*", "glb", "blend", "fbx", "gltf", "obj", "mtl", "zprj"].includes(
          fileTypeName[1]
        ) &&
        getCategoryText === "3D Files"
      ) {
        arr3d.push(element);
      } else if (
        (fileType === "image/png" ||
          fileType === "image/jpeg" ||
          fileType === "image/*") &&
        getCategoryText === "Images"
      ) {
        arrImage.push(element);
      } else if (
        ["Pattern", "Art work", "Sketch", "Other Files"].includes(
          getCategoryText
        )
      ) {
        multipleTypes.push(element);
      } else alert("check file format");
    });

    // arr?.forEach((element) => {
    //   let params = new FormData();
    //   params.append("UploadedFileName", "");
    //   params.append("UploadedFile", element);
    //   params.append("AccountGuid", accountGuid);
    //   params.append("UploadedCategoryType", getCategoryID);
    //   params.append("ProductId", uploadProductFile?.dataThumbnail?.id);
    //   params.append("Description", "");
    //   params.append("ToolsList", "");
    //   params.append("TagsList", "");
    //   params.append("ProductCategoryList", "");
    //   params.append("ProductUploadFilesWithTypeID", 1);
    //   // arrOfParam.push(params)

    //   dispatch({
    //     type: uploadProductFileReducerTypes.UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
    //     payload: params,
    //     onUploadProgress,
    //   });
    // })

    if (uploadType._3D) {
      setFileUploaded({
        ...fileUplaoded,
        _3dFileUpload: [...fileUplaoded._3dFileUpload, ...arr3d],
      });
    }

    if (uploadType.pattern) {
      setFileUploaded({
        ...fileUplaoded,
        patternUpload: [...fileUplaoded.patternUpload, ...multipleTypes],
      });
    }

    if (uploadType.artWork) {
      setFileUploaded({
        ...fileUplaoded,
        artWorkUpolad: [...fileUplaoded.artWorkUpolad, ...multipleTypes],
      });
    }
    if (uploadType.sketch) {
      setFileUploaded({
        ...fileUplaoded,
        sketchUpload: [...fileUplaoded.sketchUpload, ...multipleTypes],
      });
    }

    if (uploadType.image) {
      setFileUploaded({
        ...fileUplaoded,
        imageUpload: [...fileUplaoded.imageUpload, ...arrImage],
      });
    }

    if (uploadType.otherFiles) {
      setFileUploaded({
        ...fileUplaoded,
        otherFileUpload: [...fileUplaoded.otherFileUpload, ...multipleTypes],
      });
    }
  };

  const {
    ReactComponent: LightAddIcon,
  } = require("../../../assets/icons/Icons_Light_Add Icon.svg");

  return (
    <BlackButton
      className={classes.button}
      onClick={() => attachFileRef.current.click()}
    >
      <Box>
        <input
          style={{ display: "none", width: "30px" }}
          accept="*"
          id="icon-button-file"
          multiple
          ref={attachFileRef}
          type="file"
          hidden
          onChange={onUploadFile}
        />
      </Box>
      <LightAddIcon style={{ marginRight: "10px" }} />
      Upload {getCategoryText ? getCategoryText : "3D Files"}
    </BlackButton>
  );
}
