/*
 **	User name
 **	Created	5/25/2023
 **	index.tsx
 */

import React, { useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { uploadDesignReducerTypes } from "app/redux/reducers/productReducer/uploadDesign";
import { IRootState } from "app/redux/reducers";
import { isIndividualSelector } from "app/redux/selectors/user-selector";
import { useAppSelector } from "app/utils/hooks";
import { AppPermissionsContext } from "context/AppPermissionsContext";
import { libraryActionTypes } from "app/redux/reducers/library";
import { designerProductsReducerTypes } from "app/redux/reducers/productReducer/product-main";
import { LightModeCross, NightModeCross } from "../Logo/logos";

export const useUpload3dModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 632,
    maxHeight: "95vh",
    height: 380,
    maxWidth: "80%",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 28,
    right: 24,
    position: "absolute",
  },
  titleBox: {
    margin: "54px 0px 0px 0px",
  },
}));

export default function ProductVisibilityModal({
  open,
  handleClose,
  productData,
}) {
  const dispatch = useDispatch();
  const classes = useUpload3dModalStyles();
  const theme = useTheme();
  const userData = useSelector((state: IRootState) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;
  const visibilityId = productData?.visibilityId || "1";

  const [localVisibilityID, setLocalVisibilityID] = React.useState("1");

  useEffect(() => {
    setLocalVisibilityID(visibilityId);
  }, [visibilityId]);

  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };

  const {
    ReactComponent: WebIcon,
  } = require("../../../assets/icons/designer-navbar/Icons_Light_Website Icon.svg");

  const {
    ReactComponent: LockIcon,
  } = require("../../../assets/icons/designer-navbar/Icons_Light_Lock Icon.svg");

  const {
    ReactComponent: TeamIcon,
  } = require("../../../assets/icons/designer-navbar/Icons_Light_Team Icon.svg");

  const handleChange = (VisibilityId) => {
    const params = new FormData();

    params.append("ProductId", productData?.productID);
    params.append("VisibilityId", VisibilityId);
    params.append("AccountGuid", accountGuid);
    params.append("ProductTitle", productData?.productName);
    params.append("ProductPrice", productData?.productPrice);
    params.append("IsPublished", "true");

    const params_Library = new FormData();

    params_Library.append("ProductLibraryId", productData?.productLibraryId);
    params_Library.append("VisibilityId", VisibilityId);
    params_Library.append("AccountGuid", accountGuid);
    params_Library.append("LibraryItemTitle", productData?.libraryItemTitle);
    params_Library.append("IsPublished", "true");
    params_Library.append("Price", productData?.price);

    dispatch({
      type: productData?.productID
        ? uploadDesignReducerTypes.EDIT_DESIGN
        : libraryActionTypes.LIBRARY_UPLOAD,

      payload: productData?.productID ? params : params_Library,
    });

    productData?.productID
      ? ""
      : dispatch({
          type: designerProductsReducerTypes.DESIGNER_PRODUCT,
          payload: {
            accountGuid,
            loggedInGuid: accountGuid,
            isLibraryProduct: true,
          },
        });
  };
  const isIndividual = useAppSelector(isIndividualSelector);
  const { permissions } = useContext(AppPermissionsContext);

  const Public = {
    icon: WebIcon,
    id: "1",
    title: "Public",
    desc: "Product visible to anyone on Designers Capitol",
  };

  const Private = {
    icon: LockIcon,
    id: "2",
    title: "Private",
    desc: "Product visible to you only",
  };

  const Team = {
    icon: TeamIcon,
    id: "3",
    title: "Team",
    desc: "Product visible to your team members on Designers Capitol",
  };

  // const visibiityArray = isIndividual
  //   ? [Public, Private]
  //   : permissions?.productSettings?.uploadPublic
  //   ? [Public, Private, Team]
  //   : [Private, Team];

  const companyEmployeeRoleId =
    userData?.userAdditionalInfo?.companyEmployeeRoleId;

  const visibiityArray = isIndividual
    ? [Public, Private]
    : permissions?.productSettings?.uploadPublic
    ? companyEmployeeRoleId === 3
      ? [Public, Private]
      : [Public, Private, Team]
    : companyEmployeeRoleId === 3
    ? [Private]
    : [Private, Team];

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal open={open}>
        <Box
          className={classes.modalStyle}
          style={{
            backgroundColor: theme?.palette?.mode === "light" && "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box className={classes.closeBox}>
              {theme?.palette?.mode === "light" ? (
                <LightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <NightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>

            <Box className={classes.titleBox}>
              <Typography
                style={
                  theme?.palette?.mode === "light"
                    ? heading.lightMode
                    : heading.nightMode
                }
              >
                {"Select Visibility"}
              </Typography>
            </Box>
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                paddingLeft: "54px",
                paddingRight: 54,
                width: "100%",
                color: "#2b2639",
                textAlign: "left",
              }}
            >
              <Box style={{ width: "100%" }}>
                {visibiityArray.map((item) => {
                  const Icon = item.icon;

                  return (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 16,
                        cursor: "pointer",
                        ...(item.id !== "3" && {
                          borderBottom: "1px solid #d7d0e8",
                        }),
                      }}
                      onClick={() => handleChange(item.id)}
                    >
                      <Box>
                        <Icon />
                      </Box>
                      <Box
                        style={{
                          flex: 1,
                          paddingLeft: "16px",
                          marginBottom: "16px",
                        }}
                      >
                        <Box style={{ color: "#2b2639", fontWeight: "bold" }}>
                          {item.title}
                        </Box>
                        <Box
                          style={{
                            color: "#a49bbe",
                            fontSize: "13px",
                            width: "387px",
                            height: "20px",
                          }}
                        >
                          {item.desc}
                        </Box>
                      </Box>
                      <Box>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={
                              localVisibilityID == item.id ? true : false
                            }
                            style={{ cursor: "inherit" }}
                          />
                        </div>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
