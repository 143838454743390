const initialState = {
  employeesListModal: false,
  inviteEmployeesModal: false,
  addMembersAlertModal: false,
};

export const employeesModalPreviewType = {
  OPEN_EMPLOYEES_LIST_MODAL: "OPEN_EMPLOYEES_LIST_MODAL",
  CLOSE_EMPLOYEES_LIST_MODAL: "CLOSE_EMPLOYEES_LIST_MODAL",
  OPEN_EMPLOYEES_INVITE_MODAL: "OPEN_EMPLOYEES_INVITE_MODAL",
  CLOSE_EMPLOYEES_INVITE_MODAL: "CLOSE_EMPLOYEES_INVITE_MODAL",
  OPEN_ADD_MEMBERS_ALERT_MODAL: "OPEN_ADD_MEMBERS_ALERT_MODAL",
  CLOSE_ADD_MEMBERS_ALERT_MODAL: "CLOSE_ADD_MEMBERS_ALERT_MODAL",
};

export function employeesModalPreview(state = initialState, action) {
  switch (action.type) {
    case employeesModalPreviewType.OPEN_EMPLOYEES_LIST_MODAL:
      return {
        ...initialState,
        employeesListModal: true,
      };
    case employeesModalPreviewType.CLOSE_EMPLOYEES_LIST_MODAL:
      return {
        ...initialState,
        employeesListModal: false,
      };
    case employeesModalPreviewType.OPEN_EMPLOYEES_INVITE_MODAL:
      return {
        ...state,
        inviteEmployeesModal: true,
      };
    case employeesModalPreviewType.CLOSE_EMPLOYEES_INVITE_MODAL:
      return {
        ...state,
        inviteEmployeesModal: false,
      };
    case employeesModalPreviewType.OPEN_ADD_MEMBERS_ALERT_MODAL:
      return {
        ...state,
        addMembersAlertModal: true,
      };
    case employeesModalPreviewType.CLOSE_ADD_MEMBERS_ALERT_MODAL:
      return {
        ...state,
        addMembersAlertModal: false,
      };

    default:
      return state;
  }
}
