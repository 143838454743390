/*
 **	User name
 **	Created	5/25/2023
 **	singleItemView.tsx
 */

import React, { useEffect, useState } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { isImage } from "app/utils/helper";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "app/redux/reducers";
import ModelViewer from "app/components/ModelViewer";
import { uploadProductFileReducerTypes } from "../../../redux/reducers/productReducer/uploadProductFile";
import { CheckboxField } from "../../UIFormFields";
import { useUpload3dModalStyles } from "./modal";

export const SingleItem = (props) => {
  const {
    item,
    index,
    accountGuid,
    handleSelectionAttachment,
    handleSelectAllItem,
    handleEditItem,
    handlePopoverOpen,
    openHoverPopup,
    handlePopoverClose,
    getCategoryID,
    individualProductData,
    returnFirstPogress,
    isEditProduct,
    // returnGetUploadedType,
    uploadType,
    forPreview,
    setFileUploaded,
    selectedFileUplaoded,
    setSelectedFileUplaoded,
    fileUplaoded,
    setThumbnail,
    array,
  } = props;
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const classes = useUpload3dModalStyles();
  const uploadFiles = useSelector(
    (state: IRootState) => state.uploadProductFile
  );
  const detailsOfProduct = useSelector(
    (state: IRootState) => state.productWithUploadType
  );
  const productDetails = detailsOfProduct?.data;

  function onUploadProgress(progressEvent) {
    const _progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );

    setProgress(_progress);
    // dispatch({
    //     type: uploadProductFileReducerTypes.SET_UPLOAD_PRODUCT_FILE_PROGRESS_FOR_DIFF_CATEGORIES,
    //     payload: parseInt(progress),
    // });
  }

  const handleTumbnail = (item) => {
    setThumbnail(array.length - item.index - 1);
  };

  const handleDeleteItem = (value) => {
    let TempArr = [];

    if (uploadType.image) {
      TempArr = selectedFileUplaoded.selectedImageUpload;
    } else if (uploadType._3D) {
      TempArr = selectedFileUplaoded.selected3dFileUpload;
    } else if (uploadType.artWork) {
      TempArr = selectedFileUplaoded.selectedArtWorkUpolad;
    } else if (uploadType.otherFiles) {
      TempArr = selectedFileUplaoded.selectedOtherFileUpload;
    } else if (uploadType.pattern) {
      TempArr = selectedFileUplaoded.selectedPatternUpload;
    } else if (uploadType.sketch) {
      TempArr = selectedFileUplaoded.selectedSketchUpload;
    }

    const filteredArray = TempArr.filter((item) => item.isSelected === false);

    if (uploadType.image) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedImageUpload: filteredArray,
      });
      setFileUploaded({ ...fileUplaoded, imageUpload: filteredArray });
    } else if (uploadType._3D) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selected3dFileUpload: filteredArray,
      });
      setFileUploaded({ ...fileUplaoded, _3dFileUpload: filteredArray });
    } else if (uploadType.artWork) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedArtWorkUpolad: filteredArray,
      });
      setFileUploaded({ ...fileUplaoded, artWorkUpolad: filteredArray });
    } else if (uploadType.otherFiles) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedOtherFileUpload: filteredArray,
      });
      setFileUploaded({ ...fileUplaoded, otherFileUpload: filteredArray });
    } else if (uploadType.pattern) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedPatternUpload: filteredArray,
      });
      setFileUploaded({ ...fileUplaoded, patternUpload: filteredArray });
    } else if (uploadType.sketch) {
      setSelectedFileUplaoded({
        ...selectedFileUplaoded,
        selectedSketchUpload: filteredArray,
      });
      setFileUploaded({ ...fileUplaoded, sketchUpload: filteredArray });
    }
  };

  useEffect(() => {
    if (!forPreview) {
      const params = new FormData();

      params.append("UploadedFileName", "");
      params.append("UploadedFile", item);
      params.append("AccountGuid", accountGuid);
      params.append("UploadedCategoryType", getCategoryID);
      params.append(
        "ProductId",
        uploadFiles?.dataThumbnail?.id ||
          uploadFiles?.dataDiffCategories?.id ||
          individualProductData?.productID ||
          individualProductData?.productDetailedEntity?.productID ||
          productDetails?.productDetailedEntity?.productID
      );
      params.append("Description", "");
      params.append("ToolsList", "");
      params.append("TagsList", "");
      params.append("ProductCategoryList", "");
      params.append("ProductUploadFilesWithTypeID", "0");

      dispatch({
        type: uploadProductFileReducerTypes.UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
        payload: params,
        onUploadProgress,
        // length: parseInt(arr.length)
      });
    }
  }, [item]);

  const {
    ReactComponent: EditIcon,
  } = require("../../../../assets/icons/Icons_Light_Edit Icon.svg");
  const {
    ReactComponent: DeleteIcon,
  } = require("../../../../assets/icons/Icons_Dark_Delete Icon.svg");
  const {
    ReactComponent: SelectAllItem,
  } = require("../../../../assets/icons/Icons_Light_Select All Icon.svg");
  const {
    ReactComponent: Tumbnail,
  } = require("../../../../assets/icons/Icons_Light_Thumbnail.svg");

  // isImage(uploadProductFile?.dataDiffCategories?.urlDetails)

  useEffect(() => {
    returnFirstPogress(progress);
  }, [index === 0]);

  const returnFileTypeHandler = (value) => {
    if (isEditProduct)
      return (
        (value?.fileUploadedType ?? null)?.toUpperCase() === ".PNG" ||
        isImage(uploadFiles?.dataDiffCategories?.urlDetails) !== null
      );
    else return value?.type?.includes("image/");
    // isImage(uploadFiles?.dataDiffCategories?.urlDetails) !== null;
  };

  const returnFileHandler = (value) => {
    try {
      if (value) {
        return URL.createObjectURL(value);
      }
    } catch (error) {
      return uploadFiles?.dataDiffCategories?.urlDetails;
    }
    if (isEditProduct)
      return (
        value?.uploadedFileUrl || uploadFiles.dataDiffCategories.urlDetails
      );
    else return uploadFiles.dataDiffCategories.urlDetails;
  };

  const returnLoaderPrevieHandler = () => {
    if (forPreview) return true;
    else
      return (
        (uploadFiles?.dataDiffCategories?.responseCode ?? 0) === 200 &&
        (progress ?? 0) === 100
      );
  };

  const nameFunction = () => {
    return (
      (item?.fileName || item?.name?.split(".")[0])
        .substr(0, 10)
        .concat(".")
        .concat(item?.name?.split(".")[1]) ??
      ((uploadType?._3D && "3D Model") ||
        (uploadType?.pattern && "Pattern") ||
        (uploadType?.artWork && "Artwork") ||
        (uploadType?.sketch && "Sketch") ||
        (uploadType?.image && "Image") ||
        (uploadType?.otherFiles && "Other Files"))
    );
  };

  const previewNotAvailable =
    "https://www.justbellydance.com/events/images/no-preview.png";

  const singelItemView = () => {
    return (
      <Box className={classes.smallViewPort}>
        {returnLoaderPrevieHandler() ? (
          returnFileTypeHandler(item) ? (
            <Box
              style={{
                position: "relative",
              }}
            >
              <Tooltip
                placement="bottom"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "transparent",
                    },
                  },
                }}
                title={
                  item?.isSelected ? (
                    <Box className={classes.toolTipStyle}>
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditItem({ item, index })}
                      />
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteItem({ item, index })}
                      />
                      <SelectAllItem
                        onClick={() => handleSelectAllItem()}
                        style={{ cursor: "pointer" }}
                      />
                      <Tumbnail
                        style={{ cursor: "pointer" }}
                        onClick={() => handleTumbnail({ item, index })}
                      />
                    </Box>
                  ) : (
                    <Box className={classes.toolTipStyleForImageHover}>
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "6px",
                        }}
                        src={returnFileHandler(item) || previewNotAvailable}
                        alt="..."
                      />
                    </Box>
                  )
                }
              >
                <img
                  src={returnFileHandler(item) || previewNotAvailable}
                  alt="product"
                  style={{
                    // maxWidth: "606px",
                    width: "100%",
                    height: "150px",
                    border: "2px solid #ccc",
                    borderRadius: "6px",
                    margin: "16px 16px 0px 0px",
                  }}
                  aria-owns={openHoverPopup ? "mouse-over-popover" : undefined}
                  onMouseEnter={(e) => handlePopoverOpen(e, index)}
                  onMouseLeave={handlePopoverClose}
                />
              </Tooltip>
              <CheckboxField
                defaultChecked={item?.isSelected}
                key={index}
                onClick={() => handleSelectionAttachment(item, index)}
                style={{
                  position: "absolute",
                  top: 15,
                  left: 0,
                }}
              />
              <Box className={classes.smallViewPortTitleBox}>
                <Typography className={classes.smallViewPortTitle}>
                  {nameFunction()}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box style={{ position: "relative" }}>
              <Tooltip
                placement="bottom"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "transparent",
                    },
                  },
                }}
                title={
                  item?.isSelected ? (
                    <Box className={classes.toolTipStyle}>
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleEditItem({ item, index })}
                      />
                      <DeleteIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDeleteItem({ item, index })}
                      />
                      <SelectAllItem
                        onClick={() => handleSelectAllItem()}
                        style={{ cursor: "pointer" }}
                      />
                      <Tumbnail
                        // style={{ cursor: "pointer" }}
                        onClick={() => handleTumbnail({ item, index })}
                      />
                    </Box>
                  ) : (
                    <Box className={classes.toolTipStyleForImageHover}>
                      <ModelViewer
                        style={{
                          height: "242px !important",
                          width: "100%",
                          // maxHeight: "394px",
                        }}
                        src={returnFileHandler(item) || previewNotAvailable}
                        ios-src=""
                        alt="model name"
                        camera-controls
                        ar
                        ar-modes="webxr scene-viewer quick-look"
                        environment-image="neutral"
                        oncontextmenu="return false;"
                        autoplay
                      ></ModelViewer>
                    </Box>
                  )
                }
              >
                <ModelViewer
                  style={{
                    border: "2px solid #ccc",
                    borderRadius: "6px",
                    margin: "16px 16px 0px 0px",
                    height: "135px !important",
                    width: "100%",
                    // maxHeight: "394px",
                  }}
                  src={returnFileHandler(item)}
                  ios-src=""
                  alt="model name"
                  camera-controls
                  ar
                  ar-modes="webxr scene-viewer quick-look"
                  environment-image="neutral"
                  oncontextmenu="return false;"
                  autoplay
                  aria-owns={openHoverPopup ? "mouse-over-popover" : undefined}
                  onMouseEnter={(e) => handlePopoverOpen(e, index)}
                  onMouseLeave={handlePopoverClose}
                ></ModelViewer>
              </Tooltip>
              <CheckboxField
                defaultChecked={item.isSelected}
                onClick={() => handleSelectionAttachment(item)}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
              <Box className={classes.smallViewPortTitleBox}>
                <Typography className={classes.smallViewPortTitle}>
                  {nameFunction()}
                </Typography>
              </Box>
            </Box>
          )
        ) : (
          <Box
            style={{
              border: index === 0 ? "2px #ccc dashed" : "",
              padding: " 3px",
              borderRadius: "6px",
            }}
          >
            <Box
              style={{
                borderRadius: "6px",
                margin: "16px 16px 0px 0px",
                // height: "138px !important",
                width: "100%",
                border: "2px solid #ccc",
                backgroundColor: "rgba(0,0,0,0.14)",
                display: "flex",
                // filter: "blur(1px)",
                justifyContent: "center",
                alignItems: "center",
                maxHeight: "135px",
                height: "135px",
              }}
            >
              <div
                style={{
                  width: 57,
                  height: 57,
                  zIndex: 100,
                }}
              >
                <CircularProgressbar
                  strokeWidth={5}
                  value={progress}
                  text={`${progress} %`}
                  styles={buildStyles({
                    rotation: 0.25,
                    textSize: "16px",
                    pathTransitionDuration: 0.5,
                    textColor: "#fff",
                    pathColor: "#04AA6D",
                    trailColor: "#fff",
                  })}
                />
              </div>
            </Box>
            <Box className={classes.smallViewPortTitleBox}>
              <Typography className={classes.smallViewPortTitle}>
                3D Model
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return singelItemView();
};
