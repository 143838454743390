const initialState = {
  loading: false,
  error: null,
  data: [],
  status: null,
  addKeyData: null,
  addKeyStatus: null,
  addKeyError: null,
  companyLicenses: [],
  companyLicensesLoading: false,
  companyLicensesStatus: null,
};

export const licenceActionTypes = {
  FETCH_LICENSE: "FETCH_LICENSE",
  FETCH_LICENSE_REQUEST: "FETCH_LICENSE_REQUEST",
  FETCH_LICENSE_SUCCESS: "FETCH_LICENSE_SUCCESS",
  FETCH_LICENSE_FAILURE: "FETCH_LICENSE_FAILURE",

  ADD_LICENSE_KEY: "ADD_LICENSE_KEY",
  ADD_LICENSE_KEY_REQUEST: "ADD_LICENSE_KEY_REQUEST",
  ADD_LICENSE_KEY_SUCCESS: "ADD_LICENSE_KEY_SUCCESS",
  ADD_LICENSE_KEY_FAILURE: "ADD_LICENSE_KEY_FAILURE",

  GET_COMPANY_LICENSES: "GET_COMPANY_LICENSES",
  GET_COMPANY_LICENSES_REQUEST: "GET_COMPANY_LICENSES_REQUEST",
  GET_COMPANY_LICENSES_SUCCESS: "GET_COMPANY_LICENSES_SUCCESS",
  GET_COMPANY_LICENSES_FAILURE: "GET_COMPANY_LICENSES_FAILURE",

  GET_ALL_COMPANY_LICENSE: "GET_ALL_COMPANY_LICENSE",
  GET_ALL_COMPANY_LICENSE_REQUEST: "GET_ALL_COMPANY_LICENSE_REQUEST",
  GET_ALL_COMPANY_LICENSE_SUCCESS: "GET_ALL_COMPANY_LICENSE_SUCCESS",
  GET_ALL_COMPANY_LICENSE_FAILURE: "GET_ALL_COMPANY_LICENSE_FAILURE",

  GET_ALL_INDIVIDUAL_LICENSE: "GET_ALL_INDIVIDUAL_LICENSE",
  GET_ALL_INDIVIDUAL_LICENSE_REQUEST: "GET_ALL_INDIVIDUAL_LICENSE_REQUEST",
  GET_ALL_INDIVIDUAL_LICENSE_SUCCESS: "GET_ALL_INDIVIDUAL_LICENSE_SUCCESS",
  GET_ALL_INDIVIDUAL_LICENSE_FAILURE: "GET_ALL_INDIVIDUAL_LICENSE_FAILURE",
};

function licenseReducer(state = initialState, action) {
  switch (action.type) {
    case licenceActionTypes.FETCH_LICENSE_REQUEST:
      return {
        ...state,
        error: null,
        status: licenceActionTypes.FETCH_LICENSE_REQUEST,
        addKeyData: null,
        addKeyStatus: null,
        addKeyError: null,
      };
    case licenceActionTypes.FETCH_LICENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: licenceActionTypes.FETCH_LICENSE_FAILURE,
      };
    case licenceActionTypes.FETCH_LICENSE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: licenceActionTypes.FETCH_LICENSE_SUCCESS,
      };

    case licenceActionTypes.ADD_LICENSE_KEY_REQUEST:
      return {
        ...state,
        error: null,
        addKeyData: null,
        addKeyStatus: licenceActionTypes.ADD_LICENSE_KEY_REQUEST,
      };
    case licenceActionTypes.ADD_LICENSE_KEY_FAILURE:
      return {
        ...state,
        addKeyError: action.payload,
        addKeyStatus: licenceActionTypes.ADD_LICENSE_KEY_FAILURE,
      };
    case licenceActionTypes.ADD_LICENSE_KEY_SUCCESS:
      return {
        ...state,
        addKeyData: action.payload,
        addKeyStatus: licenceActionTypes.ADD_LICENSE_KEY_SUCCESS,
      };

    case licenceActionTypes.GET_COMPANY_LICENSES_REQUEST:
      return {
        ...state,
        error: null,
        companyLicenses: [],
        companyLicensesLoading: true,
        companyLicensesStatus: licenceActionTypes.GET_COMPANY_LICENSES_REQUEST,
      };
    case licenceActionTypes.GET_COMPANY_LICENSES_FAILURE:
      return {
        ...state,
        error: action.payload,
        companyLicenses: [],
        companyLicensesLoading: false,
        companyLicensesStatus: licenceActionTypes.GET_COMPANY_LICENSES_FAILURE,
      };
    case licenceActionTypes.GET_COMPANY_LICENSES_SUCCESS:
      return {
        ...state,
        companyLicenses: action.payload,
        companyLicensesLoading: false,
        companyLicensesStatus: licenceActionTypes.GET_COMPANY_LICENSES_SUCCESS,
      };

    //new company licence api ---

    case licenceActionTypes.GET_ALL_COMPANY_LICENSE_REQUEST:
      return {
        ...state,
        error: null,
        status: licenceActionTypes.GET_ALL_COMPANY_LICENSE_REQUEST,
        addKeyData: null,
        addKeyStatus: null,
        addKeyError: null,
      };
    case licenceActionTypes.GET_ALL_COMPANY_LICENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: licenceActionTypes.GET_ALL_COMPANY_LICENSE_FAILURE,
      };
    case licenceActionTypes.GET_ALL_COMPANY_LICENSE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: licenceActionTypes.GET_ALL_COMPANY_LICENSE_SUCCESS,
      };

    // new Individual Subscription Licenses
    case licenceActionTypes.GET_ALL_INDIVIDUAL_LICENSE_REQUEST:
      return {
        ...state,
        error: null,
        status: licenceActionTypes.GET_ALL_INDIVIDUAL_LICENSE_REQUEST,
        addKeyData: null,
        addKeyStatus: null,
        addKeyError: null,
      };
    case licenceActionTypes.GET_ALL_INDIVIDUAL_LICENSE_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: licenceActionTypes.GET_ALL_INDIVIDUAL_LICENSE_FAILURE,
      };
    case licenceActionTypes.GET_ALL_INDIVIDUAL_LICENSE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: licenceActionTypes.GET_ALL_INDIVIDUAL_LICENSE_SUCCESS,
      };

    default:
      return state;
  }
}

export default licenseReducer;
