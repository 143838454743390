/*
 **	User name
 **	Created	6/11/2023
 **	index.ts
 */

import { combineReducers } from "@reduxjs/toolkit";
import licenseInfoReducer from "./license";
import {
  registerReducer,
  usernameReducer,
  resetPasswordReducer,
  userTypeReducer,
  userEmailReducer,
  userLatestDesignersTypeReducer,
  userYoungDesignersTypeReducerTypesReducer,
  followersDesignerReducer,
  followingsDesignerReducer,
  mutualFollowersReducer,
  saveStripeCustomerIdReducer,
} from "./userReducer";
import { categoryReducer } from "./productReducer/product-category";
import {
  myProductReducer,
  designerProductsReducer,
  productFilterReducer,
  productListReducer,
  productReducer,
  productReversalReducer,
  productPurchaseReducer,
  productFavouriteReducer,
  materialListReducer,
  productSizeReducer,
  currenciesReducer,
  productDeleteReducer,
  myDraftProductsReducer,
  productDetailsForProductSectionReducer,
  productDetailaWithEachUploadReducer,
  productThumbnailDNDReducer,
  productDownloadReducer,
} from "./productReducer/product-main";
import { libraryCategoriesyListReducer } from "./libraryReducers/libraryList";
import { uploadProductFileReducer } from "./productReducer/uploadProductFile";
import paymentsInfoReducer from "./payments";
import companyInfoReducer, {
  companyInviteEmployeesReducer,
  updateCompanyEmployeeLicenseReducer,
  updateCompanyEmployeeRoleReducer,
  companyDeleteEmployeesReducer,
  companyPermissionsReducer,
  manageSeatsReducer, //added to check functionality of AddedSeats
} from "./company";
import profilePersonalizationReducer from "./profile-personalization";
import inviteReducer from "./invite";
import { invoiceListReducer, subscriptionInvoiceReducer } from "./invoice";
import { uploadDesignReducer } from "./productReducer/uploadDesign";
import invoiceSettingsReducer from "./invoice-settings";
import { complaintReducer } from "./complaint";
import {
  certificateListReducer,
  uploadCertificateReducer,
} from "./userReducer/certificate";
import { deleteUserReducer } from "./userReducer/deleteUser";
import { licenseSelectionReducer } from "./licenceSelection";
import { notificationsReducer } from "./notifications";
import {
  changeEmailReducer,
  subscribeReducer,
  subscribeWithEmailReducer,
} from "./userReducer/newsLetter";
import { experienceReducer } from "./experience";
import {
  degreesReducer,
  fieldsOfStudyReducer,
  universitiesReducer,
} from "./userReducer/editList";
import { skillsReducer } from "./skills";
import { aboutMeReducer } from "./about-me";
import {
  academicsDetailReducer,
  deleteAcademicsReducer,
  uploadAcademicsReducer,
} from "./userReducer/academic";
import { uploadCoverImageReducer } from "./userReducer/uploadImge";
import { uploadProfilePicReducer } from "./userReducer/profilePic";
import { resumesReducer } from "./resumes";
import {
  webLinkDeleteReducer,
  webLinkDetailReducer,
  webLinkPositionReducer,
  webLinkUploadReducer,
} from "./userReducer/webLink";
import {
  companyProfileNavBarReducer,
  designerProfileCardOptionReducer,
  designersNavBarReducer,
  designersProfileNavBarReducer,
  jobsNavBarReducer,
  jobsPostedNavBarReducer,
  navBarReducer,
  productsNavBarReducer,
  profilePublicViewNavBarReducer,
  profileViewNavBarReducer,
  publicViewfollowersNavBarReducer,
  companyEmployeesReducer,
  myProfileSectionReducer,
} from "./navBar";
import { accountDetailsReducer } from "./userReducer/userdetails";
import { filterReducer } from "./filterReducers/filter";
import girdListViewReducer from "./grid-list";
import { jobsReducer } from "./jobReducer";
import { productDetailsNavBarReducer } from "./navBar/productDetails";
import { productEditNavBarReducer } from "./navBar/productEditNavBar";
import { tagsReducer } from "./productReducer/productTags";
import {
  // localDataReducer,
  previewCategoryDataReducer,
} from "./localDataReducer/categoryPreview";
import { employeesModalPreview } from "./localDataReducer/companyPreview";
import { employeeReducer } from "./employee";
import categoriesReducer from "./categories";
import homeReducer from "./home";
import { libraryProductListReducer, libraryReducer } from "./library";
import { fetchBillingInformationReducer } from "./billingReducers/billingInformation";
import cartReducer from "./cart";
import ordersReducer from "./order";
import savePopUpSettingsReducer from "./settings";
import searchReducer from "./search";
import productCommentsReducer from "./productReducer/products-comments";

const allReducers = combineReducers({
  billingInfo: fetchBillingInformationReducer,
  aboutMeInfo: aboutMeReducer,
  academicsDetail: academicsDetailReducer,
  accountDetails: accountDetailsReducer,
  category: categoryReducer,
  categories: categoriesReducer,
  cart: cartReducer,
  certificateList: certificateListReducer,
  changeEmail: changeEmailReducer,
  companyDeleteEmployees: companyDeleteEmployeesReducer,
  companyEmployeesNavBar: companyEmployeesReducer,
  companyInfo: companyInfoReducer,
  companyInviteEmployees: companyInviteEmployeesReducer,
  companyNavbar: companyProfileNavBarReducer,
  companyPermissions: companyPermissionsReducer,
  complaintInfo: complaintReducer,
  currencies: currenciesReducer,
  crousalProduct: myProductReducer,
  deleteAcademics: deleteAcademicsReducer,
  manageSeats: manageSeatsReducer,
  deleteUser: deleteUserReducer,
  designerProducts: designerProductsReducer,
  designerProfileCardOption: designerProfileCardOptionReducer,
  designersNavBar: designersNavBarReducer,
  designersProfileNavBar: designersProfileNavBarReducer,
  emailCheck: userEmailReducer,
  employee: employeeReducer,
  employeesModal: employeesModalPreview,
  experienceInfo: experienceReducer,
  fieldsOfStudy: fieldsOfStudyReducer,
  filterTypes: filterReducer,
  followersDesigners: followersDesignerReducer,
  followingsDesigners: followingsDesignerReducer,
  getDegrees: degreesReducer,
  girdListView: girdListViewReducer,
  home: homeReducer,
  inviteInfo: inviteReducer,
  invoiceList: invoiceListReducer,
  subscriptionInvoice: subscriptionInvoiceReducer,
  invoiceSettings: invoiceSettingsReducer,
  jobs: jobsReducer,
  jobsNavBar: jobsNavBarReducer,
  jobsPostedTabs: jobsPostedNavBarReducer,
  library: libraryReducer,
  libraryProductList: libraryProductListReducer,
  licenceSelection: licenseSelectionReducer,
  licenseInfo: licenseInfoReducer,
  materialList: materialListReducer,
  mutualFollowers: mutualFollowersReducer,
  myDraftProducts: myDraftProductsReducer,
  myProducts: myProductReducer,
  myProfileSection: myProfileSectionReducer,
  navBarReducer,
  notifications: notificationsReducer,
  orders: ordersReducer,
  paymentsInfo: paymentsInfoReducer,
  previewCategoryData: previewCategoryDataReducer,
  productComments: productCommentsReducer,
  productDelete: productDeleteReducer,
  productDetails: productDetailsForProductSectionReducer,
  productDetailsNavBar: productDetailsNavBarReducer,
  productDownload: productDownloadReducer,
  productEditNavBar: productEditNavBarReducer,
  productFavourites: productFavouriteReducer,
  productFilter: productFilterReducer,
  productInfo: productReducer,
  productList: productListReducer,
  productReversal: productReversalReducer,
  productSize: productSizeReducer,
  productThumbnailDND: productThumbnailDNDReducer,
  productWithUploadType: productDetailaWithEachUploadReducer,
  productsNavBar: productsNavBarReducer,
  profilePersonalization: profilePersonalizationReducer,
  profilePublicViewNavBar: profilePublicViewNavBarReducer,
  profileViewNavBar: profileViewNavBarReducer,
  publicViewfollowersNavButton: publicViewfollowersNavBarReducer,
  purchaseProduct: productPurchaseReducer,
  register: registerReducer,
  resetPassword: resetPasswordReducer,
  resumesInfo: resumesReducer,
  savePopUpSettings: savePopUpSettingsReducer,
  saveStripeCustomerId: saveStripeCustomerIdReducer,
  search: searchReducer,
  skillsInfo: skillsReducer,
  subscribe: subscribeReducer,
  subscribeWithEmail: subscribeWithEmailReducer,
  tags: tagsReducer,
  universities: universitiesReducer,
  updateCompanyEmployeeLicense: updateCompanyEmployeeLicenseReducer,
  updateCompanyEmployeeRole: updateCompanyEmployeeRoleReducer,
  uploadAcademics: uploadAcademicsReducer,
  uploadCertificate: uploadCertificateReducer,
  uploadCoverImage: uploadCoverImageReducer,
  uploadDesign: uploadDesignReducer,
  uploadProductFile: uploadProductFileReducer,
  uploadProfilePic: uploadProfilePicReducer,
  userLatestDesigners: userLatestDesignersTypeReducer,
  userType: userTypeReducer,
  userYoungDesigners: userYoungDesignersTypeReducerTypesReducer,
  usercheck: usernameReducer,
  webLinkDelete: webLinkDeleteReducer,
  webLinkDetail: webLinkDetailReducer,
  webLinkPosition: webLinkPositionReducer,
  webLinkUpload: webLinkUploadReducer,
  libraryCategoriesyList: libraryCategoriesyListReducer,
  // localData: localDataReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "undefined") {
    return allReducers(undefined, action);
  }

  return allReducers(state, action);
};

export default rootReducer;

export type IRootState = ReturnType<typeof rootReducer>;
