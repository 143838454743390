import React from "react";
import classNames from "classnames";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LoadingButton } from "@mui/lab";

const debugPlatformButton = false;

const useStyles = makeStyles((theme) => ({
  baseButton: {
    width: "100%",
    height: "54px !important",
    transition: "all 0.5s !important",
    borderRadius: "6px !important",
    textTransform: "none !important",
    fontSize: "15px !important",
    boxShadow: "0 0 0 !important",
  },
  blackButton: {
    color: "#FFF !important",
    border: "1px solid #2b2639 !important",
    backgroundColor: "#2b2639 !important",
    "&:hover": {
      color: "#2b2639 !important",
      backgroundColor: "#FFF !important",
    },
    "&:disabled": {
      opacity: "0.5 !important",
      cursor: "not-allowed !important",
    },
  },
  purpleButton: {
    backgroundColor: "#7a48ff !important",
    color: "#fff !important",
    "&:disabled": {
      backgroundColor: "#d7d0e8 !important",
      color: "#a49bbe !important",
    },
    "&:hover": {
      backgroundColor: "#643CD1 !important",
    },
  },
  purpleMediumButton: {
    backgroundColor: "#a49bbe !important",
    color: "#fff !important",
    "&:disabled": {
      backgroundColor: "#d7d0e8 !important",
      color: "#a49bbe !important",
    },
    "&:hover": {
      backgroundColor: "#643CD1 !important",
    },
  },
  purpleLightButton: {
    backgroundColor: "#ebe3ff !important",
    // color: "#7a48ff !important",
    color: "#a49bbe !important",
    "&:hover": {
      backgroundColor: "#D7D0E8 !important",
    },
  },
  blackBorderedButton: {
    backgroundColor: "#FFF !important",
    color: "#2b2639 !important",
    border: "1px solid #2b2639 !important",
    "&:hover": {
      color: "#FFF !important",
      backgroundColor: "#2b2639 !important",
    },
  },
  whiteBorderedButton: {
    backgroundColor: "#2b2639 !important",
    color: "#FFF !important",
    border: "1px solid #FFF !important",
    "&:hover": {
      color: "#2b2639 !important",
      backgroundColor: "#FFF !important",
    },
  },
  greenBorderedButton: {
    backgroundColor: "#FFF !important",
    color: "#64c279 !important",
    border: "1px solid #64c279 !important",
    "&:hover": {
      color: "#FFF !important",
      backgroundColor: "#64c279 !important",
    },
  },
  greenButton: {
    backgroundColor: "#64c279 !important",
    color: "#FFF !important",
    border: "1px solid #64c279 !important",
    "&:hover": {
      color: "#FFF !important",
      backgroundColor: "#64c279 !important",
    },
  },
  orangeButton: {
    backgroundColor: "#ff4f47 !important",
    color: "#FFF !important",
    border: "1px solid #ff4f47 !important",
    "&:hover": {
      color: "#FFF !important",
      backgroundColor: "#ff4f47 !important",
      opacity: 0.8,
    },
  },
  plainButton: {
    height: "54px !important",
    borderRadius: "6px !important",
    textTransform: "none !important",
    fontSize: "15px !important",
  },
  shareLinkButton: {
    width: "74px",
    height: "74px",
    borderRadius: "50% !important",
  },
  inActiveGreyButton: {
    backgroundColor: "#FFFFFF !important",
    color: "#a49bbe !important",
    border: "1px solid #a49bbe !important",
  },

  [theme.breakpoints.down("md")]: {
    baseButton: {
      height: "39px !important",
    },
    plainButton: {
      height: "39px !important",
    },
  },
}));

export const InActiveGreyButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.inActiveGreyButton,
        props.className
      )}
    >
      {debugPlatformButton ? `>>${props.children}` : props.children}
    </Button>
  );
};

export const BlackButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.blackButton,
        props.className
      )}
    >
      {debugPlatformButton ? `>>${props.children}` : props.children}
    </Button>
  );
};

export const PurpleButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.purpleButton,
        props.className
      )}
      type="submit"
      variant="contained"
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </Button>
  );
};

export const PurpleMediumButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.purpleMediumButton,
        props.className
      )}
      type="submit"
      variant="contained"
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </Button>
  );
};

export const PurpleLightButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.purpleLightButton,
        props.className
      )}
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </Button>
  );
};

export const BlackBorderedButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.blackBorderedButton,
        props.className
      )}
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </Button>
  );
};

export const WhiteBorderedButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.whiteBorderedButton,
        props.className
      )}
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </Button>
  );
};

export const GreenBorderedButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.greenBorderedButton,
        props.className
      )}
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </Button>
  );
};

export const GreenButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.greenButton,
        props.className
      )}
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </Button>
  );
};

export const OrangeButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(
        classes.baseButton,
        classes.orangeButton,
        props.className
      )}
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </Button>
  );
};

export const PlainButton = (props) => {
  const classes = useStyles();

  return (
    <LoadingButton
      {...props}
      className={classNames(classes.plainButton, props.className)}
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </LoadingButton>
  );
};

export function GradientColoredButton(props) {
  return (
    <LoadingButton
      disableElevation
      sx={{
        backgroundImage: "linear-gradient(62deg, #f54d64 0%, #f58561 98%)",
        paddingTop: "15px",
        paddingLeft: "16px",
        paddingRight: "16px",
        paddingBottom: "15px",
        fontSize: "15px",
        textTransform: "none",
        boxShadow: "0 0 0",
        minHeight: "54px",
        width: "auto",
        color: "#fff !important",
        "&:hover": {
          backgroundImage: "linear-gradient(62deg, #D24559 0%, #E27C5B 98%)",
          color: "#fff",
        },
        "@media (max-width: 900px)": {
          minHeight: "39px",
        },
      }}
      // disableRipple
      disableTouchRipple
      {...props}
      style={{
        ...props.style,
      }}
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </LoadingButton>
  );
}

export const ShareLinkButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      className={classNames(classes.shareLinkButton, props.className)}
    >
      {debugPlatformButton ? ">>" : null}
      {props.children}
    </Button>
  );
};
