/*
 **	User name
 **	Created	6/1/2023
 **	JobPostContext.tsx
 */

import React from "react";

export type JobData = {
  profileUrl?: string;
  companyName: string;
  jobTitle: string;
  industryTypeId: number | string;
  employmentTypeId: number;
  experienceTypeId: number;
  applyBy: number;
  otherIndustryType: string;
  jobWorkplaceTypeId: number;
  jobSalaryRangeId: number;
  jobRateId: string;
  minimumRange: string;
  maximumRange: string;
  jobDescription: string;
  jobLocation: Array<any>;
  skillsIdList: Array<any>;
  isActive: boolean;
  isSavedToDraft: boolean;
  applyByLink: string;
  country?: string;
  city?: string;
  currencyId: number;
};

type JobPostContextType = {
  postJob: (data: JobData) => void;
  saveJobAsDraft: (data: JobData) => void;
  formData: JobData;
  setFormData: (data: JobData) => void;
  clearJobFormData: () => void;
};

export const JobPostContext = React.createContext<JobPostContextType>({
  postJob: (data: JobData) => null,
  saveJobAsDraft: (data: JobData) => null,
  formData: {
    companyName: "",
    industryTypeId: null,
    jobTitle: "",
    employmentTypeId: null,
    experienceTypeId: null,
    skillsIdList: [],
    applyBy: null,
    otherIndustryType: "",
    applyByLink: "",
    jobWorkplaceTypeId: null,
    jobSalaryRangeId: null,
    jobLocation: [],
    minimumRange: "",
    maximumRange: "",
    jobRateId: "",
    jobDescription: "",
    isActive: false,
    isSavedToDraft: false,
    currencyId: null,
  },
  setFormData: (data: JobData) => null,
  clearJobFormData: () => null,
});
