import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Typography, Button, TextField, Tooltip } from "@mui/material";
import { useTheme } from "@mui/system";
import copy from "copy-to-clipboard";
import { LightModeCross, NightModeCross } from "../Logo/logos";
import { useProductDetailsModalStyles } from "../../../styles/productDetails";
import { ShareLinkButton } from "../UIFormFields/Buttons";

export default function ShareProductModal({
  open,
  handleCloseShareModal,
  shareUrl,
}) {
  const classes = useProductDetailsModalStyles();
  const theme = useTheme();
  const [linkCopyStatus, setLinkCopyStatus] = React.useState("Copy");

  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
    textFont: {
      fontSize: "15px",
      textAlign: "left",
      color: "#a49bbe",
      float: "left",
      marginTop: "24px",
      marginBottom: "16px",
    },
    LinkButton: {
      background: "#7a48ff",
      width: "66px",
      height: "54px",
      borderRadius: "6px 0px 0px 6px",
    },
  };

  const {
    ReactComponent: LinkedInImg,
  } = require("../../../assets/icons/Social_Icon_Inactive_Linkedin.svg");
  const {
    ReactComponent: PinterestImg,
  } = require("../../../assets/icons/Social_Icon_Inactive_Pinterest.svg");
  const {
    ReactComponent: FaceBookImg,
  } = require("../../../assets/icons/Social_Icon_Inactive_Facebook.svg");
  const {
    ReactComponent: WhatsAppImg,
  } = require("../../../assets/icons/Social_Icon_Active_Whatsapp.svg");
  const {
    ReactComponent: LinkShareIcon,
  } = require("../../../assets/icons/Icons_Light_Link share_Icon.svg");

  const handleCopyText = (e) => {};

  const copyToClipboard = () => {
    copy(shareUrl);
    setLinkCopyStatus("Copied to clipboard");
  };

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal open={open}>
        <Box
          style={{
            backgroundColor:
              theme?.palette?.mode === "light" ? "#fff" : "black",
            position: "absolute",
            top: "50%",
            // border: "1px solid #fff",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxHeight: "100%",
            maxWidth: 680,
            borderRadius: "15px",
            color: "white",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box className={classes.closeBox}>
              {theme?.palette?.mode === "light" ? (
                <LightModeCross
                  onClick={handleCloseShareModal}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <NightModeCross
                  onClick={handleCloseShareModal}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>
            <Box className={classes.titleBox}>
              <Typography
                style={
                  theme?.palette?.mode === "light"
                    ? heading.lightMode
                    : heading.nightMode
                }
              >
                Share your work
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              margin: "14px 54px 54px 54px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <ShareLinkButton
                onClick={() => {
                  // window.open(
                  //   `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                  //     shareUrl
                  //   )}`
                  // );
                }}
              >
                <Tooltip title="Share on Facebook">
                  <FaceBookImg />
                </Tooltip>
              </ShareLinkButton>
              <ShareLinkButton
                onClick={() => {
                  // window.open(
                  //   `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                  //     shareUrl
                  //   )}`
                  // );
                }}
              >
                <Tooltip title="Share on LinkedIn">
                  <LinkedInImg />
                </Tooltip>
              </ShareLinkButton>
              <ShareLinkButton
                onClick={() => {
                  // window.open(
                  //   `http://pinterest.com/pin/create/button/?url=${encodeURIComponent(
                  //     shareUrl
                  //   )}`
                  // );
                }}
              >
                <Tooltip title="Share on Pinterest">
                  <PinterestImg />
                </Tooltip>
              </ShareLinkButton>

              <ShareLinkButton
                onClick={() => {
                  window.open(
                    `https://api.whatsapp.com/send?text=${encodeURIComponent(
                      shareUrl
                    )}`
                  );
                }}
              >
                <Tooltip title="Share on WhatsApp">
                  <WhatsAppImg />
                </Tooltip>
              </ShareLinkButton>
            </Box>
            <Typography
              style={{
                fontSize: "15px",
                textAlign: "left",
                color: "#a49bbe",
                float: "left",
                marginTop: "24px",
                marginBottom: "16px",
              }}
            >
              or copy link
            </Typography>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button style={heading.LinkButton} onClick={copyToClipboard}>
                <Tooltip title={linkCopyStatus}>
                  <LinkShareIcon />
                </Tooltip>
              </Button>
              <TextField
                id="outlined-basic"
                variant="outlined"
                placeholder="Link"
                size="medium"
                value={shareUrl}
                onChange={handleCopyText}
                style={{
                  width: "100%",
                  height: "54px !important",
                  // marginTop: "5px",
                  borderRadius: "0 6px 6px 0",
                }}
                inputProps={{ readOnly: true }}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
