/*
 **	User name
 **	Created	6/11/2023
 **	hooks.ts
 */

import { IRootState } from "app/redux/reducers";
import { TypedUseSelectorHook, useSelector } from "react-redux";

export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
