/*
 **	User name
 **	Created	5/25/2023
 **	image.tsx
 */

import React from "react";
import { Box } from "@mui/material";
import { isImage } from "../../utils/helper";

const ProductImage = ({
  item,
  onCardClick = (item: any) => null,
  style = {},
}) => {
  const placeholderImg = require("../../../assets/backgrounds/product-placeholder.jpg");
  const imageUrl =
    item?.productThumbnailUploadedURL ||
    item?.productImageUrl ||
    item?.src ||
    item?.productLibraryThumbnailUrl;

  if (!imageUrl) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          height: 201,
        }}
      >
        <img
          src={placeholderImg}
          alt="product"
          style={{
            maxWidth: "100%",
            // width: "100%",
            // height: 201,
            width: "auto",
            // borderRadius: "20px 20px 0px 0px",
            ...style,
          }}
          onClick={() => onCardClick?.(item)}
          // className={classes.viewPort}
        />
      </Box>
    );
  }

  return (
    <Box style={{ display: "flex", justifyContent: "center" }}>
      <>
        {isImage(imageUrl) !== null ||
        (item?.thumbnailFileType && item?.thumbnailFileType !== ".glb") ? (
          <img
            src={imageUrl}
            alt="product"
            style={{
              // maxWidth: "100%",
              width: "100%",
              height: 201,
              // borderRadius: "20px 20px 0px 0px",
              ...style,
            }}
            onClick={() => onCardClick?.(item)}
            // className={classes.viewPort}
          />
        ) : (
          <model-viewer
            style={{
              maxWidth: "100%",
              width: "100%",
              height: 201,
              borderRadius: "20px 20px 0px 0px",
              ...style,
            }}
            src={imageUrl}
            ios-src=""
            alt={item.productName || "3d model"}
            auto-rotate={false}
            camera-controls={false}
            ar={false}
            ar-modes="webxr scene-viewer quick-look"
            environment-image="neutral"
            oncontextmenu="return false"
            autoplay={false}
          ></model-viewer>
        )}
      </>
      {/* commenthing this , otherwise was getting 2 pictures side by side in Public profile of Company */}
      {/* <>
        {item.src ? (
          <img
            src={item.src || placeholderImg}
            alt="Works"
            style={{
              maxWidth: "100%",
              // width: "100%",
              height: 201,
              borderRadius: "20px 20px 0px 0px",
              ...style,
            }}
            // onClick={() => onCardClick(item)}
            // className={classes.viewPort}
          />
        ) : null}
      </> */}
    </Box>
  );
};

export default React.memo(ProductImage);
