/*
 **	User name
 **	Created	5/25/2023
 **	AppRoutes.ts
 */

import React, { useLayoutEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { IRootState } from "app/redux/reducers";

// Pages need to be load without showing loader - Start
import HomeArea from "app/screens/NewHomePage";
import GenericPage from "app/components/GenericPage";
// Pages need to be load without showing loader - End

import Inprogress from "app/screens/work_inprogress/inprogress";
import TrialExpiredModal from "app/components/Modals/TrialExpiredModal";
import RouteLinks from "./RouteLinks";

const Signup = React.lazy(() => import("app/screens/Signup"));
const ForgotPassword = React.lazy(
  () => import("app/screens/reset-forgot-password/forgotPassword")
);
const ResetPassword = React.lazy(
  () => import("app/screens/reset-forgot-password/resetPassword")
);
const Login = React.lazy(() => import("app/screens/Login"));

const ComingSoon = React.lazy(
  () => import("app/screens/work_inprogress/comingSoon")
);
const PageNotFound = React.lazy(() => import("app/screens/not-found-page"));
const BillingPage = React.lazy(
  () => import("app/screens/LicenseSelection/billingPage")
);
const AddToCart = React.lazy(() => import("app/screens/AddToCart"));
const OrderDetails = React.lazy(
  () => import("app/screens/Invoices/orderDetails")
);
const Profile = React.lazy(() => import("app/screens/profile"));

const Help = React.lazy(() => import("app/screens/help"));
const Companies = React.lazy(() => import("app/screens/companies"));
const Setting = React.lazy(() => import("app/screens/setting-page"));
const ContactForm = React.lazy(
  () => import("app/screens/contactUs/contactForm")
);
const CompanyInfo = React.lazy(() => import("app/screens/company-info"));
const CareerInfo = React.lazy(() => import("app/screens/career-info"));
const CompanyEmployees = React.lazy(
  () => import("app/screens/company-employees")
);
const ProductDetail = React.lazy(() => import("app/components/productDetail"));

const PrivacyPolicy = React.lazy(() => import("app/screens/privacy-policy"));
const TermsAndConditions = React.lazy(
  () => import("app/screens/terms-and-conditions")
);
const Personalization = React.lazy(
  () => import("app/screens/setting-page/personalization")
);

const AccountSettings = React.lazy(() => import("app/screens/accountSettings"));
const DesignerProfile = React.lazy(() => import("app/screens/designerProfile"));

const ProfileLoginView = React.lazy(
  () => import("app/screens/ProfileLoginView")
);
const ProfilePublicViewScreen = React.lazy(
  () => import("app/screens/newDesigners/publicView")
);
const ViewProfile = React.lazy(() => import("app/screens/viewProfile"));
const LibraryContainer = React.lazy(
  () => import("app/screens/LibraryContainer")
);

const IndividualLicenseSelection = React.lazy(
  () => import("app/screens/LicenseSelection")
);
const CurrentPlanPage = React.lazy(
  () => import("app/screens/LicenseSelection/currentPlanPage")
);

// Nav pages
const NewDesigners = React.lazy(() => import("app/screens/newDesigners"));
const NewProducts = React.lazy(() => import("app/screens/newProducts"));
const InvoicesPage = React.lazy(
  () => import("app/screens/Invoices/orders_Invoices")
);
const Jobs = React.lazy(() => import("app/screens/jobs"));

const DesignerPage = React.lazy(
  () => import("app/screens/NewHomePage/guestHomepage/designerPage")
);
const CompanyPage = React.lazy(
  () => import("app/screens/NewHomePage/guestHomepage/companyPage")
);

const StripeConnect = React.lazy(() => import("app/screens/StripeConnect"));

const JobDetails = React.lazy(() => import("app/screens/jobDetails"));

enum RouteType {
  PUBLIC = "public",
  PRIVATE = "private",
  NON_PRIVATE = "non-private",
}

// For routing

function configureRoutes() {
  const routes: Array<{
    accessible: boolean;
    path: RouteLinks;
    component: React.FC;
    exact: boolean;
    title: string;
    type: RouteType;
    params?: Record<any, any>;
  }> = [
    {
      accessible: true,
      component: OrderDetails,
      exact: true,
      path: RouteLinks.ORDER_DETAILS,
      title: "3Direct",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: HomeArea,
      exact: true,
      path: RouteLinks.HOME,
      title: "3Direct",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: DesignerPage,
      exact: true,
      path: RouteLinks.DESIGNER_PAGE,
      title: "3Direct",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: CompanyPage,
      exact: true,
      path: RouteLinks.COMPANY_PAGE,
      title: "3Direct",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: Inprogress,
      exact: true,
      path: RouteLinks.IN_Progress,
      title: "InProgress",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: Signup,
      exact: true,
      path: RouteLinks.REGISTRATION,
      title: "Registration",
      type: RouteType.NON_PRIVATE,
    },
    {
      accessible: true,
      component: Signup,
      exact: true,
      path: RouteLinks.EMPLOYEE_SIGNUP,
      title: "Employee SignUp",
      type: RouteType.PUBLIC,
      params: { isEmployeeSignUp: true },
    },
    {
      accessible: true,
      component: Login,
      exact: true,
      path: RouteLinks.LOGIN,
      title: "Login",
      type: RouteType.NON_PRIVATE,
    },
    {
      accessible: true,
      component: StripeConnect,
      exact: true,
      path: RouteLinks.STRIPE_CONNECT,
      title: "Connect to stripe",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: IndividualLicenseSelection,
      exact: true,
      path: RouteLinks.LICENSE,
      title: "Individual License",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: CurrentPlanPage,
      exact: true,
      path: RouteLinks.CURRENT_PLAN,
      title: "Current Plan",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: BillingPage,
      exact: true,
      path: RouteLinks.BILLING_PAGE,
      title: "Billing Page",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: Profile,
      exact: true,
      path: RouteLinks.PROFILE,
      title: "Profile",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: InvoicesPage,
      exact: true,
      path: RouteLinks.ORDERS_AND_INVOICES,
      title: "Orders and Subscription",
      type: RouteType.PRIVATE,
    },

    {
      accessible: true,
      component: PrivacyPolicy,
      exact: true,
      path: RouteLinks.PRIVACY_POLICY,
      title: "privacy-policy",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: TermsAndConditions,
      exact: true,
      path: RouteLinks.TERMS,
      title: "terms-and-conditions",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: AddToCart,
      exact: true,
      path: RouteLinks.CART,
      title: "Shopping cart",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: ForgotPassword,
      exact: true,
      path: RouteLinks.FORGOT_PASSWORD,
      title: "Forgot password",
      type: RouteType.NON_PRIVATE,
    },
    {
      accessible: true,
      component: ResetPassword,
      exact: true,
      path: RouteLinks.RESET_PASSWORD,
      title: "Reset password",
      type: RouteType.NON_PRIVATE,
    },
    {
      accessible: true,
      component: Help,
      exact: true,
      path: RouteLinks.HELP,
      title: "Need Help",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: DesignerProfile,
      exact: true,
      path: RouteLinks.DESIGNER,
      title: "Designer Profile",
      type: RouteType.PRIVATE,
    },
    {
      //for Company route profile using default component  "DesignerProfile"
      accessible: true,
      component: DesignerProfile,
      exact: true,
      path: RouteLinks.COMPANY_PROFILE,
      title: "Company Profile",
      type: RouteType.PUBLIC,
      params: {
        isCompany: true,
      },
    },
    {
      //for Employee route profile using default component  "DesignerProfile"
      accessible: true,
      component: DesignerProfile,
      exact: true,
      path: RouteLinks.EMPLOYEE_PROFILE,
      title: "Employee Profile",
      type: RouteType.PUBLIC,
      params: {
        isEmployee: true,
      },
    },
    {
      accessible: true,
      component: Companies,
      exact: true,
      path: RouteLinks.COMPANIES,
      title: "Companies",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: ViewProfile,
      exact: true,
      path: RouteLinks.VIEW_PROFILE,
      title: "ViewProfile",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: Personalization,
      exact: true,
      path: RouteLinks.SETTINGS_PERSONALIZATION,
      title: "Personalization",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: NewProducts,
      exact: true,
      path: RouteLinks.PRODUCTS,
      title: "Products",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: ProductDetail,
      exact: true,
      path: RouteLinks.SINGLE_PRODUCT,
      title: "product",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: ProductDetail,
      exact: true,
      path: RouteLinks.SINGLE_LIBRARY_PRODUCT,
      title: "product",
      type: RouteType.PUBLIC,
      params: {
        isLibraryProduct: true,
      },
    },
    {
      accessible: true,
      component: ContactForm,
      exact: true,
      path: RouteLinks.CONTACT,
      title: "ContactForm",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: Setting,
      exact: true,
      path: RouteLinks.SETTINGS_PROFILE,
      title: "Profile Data",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: CompanyInfo,
      exact: true,
      path: RouteLinks.COMPANY_INFO,
      title: "invoice",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: CareerInfo,
      exact: true,
      path: RouteLinks.CAREER_INFO,
      title: "invoice",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: AccountSettings,
      exact: true,
      path: RouteLinks.ACCOUNT_SETTINGS,
      title: "Account Settings",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: DesignerProfile,
      exact: true,
      path: RouteLinks.DESIGNER_PROFILE,
      title: "Designer Profile",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: NewDesigners,
      exact: true,
      path: RouteLinks.DESIGNERS,
      title: "Designers",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: ProfilePublicViewScreen,
      exact: true,
      path: RouteLinks.SINGLE_DESIGNER,
      title: "Designer",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: ProfileLoginView,
      exact: true,
      path: RouteLinks.SINGLE_COMPANY,
      title: "profile",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: Jobs,
      exact: true,
      path: RouteLinks.JOBS,
      title: "Jobs",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: JobDetails,
      exact: true,
      path: RouteLinks.JOB_DETAILS,
      title: "Job Details",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: CompanyEmployees,
      exact: true,
      path: RouteLinks.EMPLOYEES,
      title: "Company Employees",
      type: RouteType.PRIVATE,
    },
    {
      accessible: true,
      component: LibraryContainer,
      exact: true,
      path: RouteLinks.LIBRARY_PRODUCTS,
      title: "Library",
      type: RouteType.PUBLIC,
    },
    {
      accessible: true,
      component: ComingSoon,
      exact: true,
      path: RouteLinks.COMING_SOON,
      title: "Coming Soon Page",
      type: RouteType.PUBLIC,
    },
  ];

  return routes;
}

export function AppRoutes() {
  const routes = configureRoutes();
  const location = useLocation();

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const userData = useSelector((state: IRootState) => state.register);
  const isPlanOverRide = userData?.userAdditionalInfo?.isPlanOverride;

  const loggedInUser = userData?.userAdditionalInfo;

  return (
    <>
      {loggedInUser?.currentPlanId === 1 &&
        loggedInUser.isAccountBlocked &&
        !(
          window.location.href.includes("/license") ||
          window.location.href.includes("/billing-page")
        ) &&
        !isPlanOverRide && <TrialExpiredModal />}
      <Routes>
        <Route path="/" element={<GenericPage />}>
          {routes.map((route) => {
            if (
              (!userData.userInfo && route.type === "private") ||
              (userData?.loginStatus === "SUCCESS" &&
                userData?.userAdditionalInfo?.postCode === null &&
                !(
                  window.location.href.includes("/profile") ||
                  window.location.href.includes("/login") ||
                  window.location.href.includes("/settings-personalization")
                ))
            ) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<Navigate replace to="/login" />}
                />
              );
            }

            if (userData?.userInfo && route?.type === "non-private") {
              return userData?.userInfo?.email !== null &&
                userData?.userInfo?.userName !== null &&
                userData?.userInfo?.arePersonalDetailsFilled ? (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<Navigate replace to="/" />}
                />
              ) : (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<Navigate replace to="/profile" />}
                />
              );
            } else <Navigate replace to="/" />;

            const Component = route.component;

            return (
              <Route
                key={route.path}
                path={route.path}
                title={route.title}
                element={<Component {...route.params} />}
                {...route}
              />
            );
          })}
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>{" "}
    </>
  );
}
