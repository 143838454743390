import React from "react";
import { TextValidator } from "react-material-ui-form-validator";

export function TextFieldValidator(props) {
  return (
    <TextValidator
      style={{ width: "100%" }}
      InputProps={{ style: { color: "#2b2639", borderColor: "#d7d0e8" } }}
      {...props}
    />
  );
}
