/*
 **	User name
 **	Created	5/27/2023
 **	previewDesign.ts
 */

import { makeStyles } from "@mui/styles";

export const usePreviewDesignStyles = makeStyles(() => ({
  previewBox: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "668px",
    borderRadius: "0px 0px 20px 20px",
    opacity: 0.99,
  },
  LeftSection: {
    flex: 0.4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "5px 0px 0px 108px",
    position: "relative",
  },
  RightSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 0.6,
    zIndex: 1,
    position: "relative",
  },
  headingText: {
    fontWeight: "bold",
    bold: 700,
  },
  headingBox: {
    padding: "0px 50px 30px 0px",
  },
  SubtitleText: {
    fontSize: "15px",
    fontWeight: "bold",
    bold: 700,
    padding: "0px 130px 30px 0px",
  },
  userInfo: {
    width: "309px",
    height: "140px",
    position: "absolute",
    bottom: 0,
    left: 0,
    borderRadius: "0px 20px 0px 20px",
    backgroundImage:
      "linear-gradient(114deg, #fff -10%, rgba(255, 255, 255, 0) 104%)",
  },
  profileImage: {
    width: "64px",
    height: "64px",
    borderRadius: "0px 20px 0px 20px",
    backgroundColor: "#999",
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
  },
  likeButton: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    width: "87px",
    height: "42px",
    borderRadius: "20px 0px 0px 0px",
    position: "absolute",
    right: 0,
    bottom: 0,
    backgroundColor: "#ffaf48",
    cursor: "pointer",
  },
  userName: {
    padding: "26px 0px 0px 24px",
  },
  designBackground: {
    zIndex: -1,
    position: "absolute",
  },
  designImage: {
    cursor: "pointer",
    width: "500px",
    height: "500px",
    margin: "auto",
    position: "relative",
    top: "-20px",
  },
}));
