import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import {
  notificationsReducerTypes,
  requestReceived,
} from "../reducers/notifications";

const version = process.env.REACT_APP_API_VERSION;

function* getNotificationsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_NOTIFICATIONS.replace("{version}", version),
    payload
  );

  return res;
}

function* getNotifications({ payload }) {
  try {
    yield put({
      type: notificationsReducerTypes.REQUEST_GET_NOTIFICATIONS,
      payload: null,
    });
    const response = yield call(getNotificationsRequest, payload);

    yield put({
      type: notificationsReducerTypes.RESPONSE_GET_NOTIFICATIONS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: notificationsReducerTypes.ERROR_GET_NOTIFICATIONS,
      payload: e,
    });
  }
}

// REQUEST_RECEIVED
function* requestReceivedRequest(payload) {
  const res = yield request(
    "post",
    EndPoints.REQUEST_RECEIVED.replace("{version}", version),
    payload
  );

  return res;
}

function* requestReceivedFun({ payload }) {
  try {
    yield put({
      type: requestReceived.REQUEST_REQUEST,
      payload: null,
    });
    const response = yield call(requestReceivedRequest, payload);

    yield put({
      type: requestReceived.RESPONSE_REQUEST,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: requestReceived.ERROR_REQUEST,
      payload: e,
    });
  }
}

//

function* invoiceSaga() {
  yield all([
    takeLatest(notificationsReducerTypes.GET_NOTIFICATIONS, getNotifications),
    takeLatest(requestReceived.REQUEST, requestReceivedFun),
  ]);
}
export default invoiceSaga;
