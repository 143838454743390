/*
 **	User name
 **	Created	5/31/2023
 **	utils.ts
 */

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
    maxHeight: 50,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontFamily: "Poppins",
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  mainBox: {
    padding: "24px",
    boxShadow: " 0 12px 34px 0 rgba(0, 0, 0, 0.1)",
    borderRadius: "20px",
    marginBottom: "65px",
  },
  subBox: {
    backgroundColor: "#7847ff0a",
    // opacity: "0.4",
    borderRadius: "20px",
    padding: "16px",
    margin: "0px 0px 24px",
    display: "flex",
    flexDirection: "row",
    rowGap: "10px",
    objectFit: "contain",
    position: "relative",
  },
  title: {
    padding: "16px 16px,16px 0px",
    fontWeight: "bold !important" as any,
    color: "#a49bbe",
  },
  resetText: {
    right: 0,
    marginLeft: "auto !important" as any,
    bottom: 0,
    marginTop: "auto !important" as any,
    color: "#a49bbe",
    fontWeight: "bold !important" as any,
    cursor: "pointer !important" as any,
  },
  innerReset: {
    fontFamily: "Poppins",
    justifyContent: "end",
    margin: "10px 0px 0px 0px",
    display: "flex",
    padding: "0px 10px 0px 10px",
    fontWeight: "bold !important" as any,
    color: "#a49bbe",
    cursor: "pointer",
  },
  dropDownBox: {
    position: "absolute",
    padding: "16px",
    width: "100%",
    top: 55,
    zIndex: 1000,
    borderRadius: "5px",
    overflowY: "auto",
    maxHeight: "224px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    backgroundColor: "#fff",
  },
  locationDropDownBox: {
    position: "absolute",
    // width: "116%",
    // maxHeight: "273px",
    padding: "16px 16px 16px 0px",
    width: "288px",
    top: 58,
    zIndex: 1000,
    borderRadius: "5px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    background: "#fff",
  },
  dropDownOption: {
    borderBottom: 1.9,
    borderColor: "#d7d0e8 !important" as any,
    background: "#fff",
    zIndex: 1000,
    "&:hover": {
      background: "#fff",
    },
  },
  addButton: {
    width: "63px",
    marginTop: "16px !important" as any,
    // padding: "12px !important" as any,
  },
  applyButton: {
    width: "75px",
    margin: "16px 0px 0px 24px !important" as any,
  },
  individualDropDown: {
    width: "300px !important",
    margin: "16px 24px 0 0 !important",
  },
  gradientButton: {
    padding: "15px !important",
    color: "white",
    margin: "5px !important",
    textTransform: "none",
    borderRadius: "6px",
  },
  purpleButton: { margin: "5px !important", width: "10% !important" },
  typographyDesigns: {
    padding: "16px auto 0px !important",
    fontWeight: "bold !important",
  },

  [theme.breakpoints.down("md")]: {
    formControl: {
      width: "265px !important",
    },
    individualDropDown: {
      width: "265px !important",
    },
    gradientButton: {
      height: "39px !important",
      width: "55% !important",
      padding: "0px !important",
    },
    purpleButton: { width: "45% !important" },
    subBox: { flexDirection: "column" },
    locationDropDownBox: { width: "265px" },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  } as any,
  getContentAnchorEl: null as any,
  anchorOrigin: {
    vertical: "bottom" as any,
    horizontal: "center" as any,
  } as any,
  transformOrigin: {
    vertical: "top" as any,
    horizontal: "center" as any,
  } as any,
  variant: "menu" as any,
};

const options = [
  { id: 1, type: "Cotton" },
  { id: 2, type: "Spandex" },
  { id: 3, type: "Polyester" },
  { id: 4, type: "Wool" },
  { id: 5, type: "Cotton" },
  { id: 6, type: "Linen" },
];

export { useStyles, MenuProps, options };
