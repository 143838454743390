import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { employeeTypes } from "../reducers/employee";

const version = process.env.REACT_APP_API_VERSION;

function* getNotificationsRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.FETCH_EMPLOYEE_DETAILS.replace("{version}", version),
    payload
  );

  return res;
}

function* getNotifications({ payload }: any) {
  try {
    yield put({
      type: employeeTypes.REQUEST_FETCH_EMPLOYEE_DETAILS,
      payload: null,
    });
    const response = yield call(getNotificationsRequest, payload);

    yield put({
      type: employeeTypes.RESPONSE_FETCH_EMPLOYEE_DETAILS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: employeeTypes.ERROR_FETCH_EMPLOYEE_DETAILS,
      payload: e,
    });
  }
}

function* rootSaga() {
  yield all([
    takeLatest(employeeTypes.FETCH_EMPLOYEE_DETAILS, getNotifications),
  ]);
}
export default rootSaga;
