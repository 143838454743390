import React from "react";
import { Box, Modal, Typography, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { LightModeCross, NightModeCross } from "../Logo/logos";
import { BlackButton } from "../UIFormFields";
import { SwitchCheckBox } from "../UIFormFields/checkbox";

const useAlertModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100% !important",
    maxHeight: 707,
    maxWidth: 880,
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
  },
  titleBox: {
    margin: "54px 0px 0px 0px",
  },
  textField: {
    objectFit: "contain",
    fontFamily: "Poppins",
    fontSize: "15px",
    fontWeight: "normal",
    textAlign: "left",
    color: "#a49bbe",
  },
  editFielBox: {
    width: "100%",
    padding: "0px 54px 24px 54px",
  },
  editBox: {
    display: "flex",
    flexDirection: "row",
  },
  descriptionBox: {
    padding: "0px 50px",
    alignItems: "center",
    width: "100%",
  },
  description: {
    fontFamily: "Poppins",
    fontSize: "15px",
    textAlign: "center",
    color: "#2b2639",
  },
  groundBox: {
    padding: "0px 31px 46px 0px",
  },
  ModalBox: {
    backgroundColor: theme?.palette?.mode === "light" ? "#fff" : "black",
    position: "absolute",
    top: "50%",
    left: "50%",
    border: "1px solid #fff",
    transform: "translate(-50%, -50%)",
    width: 880,
    borderRadius: "15px",
    color: "white",
    overflowY: "auto",
    height: "681px !important",
  },
  [theme.breakpoints.down("md")]: {
    ModalBox: {
      width: "350px !important",
      top: "50%",
      left: "50%",
      height: "auto !important",
    },
  },
}));

const CookieSection = ({ title, description, disableCheckbox = false }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        marginBottom: 34,
      }}
    >
      <Box style={{ display: "flex", flexDirection: "row", marginBottom: 8 }}>
        <Box style={{ flex: 1 }}>
          <Typography
            style={{ textAlign: "left", fontSize: 15, fontWeight: "bold" }}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <SwitchCheckBox
            name={title}
            disabled={disableCheckbox}
            {...(disableCheckbox && { checked: true })}
          />
        </Box>
      </Box>
      <Box style={{ maxWidth: 594 }} className="d-none d-md-block">
        <Typography style={{ textAlign: "left", fontSize: 15 }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default function AdvanceCookieSettings({ setOpen, onClose }) {
  const classes = useAlertModalStyles();
  const theme = useTheme();

  const handleClose = () => {
    setOpen(false);
  };

  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "20px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };

  return (
    <Modal open={true}>
      <Box
        className={classes.ModalBox}
        style={
          {
            // // backgroundColor: theme?.palette?.mode === "light" ? "#fff" : "black",
            // position: "absolute",
            // top: "50%",
            // left: "50%",
            // border: "1px solid #fff",
            // transform: "translate(-50%, -50%)",
            // width: "100vw",
            // borderRadius: "15px",
            // color: "white",
            // overflowY: "auto",
            // height: 681,
            // backgroundColor: "red",
          }
        }
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box className={classes.closeBox}>
            {theme?.palette?.mode === "light" ? (
              <LightModeCross
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <NightModeCross
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            )}
          </Box>
          <Box className={classes.titleBox}>
            <Typography
              style={
                theme?.palette?.mode === "light"
                  ? heading.lightMode
                  : heading.nightMode
              }
            >
              Advanced Cookie Settings
            </Typography>
          </Box>
          <Box className={classes.descriptionBox}>
            <Box className={classes.description}>
              <CookieSection
                title="Strictly Necessary Cookies"
                description={
                  "These cookies are essential for the proper functioning of our website. They ensure basic features and security. Therefore they cannot be deactivated."
                }
                disableCheckbox={true}
              />
              <CookieSection
                title="Marketing Cookies"
                description={
                  "These cookies help us personalize your experience and provide you with tailored content and offers based on your interests."
                }
              />
              <CookieSection
                title="Functional Cookies"
                description={
                  "Functional cookies enhance your browsing experience by enabling additional features like saved preferences and smoother navigation."
                }
              />
              <CookieSection
                title="Analytics Cookies"
                description={
                  "Analytics cookies gather anonymous data to help us understand how visitors interact with our website, allowing us to improve its performance and content."
                }
              />
            </Box>

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <BlackButton
                onClick={() => {
                  handleClose();
                  onClose();
                }}
                style={{ width: 170, margin: "20px" }}
              >
                Confirm Selection
              </BlackButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
