const Login = {
  LOGIN: "api/v{version}/Login",
};

const Account = {
  REGISTER: "api/v{version}/Account",
  CHECK_USERNAME: "api/v{version}/Account/IsUserNameUnique",
  CHECK_EMAIL: "api/v{version}/Account/IsEmailUnique",
  ACCOUNT_INFO: "api/v{version}/Account/{Guidid}",
  UPDATE_ACCOUNT_INFO: "api/v{version}/Account/UpdateProfileBasedOnProfileType",
  UPDATE_ACCOUNT_WITH_PROFILE_PIC:
    "api/v{version}/Account/UpdateAccountWithProfilePic",
  FORGOT_PASSWORD: "api/v{version}/Account/ForgotPassword",
  IS_VALID_RESET_TOKEN: "api/v{version}/Account/IsPasswordResetTokenExpired",
  RESET_PASSWORD: "api/v{version}/Account/ResetPassword",
  UPDATE_PASSWORD: "api/v{version}/Account/ChangePassword",
  UPDATE_PROFILE_PERSONALIZATION:
    "api/v{version}/Account/UpdateProfilePersonalisationBasedOnGuid",
  GET_PROFILE_PERSONALIZATION:
    "api/v{version}/Account/GetProfilePersonalisationByGuid",
  UPDATE_PROFILE_VISIBILITY:
    "api/v{version}/Account/SaveAccountProfileVisibility",
  GET_PROFILE_VISIBILITY: "api/v{version}/Account/GetAccountProfileVisibility",
  UPDATE_COMPANY_PROFILE_VISIBILITY:
    "api/v{version}/Account/SaveCompanyProfileVisibility",
  GET_COMPANY_PROFILE_VISIBILITY:
    "api/v{version}/Account/GetCompanyProfileVisibility",
  INVITE_A_FRIEND: "api/v{version}/Account/InviteAFriend",
  CONTACT_US: "api/v{version}/Account/ContactUs",
  DELETE_ACCOUNT: "api/v{version}/Account/DeleteAccount",
  GET_NOTIFICATIONS: "api/v{version}/Account/GetAccountNotificationsByGuid",
  SUBSCRIBE: "api/v{version}/Account/SubscribeToNewsLetter",
  SAVE_ABOUT_ME: "api/v{version}/Account/SaveAboutMeForAccount",
  SAVE_SOCIAL_MEDIA_LINKS:
    "api/v{version}/Account/SaveSocialMediaLinksForAccount",
  SUBSCRIBE_WITH_EMAIL: "api/v{version}/Account/SubscribeToNewsLetterWithEmail",
  UPLOAD_COVER_PICTURE: "api/v{version}/Account/UpdateAccountWithCoverPic",

  UPLOAD_PROFILE_PIC: "api/v{version}/Account/UpdateAccountWithProfilePic",

  CHANGE_EMAIL: "api/v{version}/Account/ChangeEmail",
  ACCOUNT_DETAILS: "api/v{version}/Account/{Guidid}",
  FOLLOWERS_LIST: "api/v{version}/Account/GetFollowerListAccount",
  FOLLOWED_LIST: "api/v{version}/Account/GetAccountFollowedList",
  SAVE_FOLLOW_COUNTER:
    "api/v{version}/Account/SaveFollowUnFollowCounterAccount",
  SAVE_HEADLINE: "api/v{version}/Account/SaveHeadlineForAccount",
  GET_MUTUAL_FOLLOWERS: "api/v{version}/Account/GetAccountMutualFollowers",
  SAVE_SKIP_SETTING_FOR_INVITE_EMPLOYEES:
    "api/v{version}/Account/SaveSkipSettingForInviteEmployee",
  GET_COMPANY_PERMISSIONS: "api/v{version}/Account/GetAllPermissions",
  UPDATE_COMPANY_PERMISSIONS: "api/v{version}/Account/AddRolesWithPermissions",
  SAVE_STRIPE_CUSTOMER_ID:
    "api/v{version}/Account/SaveCustomerIdDetailsForAccount",
  SAVE_CURRENT_PLAN_DETAILS:
    "api/v{version}/Account/SaveCurrentPlanDetailsAndSubscriptionForAccount",
  SAVE_STRIPE_ACCOUNT_ID:
    "api/v{version}/Account/SaveStripeIdDetailsForAccount",
  SAVE_STRIPE_ACCOUNT_ID_REGISTERED:
    "api/v{version}/Account/SaveStripeRegisteredForAccount",
  SAVE_STRIPE_CUSTOMER_ID_BY_SELLER:
    "api/v{version}/Account/SaveCustomerIdBasedUponSellerId",
  GET_STRIPE_CUSTOMER_ID_BY_SELLER:
    "api/v{version}/Account/GetCustomerIdBasedUponSellerId",
  SAVE_POP_UP_SETTINGS:
    "api/v{version}/Account/SaveSkipPlanPopupSettingForAccount",
  GET_ALL_LANGUAGES: "api/v{version}/Account/GetAllLanguages",
  SAVE_LANGUAGES: "api/v{version}/Account/SaveLanguagesForAnAccount",
  REQUEST_RECEIVED: "api/v{version}/Email/SendEmailRequest",
};

const AccountPayments = {
  // FETCH_CC:
  //   "api/v{version}/AccountPayments/GetCreditCardAccountDetailsByAccountGuid",
  // FETCH_PAYPAL_ACCOUNT:
  //   "api/v{version}/AccountPayments/GetPayPalAccountsByGuid",
  // UPDATE_CC: "api/v{version}/AccountPayments/SaveUserCardDetails",
  // UPDATE_PAYPAL_ACCOUNT:
  //   "api/v{version}/AccountPayments/SaveUserPayPalAccountDetails",
};

const Company = {
  FETCH_COMPANY_DETAILS_BY_USERNAME:
    "api/v{version}/Company/GetCompanyDetailsByUserName",
  COMPANY_LIST: "api/v{version}/Company/GetAllCompanies",
  EMPLOYESS_IN_COMPANY: "api/v{version}/Company/GetCompanyNumOfEmployees",
  COMPANY_TYPES: "api/v{version}/Company/GetCompanyIndustryType",
  // GET_FILTERED_COMPANIES: "api/v{version}/Company/FilteredCompanies",
  GET_FILTERED_COMPANIES_1: "api/v{version}/Company/GetFilteredCompanies",
  COMPANIES_AUTOCOMPLETE_FILTER:
    "api/v{version}/Company/GetAllCompaniesAutoComplete",
  INVITE_EMPLOYEES_FOR_REGISTRATION:
    "api/v{version}/Company/InviteEmployeeForRegisteration",
  GET_ALL_COMPANY_EMPOLOYEES: "api/v{version}/Company/GetAllCompanyEmployees",
  GET_COMPANY_ROLES: "api/v{version}/Company/GetCompanyRoles",
  GET_COMPANY_LICENCES: "api/v{version}/Company/GetCompanyLicences",
  DELETE_COMPANY_EMPLOYEES: "api/v{version}/Company/DeleteCompanyEmployees",
  UPDATE_EMPLOYEES_ROLE: "api/v{version}/Company/UpdateInviteEmployeeDetails",
  UPDATE_EMPLOYEES_LICENSE:
    "api/v{version}/Company/UpdateInviteEmployeeDetails",
  GET_COMPANY_EMPLOYEE_FOR_SEARCH:
    "api/v{version}/Company/GetCompanyEmployeesForSearch",
};

const Designer = {
  DESIGNER_DETAILS: "api/v{version}/Designer/GetDesignerDetailsByUserName",
  DESIGNERS_LIST: "api/v{version}/Designer/GetAllDesigners",
  DESIGNERS_SORT_TYPE: "api/v{version}/Designer/GetAllSortTypes",
  TIME_SORT_TYPE: "api/v{version}/Designer/GetAllSortTimeList",
  AVAILABILITY_TYPES: "api/v{version}/Designer/GetAllAvailabilityList",
  TOOL_TYPES: "api/v{version}/Designer/GetListOfTools",
  DESIGNER_BY_NAME: "api/v{version}/Designer/GetListOfDesignerByUserName",
  LATEST_DESIGNERS_LIST:
    "api/v{version}/Designer/GetDesignerListUploadedProductsToday",
  DESIGNERS_LIST_BY_NAME: "api/v{version}/Designer/GetListOfDesignerByUserName",
  GET_FILTERED_DESIGNER: "api/v{version}/Designer/FilteredDesigners",
  YOUNG_DESIGNERS_LIST: "api/v{version}/Designer/GetYoungTalents",
  DESIGNER_PRODUCT: "api/v{version}/Designer/GetDesignerWork",
  FILTERED_DESIGNERS: "api/v{version}/Designer/GetFilteredDesigners",
  FILTERED_YOUNG_DESIGNERS:
    "api/v{version}/Designer/GetFilteredYoungTalentDesigners",
};

const AccountCertificates = {
  CERTIFICATE_UPLOAD: "api/v{version}/AccountCertificates/CertificateUpload",
  CERTIFICATE_LISTS: "api/v{version}/AccountCertificates/GetAllCertificates",
};

const Product = {
  PRODUCT_LIST: "api/v{version}/Product/GetAllProduct",
  GET_PRODUCT_BY_ID: "api/v{version}/Product/GetProductDetailsByProductId",
  GET_PRODUCT_WITH_EACH_UPLOAD_TYPE:
    "api/v{version}/Product/GetProductDetailWithEachUploadedTypeForProd",
  GET_PRODUCT_BY_ID_FOR_PRODUCT_SECTION:
    "api/v{version}/Product/GetProductDetailByGuidIdForProductSection",
  // CATEGORY_LIST: "api/v{version}/Product/GetAllProductCategories",
  // MY_PRODUCTS: "api/v{version}/Product/GetAllProductsByGuid",
  // MY_PRODUCTS: "api/v{version}/Product/GetAllProductUploadedByAccount",
  MY_PRODUCTS: "api/v{version}/Product/GetAllProduct",
  MY_DRAFT_PRODUCTS: "api/v{version}/Product/GetAllProductInDraftsByAccount",
  FETCH_PRODUCT_REVERSAL_SELLER:
    "api/v{version}/Product/GetSingleAccountProductReversalByTempToken",
  UPDATE_PRODUCT_REVERSAL_SELLER:
    "api/v{version}/Product/ProductReversalBySellerValidation",
  FETCH_ALL_PRODUCT_REVERSAL_SELLER:
    "api/v{version}/Product/GetListOfReversalPerBuyer",
  FETCH_ALL_PRODUCT_REVERSAL_BY_SELLER:
    "api/v{version}/Product/GetListOfReversalPerSeller",
  SET_PRODUCT_FAVOURITE:
    "api/v{version}/Product/SaveTheLikeUnlikeCounterProduct",
  SET_PRODUCT_VIEW: "api/v{version}/Product/SaveTheViewProduct",
  UPLOAD_PRODUCT: "api/v{version}/Product/UploadProduct",
  EDIT_PRODUCT: "api/v{version}/Product/EditAProduct",
  PRODUCT_REVERSAL: "api/v{version}/Product/RaiseProductReversalByBuyer",
  // PRODUCT_FILTER: "api/v{version}/Product/FilteredProducts",
  PRODUCT_FILTER: "api/v{version}/Product/GetAllFilteredProducts",
  UPLOAD_PRODUCT_FILE: "api/v{version}/Product/Upload",
  UPLOAD_PRODUCT_FILE_FOR_DIFFERENT_CATEGORIES:
    "api/v{version}/Product/ProductFileUploadforDifferentCategories",
  DELETE_PRODUCT_FILE_FOR_DIFFERENT_CATEGORIES:
    "api/v{version}/Product/DeleteUploadedProductsByDetailsId",
  CLEAR_UPLOAD_PRODUCT_FILE_FOR_DIFFERENT_CATEGORIES:
    "api/v{version}/Product/ClearAllUploadedProducts",
  GET_FILTERED_PRODUCTS: "/api/v{version}/Product/FilteredProducts",
  PRODUCTS_AUTOCOMPLETE_FILTER:
    "api/v{version}/Product/GetAllProductAutoComplete",
  GET_PRODUCT_MATERIALS: "api/v{version}/Product/GetAllProductMaterial",
  GET_PRODUCT_SIZE: "api/v{version}/Product/GetAllProductSize",
  GET_CURRENCIES: "api/v{version}/Product/GetAllCurrencies",
  DELETE_PRODUCT_BY_PRODUCT_ID:
    "api/v{version}/Product/DeleteProductByProductId",
  UNPUBLISH_PRODUCT_BY_PRODUCT_ID:
    "api/v{version}/Product/UnpublishProdByProductId",
  YOUNG_TALENT_PRODUCT_LIST:
    "api/v{version}/Product/GetAllProductUploadedByYoungTalents",
  GET_PRODUCT_TAG: "api/v{version}/Product/GetAllProductTags",
  PRODUCT_THUMBNAIL_UPLOAD: "api/v{version}/Product/ProductThumbnailUpload",
  SAVED_PRODUCT_LIST: "api/v{version}/Product/GetAllProductSavedByAccount",
  SAVE_PRODUCT: "api/v{version}/Product/ProductSavedByAccount",
  PRODUCT_THUMBNAIL_UPLOAD_DND:
    "api/v{version}/Product/ProductThumbnailUploadDragDrop",
  GET_ALL_CATEGORIES: "api/v{version}/Product/GetAllCategories",
  GET_ALL_LIBRARY_CATEGORIES: "api/v{version}/Product/GetAllLibraryCategories",
  PRODUCT_DETAILS_DOWNLOAD: "api/v{version}/Product/DownloadProductByProductId",
  GET_ALL_HOME_PRODUCTS:
    "api/v{version}/Product/GetHomePageProductsByAccountGuid",
  GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY:
    "api/v{version}/Product/GetAllCategoriesAttributesHierarchy",
  GET_ALL_PRODUCT_CATEGORIES_HIERARCHY:
    "api/v{version}/Product/GetAllProductCategoriesHierarchy",
  PLACE_THE_ORDER: "api/v{version}/Product/PlaceTheOrder",
  FETCH_PURCHASED_ORDERS:
    "api/v{version}/Product/GetAllPurchasedProductsByAccount",
  FETCH_SOLD_ORDERS: "api/v{version}/Product/GetAllSoldProductsByAccount",
  FETCH_MY_SUBSCRIPTIONS:
    "api/v{version}/Account/GetsubscriptionDetailsOfAnAccount",
  // "api/v{version}/Product/GetAllPurchasedProductsByAccount",
  FETCH_ORDER_DETAILS: "api/v{version}/Product/GetProductDetailsByOrderId",
  REFUND_ORDER: "api/v{version}/Product/UpdateReturnTheProductSettingInOrders",
  ORDER_DOWNLOADED:
    "api/v{version}/Product/UpdateDownloadProductSettingInOrders",
  ORDER_DOWNLOAD_INVOICE: "api/v{version}/Product/DownloadInvoiceByOrderId",
  SEARCH_ITEMS_BY_CATEGORY: "api/v{version}/Product/SearchItemsByCategory",
  CROUSAL_PRODUCTS: "api/v{version}/Product/GetHomePageCarouselProducts",
  SAVE_PRODUCT_COMMENTS: "api/v{version}/Product/SaveTheProductComments",
  GET_PRODUCT_COMMENTS: "api/v{version}/Product/GetProductCommentsByProdId",
  DELETE_PRODUCT_COMMENT_BY_ID: "api/v{version}/Product/DeleteCommentById",
};

const ProductPurchase = {
  PRODUCR_PURCHASE: "api/v{version}/ProductPurchase/ProductPurchase",
};

const Invoice = {
  INVOICE_LIST: "api/v{version}/Invoice/GetInvoiceDetailsByGuid",
  FETCH_INVOICE_SETTINGS: "api/v{version}/Invoice/GetInvoiceSettingsByGuid",
  UPDATE_INVOICE_SETTINGS: "api/v{version}/Invoice/SaveInvoiceSettingDetails",
  GET_BILLING_INFO:
    "api/v{version}/Account/GetBillingInfoOfAnAccount?AccountGuid={Guidid}",
  SAVE_BILLING_INFO: "api/v{version}/Account/AddBillingInfoForAnAccount",
  SUBSCRIPTION_INVOICE: "api/v{version}/Account/DownloadSubscriptionInvoice",
};

const License = {
  FETCH_LICENSE: "api/v{version}/License",
  INDIVIDUAL_LICENCE: "api/v{version}/License/AddLicenceToIndividual",
  COMPANY_LICENCE: "api/v{version}/License/AddLicenceToCompany",
  ADD_LICENCE_KEY: "api/v{version}/License/ValidateLicenseKey",
  GET_COMPANY_LICENSES:
    "api/v{version}/License/GetAllLicenseDetailsByCompanyGUID",
  GET_ALL_COMPANY_LICENSE: "api/v{version}/License/GetAllCompanyLicense",
  GET_ALL_INDIVIDUAL_LICENSE: "api/v{version}/License/GetAllIndividualLicense",
};

const AccountExperience = {
  GET_ALL_EMPLOYEE_TYPE:
    "api/v{version}/AccountExperience/GetAllEmploymentType",
  GET_ALL_COMPANY_TYPE: "api/v{version}/AccountExperience/GetAllCompanyType",
  GET_ALL_DESIGNATIONS: "api/v{version}/AccountExperience/GetAllDesignations",
  GET_ALL_LOCATIONS: "api/v{version}/AccountExperience/GetAllLocations",
  SAVE_USER_EXPERIENCE:
    "api/v{version}/AccountExperience/SaveUserExperienceDetails",
  GET_EXPERIENCE_DETAILS:
    "api/v{version}/AccountExperience/GetExperienceDetailsByAccountGuid",
  DELETE_EXPERIENCE_BY_ID:
    "api/v{version}/AccountExperience/DeleteAccountExperienceById",
  SAVE_EXPERIENCE_POSITION:
    "api/v{version}/AccountExperience/SaveExperiencePosition",
};

const AccountAcademics = {
  GET_DEGREES: "api/v{version}/AccountAcademics/GetAllDegrees",
  GET_UNIVERSITIES: "api/v{version}/AccountAcademics/GetAllUniversitites",
  FIELDS_OF_STUDY: "api/v{version}/AccountAcademics/GetAllFieldsOfStudy",
  UPLOAD_ACADEMICS: "api/v{version}/AccountAcademics/SaveUserAcademicsDetails",
  ACADEMICS_DETAIL:
    "api/v{version}/AccountAcademics/GetAcademicsDetailsByAccountGuid",
  DELETE_ACADEMICS:
    "api/v{version}/AccountAcademics/DeleteAccountAcademicsById",
  SAVE_ACADEMIC_POSITION:
    "api/v{version}/AccountAcademics/SaveAcademicsPosition",
};

const AccountSkills = {
  GET_ALL_SKILLS: "api/v{version}/AccountSkills/GetAllSkills",
  SAVE_SKILLS: "api/v{version}/AccountSkills/SaveSkillsForAccount",
  GET_SKILL_DETAILS:
    "api/v{version}/AccountSkills/GetSkillDetailsByAccountGuid",
};

const AccountWebLink = {
  WEBLINK_SAVE: "api/v{version}/AccountWebLink/SaveWebLinksDetailsForAccount",
  WEBLINK_DELETE: "api/v{version}/AccountWebLink/DeleteWebLinksById",
  WEBLINK_DETAILS:
    "api/v{version}/AccountWebLink/GetWebLinkDetailsByAccountGuid",
  WEBLINK_POSITION: "api/v{version}/AccountWebLink/SaveWebLinksPosition",
};

const AccountResume = {
  SAVE_USER_RESUMES: "api/v{version}/AccountResume/SaveResumeDetailsForAccount",
  GET_ALL_RESUMES: "api/v{version}/AccountResume/GetResumeListByAccountGuid",
  DELETE_RESUME_BY_ID: "api/v{version}/AccountResume/DeleteResumeById",
  SAVE_RESUMES_POSITION: "api/v{version}/AccountResume/SaveResumePosition",
};

const Jobs = {
  GET_ALL_JOBS_WORKPLACE: "api/v{version}/Job/GetAllJobWorkplaceType",
  GET_ALL_JOBS_APPLY_MEDIUM: "api/v{version}/Job/GetJobApplyMedium",
  GET_ALL_JOBS_EXPERIENCES: "api/v{version}/Job/GetJobExperienceRequired",
  POST_JOB: "api/v{version}/Job/PostAJob",
  GET_ALL_JOBS_POSTED_BY_USER: "api/v{version}/Job/GetAllJobPostedByUser",
  GET_ALL_JOB_POSTED: "api/v{version}/Job/GetAllJobPosted",
  APPLY_FOR_JOB: "api/v{version}/Job/JobAppliedByAccount",
  SAVE_JOB: "api/v{version}/Job/JobSavedByAccount",
  GET_JOBS_SAVED_BY_USER: "api/v{version}/Job/GetJobSavedByUser",
  GET_JOBS_APPLIED_BY_USER: "api/v{version}/Job/GetJobAppliedByUser",
  GET_ALL_RATES_TYPE: "apI/v{version}/Job/GetAllRatesType",
  EDIT_JOB_POST: "api/v{version}/Job/EditJob",
  GET_ALL_DRAFTED_JOBS: "api/v{version}/Job/GetAllDraftedJobForAnAccount",
  DELETE_POSTED_JOB: "api/v{version}/Job/DeletePostedJobById",
  ACTIVATE_JOB_BY_ID: "api/v{version}/Job/ActivateJobById",
  DEACTIVATE_JOB_BY_ID: "api/v{version}/Job/DectivateJobById",
  // POST_FILTERED_JOBS: "api/v{version}/Job/FilteredJobs",
  GET_FILTERED_JOBS: "api/v{version}/Job/GetFilteredPostedJobs",
  GET_JOBS_SALARY: "api/v{version}/Job/GetJobSalaryRange",
  JOBS_AUTOCOMPLETE_FILTER: "api/v{version}/Job/GetAllJobTitleAutocomplete",
  APPLY_JOB_ON_DC: "api/v{version}/Job/JobAppliedByAccount",
  JOB_APPLICANTS: "api/v{version}/Job/GetAllJobApplicantsList",
  DELETE_PORTFOLIO_BY_ID: "api/v{version}/Job/DeletePortfolioById",
  GET_JOB_BY_ID: "api/v{version}/Job/GetPostedJobDetailByJobId",
};

const Employees = {
  FETCH_EMPLOYEE_DETAILS:
    "api/v{version}/Company/GetCompanyEmployeesEmailFromEmpGuid",
};

const LibraryCategoriesList = {
  // LIBRARY_CATEGORIES_LIST: "api/v{version}​/Product​/GetAllLibraryCategories",
  LIBRARY_CATEGORIES_LIST: "api/v{version}/Company/GetCompanyRoles",
};

const Library = {
  LIBRARY_UPLOAD: "api/v{version}/Library/ProductLibraryUpload",
  LIBRARY_PRODUCT_LIST: "api/v{version}/Library/GetAllLibraryProductByGuid",
  SAVED_LIBRARY_PRODUCT_LIST:
    "api/v{version}/Library/GetAllLibraryProductSavedByAccount",
  SAVE_LIBRARY_PRODUCT: "api/v{version}/Library/LibraryProductSavedByAccount",
  GET_LIBRARY_PRODUCT_BY_ID:
    "api/v{version}/Library/GetAllLibraryItemDetailsByLibraryId",
  MY_LIBRARY_DRAFT_PRODUCTS:
    "api/v{version}/Library/GetAllLibraryProductInDraftsByAccount",
  MY_LIBRARY_PRODUCTS: "api/v{version}/Library/GetDesignerWorkForLibrary",
  DELETE_LIBRARY_PRODUCT_BY_PRODUCT_ID:
    "api/v{version}/Library/DeleteUploadedLibraryItemByLibraryId",
  GET_ALL_LIBRARY_CATEGORIES_HIERARCHY:
    "api/v{version}/Library/GetAllLibraryCategoriesHierarchy",
  SET_LIBRARY_PRODUCT_VIEW:
    "api/v{version}/Library/SaveTheViewCounterForLibraryProduct",
  LIBRARY_PRODUCT_DETAILS_DOWNLOAD:
    "api/v{version}/Library/DownloadLibraryByLibraryId",
  SAVE_LIBRARY_PRODUCT_COMMENTS:
    "api/v{version}/Library/SaveTheLibraryComments",
  GET_LIBRARY_PRODUCT_COMMENTS:
    "api/v{version}/Library/GetLibraryCommentsByLibraryId",
  SET_LIBRARY_FAVOURITE:
    "api/v{version}/Library/SaveTheLikeUnlikeCounterLibraryProduct",
};

const Cart = {
  ADD_TO_CART: "api/v{version}/Product/SaveTheProductItemsToCart",
  FETCH_CART: "api/v{version}/Product/GetProductInCartDetailsByGuidId",
  CLEAR_CART: "api/v{version}/Product/ClearAllTheProductItemsFromCart",
  REMOVE_FROM_CART: "api/v{version}/Product/DeleteTheProductItemsFromCart",
};

const ENDPOINTS = {
  ...Login,
  ...Account,
  ...AccountPayments,
  ...Company,
  ...Designer,
  ...AccountCertificates,
  ...Product,
  ...ProductPurchase,
  ...Invoice,
  ...License,
  ...AccountExperience,
  ...AccountAcademics,
  ...AccountSkills,
  ...AccountWebLink,
  ...AccountResume,
  ...Jobs,
  ...Employees,
  ...LibraryCategoriesList,
  ...Library,
  ...Cart,
};

export default ENDPOINTS;
