/*
 **	User name
 **	Created	6/20/2023
 **	OrderSuccess.tsx
 */

import React from "react";
import { Box, Typography, Modal } from "@mui/material";
import { LightModeCross } from "app/components/Logo/logos";
import newLicenseStyle from "styles/newLicenseStyle";
import { useNavigate } from "react-router";
import {
  BlackBorderedButton,
  GradientColoredButton,
} from "app/components/UIFormFields";
import { useAppSelector } from "app/utils/hooks";
import UniversalLoader from "app/components/Loader";
import RouteLinks from "app/navigation/RouteLinks";

const OrderSuccess = () => {
  const classes = newLicenseStyle();
  const navigate = useNavigate();

  const ordersData = useAppSelector((state) => state.orders);

  const {
    ReactComponent: GreenTick,
  } = require("../../../assets/logo/Group 721.svg");

  const setConfirmationMessage = () => {
    navigate("/", { replace: true });
  };
  const handleNavigate = () => {
    navigate(RouteLinks.ORDERS_AND_INVOICES, { replace: true });
  };

  return (
    <Modal open={true}>
      <Box
        className={classes.modalView}
        style={{ width: "680px", height: "485px" }}
      >
        <Box className={classes.modalBox}>
          <LightModeCross
            onClick={() => setConfirmationMessage()}
            className={classes.crossButton}
          />
          {ordersData?.loading ? (
            <UniversalLoader />
          ) : ordersData?.data?.orderId ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                position: "relative",
              }}
            >
              <GreenTick className={classes.tick} />

              <Box>
                <Typography className={classes.statusMessage}>
                  Congratulations!
                </Typography>
                <Typography className={classes.statusMessage}>
                  Your payment was successful.
                </Typography>
              </Box>
              <Box style={{ margin: "24px" }}>
                <Typography style={{ textAlign: "center" }}>
                  You can download your product anytime from purchases.
                </Typography>

                <Typography style={{ textAlign: "center" }}>
                  Your order confirmation has been sent on your Email address
                </Typography>
              </Box>
              <Typography style={{ fontSize: "27px" }}>
                Order ID : {ordersData?.data?.orderId}
              </Typography>
              <Box style={{ display: "flex" }}>
                <BlackBorderedButton
                  style={{ width: "180px", margin: "12px" }}
                  onClick={handleNavigate}
                >
                  See Your purchases
                </BlackBorderedButton>
                <GradientColoredButton
                  style={{ width: "162px", margin: "12px" }}
                  onClick={handleNavigate}
                >
                  See your invoices
                </GradientColoredButton>
              </Box>
            </Box>
          ) : (
            <Box>{ordersData?.data?.responseMessage}</Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default OrderSuccess;
