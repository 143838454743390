/*
 **	User name
 **	Created	5/25/2023
 **	inprogress.tsx
 */

import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

function Inprogress() {
  return (
    <Box sx={{ padding: "34px 42px 0px 34px" }}>
      <Box
        sx={{
          width: "100%",
          backgroundImage: "linear-gradient(89deg, #ffeef3 -8%, #ddfce8 121%)",
          boxShadow: "0 8px 24px 0 rgba(43, 38, 57, 0.1)",
          borderRadius: "20px",
          position: "relative",
          zIndex: 2,
          opacity: 0.9,
        }}
      ></Box>
      <Box
        sx={{
          // marginTop: "100px",

          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              alignContent: "center",
              display: "table-caption",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                fontSize: "91px",
                fontWeight: "bold",
              }}
              className="oppsText"
            >
              Oops!!
            </Typography>
            <Typography
              sx={{
                fontSize: "21px",
              }}
            >
              The page you are looking for <br /> is still in progress.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            padding: "45px 0px 20px 0px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="../assets/images/Ellipse1557.png" alt="inprogress" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Inprogress;
