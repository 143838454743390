import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import RouteLinks from "app/navigation/RouteLinks";
import { makeStyles } from "@mui/styles";
import { WhiteBorderedButton, PurpleButton } from "../UIFormFields";
import AdvanceCookieSettings from "./AdvanceCookieSettings";

const useStyles = makeStyles((theme) => ({
  cookies: {
    position: "fixed",
    bottom: 0,
    background: "#2B2639",
    left: 0,
    right: 0,
    zIndex: 9,
    display: "flex",
    justifyContent: "center",
    paddingTop: 24,
    paddingBottom: 24,
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "var(--container-width)",
    width: "100%",
    // backgroundColor: "green",
  },
  textBox: {
    flex: 1,
    maxWidth: 628,
    // backgroundColor: "blue"
  },
  textBox1: {
    // backgroundColor: "purple"
  },
  ButtonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    flex: 1,
    alignItems: "center",
    // backgroundColor: "yellow",
  },
  Buttons: { display: "flex", flexDirection: "row" },
  AcceptAll: {},
  Decline: { marginRight: "24px !important" },
  [theme.breakpoints.down("md")]: {
    Decline: { width: "50%", margin: "5px" },
    AcceptAll: { width: "50%", margin: "5px" },
    cookies: {
      // position: "fixed",
      // bottom: 0,
      // background: "red",
      width: "100vw",
      // left: 0,
      // right: 0,
      // zIndex: 9,
      display: "flex",
      flexDirection: "column",
      // justifyContent: "center",
      // paddingTop: 24,
      // paddingBottom: 24,
    },
    mainBox: {
      display: "flex",
      flexDirection: "column",
      // alignItems: "center",
      // maxWidth: "var(--container-width)",
      // width: "100%",
      // backgroundColor: "green",
    },
    ButtonBox: {
      flexDirection: "column",
      width: "100% !important",
    },
    Buttons: {
      width: "100%",
    },
  },
}));

const CookieNotice = ({ onClose }) => {
  const [showAdvanceCookieSettings, setShowAdvanceCookieSettings] =
    useState(false);

  const classes = useStyles();

  return (
    <Box
      className={classes.cookies}
      // style={
      //   {
      //     // position: "fixed",
      //     // bottom: 0,
      //     // background: "#2B2639",
      //     // left: 0,
      //     // right: 0,
      //     // zIndex: 9,
      //     // display: "flex",
      //     // justifyContent: "center",
      //     // paddingTop: 24,
      //     // paddingBottom: 24,
      //   }
      // }
    >
      {showAdvanceCookieSettings && (
        <AdvanceCookieSettings
          setOpen={setShowAdvanceCookieSettings}
          onClose={onClose}
        />
      )}
      <Box
        className={classes.mainBox}
        style={{
          padding: "5px",
        }}
      >
        <Box
          className={`${classes.textBox} d-none d-md-block`}
          style={
            {
              // flex: 1,
              // maxWidth: 628,
              // backgroundColor: "blue",
            }
          }
        >
          <Typography style={{ color: "#FFF", fontSize: 15, fontWeight: 400 }}>
            Your privacy matters to us. By clicking &quot;Accept,&quot; you
            agree to our use of cookies and tracking technologies as outlined in
            our{" "}
            <Typography
              style={{
                color: "#ffaf48",
                fontSize: 15,
                textDecoration: "underline",
                fontWeight: "normal",
              }}
              component={Link}
              to={RouteLinks.PRIVACY_POLICY}
            >
              Privacy Policy
            </Typography>
          </Typography>
        </Box>
        <Box
          className={`${classes.textBox1} d-block d-md-none`}
          style={{
            // flex: 1,
            // maxWidth: 628,
            // backgroundColor: "blue",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            style={{ color: "#FFF", fontSize: 15, fontWeight: 400 }}
            className="text-center"
          >
            Your privacy matters to us. By clicking &quot;Accept,&quot; you
            agree to our use of cookies and tracking technologies as outlined in
            our{" "}
          </Typography>{" "}
          <Box
            style={{
              display: "flex",
              // backgroundColor: "greenyellow",
              justifyContent: "center",
              margin: "auto  auto 20px auto",
            }}
          >
            <Typography
              style={{
                color: "#ffaf48",
                fontSize: 15,
                textDecoration: "underline",
                fontWeight: "normal",

                // backgroundColor: "grey",
              }}
              component={Link}
              to={RouteLinks.PRIVACY_POLICY}
              className="text-center"
            >
              Privacy Policy
            </Typography>
          </Box>
        </Box>
        <Box className={classes.ButtonBox}>
          <Box className="me-0 me-md-3 mb-2 mb-md-0">
            <Typography
              style={{
                color: "#ffaf48",
                fontSize: 15,
                textDecoration: "underline",
                fontWeight: 500,
              }}
              component={Link}
              to="#"
              onClick={() => setShowAdvanceCookieSettings(true)}
            >
              Advanced Cookie Settings
            </Typography>
          </Box>
          <Box className={classes.Buttons}>
            <Box className={classes.Decline}>
              <WhiteBorderedButton onClick={onClose}>
                Decline
              </WhiteBorderedButton>
            </Box>
            <Box className={classes.AcceptAll}>
              <PurpleButton onClick={onClose}>Accept All</PurpleButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CookieNotice;
