import React from "react";

interface AppInitContextInterface {
  isUserLoggedIn: boolean;
  showGuestLogin: () => void;
}

export const AppInitContext = React.createContext<AppInitContextInterface>({
  isUserLoggedIn: false,
  showGuestLogin: () => null,
});
