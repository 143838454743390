/*
 **	User name
 **	Created	5/25/2023
 **	index.tsx
 */

import React, { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Badge,
  Stack,
  IconButton,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  isCompanyUser,
  isIndividualUser,
  isEmployeeUser,
} from "app/utils/helper";
import { IRootState } from "app/redux/reducers";
import { userRegisterReducerTypes } from "app/redux/reducers/userReducer";
import { accessTokenSelector } from "app/redux/selectors/user-selector";
import { useAppSelector } from "app/utils/hooks";
import RouteLinks from "app/navigation/RouteLinks";

import { categoriesActionTypes } from "app/redux/reducers/categories";
import NotificationMenu from "./notifications";
// import ColorModeSwitch from "../ColorModeSwitch";
import { LightCart, LightMessage, LightSearch } from "../Logo/logos";
import NewNavBar from "./newNavbar";
import UploadWork from "../UploadWork";
import LogoComponent from "../Logo";
import { PlainButton, GradientColoredButton } from "../UIFormFields/Buttons";

export const useHeaderStyles = makeStyles((theme) => ({
  arrowIcon: {
    padding: "35px 0px 35px 24px",
    cursor: "pointer",
  },
  mainIconBox: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "-20px",
  },
  HeaderTitle: {
    padding: "34px 0px 34px 19px",
  },
  mainIcon: {
    padding: "13px 0px 13px 24px",
    cursor: "pointer",
  },
  navBar: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    alignText: "center",
    marginLeft: "-10px",
  },
  NewNavBar: { display: "flex" },
  NewNavBar_1: { display: "none" },
  mainLogo: { width: 74, height: 74 },
  gradientButton: {
    margin: "0px 24px 0 20px !important",
    width: "120px !important",
    height: "40px !important",
  },
  plainButton: {
    fontWeight: "bold !important",
    fontSize: "15px !important",
    color: "#2b2639 !important",
    textTransform: "capitalize",
    // border: "1px solid !important",
  },
  [theme.breakpoints.down("md")]: {
    NewNavBar: { display: "none" },
    NewNavBar_1: { display: "flex", flexDirection: "column" },
    mainLogo: { width: "60px", height: "60px" },
    gradientButton: {
      margin: "0px !important",
      width: "45% !important",
    },
    plainButton: {
      width: "45% !important",
      textTransform: "capitalize !important",
      border: "1px black solid",
    },
  },
}));

const NewHeader = (props) => {
  const { setSearchState } = props;
  const classes = useHeaderStyles();
  const userData = useSelector((state: IRootState) => state.register);
  const accessToken = useSelector(accessTokenSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userAdditionalInfo = userData?.userAdditionalInfo;

  const cartData = useAppSelector((state) => state.cart);
  const companyInfo = useSelector((state: IRootState) => state.companyInfo);
  const allCompanyEmployeesData = companyInfo?.allCompanyEmployesData || [];

  const handleCloseUserMenu = (key) => {
    if (key === "Upgrade" || key === "My Subscription") {
      if (isIndividualUser(userAdditionalInfo?.profileType)) {
        navigate({ pathname: "/current-plan" }, { replace: false });
      } else {
        navigate({ pathname: "/current-plan" }, { replace: false });
      }
    }

    if (key === "Logout") {
      dispatch({
        type: userRegisterReducerTypes.LOGOUT_USER,
        navigate,
      });
      dispatch({
        type: categoriesActionTypes.GET_ALL_CATEGORIES,
      });
    }

    if (
      key === "My Profile" &&
      isIndividualUser(userAdditionalInfo?.profileType)
    ) {
      navigate({ pathname: "/designer-profile" }, { replace: false });
    }

    if (key === "My Profile") {
      if (isCompanyUser(userAdditionalInfo?.profileType)) {
        navigate({ pathname: "/company-profile/" }, { replace: false });
      }

      if (isEmployeeUser(userAdditionalInfo?.profileType)) {
        navigate({ pathname: "/employee-profile" }, { replace: false });
      }
    }

    if (key === "Account Settings") {
      navigate({ pathname: "/settings" }, { replace: false });
      navigate({ pathname: "/account-settings" }, { replace: false });
    }

    if (key === "Help") {
      navigate({ pathname: "/help" }, { replace: false });
    }
    if (key === "My Dashboard") {
      navigate({ pathname: "/my-dashboard" }, { replace: false });
    }

    if (key === "Orders and Subscription") {
      navigate(
        { pathname: RouteLinks.ORDERS_AND_INVOICES },
        { replace: false }
      );
      window.sessionStorage.setItem("orderType", "ordersHistory");
    }
    if (key === "Invoice") {
      navigate({ pathname: "/:username/invoice" }, { replace: false });
    }

    if (key === "Employees") {
      navigate({ pathname: "/employees" }, { replace: false });
    }

    if (key === "Purchase and Sale") {
      navigate({ pathname: "/purchase&Sale" }, { replace: false });
    }
  };

  const settingMenu = useMemo(() => {
    if (
      isCompanyUser(userAdditionalInfo?.profileType) ||
      isEmployeeUser(userAdditionalInfo?.profileType)
    ) {
      return [
        "My Profile",
        "My Subscription",
        `Employees`,
        "Account Settings",
        "Orders and Subscription",
        "Help",
        "Logout",
      ];
    }
    return [
      "My Profile",
      "My Subscription",
      "Account Settings",
      "Orders and Subscription",
      "Help",
      "Logout",
    ];
  }, [userAdditionalInfo?.profileType]);

  const navBarFunctionRightDropdown = () => {
    return (
      <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navsbar">
        <Stack spacing={2} direction="row">
          {accessToken ? (
            <>
              <Badge>
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    p: 0,
                    width: "25px",
                    height: "25px",
                    margin: "auto",
                  }}
                  onClick={() => setSearchState(true)}
                >
                  <LightSearch />
                </IconButton>
              </Badge>{" "}
              <Badge
                badgeContent={cartData?.cart?.cartDetailsList?.length}
                color="error"
              >
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    p: 0,
                  }}
                  onClick={() => navigate("/shopping-cart")}
                >
                  <LightCart />
                </IconButton>
              </Badge>
              <Badge>
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    p: 0,
                  }}
                >
                  <LightMessage
                    onClick={() => window.open("/InProgress", "_blank")}
                  />
                </IconButton>
              </Badge>
              <Badge>
                <NotificationMenu isLoggedIn={undefined} />
              </Badge>
            </>
          ) : null}
        </Stack>

        {accessToken ? (
          <UploadWork />
        ) : (
          <Box
            className="d-flex w-100"
            style={{
              justifyContent: "space-between",
              height: "50px",
            }}
          >
            <Box className="d-none d-md-block m-auto">
              {" "}
              <Badge>
                <IconButton
                  sx={{
                    backgroundColor: "transparent",
                    p: 0,
                    width: "25px",
                    height: "25px",
                    margin: "auto 20px",
                  }}
                  onClick={() => setSearchState(true)}
                >
                  <LightSearch />
                </IconButton>
              </Badge>{" "}
              <Button
                className={`${classes.plainButton}`}
                onClick={() => navigate("/login")}
                style={{ textTransform: "capitalize" }}
              >
                {" "}
                Log In
              </Button>{" "}
            </Box>
            <PlainButton
              className={`${classes.plainButton} d-block d-md-none`}
              onClick={() => navigate("/login")}
              style={{ textTransform: "capitalize", border: "1px solid black" }}
            >
              Log In
            </PlainButton>

            <GradientColoredButton
              className={classes.gradientButton}
              onClick={() => navigate("/registration")}
            >
              Sign up
            </GradientColoredButton>
          </Box>
        )}

        {accessToken ? (
          <li className="nav-item">
            <div className="profile-user">
              <a className="nav-link" href="#">
                {userAdditionalInfo?.profilePictureUrl ? (
                  <img
                    style={{
                      width: "52px",
                      height: "52px",
                      borderRadius: "50%",
                      borderColor: "#999",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      backgroundColor: "#999",
                      objectFit: "cover",
                    }}
                    src={userAdditionalInfo?.profilePictureUrl}
                  />
                ) : (
                  <i className="fa-solid fa-user"></i>
                )}
                <i
                  className="fa-solid fa-angle-down"
                  style={{ color: "#999", marginLeft: 15 }}
                ></i>
              </a>
              <div className="profile">
                <div className="user-profile-head">
                  {userAdditionalInfo?.profilePictureUrl ? (
                    <img
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                      }}
                      src={userAdditionalInfo?.profilePictureUrl}
                      alt={userData.userInfo?.username}
                    />
                  ) : (
                    <i className="fa-solid fa-user"></i>
                  )}
                  <div style={{ cursor: "pointer" }} className="head-content">
                    <h4>{userData.userInfo?.username}</h4>
                    <p>{userAdditionalInfo?.profession}</p>
                  </div>
                </div>
                {settingMenu.map((setting) => (
                  <ul
                    key={setting}
                    onClick={() => handleCloseUserMenu(setting)}
                  >
                    <li>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>{setting}</span>
                        {setting === "Employees" && (
                          <Typography style={{ textAlign: "right" }}>
                            {allCompanyEmployeesData.length}
                          </Typography>
                        )}
                      </div>
                    </li>
                  </ul>
                ))}
              </div>
            </div>
          </li>
        ) : null}
        <Box
          className="d-md-none"
          style={{ position: "relative", top: "-25px" }}
        >
          <NewNavBar />
        </Box>
      </ul>
    );
  };

  return (
    <Box
      style={{
        borderRadius: "20px 20px 0px 0px",
        opacity: 0.99,
        zIndex: 3,
        position: "relative",
        backgroundColor: location.pathname !== "/" && "#fff",
        background:
          location.pathname === "/license"
            ? "linear-gradient(94deg, rgba(255, 255, 255, 0.76) -2%, rgba(255, 255, 255, 0) 120%)"
            : "var(--white)",
        boxShadow:
          location.pathname !== "/" && "0 8px 24px 0 rgba(43, 38, 57, 0.1)",
        maxWidth: "var(--container-width)",
      }}
      className="navbar navbar-expand-lg navbar-light"
      id="header-common-1"
    >
      <div className="container">
        <Box className={classes.mainIconBox} style={{ display: "flex" }}>
          <Box className={classes.mainIcon}>
            <LogoComponent
              className={classes.mainLogo}
              isLoginStyle={false}
              onClick={() => navigate("/")}
              isLoggedIn={undefined}
            />
          </Box>
          <Box
            // className={classes.NewNavBar}
            className="d-none d-md-block"
            style={{ position: "relative" }}
          >
            <div style={{ display: "flex" }}>
              <NewNavBar />
              {/* <Badge>
                 <IconButton
                   sx={{
                     backgroundColor: "transparent",
                     p: 0,
                     position: "relative",
                     left: "380px",
                     margin: "auto",
                   }}
                   onClick={() => setSearchState(true)}
                 >
                   <LightSearch />
                 </IconButton>
               </Badge>{" "} */}
            </div>
          </Box>
        </Box>

        <PlainButton
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <Box className="d-flex w-100 justify-content-between mb-4">
            <Badge>
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  p: 0,
                  width: "25px",
                  height: "25px",
                  margin: "auto 20px",
                }}
                onClick={() => setSearchState(true)}
              >
                <LightSearch />
              </IconButton>
            </Badge>

            <a
              // className="btn btn-primary"
              data-bs-toggle="offcanvas"
              href="#offcanvasExample"
              // role="button"
              aria-controls="offcanvasExample"
            >
              <span
                className="navbar-toggler-icon"
                style={{
                  width: "25px",
                  height: "25px",
                  margin: "auto",
                }}
              />
            </a>
          </Box>
          {/* offCanvas Functionality */}

          <div
            className="offcanvas offcanvas-end"
            data-bs-scroll="true"
            // tabIndex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
            style={{ overflow: "scroll" }}
          >
            <div className="offcanvas-header">
              <h5
                className="offcanvas-title"
                id="offcanvasExampleLabel"
                style={{
                  fontSize: "14px",
                  color: "#2B2639",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box style={{ display: "flex" }}>
                  <LogoComponent
                    style={{ width: "36px", height: "36px" }}
                    isLoginStyle={false}
                    onClick={() => navigate("/")}
                    isLoggedIn={undefined}
                  />{" "}
                </Box>{" "}
                Designer Capitol
              </h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              {navBarFunctionRightDropdown()}
            </div>
          </div>

          {/*  */}
        </PlainButton>
        <div
          className="collapse navbar-collapse d-none d-md-block"
          id="navbarSupportedContent"
        >
          {navBarFunctionRightDropdown()}
        </div>
      </div>
    </Box>
  );
};

export default NewHeader;
