import { userRegisterReducerTypes } from "./userReducer";

const initialState = {
  loading: false,
  error: null,
  data: null,
  status: null,
  profileVisibilityLoading: false,
  profileVisibilityError: null,
  profileVisibilityData: null,
  profileVisibilityStatus: null,
};

export const personalizationActionTypes = {
  GET_PROFILE_PERSONALIZATION: "GET_PROFILE_PERSONALIZATION",
  GET_PROFILE_PERSONALIZATION_REQUEST: "GET_PROFILE_PERSONALIZATION_REQUEST",
  GET_PROFILE_PERSONALIZATION_SUCCESS: "GET_PROFILE_PERSONALIZATION_SUCCESS",
  GET_PROFILE_PERSONALIZATION_FAILURE: "GET_PROFILE_PERSONALIZATION_FAILURE",

  UPDATE_PROFILE_PERSONALIZATION: "UPDATE_PROFILE_PERSONALIZATION",
  UPDATE_PROFILE_PERSONALIZATION_REQUEST:
    "UPDATE_PROFILE_PERSONALIZATION_REQUEST",
  UPDATE_PROFILE_PERSONALIZATION_SUCCESS:
    "UPDATE_PROFILE_PERSONALIZATION_SUCCESS",
  UPDATE_PROFILE_PERSONALIZATION_FAILURE:
    "UPDATE_PROFILE_PERSONALIZATION_FAILURE",

  GET_PROFILE_VISIBILITY: "GET_PROFILE_VISIBILITY",
  GET_PROFILE_VISIBILITY_REQUEST: "GET_PROFILE_VISIBILITY_REQUEST",
  GET_PROFILE_VISIBILITY_SUCCESS: "GET_PROFILE_VISIBILITY_SUCCESS",
  GET_PROFILE_VISIBILITY_FAILURE: "GET_PROFILE_VISIBILITY_FAILURE",

  UPDATE_PROFILE_VISIBILITY: "UPDATE_PROFILE_VISIBILITY",
  UPDATE_PROFILE_VISIBILITY_REQUEST: "UPDATE_PROFILE_VISIBILITY_REQUEST",
  UPDATE_PROFILE_VISIBILITY_SUCCESS: "UPDATE_PROFILE_VISIBILITY_SUCCESS",
  UPDATE_PROFILE_VISIBILITY_FAILURE: "UPDATE_PROFILE_VISIBILITY_FAILURE",
};

function reducerFunc(state = initialState, action) {
  switch (action.type) {
    case personalizationActionTypes.GET_PROFILE_PERSONALIZATION_REQUEST:
      return {
        ...state,
        error: null,
        status: personalizationActionTypes.GET_PROFILE_PERSONALIZATION_REQUEST,
      };
    case personalizationActionTypes.GET_PROFILE_PERSONALIZATION_FAILURE:
      return {
        ...state,
        error: action.payload,
        status: personalizationActionTypes.GET_PROFILE_PERSONALIZATION_FAILURE,
      };
    case personalizationActionTypes.GET_PROFILE_PERSONALIZATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        status: personalizationActionTypes.GET_PROFILE_PERSONALIZATION_SUCCESS,
      };

    case personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_REQUEST:
      return {
        ...state,
        error: null,
        status:
          personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_REQUEST,
      };
    case personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_FAILURE:
      return {
        ...state,
        error: action.payload,
        status:
          personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_FAILURE,
      };
    case personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_SUCCESS:
      return {
        ...state,
        status:
          personalizationActionTypes.UPDATE_PROFILE_PERSONALIZATION_SUCCESS,
      };

    case personalizationActionTypes.GET_PROFILE_VISIBILITY_REQUEST:
      return {
        ...state,
        profileVisibilityLoading: !action.payload.hideLoading,
        profileVisibilityError: null,
        profileVisibilityStatus:
          personalizationActionTypes.GET_PROFILE_VISIBILITY_REQUEST,
      };
    case personalizationActionTypes.GET_PROFILE_VISIBILITY_FAILURE:
      return {
        ...state,
        profileVisibilityLoading: false,
        profileVisibilityError: action.payload,
        profileVisibilityStatus:
          personalizationActionTypes.GET_PROFILE_VISIBILITY_FAILURE,
      };
    case personalizationActionTypes.GET_PROFILE_VISIBILITY_SUCCESS:
      return {
        ...state,
        profileVisibilityLoading: false,
        profileVisibilityData: action.payload,
        profileVisibilityStatus:
          personalizationActionTypes.GET_PROFILE_VISIBILITY_SUCCESS,
      };

    case personalizationActionTypes.UPDATE_PROFILE_VISIBILITY_REQUEST:
      return {
        ...state,
        profileVisibilityError: null,
        profileVisibilityStatus:
          personalizationActionTypes.UPDATE_PROFILE_VISIBILITY_REQUEST,
      };
    case personalizationActionTypes.UPDATE_PROFILE_VISIBILITY_FAILURE:
      return {
        ...state,
        profileVisibilityError: action.payload,
        profileVisibilityStatus:
          personalizationActionTypes.UPDATE_PROFILE_VISIBILITY_FAILURE,
      };
    case personalizationActionTypes.UPDATE_PROFILE_VISIBILITY_SUCCESS:
      return {
        ...state,
        profileVisibilityStatus:
          personalizationActionTypes.UPDATE_PROFILE_VISIBILITY_SUCCESS,
      };

    case userRegisterReducerTypes.LOGOUT_USER:
      return { ...initialState };

    default:
      return state;
  }
}

export default reducerFunc;
