import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import {
  FormHelperText,
  MenuItem,
  TextField,
  Typography,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { LightModeCross, NightModeCross } from "app/components/Logo/logos";
import {
  BlackBorderedButton,
  PurpleButton,
  PurpleLightButton,
} from "app/components/UIFormFields/Buttons";
import { companyIdSelector } from "app/redux/selectors/company-selectors";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router";
import { companyInviteEmployeesActionTypes } from "app/redux/reducers/company";
import { isValidEmail } from "app/utils/helper";
import { ExpandMoreRounded } from "@mui/icons-material";
import InviteMoreEmployeeAlert from "./InviteMoreEmployeeAlert";
import Profile_icon from "../../../../assets/icons/Profile_icon.png";
import { CheckboxField } from "../../../components/UIFormFields";

export const useUpload3dModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1086,
    maxHeight: "95vh",
    height: 772,
    maxWidth: "90%",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  snackbarcss: {
    // width: "10px !important",
    // height: "54px !important",
    // backgroundColor: "#64c279 !important",
    // color: "#fff",
    // borderRadius: "10px",
    // boxShadow: "0 10px 20px 0 rgba(100, 194, 121, 0.71)",
  },
  closeBox: {
    top: 28,
    right: 24,
    position: "absolute",
  },
  titleBox: {
    margin: "54px 0px 0px 0px",
  },
  emailBox: {
    width: "360px",
    height: "54px",
    border: "solid 1px #d7d0e8",
    padding: "16px 10px 15px 16px",
    objectFit: "contain",
    borderRadius: "6px",
  },
  emailBox2: {
    width: "420px",
    height: "54px",
    border: "solid 1px #d7d0e8",
    padding: "16px 10px 15px 16px",
    objectFit: "contain",
    borderRadius: "6px",
  },
  roleBox: {
    width: "260px  !important",
    height: "54px",
    flexGrow: 0,
    padding: "16px 16px 15px",
    objectFit: "contain",
    borderRadius: "6px",
    border: "solid 1px #d7d0e8",
  },
  roleBox2: {
    width: "305px  !important",
    height: "54px",
    flexGrow: 0,
    padding: "16px 16px 15px",
    objectFit: "contain",
    borderRadius: "6px",
    border: "solid 1px #d7d0e8",
  },
}));

export default function InviteEmployeeForm({
  open,
  handleClose,
  popUp,
  alreadySelected,
}) {
  const classes = useUpload3dModalStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const companyId = useSelector(companyIdSelector);
  const userData = useSelector((state) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;

  const userAdditionalInfo = userData?.userAdditionalInfo;
  const [inviteForm, setInviteForm] = useState(false);
  const [addMoreForm, setAddMoreForm] = useState(false);
  const {
    ReactComponent: AddIcon,
  } = require("../../../../assets/icons/Icons_Light_Add.svg");
  const priviledgedAccounts = [
    "b8694870-653b-47e6-b667-a6b60efa4380",
    "7eaa35f5-8fd1-450f-bf62-d1e8cef436f4",
  ];
  const isLoggedinUserPrivileged = priviledgedAccounts?.includes(accountGuid);
  const length = isLoggedinUserPrivileged ? 30 : 4;
  const [emailValue, setEmailValue] = useState(
    Array.from({ length }, () => "")
  );

  const [roleValue, setRoleValue] = useState(Array.from({ length }, () => ""));

  const licenceValue_New = Array(length)
    .fill(null)
    .map((u, i) => []);

  const [licenceValue, setLicenceValue] = useState(
    { ...licenceValue_New }
    // { userLicence0: [], userLicence1: [], userLicence2: [], userLicence3: [] }
  );

  const [hasError, setHasError] = useState();
  const [indexValue, setIndexValue] = useState();
  const [emailError, setEmailError] = useState(false);

  const navigate = useNavigate();
  const companyInviteEmployees = useSelector(
    (state) => state.companyInviteEmployees
  );
  const {
    ReactComponent: Tick,
  } = require("../../../../assets/icons/Icons _Light_Tick_mark_Icon.svg");
  const companyInfo = useSelector((state) => state.companyInfo);
  const [inviteStatus, setInviteStatus] = useState(false);
  const addedSeats = useSelector((state) => state?.manageSeats?.selectedSeats);

  // React.useEffect(() => {
  //   dispatch({
  //     type: companyActionTypes.GET_ALL_COMPANY_ROLES,
  //   });
  //   dispatch({
  //     type: companyActionTypes.GET_ALL_COMPANY_LICENCES,
  //   });
  // }, []);

  /// commenting as it was preventing Invitation Employee model from opening on second time.
  React.useEffect(() => {
    if (
      companyInviteEmployees?.data?.responseCode === 200 &&
      companyInviteEmployees?.data?.employeeEmailAlreadyExist?.length === 0
    ) {
      dispatch({
        type: companyInviteEmployeesActionTypes.CLEAR_INVITE_EMPLOYEES_FOR_REGISTRATION,
      });
      setInviteStatus(true);
      setTimeout(() => {
        handleClose(false);
        setInviteStatus(false);
      }, 3000);
    }
  }, [companyInviteEmployees]);

  const handleEmail = (event, index) => {
    const idValue = event.target.value;

    setIndexValue(event.target.name);
    setEmailValue((state) =>
      state.map((val, i) => (i !== index ? val : idValue))
    );
  };

  licenceValue;
  const handleRole = (event, index) => {
    event.target.value == 3 &&
      setLicenceValue({ ...licenceValue, [`userLicence${[index]}`]: [] });
    setRoleValue((state) =>
      state.map((val, i) => (i !== index ? val : event.target.value))
    );

    // console.log(event.target.value == 3, "tytty", licenceValue?.`userLicence${[index]}`);
    const temp = () => {
      return;
    };
  };

  const handleLicence = (event, index) => {
    setLicenceValue((licenceValue) => ({
      ...licenceValue,
      [index]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    }));
  };

  const handleReset = (index) => {
    setLicenceValue((licenceValue) => ({
      ...licenceValue,
      [index]: [],
    }));
    // index === 0 &&
    //   setLicenceValue({
    //     ...licenceValue,
    //     userLicence0: [],
    //   });
    // index === 1 &&
    //   setLicenceValue({
    //     ...licenceValue,
    //     userLicence1: [],
    //   });
    // index === 2 &&
    //   setLicenceValue({
    //     ...licenceValue,
    //     userLicence2: [],
    //   });
    // index === 3 &&
    //   setLicenceValue({
    //     ...licenceValue,
    //     userLicence3: [],
    //   });
  };
  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };

  // const openInviteForm = () => {  commentiing a these variables are unused
  //   setInviteForm(true);
  // };
  // const openInviteForm2 = () => {
  //   handleClose();
  //   navigate({ pathname: "/employees" }, { replace: false });
  //   handleClose(false);
  // };

  const handleInviteEmployees = (e) => {
    const formInfo = [];

    if (
      isValidEmail(emailValue[indexValue]) == false ||
      (isValidEmail(emailValue[indexValue]) &&
        !(
          emailValue[indexValue]?.split("@")?.pop().toLowerCase() ===
          userData?.userAdditionalInfo?.emailAddress
            ?.split("@")
            ?.pop()
            .toLowerCase()
        )) // to ensure only company domain is used for invitation
    ) {
      setEmailError(true);
      setHasError(false);
      return;
    }
    if (roleValue[indexValue] == "") {
      setHasError(true);
      setEmailError(false);
      return;
    } else if (
      roleValue[indexValue] != 3 &&
      // (indexValue == 0
      //   ? licenceValue[0].length == "0"
      //   : indexValue == 1
      //   ? licenceValue[1].length == "0"
      //   : indexValue == 2
      //   ? licenceValue[2].length == "0"
      //   : licenceValue[3].length == "0")
      licenceValue[indexValue].length == "0"
    ) {
      setHasError(true);
      setEmailError(false);
      return;
    } else if (
      roleValue[indexValue] == 3 ||
      // (indexValue == 0
      //   ? licenceValue[0].length != "0"
      //   : indexValue == 1
      //   ? licenceValue[1].length != "0"
      //   : indexValue == 2
      //   ? licenceValue[2].length != "0"
      //   : indexValue == 3
      //   ? licenceValue[3].length != "0" && roleValue[indexValue] != ""
      //   : null)
      (licenceValue[indexValue].length != "0" && roleValue[indexValue] != "") ||
      null
    ) {
      setEmailError(false);
      setHasError(false);
      emailValue.forEach((ele, ind) => {
        if (emailValue[ind]) {
          formInfo.push({
            employeeEmail: emailValue[ind],
            employeeRole: roleValue[ind] ? parseInt(roleValue[ind]) : 1,
            employeeLicense: licenceValue[ind],
            // ind == 0
            //   ? licenceValue[0]
            //   : ind == 1
            //   ? licenceValue[1]
            //   : ind == 2
            //   ? licenceValue[2]
            //   : licenceValue[3],
          });
        }
      });
      dispatch({
        type: companyInviteEmployeesActionTypes.INVITE_EMPLOYEES_FOR_REGISTRATION,
        payload: {
          accountGuid,
          employeeInvitationModel: formInfo,
          companyId,
        },
      });
      setTimeout(() => window.open(window.location.href, "_self"), 1000);

      return;
    }
  };
  const form = () => {
    return (
      <Box>
        {/* {companyInviteEmployees?.data?.responseCode === 200 && */}
        {inviteStatus && (
          <Box
            sx={{
              backgroundColor: "#64c279",
              color: "#ffffff",
              width: "250px",
              height: "54px",
              padding: "auto",
              fontSize: "15px",
              position: "absolute",
              borderRadius: "10px",
              boxShadow: "0 10px 20px 0 rgba(100, 194, 121, 0.71)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              left: "40%",
              zIndex: "5",
            }}
          >
            <Typography>
              Invite Sent Successfully
              <Tick />
            </Typography>
            {/* <Snackbar
              ContentProps={{ className: classes.snackbarcss }}
              anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
              open={true}
              message="Invite Sent Successfully"
              action={<Tick />}
            /> */}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          {!popUp === false && (
            <Box className={classes.closeBox}>
              {theme?.palette?.mode === "light" ? (
                <LightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <NightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>
          )}

          {/* <form action="#" method="get"> */}
          <Box>
            {popUp === false ? (
              ""
            ) : (
              <Box
                className={classes.titleBox}
                style={{
                  width: "372px",
                  fontSize: "27px",
                  position: "relative",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Typography
                  style={
                    theme?.palette?.mode === "light"
                      ? heading.lightMode
                      : heading.nightMode
                  }
                >
                  {"Invite Employees"}
                </Typography>
              </Box>
            )}
            <Box>
              <Table borderless>
                <thead>
                  {((popUp === false && length - alreadySelected !== 0) ||
                    popUp !== false) && (
                    <tr
                      style={{
                        fontSize: "13px",
                        textAlign: "left",
                        color: "#a49bbe",
                        fontFamily: " Poppins",
                      }}
                    >
                      <th></th>
                      <th>Email ID/ Username</th>
                      <th>Roles</th>
                      <th> Product Licence</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {popUp === false ? (
                    <></>
                  ) : (
                    <tr>
                      <td>
                        {userAdditionalInfo?.profilePictureUrl ? (
                          <img
                            src={userAdditionalInfo?.profilePictureUrl}
                            style={{
                              width: "34px",
                              height: "34px",
                              flexGrow: 0,
                              margin: "7px -12px 1px 2px",
                              padding: "1px",
                              borderRadius: 50,
                            }}
                            alt="user"
                          ></img>
                        ) : (
                          <img
                            src={Profile_icon}
                            alt="user"
                            style={{
                              background: "#d7d0e8",
                              /* width: 34px, */
                              height: "34px",
                              flexGrow: 0,
                              margin: "12px 1px 0px 13px",
                              padding: "5px",
                              borderRadius: "18px",
                            }}
                          ></img>
                        )}
                      </td>
                      <td>
                        <input
                          className={classes.emailBox}
                          value={userAdditionalInfo?.emailAddress}
                          disabled
                          style={{ color: "#d7d0e8", background: "#fff" }}
                        ></input>
                      </td>
                      <td>
                        <Box>
                          <TextField
                            className={classes.roleBox}
                            label=""
                            select
                            // fullWidth
                            disabled
                            value={
                              userAdditionalInfo?.companyEmployeeRoleId || 1
                            }
                            style={{ color: "#d7d0e8" }}
                          >
                            {companyInfo?.CompanyRolesData?.map((item) => {
                              return (
                                <MenuItem
                                  key={item.companyRolesId}
                                  value={item.companyRolesId}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginleft: "-10%",
                                  }}
                                >
                                  <p
                                    style={{
                                      flexGrow: 0,
                                      margin: 0,
                                      padding: 0,
                                      fontSize: "15px",
                                      textAlign: "left",
                                      color: "#2b2639",
                                    }}
                                  >
                                    {item.roleName}
                                  </p>
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Box>
                      </td>
                      <td>
                        <Box>
                          <TextField
                            className={classes.roleBox}
                            label=""
                            value={
                              userAdditionalInfo?.companyEmployeeLicenses?.map(
                                (item) => parseInt(item)
                              ) || [3]
                            }
                            select
                            // fullWidth
                            disabled
                            style={{ color: "#d7d0e8" }}
                          >
                            {companyInfo?.CompanyLicencesData?.map((item) => {
                              return (
                                <MenuItem
                                  value={item.companyLicenseId}
                                  key={item.companyLicenseId}
                                >
                                  <p
                                    style={{
                                      textAlign: "left",
                                      margin: 0,
                                      padding: 0,
                                    }}
                                  >
                                    {item.licenseName}
                                  </p>
                                </MenuItem>
                              );
                            })}
                          </TextField>
                        </Box>
                      </td>
                    </tr>
                  )}
                  {/* for adding new employees */}
                  {Array(popUp === false ? length - alreadySelected : length)
                    .fill()
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          {/* put icon here */}
                          <img
                            src={Profile_icon}
                            alt="user"
                            style={{
                              background: "#d7d0e8",
                              /* width: 34px, */
                              height: "34px",
                              flexGrow: 0,
                              margin: "12px 1px 0px 13px",
                              padding: "5px",
                              borderRadius: "18px",
                            }}
                          ></img>
                        </td>
                        <td>
                          <input
                            className={
                              popUp === false
                                ? classes.emailBox2
                                : classes.emailBox
                            }
                            placeholder="Username/Email Address"
                            onChange={(item) => handleEmail(item, index)}
                            name={index}
                          ></input>
                          {companyInviteEmployees?.data
                            ?.employeeEmailAlreadyExist?.length > 0 &&
                          companyInviteEmployees?.data?.employeeEmailAlreadyExist.find(
                            (email) => email === emailValue[index]
                          ) ? (
                            <FormHelperText style={{ color: "red" }}>
                              This email is already registered on our platform!!
                            </FormHelperText>
                          ) : index == indexValue && emailError ? (
                            <FormHelperText
                              style={{ color: "red", fontWeight: "bold" }}
                            >
                              Please enter a valid email address
                            </FormHelperText>
                          ) : null}
                        </td>
                        <td>
                          <Box>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Select Role
                              </InputLabel>
                              <Select
                                className={
                                  popUp === false
                                    ? classes.roleBox2
                                    : classes.roleBox
                                }
                                label="Select Role"
                                fullWidth
                                IconComponent={ExpandMoreRounded}
                                MenuProps={{
                                  PaperProps: {
                                    sx: {
                                      "& .MuiMenuItem-root:hover": {
                                        backgroundColor: "#ebe3ff",
                                        color: "#7a48ff",
                                        // fontWeight: "bold",
                                      },
                                    },
                                  },
                                }}
                                renderValue={(selected) => (
                                  <p
                                    style={{
                                      fontSize: "15px",
                                      textAlign: "left",
                                      color: "#2b2639",
                                      margin: 0,
                                    }}
                                  >
                                    {
                                      companyInfo?.CompanyRolesData.find(
                                        (item) =>
                                          item.companyRolesId === selected
                                      )?.roleName
                                    }
                                  </p>
                                )}
                                value={roleValue[index] || ""}
                                onChange={(item) => handleRole(item, index)}
                              >
                                {companyInfo?.CompanyRolesData?.map(
                                  (item, indx) => {
                                    return (
                                      <MenuItem
                                        key={item.companyRolesId}
                                        value={item.companyRolesId}
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          margin: "0 0 5px -10%",
                                          borderBottom:
                                            indx <
                                            companyInfo?.CompanyRolesData
                                              ?.length -
                                              1
                                              ? "1px solid #d7d0e8"
                                              : "",
                                        }}
                                      >
                                        <p
                                          style={{
                                            width: "49px",
                                            height: "23px",
                                            flexGrow: 0,
                                            margin: "0px 164px 4px 14px",
                                            fontFamily: "Poppins",
                                            fontSize: "15px",
                                            // fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            textAlign: "center",
                                            // color: "#2b2639",
                                          }}
                                        >
                                          {item.roleName}
                                        </p>
                                        {/* {roleValue[index] != "" ? null : ( */}
                                        <div
                                          style={{
                                            width: "205px",
                                            fontFamily: "Poppins",
                                            fontSize: "11px",
                                            textAlign: "left",
                                            color: "#a49bbe",
                                          }}
                                        >
                                          <p
                                            style={{
                                              wordBreak: "break-word",
                                              whiteSpace: "normal",
                                            }}
                                          >
                                            {item.roleDescription}
                                          </p>
                                        </div>
                                        {/* )} */}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </FormControl>
                            {index == indexValue && hasError ? (
                              <FormHelperText style={{ color: "red" }}>
                                This is required
                              </FormHelperText>
                            ) : null}
                          </Box>
                        </td>
                        <td>
                          <Box>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                Select Licence
                              </InputLabel>
                              <Select
                                className={
                                  popUp === false
                                    ? classes.roleBox2
                                    : classes.roleBox
                                }
                                name={`userLicence${[index]}`}
                                label="Select Licence"
                                // select
                                disabled={roleValue[index] == 3}
                                MenuProps={{
                                  sx: {
                                    "& .MuiMenuItem-root:hover": {
                                      backgroundColor: "#ebe3ff",
                                      color: "#7a48ff",
                                      // fontWeight: "bold",
                                    },
                                  },
                                }}
                                multiple
                                value={licenceValue[index] || ""}
                                // index == 0
                                //   ? licenceValue[0] || ""
                                //   : index == 1
                                //   ? licenceValue[1] || ""
                                //   : index == 2
                                //   ? licenceValue[2] || ""
                                //   : index == 3
                                //   ? licenceValue[3] || ""
                                //   : ""

                                renderValue={(selected) => {
                                  const data =
                                    companyInfo?.CompanyLicencesData?.find(
                                      (item) =>
                                        item.companyLicenseId === selected?.[0]
                                    )?.licenseName;

                                  return (
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        textAlign: "left",
                                        color: "#2b2639",
                                        margin: 0,
                                      }}
                                    >
                                      {data}
                                      {selected?.length > 1 ? (
                                        <span
                                          style={{
                                            fontSize: "15px",
                                            textAlign: "left",
                                            color: "#7a48ff",
                                            margin: 0,
                                            fontWeight: "bold",
                                            marginLeft: 5,
                                            marginTop: 2,
                                          }}
                                        >
                                          {`${`+`}`}
                                          {selected?.length - 1}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  );
                                }}
                                IconComponent={ExpandMoreRounded}
                                onChange={(item) => handleLicence(item, index)}
                                fullWidth
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    color: "#7a48ff",
                                    fontSize: "15px",
                                    textAlign: "end",
                                    margin: "5px 10px 10px 0",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleReset(index)}
                                >
                                  Reset
                                </Typography>
                                {companyInfo?.CompanyLicencesData?.map(
                                  (item, indx) => {
                                    return (
                                      <MenuItem
                                        value={item.companyLicenseId}
                                        key={item.companyLicenseId}
                                        sx={{
                                          borderBottom:
                                            indx <
                                            companyInfo?.CompanyLicencesData
                                              ?.length -
                                              1
                                              ? "1px solid #d7d0e8"
                                              : "",
                                        }}
                                      >
                                        <Box>
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "flex-end",
                                            }}
                                          >
                                            <CheckboxField
                                              checked={
                                                item.licenseName === "View"
                                                  ? licenceValue[
                                                      index
                                                    ]?.includes(1)
                                                  : item.licenseName ===
                                                    "Standard"
                                                  ? licenceValue[
                                                      index
                                                    ]?.includes(2)
                                                  : item.licenseName ===
                                                    "Exclusive"
                                                  ? licenceValue[
                                                      index
                                                    ]?.includes(3)
                                                  : item.licenseName ===
                                                    "Customised"
                                                  ? licenceValue[
                                                      index
                                                    ]?.includes(4)
                                                  : false
                                              }
                                              // checked={
                                              //   index === 0
                                              //     ? item.licenseName === "View"
                                              //       ? licenceValue[0]?.includes(
                                              //           1
                                              //         )
                                              //       : item.licenseName ===
                                              //         "Standard"
                                              //       ? licenceValue[0]?.includes(
                                              //           2
                                              //         )
                                              //       : item.licenseName ===
                                              //         "Exclusive"
                                              //       ? licenceValue[0]?.includes(
                                              //           3
                                              //         )
                                              //       : item.licenseName ===
                                              //         "Customised"
                                              //       ? licenceValue[0]?.includes(
                                              //           4
                                              //         )
                                              //       : false
                                              //     : index === 1
                                              //     ? item.licenseName === "View"
                                              //       ? licenceValue[1]?.includes(
                                              //           1
                                              //         )
                                              //       : item.licenseName ===
                                              //         "Standard"
                                              //       ? licenceValue[1]?.includes(
                                              //           2
                                              //         )
                                              //       : item.licenseName ===
                                              //         "Exclusive"
                                              //       ? licenceValue[1]?.includes(
                                              //           3
                                              //         )
                                              //       : item.licenseName ===
                                              //         "Customised"
                                              //       ? licenceValue[1]?.includes(
                                              //           4
                                              //         )
                                              //       : false
                                              //     : index === 2
                                              //     ? item.licenseName === "View"
                                              //       ? licenceValue[2]?.includes(
                                              //           1
                                              //         )
                                              //       : item.licenseName ===
                                              //         "Standard"
                                              //       ? licenceValue[2]?.includes(
                                              //           2
                                              //         )
                                              //       : item.licenseName ===
                                              //         "Exclusive"
                                              //       ? licenceValue[2]?.includes(
                                              //           3
                                              //         )
                                              //       : item.licenseName ===
                                              //         "Customised"
                                              //       ? licenceValue[2]?.includes(
                                              //           4
                                              //         )
                                              //       : false
                                              //     : item.licenseName === "View"
                                              //     ? licenceValue[3]?.includes(1)
                                              //     : item.licenseName ===
                                              //       "Standard"
                                              //     ? licenceValue[3]?.includes(2)
                                              //     : item.licenseName ===
                                              //       "Exclusive"
                                              //     ? licenceValue[3]?.includes(3)
                                              //     : item.licenseName ===
                                              //       "Customised"
                                              //     ? licenceValue[3]?.includes(4)
                                              //     : false
                                              // }
                                            />

                                            {item.licenseName}
                                          </Box>
                                          <Box
                                            style={{
                                              width: "250px",
                                              fontFamily: "Poppins",
                                              fontSize: "11px",
                                              textAlign: "left",
                                              color: "#a49bbe",
                                              paddingLeft: "40px",
                                            }}
                                          >
                                            <p
                                              style={{
                                                wordBreak: "break-word",
                                                whiteSpace: "normal",
                                              }}
                                            >
                                              {item?.companyLicenseId === 1
                                                ? "Simple and temporary  unlimited non-commercial right of use"
                                                : item?.companyLicenseId === 2
                                                ? "Simple and temporary  unlimited commercial right of use"
                                                : item?.companyLicenseId === 3
                                                ? "Temporary unlimited exclusive right of commercial use"
                                                : "Customized license aggreement"}
                                            </p>
                                          </Box>
                                        </Box>
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </FormControl>
                            {index == indexValue && hasError ? (
                              <FormHelperText style={{ color: "red" }}>
                                This is required
                              </FormHelperText>
                            ) : null}
                          </Box>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Box>{" "}
            {popUp === false ? (
              length - alreadySelected !== 0 && (
                <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                  {emailValue.filter((item) => item !== "").length === 0 ? (
                    <PurpleLightButton
                      style={{
                        width: "165px",
                        cursor: "default",
                      }}
                    >
                      Invite Employees
                    </PurpleLightButton>
                  ) : (
                    <PurpleButton
                      onClick={handleInviteEmployees}
                      style={{
                        width: "165px",
                      }}
                    >
                      Invite Employees
                    </PurpleButton>
                  )}
                </Box>
              )
            ) : (
              <>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    float: "right",
                  }}
                  onClick={() => setAddMoreForm(true)}
                >
                  <Box style={{ marginRight: 6 }}>
                    <AddIcon style={{ color: "#7A48FF" }} />
                  </Box>
                  <Typography
                    style={{
                      fontSize: 15,
                      color: "#7a48ff",
                      fontWeight: "bold",
                    }}
                  >
                    Add More Seats
                  </Typography>
                </Box>
                <Box style={{ marginTop: "50px" }}>
                  <BlackBorderedButton
                    sx={{
                      width: "86px",
                    }}
                    onClick={handleClose}
                  >
                    Cancel
                  </BlackBorderedButton>
                  {emailValue.filter((item) => item !== "").length === 0 ? (
                    <PurpleLightButton
                      style={{
                        width: "165px",
                        marginLeft: 16,
                        cursor: "default",
                      }}
                    >
                      Invite Employees
                    </PurpleLightButton>
                  ) : (
                    <PurpleButton
                      onClick={handleInviteEmployees}
                      style={{
                        width: "165px",
                        marginLeft: 16,
                      }}
                    >
                      {/* Done */}
                      Invite Employees
                    </PurpleButton>
                  )}
                </Box>
              </>
            )}
          </Box>
          {/* </form> */}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {addMoreForm && (
        <InviteMoreEmployeeAlert
          open={addMoreForm}
          handleClose={() => setAddMoreForm(false)}
        />
      )}

      {popUp === false ? (
        form()
      ) : (
        <Modal open={open}>
          <Box
            className={classes.modalStyle}
            style={{
              backgroundColor: theme?.palette?.mode === "light" && "#fff",
            }}
          >
            {form()}
          </Box>
        </Modal>
      )}
    </Box>
  );
}
