/*
 **	User name
 **	Created	7/7/2023
 **	index.tsx
 */

import React from "react";
import MainPage from "./mainPage";
import Footer from "../../../components/Footer";

export default function GuestHomepage() {
  return (
    <>
      <MainPage />
      <Footer />
    </>
  );
}
