/*
 **	User name
 **	Created	5/25/2023
 **	checkbox.tsx
 */

import React from "react";
import { Typography, Checkbox, useTheme, Box } from "@mui/material";

export function CheckboxField(props) {
  const {
    ReactComponent: Checked,
  } = require("../../../assets/icons/checkbox-checked.svg");
  const {
    ReactComponent: UnChecked,
  } = require("../../../assets/icons/checkbox-not-checked.svg");
  const {
    ReactComponent: DarkUnChecked,
  } = require("../../../assets/icons/Dark_Unselected.svg");

  const theme = useTheme();

  return (
    <Checkbox
      disableRipple
      disableTouchRipple
      icon={theme.palette.mode === "light" ? <UnChecked /> : <DarkUnChecked />}
      checkedIcon={<Checked />}
      style={{ paddingBottom: 0 }}
      {...props}
    />
  );
}

export function SwitchCheckBox(props) {
  const theme = useTheme();
  const {
    ReactComponent: CheckedSwitch,
  } = require("../../../assets/checkbox/Toggle_Light_Btn_USD.svg");

  const {
    ReactComponent: UnCheckedSwitch,
  } = require("../../../assets/checkbox/Toggle_Light_Btn_USD (1).svg");

  return (
    <Checkbox
      disableRipple
      disableTouchRipple
      icon={<UnCheckedSwitch />}
      checkedIcon={<CheckedSwitch />}
      style={{ padding: 0 }}
      {...props}
    />
  );
}

export function CustomCheckboxField(props) {
  const theme = useTheme();
  const {
    ReactComponent: Checked,
  } = require("../../../assets/icons/checkbox-checked.svg");
  const {
    ReactComponent: UnChecked,
  } = require("../../../assets/icons/checkbox-not-checked.svg");
  const {
    ReactComponent: DarkUnChecked,
  } = require("../../../assets/icons/Dark_Unselected.svg");

  const SelectionBox = () => {
    return (
      <Box
        style={{
          width: "24px",
          height: "24px",
          borderRadius: "6px",
          backgroundColor: "#64c279",
        }}
      >
        <Typography
          style={{
            color: "#fff",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          {props?.checkNumber}
        </Typography>
      </Box>
    );
  };

  return (
    <Checkbox
      disableRipple
      disableTouchRipple
      icon={theme.palette.mode === "light" ? <UnChecked /> : <DarkUnChecked />}
      checkedIcon={<SelectionBox />}
      style={{ paddingBottom: 0 }}
      {...props}
    />
  );
}
