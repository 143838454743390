import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { isImage } from "app/utils/helper";
import { useDispatch } from "react-redux";
import { productDetailaWithEachUploadTypes } from "app/redux/reducers/productReducer/product-main";
import { useAppSelector } from "app/utils/hooks";
import { LightModeCross, NightModeCross } from "../../Logo/logos";
import { BlackBorderedButton, PurpleButton } from "../../UIFormFields/Buttons";
import { AdditionalInfo } from "./additionalInfoModal";
import EditThumbnailModal from "./EditThumbnail";
import { CategoryDataContext } from "../../CategoryComponent/CategoryDataContext";

export const useUpload3dModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1220px",
    maxHeight: "95vh",
    height: 881,
    maxWidth: "80%",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 28,
    right: 24,
    position: "absolute",
  },
  titleBox: {
    margin: "54px 0px 0px 0px",
  },
}));

export default function NewUploadPreviewModal({
  open,
  handleClose,
  formData,
  setAdditionalInfo,
  additionalInfo,
  handleDraft,
}) {
  const dispatch = useDispatch();
  const classes = useUpload3dModalStyles();
  const theme = useTheme();
  const filterTypes = useAppSelector((state) => state.filterTypes);
  const toolTypes = filterTypes?.toolTypeList || [];
  const categoryTypes = useAppSelector((state) => state.category);
  const categoriesData = useAppSelector((state) => state.categories);
  const productWithUploadType = useAppSelector(
    (state) => state.productWithUploadType
  );
  // replacing productData with productDataSingle , for importing CategoryDataContext as productData
  const productDataSingle = productWithUploadType?.data || {};

  const uploadProductFile = useAppSelector((state) => state.uploadProductFile);

  const thumbnailUpload = uploadProductFile?.dataThumbnail;
  const userData = useAppSelector((state) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;
  const { productData } = React.useContext(CategoryDataContext);
  const attributeValuesMap = categoriesData?.attributesData?.attributeValuesMap;

  useEffect(() => {
    dispatch({
      type: productDetailaWithEachUploadTypes.GET_PRODUCT_BY_ID_WITH_EACH_UPLOAD_TYPE,
      payload: {
        ProductId: thumbnailUpload?.id || 7689,
        accountGuid,
      },
    });
  }, [accountGuid, thumbnailUpload?.id]);

  const [showEditThumbail, setShowEditThumbail] = useState(false);

  const handleSubmit = () => {
    setAdditionalInfo(true);
  };
  const handleBack = () => {
    handleClose(false);
  };

  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "24px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      backgroundImage:
        theme?.palette?.mode === "light" &&
        "linear-gradient(82deg, #f54d64 0%, #f58561 100%)",
      backgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      paddingBottom: "24px",
    },
    formControl: {
      padding: "0px 0px 24px 0px",
    },
    errorStyle: {
      textAlignLast: "right",
      marginRight: 0,
      fontFamily: "Poppins",
      fontSize: "11px",
      fontWeight: "bold",
      color: "#ff4f47",
    },
  };

  const toolsData = React.useMemo(() => {
    return formData?.tools?.map((tool) => {
      return toolTypes.find((item) => item.toolDetailId === tool) || {};
    });
  }, [formData, toolTypes]);

  const categoryData = React.useMemo(() => {
    return formData?.categories?.map((tool) => {
      return (
        categoryTypes?.data?.find((item) => item.categoryID === tool) || {}
      );
    });
  }, [formData, toolTypes]);

  const productTag2 = filterTypes?.productTagList;

  // const tagData = React.useMemo(() => {
  //   return formData?.productTag?.map((tool) => {
  //     return {productTag2?.find((item) => item.productTagID === tool)} || {};
  //   });
  // }, [formData, toolTypes]);

  const tagData = React.useMemo(() => {
    return formData?.productTag?.map((tool) => {
      return productTag2?.find((item) => item?.productTagID === tool) || {};
    });
  }, [formData, toolTypes]);

  const {
    ReactComponent: EditIcon,
  } = require("../../../../assets/icons/Icons_Light_Edit Icon.svg");

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {/* <Button
                style={{ margin: "10px 5px" }}
                onClick={() => setOpen(true)}>
                Updoad 3D
            </Button> */}
      <Modal open={open}>
        <Box
          className={classes.modalStyle}
          style={{
            backgroundColor: theme?.palette?.mode === "light" && "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box className={classes.closeBox}>
              {theme?.palette?.mode === "light" ? (
                <LightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <NightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>

            <Box className={classes.titleBox}>
              <Typography
                style={
                  theme?.palette?.mode === "light"
                    ? heading.lightMode
                    : heading.nightMode
                }
              >
                {"Product Information Preview"}
              </Typography>
            </Box>
            {/* <Box style={{ color: "black" }}>
              {JSON.stringify(formData, null, 2)}
            </Box> */}
            <Box
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                paddingLeft: 54,
                paddingRight: 54,
                width: "100%",
              }}
            >
              <Box
                style={{
                  // alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Box>
                  <Box
                    style={{
                      marginRight: 34,
                      border: "1px solid #ccc",
                      borderRadius: 6,
                      height: 308,
                      maxWidth: 400,
                      minWidth: 400,
                    }}
                  >
                    {productWithUploadType.loading ? (
                      <Box
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100%" }}
                      >
                        <i
                          className="fa-solid fa-spinner fa-spin fa-2xl"
                          style={{
                            color: "black",
                          }}
                        ></i>
                      </Box>
                    ) : (
                      (productDataSingle?.productDetailedEntity
                        ?.productThumbnailUploadedURL ||
                        uploadProductFile?.dataThumbnail?.urlDetails) &&
                      (isImage(
                        productDataSingle?.productDetailedEntity
                          ?.productThumbnailUploadedURL ||
                          uploadProductFile?.dataThumbnail?.urlDetails
                      ) ? (
                        <img
                          src={
                            productDataSingle?.productDetailedEntity
                              ?.productThumbnailUploadedURL ||
                            uploadProductFile?.dataThumbnail?.urlDetails
                          }
                          style={{
                            maxWidth: "100%",
                            height: "306px",
                            padding: "10px 69.7px 10.3px 87.6px",
                          }}
                        ></img>
                      ) : (
                        <model-viewer
                          style={{
                            maxWidth: "100%",
                            width: "100%",
                            height: 306,
                            borderRadius: 5,
                            border: "2px solid #ccc",
                          }}
                          src={
                            productDataSingle?.productDetailedEntity
                              ?.productThumbnailUploadedURL ||
                            uploadProductFile?.dataThumbnail?.urlDetails
                          }
                          ios-src=""
                          alt="model name"
                          camera-controls
                          ar
                          ar-modes="webxr scene-viewer quick-look"
                          environment-image="neutral"
                          oncontextmenu="return false;"
                          // poster="../assets/Chiemsee_Top.png" "/assets/Taschenmesser.glb"
                          autoplay
                        ></model-viewer>
                      ))
                    )}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      marginTop: 6,
                      cursor: "pointer",
                    }}
                    onClick={() => setShowEditThumbail(true)}
                  >
                    <EditIcon
                      style={{
                        color: "#2b2639",
                        width: 18,
                        height: 18,
                        marginRight: 10,
                      }}
                    />
                    <Typography
                      style={{
                        color: "#7a48ff",
                        fontSize: 13,
                        fontWeight: "600",
                      }}
                    >
                      Edit Thumbnail
                    </Typography>
                  </Box>
                </Box>

                <Box
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: 400,
                  }}
                >
                  <Box>
                    <Typography
                      style={{
                        color: "#a49bbe",
                        fontSize: 13,
                        fontWeight: "bold",
                        marginBottom: 6,
                        textAlign: "left",
                      }}
                    >
                      Product Title
                    </Typography>
                    <Typography
                      style={{
                        color: "#2b2639",
                        fontSize: 15,
                        marginBottom: 16,
                        textAlign: "left",
                      }}
                    >
                      {formData?.title}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      style={{
                        color: "#a49bbe",
                        fontSize: 13,
                        fontWeight: "bold",
                        marginBottom: 6,
                        textAlign: "left",
                      }}
                    >
                      Reference ID
                    </Typography>
                    <Typography
                      style={{
                        color: "#2b2639",
                        fontSize: 15,
                        marginBottom: 16,
                        textAlign: "left",
                      }}
                    >
                      {formData?.ProductReferenceId}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      style={{
                        color: "#a49bbe",
                        fontSize: 13,
                        fontWeight: "bold",
                        marginBottom: 6,
                        textAlign: "left",
                      }}
                    >
                      Tools
                    </Typography>
                    <Box
                      style={{
                        marginBottom: 8,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {toolsData?.map((tool) => {
                        return (
                          <Box
                            style={{
                              color: "#7a48ff",
                              fontSize: 15,
                              marginBottom: 8,
                              textAlign: "left",
                              background: "#d7d0e8",
                              padding: "5px 8px",
                              borderRadius: 6,
                              marginRight: 8,
                            }}
                          >
                            {tool?.toolName}
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>

                  <Box>
                    <Typography
                      style={{
                        color: "#a49bbe",
                        fontSize: 13,
                        fontWeight: "bold",
                        marginBottom: 6,
                        textAlign: "left",
                      }}
                    >
                      Tags
                    </Typography>
                    <Box
                      style={{
                        marginBottom: 8,
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                      }}
                    >
                      {tagData?.map((productTagI) => (
                        <Box
                          style={{
                            color: "#7a48ff",
                            fontSize: 15,
                            marginBottom: 8,
                            textAlign: "left",
                            background: "#d7d0e8",
                            padding: "5px 8px",
                            borderRadius: 6,
                            marginRight: 8,
                          }}
                        >
                          {productTagI?.productTagName}
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  {productData?.productCategoriesTreeData?.length && (
                    <Box>
                      <Typography
                        style={{
                          color: "#a49bbe",
                          fontSize: 13,
                          fontWeight: "bold",
                          marginBottom: 6,
                          textAlign: "left",
                        }}
                      >
                        Gender
                      </Typography>
                      <Box
                        style={{
                          marginBottom: 8,
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <Box
                            style={{
                              color: "#7a48ff",
                              fontSize: 15,
                              marginBottom: 8,
                              textAlign: "left",
                              background: "#d7d0e8",
                              padding: "5px 8px",
                              borderRadius: 6,
                              marginRight: 8,
                            }}
                          >
                            {productData?.productCategoriesTreeData?.[0]?.name}
                          </Box>
                        </Box>
                      </Box>

                      {/*  */}
                    </Box>
                  )}

                  {productData?.productCategoriesTreeData?.length && (
                    <Box>
                      <Typography
                        style={{
                          color: "#a49bbe",
                          fontSize: 13,
                          fontWeight: "bold",
                          marginBottom: 6,
                          textAlign: "left",
                        }}
                      >
                        Categories
                      </Typography>
                      <Box
                        style={{
                          marginBottom: 8,
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        <Box style={{ display: "flex", flexDirection: "row" }}>
                          <Box
                            style={{
                              color: "#7a48ff",
                              fontSize: 15,
                              marginBottom: 8,
                              textAlign: "left",
                              background: "#d7d0e8",
                              padding: "5px 8px",
                              borderRadius: 6,
                              marginRight: 8,
                            }}
                          >
                            {
                              productData?.productCategoriesTreeData?.[1]
                                ?.parentCategory?.name
                            }{" "}
                            ({productData?.productCategoriesTreeData?.[1]?.name}
                            )
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  )}

                  {productData?.attributesTreeData?.length && (
                    <Box>
                      <Typography
                        style={{
                          color: "#a49bbe",
                          fontSize: 13,
                          fontWeight: "bold",
                          marginBottom: 6,
                          textAlign: "left",
                        }}
                      >
                        Attributes
                      </Typography>
                      <Box
                        style={{
                          marginBottom: 8,
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            maxWidth: 250,
                            flexWrap: "wrap",
                          }}
                        >
                          {productData?.attributesTreeData?.map((category) => (
                            <Box
                              style={{
                                color: "#7a48ff",
                                fontSize: 15,
                                marginBottom: 8,
                                textAlign: "left",
                                background: "#d7d0e8",
                                padding: "5px 8px",
                                borderRadius: 6,
                                marginRight: 8,
                              }}
                            >
                              {category?.name}(
                              {category?.selectedAttribute?.map((item) => (
                                <>{attributeValuesMap?.[item]}</>
                              ))}
                              )
                            </Box>
                          ))}
                        </Box>
                      </Box>

                      {/*  */}
                    </Box>
                  )}

                  <Box>
                    <Typography
                      style={{
                        color: "#a49bbe",
                        fontSize: 13,
                        fontWeight: "bold",
                        marginBottom: 6,
                        textAlign: "left",
                      }}
                    >
                      Description
                    </Typography>
                    <Typography
                      style={{
                        color: "#2b2639",
                        fontSize: 15,
                        marginBottom: 16,
                        textAlign: "left",
                      }}
                    >
                      {formData?.description}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  style={{
                    paddingTop: "30px",
                    bottom: 0,
                    margin: "0 auto",
                  }}
                >
                  <BlackBorderedButton
                    style={{
                      width: "87px",
                    }}
                    onClick={handleBack}
                  >
                    Cancel
                  </BlackBorderedButton>
                  <PurpleButton
                    style={{
                      width: 101,
                      marginLeft: 16,
                    }}
                    onClick={handleSubmit}
                  >
                    Next
                  </PurpleButton>
                </Box>
                <h6
                  style={{
                    marginTop: "24px",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#a49bbe",
                    marginBottom: "24px",
                    cursor: "pointer",
                  }}
                  onClick={handleDraft}
                >
                  Save to draft
                </h6>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>

      {additionalInfo && (
        <AdditionalInfo
          open={additionalInfo}
          setAdditionalInfo={setAdditionalInfo}
          dataOfForm={formData}
          isEditProduct={undefined}
          additionalErrors={undefined}
          setAdditionalErrors={undefined}
          additionalFormData={undefined}
          setAdditionalFormData={undefined}
          additionalDownloadType={undefined}
          setAdditionalDownloadType={undefined}
          additonalVisibility={undefined}
          setAdditonalVisibility={undefined}
          setSuccess={undefined}
          individualProductData={undefined}
          productEditNavBar={undefined}
        />
      )}
      {showEditThumbail && (
        <EditThumbnailModal
          open={showEditThumbail}
          handleClose={() => setShowEditThumbail(false)}
          productDetails={""}
        />
      )}
    </Box>
  );
}
