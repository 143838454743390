/*
 **	User name
 **	Created	5/25/2023
 **	CategoryDropdown.tsx
 */

import React, { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  Button,
  useMediaQuery,
} from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { useNavigate } from "react-router";
import { navBarTypes, productsNavBarTypes } from "app/redux/reducers/navBar";
import { filterReducerTypes } from "app/redux/reducers/filterReducers/filter";
import { IRootState } from "app/redux/reducers";
import RouteLinks from "app/navigation/RouteLinks";
import { Link } from "react-router-dom";
import { categoriesActionTypes } from "app/redux/reducers/categories";
import {
  useNavBarStyles,
  useCategoryDropdownSyle,
} from "../../../styles/navbarStyles";
import { DropDown } from "../Logo/logos";

const ItemComp = ({
  item,
  handleClick,
}: {
  item: {
    id: string;
    name: string;
    url: string;
    hoverURL: string;
  };
  handleClick: () => void;
}) => {
  const categoryStyles = useCategoryDropdownSyle();

  return (
    <div
      key={item.name}
      className={categoryStyles.productBox}
      onClick={handleClick}
    >
      <Link to={`${RouteLinks.PRODUCTS}?categoryIds=${item.id}`}>
        <img className={categoryStyles.img} src={item.url} alt={item.name} />
        <img
          className={categoryStyles.hoverImg}
          src={item.hoverURL}
          alt={item.name}
        />
        <p className={categoryStyles.product} style={{ textAlign: "left" }}>
          {item.name.slice(0, 22)}
        </p>
      </Link>
    </div>
  );
};

export default function CategoryDropdown() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useNavBarStyles();
  const categoryStyles = useCategoryDropdownSyle();
  const theme = useTheme();
  const categoriesData = useSelector(
    (state: IRootState) => state.categories,
    shallowEqual
  );
  const navBarState = useSelector((state: IRootState) => state.navBarReducer);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [dropdownClassName, setDropdownClassName] =
    useState("dropdown-content");

  useEffect(() => {
    if (categoriesData?.data?.length) {
      setSelectedCategory(categoriesData.data[0]);
    }
  }, [categoriesData.data]);

  const handleCategory = (data) => {
    setSelectedCategory({
      ...data,
    });
  };

  const handleMenuClicks = () => {
    dispatch({
      type: productsNavBarTypes.NEWS_FEED,
    });

    dispatch({
      type: navBarTypes.NAV_BAR_PRODUCTS,
    });
    dispatch({
      type: filterReducerTypes.CLEAR_PRODUCT_FILTERED_LIST,
    });
    setDropdownClassName("dropdown-content-hidden");
    setTimeout(() => {
      setDropdownClassName("dropdown-content");
    }, 400);
  };

  const clothing = selectedCategory?.category?.find(
    (data) => data.type === "Clothing"
  );
  const shoes = selectedCategory?.category?.find(
    (data) => data.type === "Shoes"
  );
  const underwear = selectedCategory?.category?.find(
    (data) => data.type === "Underwears"
  );
  const sports = selectedCategory?.category?.find(
    (data) => data.type === "Sports"
  );
  const accessories = selectedCategory?.category?.find(
    (data) => data.type === "Accessories"
  );
  const babies = selectedCategory?.category?.find(
    (data) => data.type === "Babies"
  );

  const AIDrivenDesigns = selectedCategory?.category?.find(
    (data) => data.type === "AI-Driven Designs"
  );

  const handleProductsClick = () => {
    handleMenuClicks();
    dispatch({
      type: categoriesActionTypes.GET_ALL_CATEGORIES,
    });

    navigate("/products");
  };

  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dropdownListFunction = (temp) => {
    const a = temp?.type;
    const b = temp?.subCategory;

    const handleNaming = (name) => {
      return name?.replaceAll(" ", "")?.replaceAll("&", "");
    };

    return (
      <div className="accordion" id="accordionPanelsStayOpenExample">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              // data-bs-target="#panelsStayOpen-collapseOne"
              data-bs-target={`#${
                selectedCategory?.name
              }panelsStayOpen-${handleNaming(a)}`}
              aria-expanded="true"
              // aria-controls="panelsStayOpen-collapseOne"
              aria-controls={`${
                selectedCategory?.name
              }panelsStayOpen-${handleNaming(a)}`}
              style={{
                padding: "0px",
                backgroundColor: "#ffff",
                boxShadow: "none",
              }}
            >
              <Typography
                component={Link}
                className={categoryStyles.subHeading}
                to={`${RouteLinks.PRODUCTS}?categoryIds=${temp?.id}`}
                onClick={handleMenuClicks}
              >
                {a}
              </Typography>
            </button>
          </h2>
          {b?.map((item) => (
            <div
              // id="panelsStayOpen-collapseOne"
              id={`${selectedCategory?.name}panelsStayOpen-${handleNaming(a)}`}
              className="accordion-collapse collapse"
              style={{ padding: "0px" }}
              data-bs-dismiss="offcanvas"
            >
              <div className="accordion-body" style={{ padding: "0px" }}>
                {" "}
                <ItemComp
                  handleClick={handleMenuClicks}
                  key={item.name}
                  item={item}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const dropDownList = (listItem) => {
    return (
      <>
        <Typography
          component={Link}
          className={categoryStyles.subHeading}
          to={`${RouteLinks.PRODUCTS}?categoryIds=${listItem?.id}`}
          onClick={handleMenuClicks}
        >
          {listItem?.type}
        </Typography>
        {(listItem?.subCategory || [])?.map((item) => (
          <ItemComp
            handleClick={handleMenuClicks}
            key={item.name}
            item={item}
          />
        ))}{" "}
      </>
    );
  };

  return (
    <>
      <div
        className="dropdown  w-100 w-md-auto responsivedropdown_1 "
        style={{ alignItems: "center" }}
      >
        <span
          className={
            theme?.palette?.mode === "light"
              ? navBarState.products
                ? `${classes.navbar_li_a_light} newHeaderNavBar HeaderActiveNavBar`
                : `${classes.navbar_li_a_light} newHeaderNavBar`
              : navBarState.products
              ? `${classes.navbar_li_a_dark} newHeaderNavBar HeaderActiveNavBar`
              : `${classes.navbar_li_a_dark} newHeaderNavBar`
          }
          onClick={handleProductsClick}
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            // alignItems: "center",
            height: "100%",
          }}
        >
          Products &nbsp; <DropDown style={{ marginRight: "10px" }} />
        </span>
        {selectedCategory && (
          <div className="droppop">
            <div
              className={classNames(dropdownClassName, categoryStyles.content)}
              style={{
                width: "var(--container-width)",
                minHeight: "702px",
                position: "relative",
                top: "37px",
                right: "90px",
                boxShadow: "0 16px 34px 0 rgba(104, 62, 216, 0.1)",
                borderRadius: "0 0 20px 20px",
              }}
            >
              <Box
                style={{
                  boxShadow: "0px 10px 10px 0px rgba(104, 62, 216, 0.1) inset",
                  padding: "20px",
                }}
              >
                <Box>
                  {categoriesData?.data?.map((item) => {
                    return (
                      <Button
                        key={item.name}
                        className={
                          item.name === selectedCategory?.name
                            ? categoryStyles.activeStyleButton
                            : categoryStyles.inactiveStyleButton
                        }
                        onClick={() => handleCategory(item)}
                      >
                        {item.name}
                      </Button>
                    );
                  })}
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {dropDownList(clothing)}
                    {selectedCategory?.name === "Kids" && (
                      <>{dropDownList(shoes)}</>
                    )}
                  </div>

                  {!(selectedCategory?.name === "Kids") ? (
                    <div>
                      {dropDownList(underwear)}
                      {dropDownList(sports)}
                    </div>
                  ) : (
                    <div>
                      {dropDownList(sports)}
                      {dropDownList(accessories)}
                    </div>
                  )}

                  {!(selectedCategory?.name === "Kids") && (
                    <div>{dropDownList(shoes)}</div>
                  )}

                  {!(selectedCategory?.name === "Kids") ? (
                    <div>{dropDownList(accessories)}</div>
                  ) : (
                    <div>{dropDownList(babies)}</div>
                  )}
                  <div>{dropDownList(AIDrivenDesigns)}</div>
                  {selectedCategory?.name === "Kids" && (
                    <div className={categoryStyles.productBox}>&nbsp;</div>
                  )}
                </div>
              </Box>
            </div>
          </div>
        )}
      </div>
      <div
        className="accordion accordion-flush responsivedropdown_2"
        id="accordionFlushExample"
      >
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
              style={{
                height: "30px",
                backgroundColor: "#ffff",
                boxShadow: "none",
              }}
            >
              <span
                className={
                  theme?.palette?.mode === "light"
                    ? navBarState.products
                      ? `${classes.navbar_li_a_light} ${
                          isMdScreen ? "" : "newHeaderNavBar HeaderActiveNavBar"
                        }`
                      : `${classes.navbar_li_a_light} newHeaderNavBar`
                    : navBarState.products
                    ? `${classes.navbar_li_a_dark} ${
                        isMdScreen ? "" : "newHeaderNavBar HeaderActiveNavBar"
                      }`
                    : `${classes.navbar_li_a_dark} newHeaderNavBar`
                }
                onClick={handleProductsClick}
                style={{
                  position: "relative",
                  right: "15px",
                }}
                data-bs-dismiss="offcanvas"
              >
                Products
              </span>
            </button>
          </h2>
          <div
            id="flush-collapseOne"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {categoriesData?.data?.map((item) => {
                return (
                  <Button
                    key={item.name}
                    className={
                      item.name === selectedCategory?.name
                        ? categoryStyles.activeStyleButton
                        : categoryStyles.inactiveStyleButton
                    }
                    onClick={() => handleCategory(item)}
                  >
                    {item.name}
                  </Button>
                );
              })}
              {/*  */}
              {dropdownListFunction(clothing)}
              {selectedCategory?.name === "Kids" && dropdownListFunction(shoes)}

              {selectedCategory?.name !== "Kids" &&
                dropdownListFunction(underwear)}
              {selectedCategory?.name !== "Kids" &&
                dropdownListFunction(sports)}

              {selectedCategory?.name === "Kids" &&
                dropdownListFunction(sports)}

              {selectedCategory?.name === "Kids" &&
                dropdownListFunction(accessories)}

              {selectedCategory?.name !== "Kids" && dropdownListFunction(shoes)}

              {selectedCategory?.name !== "Kids" &&
                dropdownListFunction(accessories)}
              {selectedCategory?.name === "Kids" &&
                dropdownListFunction(babies)}

              {!!selectedCategory?.name &&
                dropdownListFunction(AIDrivenDesigns)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
