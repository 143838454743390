import React from "react";
import { Radio } from "@mui/material";

const CatgeoryRadio = ({ onClick, name, isSelected }) => {
  return (
    <label
      style={{
        color: "#a49bbe",
        cursor: "pointer",
        marginRight: 8,
        textTransform: "none",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      <Radio
        onClick={onClick}
        style={{
          color: "#7a48ff",
          padding: 0,
          marginRight: 6,
        }}
        value={name}
        checked={isSelected}
      ></Radio>
      {name}
    </label>
  );
};

export default CatgeoryRadio;
