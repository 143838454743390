import { Box, Typography } from "@mui/material";
import { BootstrapTooltipForFileType } from "app/components/Card&ListView/ToolTip";
import React, { useEffect, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import { uploadProductFileReducerTypes } from "app/redux/reducers/productReducer/uploadProductFile";
import { SingleItem } from "./singleItemView";
import { useUpload3dModalStyles } from "./modal";

export default function AttachmentViewer({
  selectedFileUplaoded,
  getCategoryID,
  selectedItem,
  getCategoryText,
  openFile,
  handleTooltipOpen,
  // uploadProductFile,
  // progress,
  setSelectedItem,
  fileUplaoded,
  setSelectedFileUplaoded,
  setFileUploaded,
  handleSelectAllItem,
  handleSelectionAttachment,
  handleEditItem,
  handlePopoverOpen,
  handlePopoverClose,
  uploadType,
  individualProductData,
  isEditProduct,
  setSelectdStack,

  handleUploadFileTypeClick,
}) {
  const classes = useUpload3dModalStyles();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;
  const attachFileRef = React.useRef();
  const detailsOfProduct = useSelector((state) => state.productWithUploadType);
  const productDetails = detailsOfProduct?.data;
  const productEditNavBar = useSelector((state) => state.productEditNavBar);
  const uploadProductFile = useSelector((state) => state.uploadProductFile);
  const [progress, setProgress] = useState(0);
  const [thumbnail, setThumbnail] = useState(0);

  function onUploadProgress(progressEvent) {
    const _progress = Math.round(
      (progressEvent.loaded / progressEvent.total) * 100
    );

    setProgress(_progress);
    dispatch({
      type: uploadProductFileReducerTypes.SET_UPLOAD_PRODUCT_FILE_PROGRESS_FOR_DIFF_CATEGORIES,
      payload: parseInt(progress),
    });
  }

  const returnFirstPogress = (firstProgress) => firstProgress;

  const {
    ReactComponent: Icon3dMode,
  } = require("../../../../assets/icons/Icons_Light_3D_square Icon large.svg");

  const returnUploadType = (type) => {
    return (
      (type._3D && setFileUploaded({ ...fileUplaoded, _3dFileUpload: [] })) ||
      (type.pattern &&
        setFileUploaded({ ...fileUplaoded, patternUpload: [] })) ||
      (type.artWork &&
        setFileUploaded({ ...fileUplaoded, artWorkUpolad: [] })) ||
      (type.sketch && setFileUploaded({ ...fileUplaoded, sketchUpload: [] })) ||
      (type.image && setFileUploaded({ ...fileUplaoded, imageUpload: [] })) ||
      (type.otherFiles &&
        setFileUploaded({ ...fileUplaoded, otherFileUpload: [] }))
    );
  };

  const returnSelectedUpload = (type) => {
    return (
      (type._3D &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selected3dFileUpload: [],
        })) ||
      (type.pattern &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedPatternUpload: [],
        })) ||
      (type.artWork &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedArtWorkUpolad: [],
        })) ||
      (type.sketch &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedSketchUpload: [],
        })) ||
      (type.image &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedImageUpload: [],
        })) ||
      (type.otherFiles &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedOtherFileUpload: [],
        }))
    );
  };

  const reutrnPreviewSelectedType = (type) => {
    const returnValue =
      (type._3D && selectedFileUplaoded.selected3dFileUpload) ||
      (type.pattern && selectedFileUplaoded.selectedPatternUpload) ||
      (type.artWork && selectedFileUplaoded.selectedArtWorkUpolad) ||
      (type.sketch && selectedFileUplaoded.selectedSketchUpload) ||
      (type.image && selectedFileUplaoded.selectedImageUpload) ||
      (type.otherFiles && selectedFileUplaoded.selectedOtherFileUpload) ||
      [];

    return returnValue;
  };

  const uploadedProductsForSelectedCategory =
    reutrnPreviewSelectedType(uploadType) || [];

  useEffect(() => {
    if (uploadedProductsForSelectedCategory.length < 1) {
      setThumbnail(0);
    }
  }, [uploadedProductsForSelectedCategory.length]);

  const returnGetUploadedType = (type) => {
    const returnValue =
      (productEditNavBar._3d && productDetails?.threeDFilesUploaded) ||
      (productEditNavBar.pattern && productDetails?.patternFiles) ||
      (productEditNavBar.art && productDetails?.artFiles) ||
      (productEditNavBar.sketch && productDetails?.sketchFiles) ||
      (productEditNavBar.image && productDetails?.imageFiles) ||
      (productEditNavBar.others && productDetails?.otherFiles);

    return returnValue;
  };

  useEffect(() => {
    if (uploadProductFile.dataDiffCategoriesDelete?.responseCode === 200) {
      // alert("yes i'm working ....")
      returnGetUploadedType();
    }
  }, [uploadProductFile.dataDiffCategoriesDelete]);

  const selectedItemDelete = (type) => {
    return (
      //Commenting to ensure fowrking of ClearAll
      // type._3D
      // && setSelectedItem({ ...selectedItem, selected3dFile: null }))
      // || (type.pattern
      //     && setSelectedItem({ ...selectedItem, selectedPattern: null }))
      // || (type.artWork
      //     && setSelectedItem({ ...selectedItem, selectedArtWork: null }))
      // || (type.sketch
      //     && setSelectedItem({ ...selectedItem, selectedSketch: null }))
      // || (type.image
      //     && setSelectedItem({ ...selectedItem, selectedImage: null }))
      (type._3D &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selected3dFileUpload: [],
        })) ||
      (type.pattern &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedPatternUpload: [],
        })) ||
      (type.artWork &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedArtWorkUpolad: [],
        })) ||
      (type.sketch &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedSketchUpload: [],
        })) ||
      (type.image &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedImageUpload: [],
        })) ||
      (type.otherFiles &&
        setSelectedFileUplaoded({
          ...selectedFileUplaoded,
          selectedOtherFileUpload: [],
        }))
    );
    // || (type.otherFiles
    //     && setSelectedItem({ ...selectedItem, selectedOtherFile: null }))
  };

  // const singelItemViewer = useMemo(() => {
  //     return reutrnPreviewSelectedType(uploadType)?.map((item, index) => {
  //         return (
  //             <SingleItem
  //                 item={item}
  //                 index={index}
  //                 accountGuid={accountGuid}
  //                 handleSelectAllItem={handleSelectAllItem}
  //                 handleSelectionAttachment={handleSelectionAttachment}
  //                 handleEditItem={handleEditItem}
  //                 handlePopoverOpen={handlePopoverOpen}
  //                 openHoverPopup={handlePopoverOpen}
  //                 handlePopoverClose={handlePopoverClose}
  //                 // handleReturnProgress={handleReturnProgress}
  //                 fileUplaoded={fileUplaoded}
  //                 getCategoryID={getCategoryID}
  //             />
  //         )
  //     })
  // }, [uploadType])

  const handleReturnLength = () => {
    const uploaded = parseInt(returnGetUploadedType(uploadType)?.length);
    const newUpload = parseInt(uploadedProductsForSelectedCategory.length);
    // return uploaded + newUpload  uploaded returned as true or false insrtead of Number
    let temp = 0;

    if (isNaN(uploaded)) {
      temp = newUpload;
    } else temp = newUpload + uploaded;
    return temp;
  };

  const dragOver = (ele) => {
    ele.preventDefault();
  };

  const dropFile = (file) => {
    alert("Check my file");
  };

  useEffect(() => {
    setSelectdStack([]);
  }, [uploadedProductsForSelectedCategory.length]);
  return (
    <Box className={classes.leftSection}>
      {uploadedProductsForSelectedCategory?.length !== 0 ||
      (isEditProduct && returnGetUploadedType(uploadType)?.length !== 0) ? (
        <>
          <Typography
            className={classes.title}
            style={{ marginBottom: "30px", textAlign: "center" }}
          >
            Upload {getCategoryText}
          </Typography>
          <Typography
            className={classes.subTitle}
            style={{ textAlign: "center" }}
          >
            Upload {getCategoryText} and other related image.
            <br />
            The first image in the grid act as a thumbnail
          </Typography>
          <Box className={classes.previewTop}>
            {/* <Typography className={classes.subHeaderTitle}>
                                    File types
                                </Typography> */}
            <Box sx={{ cursor: "pointer", display: "flex" }}>
              <Typography className={classes.subHeaderTitle}>
                File types
              </Typography>

              <BootstrapTooltipForFileType
                open={openFile}
                handleUploadFileTypeClick={handleUploadFileTypeClick}
              >
                <ExpandMore
                  onClick={handleTooltipOpen}
                  sx={{
                    color: "#a49bbe",
                    marginLeft: "8px",
                  }}
                />
              </BootstrapTooltipForFileType>
            </Box>
            <Typography className={classes.subHeaderTitle}>
              {/* {`${getCategoryText} (${handleReturnLength()})`} */}
              {`${getCategoryText} (${handleReturnLength()})`}
            </Typography>
          </Box>
        </>
      ) : (
        <div onDragOver={dragOver} onDrop={dropFile}>
          <Typography className={classes.boldTitle}>
            Upload {getCategoryText}
          </Typography>
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Icon3dMode
              style={{
                margin: "34px 0px 24px 0px",
              }}
            />
          </Box>{" "}
          <Typography className={classes.subHeaderTitle}>
            Upload {getCategoryText} and other related image.
            <br />
            The first image in the grid act as a thumbnail
          </Typography>
        </div>
      )}
      {uploadedProductsForSelectedCategory?.length !== 0 &&
      (uploadProductFile?.dataDiffCategories?.responseCode === 200 ||
        progress === 100) ? (
        ((uploadedProductsForSelectedCategory &&
          uploadedProductsForSelectedCategory[0] &&
          uploadedProductsForSelectedCategory[0].name &&
          uploadedProductsForSelectedCategory[0].name.split("."))[1] || "") !==
        "glb" ? (
          <img
            src={
              URL.createObjectURL(
                thumbnail
                  ? uploadedProductsForSelectedCategory[thumbnail]
                  : uploadedProductsForSelectedCategory[0]
              ) || "https://www.justbellydance.com/events/images/no-preview.png"
            }
            alt="product"
            style={{
              // maxWidth: "606px",
              width: "100%",
              maxHeight: "394px",
              height: "394px",
              border: "1px solid #ccc",
              borderRadius: "6px",
              margin: "16px 24px",
            }}
          />
        ) : (
          <model-viewer
            style={{
              border: "2px solid #ccc",
              borderRadius: "6px",
              margin: "16px 16px 0px 0px",
              width: "100%",
              maxHeight: "394px",
              height: "394px",
            }}
            src={
              URL.createObjectURL(uploadedProductsForSelectedCategory[0]) ||
              "https://www.justbellydance.com/events/images/no-preview.png"
            }
            ios-src=""
            alt="model name"
            camera-controls
            ar
            ar-modes="webxr scene-viewer quick-look"
            environment-image="neutral"
            oncontextmenu="return false;"
            autoplay
          ></model-viewer>
        )
      ) : (
        returnGetUploadedType(uploadType)?.length !== 0 &&
        (uploadedProductsForSelectedCategory?.length ?? 0) !== 0 && (
          <Box
            style={{
              // maxWidth: "606px",
              width: "100%",
              maxHeight: "394px",
              height: "394px",
              borderRadius: "6px",
              margin: "16px 24px",
              border: "2px solid #ccc",
              backgroundColor: "rgba(0,0,0,0.263)",
              display: "flex",
              // filter: "blur(1px)",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 149,
                height: 149,
                zIndex: 100,
              }}
            >
              <CircularProgressbar
                strokeWidth={5}
                value={progress}
                text={`${progress} %`}
                styles={buildStyles({
                  rotation: 0.25,
                  textSize: "16px",
                  pathTransitionDuration: 0.5,
                  textColor: "#fff",
                  pathColor: "#04AA6D",
                  trailColor: "#fff",
                })}
              />
            </div>
          </Box>
        )
      )}
      <Box className={classes.smallViewPortBox}>
        {uploadedProductsForSelectedCategory?.length !== 0 && (
          <Typography
            className={classes.clearText}
            onClick={() => {
              returnUploadType(uploadType);
              returnSelectedUpload(uploadType);
              // setFileUplaodedUpdated([])
              selectedItemDelete(uploadType);
              setSelectedItem(null);
              setSelectdStack([]);
              dispatch({
                type: uploadProductFileReducerTypes.CLEAR_UPLOAD_PRODUCT_FILE_FOR_DIFF_CATEGORIES,
                payload: {
                  accountGuid,
                  productId: uploadProductFile?.dataThumbnail?.id,
                  uploadedCategoryType: getCategoryID,
                },
              });
            }}
          >
            Clear All
          </Typography>
        )}
        <Box
          style={{
            overflowY: "scroll",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            position: "relative",
            maxHeight: "700px",
          }}
        >
          {isEditProduct &&
            returnGetUploadedType(uploadType)?.map((item, index) => {
              return (
                <SingleItem
                  item={item}
                  index={index}
                  accountGuid={accountGuid}
                  handleSelectAllItem={handleSelectAllItem}
                  handleSelectionAttachment={handleSelectionAttachment}
                  handleEditItem={handleEditItem}
                  handlePopoverOpen={handlePopoverOpen}
                  openHoverPopup={handlePopoverOpen}
                  handlePopoverClose={handlePopoverClose}
                  // handleReturnProgress={handleReturnProgress}
                  fileUplaoded={fileUplaoded}
                  getCategoryID={getCategoryID}
                  individualProductData={individualProductData}
                  returnFirstPogress={returnFirstPogress}
                  isEditProduct={isEditProduct}
                  forPreview={true}
                  // returnGetUploadedType={returnGetUploadedType}
                  uploadType={uploadType}
                />
              );
            })}
          {uploadedProductsForSelectedCategory
            ?.slice(0)
            .reverse()
            .map((item, index) => {
              return (
                <SingleItem
                  item={item}
                  index={index}
                  accountGuid={accountGuid}
                  handleSelectAllItem={handleSelectAllItem}
                  handleSelectionAttachment={handleSelectionAttachment}
                  handleEditItem={handleEditItem}
                  handlePopoverOpen={handlePopoverOpen}
                  openHoverPopup={handlePopoverOpen}
                  handlePopoverClose={handlePopoverClose}
                  // handleReturnProgress={handleReturnProgress}
                  fileUplaoded={fileUplaoded}
                  setFileUploaded={setFileUploaded}
                  getCategoryID={getCategoryID}
                  individualProductData={individualProductData}
                  returnFirstPogress={returnFirstPogress}
                  isEditProduct={isEditProduct}
                  // returnGetUploadedType={returnGetUploadedType}
                  // uploadType={uploadType}
                  // forPreview={false}
                  // setFileUploaded={setFileUploaded}
                  selectedFileUplaoded={selectedFileUplaoded}
                  uploadType={uploadType}
                  setSelectedFileUplaoded={setSelectedFileUplaoded}
                  setThumbnail={setThumbnail}
                  array={uploadedProductsForSelectedCategory}
                />
              );
            })}
        </Box>
      </Box>
    </Box>
  );
}
