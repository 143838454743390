/*
 **	User name
 **	Created	7/11/2023
 **	settings-saga.ts
 */

import { put, all, call, takeLatest } from "redux-saga/effects";

import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import {
  SavePopUpSettingsActions,
  SavePopUpSettingsActionsTypes,
} from "../reducers/settings";
import { userRegisterReducerTypes } from "../reducers/userReducer";

const version = process.env.REACT_APP_API_VERSION;

function* savePopUpSettingsFunRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SAVE_POP_UP_SETTINGS.replace("{version}", version),
    payload
  );

  return res;
}

function* savePopUpSettingsFun({ payload }: any) {
  try {
    yield put(SavePopUpSettingsActions.savePopUpSettingsRequest());
    const response = yield call(savePopUpSettingsFunRequest, payload);

    yield put(
      SavePopUpSettingsActions.savePopUpSettingsSuccess(response?.data)
    );
    payload?.callback?.();

    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO,
      accountGuid: payload.accountGuid,
    });
  } catch (e) {
    yield put(SavePopUpSettingsActions.savePopUpSettingsFailure(e));
  }
}

function* getAllLanguagesFunRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_LANGUAGES.replace("{version}", version),
    payload
  );

  return res;
}

function* getAllLanguagesFun({ payload }: any) {
  try {
    yield put(SavePopUpSettingsActions.getAllLanguagesRequest());
    const response = yield call(getAllLanguagesFunRequest, payload);

    yield put(SavePopUpSettingsActions.getAllLanguagesSuccess(response?.data));
  } catch (e) {
    yield put(SavePopUpSettingsActions.getAllLanguagesFailure(e));
  }
}

function* saveLanguagesFunRequest(payload) {
  const res = yield request(
    "put",
    EndPoints.SAVE_LANGUAGES.replace("{version}", version),
    payload
  );

  return res;
}

function* saveLanguagesFun({ payload }: any) {
  try {
    yield put(SavePopUpSettingsActions.saveLanguagesRequest());
    const response = yield call(saveLanguagesFunRequest, payload);

    yield put(SavePopUpSettingsActions.saveLanguagesSuccess(response?.data));
    payload?.callback?.();

    yield put({
      type: userRegisterReducerTypes.FETCH_USER_INFO,
      accountGuid: payload.accountGuid,
    });
  } catch (e) {
    yield put(SavePopUpSettingsActions.saveLanguagesFailure(e));
  }
}

function* combinedSaga() {
  yield all([
    takeLatest(
      SavePopUpSettingsActionsTypes.savePopUpSettings,
      savePopUpSettingsFun
    ),
    takeLatest(
      SavePopUpSettingsActionsTypes.getAllLanguages,
      getAllLanguagesFun
    ),
    takeLatest(SavePopUpSettingsActionsTypes.saveLanguages, saveLanguagesFun),
  ]);
}

export default combinedSaga;
