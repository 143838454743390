/*
 **	User name
 **	Created	5/25/2023
 **	uploadOptions.tsx
 */

import React, { useContext, useEffect } from "react";
import { Box, Modal, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { LibraryProductUploadContext } from "context/LibraryProductUploadContext";

import { GradientColoredButton } from "../UIFormFields";
import { BlackBorderedButton } from "../UIFormFields/Buttons";
import { LightModeCross, NightModeCross } from "../Logo/logos";

export const useUploadModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "505px",
    maxHeight: "256px",
    // height: 1200,
    // maxWidth: 1200,
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
  },
  header: {
    fontSize: "27px !important",
    fontWeight: "bold !important",
    backgroundImage:
      " linear-gradient(78deg, #f54d64 0%, #f58561 100%) !important",
    WebkitBackgroundClip: "text ",
    WebkitTextFillColor: "transparent ",
    textAlign: "center",
  },
  description: {
    color: "#2b2639",
    textAlign: "center",
    fontSize: "15px",
    margin: "6px 0 6px 0 !important",
  },
  button: {
    width: "150px !important",
    margin: "24px 12px 24px 12px !important",
  },
  textBox: {
    textAlign: "center",
    position: "relative",
    top: "50px",
  },
  buttonBox: { display: "flex", justifyContent: "center" },
}));

export default function uploadOptionModal({ handleClick, handleClose }) {
  const classes = useUploadModalStyles();
  const theme = useTheme();
  const { clearLibraryUpload } = useContext(LibraryProductUploadContext);

  useEffect(() => {
    clearLibraryUpload();
  }, []);

  return (
    <Modal open={true}>
      <Box
        className={classes.modalStyle}
        style={{
          backgroundColor: theme?.palette?.mode === "light" && "#fff",
          height: "95vh",
        }}
      >
        <Box>
          <Box className={classes.closeBox}>
            {theme?.palette?.mode === "light" ? (
              <LightModeCross
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <NightModeCross
                onClick={handleClose}
                style={{ cursor: "pointer" }}
              />
            )}
          </Box>
        </Box>
        <Box className={classes.textBox}>
          <Typography className={classes.header}> Upload Work</Typography>
          <Typography className={classes.description}>
            Do you want to upload a Product or a Library?
          </Typography>
          <Box className={classes.buttonBox}>
            <GradientColoredButton
              onClick={() => {
                handleClick("productUpload");
              }}
              className={classes.button}
            >
              Product Upload
            </GradientColoredButton>
            <BlackBorderedButton
              onClick={() => {
                handleClick("libraryUpload");
              }}
              className={classes.button}
            >
              Library Upload
            </BlackBorderedButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
