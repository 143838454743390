/*
 **	User name
 **	Created	5/24/2023
 **	index.tsx
 */
import { productListReducerTypes } from "app/redux/reducers/productReducer/product-main";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/styles";
import { Avatar } from "@mui/material";
import { saveFollowerCountTypes } from "app/redux/reducers/userReducer";
import { makeNumUserFriendly } from "app/utils/helper";
import {
  BlackButton,
  PurpleLightButton,
  BlackBorderedButton,
} from "../UIFormFields/Buttons";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "24px",
    border: "1px solid #dadde9",

    width: 510,
    // height: 410,
    padding: "0",
    objectFit: "contain",
    boxShadow: "0 24px 34px 0 rgba(104, 62, 216, 0.16)",
    borderRadius: 20,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
}));

const HoverProfile = ({
  createdBy,
  profession,
  image,
  isFollowedByMe,
  accountGuid,
  accountIdToFollow,
  productUploadedByCoverPic,
  productUploadedByAppreciationCount,
  productUploadedByProductViewCount,
  productUploadedByFollowerCount,
  productUploadedByHeadlineText,
}) => {
  const dispatch = useDispatch();
  const {
    ReactComponent: FollowersIcon,
  } = require("../../../assets/icons/Followers.svg");
  const {
    ReactComponent: ViewIcon,
  } = require("../../../assets/icons/Views.svg");
  const {
    ReactComponent: LikesIcon,
  } = require("../../../assets/icons/Likes.svg");

  const [following, setFollowing] = useState(isFollowedByMe);

  const handleFollow = () => {
    dispatch({
      type: saveFollowerCountTypes.SAVE_FOLLOW_COUNTER,
      payload: {
        accountGuid,
        accountIdToFollow,
        isFollow: !isFollowedByMe,
      },
    });
    dispatch({
      type: productListReducerTypes.PRODUCT_LIST,
      payload: {
        guid: accountGuid,
      },
    });

    following
      ? setFollowerCountTemp(FollowerCountTemp - 1)
      : setFollowerCountTemp(FollowerCountTemp + 1);

    setFollowing(!following);
  };

  const [FollowerCountTemp, setFollowerCountTemp] = useState(0);

  React.useEffect(() => {
    setFollowerCountTemp(productUploadedByFollowerCount || 0);
  }, []);

  return (
    <Box>
      <Box style={{ position: "relative" }}>
        <Box
          style={{
            width: "100%",
            height: 170,
            background: `url(${productUploadedByCoverPic})`,
            backgroundPosition: "center",
            borderRadius: "20px 20px 0 0",
            backgroundSize: "cover",
          }}
        />

        <img
          src={image}
          alt="user"
          style={{
            position: "absolute",
            left: "50%",
            bottom: -50,
            border: "6px solid #fff",
            borderRadius: "50%",
            transform: "translate(-50%, 0px)",
            maxWidth: 102,
            maxHeight: 102,
          }}
        />
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          paddingTop: 58,
        }}
      >
        <Box>
          <Typography
            style={{
              fontSize: 21,
              display: "flex",
              fontWeight: "bold",
              color: "#2b2639",
            }}
          >
            {createdBy}
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontSize: 13, color: "#a49bbe" }}>
            {profession}
          </Typography>
        </Box>
        <Box width={315} height={15}>
          <Typography
            fontSize={13}
            color={"#7a48ff"}
            style={{ cursor: "pointer", textAlign: "center" }}
          >
            {productUploadedByHeadlineText || "-"}
          </Typography>
        </Box>
      </Box>

      <Box style={{ paddingBottom: 3 }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            alignItems: "center",
            marginTop: 22,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              borderRight: "1px solid #d7d0e8",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <Box style={{ marginRight: 6 }}>
              <FollowersIcon />
            </Box>
            <Box>
              <Typography
                style={{
                  color: "#a49bbe",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                Followers
              </Typography>
              <Typography style={{ color: "#2b2639", fontSize: 13 }}>
                {makeNumUserFriendly(FollowerCountTemp)}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              borderRight: "1px solid #d7d0e8",
              flex: 1,
              justifyContent: "center",
            }}
          >
            <Box style={{ marginRight: 6 }}>
              <ViewIcon />
            </Box>
            <Box>
              <Typography
                style={{
                  color: "#a49bbe",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                Product Views
              </Typography>
              <Typography style={{ color: "#2b2639", fontSize: 13 }}>
                {makeNumUserFriendly(productUploadedByProductViewCount)}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              alignItems: "flex-start",
              justifyContent: "center",
            }}
          >
            <Box style={{ marginRight: 6 }}>
              <LikesIcon />
            </Box>
            <Box>
              <Typography
                style={{
                  color: "#a49bbe",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                Appreciations
              </Typography>
              <Typography style={{ color: "#2b2639", fontSize: 13 }}>
                {makeNumUserFriendly(productUploadedByAppreciationCount)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box style={{ padding: 21, display: "flex", justifyContent: "center" }}>
        <Box style={{ flex: 1, marginRight: 20 }}>
          <PurpleLightButton>Message</PurpleLightButton>
        </Box>
        <Box style={{ flex: 1 }} onClick={handleFollow}>
          {following ? (
            <BlackBorderedButton style={{ height: 64 }}>
              Unfollow
            </BlackBorderedButton>
          ) : (
            <BlackButton style={{ height: 64 }}>Follow</BlackButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const UserInfo = (props) => {
  const { createdBy, profession, image, data } = props;

  const isCompany =
    ((data?.uploadedByDetails?.profileType === 2 || data?.profileType === 2) &&
      true) ||
    false;

  return (
    <>
      <HtmlTooltip
        classes={
          {
            // arrow: {
            //   "&::before": {
            //     backgroundColor: "blue",
            //     border: "2px solid red",
            //   },
            // },
            // tooltip: {
            //   backgroundColor: "#f00",
            // },
          }
        }
        title={<HoverProfile {...props} />}
        placement="top"
        arrow
        // open
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
          }}
        >
          <Box
            style={{
              marginRight: 16,
              borderRadius: "50%",
              overflow: "hidden",
              width: 34,
              height: 34,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar style={{ width: 34, height: 34 }} src={image}></Avatar>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box>
              <Typography
                style={{
                  color: "#2b2639",
                  fontSize: 15,
                  fontWeight: "bold",
                  width: "120px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {createdBy}
              </Typography>
            </Box>
            {window.location.href?.includes("/designer-profile") ||
            window.location.href?.includes("/company-profile") ? (
              // !isCompany && (
              <Box>
                <Typography
                  style={{
                    color: "#a49bbe",
                    fontSize: 13,
                    textAlign: "left",
                  }}
                >
                  {profession?.slice(0, 20)}
                  {profession?.length > 20 && "..."}
                </Typography>
              </Box>
            ) : (
              // )
              <Box>
                <Typography
                  style={{ color: "#a49bbe", fontSize: 13, textAlign: "left" }}
                >
                  {profession?.slice(0, 20)}
                  {profession?.length > 20 && "..."}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </HtmlTooltip>
    </>
  );
};

export default UserInfo;
