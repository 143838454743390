/*
 **	User name
 **	Created	6/13/2023
 **	AppInitProvider.tsx
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import {
  accessTokenSelector,
  accountGuidSelector,
} from "app/redux/selectors/user-selector";
import { categoriesActionTypes } from "app/redux/reducers/categories";
import { filterReducerTypes } from "app/redux/reducers/filterReducers/filter";
// import { categoryReducerTypes } from "app/redux/reducers/productReducer/product-category";
import {
  currenciesReducerTypes,
  materialListReducerTypes,
  ProductSizeReducerTypes,
} from "app/redux/reducers/productReducer/product-main";
import { tagsReducerTypes } from "app/redux/reducers/productReducer/productTags";
import { CartActions } from "app/redux/reducers/cart";
import { companyActionTypes } from "app/redux/reducers/company";
import {
  saveStripeCustomerIdTypes,
  userRegisterReducerTypes,
} from "app/redux/reducers/userReducer";
import { useAppSelector } from "app/utils/hooks";
import GuestUserPopUp from "app/screens/NewHomePage/guestHomepage/guestUserPopUp";
import { SavePopUpSettingsActions } from "app/redux/reducers/settings";
import RouteLinks from "app/navigation/RouteLinks";

import { JobsReducerTypes } from "app/redux/reducers/jobReducer";
import { skillsActionTypes } from "app/redux/reducers/skills";

import { AppInitContext } from "./AppInitContext";

const reloadOnLoginChange = (data) => {
  try {
    if (data?.key === "persist:root" && data.newValue && data.oldValue) {
      const newValue = JSON.parse(
        JSON.parse(data.newValue)?.register
      )?.userInfo;
      const oldValue = JSON.parse(
        JSON.parse(data.oldValue)?.register
      )?.userInfo;

      if (
        newValue?.token !== oldValue?.token ||
        newValue?.accessToken !== oldValue?.accessToken
      ) {
        window.location.reload();
      }
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const AppInitProvider = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const accessToken = useSelector(accessTokenSelector);
  const accountGuid = useSelector(accountGuidSelector);
  const cartData = useAppSelector((state) => state.cart);
  const [showGuestPopup, setShowGuestPopup] = useState(false);
  const isUserLoggedIn = accessToken;

  useEffect(() => {
    window.addEventListener("storage", reloadOnLoginChange);

    return () => {
      window.removeEventListener("storage", reloadOnLoginChange);
    };
  }, []);

  // call open apis here
  useEffect(() => {
    dispatch({
      type: categoriesActionTypes.GET_ALL_CATEGORIES,
    });
    // dispatch({
    //   type: categoryReducerTypes.CATEGORY_LIST,
    // });
    dispatch({
      type: filterReducerTypes.TOOL_TYPES,
    });
    dispatch({
      type: filterReducerTypes.PRODUCT_TAG,
    });
    dispatch({
      type: materialListReducerTypes.MATERIAL_LIST,
    });
    dispatch({
      type: tagsReducerTypes.TAGS_LIST,
    });
    dispatch({
      type: ProductSizeReducerTypes.PRODUCT_SIZE,
    });
    dispatch({
      type: currenciesReducerTypes.CURRENCIES,
    });

    dispatch({
      type: JobsReducerTypes.JOB_EXPERIENCE_LIST,
    });
    dispatch({
      type: JobsReducerTypes.JOB_WORK_PLACE_LIST,
    });
    dispatch({
      type: skillsActionTypes.FETCH_ALL_SKILLS,
    });
    dispatch({
      type: filterReducerTypes.COMPANY_TYPE,
    });
    dispatch({
      type: JobsReducerTypes.JOB_SALARY_RANGE_LIST,
    });
    dispatch({
      type: JobsReducerTypes.JOB_TYPE_LIST,
    });
    dispatch({
      type: filterReducerTypes.TIME_SORT_TYPES,
    });

    dispatch(SavePopUpSettingsActions.getAllLanguages());
  }, []);

  useEffect(() => {
    if (accessToken) {
      dispatch(CartActions.fetchCart({ guid: accountGuid }));

      dispatch({
        type: companyActionTypes.GET_ALL_COMPANY_LICENCES,
      });

      dispatch({
        type: userRegisterReducerTypes.FETCH_USER_INFO,
        accountGuid,
      });

      dispatch({
        type: categoriesActionTypes.GET_ALL_CATEGORIES,
        payload: {
          accountGuid,
          accessToken,
        },
      });
    }
  }, [accountGuid, accessToken]);

  useEffect(() => {
    if (cartData?.cart?.sellerStripeId) {
      dispatch({
        type: saveStripeCustomerIdTypes.GET_STRIPE_CUSTOMER_ID_BY_SELLER,
        payload: { stripeId: cartData?.cart?.sellerStripeId, accountGuid },
      });
    }
  }, [cartData?.cart?.sellerStripeId]);

  const onCloseGuestPopup = () => {
    setShowGuestPopup(false);
  };

  const showGuestLogin = () => {
    setShowGuestPopup(true);
  };

  const isEligibleForGuestPopup = (() => {
    const pathName =
      location.pathname !== RouteLinks.HOME && location.pathname.endsWith("/")
        ? location.pathname.slice(0, -1)
        : location.pathname;

    if (
      [
        RouteLinks.LOGIN,
        RouteLinks.REGISTRATION,
        RouteLinks.EMPLOYEE_SIGNUP,
        RouteLinks.FORGOT_PASSWORD,
        RouteLinks.RESET_PASSWORD,
        RouteLinks.BILLING_PAGE,
      ].includes(pathName as RouteLinks)
    ) {
      return false;
    }

    return !isUserLoggedIn;
  })();

  return (
    <AppInitContext.Provider value={{ isUserLoggedIn, showGuestLogin }}>
      {showGuestPopup && isEligibleForGuestPopup && (
        <GuestUserPopUp onClose={onCloseGuestPopup} />
      )}
      {children}
    </AppInitContext.Provider>
  );
};
