/*
 **	User name
 **	Created	6/25/2023
 **	WelcomeModal.tsx
 */

import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Modal, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/system";
import { BlackButton } from "app/components/UIFormFields";
import { useNavigate } from "react-router";
import { SavePopUpSettingsActions } from "app/redux/reducers/settings";
import { accountGuidSelector } from "app/redux/selectors/user-selector";

import { LightModeCross, NightModeCross } from "../Logo/logos";

const useHomeAreaStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "540px !important",
    minHeight: "500px !important",
    padding: "20px",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 20,
    right: 20,
    position: "absolute",
    zIndex: 100,
  },
}));

export default function WelcomeModal() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const classes = useHomeAreaStyles();
  const accountGuid = useSelector(accountGuidSelector);
  const theme = useTheme();

  const {
    ReactComponent: SubscribeGraphic,
  } = require("../../../assets/logo/Subscribe Graphic.svg");
  const navigate = useNavigate();

  const handleClose = () => {
    dispatch(
      SavePopUpSettingsActions.savePopUpSettings({
        isWelcomePopup: true,
        accountGuid,
      })
    );
    setOpen(false);
  };

  return (
    <Modal open={open}>
      <Box
        className={classes?.modalStyle}
        style={{
          backgroundColor: theme?.palette?.mode === "light" && "#fff",
          padding: 0,
          border: 0,
        }}
      >
        {" "}
        <Box className={classes.closeBox}>
          {theme?.palette?.mode !== "light" ? (
            <LightModeCross
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <NightModeCross
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          )}
        </Box>
        <Box
          style={{
            backgroundImage: "linear-gradient(68deg, #f54d64 0%, #f58561 99%)",
            width: "100%",
            height: "250px",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SubscribeGraphic />
          </Box>
        </Box>
        <Typography
          style={{
            fontSize: "27px",
            fontWeight: "bold",
            color: "#2b2639",
            textAlign: "center",
          }}
        >
          Thank you for connecting with us
        </Typography>
        <Typography
          style={{
            fontSize: "15px",
            color: "#2b2639",
            textAlign: "center",
            marginBottom: 16,
          }}
        >
          Your 90 days free trial starts now!
        </Typography>
        <Typography
          style={{
            fontSize: "13px",
            color: "#a49bbe",
            textAlign: "center",
          }}
        >
          Note: An active plan is essential to access your account. After the
          free trial period, your profile will become inactive unless an
          appropriate plan is selected.
        </Typography>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "16px",
          }}
        >
          <BlackButton
            style={{ width: "104px" }}
            onClick={() => {
              navigate("/license");
              handleClose();
            }}
          >
            See Plans
          </BlackButton>
        </Box>
      </Box>
    </Modal>
  );
}
