import React, { useEffect, useState } from "react";
import { Box, Grid, MenuItem, Snackbar, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/styles";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  productFavouriteReducerTypes,
  saveProductTypes,
} from "app/redux/reducers/productReducer/product-main";
import { accountGuidSelector } from "app/redux/selectors/user-selector";
import { saveLibraryProductTypes } from "app/redux/reducers/library";
import NotFound from "app/screens/work_inprogress/notFound";
import { IRootState } from "app/redux/reducers";
import GuestUserPopUp from "app/screens/NewHomePage/guestHomepage/guestUserPopUp";
import { libraryFavouriteReducerTypes } from "app/redux/reducers/libraryReducers/libraryList";
import { CartActions } from "app/redux/reducers/cart";
import { useAppSelector } from "app/utils/hooks";

import {
  useProductCardViewStyles,
  useJobCardStyles,
} from "../../../styles/cardAndListStyle";
import { cryptr, makeNumUserFriendly } from "../../utils/helper";
import UserInfo from "../UserInfo";

import { designerProfileCardOptionStateType } from "../../redux/reducers/navBar";
import ProductImage from "../ProductImage/image";
import ProductVisibilityModal from "../ProductVisibilityModal";
import ShareProductModal from "../NewProductDetailsUpdated/ShareProductModalV2";

const TooltipForDropdown = ({
  setOpen,
  previewData,
  setProductData,
  isDraft,
  setSnackBarPopUp,
  setShareProductDetails,
}) => {
  const userData = useSelector((state: IRootState) => state.register);
  const classes = useJobCardStyles();
  const dispatch = useDispatch();

  const handleEditProduct = () => {
    if (
      previewData?.data?.productDetailedEntity?.productUploadedByAccountId &&
      userData?.userAdditionalInfo?.accountID !==
        previewData?.data?.productDetailedEntity?.productUploadedByAccountId
    ) {
      setSnackBarPopUp(true);
    } else {
      setSnackBarPopUp(false);
      dispatch({
        type: designerProfileCardOptionStateType.OPEN_EDIT,
      });
    }
  };

  if (isDraft) {
    return (
      <Box>
        <MenuItem
          className={classes.dropDownMenu}
          value="Edit"
          onClick={(event) => {
            event.stopPropagation();
            handleEditProduct();
            setProductData(previewData);
          }}
        >
          Edit
        </MenuItem>
        <Box
          style={{
            width: "100%",
            height: "1px",
            backgroundColor: "#d7d0e8",
          }}
        />
        <MenuItem
          className={classes.dropDownMenu}
          value="Delete"
          onClick={(event) => {
            event.stopPropagation();
            setOpen({ deleteModal: true });
            setProductData(previewData);
          }}
        >
          Delete
        </MenuItem>
      </Box>
    );
  }

  const isInternalProfile =
    location.href?.includes("/designer-profile") ||
    location.href?.includes("/company-profile") ||
    location.href?.includes("/employee-profile");

  const isMyProduct =
    [
      previewData?.data?.productDetailedEntity?.productUploadedByAccountId,
      previewData?.data?.accountId,
    ].indexOf(userData?.userAdditionalInfo?.accountID) > -1;

  return (
    <Box>
      {isInternalProfile && isMyProduct && (
        <>
          {" "}
          <MenuItem
            className={classes.dropDownMenu}
            value="Edit"
            onClick={(event) => {
              event.stopPropagation();
              // setOpen({ productEditModal: true });
              handleEditProduct();
              setProductData(previewData);
            }}
          >
            Edit
          </MenuItem>
          <Box
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#d7d0e8",
            }}
          />{" "}
        </>
      )}
      <MenuItem
        className={classes.dropDownMenu}
        value="Share"
        onClick={(event) => {
          isInternalProfile
            ? (event.stopPropagation(),
              setOpen({ shareModal: true }),
              setProductData(previewData))
            : setShareProductDetails(previewData);
        }}
      >
        Share
      </MenuItem>
      {/* {
                !jobsNavBarState.JobsPosted &&
                <>
                    <Box
                        style={{
                            width: "100%",
                            height: "1px",
                            backgroundColor: "#d7d0e8"
                        }} />
                    <MenuItem
                        className={classes.dropDownMenu}
                        value="Preview job"
                        onClick={
                            () => {
                                setOpen({ individualPreviewModal: true })
                                setProductData(previewData)
                            }
                        }
                    >
                        Preview job
                    </MenuItem>
                </>
            } */}

      {isInternalProfile && isMyProduct && (
        <>
          {" "}
          <Box
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#d7d0e8",
            }}
          />
          {/* <MenuItem
        className={classes.dropDownMenu}
        value={jobsPostedTabs.inactive ? "Activate Job" : "Inactivate Job"}
        onClick={() => {
          jobsPostedTabs.inactive
            ? setOpen({ activeJobModal: true })
            : setOpen({ inactiveJobModal: true });
          setProductData(previewData);
        }}
      >
        {jobsPostedTabs.inactive ? "Activate Job" : "Inactivate Job"}
      </MenuItem> */}
          <MenuItem
            className={classes.dropDownMenu}
            value="Delete"
            onClick={(event) => {
              event.stopPropagation();
              setOpen({ deleteModal: true });
              setProductData(previewData);
            }}
          >
            Delete
          </MenuItem>{" "}
        </>
      )}
      {/* {userData?.userAdditionalInfo?.profileType !== 2 && isInternalProfile && ( */}
      {isMyProduct && isInternalProfile && (
        <>
          <Box
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#d7d0e8",
            }}
          />

          <MenuItem
            className={classes.dropDownMenu}
            value="Unpublish"
            onClick={(event) => {
              event.stopPropagation();
              setOpen({ unPublishModal: true });
              setProductData(previewData);
            }}
          >
            Unpublish
          </MenuItem>
        </>
      )}
    </Box>
  );
};

export const BootstrapTooltipForDropdown = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    title={
      <TooltipForDropdown
        setOpen={props.setOpen}
        previewData={props.previewData}
        setProductData={props.setProductData}
        isDraft={props.isDraft}
        setSnackBarPopUp={props.setSnackBarPopUp}
        setShareProductDetails={props.setShareProductDetails}
      />
    }
    placement="bottom-end"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    boxShadow: "0 1px 24px 0 rgba(43, 38, 57, 0.2)",
    width: "150px",
    borderRadius: "6px",
  },
}));

const TooltipDiv = () => {
  const {
    ReactComponent: TooltipIcons,
  } = require("../../../assets/icons/tooltipFB.svg");
  const {
    ReactComponent: TooltipIcons1,
  } = require("../../../assets/icons/tooltipLkdin.svg");
  const {
    ReactComponent: TooltipIcons2,
  } = require("../../../assets/icons/tooltipChat.svg");
  const {
    ReactComponent: TooltipIcons3,
  } = require("../../../assets/icons/tooltipEmail.svg");
  const {
    ReactComponent: TooltipIcons4,
  } = require("../../../assets/icons/tooltipCopy.svg");

  return (
    <div style={{ padding: "10px ", width: "266px" }}>
      <TooltipIcons style={{ marginRight: " 16px", cursor: "pointer" }} />
      <TooltipIcons1 style={{ marginRight: " 16px", cursor: "pointer" }} />
      <TooltipIcons2 style={{ marginRight: " 16px", cursor: "pointer" }} />
      <TooltipIcons3 style={{ marginRight: " 16px", cursor: "pointer" }} />
      <TooltipIcons4 style={{ cursor: "pointer" }} />
    </div>
  );
};

export const BootstrapTooltipForShare = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    title={<TooltipDiv />}
    placement="bottom-end"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#fff",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    boxShadow: "0 1px 24px 0 rgba(43, 38, 57, 0.2)",
    width: "266px",
    borderRadius: "6px",
  },
}));

const ProductCardBase = ({
  index,
  item,
  setProductData,
  setOpen,
  isCreatedByMe,
  isDraft,
  gridCountSm,
  publicView,
  userData,
  companyDetails,
  accountGuid,
  setSnackBarPopUp,
  setGuestUserPopUp,
  setShareProductDetails,
}) => {
  const cartData = useAppSelector((state) => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useProductCardViewStyles();
  const [showVisibilityModal, setShowVisibilityModal] = useState(false);
  const {
    ReactComponent: LikeIcon,
  } = require("../../../assets/icons/like-icon.svg");
  const {
    ReactComponent: WhishlistIcon,
  } = require("../../../assets/icons/whishlist-icon.svg");
  const {
    ReactComponent: WhishlistIconFill,
  } = require("../../../assets/icons/wishlistFill.svg");
  const {
    ReactComponent: CartIcon,
  } = require("../../../assets/icons/Icons_Light_Cart Icon.svg");
  const {
    ReactComponent: CommmentIcon,
  } = require("../../../assets/icons/Comments.svg");
  const {
    ReactComponent: DownloadIcon,
  } = require("../../../assets/icons/Download.svg");
  const {
    ReactComponent: ViewIcon,
  } = require("../../../assets/icons/Views.svg");
  const {
    ReactComponent: LikesIcon,
  } = require("../../../assets/icons/Likes.svg");

  const {
    ReactComponent: MoreOptionCard,
  } = require("../../../assets/icons/Icons_Light_More options for jobs card Icon.svg");

  const {
    ReactComponent: WebIcon,
  } = require("../../../assets/icons/ProductHover/Icons_Light_Website Icon.svg");

  const {
    ReactComponent: LockIcon,
  } = require("../../../assets/icons/ProductHover/Icons_Light_Lock Icon (1).svg");

  const {
    ReactComponent: TeamIcon,
  } = require("../../../assets/icons/ProductHover/Icons_Light_Team Icon (1).svg");

  const {
    ReactComponent: ThreeDIcon,
  } = require("../../../assets/icons/ProductHover/Icons_Light_3D square Icon.svg");

  const {
    ReactComponent: ImageIcon,
  } = require("../../../assets/icons/ProductHover/Icons_Light_Image Icon.svg");
  const {
    ReactComponent: PatternIcon,
  } = require("../../../assets/icons/ProductHover/Icons_Light_Pattern Icon.svg");
  const {
    ReactComponent: SketchIcon,
  } = require("../../../assets/icons/ProductHover/Icons_Light_Sketch Icon.svg");

  const {
    ReactComponent: ArtIcon,
  } = require("../../../assets/icons/ProductHover/Icons_Light_Artwork Icon.svg");

  const productDataInddd = item?.productDetailedEntity || item;

  const onCardClick = (item) => {
    if (productDataInddd?.productID) {
      navigate(`/products/${cryptr.encrypt(productDataInddd.productID)}`, {
        replace: false,
      });
    }

    if (productDataInddd?.productLibraryId) {
      navigate(
        `/library-product/${cryptr.encrypt(productDataInddd.productLibraryId)}`,
        {
          replace: false,
        }
      );
    }
  };
  const ifLoggedIn = useSelector(
    (state: IRootState) => state.register?.loginStatus
  );
  const [like, setLike] = useState(
    productDataInddd?.isLikedByMe ||
      productDataInddd?.libraryProductAdditionalDetails?.isLikedByMe
  );

  const handleLike = () => {
    if (ifLoggedIn === "SUCCESS") {
      if (productDataInddd?.productID) {
        dispatch({
          type: productFavouriteReducerTypes.SET_PRODUCT_FAVOURITE,
          payload: {
            accountGuid,
            productId: productDataInddd?.productID,
            isLike: !like,
          },
        });
      } else {
        dispatch({
          type: libraryFavouriteReducerTypes.SET_LIBRARY_FAVOURITE,
          payload: {
            accountGuid,
            libraryProductId: productDataInddd?.productLibraryId,
            isLike: !like,
          },
        });
      }

      if (like) {
        setLikeCounter(parseInt(likeCounter) - 1);
      } else {
        setLikeCounter(parseInt(likeCounter) + 1);
      }

      setLike(!like);
    } else {
      setGuestUserPopUp(true);
    }
  };

  const [list, unList] = useState(
    productDataInddd?.isSavedByMe ||
      productDataInddd?.libraryProductAdditionalDetails?.isSavedByMe
  );

  const handleList = () => {
    if (ifLoggedIn === "SUCCESS") {
      if (productDataInddd?.productLibraryId) {
        dispatch({
          type: saveLibraryProductTypes.SAVE_LIBRARY_PRODUCT,
          payload: {
            accountGuid,
            productLibraryId: productDataInddd?.productLibraryId,
            isSaved: !list,
          },
        });
      } else {
        dispatch({
          type: saveProductTypes.SAVE_PRODUCT,
          payload: {
            accountGuid,
            productId: productDataInddd?.productID,
            isSaved: !list,
          },
        });
      }

      unList(!list);
    } else {
      setGuestUserPopUp(true);
    }
  };

  if (!item) {
    return <Typography>Invalid Product Data</Typography>;
  }

  const IconVisibilty = () => {
    if (productDataInddd?.visibilityId) {
      switch (parseInt(productDataInddd?.visibilityId)) {
        case 1:
          return <WebIcon />;
        case 2:
          return <LockIcon />;
        case 3:
          return <TeamIcon />;
        default:
          return <WebIcon />;
      }
    }

    return <WebIcon />;
  };

  const handleCartClick = () => {
    if (ifLoggedIn === "SUCCESS") {
      dispatch(
        CartActions.addToCart({
          accountGuid,
          productId:
            item.productID || item?.productDetailedEntity?.productID || 0,
          libraryProductId: productDataInddd.productLibraryId || 0,
        })
      );
    } else {
      setGuestUserPopUp(true);
    }
  };

  const hasItemInCart = (() => {
    return cartData?.cart?.cartDetailsList?.some(
      ({ productId, libraryProductId }) =>
        productId === productDataInddd?.productID ||
        libraryProductId === productDataInddd?.productLibraryId
    );
  })();

  const price = `${productDataInddd?.currencyId === 1 ? "$" : "€"}${
    productDataInddd?.productPrice ?? productDataInddd?.price ?? 0
  }`;

  const productName =
    productDataInddd.productName || productDataInddd?.libraryItemTitle || "-";

  const canAddToCart =
    ((productDataInddd?.productPrice || productDataInddd?.price) > 0 && true) ||
    false;

  const [likeCounter, setLikeCounter] = useState(
    productDataInddd?.likeCount || productDataInddd?.libraryProductLikeCounter
  );

  useEffect(
    () =>
      setLikeCounter(
        productDataInddd?.likeCount ||
          productDataInddd?.libraryProductLikeCounter ||
          0
      ),
    []
  );
  return (
    <Grid
      item
      sm={gridCountSm || 3}
      id={`product-grid-item-${index}`}
      style={{ margin: "auto" }}
    >
      <Box className={classes.gridBox} style={{ overflow: "hidden" }}>
        <Box className={classes.viewPortBox} onClick={() => onCardClick(item)}>
          <Box className={classes.imageContainer}>
            {!productDataInddd?.productLibraryId && (
              <Box className={classes.hoverContent}>
                <Box style={{ flex: 1 }}></Box>
                <Box style={{ display: "flex", flexDirection: "row" }}>
                  {item?.threeDFilesUploaded?.length ? (
                    <Box className={classes.iconContainer}>
                      <ThreeDIcon />
                    </Box>
                  ) : null}
                  {item?.patternFiles?.length ? (
                    <Box className={classes.iconContainer}>
                      <PatternIcon />
                    </Box>
                  ) : null}
                  {item?.artFiles?.length ? (
                    <Box className={classes.iconContainer}>
                      <ArtIcon />
                    </Box>
                  ) : null}
                  {item?.sketchFiles?.length ? (
                    <Box className={classes.iconContainer}>
                      <SketchIcon />
                    </Box>
                  ) : null}
                  {item?.imageFiles?.length ? (
                    <Box className={classes.iconContainer}>
                      <ImageIcon />
                    </Box>
                  ) : null}
                </Box>
              </Box>
            )}
            <ProductImage item={productDataInddd} />
          </Box>
        </Box>
        <Box
          style={{
            paddingTop: 18,
            paddingBottom: 18,
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              paddingLeft: "16px",
              paddingRight: "16px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                whiteSpace: "nowrap",
                overflow: "hidden",
                paddingRight: 5,
                textOverflow: "ellipsis",
              }}
              onClick={() => onCardClick(item)}
            >
              <Typography
                className={classes.title}
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {productName}
              </Typography>
              {/* extra check of publicView , used for sprint_28 4504 bug_12  */}
              {/* {(companyDetails || publicView) && (
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    margin: "5px 0 0 5px",
                  }}
                >
                  <IconVisibilty />
                </Box>
              )} */}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                alignSelf: "flex-start",
              }}
            >
              {/* for showing price of paid products only */}
              {/* {(localStorage.getItem("ViewType") === "Librarypublic"
                ? item?.downloadType === 2
                : item?.downloadType === 3) && ( */}
              {(productDataInddd?.productPrice > 0 ||
                productDataInddd?.price > 0) && (
                <Box>
                  <Box className={classes.price}>{price}</Box>
                </Box>
              )}

              {/* )} */}

              {/*  commenting for  sprint_28 4504 bug_12 */}
              {isCreatedByMe || publicView ? (
                <BootstrapTooltipForDropdown
                  previewData={{ data: item, index }}
                  setProductData={setProductData}
                  setOpen={setOpen}
                  isDraft={isDraft}
                  setSnackBarPopUp={setSnackBarPopUp}
                  setShareProductDetails={setShareProductDetails}
                >
                  <MoreOptionCard style={{ marginLeft: "16px" }} />
                </BootstrapTooltipForDropdown>
              ) : !canAddToCart || hasItemInCart ? null : (
                <Box
                  style={{ margin: "2px 0px 0px 16px" }}
                  onClick={handleCartClick}
                >
                  <CartIcon />
                </Box>
              )}
            </Box>
          </Box>

          <Box className={classes.userInfoBox}>
            <Box style={{ flex: 1 }}>
              <Box style={{ display: "flex", flexDirection: "row", flex: 1 }}>
                <UserInfo
                  createdBy={
                    productDataInddd.productUploadedByName ||
                    productDataInddd.libraryProductAdditionalDetails
                      ?.libraryProductUploadedByName ||
                    productDataInddd.createdBy
                  }
                  profession={
                    productDataInddd.productUploadedByProfession ||
                    productDataInddd.libraryProductAdditionalDetails
                      ?.libraryProductUploadedByProfession
                  }
                  image={
                    productDataInddd.productUploadedByProfilePic ||
                    productDataInddd.uploadedByDetails?.profilePictureUrl
                  }
                  productUploadedByCoverPic={
                    productDataInddd.productUploadedByCoverPic ||
                    productDataInddd.uploadedByDetails?.coverPictureUrl
                  }
                  productUploadedByAppreciationCount={
                    productDataInddd.productUploadedByAppreciationCount ||
                    productDataInddd.libraryProductAdditionalDetails
                      ?.libraryItemUploadedByAppreciationCount
                  }
                  productUploadedByProductViewCount={
                    productDataInddd.productUploadedByProductViewCount ||
                    productDataInddd.libraryProductAdditionalDetails
                      ?.libraryItemUploadedByProductViewCount
                  }
                  productUploadedByFollowerCount={
                    productDataInddd.productUploadedByFollowerCount ||
                    productDataInddd.libraryProductAdditionalDetails
                      ?.libraryItemUploadedByFollowerCount
                  }
                  productUploadedByHeadlineText={
                    productDataInddd.productUploadedByHeadlineText ||
                    productDataInddd.libraryProductAdditionalDetails
                      ?.libraryItemUploadedByFollowerCount
                  }
                  isFollowedByMe={
                    productDataInddd.isFollowedByMe ||
                    productDataInddd.libraryProductAdditionalDetails
                      ?.isFollowedByMe
                  }
                  data={productDataInddd}
                  companyDetails={companyDetails}
                  userData={userData}
                  accountGuid={accountGuid}
                  accountIdToFollow={
                    productDataInddd?.productUploadedByAccountId
                  }
                />
              </Box>
            </Box>
            {/* commenting for sprint_28 4504 bug_12 */}
            {isCreatedByMe || publicView ? (
              <Box
                style={{
                  paddingRight: 10,
                  display: "flex",
                  ...(isDraft && { alignSelf: "flex-end" }),
                }}
                onClick={() => isCreatedByMe && setShowVisibilityModal(true)}
              >
                {isDraft ? (
                  <Typography style={{ color: "#a49bbe", fontSize: 13 }}>
                    Drafts
                  </Typography>
                ) : (
                  <>
                    <Box>
                      <IconVisibilty />
                    </Box>

                    <ProductVisibilityModal
                      open={showVisibilityModal}
                      handleClose={() => setShowVisibilityModal(false)}
                      productData={productDataInddd}
                    />
                  </>
                )}
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginRight: "8px",
                }}
              >
                {(companyDetails || publicView) && (
                  <Box style={{ marginRight: "10px" }}>
                    <IconVisibilty />
                  </Box>
                )}
                <Box onClick={handleLike}>
                  {like ? (
                    <LikesIcon
                      style={{ width: "24px", height: "24px" }}
                      // onClick={setLike(!like)}
                    />
                  ) : (
                    <LikeIcon
                      style={{ width: "24px", height: "24px" }}
                      // onClick={setLike(!like)}
                    />
                  )}
                </Box>
                <Box style={{ marginLeft: "10px" }} onClick={handleList}>
                  {list ? (
                    <WhishlistIconFill
                      style={{ width: "24px", height: "24px" }}
                    />
                  ) : (
                    <WhishlistIcon style={{ width: "24px", height: "24px" }} />
                  )}
                </Box>
              </Box>
            )}
          </Box>

          <Box className={classes.iconBox} onClick={() => onCardClick(item)}>
            <Box
              className={classes.icon}
              style={{
                borderRight: "1px solid #a49bbe",
              }}
            >
              <Box style={{ marginRight: 6 }}>
                <ViewIcon />
              </Box>
              <Box style={{ color: "#2b2639" }}>
                {makeNumUserFriendly(
                  productDataInddd.viewsCount ||
                    productDataInddd.libraryProductViewsCounter
                )}
              </Box>
            </Box>
            <Box
              className={classes.icon}
              style={{
                borderRight: "1px solid #a49bbe",
              }}
            >
              <Box style={{ marginRight: 6 }}>
                <DownloadIcon />
              </Box>
              <Box style={{ color: "#2b2639" }}>
                {makeNumUserFriendly(
                  productDataInddd.downloadCount ||
                    productDataInddd.libraryProductDownloadCounter
                )}
              </Box>
            </Box>
            <Box
              className={classes.icon}
              style={{
                borderRight: "1px solid #a49bbe",
              }}
            >
              <Box style={{ marginRight: 6 }}>
                <CommmentIcon />
              </Box>
              <Box style={{ color: "#2b2639" }}>
                {makeNumUserFriendly(
                  productDataInddd.commentCount ||
                    productDataInddd.libraryProductCommentCounter
                )}
              </Box>
            </Box>
            <Box className={classes.icon}>
              <Box style={{ marginRight: 6 }}>
                <LikesIcon style={{ width: "100%", height: "100%" }} />
              </Box>
              <Box style={{ color: "#2b2639" }}>
                {makeNumUserFriendly(
                  likeCounter
                  // productDataInddd.likeCount ||
                  //   productDataInddd.libraryProductLikeCounter
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

const ProductCard = React.memo(ProductCardBase);

function ProductCardView({
  productsList = [],
  setOpen = () => {},
  setProductData = (data: any) => {},
  isCreatedByMe = false,
  isDraft = false,
  gridCountSm = 3,
  publicView = false,
  userData = null,
  companyDetails = null,
}) {
  const classes = useProductCardViewStyles();
  const accountGuid = useSelector(accountGuidSelector, shallowEqual);

  const [snackBarPopUp, setSnackBarPopUp] = useState(false);
  const {
    ReactComponent: RedAlert,
  } = require("../../../assets/icons/red_alert.svg");

  useEffect(() => {
    if (snackBarPopUp) {
      setTimeout(() => {
        setSnackBarPopUp(false);
      }, 3000);
    }
  }, [snackBarPopUp]);

  const [guestUserPopUp, setGuestUserPopUp] = useState(false);
  const [shareProductDetails, setShareProductDetails] = useState<any>({});
  const sharedProduct =
    shareProductDetails?.data?.productDetailedEntity?.productID;

  const shareUrl = shareProductDetails?.data?.productDetailedEntity?.productID
    ? `${window.location.origin}/products/${cryptr.encrypt(
        shareProductDetails?.data?.productDetailedEntity?.productID
      )}`
    : `${window.location.origin}/library-product/${cryptr.encrypt(
        shareProductDetails?.data?.productDetailedEntity?.productID
      )}`;

  return (
    <>
      {guestUserPopUp && (
        <GuestUserPopUp onClose={() => setGuestUserPopUp(false)} />
      )}
      <ShareProductModal
        open={!!sharedProduct}
        handleCloseShareModal={() => {
          setShareProductDetails({});
        }}
        shareUrl={shareUrl}
      />

      {productsList.length !== 0 ? (
        <Box className={classes.mainBox}>
          <Snackbar
            open={snackBarPopUp}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            style={{
              // width: "100%",
              backgroundColor: "white",
              width: 500,
              height: 77,
              borderRadius: "6px",
              boxShadow: "0 10px 16px 0 rgba(122, 72, 255, 0.1)",
            }}
            // autoHideDuration={5000}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
              }}
            >
              <RedAlert style={{ margin: "5px", width: 40 }} />
              <Typography>
                You don't have the access to this product. Please contact your
                admin or the owner for request access
              </Typography>
            </Box>
          </Snackbar>
          <Box>
            <Grid container spacing={2} id="product-grid-container">
              {productsList &&
                productsList?.map((item, index) => (
                  <ProductCard
                    index={index}
                    key={
                      item.productID ||
                      item?.productDetailedEntity?.productID ||
                      index
                    }
                    item={item || item.productDetailedEntity}
                    setOpen={setOpen}
                    setProductData={setProductData}
                    isCreatedByMe={isCreatedByMe}
                    isDraft={isDraft}
                    gridCountSm={gridCountSm}
                    publicView={publicView}
                    userData={userData}
                    companyDetails={companyDetails}
                    accountGuid={accountGuid}
                    setSnackBarPopUp={setSnackBarPopUp}
                    setGuestUserPopUp={setGuestUserPopUp}
                    setShareProductDetails={setShareProductDetails}
                  />
                ))}
            </Grid>
          </Box>
        </Box>
      ) : (
        <NotFound />
      )}
    </>
  );
}

export default React.memo(ProductCardView);
