const initialState = {
  loading: false,
  sortTypesData: null,
  sortTypesError: null,
  timeSortData: null,
  timeSortError: null,
  availabilityList: null,
  availabilityError: null,
  toolTypeList: null,
  toolTypeError: null,
  designationData: null,
  designationError: null,
  companyNoOfEmpData: null,
  companyNoOfEmpError: null,
  companyIndustryTypeData: null,
  companyIndustryTypeError: null,
  companyListData: null,
  companyListError: null,
  designerFilteredData: null,
  designerFilteredError: null,
  companiesFilteredData: null,
  companiesFilteredError: null,
  filteredProductsData: null,
  filteredProductsError: null,
  companiesAutocompleteData: null,
  companiesAutocompleteError: null,
  designersAutocompleteData: null,
  designersAutocompleteError: null,
  productsAutocompleteData: null,
  productsAutocompleteError: null,

  materialsAutoCompleteData: null,
  materialsAutoCompleteError: null,

  sizeTypeList: null,
  sizeTypeError: null,

  productTagList: null,
  productTagError: null,
};

///Action
export const filterReducerTypes = {
  SORT_TYPES: "SORT_TYPES",
  REQUEST_SORT_TYPES: "REQUEST_SORT_TYPES",
  RESPONSE_SORT_TYPES: "RESPONSE_SORT_TYPES",
  ERROR_SORT_TYPES: "ERROR_SORT_TYPES",

  TIME_SORT_TYPES: "TIME_SORT_TYPES",
  REQUEST_TIME_SORT_TYPES: "REQUEST_TIME_SORT_TYPES",
  RESPONSE_TIME_SORT_TYPES: "RESPONSE_TIME_SORT_TYPES",
  ERROR_TIME_SORT_TYPES: "ERROR_TIME_SORT_TYPES",

  AVAILABILITY_TYPES: "AVAILABILITY_TYPES",
  REQUEST_AVAILABILITY_TYPES: "REQUEST_AVAILABILITY_TYPES",
  RESPONSE_AVAILABILITY_TYPES: "RESPONSE_AVAILABILITY_TYPES",
  ERROR_AVAILABILITY_TYPES: "ERROR_AVAILABILITY_TYPES",

  TOOL_TYPES: "TOOL_TYPES",
  REQUEST_TOOL_TYPES: "REQUEST_TOOL_TYPES",
  RESPONSE_TOOL_TYPES: "RESPONSE_TOOL_TYPES",
  ERROR_TOOL_TYPES: "ERROR_TOOL_TYPES",

  DESIGNATION_FILTER: "DESIGNATION_FILTER",
  REQUEST_DESIGNATION_FILTER: "REQUEST_DESIGNATION_FILTER",
  RESPONSE_DESIGNATION_FILTER: "RESPONSE_DESIGNATION_FILTER",
  ERROR_DESIGNATION_FILTER: "ERROR_DESIGNATION_FILTER",

  EMPLOYESS_IN_COMPANY: "EMPLOYESS_IN_COMPANY",
  REQUEST_EMPLOYESS_IN_COMPANY: "REQUEST_EMPLOYESS_IN_COMPANY",
  RESPONSE_EMPLOYESS_IN_COMPANY: "RESPONSE_EMPLOYESS_IN_COMPANY",
  ERROR_EMPLOYESS_IN_COMPANY: "ERROR_EMPLOYESS_IN_COMPANY",

  COMPANY_TYPE: "COMPANY_TYPE",
  REQUEST_COMPANY_TYPE: "REQUEST_COMPANY_TYPE",
  RESPONSE_COMPANY_TYPE: "RESPONSE_COMPANY_TYPE",
  ERROR_COMPANY_TYPE: "ERROR_COMPANY_TYPE",

  COMPANY_LIST: "COMPANY_LIST",
  REQUEST_COMPANY_LIST: "REQUEST_COMPANY_LIST",
  RESPONSE_COMPANY_LIST: "RESPONSE_COMPANY_LIST",
  ERROR_COMPANY_LIST: "ERROR_COMPANY_LIST",

  DESIGNERS_FILTERED_LIST: "DESIGNERS_FILTERED_LIST",
  REQUEST_DESIGNERS_FILTERED_LIST: "REQUEST_DESIGNERS_FILTERED_LIST",
  RESPONSE_DESIGNERS_FILTERED_LIST: "RESPONSE_DESIGNERS_FILTERED_LIST",
  ERROR_DESIGNERS_FILTERED_LIST: "ERROR_DESIGNERS_FILTERED_LIST",
  CLEAR_DESIGNERS_FILTERED_LIST: "CLEAR_DESIGNERS_FILTERED_LIST",

  COMPANIES_FILTERED_LIST: "COMPANIES_FILTERED_LIST",
  REQUEST_COMPANIES_FILTERED_LIST: "REQUEST_COMPANIES_FILTERED_LIST",
  RESPONSE_COMPANIES_FILTERED_LIST: "RESPONSE_COMPANIES_FILTERED_LIST",
  ERROR_COMPANIES_FILTERED_LIST: "ERROR_COMPANIES_FILTERED_LIST",
  CLEAR_COMPANIES_FILTERED_LIST: "CLEAR_COMPANIES_FILTERED_LIST",

  PRODUCT_FILTERED_LIST: "PRODUCT_FILTERED_LIST",
  REQUEST_PRODUCT_FILTERED_LIST: "REQUEST_PRODUCT_FILTERED_LIST",
  RESPONSE_PRODUCT_FILTERED_LIST: "RESPONSE_PRODUCT_FILTERED_LIST",
  ERROR_PRODUCT_FILTERED_LIST: "ERROR_PRODUCT_FILTERED_LIST",
  CLEAR_PRODUCT_FILTERED_LIST: "CLEAR_PRODUCT_FILTERED_LIST",

  COMPANIES_AUTOCOMPLETE: "COMPANIES_AUTOCOMPLETE",
  REQUEST_COMPANIES_AUTOCOMPLETE: "REQUEST_COMPANIES_AUTOCOMPLETE",
  RESPONSE_COMPANIES_AUTOCOMPLETE: "RESPONSE_COMPANIES_AUTOCOMPLETE",
  ERROR_COMPANIES_AUTOCOMPLETE: "ERROR_COMPANIES_AUTOCOMPLETE",
  CLEAR_COMPANIES_AUTOCOMPLETE: "CLEAR_COMPANIES_AUTOCOMPLETE",

  DESIGNER_FILTER_BY_NAME: "DESIGNER_FILTER_BY_NAME",
  REQUEST_DESIGNER_FILTER_BY_NAME: "REQUEST_DESIGNER_FILTER_BY_NAME",
  RESPONSE_DESIGNER_FILTER_BY_NAME: "RESPONSE_DESIGNER_FILTER_BY_NAME",
  ERROR_DESIGNER_FILTER_BY_NAME: "ERROR_DESIGNER_FILTER_BY_NAME",
  CLEAR_DESIGNER_FILTER_BY_NAME: "CLEAR_DESIGNER_FILTER_BY_NAME",

  PRODUCT_AUTOCOMPLETE: "PRODUCT_AUTOCOMPLETE",
  REQUEST_PRODUCT_AUTOCOMPLETE: "REQUEST_PRODUCT_AUTOCOMPLETE",
  RESPONSE_PRODUCT_AUTOCOMPLETE: "RESPONSE_PRODUCT_AUTOCOMPLETE",
  ERROR_PRODUCT_AUTOCOMPLETE: "ERROR_PRODUCT_AUTOCOMPLETE",
  CLEAR_PRODUCT_AUTOCOMPLETE: "CLEAR_PRODUCT_AUTOCOMPLETE",

  MATERIAL_AUTOCOMPLETE: "MATERIAL_AUTOCOMPLETE",
  REQUEST_MATERIAL_AUTOCOMPLETE: "REQUEST_MATERIAL_AUTOCOMPLETE",
  RESPONSE_MATERIAL_AUTOCOMPLETE: "RESPONSE_MATERIAL_AUTOCOMPLETE",
  ERROR_MATERIAL_AUTOCOMPLETE: "ERROR_MATERIAL_AUTOCOMPLETE",
  CLEAR_MATERIAL_AUTOCOMPLETE: "CLEAR_MATERIAL_AUTOCOMPLETE",

  SIZE_TYPES: "SIZE_TYPES",
  REQUEST_SIZE_TYPES: "REQUEST_SIZE_TYPES",
  RESPONSE_SIZE_TYPES: "RESPONSE_SIZE_TYPES",
  ERROR_SIZE_TYPES: "ERROR_SIZE_TYPES",

  PRODUCT_TAG: "PRODUCT_TAG",
  REQUEST_PRODUCT_TAG: "REQUEST_PRODUCT_TAG",
  RESPONSE_PRODUCT_TAG: "RESPONSE_PRODUCT_TAG",
  ERROR_PRODUCT_TAG: "ERROR_PRODUCT_TAG",
};

////reducer
export function filterReducer(state = initialState, action) {
  switch (action.type) {
    case filterReducerTypes.REQUEST_SORT_TYPES:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_SORT_TYPES:
      return {
        ...state,
        sortTypesError: null,
        loading: false,
        sortTypesData: action.payload,
      };
    case filterReducerTypes.ERROR_SORT_TYPES:
      return {
        ...state,
        loading: false,
        sortTypesData: null,
        sortTypesError: action.payload,
      };

    case filterReducerTypes.REQUEST_TIME_SORT_TYPES:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_TIME_SORT_TYPES:
      return {
        ...state,
        timeSortError: null,
        loading: false,
        timeSortData: action.payload,
      };
    case filterReducerTypes.ERROR_TIME_SORT_TYPES:
      return {
        ...state,
        loading: false,
        timeSortData: null,
        timeSortError: action.payload,
      };

    case filterReducerTypes.REQUEST_AVAILABILITY_TYPES:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_AVAILABILITY_TYPES:
      return {
        ...state,
        availabilityError: null,
        loading: false,
        availabilityList: action.payload,
      };
    case filterReducerTypes.ERROR_AVAILABILITY_TYPES:
      return {
        ...state,
        loading: false,
        availabilityList: null,
        availabilityError: action.payload,
      };

    case filterReducerTypes.REQUEST_TOOL_TYPES:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_TOOL_TYPES:
      return {
        ...state,
        toolTypeError: null,
        loading: false,
        toolTypeList: action.payload,
      };
    case filterReducerTypes.ERROR_TOOL_TYPES:
      return {
        ...state,
        loading: false,
        toolTypeList: null,
        toolTypeError: action.payload,
      };

    case filterReducerTypes.REQUEST_DESIGNATION_FILTER:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_DESIGNATION_FILTER:
      return {
        ...state,
        designationError: null,
        loading: false,
        designationData: action.payload,
      };
    case filterReducerTypes.ERROR_DESIGNATION_FILTER:
      return {
        ...state,
        loading: false,
        designationData: null,
        designationError: action.payload,
      };

    case filterReducerTypes.REQUEST_EMPLOYESS_IN_COMPANY:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_EMPLOYESS_IN_COMPANY:
      return {
        ...state,
        companyNoOfEmpError: null,
        loading: false,
        companyNoOfEmpData: action.payload,
      };
    case filterReducerTypes.ERROR_EMPLOYESS_IN_COMPANY:
      return {
        ...state,
        loading: false,
        companyNoOfEmpData: null,
        companyNoOfEmpError: action.payload,
      };

    case filterReducerTypes.REQUEST_COMPANY_TYPE:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_COMPANY_TYPE:
      return {
        ...state,
        companyIndustryTypeError: null,
        loading: false,
        companyIndustryTypeData: action.payload,
      };
    case filterReducerTypes.ERROR_COMPANY_TYPE:
      return {
        ...state,
        loading: false,
        companyIndustryTypeData: null,
        companyIndustryTypeError: action.payload,
      };

    case filterReducerTypes.REQUEST_COMPANY_LIST:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_COMPANY_LIST:
      return {
        ...state,
        companyListError: null,
        loading: false,
        companyListData: action.payload,
      };
    case filterReducerTypes.ERROR_COMPANY_LIST:
      return {
        ...state,
        loading: false,
        companyListData: null,
        companyListError: action.payload,
      };

    case filterReducerTypes.REQUEST_DESIGNERS_FILTERED_LIST:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_DESIGNERS_FILTERED_LIST:
      return {
        ...state,
        designerFilteredError: null,
        loading: false,
        designerFilteredData: action.payload,
      };
    case filterReducerTypes.ERROR_DESIGNERS_FILTERED_LIST:
      return {
        ...state,
        loading: false,
        designerFilteredData: null,
        designerFilteredError: action.payload,
      };
    case filterReducerTypes.CLEAR_DESIGNERS_FILTERED_LIST:
      return {
        ...state,
        loading: false,
        designerFilteredData: null,
        designerFilteredError: null,
      };

    case filterReducerTypes.REQUEST_COMPANIES_FILTERED_LIST:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_COMPANIES_FILTERED_LIST:
      return {
        ...state,
        companiesFilteredError: null,
        loading: false,
        companiesFilteredData: action.payload,
      };
    case filterReducerTypes.ERROR_COMPANIES_FILTERED_LIST:
      return {
        ...state,
        loading: false,
        companiesFilteredData: null,
        companiesFilteredError: action.payload,
      };
    case filterReducerTypes.CLEAR_COMPANIES_FILTERED_LIST:
      return {
        ...state,
        loading: false,
        companiesFilteredData: null,
        companiesFilteredError: null,
      };

    case filterReducerTypes.REQUEST_PRODUCT_FILTERED_LIST:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_PRODUCT_FILTERED_LIST:
      return {
        ...state,
        filteredProductsError: null,
        loading: false,
        filteredProductsData: action.payload,
      };
    case filterReducerTypes.ERROR_PRODUCT_FILTERED_LIST:
      return {
        ...state,
        loading: false,
        filteredProductsData: null,
        filteredProductsError: action.payload,
      };
    case filterReducerTypes.CLEAR_PRODUCT_FILTERED_LIST:
      return {
        ...state,
        loading: false,
        filteredProductsData: null,
        filteredProductsError: null,
      };

    case filterReducerTypes.REQUEST_COMPANIES_AUTOCOMPLETE:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_COMPANIES_AUTOCOMPLETE:
      return {
        ...state,
        companiesAutocompleteError: null,
        loading: false,
        companiesAutocompleteData: action.payload,
      };
    case filterReducerTypes.ERROR_COMPANIES_AUTOCOMPLETE:
      return {
        ...state,
        loading: false,
        companiesAutocompleteData: null,
        companiesAutocompleteError: action.payload,
      };
    case filterReducerTypes.CLEAR_COMPANIES_AUTOCOMPLETE:
      return {
        ...state,
        loading: false,
        companiesAutocompleteData: null,
        companiesAutocompleteError: null,
      };

    case filterReducerTypes.REQUEST_DESIGNER_FILTER_BY_NAME:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_DESIGNER_FILTER_BY_NAME:
      return {
        ...state,
        designersAutocompleteError: null,
        loading: false,
        designersAutocompleteData: action.payload,
      };
    case filterReducerTypes.ERROR_DESIGNER_FILTER_BY_NAME:
      return {
        ...state,
        loading: false,
        designersAutocompleteData: null,
        designersAutocompleteError: action.payload,
      };
    case filterReducerTypes.CLEAR_DESIGNER_FILTER_BY_NAME:
      return {
        ...state,
        loading: false,
        designersAutocompleteData: null,
        designersAutocompleteError: null,
      };

    case filterReducerTypes.REQUEST_PRODUCT_AUTOCOMPLETE:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_PRODUCT_AUTOCOMPLETE:
      return {
        ...state,
        productsAutocompleteError: null,
        loading: false,
        productsAutocompleteData: action.payload,
      };
    case filterReducerTypes.ERROR_PRODUCT_AUTOCOMPLETE:
      return {
        ...state,
        loading: false,
        productsAutocompleteData: null,
        productsAutocompleteError: action.payload,
      };
    case filterReducerTypes.CLEAR_PRODUCT_AUTOCOMPLETE:
      return {
        ...state,
        loading: false,
        productsAutocompleteData: null,
        productsAutocompleteError: null,
      };

    case filterReducerTypes.REQUEST_MATERIAL_AUTOCOMPLETE:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_MATERIAL_AUTOCOMPLETE:
      return {
        ...state,
        materialsAutocompleteError: null,
        loading: false,
        materialsAutoCompleteData: action.payload,
      };
    case filterReducerTypes.ERROR_MATERIAL_AUTOCOMPLETE:
      return {
        ...state,
        loading: false,
        materialsAutoCompleteData: null,
        materialsAutocompleteError: action.payload,
      };
    case filterReducerTypes.CLEAR_MATERIAL_AUTOCOMPLETE:
      return {
        ...state,
        loading: false,
        materialsAutoCompleteData: null,
        materialsAutocompleteError: null,
      };

    case filterReducerTypes.REQUEST_SIZE_TYPES:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_SIZE_TYPES:
      return {
        ...state,
        sizeTypeError: null,
        loading: false,
        sizeTypeList: action.payload,
      };
    case filterReducerTypes.ERROR_SIZE_TYPES:
      return {
        ...state,
        loading: false,
        sizeTypeList: null,
        sizeTypeError: action.payload,
      };

    case filterReducerTypes.REQUEST_PRODUCT_TAG:
      return { ...state, loading: true };
    case filterReducerTypes.RESPONSE_PRODUCT_TAG:
      return {
        ...state,
        productTagError: null,
        loading: false,
        productTagList: action.payload,
      };
    case filterReducerTypes.ERROR_PRODUCT_TAG:
      return {
        ...state,
        loading: false,
        productTagList: null,
        productTagError: action.payload,
      };

    default:
      return state;
  }
}
