import { useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";
import { ColorModeContext } from "./ColorModeContext";

export const ColorModeProvider = ({ children }) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = React.useState(prefersDarkMode ? "dark" : "light");

  useEffect(() => {
    setMode(localStorage.getItem("theme-mode") || "light");
  }, []);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";

          localStorage.setItem("theme-mode", newMode);

          return newMode;
        });
      },
      mode,
      isDarkMode: mode === "dark",
    }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      {children}
    </ColorModeContext.Provider>
  );
};
