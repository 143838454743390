import React, { useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import { useDispatch } from "react-redux";
import { LightModeCross, NightModeCross } from "app/components/Logo/logos";
import { useAppSelector } from "app/utils/hooks";
import {
  BlackBorderedButton,
  PurpleButton,
} from "app/components/UIFormFields/Buttons";
import { useNavigate } from "react-router";
import { employeesModalPreviewType } from "app/redux/reducers/localDataReducer/companyPreview";

export const useUpload3dModalStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 497,
    maxHeight: "95vh",
    height: 392,
    maxWidth: "80%",
    borderRadius: "15px",
    backgroundColor: "rgba(43, 38, 57,.630)",
    color: "white",
    overflowY: "auto",
  },
  closeBox: {
    top: 28,
    right: 24,
    position: "absolute",
  },
  titleBox: {
    margin: "54px 0px 0px 0px",
  },
  cancelButton: {
    width: "86px",
    height: "54px",
    flexGrow: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
    border: "solid 1px #2b2639",
    marginRight: "12px !important",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: "34px",
  },
}));

export default function InviteMoreEmployeeAlert({ open, handleClose }) {
  const {
    ReactComponent: Alert_Icon,
  } = require("../../../../assets/icons/Icons_Light_Alert Icon.svg");

  const classes = useUpload3dModalStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const userData = useAppSelector((state) => state.register);
  const [inviteForm, setInviteForm] = useState(false);

  const navigate = useNavigate();

  const heading = {
    nightMode: {
      fontSize: "27px",
      fontWeight: "bold",
      color: "#fff",
      paddingBottom: "8px",
      fontFamily: "Poppins",
    },
    lightMode: {
      fontSize: "28px",
      fontWeight: "bold",
      color: "#2b2639",
      paddingBottom: "8px",
    },
  };

  const openInviteForm = () => {
    navigate({ pathname: "/employees" }, { replace: false });
    handleClose();
    dispatch({
      type: employeesModalPreviewType.CLOSE_EMPLOYEES_LIST_MODAL,
    });
  };

  return (
    <Box
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <Modal open={open}>
        <Box
          className={classes.modalStyle}
          style={{
            backgroundColor: theme?.palette?.mode === "light" && "#fff",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box className={classes.closeBox}>
              {theme?.palette?.mode === "light" ? (
                <LightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              ) : (
                <NightModeCross
                  onClick={() => handleClose(false)}
                  style={{ cursor: "pointer" }}
                />
              )}
            </Box>
            <Box>
              <Box>
                <img
                  src={Alert_Icon}
                  alt="Alert_logo"
                  style={{
                    width: "100px",
                    height: "100px",
                    position: "relative",
                    top: "54px",
                    objectFit: "contain",
                  }}
                ></img>
              </Box>
              <Box
                className={classes.titleBox}
                style={{
                  width: "100%",
                }}
              >
                <p
                  style={{
                    width: "389px",
                    height: "41px",
                    flexGrow: 0,
                    margin: "24px 6px 8px 0",
                    fontFamily: "Poppins",
                    fontSize: "27px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#2b2639",
                  }}
                >
                  {"Add seat for more members"}
                </p>
              </Box>
              <Box
                style={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 54,
                  paddingRight: 54,
                  width: "100%",
                  color: "black",
                }}
              >
                <p
                  style={{
                    width: "295px",
                    height: "23px",
                    flexGrow: 0,
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#2b2639",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      textAlign: "center",
                      color: "#2b2639",
                    }}
                  >
                    {"Add more employees to your company"}
                  </Typography>
                </p>
              </Box>
            </Box>
            <Box sx={{ position: "relative", top: "34px" }}>
              <BlackBorderedButton
                sx={{
                  width: "86px",
                }}
                onClick={handleClose}
              >
                Cancel
              </BlackBorderedButton>
              <PurpleButton
                onClick={openInviteForm}
                style={{
                  width: "100px",
                  marginLeft: 16,
                  padding: "12px",
                }}
              >
                Add seat
              </PurpleButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}
