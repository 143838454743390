import { Box, Chip, Typography, Avatar } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { useTheme } from "@mui/system";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { isImage } from "app/utils/helper";
import {
  BlackBorderedButton,
  GradientColoredButton,
} from "app/components/UIFormFields/Buttons";
import { useProductDetailsStyles } from "styles/productDetails";
import { CategoryDataContext } from "../../CategoryComponent/CategoryDataContext";

const dummyData = {
  size: [
    { name: "XS" },
    { name: "S" },
    { name: "M" },
    { name: "L" },
    { name: "XL" },
    { name: "XXL" },
  ],
  color: [
    { colorCode: "#ffaf48" },
    { colorCode: "#2b2639" },
    { colorCode: "#1aafd0" },
    { colorCode: "#ffaf48" },
  ],
  categories: ["Dresses", "Suit", "Jackets", "Bag", "Hats"],
  material: ["Cotton", "Spandex", "Wool", "Polyester", "Linen"],
  tool: ["3D max", "Cinema 4D", "Blender"],
  tags: [
    "Fashion",
    "Fashion style",
    "Clothing",
    "Fashion design 3D",
    "T-shirt",
  ],
};

export default function IndividualProductPreviewUpload({
  individualProductData,
  isMyProduct,
  handleClosePreview,
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.register);
  const accountGuid = userData?.userInfo?.accountGuid;
  const categoryTypes = useSelector((state) => state.category);
  const filterTypes = useSelector((state) => state.filterTypes);
  const materialList = useSelector((state) => state.materialList);
  const productSize = useSelector((state) => state.productSize);
  const currencies = useSelector((state) => state.currencies);
  const uploadProduct = useSelector((state) => state.uploadDesign);
  const uploadProductFile = useSelector((state) => state.uploadProductFile);
  const productDetails = useSelector((state) => state.productDetails);
  const toolTypes = filterTypes.toolTypeList;
  const classes = useProductDetailsStyles();
  const [sizeList, setSizeList] = useState(null);
  const [colorList, setColorList] = useState(null);
  const [previewData, setPreviewData] = useState(
    JSON.parse(localStorage.getItem("productUploadData"))
  );

  // const productTag = [
  //   {
  //     tagName: "#first",
  //     tagId: 1,
  //   },
  //   {
  //     tagName: "#second",
  //     tagId: 2,
  //   },
  //   {
  //     tagName: "#third",
  //     tagId: 3,
  //   },
  // ];

  const {
    ReactComponent: Emoticon,
  } = require("../../../../assets/icons/Icons_Light_Emoticon Icon.svg");
  const {
    ReactComponent: ShareIcon,
  } = require("../../../../assets/icons/Icons_Light_Share Icon.svg");
  const {
    ReactComponent: WhishlistIcon,
  } = require("../../../../assets/icons/whishlist-icon.svg");
  const {
    ReactComponent: HeartLikeIcon,
  } = require("../../../../assets/icons/Icons_Light_Like heart Icon.svg");
  const {
    ReactComponent: AddCardButtonIcon,
  } = require("../../../../assets/icons/Icons_Light_add_Cart button Icon.svg");
  const {
    ReactComponent: DownloadButtonIcon,
  } = require("../../../../assets/icons/Icons_Light_Download Icon.svg");
  const {
    ReactComponent: CommentIcon,
  } = require("../../../../assets/icons/Comments.svg");
  const {
    ReactComponent: DownloadIcon,
  } = require("../../../../assets/icons/Download.svg");
  const {
    ReactComponent: ViewIcon,
  } = require("../../../../assets/icons/Views.svg");
  const {
    ReactComponent: LikesIcon,
  } = require("../../../../assets/icons/Likes.svg");
  const {
    ReactComponent: LightFollowIcon,
  } = require("../../../../assets/icons/Icons_Light_Follow.svg");
  const {
    ReactComponent: LightFollowedIcon,
  } = require("../../../../assets/icons/Icons_Light_Followed.svg");
  const {
    ReactComponent: LightChatIcon,
  } = require("../../../../assets/icons/Icons_Light_Chat Icon.svg");
  const {
    ReactComponent: AttachmentDownloadIcon,
  } = require("../../../../assets/icons/Icons_Light_Attachment_Download Icon.svg");

  const demoUserImg = require("../../../../assets/placeholders/Adult1.png");

  const [formData, setFormData] = useState({
    review: "",
    isFollowed: false,
  });
  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    const editProductData = JSON.parse(
      localStorage.getItem("productUploadData")
    );

    setPreviewData(editProductData);
    setTimeout(() => {
      const editProductData = JSON.parse(
        localStorage.getItem("productUploadData")
      );

      setPreviewData(editProductData);
    }, 2000);
  }, []);

  const returnToolValue = (item) =>
    filterTypes?.toolTypeList?.map((value) => {
      if (parseInt(item) === parseInt(value.toolDetailId))
        return value?.toolName;
    });

  const returnTagValue = (item) =>
    filterTypes?.productTagList?.map((value) => {
      if (parseInt(item) === parseInt(value.productTagID))
        return value.productTagName;
    });
  const returnCategoryValue = (item) =>
    categoryTypes?.data?.map((value) => {
      if (parseInt(item) === parseInt(value.categoryID))
        return value.categoryName;
    });

  const handleFollowUser = () =>
    setFormData({ ...formData, isFollowed: !formData.isFollowed });
  const categoriesData = useSelector((state) => state.categories);
  const { productData } = useContext(CategoryDataContext);
  const attributeValuesMap = categoriesData?.attributesData?.attributeValuesMap;

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box style={{ width: "100%" }}>
        <Box className={classes.mainBox}>
          <Box className={classes.leftBox}>
            {/* {isImage(
            individualProductData?.data?.productImageUrl
            || individualProductData?.productDetailedEntity?.productThumbnailUploadedURL
          ) !== null ? ( */}
            {isImage(uploadProductFile?.dataThumbnail?.urlDetails) !== null ? (
              <img
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  height: 515,
                  borderRadius: "20px",
                  backgroundColor: "#ebe3ff",
                  marginBottom: "35px",
                }}
                src={uploadProductFile?.dataThumbnail?.urlDetails}
                alt="product"
              />
            ) : (
              <model-viewer
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  height: 515,
                  borderRadius: "20px",
                  backgroundColor: "#ebe3ff",
                  marginBottom: "35px",
                }}
                className={classes.modalPreview}
                src={uploadProductFile?.dataThumbnail?.urlDetails}
                ios-src=""
                alt="3d model"
                auto-rotate
                camera-controls
                ar
                ar-modes="webxr scene-viewer quick-look"
                environment-image="neutral"
                oncontextmenu="return false"
                autoplay
              ></model-viewer>
            )}

            <Typography className={classes.productName}>
              {previewData.title}
            </Typography>
            <Typography className={classes.title}>Tools</Typography>
            <Box className={classes.clickBox}>
              {previewData?.tools?.map((value, index) => {
                return (
                  <Chip
                    className={classes.chipBox}
                    key={index}
                    label={returnToolValue(value)}
                  />
                );
              })}
            </Box>
            <Typography className={classes.title}>Tags</Typography>
            <Box className={classes.clickBox}>
              {previewData?.productTag?.map((value, index) => {
                return (
                  <Chip
                    className={classes.chipBox}
                    key={index}
                    label={returnTagValue(value)}
                  />
                );
              })}
            </Box>
            {/*  */}

            {/*  */}
            {/* <Typography className={classes.title}>Categories</Typography> */}

            {/* <Box className={classes.clickBox}>
              {previewData?.categories?.map((value, index) => {
                return (
                  <Chip
                    className={classes.chipBox}
                    key={index}
                    label={returnCategoryValue(value)}
                  />
                );
              })}
            </Box> */}
            {productData?.productCategoriesTreeData?.length && (
              <Box>
                <Typography className={classes.title}>Gender</Typography>
                <Box
                  style={{
                    marginBottom: 8,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    <Box
                      style={{
                        color: "#7a48ff",
                        fontSize: 15,
                        marginBottom: 8,
                        textAlign: "left",
                        background: "#d7d0e8",
                        padding: "5px 8px",
                        borderRadius: 6,
                        marginRight: 8,
                      }}
                    >
                      {productData?.productCategoriesTreeData?.[0]?.name}
                    </Box>
                  </Box>
                </Box>

                {/*  */}
              </Box>
            )}

            {productData?.productCategoriesTreeData?.length && (
              <Box>
                <Typography className={classes.title}>Categories</Typography>
                <Box
                  style={{
                    marginBottom: 8,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Box style={{ display: "flex", flexDirection: "row" }}>
                    <Box
                      style={{
                        color: "#7a48ff",
                        fontSize: 15,
                        marginBottom: 8,
                        textAlign: "left",
                        background: "#d7d0e8",
                        padding: "5px 8px",
                        borderRadius: 6,
                        marginRight: 8,
                      }}
                    >
                      {
                        productData?.productCategoriesTreeData?.[1]
                          ?.parentCategory?.name
                      }{" "}
                      ({productData?.productCategoriesTreeData?.[1]?.name})
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}

            {productData?.attributesTreeData?.length && (
              <Box>
                <Typography className={classes.title}>Attributes</Typography>
                <Box
                  style={{
                    marginBottom: 8,
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      maxWidth: 250,
                      flexWrap: "wrap",
                    }}
                  >
                    {productData?.attributesTreeData?.map((category) => (
                      <Box
                        style={{
                          color: "#7a48ff",
                          fontSize: 15,
                          marginBottom: 8,
                          textAlign: "left",
                          background: "#d7d0e8",
                          padding: "5px 8px",
                          borderRadius: 6,
                          marginRight: 8,
                        }}
                      >
                        {category?.name}(
                        {category?.selectedAttribute?.map((item) => (
                          <>{attributeValuesMap?.[item]}</>
                        ))}
                        )
                      </Box>
                    ))}
                  </Box>
                </Box>

                {/*  */}
              </Box>
            )}

            {/* flow 170 */}
            {/* <Typography className={classes.title}>Size</Typography>
            <Box className={classes.clickBox}>
              {["Size (XS)"]?.map((value, index) => {
                return (
                  <Chip className={classes.chipBox} key={index} label={value} />
                );
              })}
            </Box> */}

            <Typography className={classes.title}>Color</Typography>
            <Box className={classes.clickBox}>
              {dummyData?.color?.map((item) => (
                <Box
                  className={classes.colorBox}
                  style={{ background: item.colorCode }}
                />
              ))}
            </Box>

            {/* <Typography className={classes.title}>Material</Typography>
            <Box className={classes.clickBox}>
              {dummyData?.material?.map((value, index) => {
                return (
                  <Chip className={classes.chipBox} key={index} label={value} />
                );
              })}
            </Box> */}

            {/* <Typography className={classes.title}>Attributes</Typography> */}
            {/* <Box className={classes.clickBox}>
              {[
                "Collar (Hood)",
                "Fit (Regular)",
                "Length (Regular)",
                "Neckline (Round)",
                "Shape (Straight)",
                "Sleeve Length(Sleeveless)",
                "Armhole type(Raglan)",
              ]?.map((value, index) => {
                return (
                  <Chip className={classes.chipBox} key={index} label={value} />
                );
              })}
            </Box> */}
            <Typography className={classes.title}>Description</Typography>
            <Typography className={classes.description}>
              {previewData?.description}
            </Typography>
          </Box>
          <Box className={classes.rightBox}>
            <Box className={classes.rightTopBox}>
              <Box className={classes.rightTopPriceBox}>
                {!previewData?.downloadType?.free && (
                  <Typography className={classes.productName}>
                    {previewData?.amount &&
                      `${previewData?.currency === 1 ? "$" : "€"}${
                        previewData?.amount ?? 0
                      }`}
                  </Typography>
                )}
                <Box className={classes.socialIconBox}>
                  <Box className={classes.socialIcon}>
                    <ShareIcon />
                  </Box>
                  <Box className={classes.socialIcon}>
                    <WhishlistIcon />
                  </Box>
                  <Box className={classes.socialIcon}>
                    <HeartLikeIcon />
                  </Box>
                </Box>
              </Box>
              {!previewData?.downloadType?.no && (
                <GradientColoredButton
                  style={{
                    width: "auto",
                    marginBottom: "16px",
                    padding: "10px 42px",
                    borderRadius: "6px",
                    color: "#fff",
                  }}
                  disabled
                >
                  <DownloadButtonIcon style={{ marginRight: "10px" }} />
                  Download Now
                </GradientColoredButton>
              )}

              {previewData?.downloadType?.paid && (
                <BlackBorderedButton
                  style={{
                    padding: "10px 42px",
                    width: "auto",
                    marginBottom: "24px",
                  }}
                  disabled
                >
                  <AddCardButtonIcon style={{ marginRight: "10px" }} />
                  Add To Cart
                </BlackBorderedButton>
              )}
              <Box className={classes.details}>
                <ViewIcon className={classes.detailsIcon} />
                <Typography className={classes.description} variant="subtitle1">
                  Project Views
                </Typography>
                <Typography
                  className={`${classes.description} ${classes.detailsText}`}
                  variant="subtitle1"
                >
                  {individualProductData?.data?.viewsCount ||
                    individualProductData?.productDetailedEntity?.viewsCount ||
                    0}
                </Typography>
              </Box>
              <Box className={classes.details}>
                <LikesIcon className={classes.detailsIcon} />
                <Typography className={classes.description} variant="subtitle1">
                  Appreciations
                </Typography>
                <Typography
                  className={`${classes.description} ${classes.detailsText}`}
                  variant="subtitle1"
                >
                  {individualProductData?.data?.likeCount ||
                    individualProductData?.productDetailedEntity?.likeCount ||
                    0}
                </Typography>
              </Box>
              <Box className={classes.details}>
                <DownloadIcon className={classes.detailsIcon} />
                <Typography className={classes.description} variant="subtitle1">
                  Downloads
                </Typography>
                <Typography
                  className={`${classes.description} ${classes.detailsText}`}
                  variant="subtitle1"
                >
                  {individualProductData?.data?.downloadCount ||
                    individualProductData?.productDetailedEntity
                      ?.downloadCount ||
                    0}
                </Typography>
              </Box>
              <Box className={classes.details}>
                <CommentIcon className={classes.detailsIcon} />
                <Typography className={classes.description} variant="subtitle1">
                  Comments
                </Typography>
                <Typography
                  className={`${classes.description} ${classes.detailsText}`}
                  variant="subtitle1"
                >
                  {individualProductData?.data?.commentCount ||
                    individualProductData?.productDetailedEntity
                      ?.commentCount ||
                    0}
                </Typography>
              </Box>
              <Box className={classes.boundaryLine} />
              <Box className={classes.userProfile}>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {/* <img
                    className={classes.profilePicture}
                    src={userData?.userAdditionalInfo?.profilePictureUrl}
                                  alt="user"
                  /> */}
                  <Avatar
                    className={classes.profilePicture}
                    src={userData?.userAdditionalInfo?.profilePictureUrl}
                  ></Avatar>

                  <Box>
                    <Typography className={classes.userName}>
                      {`${
                        (userData.userAdditionalInfo?.firstName !== null &&
                          userData.userAdditionalInfo?.firstName) ||
                        userData.userAdditionalInfo?.companyName
                      } ${
                        (userData.userAdditionalInfo?.lastName !== null &&
                          userData.userAdditionalInfo?.lastName) ||
                        ""
                      }`}
                    </Typography>
                    <Typography className={classes.userProfession}>
                      {userData.userAdditionalInfo?.profession}
                    </Typography>
                  </Box>
                </Box>

                <Box className={classes.userSocialIconBox}>
                  <Box className={classes.socialIcon}>
                    <LightChatIcon />
                  </Box>
                  {formData.isFollowed ? (
                    <Box
                      className={classes.socialIcon}
                      onClick={() => handleFollowUser}
                      style={{
                        border:
                          formData.isFollowed && "solid 1px #d7d0e8 !important",
                      }}
                    >
                      <LightFollowedIcon />
                    </Box>
                  ) : (
                    <Box
                      className={classes.socialIcon}
                      onClick={() => handleFollowUser}
                    >
                      <LightFollowIcon />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box className={classes.boundaryLine} />
              <Box className={classes.detailsUser}>
                <Typography
                  className={classes.rightBottomPanelTitle}
                  variant="subtitle1"
                >
                  Published
                </Typography>
                <Typography
                  className={`${classes.rightPanelDescription} ${classes.rightBottomDetailsText}`}
                  variant="subtitle1"
                >
                  <Moment format="DD MMM, YYYY">
                    {individualProductData?.data?.createdDate ||
                    individualProductData?.productDetailedEntity?.createdBy
                      ? new Date(
                          individualProductData?.data?.createdDate ||
                            individualProductData?.productDetailedEntity
                              ?.createdBy
                        )
                      : new Date()}
                  </Moment>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: "75px",
          marginTop: "34px",
          marginBottom: "34px",
        }}
      >
        {/* <PurpleButton onClick={handleClosePreview}>Close</PurpleButton> */}
      </Box>
    </Box>
  );
}
