const initialState = {
  loading: false,
  data: null,
  error: null,
};

//UPLOAD ACADEMICS
export const accountDetailsReducerTypes = {
  ACCOUNT_DETAILS: "ACCOUNT_DETAILS",
  REQUEST_ACCOUNT_DETAILS: "REQUEST_ACCOUNT_DETAILS",
  RESPONSE_ACCOUNT_DETAILS: "RESPONSE_ACCOUNT_DETAILS",
  ERROR_ACCOUNT_DETAILS: "ERROR_ACCOUNT_DETAILS",
  CLEAR_ACCOUNT_DETAILS: "CLEAR_ACCOUNT_DETAILS",
};

export function accountDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case accountDetailsReducerTypes.REQUEST_ACCOUNT_DETAILS:
      return { ...state, loading: true };
    case accountDetailsReducerTypes.RESPONSE_ACCOUNT_DETAILS:
      return { error: null, loading: false, data: action.payload };
    case accountDetailsReducerTypes.ERROR_ACCOUNT_DETAILS:
      return { loading: false, data: null, error: action.payload };
    case accountDetailsReducerTypes.CLEAR_ACCOUNT_DETAILS:
      return { loading: false, data: null, error: null };
    default:
      return state;
  }
}
