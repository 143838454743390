// @ts-nocheck
import { Box, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { productDetailsNavBarType } from "app/redux/reducers/navBar/productDetails";
import { useEffect, useMemo } from "react";

const useNavBarStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1rem 2%",
  },
  navbar_li: {
    display: "inline-block",
    paddingRight: "3rem",
  },
  navbar_li_a_dark: {
    cursor: "pointer",
    position: "relative !important",
    color: "#999",
    transition: "all 0.3s ease .1s",
    "&:hover": {
      color: "#fff !important",
    },
  },
  navbar_li_a_light: {
    cursor: "pointer",
    position: "relative !important",
    color: "#999 !important",
    transition: "all 0.3s ease .1s",
    "&:hover": {
      color: "black !important",
    },
  },
  nav_ul_li_a: {
    "&::after": {
      content: "",
      position: "absolute",
      bottom: "-8px",
      right: 0,
      left: "auto",
      width: 0,
      height: "3px",
      backgroundColor: "#f25757",
      transition: "all .25s cubic - bezier(.694, .048, .335, 1)",
    },
    "&:hover": {
      color: "#fff",
      "&::after": {
        content: "",
        position: "absolute",
        bottom: "-8px",
        right: 0,
        left: "auto",
        width: 0,
        height: "3px",
        backgroundColor: "#f25757",
        transition: "all .25s cubic - bezier(.694, .048, .335, 1)",
      },
      "::before": {
        width: "100%",
        left: 0,
      },
    },
  },
  nav_ul_li_a_selected: {
    content: "",
    position: "absolute",
    bottom: "-8px",
    right: 0,
    left: 0,
    width: "100%",
    height: "3px",
    backgroundColor: "#f25757",
    transition: "all .25s cubic - bezier(.694, .048, .335, 1)",
  },
}));

export default function NavBarPreview() {
  const classes = useNavBarStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const productDetailsNavBar = useSelector(
    (state) => state.productDetailsNavBar
  );
  const detailsOfProduct = useSelector((state) => state.productWithUploadType);
  const productDetails = detailsOfProduct?.data;
  const previewCategoryData = useSelector((state) => state.previewCategoryData);

  useEffect(() => {
    dispatch({
      type: productDetailsNavBarType.PRODUCT_INFO,
    });
  }, []);

  const productInfoHandler = () => {
    dispatch({
      type: productDetailsNavBarType.PRODUCT_INFO,
    });
  };

  const _3dHandler = () => {
    dispatch({
      type: productDetailsNavBarType._3D,
    });
  };

  const patternHandler = () => {
    dispatch({
      type: productDetailsNavBarType.PATTERN,
    });
  };

  const artFileHandler = () => {
    dispatch({
      type: productDetailsNavBarType.ART_FILE,
    });
  };

  const imageHandler = () => {
    dispatch({
      type: productDetailsNavBarType.IMAGE_FILE,
    });
  };

  const sketchHandler = () => {
    dispatch({
      type: productDetailsNavBarType.SKETCH_FILE,
    });
  };

  const otherHandler = () => {
    dispatch({
      type: productDetailsNavBarType.OTHER_FILE,
    });
  };

  const clickHandler = (id) => {
    if (id === "_3d") {
      _3dHandler();
      return;
    } else if (id === "pattern") {
      patternHandler();
      return;
    } else if (id === "art") {
      artFileHandler();
      return;
    } else if (id === "image") {
      imageHandler();
      return;
    } else if (id === "sketch") {
      sketchHandler();
      return;
    } else if (id === "others") {
      otherHandler();
      return;
    }
  };

  const navBarArrayHandler = useMemo(() => {
    const arr = [];

    if (
      (previewCategoryData.data?.fileUplaoded?._3dFileUpload?.length ?? 0) !== 0
    )
      arr.push({ category: "3D", id: "_3d" });
    if (
      (previewCategoryData.data?.fileUplaoded?.patternUpload?.length ?? 0) !== 0
    )
      arr.push({ category: "Pattern", id: "pattern" });
    if (
      (previewCategoryData.data?.fileUplaoded?.artWorkUpolad?.length ?? 0) !== 0
    )
      arr.push({ category: "Art files", id: "art" });
    if (
      (previewCategoryData.data?.fileUplaoded?.imageUpload?.length ?? 0) !== 0
    )
      arr.push({ category: "Image", id: "image" });
    if (
      (previewCategoryData.data?.fileUplaoded?.sketchUpload?.length ?? 0) !== 0
    )
      arr.push({ category: "Sketch", id: "sketch" });
    if (
      (previewCategoryData.data?.fileUplaoded?.otherFileUpload?.length ?? 0) !==
      0
    )
      arr.push({ category: "Other", id: "others" });
    return arr;
  }, [previewCategoryData]);

  return (
    <Box className="col-md-12 mx-auto">
      <Box className={classes.header}>
        <nav>
          <ul>
            <li
              onClick={productInfoHandler}
              className={classes.navbar_li}
              style={{ marginLeft: "-52px" }}
            >
              <span
                style={{
                  color: theme?.palette?.mode === "light" ? "#2b2639" : "#fff",
                }}
                className={
                  theme?.palette?.mode === "light"
                    ? productDetailsNavBar.productInfo
                      ? `activeNavBar`
                      : classes.navbar_li_a_light
                    : productDetailsNavBar.productInfo
                    ? `activeNavBar`
                    : classes.navbar_li_a_dark
                }
              >
                Product Information
              </span>
            </li>
            <>
              {
                // TODO: Make Category Dynamic
                navBarArrayHandler.map((value, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => clickHandler(value?.id)}
                      className={classes.navbar_li}
                    >
                      <span
                        style={{
                          color:
                            theme?.palette?.mode === "light"
                              ? "#2b2639"
                              : "#fff",
                        }}
                        className={
                          theme?.palette?.mode === "light"
                            ? productDetailsNavBar?.[value?.id]
                              ? `activeNavBar`
                              : classes.navbar_li_a_light
                            : productDetailsNavBar?.[value?.id]
                            ? `activeNavBar`
                            : classes.navbar_li_a_dark
                        }
                      >
                        {value.category}
                      </span>
                    </li>
                  );
                })
              }
            </>
          </ul>
        </nav>
      </Box>
      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "#999",
          marginTop: "-26px",
        }}
      ></div>
    </Box>
  );
}
