/*
 **	User name
 **	Created	5/25/2023
 **	cardAndListStyle.ts
 */

import { makeStyles } from "@mui/styles";

const useJobCardStyles = makeStyles((theme) => ({
  gridBox: {
    display: "flex",
    justifyContent: "flex-start",
    float: "left",
    flexGrow: 0,
    alignItems: "center",
  },
  cardMainBox: {
    flexGrow: 0,
    padding: "24px",
    // margin: "34px 15px 15px 42px",
    // padding: "24px 23px 24px 24px",
    objectFit: "contain",
    border: "solid 1px #d7d0e8",
    backgroundColor: "var(--white)",
    borderRadius: "20px",
    justifyContent: "center",
    // float: "left",
    alignItems: "center",
    cursor: "pointer",
    maxWidth: 410,
    height: "100%",
    "&:hover": {
      boxShadow: "0 39px 42px 0 rgba(122, 72, 255, 0.45) !important",
    },
  },
  buttonBox: {
    padding: "21px 0px 5px 0px",
    display: "flex",
  },
  headerBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left",
    position: "relative",
  },
  profilePicture: {
    width: "54px !important",
    height: "54px !important",
    borderRadius: "50%",
  },
  userName: {
    textTransform: "capitalize",
    fontSize: "15px",
    fontWeight: "bold !important",
    textAlign: "left",
    color: "#2b2639",
    paddingLeft: "16px",
  },
  userProfession: {
    fontSize: "13px !important",
    textAlign: "left",
    color: "#a49bbe",
    paddingLeft: "16px",
  },
  iconBox: {
    position: "absolute",
    top: 0,
    right: 0,
  },
  profession: {
    margin: "16px 3px 6px 0 !important",
    fontFamily: "Poppins",
    fontSize: "15px !important",
    fontWeight: "bold",
    textAlign: "left",
    color: "#7a48ff",
  },
  details: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 500,
    textAlign: "left",
    color: "#2b2639",
  },
  detailsBox: {
    display: "flex",
    flexDirection: "row",
  },
  detailsLeftBox: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: "35%",
  },
  detailsRightBox: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    width: "65%",
  },
  description: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "bold",
    color: "#a49bbe",
    paddingTop: "16px",
  },
  descriptionInfo: {
    height: "69px",
    overflowY: "hidden",
    fontSize: "15px",
    textAlign: "left",
    color: " var(--ui-dark-colors-secondary-dark-mode)",
    paddingTop: "6px",
  },
  descriptionSeeMore: {
    fontSize: "15px",
    textAlign: "left",
    color: "#7a48ff",
    paddingTop: "6px",
  },
  postTime: {
    flexGrow: 0,
    padding: "16px 0px 0px 0",
    fontFamily: "Poppins",
    fontSize: "13px",
    textAlign: "left",
    color: "#a49bbe",
  },
  iconBoxes: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
  },
  dropDownBox: {
    position: "absolute",
    width: "232px",
    top: 30,
    right: 0,
    zIndex: 10,
    borderRadius: "5px",
    background: "#fff",
    boxShadow: "0 16px 24px 0 rgba(43, 38, 57, 0.1)",
  },
  dropDownMenu: {
    borderBottom: 1.9,
    borderColor: "#d7d0e8",
    background: "#fff",
    width: "100%",
    height: "49px",
    color: "#2b2639 !important",
    // marginLeft: "25% !important",
    display: "flex !important", // bug fix 4408
    justifyContent: "center !important",
    zIndex: 10,
    "&:hover": {
      backgroundColor: "#ebe3ff !important",
      color: "#7a48ff !important",
      textDecoration: "none",
    },
  },
  recieved: {
    fontSize: "15px !important",
    textAlign: "left",
    marginTop: "16px !important",
    fontWeight: 500,
    color: "#7a48ff",
  },
  [theme.breakpoints.down("md")]: {
    detailsBox: {
      flexDirection: "column",
    },
  },
}));

const useJobListCardStyles = makeStyles((theme) => ({
  cardBox: {
    height: "230px",
    marginBottom: "16px",
    padding: "24px",
    width: "100%",
    border: "solid 1px #d7d0e8",
    display: "flex",
    // alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    cursor: "pointer",
    borderRadius: "10px",
    "&:hover": {
      boxShadow: "0 30px 40px 0 rgba(122, 72, 255, 0.25)",
    },
    position: "relative",
  },
  details: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: 500,
    textAlign: "left",
    color: "#2b2639",
    marginRight: "16px",
  },
  iconBoxes: {
    display: "flex",
    flexDirection: "row",
    // marginTop: "10px",
    margin: "0px 8px 0px 8px",
  },
  profilePicture: {
    width: "54px !important",
    height: "54px !important",
    borderRadius: "50%",
    margin: "0px 16px 16px 0px",
  },
  userName: {
    textTransform: "capitalize",
    fontSize: "21px !important",
    fontWeight: "bold !important",
    textAlign: "center",
    height: "32px",
    color: "#2b2639",
    marginRight: "8px !important",
  },
  userProfession: {
    fontSize: "15px !important",
    textAlign: "center",
    width: "113px",
    height: "23px",
    color: "#7a48ff",
    marginLeft: "3px !important",
  },
  leftBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "left",
  },
  centerBox: {},
  rightBox: {
    display: "flex",
    flexDirection: "column",
  },
  detailsRightBox: {
    display: "flex",
    flexDirection: "row",
  },
  descriptionInfo: {
    width: "856px",
    fontSize: "15px",
    textAlign: "left",
    color: " var(--ui-dark-colors-secondary-dark-mode)",
    paddingTop: "6px",
  },
  descriptionSeeMore: {
    fontSize: "15px",
    textAlign: "left",
    color: "#7a48ff",
    paddingTop: "6px",
  },
  description: {
    fontFamily: "Poppins",
    fontSize: "13px",
    fontWeight: "bold !important",
    color: "#a49bbe",
    paddingTop: "18px",
  },
  companyProfession: {
    fontSize: "13px !important",
    textAlign: "left",
    color: "#a49bbe",
  },
  dropDownMenu: {
    borderBottom: 1.9,
    borderColor: "#d7d0e8",
    background: "#fff",
    width: "100%",
    height: "49px",
    color: "#2b2639 !important",
    marginLeft: "-10px",
    zIndex: 10,
    "&:hover": {
      backgroundColor: "#ebe3ff !important",
      color: "#7a48ff",
    },
  },
  recieved: {
    fontSize: "15px !important",
    fontWeight: 500,
    color: "#7a48ff",
    position: "absolute",
    bottom: "24px",
    right: "24px",
  },
  // iconStyles: { margin: "0px 6px 0px 16px !important" },
  // [theme.breakpoints.down("md")]: {
  //   iconBoxes: { margin: "0px 8px 0px 0px !important" },
  //   iconStyles: { margin: "0px 6px 0px 0px !important" },
  // },
}));

const useGridCardStyles = makeStyles((theme) => ({
  gridBox: {
    display: "flex",
    justifyContent: "flex-start",
    float: "left",
    flexGrow: 0,
    alignItems: "center",
  },
  cardMainBox: {
    flexGrow: 0,
    margin: "10px .5%",
    padding: "6px 6px 24px",
    objectFit: "contain",
    border: "solid 1px #d7d0e8",
    backgroundColor: "var(--white)",
    borderRadius: "20px",
    justifyContent: "center",
    // float: "left",
    alignItems: "center",
    cursor: "pointer",
    width: "100%",
    height: "100%",
    "&:hover": {
      boxShadow: "0 39px 42px 0 rgba(122, 72, 255, 0.45) !important",
    },
  },
  cardInnerBox: {
    position: "relative",
    width: "100%",
  },
  coverImg: {
    borderRadius: "17px 17px 0px 0px",
    width: "100%",
    maxHeight: "144px",
    minHeight: "144px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "#f5f5f5",
  },
  profileImg: {
    display: "flex",
    width: "100px",
    height: "100px",
    position: "absolute",
    background: "white",
    padding: "6px",
    borderRadius: "50%",
    top: "100px",
    left: "50%",
    transform: "translate(-50%, 0px)",
    marginLeft: "auto",
    marginRight: "auto",
    objectFit: "cover",
  },
  dupProfileImg: {
    display: "flex",
    border: "6px solid #fff",
    margin: "0",
    padding: 6,
    top: "100px",
    left: "50%",
    textTransform: "capitalize !important" as any,
    transform: "translate(-50%, 0px)",
    fontSize: "40px !important",
    position: "absolute !important" as any,
    width: "100px !important",
    height: "100px !important",
    borderRadius: "50%",
  },
  textBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 67,
  },
  title: {
    textTransform: "capitalize",
    fontSize: "21px !important",
    // display: "flex",
    fontWeight: "bold !important",
    color: "#2b2639",
  },
  profession: {
    fontSize: "13px !important",
    color: "#a49bbe",
  },
  description: {
    fontSize: "13px !important",
    color: "#7a48ff",
    padding: "0px 10px 0px 10px",
  },
  socialBox: {
    display: "flex",
    flexDirection: "row",
    // marginLeft: "10px",
    // alignItems: "center !important",
    marginTop: "22px !important",
    // flexWrap: "wrap",
    justifyContent: "center",
    width: "100%",
    padding: "0 15px",
  },
  socialSubBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center !important",
    // margin: "0px 3px",
    // paddingRight: "1px",
    flex: 1,
  },
  socialText: {
    color: "#a49bbe",
    fontSize: "13px !important",
    fontWeight: "500 !important",
    // letterSpacing: "0.1px",
    textAlign: "center",
  },
  socialCount: {
    color: "#2b2639",
    fontSize: "13px !important",
    textAlign: "left",
  },
  buttonBox: {
    padding: "20px 15px 5px 15px",
    display: "flex",
  },
  [theme.breakpoints.down("md")]: {
    socialSubBox: {
      display: "flex",
      flexDirection: "column !important",
      alignItems: "center !important",
    },
  },
}));

const useListCardStyles = makeStyles((theme) => ({
  cardBox: {
    marginBottom: "16px",
    width: "100%",
    border: "solid 1px #d7d0e8",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    cursor: "pointer",
    justifyContent: "space-between",
    borderRadius: "10px",
    "&:hover": {
      boxShadow: "0 30px 40px 0 rgba(122, 72, 255, 0.25)",
      //   transform: "scale(.98)"
    },
  },
  profilePicture: {
    width: "64px !important",
    height: "64px !important",
    borderRadius: "50%",
    margin: "24px 16px 24px 24px",
  },
  userName: {
    textTransform: "capitalize",
    fontSize: "15px",
    fontWeight: "bold !important",
    textAlign: "left",
    color: "#2b2639",
  },
  userProfession: {
    fontSize: "13px !important",
    textAlign: "left",
    color: "#a49bbe",
  },
  leftBox: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
    width: "300px",
  },
  centerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    // width: "75%",
  },
  rightBox: {
    // padding: 21,
    display: "flex",
    position: "sticky",
    // margin: "0px 24px 0px 0px",
    marginRight: "24px",
  },
  detailsBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  projectNumbersBox: {
    borderRight: "1px solid #d7d0e8 !important",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  buttonDimension: { width: "118px" },
  [theme.breakpoints.down("md")]: {
    cardBox: {
      height: "260px !important",
      padding: "10px !important",
      display: "flex",
      flexDirection: "column",
    },
    buttonDimension: { width: "157px" },
    leftBox: {},
    profilePicture: {
      margin: "20px 0px !important",
    },
    centerBox: { width: "95%" },
    detailsBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",

      width: "100%",
    },
    projectNumbersBox: {
      // display: "flex",
      // flexDirection: "row",
      // alignItems: "flex-start",
      borderRight: "initial !important",
      // justifyContent: "center",
    },
  },
}));

const useProductCardViewStyles = makeStyles((theme) => ({
  mainBox: {
    position: "relative",
  },
  gridBox: {
    maxWidth: "100% !important",
    WebkitBackdropFilter: "blur(60px)",
    boxShadow: "0 8px 16px 0 rgba(122, 72, 255, 0.1)",
    border: "1px solid #ebe3ff",
    // marginRight: "15px !important",
    // marginBottom: "34px !important",
    // margin: "7.5px !important",
    borderRadius: "20px !important",
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 39px 42px 0 rgba(122, 72, 255, 0.45) !important",
    },
    "&:hover $hoverContent": {
      display: "flex",
    },
  },
  viewPortBox: {
    height: "201px !important",
  },
  title: {
    textTransform: "capitalize",
    fontFamily: "Poppins",
    fontSize: "21px !important",
    fontWeight: "bold !important",
    textAlign: "left",
    color: "#2b2639",
  },
  price: {
    fontFamily: "Poppins",
    fontSize: "21px !important",
    fontWeight: "bold !important",
    color: "#2b2639",
    backgroundImage: "linear-gradient(44deg, #f54d64 0%, #f58561 96%)",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitBackgroundClip: "text",
  },
  userInfoBox: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "16px",
    paddingRight: "5px",
    paddingTop: "16px",
  },
  iconBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 22,
  },
  icon: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  imageContainer: {
    position: "relative",
  },
  hoverContent: {
    position: "absolute",
    display: "none",
    backgroundImage:
      "linear-gradient(0deg, var(--ui-dark-colors-secondary-dark-mode) 0%, rgba(0, 0, 0, 0) 38%)",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    flexDirection: "column",
    padding: 16,
    zIndex: 1,
    minHeight: 201,
  },
  iconContainer: {
    background: "rgba(164, 155, 190, 0.4)",
    width: 18,
    height: 18,
    marginRight: 8,
    borderRadius: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    gridBox: {
      width: "90vw !important",
    },
  },
}));

export {
  useJobCardStyles,
  useJobListCardStyles,
  useGridCardStyles,
  useListCardStyles,
  useProductCardViewStyles,
};
