/*
 **	User name
 **	Created	6/1/2023
 **	FreeTrialProvider.tsx
 */

import React, { useMemo } from "react";
import { useAppSelector } from "app/utils/hooks";
import OneDayLeftModal from "app/components/Modals/OneDayLeftModal";
import TwoDayLeftModal from "app/components/Modals/TwoDayLeftModal";
import TrialExpiredModal from "app/components/Modals/TrialExpiredModal";
import WelcomeModal from "app/components/Modals/WelcomeModal";
import { isCompanySelector } from "app/redux/selectors/user-selector";
import RouteLinks from "app/navigation/RouteLinks";
import { useLocation } from "react-router";

import { FreeTrialContext } from "./FreeTrialContext";

const FREE_TRIAL_DAYS = 90;

export const FreeTrialProvider = ({ children }) => {
  const userData = useAppSelector((state) => state.register);
  const isCompany = useAppSelector(isCompanySelector);
  const location = useLocation();

  const {
    currentPlanId,
    daysLeftForPlanExpiry,
    isExpiredPlanPopupCancelled,
    isOneDayPlanLeftPopupCancelled,
    isTwoDayPlanLeftPopupCancelled,
    isWelcomePopup,
  } = userData?.userAdditionalInfo || {};

  const canShowExpiredOnPage = useMemo(() => {
    if (location.pathname === RouteLinks.LICENSE) {
      return false;
    }

    if (location.pathname === RouteLinks.BILLING_PAGE) {
      return false;
    }
    return true;
  }, [location]);

  const showTwoDayPlanLeftPopupCancelled =
    currentPlanId === 0 &&
    FREE_TRIAL_DAYS - daysLeftForPlanExpiry === 2 &&
    !isTwoDayPlanLeftPopupCancelled;

  const showOneDayPlanLeftPopupCancelled =
    currentPlanId === 0 &&
    FREE_TRIAL_DAYS - daysLeftForPlanExpiry === 1 &&
    !isOneDayPlanLeftPopupCancelled;

  const showExpiredPlanPopupCancelled =
    currentPlanId === 0 &&
    daysLeftForPlanExpiry >= FREE_TRIAL_DAYS &&
    (isCompany ? true : !isExpiredPlanPopupCancelled) &&
    canShowExpiredOnPage;

  const showWelcomeModal =
    location.pathname === RouteLinks.HOME &&
    currentPlanId === 0 &&
    !isWelcomePopup &&
    FREE_TRIAL_DAYS - daysLeftForPlanExpiry === FREE_TRIAL_DAYS;

  const isPlanOverRide = userData?.userAdditionalInfo?.isPlanOverride;

  return (
    <FreeTrialContext.Provider value={{ showWelcomeModal }}>
      {showOneDayPlanLeftPopupCancelled && !isPlanOverRide && (
        <OneDayLeftModal />
      )}
      {showTwoDayPlanLeftPopupCancelled && !isPlanOverRide && (
        <TwoDayLeftModal />
      )}
      {showExpiredPlanPopupCancelled && !isPlanOverRide && (
        <TrialExpiredModal />
      )}
      {showWelcomeModal && <WelcomeModal />}
      {children}
    </FreeTrialContext.Provider>
  );
};
