const initialState = {
  loading: false,
  error: null,
  metadataInfo: null,
  status: null,

  allSkills: [],
  allSkillsStatus: null,

  saveSkillStatus: null,
  saveSkillError: null,

  selectedSkills: [],
  selectedSkillsStatus: null,
  selectedSkillsLoading: false,
  selectedSkillsError: null,
};

export const skillsActionTypes = {
  FETCH_ALL_SKILLS: "FETCH_ALL_SKILLS",
  FETCH_ALL_SKILLS_REQUEST: "FETCH_ALL_SKILLS_REQUEST",
  FETCH_ALL_SKILLS_SUCCESS: "FETCH_ALL_SKILLS_SUCCESS",
  FETCH_ALL_SKILLS_FAILURE: "FETCH_ALL_SKILLS_FAILURE",
  SAVE_SKILLS: "SAVE_SKILLS",
  SAVE_SKILLS_REQUEST: "SAVE_SKILLS_REQUEST",
  SAVE_SKILLS_SUCCESS: "SAVE_SKILLS_SUCCESS",
  SAVE_SKILLS_FAILURE: "SAVE_SKILLS_FAILURE",
  SAVE_SKILLS_CLEAR: "SAVE_SKILLS_CLEAR",
  GET_SKILL_DETAILS: "GET_SKILL_DETAILS",
  GET_SKILL_DETAILS_REQUEST: "GET_SKILL_DETAILS_REQUEST",
  GET_SKILL_DETAILS_SUCCESS: "GET_SKILL_DETAILS_SUCCESS",
  GET_SKILL_DETAILS_FAILURE: "GET_SKILL_DETAILS_FAILURE",
};

export function skillsReducer(state = initialState, action) {
  switch (action.type) {
    case skillsActionTypes.FETCH_ALL_SKILLS_REQUEST:
      return {
        ...state,
        error: null,
        allSkillsStatus: skillsActionTypes.FETCH_ALL_SKILLS_REQUEST,
      };
    case skillsActionTypes.FETCH_ALL_SKILLS_FAILURE:
      return {
        ...state,
        error: action.payload,
        allSkillsStatus: skillsActionTypes.FETCH_ALL_SKILLS_FAILURE,
      };
    case skillsActionTypes.FETCH_ALL_SKILLS_SUCCESS:
      return {
        ...state,
        allSkills: action.payload,
        allSkillsStatus: skillsActionTypes.FETCH_ALL_SKILLS_SUCCESS,
      };

    case skillsActionTypes.SAVE_SKILLS_REQUEST:
      return {
        ...state,
        saveSkillError: null,
        saveSkillStatus: skillsActionTypes.SAVE_SKILLS_REQUEST,
      };
    case skillsActionTypes.SAVE_SKILLS_FAILURE:
      return {
        ...state,
        saveSkillError: action.payload,
        saveSkillStatus: skillsActionTypes.SAVE_SKILLS_FAILURE,
      };

    case skillsActionTypes.SAVE_SKILLS_SUCCESS:
      return {
        ...state,
        saveSkillError: null,
        saveSkillStatus: skillsActionTypes.SAVE_SKILLS_SUCCESS,
      };

    case skillsActionTypes.SAVE_SKILLS_CLEAR:
      return {
        ...state,
        saveSkillError: null,
        saveSkillStatus: null,
      };

    case skillsActionTypes.GET_SKILL_DETAILS_REQUEST:
      return {
        ...state,
        selectedSkillsError: null,
        selectedSkillsStatus: skillsActionTypes.GET_SKILL_DETAILS_REQUEST,
        selectedSkillsLoading: true,
      };
    case skillsActionTypes.GET_SKILL_DETAILS_FAILURE:
      return {
        ...state,
        selectedSkillsError: action.payload,
        selectedSkillsStatus: skillsActionTypes.GET_SKILL_DETAILS_FAILURE,
        selectedSkillsLoading: false,
      };

    case skillsActionTypes.GET_SKILL_DETAILS_SUCCESS:
      return {
        ...state,
        selectedSkills: action.payload,
        selectedSkillsStatus: skillsActionTypes.GET_SKILL_DETAILS_SUCCESS,
        selectedSkillsLoading: false,
      };

    default:
      return state;
  }
}
