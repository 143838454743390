const initState = {
  loading: false,
  data: null,
  error: null,
};

export const tagsReducerTypes = {
  TAGS_LIST: "TAGS_LIST",
  REQUEST_TAGS_LIST: "REQUEST_TAGS_LIST",
  RESPONSE_TAGS_LIST: "RESPONSE_TAGS_LIST",
  ERROR_TAGS_LIST: "ERROR_TAGS_LIST",
};

export function tagsReducer(state = initState, action) {
  switch (action.type) {
    case tagsReducerTypes.REQUEST_TAGS_LIST:
      return { ...state, loading: true };
    case tagsReducerTypes.RESPONSE_TAGS_LIST:
      return { error: null, loading: false, data: action.payload };
    case tagsReducerTypes.ERROR_TAGS_LIST:
      return { loading: false, data: null, error: action.payload };
    default:
      return state;
  }
}
