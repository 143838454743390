/*
 **	User name
 **	Created	6/1/2023
 **	UploadProvider.tsx
 */

import React from "react";
import { UploadContext } from "./UploadContext";
import { LibraryProductUploadProvider } from "./LibraryProductUploadProvider";

export const UploadProvider = ({ children }) => {
  return (
    <UploadContext.Provider value={{}}>
      <LibraryProductUploadProvider>{children}</LibraryProductUploadProvider>
    </UploadContext.Provider>
  );
};
