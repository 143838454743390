import { put, all, call, takeLatest } from "redux-saga/effects";
import { request } from "../../services/Axios";
import EndPoints from "../../services/EndPoints";
import { categoriesActionTypes } from "../reducers/categories";

const version = process.env.REACT_APP_API_VERSION;

function* fetchAllCategoriesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_CATEGORIES.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchAllLIbraryCategoriesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_LIBRARY_CATEGORIES.replace("{version}", version),
    payload
  );

  return res;
}

function* fetchAllLIbraryCategoriesHierarchyRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY.replace(
      "{version}",
      version
    ),
    payload
  );

  return res;
}

function* fetchMainCategories({ accountGuid }) {
  try {
    yield put({
      type: categoriesActionTypes.GET_ALL_CATEGORIES_REQUEST,
    });
    const response = yield call(fetchAllCategoriesRequest, {
      Guidid: accountGuid,
    });

    yield put({
      type: categoriesActionTypes.GET_ALL_CATEGORIES_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: categoriesActionTypes.GET_ALL_CATEGORIES_FAILURE,
      payload: e,
    });
  }
}

function* fetchAllLIbraryCategories({ accountGuid }) {
  try {
    yield put({
      type: categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_REQUEST,
    });
    const response = yield call(fetchAllLIbraryCategoriesRequest, {
      Guidid: accountGuid,
    });

    yield put({
      type: categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_FAILURE,
      payload: e,
    });
  }
}

function* fetchAllLIbraryCategoriesHierarchy({ accountGuid }) {
  try {
    yield put({
      type: categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_REQUEST,
    });
    const response = yield call(fetchAllLIbraryCategoriesHierarchyRequest, {
      Guidid: accountGuid,
    });

    yield put({
      type: categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: categoriesActionTypes.GET_ALL_LIBRARY_CATEGORIES_HIERARCHY_FAILURE,
      payload: e,
    });
  }
}

function* fetchAllProductCategoriesHierarchyRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY.replace(
      "{version}",
      version
    ),
    payload
  );

  return res;
}

function* fetchAllProductCategoriesHierarchy({ accountGuid }) {
  try {
    yield put({
      type: categoriesActionTypes.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_REQUEST,
    });
    const response = yield call(fetchAllProductCategoriesHierarchyRequest, {
      Guidid: accountGuid,
    });

    yield put({
      type: categoriesActionTypes.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: categoriesActionTypes.GET_ALL_PRODUCT_CATEGORIES_HIERARCHY_FAILURE,
      payload: e,
    });
  }
}

function* fetchAllCategories({ payload }) {
  const { accountGuid, accessToken } = payload || {};

  if (accessToken || !accessToken) {
    return yield all([
      call(fetchMainCategories, { accountGuid }),
      call(fetchAllLIbraryCategories, { accountGuid }),
      call(fetchAllLIbraryCategoriesHierarchy, { accountGuid }),
      call(fetchAllProductCategoriesHierarchy, { accountGuid }),
    ]);
  }

  return yield all([
    call(fetchMainCategories, { accountGuid }),
    call(fetchAllLIbraryCategories, { accountGuid }),
  ]);
}

function* fetchCategoryAttributesRequest(payload) {
  const res = yield request(
    "get",
    EndPoints.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY.replace(
      "{version}",
      version
    ),
    payload
  );

  return res;
}

function* fetchCategoryAttributes({ payload }) {
  try {
    yield put({
      type: categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_REQUEST,
    });
    const response = yield call(fetchCategoryAttributesRequest, payload);

    yield put({
      type: categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_SUCCESS,
      payload: response?.data,
    });
  } catch (e) {
    yield put({
      type: categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY_FAILURE,
      payload: e,
    });
  }
}

function* mainSaga() {
  yield all([
    takeLatest(categoriesActionTypes.GET_ALL_CATEGORIES, fetchAllCategories),
    takeLatest(
      categoriesActionTypes.GET_ALL_CATEGORIES_ATTRIBUTES_HIERARCHY,
      fetchCategoryAttributes
    ),
  ]);
}
export default mainSaga;
