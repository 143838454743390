import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import { SearchActions } from "app/redux/reducers/search";
import { useAppSelector } from "app/utils/hooks";
import { accountGuidSelector } from "app/redux/selectors/user-selector";
import { cryptr } from "app/utils/helper";
import ModelViewer from "app/components/ModelViewer";

import { LightModeCross } from "../../components/Logo/logos";

const useSearchBoxStyles = makeStyles((theme) => ({
  searchBox: {
    margin: "20px auto 0 !important",
    width: 466,
    boxShadow: "0 18px 34px 0 rgba(43, 38, 57, 0.4)",
    position: "relative",
    background: "#fff",
  },
  resultContainer: {
    width: 466,
    height: 503,
    background: "#FFF",
    zIndex: 9,
    position: "absolute",
    marginTop: 6,
    borderRadius: 6,
    boxShadow: "0 18px 34px 0 rgba(43, 38, 57, 0.2)",
    overflow: "auto",
  },
  titleContainer: {
    padding: 16,
    background: "#7847ff0a",
  },
  title: {
    fontWeight: "bold !important",
    fontSize: 15,
    color: "#2b2639 !important",
  },
  itemsContainer: {
    padding: 16,
    paddingBottom: 0,
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 16,
  },
  item: {
    fontSize: 15,
    color: "#2b2639 !important",
  },
  notFound: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    searchBox: {
      // margin: "20px auto 0 !important",
      width: "100%",
      boxShadow: "0 18px 34px 0 rgba(43, 38, 57, 0.4)",
      position: "relative",
      background: "#fff",
    },
    resultContainer: {
      width: "100% !important",
    },
  },
}));

const SearchSection = ({ title, items, onClick }) => {
  const classes = useSearchBoxStyles();

  const {
    ReactComponent: LogoSvg,
  } = require("../../../assets/logo/Icons_Light_New_Logo.svg");

  if (!items?.length) {
    return null;
  }

  return (
    <Box>
      <Box className={classes.titleContainer}>
        <Typography className={classes.title}>{title}</Typography>
      </Box>
      <Box className={classes.itemsContainer}>
        {items.map((item) => {
          const isThreeD = item?.src?.indexOf(".glb") > -1;

          return (
            <Link
              className={classes.itemContainer}
              to={item.link}
              key={item.id}
              onClick={onClick}
            >
              <Box
                style={{
                  width: 24,
                  height: 24,
                  overflow: "hidden",
                  marginRight: 16,
                }}
              >
                {item.src ? (
                  isThreeD ? (
                    <ModelViewer
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={item.src}
                      alt={item.name}
                    />
                  ) : (
                    <img
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                      src={item.src}
                      alt={item.name}
                    />
                  )
                ) : (
                  <LogoSvg style={{ maxWidth: "100%", maxHeight: "100%" }} />
                )}
              </Box>
              <Box>
                <Typography className={classes.item}>{item.name}</Typography>
              </Box>
            </Link>
          );
        })}
      </Box>
    </Box>
  );
};

export default function SearchBox(props) {
  const { ReactComponent: Loader } = require("./loader.svg");
  const { setSearchState, searchState } = props;
  const classes = useSearchBoxStyles();
  const dispatch = useDispatch();
  const accountGuid = useAppSelector(accountGuidSelector);
  const searchData = useAppSelector((state) => state.search);
  const [searchItem, setSearchItem] = useState("");
  const theme = createTheme({
    typography: {
      allVariants: {
        fontFamily: "Poppins",
      },
    },
    overrides: {
      MuiIconButton: {
        root: {
          "&:hover": {
            backgroundColor: "$labelcolor",
          },
        },
      },
    },
  } as any);

  const handleClick = () => {
    setSearchState(false);
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;

    setSearchItem(value);
    dispatch(
      SearchActions.searchGlobal({ guid: accountGuid, category: value })
    );
  };

  const hasAnyKindOfData = searchData?.data
    ? Object.keys(searchData?.data).some(
        (key) => searchData?.data?.[key]?.length
      )
    : false;

  return (
    <ThemeProvider theme={theme}>
      <Box className="mainSearch">
        <br />
        <br />
        <TextField
          className={classes.searchBox}
          id="search"
          type="search"
          label=""
          placeholder="Search Designers Capitol..."
          value={searchItem}
          onChange={handleSearchChange}
          InputProps={{
            endAdornment: (
              <InputAdornment
                style={{ cursor: "pointer" }}
                onClick={handleClick}
                position="end"
              >
                <LightModeCross />
              </InputAdornment>
            ),
          }}
          autoFocus={true}
        />
        {searchItem && (
          <Box className={classes.resultContainer}>
            {searchData.loading && searchItem ? (
              <Loader />
            ) : hasAnyKindOfData ? (
              <Box>
                <SearchSection
                  title="Products"
                  items={searchData?.data?.productSearchResults?.map(
                    (item) => ({
                      name: item.productName,
                      src: item.productThumbnailUrl,
                      id: item.productId,
                      link: `/products/${cryptr.encrypt(item.productId)}`,
                    })
                  )}
                  onClick={handleClick}
                />

                <SearchSection
                  title="Library Products"
                  items={searchData?.data?.librarySearchResults?.map(
                    (item) => ({
                      name: item.productLibraryName,
                      src: item.productLibraryThumbnailUrl,
                      id: item.productLibraryId,
                      link: `/library-product/${cryptr.encrypt(
                        item.productLibraryId
                      )}`,
                    })
                  )}
                  onClick={handleClick}
                />

                <SearchSection
                  title="Draft (Products)"
                  items={searchData?.data?.productDraftSearchResults?.map(
                    (item) => ({
                      name: item.productName,
                      src: item.productThumbnailUrl,
                      id: item.productId,
                      link: `/products/${cryptr.encrypt(item.productId)}`,
                    })
                  )}
                  onClick={handleClick}
                />

                <SearchSection
                  title="Draft (Library Products)"
                  items={searchData?.data?.libraryDraftSearchResults?.map(
                    (item) => ({
                      name: item.productName,
                      src: item.productLibraryThumbnailUrl,
                      id: item.productLibraryId,
                      link: `/library-product/${cryptr.encrypt(
                        item.productLibraryId
                      )}`,
                    })
                  )}
                  onClick={handleClick}
                />

                <SearchSection
                  title="Designers"
                  items={searchData?.data?.designerSearchResults?.map(
                    (item) => ({
                      name: item.accountName,
                      src: item.accountProfileUrl,
                      id: item.accountId,
                      link: `/designer/${item.userName}`,
                    })
                  )}
                  onClick={handleClick}
                />

                <SearchSection
                  title="Companies"
                  items={searchData?.data?.companySearchResults?.map(
                    (item) => ({
                      name: item.accountName,
                      src: item.accountProfileUrl,
                      id: item.accountId,
                      link: `/companies/${item.userName}`,
                    })
                  )}
                  onClick={handleClick}
                />

                <SearchSection
                  title="Jobs"
                  items={searchData?.data?.jobsSearchResults?.map((item) => ({
                    name: item.companyName,
                    src: null,
                    id: item.jobId,
                    link: `/job/${item.jobId}`,
                  }))}
                  onClick={handleClick}
                />
              </Box>
            ) : (
              <Box className={classes.notFound}>
                <Typography>No Data Found</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
}
